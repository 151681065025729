import React from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { DeprecatedNumberField } from "./DeprecatedNumberField";

export const DeprecatedFormikNumberField = ({
  name,
  helperText,
  onChange,
  hasError: hasErrorProp,
  displayErrors = true,
  ...other
}) => {
  const [{ onBlur }, { value, error, touched }, { setValue }] = useField(name);

  const handleChange = ({ value: newValue }) => {
    setValue(newValue);
  };

  const hasError = error && touched;

  return (
    <DeprecatedNumberField
      name={name}
      value={value}
      onValueChange={onChange || handleChange}
      onBlur={onBlur}
      error={hasErrorProp || hasError}
      helperText={displayErrors && (hasErrorProp || hasError ? error : helperText)}
      {...other}
    />
  );
};

DeprecatedFormikNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  displayErrors: PropTypes.bool,
};
