import { combineReducers } from "redux";

import { vaultIndexReducer as vaultIndex } from "./vaultIndexReducer";
import { vaultNewWizardReducer as vaultNewWizard } from "./vaultNewWizardReducer";
import { vaultShowReducer as vaultShow } from "./vaultShowReducer";

const vaultReducers = combineReducers({
  vaultNewWizard,
  vaultIndex,
  vaultShow,
});

export { vaultReducers };
