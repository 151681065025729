import { combineReducers } from "redux";

import { personalOrgReducer as personalOrg } from "./personalOrgReducer";
import { userReducer as user } from "./userReducer";

const userReducers = combineReducers({
  user,
  personalOrg,
});

export { userReducers };
