import * as yup from "yup";

import { validateMaxPrecision } from "./customNumberMethods";
import {
  trefoilMax,
  trefoilMin,
  trefoilMinMax,
  validateBigNumber,
  validateBitcoinAddress,
  validateEin,
  validateEq,
  validateGt,
  validateGte,
  validateLt,
  validateLte,
  validateMaxStringLength,
  validateMaxTextLength,
  validateNumDigits,
  validateNumInRange,
  validateOrgName,
  validatePhone,
  validateSsn,
} from "./customStringMethods";

// NOTE: When adding a yup method, also add a type for it to ./typings/yup.d.ts
// Otherwise, TypeScript won't like it!

yup.addMethod(yup.number, "maxPrecision", validateMaxPrecision);

yup.addMethod(yup.string, "bitcoinAddress", validateBitcoinAddress);
yup.addMethod(yup.string, "maxStringLength", validateMaxStringLength);
yup.addMethod(yup.string, "maxTextLength", validateMaxTextLength);
yup.addMethod(yup.string, "numInRange", validateNumInRange);
yup.addMethod(yup.string, "numDigits", validateNumDigits);
yup.addMethod(yup.string, "ssn", validateSsn);
yup.addMethod(yup.string, "ein", validateEin);
yup.addMethod(yup.string, "bigNumber", validateBigNumber);
yup.addMethod(yup.string, "eq", validateEq);
yup.addMethod(yup.string, "lt", validateLt);
yup.addMethod(yup.string, "lte", validateLte);
yup.addMethod(yup.string, "gt", validateGt);
yup.addMethod(yup.string, "gte", validateGte);
yup.addMethod(yup.string, "trefoilMin", trefoilMin);
yup.addMethod(yup.string, "trefoilMax", trefoilMax);
yup.addMethod(yup.string, "trefoilMinMax", trefoilMinMax);
yup.addMethod(yup.string, "phone", validatePhone);
yup.addMethod(yup.string, "orgName", validateOrgName);

/* Given a value, returns a function that returns whether all passed args match that value exactly. */
const all =
  value =>
  (...args) =>
    args.every(arg => arg === value);

/* Passed as a second argument to schema.when, requires the schema if all of the when args are the given value */
const requireIf = (value: any) => ({
  is: all(value),
  then: (schema: yup.AnySchema) => schema.required(),
});

const { object, array, string, date, lazy, boolean, number } = yup;

export { yup, requireIf, all, object, array, string, date, lazy, boolean, number };
