import React from "react";

import { CircularProgress } from "@mui/material";
import {
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Modal,
  ButtonProps,
} from "@unchained/component-library";

import { setIsShowCancelModal } from "Contexts/SellBitcoin/SellBitcoinActions";
import { useSellBitcoinDispatch } from "Contexts/SellBitcoin/sellBitcoinContext";

type LeaveSellFlowModalProps = {
  onCloseClick: () => void;
  onCancelAndClose: () => void;
  className: string;
  isDeleting: boolean;
};
export const LeaveSellFlowModal = ({
  onCloseClick,
  className,
  onCancelAndClose,
  isDeleting,
}: LeaveSellFlowModalProps) => {
  const dispatch = useSellBitcoinDispatch();

  const footerButtons: ButtonProps[] = [
    {
      onClick: onCloseClick,
      children: "Save progress and close",
    },
    {
      type: "destructive",
      onClick: onCancelAndClose,
      children: isDeleting ? (
        <CircularProgress color="primary" size="1rem" />
      ) : (
        "Cancel and delete transaction"
      ),
    },
  ];

  const hideModal = () => {
    dispatch(setIsShowCancelModal(false));
  };

  return (
    <Modal className={className} onDismiss={hideModal} maxWidth="md">
      <ModalHeader>
        <ModalTitle subtitle="How would you like to proceed?">Transaction in progress</ModalTitle>
      </ModalHeader>
      <ModalFooter actions={footerButtons}></ModalFooter>
    </Modal>
  );
};
