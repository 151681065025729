import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  envelope: {},
  envelope_accepted_files: [],
  envelope_rejected_files: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "CONTRACT_SIGN/SET/ENVELOPE":
      return updatedField(state, action, "envelope");
    case "CONTRACT_SIGN/SET/ENVELOPE_ACCEPTED_FILES":
      return updatedField(state, action, "envelope_accepted_files");
    case "CONTRACT_SIGN/SET/ENVELOPE_REJECTED_FILES":
      return updatedField(state, action, "envelope_rejected_files");
    case "CONTRACT_SIGN/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "CONTRACT_SIGN/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "CONTRACT_SIGN/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
