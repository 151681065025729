import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { LoadingWrapper } from "./LoadingWrapper";

export const withCurrentOrg = (Child, mapChildStateToProps, mapChildDispatchToProps) => {
  const mapWrapperStateToProps = state => {
    return {
      orgStatus: state.account.orgs.status,
    };
  };

  const ConnectedChild = connect(mapChildStateToProps, mapChildDispatchToProps)(Child);

  const Wrapper = class extends React.Component {
    static propTypes = {
      orgStatus: PropTypes.string.isRequired,
    };

    render() {
      const { orgStatus, params, ...other } = this.props;
      return (
        <LoadingWrapper
          status={orgStatus}
          render={() => <ConnectedChild params={params} {...other} />}
        />
      );
    }
  };

  return connect(mapWrapperStateToProps)(Wrapper);
};
