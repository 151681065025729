import { API_V2 } from "Shared/api/api";
import {
  AcceptConnectionsFeatureTos200,
  CreateConnection200,
  CreateConnectionRequest,
  GetConnectionsFeatureTosStatus200,
  ListConnections200,
  GetConnection200,
  GetConnectionKeys200,
  CreateOrUpdateSharedConnectionKey200,
  CreateOrUpdateSharedConnectionKeyRequest,
  DeleteSharedConnectionKeyErrors,
  GetConnectionsMetadata200,
  PatchConnectionsMetadata200,
  GetConnectionVaults200,
} from "Specs/v2";
import { PatchConnectionsMetadataRequest } from "Specs/v2/components";
import { CreateConnectionPathParams } from "Specs/v2/createConnection/params/path";
import { CreateOrUpdateSharedConnectionKeyPathParams } from "Specs/v2/createOrUpdateSharedConnectionKey/params/path";
import { GetConnectionPathParams } from "Specs/v2/getConnection/params/path";
import { GetConnectionKeysPathParams } from "Specs/v2/getConnectionKeys/params/path";
import { GetConnectionVaultsPathParams } from "Specs/v2/getConnectionVaults/params/path";
import { GetConnectionsFeatureTosStatusPathParams } from "Specs/v2/getConnectionsFeatureTosStatus/params/path";
import { ListConnectionsPathParams } from "Specs/v2/listConnections/params/path";
import { ListConnectionsQueryParams } from "Specs/v2/listConnections/params/query";
import { UpdateConnectionPathParams } from "Specs/v2/updateConnection/params/path";

export class ConnectionsAPI {
  static async GetConnectionsMetadata(orgUuid: ListConnectionsPathParams["orgUuid"]) {
    return (await API_V2.Get<GetConnectionsMetadata200>(`/orgs/${orgUuid}/connections-metadata`))
      .data;
  }
  static async PatchConnectionsMetadata(
    orgUuid: ListConnectionsPathParams["orgUuid"],
    data: PatchConnectionsMetadataRequest
  ) {
    return (
      await API_V2.MergePatch<PatchConnectionsMetadata200>(
        `/orgs/${orgUuid}/connections-metadata`,
        data
      )
    ).data;
  }
  static async ListConnections(
    orgUuid: ListConnectionsPathParams["orgUuid"],
    params: ListConnectionsQueryParams
  ) {
    return (await API_V2.Get<ListConnections200>(`/orgs/${orgUuid}/connections`, params)).data;
  }
  static async CreateConnection(
    orgUuid: CreateConnectionPathParams["orgUuid"],
    data: CreateConnectionRequest
  ) {
    return (await API_V2.Post<CreateConnection200>(`/orgs/${orgUuid}/connections`, data)).data;
  }
  static async AcceptConnection(
    orgUuid: UpdateConnectionPathParams["orgUuid"],
    connectionUuid: UpdateConnectionPathParams["connectionUuid"]
  ) {
    return (
      await API_V2.Patch<GetConnection200>(
        `/orgs/${orgUuid}/connections/partnership/${connectionUuid}`,
        { state: "accepted" }
      )
    ).data;
  }
  static async CancelConnection(
    orgUuid: UpdateConnectionPathParams["orgUuid"],
    connectionUuid: UpdateConnectionPathParams["connectionUuid"]
  ) {
    return (
      await API_V2.Patch<GetConnection200>(
        `/orgs/${orgUuid}/connections/partnership/${connectionUuid}`,
        { state: "rejected" }
      )
    ).data;
  }
  static async DissolveConnection(
    orgUuid: UpdateConnectionPathParams["orgUuid"],
    connectionUuid: UpdateConnectionPathParams["connectionUuid"]
  ) {
    return (
      await API_V2.Patch<GetConnection200>(
        `/orgs/${orgUuid}/connections/partnership/${connectionUuid}`,
        { state: "dissolved" }
      )
    ).data;
  }
  static async DeclineConnection(
    orgUuid: UpdateConnectionPathParams["orgUuid"],
    connectionUuid: UpdateConnectionPathParams["connectionUuid"]
  ) {
    return ConnectionsAPI.CancelConnection(orgUuid, connectionUuid);
  }
  static async GetTosAgreementStatus(orgUuid: GetConnectionsFeatureTosStatusPathParams["orgUuid"]) {
    return (await API_V2.Get<GetConnectionsFeatureTosStatus200>(`/orgs/${orgUuid}/connections/tos`))
      .data;
  }
  static async AcceptConnectionsTos(orgUuid) {
    return (
      await API_V2.Patch<AcceptConnectionsFeatureTos200>(`/orgs/${orgUuid}/connections/tos`, {
        accepted: true,
      })
    ).data;
  }
  static async GetConnection(
    orgUuid: GetConnectionPathParams["orgUuid"],
    connectionType: "partnership",
    connectionUuid: GetConnectionPathParams["connectionUuid"]
  ) {
    return (
      await API_V2.Get<GetConnection200>(
        `/orgs/${orgUuid}/connections/${connectionType}/${connectionUuid}`
      )
    ).data;
  }
  static async GetConnectionKeys(
    orgUuid: GetConnectionKeysPathParams["orgUuid"],
    connectionType: "partnership",
    connectionUuid: GetConnectionKeysPathParams["connectionUuid"]
  ) {
    return (
      await API_V2.Get<GetConnectionKeys200>(
        `/orgs/${orgUuid}/connections/${connectionType}/${connectionUuid}/keys`
      )
    ).data;
  }

  static async GetConnectionVaults(
    orgUuid: GetConnectionVaultsPathParams["orgUuid"],
    connectionType: "partnership",
    connectionUuid: GetConnectionVaultsPathParams["connectionUuid"]
  ) {
    return (
      await API_V2.Get<GetConnectionVaults200>(
        `/orgs/${orgUuid}/connections/${connectionType}/${connectionUuid}/vaults`
      )
    ).data;
  }

  static async CreateOrUpdateKeyShare({
    orgUuid,
    connectionUuid,
    accountKeyUuid,
    connectionType = "partnership",
    permissions = { sharedForUseByConnections: true },
  }: {
    orgUuid: CreateOrUpdateSharedConnectionKeyPathParams["orgUuid"];
    connectionUuid: CreateOrUpdateSharedConnectionKeyPathParams["connectionUuid"];
    accountKeyUuid: CreateOrUpdateSharedConnectionKeyPathParams["accountKeyUuid"];
    connectionType?: "partnership";
    permissions?: CreateOrUpdateSharedConnectionKeyRequest["permissions"];
  }) {
    return (
      await API_V2.Put<CreateOrUpdateSharedConnectionKey200>(
        `/orgs/${orgUuid}/connections/${connectionType}/${connectionUuid}/keys/${accountKeyUuid}`,
        { permissions }
      )
    ).data;
  }

  static async RemoveKeyShare({
    orgUuid,
    connectionUuid,
    accountKeyUuid,
    connectionType = "partnership",
  }: {
    orgUuid: CreateOrUpdateSharedConnectionKeyPathParams["orgUuid"];
    connectionUuid: CreateOrUpdateSharedConnectionKeyPathParams["connectionUuid"];
    accountKeyUuid: CreateOrUpdateSharedConnectionKeyPathParams["accountKeyUuid"];
    connectionType?: "partnership";
  }) {
    return await API_V2.Delete<DeleteSharedConnectionKeyErrors>(
      `/orgs/${orgUuid}/connections/${connectionType}/${connectionUuid}/keys/${accountKeyUuid}`
    );
  }
}
