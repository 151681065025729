import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const UnchainedLogo = (
  { viewBox = "0 0 32 32", htmlColor = "", classes = { root: "h-[32px] w-[33px]" } },
  props
) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={classes}>
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="white"
    />
    <path
      d="M12.3005 21.6418C10.9245 23.7258 9.86845 25.7378 9.06445 27.5418C11.0885 28.7618 13.4645 29.4618 16.0005 29.4618C23.4365 29.4618 29.4645 23.4338 29.4645 15.9978C29.4645 15.7538 29.4565 15.5098 29.4445 15.2658H27.9245C22.9645 15.2658 16.5885 15.1418 12.3005 21.6378V21.6418Z"
      fill="#0095D6"
    />
    <path
      d="M29.3211 14.0411H21.6651C12.4131 14.0411 7.12511 18.1371 4.13311 22.3651C3.11311 20.4691 2.53711 18.3011 2.53711 16.0011C2.53711 8.56511 8.56511 2.53711 16.0011 2.53711C22.7691 2.53711 28.3731 7.53311 29.3211 14.0411Z"
      fill="#002851"
    />
    <path
      d="M16.1032 5.97895L16.3432 6.71895C16.6072 7.52295 17.2392 8.15495 18.0432 8.41895L18.7832 8.65895C18.8632 8.68695 18.8632 8.79895 18.7832 8.82295L18.0432 9.06295C17.2392 9.32695 16.6072 9.95895 16.3432 10.7629L16.1032 11.5029C16.0752 11.5829 15.9632 11.5829 15.9392 11.5029L15.6992 10.7629C15.4352 9.95895 14.8032 9.32695 13.9992 9.06295L13.2592 8.82295C13.1792 8.79495 13.1792 8.68295 13.2592 8.65895L13.9992 8.41895C14.8032 8.15495 15.4352 7.52295 15.6992 6.71895L15.9392 5.97895C15.9672 5.89895 16.0792 5.89895 16.1032 5.97895Z"
      fill="white"
    />
  </SvgIcon>
);

UnchainedLogo.propTypes = {
  classNames: PropTypes.string,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
