import { Vault } from "Components/VaultsView/types";
import { isOwnOutput } from "Utils/transactionUtils";

import { Product } from "./productUtils";

interface Output {
  product?: Vault;
  amount: number;
}

export interface Transaction {
  product_type: string;
  uuid: string;
  outputs: Output[];
}

export interface Outflow {
  cryptoAmount: number;
}

/** Returns true if the passed transaction spends more than the threshold of the passed vault. */
export function transactionIsPastThreshold(vault: Product, transaction: Transaction) {
  const outflow = {
    cryptoAmount: transaction.outputs
      .filter(output => !isOwnOutput(vault, output))
      .reduce((sum, output) => sum + output.amount, 0),
  };
  return outflowIsPassedThreshold(vault, outflow);
}

/** Returns true if the passed outflow spends more than the threshold of the passed vault. */
export function outflowIsPassedThreshold(vault: Product, outflow: Outflow) {
  if (vault && vault.threshold !== undefined) {
    const amountSpent = outflow.cryptoAmount;
    return amountSpent > vault.threshold * vault.balance;
  }
  // else
  return false;
}
