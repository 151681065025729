import $ from "jquery";

import ajax from "Utils/ajax";

import { submittingRequest, requestFailed, requestSuccess } from "./common";
import { flash } from "./flash";

const UNCHAINED_LOG = window.UNCHAINED_LOG;

var log = typeof UNCHAINED_LOG != "undefined" && UNCHAINED_LOG;

var context = "VERIFICATION/RECORD";
var name = "REQUEST";

export function uploadVerificationVideo(verification, video, callback) {
  var fd = new FormData();
  var fields = Object.keys(verification.upload_params.video.fields);
  var index;
  for (index in fields) {
    var field = fields[index];
    fd.append(field, verification.upload_params.video.fields[field]);
  }
  fd.append("file", video);
  return dispatch => {
    // Do NOT use our 'ajax' wrapper here as we're NOT talking to
    // our own API.
    dispatch(submittingRequest(context, name));
    $.ajax({
      method: "POST",
      url: verification.upload_params.video.url,
      data: fd,
      contentType: false,
      processData: false,
      beforeSend: function (xhr, settings) {
        if (log) {
          console.group(`POST ${verification.upload_params.video.url}`);
          console.info("Request Data:", fd);
        }
      },
      success: function (response, textStatus, xhr) {
        if (log) {
          console.info("Status", textStatus, "Response Data:", response);
        }
        callback();
      },
      error: function (xhr, textStatus, errorMessage) {
        console.error(errorMessage, textStatus);
        dispatch(requestFailed(context, name, errorMessage));
        dispatch(flash("error", errorMessage));
      },
      complete: function (xhr, textStatus) {
        if (log) {
          console.groupEnd();
        }
      },
    });
  };
}

export function verificationWasUploaded(verification, callback, dispatchFlash = true) {
  return dispatch => {
    // DO use our own 'ajax' wrapper here as we ARE talking to our
    // own API.
    //
    // But do NOT use 'submittingRequest' -- we've already called
    // it above in 'uploadVerificationVideo' by the time we reach
    // here.
    ajax({
      dispatch: dispatchFlash ? dispatch : undefined,
      method: "POST",
      url: `/verifications/${verification.uuid}/uploaded`,
      data: {},
      success: function (response) {
        dispatch(requestSuccess(context, name));
        callback();
      },
      failure: function (response) {
        dispatch(requestFailed(context, name, response.message));
      },
      error: function (errorMessage) {
        dispatch(requestFailed(context, name, errorMessage));
      },
    });
  };
}
