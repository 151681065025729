import { flattenManifest, WizardStep } from "@unchained/component-library";

type Step = (WizardStep | WizardStep["steps"][number]) & {
  skip?: boolean;
  excludeFromNavigation?: boolean;
};

const handleSkipped = (step: Step) => {
  const newStep = { ...step };
  if (step.skip) {
    newStep.disabled = true;
    newStep.excludeFromNavigation = true;
  }
  if (step.steps) {
    newStep.steps = step.steps.map(handleSkipped);
  }
  return newStep;
};

/**
 * To simplify step logic, there are certain instances where we want to explicitly "skip"
 * a step, rather than just disable it. This function looks through all steps/substeps in a manifest
 * and effectively hides them if they are marked as "skip".
 */
export const hideSkippedSteps = (
  manifest: (WizardStep & { skip?: boolean; excludeFromNavigation?: boolean })[]
) => {
  const newManifest: WizardStep[] = [];

  manifest.forEach((step, i) => {
    const newStep = handleSkipped(step);
    newManifest.push(newStep as WizardStep);
  });

  return newManifest as WizardStep[];
};

export const flatVisibleStepTitles = (manifest: WizardStep[]) =>
  flattenManifest(manifest)
    .filter(s => !s.disabled)
    .map(s => s.title);
