import { AcceptedMemberUser as User, ChildRole, CompleteOrg } from "Specs/v1/getOrg/200";

export const ROLE_OPTIONS = [
  {
    label: "Admin",
    value: "admin",
    plural: "Admins",
    managed: ["admins", "transaction_approvers", "viewers"],
  },
  {
    label: "Approver",
    value: "approver",
    plural: "Transaction Approvers",
    managed: ["transaction_approvers", "viewers"],
  },
  { label: "Viewer", value: "viewer", plural: "Viewers", managed: ["viewers"] },
];

export const tradingManagedRoles = ["trader_role", "trading_manager_role"];

export const mainOrgRoles = new Set([
  "admins",
  "transaction_approvers",
  "viewers",
  "trader_role",
  "trading_manager_role",
]);
/**
 * Returns the display name for the "highest" role the user has
 */
export const displayRoleName = (
  user: User,
  ownerId: string,
  childRoles: ChildRole[],
  userRoles?: any[]
): string => {
  const isOwner = ownerId === user.uuid;
  if (isOwner) return "Admin";

  const assignedUserRoles = userRoles || rolesForUserUuid(user.uuid, childRoles);

  const firstMatchingRole = ROLE_OPTIONS.find(option =>
    assignedUserRoles.find(role => role.name === option.plural)
  );

  return firstMatchingRole ? firstMatchingRole.label : "Viewer";
};

export const rolesForUserUuid = (userUuid: string, childRoles: ChildRole[]) =>
  childRoles.filter(
    r => !r.dedicated && (r.users || []).find(roleUser => roleUser.uuid === userUuid)
  );

/** Returns the number of members in the org with permissions sufficient to approve transactions */
export const numMembersWhoCanApprove = (org: CompleteOrg): number => {
  const approverUserIds = new Set();
  org.child_roles
    .find(role => role.managed === "transaction_approvers")
    ?.users.forEach(user => {
      approverUserIds.add(user.uuid);
    });

  return approverUserIds.size;
};

export const userHasTrading = (userUuid: string, childRoles: ChildRole[]): boolean => {
  const roles = rolesForUserUuid(userUuid, childRoles);
  return roles.some(role => tradingManagedRoles.includes(role.managed));
};
