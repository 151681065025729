import React, { useState } from "react";

import { KEYSTORES } from "@caravan/wallets";

import { ItemButton } from "Components/Shared/Elements/Buttons/ItemButton";
import { ColdcardLogo, LedgerLogo, TrezorLogo } from "Components/Shared/Logos";
import { ManifestTypes } from "Components/wallets/ConfirmOnDeviceWizard/getSteps";
import { KEYSTORE } from "Components/wallets/types";

interface SigningDeviceSelectorProps {
  onDeviceSelected: (keystore: KEYSTORE) => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  defaultActiveDeviceType?: ManifestTypes | "";
}

export const SigningDeviceSelector = ({
  onDeviceSelected,
  size = "md",
  defaultActiveDeviceType = "",
}: SigningDeviceSelectorProps) => {
  const [activeDeviceType, setActiveWallet] = useState<ManifestTypes | "">(defaultActiveDeviceType);
  const logos = {
    [KEYSTORES.LEDGER]: LedgerLogo,
    [KEYSTORES.TREZOR]: TrezorLogo,
    [KEYSTORES.COLDCARD]: ColdcardLogo,
  };
  const buttons = [
    {
      type: KEYSTORES.LEDGER,
      label: "Ledger",
      onClick: () => onDeviceSelected(KEYSTORES.LEDGER),
    },
    {
      type: KEYSTORES.TREZOR,
      label: "Trezor",
      onClick: () => onDeviceSelected(KEYSTORES.TREZOR),
    },
    {
      type: KEYSTORES.COLDCARD,
      label: "Coldcard",
      onClick: () => onDeviceSelected(KEYSTORES.COLDCARD),
    },
  ];

  const onDeviceClick = (type: KEYSTORE, cb: (KEYSTORE) => void) => {
    setActiveWallet(type);
    cb(type);
  };

  return (
    <div className="grid grid-cols-3">
      {buttons.map(({ type, label, onClick }) => (
        <div
          key={type}
          className="col-span-1 flex flex-col items-center"
          data-testid="signing-device-button"
        >
          <ItemButton
            key={type}
            onClick={() => onDeviceClick(type, onClick)}
            name={label}
            size={size}
            icon={logos[type]}
            active={activeDeviceType === type}
          />
        </div>
      ))}
    </div>
  );
};
