import React, { useContext } from "react";

import { WizardStepperContext } from "@unchained/component-library";

import { PaymentConfirmation } from "Routes/onboard/[uuid]/(shared)";

export const IndividualPaymentConfirmation = () => {
  const { goToNext } = useContext(WizardStepperContext);
  return (
    <PaymentConfirmation
      buttonText="Continue"
      subtitle="You can now proceed to Unchained"
      onNext={goToNext}
    />
  );
};
