import React from "react";

import PropTypes from "prop-types";

import { humanState } from "Utils/states";
import { formatCurrency, detableize } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

import { ProductListing, productListingProptypes } from "./product_listing";
import { Spend, spendProptypes } from "./spends";
import { verificationProptypes } from "./verifications";

export function sweepProptypes() {
  return {
    uuid: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    source_wallet_type: PropTypes.string.isRequired,
    destination_wallet_type: PropTypes.string,
    source_slice_address: PropTypes.string.isRequired,
    destination_slice_address: PropTypes.string,
    signing_role: PropTypes.string.isRequired,
    cosigning_role: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    spend: PropTypes.shape(spendProptypes()),
    unit: PropTypes.string.isRequired,
    context_type: PropTypes.string.isRequired,
    product: PropTypes.shape(productListingProptypes()),
    is_rekey: PropTypes.bool.isRequired,
    comparison_verification: PropTypes.shape(verificationProptypes()),
    verification: PropTypes.shape(verificationProptypes()),

    humanState: PropTypes.string.isRequired,
    createdAt: PropTypes.object.isRequired,
    readableCreatedAt: PropTypes.string.isRequired,
    amountWithUnit: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
  };
}

export function Sweep(data) {
  if (data == null) {
    return data;
  }

  data.humanState = humanState(data);
  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);

  data.description = (
    <span>
      {detableize(data.context_type)} <code>{data.uuid}</code>
    </span>
  );

  if (data.product) {
    data.product = ProductListing(data.product);
  }

  if (data.spend) {
    data.spend = Spend(data.spend);
  }

  data.amountWithUnit = `${formatCurrency(data.amount, 8)} ${data.unit}`;

  return data;
}
