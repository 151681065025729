import { CreateOrgClientReportRequest } from "Specs/v2";

import { ApiType } from "../apiType";

export const ClientReportsApiFactory = (API: ApiType) => ({
  Create: async (data: CreateOrgClientReportRequest) => {
    const response = await API.Post<CreateOrgClientReportRequest>(`/client-reports`, data);
    return response.data;
  },
});
