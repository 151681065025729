import React from "react";

import { KEYSTORES } from "@caravan/wallets";

import BraveIcon from "Assets/images/brave-icon.png";
import ChromeIcon from "Assets/images/chrome-icon.png";
import ColdcardIcon from "Assets/images/coldcard.png";
import FirefoxIcon from "Assets/images/firefox-icon.png";
import LedgerIcon from "Assets/images/ledger.png";
import TrezorIcon from "Assets/images/trezor.png";

import { LedgerBrowserNotSupported } from "../SigningDevices/LedgerBrowserNotSupported";
import {
  LedgerWindowsSecurityWarningDeviceCheck,
  LedgerWindowsSecurityWarningPrompt,
  LedgerWindowsSecurityWarningSigning,
  LedgerWindowsSecurityWarningSimple,
} from "../SigningDevices/LedgerWindowsSecurityWarning";
import { TrezorBrowserNotSupported } from "../SigningDevices/TrezorBrowserNotSupported";

const CHECKING = "Checking";
const SIGNING = "Signing";
const CONFIRMING = "Confirming";

export const browserVersions = {
  chrome: {
    name: "chrome",
    version: "78.0.3904",
    compatibility: ">=",
    image: ChromeIcon,
  },
  firefox: {
    name: "firefox",
    version: "70.0.1",
    compatibility: "=",
    image: FirefoxIcon,
  },
  brave: {
    name: "brave",
    version: "0.66.100",
    compatibility: ">=",
    image: BraveIcon,
  },
};

export const DEVICE_INTERACTION_TYPE_ENUM = {
  CHECKING,
  SIGNING,
  CONFIRMING,
};

export const DEVICE_ISSUE_DISPLAY_FORMAT_ENUM = {
  SLIDE: "SLIDE",
  SNACKBAR: "SNACKBAR",
  TEXT: "TEXT",
};

export const compatibilityMatrix = {
  trezorModelT: {
    name: "Trezor Model T",
    make: KEYSTORES.TREZOR,
    model: "Model T",
    image: TrezorIcon,
    versions: [
      {
        name: "Firmware",
        version: ["2.4.1", "2.3.6"],
      },
      {
        name: "Bridge",
        version: ["2.0.27", "2.0.27"],
      },
    ],
    browsers: {
      chrome: {
        browserName: "Chrome",
        support: "full",
      },
      firefox: {
        browserName: "Firefox",
        support: "full",
      },
      brave: {
        browserName: "Brave",
        support: "partial",
      },
    },
    issues: [
      {
        id: 601,
        browsers: ["brave"],
        title: "Browser Not Supported by Trezor",
        description: <TrezorBrowserNotSupported browser="Brave" />,
      },
    ],
  },
  trezorOne: {
    name: "Trezor One",
    make: KEYSTORES.TREZOR,
    model: "One",
    image: TrezorIcon,
    versions: [
      {
        name: "Firmware",
        version: ["1.10.2", "1.10.1"],
      },
      {
        name: "Bridge",
        version: ["2.0.27", "2.0.27"],
      },
    ],
    browsers: {
      chrome: {
        browserName: "Chrome",
        support: "full",
      },
      firefox: {
        browserName: "Firefox",
        support: "full",
      },
      brave: {
        browserName: "Brave",
        support: "partial",
      },
    },
    issues: [
      {
        id: 601,
        browsers: ["brave"],
        title: "Browser Not Supported by Trezor",
        description: <TrezorBrowserNotSupported browser="Brave" />,
      },
    ],
  },
  ledgerNanoS: {
    name: "Ledger Nano S",
    make: KEYSTORES.LEDGER,
    model: "Nano S",
    image: LedgerIcon,
    versions: [
      {
        name: "Firmware",
        version: ["2.0.0", "1.6.1"],
      },
      {
        name: "BTC App",
        version: ["1.6.0", "1.5.5"],
      },
    ],
    browsers: {
      chrome: {
        browserName: "Chrome",
        support: "full",
      },
      firefox: {
        browserName: "Firefox",
        support: "no",
      },
      brave: {
        browserName: "Brave",
        support: "full",
      },
    },
    issues: [
      {
        id: 403,
        browsers: ["firefox"],
        title: "Browser Not Supported by Ledger",
        description: <LedgerBrowserNotSupported browser="Firefox" />,
      },
      {
        id: 662,
        title: "Windows Security Warning",
        prompt: <LedgerWindowsSecurityWarningPrompt />,
        os: ["windows"],
        browsers: ["firefox"],
        signIssue: true,
        checkIssue: true,
        content: {
          default: <LedgerWindowsSecurityWarningSimple />,
          [CHECKING]: <LedgerWindowsSecurityWarningDeviceCheck />,
          [SIGNING]: <LedgerWindowsSecurityWarningSigning />,
        },
        displayFormat: DEVICE_ISSUE_DISPLAY_FORMAT_ENUM.SLIDE,
      },
    ],
  },
  ledgerNanoX: {
    name: "Ledger Nano X",
    make: KEYSTORES.LEDGER,
    model: "Nano X",
    image: LedgerIcon,
    versions: [
      {
        name: "Firmware",
        version: ["1.2.4-5", "1.2.4-4"],
      },
      {
        name: "BTC App",
        version: ["1.5.5", "1.5.1"],
      },
    ],
    browsers: {
      chrome: {
        browserName: "Chrome",
        support: "full",
      },
      firefox: {
        browserName: "Firefox",
        support: "no",
        browserIssues: [],
      },
      brave: {
        browserName: "Brave",
        support: "full",
      },
    },
    issues: [
      {
        id: 403,
        browsers: ["firefox"],
        title: "Browser Not Supported by Ledger",
        description: <LedgerBrowserNotSupported browser="Firefox" />,
      },
      {
        id: 662,
        title: "Windows Security Warning",
        prompt: <LedgerWindowsSecurityWarningPrompt />,
        os: ["windows"],
        browsers: ["firefox"],
        signIssue: true,
        checkIssue: true,
        content: {
          default: <LedgerWindowsSecurityWarningSimple />,
          [CHECKING]: <LedgerWindowsSecurityWarningDeviceCheck />,
          [SIGNING]: <LedgerWindowsSecurityWarningSigning />,
        },
        displayFormat: DEVICE_ISSUE_DISPLAY_FORMAT_ENUM.SLIDE,
      },
    ],
  },
  coldCard: {
    name: "Coldcard",
    make: KEYSTORES.COLDCARD,
    model: "MK2 & MK3",
    image: ColdcardIcon,
    versions: [
      {
        name: "Hardware",
        version: ["MK3", "MK2"],
      },
      {
        name: "Firmware",
        version: ["4.1.2", "4.1.1"],
      },
    ],
    browsers: {
      chrome: {
        browserName: "Chrome",
        support: "full",
      },
      firefox: {
        browserName: "Firefox",
        support: "full",
      },
      brave: {
        browserName: "Brave",
        support: "full",
      },
    },
    issues: [],
  },
};
