import { useEffect, useRef, useState } from "react";

/**
 * A custom hook that will return the previous value passed into the function.
 * @description a simple hook that provides a ref variable and an isHovered boolean.
 * The ref can be linked to an element and isHovered will be set to true while the
 * users mouse is hovering over it.
 *
 * @source https://usehooks.com/useHover/
 *
 * @returns {[ ref: RefObject, isHovered: boolean ]}
 */
export const useHover = (startHoverDelay = 0, endHoverDelay = 0) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef(null);
  let mouseEnterTimer, mouseOutTimer;

  const handleMouseOver = () => {
    clearTimeout(mouseOutTimer);
    mouseEnterTimer = setTimeout(() => setIsHovered(true), startHoverDelay);
  };
  const handleMouseOut = () => {
    clearTimeout(mouseEnterTimer);
    mouseOutTimer = setTimeout(() => setIsHovered(false), endHoverDelay);
  };
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  );
  return [ref, isHovered];
};
