import { Invoice } from "Shared/api/v2";

export const Shipping = ({
  name,
  address,
}: {
  name: string;
  address: Invoice["shippingAddress"];
}) => {
  const { line1, line2, city, state, zip, country } = address;
  const addressLineOne = [line1, line2].filter(Boolean).join(", ");
  const addressLineTwo = `${city}, ${state} ${zip}, ${country}`;
  return (
    <div>
      <p className="mt-6 text-sm font-semi text-gray-900">Ship to:</p>
      <p className="text-gray-700">{name}</p>
      <p className="text-gray-700">{addressLineOne}</p>
      <p className="text-gray-700">{addressLineTwo}</p>
    </div>
  );
};
