import { PaginationState } from "@tanstack/react-table";
import { QueryKey, useQuery } from "react-query";

/**
 * This is a helper function that can be used for paginated queries.
 * Because react table has 0-indexed pagination but flask-sqlalchemy has
 * 1-indexed pagination, we need a way to "normalize" these requests.
 * Rather than having to manually add 1 each time we want to create new
 * paginated queries
 * @param pagination {PaginationState} - zero indexed pagination state
 * @param keys {QueryKey} - the query keys to pass to useQuery
 * @param apiFunc {function} - this is the api callback to pass to useQuery, like MyAPI.GetList.
 * The function signature MUST be such that the first argument it accepts are the pagination parameters
 * @param ...rest {any[]} - any other parameters will be passed to the apiFunc in the order they are
 * passed here
 * @returns useQuery call
 */
export const useGetPaginatedApiQuery = <ResponseType>(
  pagination: PaginationState,
  keys: QueryKey,
  apiFunc: (pagination: PaginationState, ...rest: unknown[]) => Promise<ResponseType>,
  ...rest: unknown[]
) =>
  useQuery<ResponseType, Error>(keys, () =>
    apiFunc({ ...pagination, pageIndex: pagination.pageIndex + 1 }, ...rest)
  );
/**
 * Wraps the API Spec error object in the appropriate Axios/API object.
 * So that all of its attributes are accessible.
 * */
export type ApiError<ErrorType> = {
  response: {
    data: ErrorType;
    status: number;
    statusText: string;
    headers: object;
    config: object;
  };
} & ErrorType;
