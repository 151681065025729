import { ReactNode, useContext, useEffect, useState } from "react";

import { FiberManualRecord, Stop } from "@mui/icons-material";
import {
  Banner,
  Button,
  ButtonProps,
  HelpCircle,
  Loader,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Tooltip,
} from "@unchained/component-library";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import MediaCapturer from "react-multimedia-capture";

import { VideoRecordingContext, withVideoRecordingContext } from "Contexts/index";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { useEasyToasts } from "Utils/toasts";

import styles from "./VideoRecordingModal.module.scss";

type ModalProps = {
  script: string | ReactNode;
  title: string;
  subtitle?: string;
  submit?: (video: Blob) => Promise<void>;
  postSuccessRedirect?: string;
  instructions?: string | ReactNode;
  cancelButton?: ButtonProps;
  onClose?: () => void;
};

const VideoRecordingModalContent = ({
  script,
  title,
  submit,
  postSuccessRedirect,
  subtitle,
  onClose = AppModalManager.close,
}: ModalProps) => {
  const {
    video,
    state: {
      error,
      recording,
      granted,
      rejectedReason,
      timeWarningNeeded,
      warnSecondsBeforeTimeout,
      timeOutAfterSeconds,
    },
    recordAgain,
    defineAutoStop,
    ref,
    handlers,
  } = useContext(VideoRecordingContext);

  const { showApiSuccessToast, showErrorToast } = useEasyToasts();
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const isPlayback = Boolean(video.size);

  const [startRecording, setStartRecording] = useState<() => void>(() => {});
  const [stopRecording, setStopRecording] = useState<() => void>(() => {});

  let videoSrcUrl: Blob | string = video;
  if (window.URL && (video as Blob).size) {
    videoSrcUrl = window.URL.createObjectURL(video as Blob);
  }

  useEffect(() => {
    defineAutoStop(stop);
  }, [video, defineAutoStop]);

  const handleSubmit = async () => {
    if (!video.size) {
      setErrorMessage("Please record a video.");
    } else {
      setErrorMessage("");
      setUploading(true);
      try {
        await submit(video);
        showApiSuccessToast(
          {
            title: "Video submission successful",
            message: "Your recording has been submitted",
          },
          postSuccessRedirect
        );
      } catch (error) {
        showErrorToast(error);
      } finally {
        setUploading(false);
        onClose();
      }
    }
  };

  const grantedState = {
    children: "Start recording",
    startIcon: <FiberManualRecord />,
    type: "primary",
    onClick: startRecording,
  };

  const recordingState = {
    children: "Stop recording",
    startIcon: <Stop />,
    type: "destructive",
    onClick: stopRecording,
  };

  const recordAgainState = {
    children: "Record again",
    type: "secondary",
    startIcon: <FiberManualRecord />,
    onClick: recordAgain,
    fullWidth: true,
  };

  const waitingState = {
    children: "Waiting for recording permission...",
    disabled: true,
  };

  let recordingButton;
  if (recording) {
    recordingButton = recordingState;
  } else if (granted) {
    recordingButton = grantedState;
  } else if (isPlayback) {
    recordingButton = recordAgainState;
  } else {
    recordingButton = waitingState;
  }

  return (
    <Modal onDismiss={AppModalManager.close} className="max-w-[44rem]">
      <ModalHeader>
        <ModalTitle subtitle={subtitle}>
          {title}
          <span className="pl-1">
            <Tooltip
              placement="top"
              content="Video verification is a security measure that helps confirm the integrity and details of a transaction request."
            >
              <div>
                <HelpCircle className="h-4 text-gray-400" />
              </div>
            </Tooltip>
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalContent>
        <div className="grid grid-cols-2 gap-4">
          <div className="relative aspect-[1/1] max-h-[21rem] w-full overflow-hidden rounded-lg">
            {isPlayback ? (
              <Video
                controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                className={`relative aspect-[1/1] h-full w-full rounded-lg ${styles.videoParent}`}
              >
                <source src={videoSrcUrl as string} type="video/webm" />
              </Video>
            ) : (
              <div ref={ref} className="h-full w-full">
                <MediaCapturer
                  className="h-full w-full"
                  constraints={{ audio: true, video: true }}
                  mimeType="video/webm"
                  timeSlice={10}
                  {...handlers}
                  width="100%"
                  render={({ start, stop }) => {
                    setStartRecording(() => start);
                    setStopRecording(() => stop);
                    if (rejectedReason) {
                      return (
                        <Banner className="w-full" type="warning">
                          Unable to record video. Check your media settings for this page.
                        </Banner>
                      );
                    }
                    if (granted) {
                      return (
                        <div className="h-full max-h-[21rem] w-full">
                          <div className="relative aspect-[1/1]">
                            <video
                              className="absolute inset-0 h-full w-full rounded-lg object-cover"
                              autoPlay
                              muted
                            />
                          </div>
                          {timeWarningNeeded && warnSecondsBeforeTimeout && timeOutAfterSeconds ? (
                            <Banner className="mt-4 w-full" type="warning">
                              Only {timeOutAfterSeconds - warnSecondsBeforeTimeout}s remaining...
                            </Banner>
                          ) : null}
                        </div>
                      );
                    } else {
                      return <Loader className="h-full w-full" />;
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="relative aspect-[1/1] max-h-[21rem] w-full rounded-md bg-gray-100 p-4 text-left">
            {script}
          </div>
        </div>

        <div className="mt-6 flex h-12 justify-between space-x-4">
          <Button {...recordingButton} className="flex w-1/2 justify-center" />
          <Button
            className="flex w-1/2 justify-center"
            type="primary"
            disabled={uploading || !video.size}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        {isPlayback && (
          <Banner className="mt-6 w-full" type="warning">
            Before submitting, review the video to ensure that the person is visible and
            identifiable, the audio is clear, and the provided script is followed closely.
          </Banner>
        )}
        {errorMessage || error?.message ? (
          <Banner className="w-full" type="error">
            {errorMessage || error?.message}
          </Banner>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

export const VideoRecordingModal = props =>
  withVideoRecordingContext(<VideoRecordingModalContent {...props} />);
