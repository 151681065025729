import { combineReducers } from "redux";

import { loanIndexReducer as loanIndex } from "./loanIndexReducer";
import { loanNewWizardReducer as loanNewWizard } from "./loanNewWizardReducer";
import { loanShowReducer as loanShow } from "./loanShowReducer";

const loanReducers = combineReducers({
  loanIndex,
  loanNewWizard,
  loanShow,
});

export { loanReducers };
