import { useContext, useState } from "react";

import { IconPage } from ".";
import { CloseOutlined, ErrorOutline } from "@mui/icons-material";
import { Clock, ClockRefresh, Loader, WizardStepperContext } from "@unchained/component-library";
import { capitalize } from "lodash";
import { Navigate, useParams } from "react-router-dom";

import { AccountSwitcher } from "Components/Layout/Nav/AccountSwitcher";
import { Link } from "Components/Link";
import { OnboardingGoal, simplifiedOrgStates } from "Routes/onboard/(utils)";
import { GetAccount, useGetOrg, useIraOrg, withAccount } from "Shared/api";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg, COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

/**
 * This component is used by all 4 onboarding flows (basic, indiv, biz, IRA)
 * to handle all post-submission states. It handles both camel-cased (IRA) and other orgs,
 * and displays icons/messaging depending on org state. Meanwhile, it polls for updates.
 */
const AwaitApprovalBase = ({
  org,
  type,
  memberships,
}: {
  org: CompleteOrg | IraOrg;
  type: OnboardingGoal;
} & Partial<GetAccount>) => {
  // This function "flattens" advanced/basic state into a set of equivalent helper booleans
  const { pendingApproval, denied, approved, resubmittable } = simplifiedOrgStates(org, type);
  const deniedReason =
    "iraPlan" in org ? org.deniedReason : (org as { denied_reason: string }).denied_reason;
  const { uuid: paramUuid } = useParams<{ uuid: string }>();
  const uuid = paramUuid || (org as { uuid: string }).uuid;
  const query = type === COMPLETE_ORG_ACCOUNT_TYPE.IRA ? useIraOrg : useGetOrg;
  const [requests, setRequests] = useState<number>(0);
  const { goToNext, goToFirstReadyStep } = useContext(WizardStepperContext);
  const { showErrorToast } = useEasyToasts();

  // Refetch 4 times (3 sec interval), then display a pending message if relevant state
  // hasn't changed.
  const getOrg = query(uuid, {
    refetchInterval: 3000,
    onSuccess: () => setRequests(r => r + 1),
    onError: error => showErrorToast(error),
    enabled: requests < 4 && pendingApproval,
  });

  const noun = type === OnboardingGoal.BASIC ? "profile" : "application";

  if (getOrg.isError) {
    showErrorToast(getOrg.error);
    return <Navigate to="/home" />;
  }

  if (pendingApproval && requests >= 4) {
    return (
      <IconPage
        title={`Your ${noun} is pending approval`}
        subtitle="We'll notify you when review is complete."
        floatingIconProps={{ Icon: Clock, color: "primary" }}
        textContent={
          <div className="flex flex-col gap-7">
            {type === OnboardingGoal.BASIC ? (
              <p>
                We're processing your application and will notify you when it's complete.{" "}
                <Link to="/logout">Sign out.</Link>
              </p>
            ) : (
              <p>
                While we process your application, you can still access your other Unchained
                accounts
                {type === OnboardingGoal.INDIVIDUAL ? (
                  <>
                    {" "}
                    or <Link to="/home">visit your dashboard</Link>
                  </>
                ) : null}
                :
              </p>
            )}
          </div>
        }
      >
        <div className="mt-4 flex w-full max-w-xs justify-center">
          <AccountSwitcher direction="up" />
        </div>
      </IconPage>
    );
  }

  if (resubmittable) {
    return (
      <IconPage
        title="Resubmission requested"
        subtitle={
          deniedReason
            ? "Please see below for more information."
            : `Please review your ${noun} for errors and resubmit.`
        }
        floatingIconProps={{ Icon: ClockRefresh, color: "yellow" }}
        textContent={deniedReason ? <div className="prose">{deniedReason}</div> : null}
        button={
          type === OnboardingGoal.BASIC
            ? { children: "Review profile", onClick: goToFirstReadyStep }
            : null
        }
      />
    );
  } else if (denied) {
    const deniedReasonMessage = deniedReason ? (
      <>Please see below for more information.</>
    ) : (
      <>Please contact support if you have any questions.</>
    );

    return (
      <IconPage
        title={`${capitalize(noun)} denied`}
        subtitle={
          <>
            <p>{deniedReasonMessage}</p>
            {type === OnboardingGoal.INDIVIDUAL ? (
              <p>
                <Link to="/home">Return to your dashboard</Link> or access your other Unchained
                accounts:
              </p>
            ) : null}
          </>
        }
        floatingIconProps={{ Icon: CloseOutlined, color: "red" }}
        textContent={deniedReason ? <div className="prose">{deniedReason}</div> : null}
      >
        <div className="mt-4 flex w-full max-w-sm justify-center">
          <AccountSwitcher direction="up" />
        </div>
      </IconPage>
    );
  } else if (approved) {
    const createAccountNext = type === OnboardingGoal.BASIC && memberships.length === 0;
    const button = createAccountNext
      ? { children: "Create your account", onClick: goToNext }
      : { children: "Continue to Unchained", to: "/home" };

    return (
      <IconPage
        title={`Your ${noun} has been approved`}
        subtitle={
          createAccountNext ? "Proceed to create an account." : "Proceed to your dashboard."
        }
        button={button}
      />
    );
  } else if (pendingApproval || getOrg.isFetching) {
    return <Loader text="Verifying your information" />;
  }

  // Should never get here
  console.error("Invalid state waiting for application approval", { org, type });
  return (
    <IconPage
      title="Something went wrong"
      subtitle="Please refresh the page."
      floatingIconProps={{ Icon: ErrorOutline, color: "red" }}
      textContent={
        <div className="flex flex-col gap-7">
          <p>
            If the problem persists, please{" "}
            <Link to="mailto:help@unchained.com">contact support</Link>. Meanwhile, you can still
            access your other accounts.
          </p>
        </div>
      }
    >
      <div className="mt-4 flex w-full max-w-xs justify-center">
        <AccountSwitcher direction="up" />
      </div>
    </IconPage>
  );
};

export const AwaitApproval = withAccount(AwaitApprovalBase);
