import React, { createContext, Dispatch, useContext, useEffect, useReducer } from "react";

import { useBreakpoint } from "@unchained/component-library";

import {
  NavAction,
  NavActionType,
  setDrawerOpenAction,
  setOpenSectionAction,
} from "./NavigationActions";

export type CollapsibleSection = "admin" | "arbiter" | "accountSettings" | "keys" | "delegate";
interface NavState {
  drawerOpen: boolean;
  openSection: CollapsibleSection | null;
}

const NavigationContextState = createContext<NavState | null>(null);
const NavigationContextDispatch = createContext<Dispatch<NavAction> | null>(null);

const navReducer = (state: NavState, action: NavAction) => {
  switch (action.type) {
    case NavActionType.SET_DRAWER_OPEN: {
      const { drawerOpen } = action.payload;
      return { ...state, drawerOpen };
    }
    case NavActionType.SET_OPEN_SECTION: {
      const { section } = action.payload;
      return { ...state, openSection: section };
    }
    default: {
      return state;
    }
  }
};

const initialNavState: NavState = {
  drawerOpen: false,
  openSection: "admin", // Start admin open. For non-admins, this won't be noticeable
};

export const useNavigationState = () => {
  const context = useContext(NavigationContextState);

  if (context === undefined) {
    throw new Error("useNavigationState was used outside of its provider");
  }

  return context;
};

export const useNavigationDispatch = () => {
  const dispatch = useContext(NavigationContextDispatch);

  if (dispatch === undefined) {
    throw new Error("useNavigationDispatch was used outside of its provider");
  }

  const setDrawerOpen = (drawerOpen: boolean) => setDrawerOpenAction(dispatch, drawerOpen);

  const setOpenSection = (section: CollapsibleSection | null) =>
    setOpenSectionAction(dispatch, section);

  return { setDrawerOpen, setOpenSection };
};

export const NavigationProvider = ({ initialState = initialNavState, children }) => {
  const [state, dispatch] = useReducer(navReducer, initialState);
  const { widthIsBelow } = useBreakpoint();
  const { drawerOpen } = state;

  // We want to show the top bar/slide out menu on xs and sm
  const isMobile = widthIsBelow("md");

  useEffect(() => {
    if (drawerOpen && !isMobile) {
      dispatch({ type: NavActionType.SET_DRAWER_OPEN, payload: { drawerOpen: false } });
    }
  }, [isMobile, drawerOpen]);

  return (
    <NavigationContextState.Provider value={state}>
      <NavigationContextDispatch.Provider value={dispatch}>
        {children}
      </NavigationContextDispatch.Provider>
    </NavigationContextState.Provider>
  );
};
