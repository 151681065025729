import { bitcoinsToSatoshis, satoshisToBitcoins } from "@caravan/bitcoin";
import { createSelector } from "reselect";

import { getVaultStatus } from "Models/get_vault_status";
import { Vault } from "Models/vaults";
import { currentPriceSelector } from "Redux/selectors/priceSelectors";
import {
  cryptoToFiat,
  cryptoToFiatPartial,
  cryptoWithUnit,
  fiatToCryptoPartial,
  fiatWithUnit,
  shortCrypto,
  shortCryptoWithUnit,
} from "Utils/currency";
import { formatCurrency } from "Utils/strings";

// get the price from the store
export const vaultSelector = state => state.vaults.vaultShow.vault;
export const vaultUnitSelector = state => state.vaults.vaultShow.vault.unit;
const vaultUnconfirmedBalanceSelector = state => state.vaults.vaultShow.vault.pending_balance;
const vaultDepositedBalanceSelector = state => state.vaults.vaultShow.vault.deposited_balance;
export const vaultWalletUtxosCountSelector = state =>
  state.vaults.vaultShow.vault.wallet.deposited_utxo_count;

export const getBuyBitcoinDefaultVault = state => {
  const vault = state.vaults.vaultShow.vault;
  const defaultVault = vault.uuid ? { name: vault.name, id: vault.uuid } : null;
  return defaultVault;
};

export const cryptoToFiatVaultSelector = createSelector(
  vaultUnitSelector,
  currentPriceSelector,
  cryptoToFiatPartial
);

export const fiatToCryptoVaultSelector = createSelector(
  vaultUnitSelector,
  currentPriceSelector,
  fiatToCryptoPartial
);

export const confirmedVaultFiatSelector = createSelector(
  vaultDepositedBalanceSelector,
  vaultUnitSelector,
  currentPriceSelector,
  (crypto, unit, prices) => {
    if (crypto >= 0 && prices && prices[unit]) {
      return Number(formatCurrency(cryptoToFiat(crypto, unit, prices)));
    } else {
      return null;
    }
  }
);

export const confirmedVaultFiatDisplayedSelector = createSelector(
  vaultDepositedBalanceSelector,
  vaultUnitSelector,
  currentPriceSelector,
  (crypto, unit, prices) => {
    if (crypto && prices && prices[unit]) {
      return `$${formatCurrency(cryptoToFiat(crypto, unit, prices))}`;
    }
    return "";
  }
);

/**
 * @description pulls unconfirmed balance of inputs and outputs from the vault
 * @returns {string} value in satoshis
 */
export const unconfirmedBalanceSatsSelector = createSelector(
  vaultUnconfirmedBalanceSelector,
  (unconfirmedBalance = 0) => bitcoinsToSatoshis(unconfirmedBalance)
);

/**
 * @description given the unconfirmed balance, and current confirmed balance
 * calculate what the current pending balance is. If there is no unconfirmed amount
 * then pending balance is zero. If there is a value then our pending amount is
 * the total unconfirmed minus the deposited/confirmed amount which accounts for change.
 * Note that we return a string to indicate that they shouldn't be used in arithmetic.
 * These are really just a different representation of a base unit (cents or satoshis),
 * and performing arithmetical operations on them as floats can cause errors. String
 * decimals are also ready for conversion back to a BigNumber when needed.
 * @returns {string} total pending balance change
 */
export const pendingBalanceChangeSelector = createSelector(
  unconfirmedBalanceSatsSelector,
  satoshisToBitcoins
);

export const pendingBalanceChangeWithUnitSelector = createSelector(
  pendingBalanceChangeSelector,
  vaultUnitSelector,
  (additionalPendingBalance, unit) => {
    if (
      typeof additionalPendingBalance === "number" ||
      typeof additionalPendingBalance === "string"
    ) {
      return cryptoWithUnit(additionalPendingBalance, unit);
    } else {
      return "";
    }
  }
);

export const getFormattedVault = createSelector(
  vaultSelector,
  confirmedVaultFiatDisplayedSelector,
  pendingBalanceChangeSelector,
  pendingBalanceChangeWithUnitSelector,
  currentPriceSelector,
  getBuyBitcoinDefaultVault,
  (
    vault,
    confirmedFiatWithUnit,
    prices,
    additionalPendingBalance,
    additionalPendingBalanceWithUnit,
    buyBitcoinDefaultVault
  ) => {
    const cryptoAmount = shortCrypto(vault.deposited_balance);

    const cryptoDisplayed = shortCryptoWithUnit(vault.deposited_balance, vault.unit);

    const fiatAmount = cryptoToFiat(vault.deposited_balance, vault.unit, prices);

    const fiatDisplayed = fiatWithUnit(fiatAmount);
    const custodyType =
      vault.wallet_type === "multiorg" ? "Multi-institution" : "Client-controlled";
    const currentStatus = vault.state ? getVaultStatus(vault.state) : "";
    return {
      ...Vault(vault, prices),
      currentWalletUuid: vault.current_wallet_uuid,
      additionalPendingBalance,
      additionalPendingBalanceWithUnit,
      cryptoAmount,
      cryptoDisplayed,
      fiatAmount,
      fiatDisplayed,
      custodyType,
      currentStatus,
      confirmedFiatWithUnit,
      buyBitcoinDefaultVault,
    };
  }
);
