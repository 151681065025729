import { Dispatch } from "react";

import { CollapsibleSection } from "./NavigationContext";

export enum NavActionType {
  SET_DRAWER_OPEN = "SET_DRAWER_OPEN",
  SET_OPEN_SECTION = "SET_OPEN_SECTION",
}

type SetDrawerOpenAction = {
  type: NavActionType.SET_DRAWER_OPEN;
  payload: { drawerOpen: boolean };
};

type SetOpenSectionAction = {
  type: NavActionType.SET_OPEN_SECTION;
  payload: { section: CollapsibleSection | null };
};

export type NavAction = SetDrawerOpenAction | SetOpenSectionAction;

export const setDrawerOpenAction = (dispatch: Dispatch<NavAction>, drawerOpen: boolean) =>
  dispatch({ type: NavActionType.SET_DRAWER_OPEN, payload: { drawerOpen } });

export const setOpenSectionAction = (
  dispatch: Dispatch<NavAction>,
  section: CollapsibleSection | null
) => dispatch({ type: NavActionType.SET_OPEN_SECTION, payload: { section } });
