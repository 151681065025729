import { useQuery } from "react-query";

import { IRAPlansAPI } from "..";

export const iraPlansV2QueryKeys = {
  getAgreement: (id: string) => ["orgs-v2", id] as const,
} as const;

export const useIraAgreement = (id: string) =>
  useQuery(iraPlansV2QueryKeys.getAgreement(id), () => IRAPlansAPI.GetAgreement(id));
