import React from "react";

import { useFormikContext } from "formik";

import { USD } from "Contexts/BuyBitcoin";
import { Fee } from "Contexts/BuyBitcoin/types";
import { formatCurrency } from "Utils/strings";

import { TradingFeesTooltip } from "../../components/TradingFeesTooltip";
import { calculateFeeTotal, getFeeRatePctFromFeesForUsdPurchaseAmount } from "../../helpers";
import { useAmountOfInverseCurrency } from "../../hooks";
import { BuyBitcoinValues } from "./BuyBitcoinForm";
import styles from "./TradingFeeAmount.module.scss";
import { TransactionAmountSubtext } from "./TransactionAmountSubtext";

type TradingFeeAmountProps = {
  fees: Fee[];
  bitcoinPrice: string;
};
/**
 * Component that displays a trading fee amount in usd.
 * @returns {React.Component}
 */
export const TradingFeeAmount = ({ fees, bitcoinPrice }: TradingFeeAmountProps) => {
  const {
    values: { amount, currency },
    errors,
  } = useFormikContext<BuyBitcoinValues>();

  const inverseOfCurrentAmount = useAmountOfInverseCurrency(currency, amount, bitcoinPrice);
  const isValidPurchaseAmount = !errors.amount;
  const usdAmount = currency === USD ? amount : inverseOfCurrentAmount;
  const feeRatePct = getFeeRatePctFromFeesForUsdPurchaseAmount(fees, usdAmount);
  const feeCost = calculateFeeTotal(usdAmount, feeRatePct);

  // TODO: We always indicate that the fee amount displayed here is an estimate,
  // because the amount computed here (using getFeeRatePctFromFeesForUsdPurchaseAmount()
  // and calculateFeeTotal()) is not authoritative, and may not use the exact same logic
  // as the authoritative version calculated in cosimo -- the biggest potential source
  // of differences is likely due to boundary conditions when the purchase amount is
  // close to the boundary between two fee rate brackets. Getting them to match
  // exactly would either require duplicating significant logic in the frontend (and
  // having the danger of them diverging in the future), or getting the amount directly
  // from cosimo via an additional API request, which would also require a significant
  // amount of rework of these components (and the backend).
  const feePrefix = "~";

  const feeCostFormatted =
    feeCost && isValidPurchaseAmount ? `${feePrefix}$${formatCurrency(feeCost)}` : "---";

  return (
    <TransactionAmountSubtext
      isValidAmount={isValidPurchaseAmount}
      label={"Fee"}
      text={feeCostFormatted}
      suffixChild={<TradingFeesTooltip infoIconClass={styles.icon} fees={fees} />}
    />
  );
};
