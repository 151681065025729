/* global trackEvent ga */
import { API } from "./api";

export class ClientErrorAPI {
  static reportRenderError = async (error: Error, info?: unknown) => {
    const data = {
      message: error && error.message,
      stack: error && error.stack,
      url: window.location.toString(),
      info: info,
      errorType: "render_error",
    };
    const response = await API.Post(`/client_error`, data);
    return response.data;
  };

  static reportDeviceError = async (error, contextInfo) => {
    const info = {
      ...contextInfo,
      errorType: error.errorType,
      response: error.response,
      message: error.message,
      deviceError: error.deviceError,
    };
    const data = {
      message: error && error.message,
      stack: error && error.stack,
      url: window.location.toString(),
      info: info,
      errorType: "device_error",
    };
    const response = await API.Post(`/client_error`, data);
    return response.data;
  };
}
