import React from "react";

import { AppModalManager, CLOSE_MODAL, OPEN_MODAL } from "./AppModalManager";

class ModalContainerBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: null,
      isOpen: false,
    };
  }

  onBackButtonEvent = async e => {
    const { confirmBack } = this.props;
    if (confirmBack) {
      let confirmClose = window.confirm("Do you want to go back without submitting details?");
      if (confirmClose) {
        AppModalManager.close();
      }
    } else {
      AppModalManager.close();
    }
  };

  componentDidMount() {
    AppModalManager.watch(OPEN_MODAL, (component, isClearQueryParams) => {
      if (!this.state.isOpen) {
        if (isClearQueryParams) {
          window.history.pushState(null, "", window.location.pathname);
        }
        window.addEventListener("popstate", this.onBackButtonEvent);
      }
      this.setState({ component: component(), isOpen: true });
    });

    AppModalManager.watch(CLOSE_MODAL, () => {
      window.removeEventListener("popstate", this.onBackButtonEvent);
      this.setState({ component: null, isOpen: false });
    });
  }

  render() {
    const Component = this.state.component;
    if (Component) {
      return <Component />;
    }
    return null;
  }
}

export const ModalContainer = ModalContainerBase;
