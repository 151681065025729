import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  redeem_script: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SLICE/SET/REDEEM_SCRIPT":
      return updatedField(state, action, "redeem_script");
    case "SLICE/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "SLICE/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "SLICE/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
