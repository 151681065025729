import React, { Fragment } from "react";

import { Link } from "Components/Link";
import { useMemoizedState } from "Redux/selectors/hooks";

import { VaultIcon } from "../../Icons/VaultIcon";
import { VaultLabel } from "../Labels";
import { vaultAdminURL, vaultURL } from "../Links";

const IconAndLink = ({ Icon, name, uuid, url, iconProps = {}, linkProps = {} }) => {
  return (
    <Fragment>
      <Icon {...iconProps} />
      <Link style={{ display: "inline-block" }} to={url} target="_blank" {...linkProps}>
        <VaultLabel vault={{ uuid, name }} />
      </Link>
    </Fragment>
  );
};

const VaultLink = ({ uuid, name = "Unnamed vault", iconProps = {}, linkProps = {} }) => {
  const orgType = useMemoizedState("account.orgs.current.type");
  const isUnchained = orgType === "unchained";

  if (typeof uuid !== "string" && !uuid) {
    return null;
  }

  return (
    <IconAndLink
      url={isUnchained ? vaultAdminURL({ uuid }) : vaultURL({ uuid })}
      Icon={VaultIcon}
      iconProps={iconProps}
      linkProps={linkProps}
      uuid={uuid}
      name={name}
    />
  );
};

export { VaultLink };
