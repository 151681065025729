import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  bip32_path: "",
  bip32_node: {},
  errors: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "WALLET/EXPORT/SET/BIP32_PATH":
      return updatedField(state, action, "bip32_path");

    case "WALLET/EXPORT/SET/BIP32_NODE":
      return updatedField(state, action, "bip32_node");

    case "WALLET/EXPORT/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "WALLET/EXPORT/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "WALLET/EXPORT/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
