import { EventEmitter } from "events";

import { Logger } from "../../logging/Logger";

export const OPEN_MODAL = "open";
export const CLOSE_MODAL = "close";

class AppModalManagerSingleton extends EventEmitter {
  watch = this.on;

  open = (component, isClearQueryParams = true) => {
    this.emit(OPEN_MODAL, () => component, isClearQueryParams);
  };

  close = () => {
    Logger.log("Modal closed");
    this.emit(CLOSE_MODAL);
  };
}
export const AppModalManager = new AppModalManagerSingleton();
