import { CamelCasedProperties } from "type-fest";

type Person = { first_name?: string; middle_name?: string; last_name?: string };
export const fullName = (person: Person | CamelCasedProperties<Person>, skipMiddle = false) => {
  let names = [];
  if ("first_name" in person) {
    names = [person.first_name, !skipMiddle && person.middle_name, person.last_name];
  } else if ("firstName" in person) {
    names = [person.firstName, !skipMiddle && person.middleName, person.lastName];
  }

  return names.filter(Boolean).join(" ");
};
