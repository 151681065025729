import { updatedField } from "Utils/errors";

function updatedSignature(state, action) {
  return {
    ...state,
    ...{
      signing_as: {
        ...state.signing_as,
        ...{
          signature: action.signature,
        },
      },
    },
  };
}

function deletedSignature(state) {
  return {
    ...state,
    ...{
      signing_as: {
        ...state.signing_as,
        ...{
          signature: null,
        },
      },
    },
  };
}

var initialState = {
  signing_as: {},
  advanced: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SPEND/SET/SIGNING_AS":
      return updatedField(state, action, "signing_as");
    case "SPEND/SET/ADVANCED":
      return updatedField(state, action, "advanced");

    case "SPEND/SET/SIGNATURE":
      return updatedSignature(state, action);
    case "SPEND/DELETE/SIGNATURE":
      return deletedSignature(state);

    default:
      return state;
  }
};
