import React from "react";

import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import { humanState } from "Utils/states";
import { parseTimestamp, readableTime } from "Utils/time";

export function signershipProptypes() {
  return {
    state: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,

    check_enabled: PropTypes.bool.isRequired,
    last_checked_at: PropTypes.string.isRequired,
    days_since_last_check: PropTypes.number.isRequired,

    is_shared: PropTypes.bool.isRequired,
    original: PropTypes.bool.isRequired,
    usable: PropTypes.bool.isRequired,

    partner_username: PropTypes.string,
    username: PropTypes.string.isRequired,

    lastChecked: PropTypes.string.isRequired,
    humanState: PropTypes.string.isRequired,
  };
}

export function Signership(data) {
  if (data == null) {
    return data;
  }

  // export function Signership(d) {
  //   if (d == null) { return d; }
  //   let data = cloneDeep(d);

  data.humanState = humanState(data);
  if (data.last_checked_at) {
    data.lastCheckedAt = parseTimestamp(data.last_checked_at);
    data.readableLastCheckedAt = readableTime(data.lastCheckedAt);
  }
  if (data.days_since_last_check !== undefined) {
    data.lastChecked =
      data.days_since_last_check === 0 ? "today" : `${data.days_since_last_check} days ago`;
  }
  return data;
}
