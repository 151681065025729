import React, { useContext, useEffect, useMemo, useState } from "react";

import { KEYSTORES } from "@caravan/wallets";
import { AddressString, ModalContent, ModalFooter } from "@unchained/component-library";

import { InstructionTable } from "Components/Shared/Elements/InstructionList";
import { handleDeviceError } from "Components/SigningDevices/DeviceErrorHandling";
import { useGetWalletConfig } from "Shared/api";
import { ErrorToastOptions, useErrorToast } from "Utils/toasts";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";
import { useGetConfirmAddressInteraction } from "../hooks";

export const ConfirmOnTrezorStep = () => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const [interactionInitialized, setInteractionInitialized] = useState(false);
  const { handleNext, accountKey, address, walletUuid, relativeBip32Path } =
    useContext(ConfirmOnDeviceContext);
  const walletConfigQuery = useGetWalletConfig(walletUuid);
  const interaction = useGetConfirmAddressInteraction({
    keystore: KEYSTORES.TREZOR,
    walletUuid,
    xfp: accountKey.xfp,
    relativeBip32Path,
  });
  const showErrorToast = useErrorToast();

  useEffect(() => {
    const runInteraction = async () => {
      if (!interaction || interactionInitialized) return null;
      setInteractionInitialized(true);
      try {
        const result = await interaction.run();
        if (result.address !== address) {
          showErrorToast(null, {
            title: "Address mismatch",
            description:
              "Something appears to be wrong with the addresses. Please check the device and try again.",
          });
        } else {
          setNextDisabled(false);
        }
      } catch (e) {
        if (e.message.includes("Device call in progress")) return;
        const cleanMessage = handleDeviceError(e);
        const error: ErrorToastOptions = {
          persist: false,
          title: "Something went wrong.",
          description: cleanMessage,
        };
        showErrorToast(e, error);
      }
    };
    runInteraction();
  }, [address, interaction, interactionInitialized, showErrorToast]);

  const rows = useMemo(() => {
    const { quorum } = walletConfigQuery.data;
    return [
      {
        header: "Key name",
        content: accountKey.name,
      },
      {
        header: "Quorum type",
        content: quorum ? `Multisig ${quorum.requiredSigners} of ${quorum.totalSigners}` : "",
      },
      {
        header: "Address",
        content: <AddressString address={address} />,
      },
    ];
  }, [accountKey.name, address, walletConfigQuery.data]);

  return (
    <>
      <ModalContent>
        <InstructionTable rows={rows} />
      </ModalContent>
      <ModalFooter actions={[{ children: "Next", onClick: handleNext, disabled: nextDisabled }]} />
    </>
  );
};
