import moment from "moment";

import { getConfig } from "Utils/config";
import { updatedField } from "Utils/errors";

export enum loanOriginatorsEnum {
  leadBank = "lead_bank",
  unchained = "unchained",
}

var initialState = {
  status: "new",
  errors: {},
  collateral: "",
  principal: "",
  starts_on: moment.utc().add(7, "days"),
  lending_price: "",
  get ltv_percentage() {
    return getConfig("loan_terms")["ltv_ratio"] * 100;
  },
  get interest_percentage() {
    return Math.round(getConfig("loan_terms")["interest_rate"] * 100);
  },
  get fee_percentage() {
    return getConfig("loan_terms")["fee_rate"] * 100;
  },
  get term() {
    return getConfig("loan_terms")["billing_period"] * 3;
  },
  apr: "",
  loan_type: "consumer",
  originator: loanOriginatorsEnum.leadBank,
};

export default (state = initialState, action = { type: "", errors: {} }) => {
  switch (action.type) {
    case "LOAN_APPROVE/SET/COLLATERAL":
      return updatedField(state, action, "collateral");
    case "LOAN_APPROVE/SET/PRINCIPAL":
      return updatedField(state, action, "principal");
    case "LOAN_APPROVE/SET/STARTS_ON":
      return updatedField(state, action, "starts_on");
    case "LOAN_APPROVE/SET/LENDING_PRICE":
      return updatedField(state, action, "lending_price");
    case "LOAN_APPROVE/SET/LTV_PERCENTAGE":
      return updatedField(state, action, "ltv_percentage");
    case "LOAN_APPROVE/SET/INTEREST_PERCENTAGE":
      return updatedField(state, action, "interest_percentage");
    case "LOAN_APPROVE/SET/FEE_PERCENTAGE":
      return updatedField(state, action, "fee_percentage");
    case "LOAN_APPROVE/SET/TERM":
      return updatedField(state, action, "term");
    case "LOAN_APPROVE/SET/LOAN_TYPE":
      return updatedField(state, action, "loan_type");
    case "LOAN_APPROVE/SET/APR":
      return updatedField(state, action, "apr");
    case "LOAN_APPROVE/SET/ORIGINATOR":
      return updatedField(state, action, "originator");

    case "LOAN_APPROVE/APPROVE_REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "LOAN_APPROVE/APPROVE_REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors || {} };
    case "LOAN_APPROVE/APPROVE_REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
