import { ReactNode } from "react";

import { Table, TableBody } from "@mui/material";
import cn from "classnames";
import { capitalize } from "lodash";

import { countryStateData } from "Components/FormFields/FormikAddressForm/CountryStateData";
import { DocLabel } from "Shared/api/hooks";
import { Address } from "Specs/v1/getOrg/200";
import { sentenceCase } from "Utils/strings";

import styles from "./SummaryTable.module.scss";
import { SummaryTextRow } from "./SummaryTextRow";

export type SummaryRowDatum = {
  title: string;
  value: ReactNode;
  // If given, value will be masked
  maskedValue?: string;
  // if true, value will be copyable
  copyable?: boolean;
  download?: {
    label: DocLabel;
    orgUuid?: string;
    principalUuid?: string;
    onDownload?: () => void;
  };
};

// Present a variety of value types as a human-readable string
const printValue = (val: any) => {
  if (val == null) return "";
  if (typeof val === "string") return val;
  if (typeof val === "boolean") return capitalize(val.toString());
  return JSON.stringify(val);
};

/** Creates default section row data, with copyable true and a sentence-case title constructed from the key  */
export function summaryRowDatum<T>(
  object: T,
  key: keyof T,
  other: Partial<SummaryRowDatum> = {}
): SummaryRowDatum {
  const val = object[key];
  return {
    value: printValue(val),
    title: sentenceCase(key.toString()),
    copyable: true,
    ...other,
  };
}

export const notCopyable = (rows: SummaryRowDatum[]) =>
  rows.map(row => ({ ...row, copyable: false }));

export const SummaryTable = ({
  rows,
  className,
}: {
  rows: SummaryRowDatum[];
  className?: string;
}) => (
  <Table className={cn(styles.summaryTable, className)}>
    <TableBody>
      {rows
        .filter(r => r)
        .map(({ value, title, ...other }, index) => (
          <SummaryTextRow key={[title, value, index].join("")} title={title} {...other}>
            {value as string}
          </SummaryTextRow>
        ))}
    </TableBody>
  </Table>
);

export const AddressSummaryTable = ({ address }: { address: Partial<Address> }) => {
  const country = countryStateData.getCountry(address.country);

  return (
    <SummaryTable
      rows={[
        {
          title: "Country",
          value: country?.name || address.country,
        },
        {
          title: "State",
          value: countryStateData.getRegion(address.country, address.state)?.name || address.state,
        },
        ...["city", "zip"].map(field => ({
          title: sentenceCase(field),
          value: address[field],
        })),
        {
          title: "Street address",
          value: address.line_1,
        },
        {
          title: "Street address line 2",
          value: address.line_2,
        },
      ].filter(r => r.value)}
    />
  );
};
