import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import classNames from "classnames";

import styles from "./ExploreBlockChain.module.scss";

export type ExploreBlockChainClasses = {
  div?: string;
  button?: string;
  icon?: string;
  text?: string;
};
export const ExploreBlockChain = ({
  item,
  url,
  showItem = true,
  classes = {},
}: {
  item: string;
  url: string;
  showItem: boolean;
  classes?: ExploreBlockChainClasses;
}) => {
  return (
    <div className={classes.div || styles.txid}>
      {showItem && <code className={classNames("break-words", classes.text)}>{item}</code>}
      <IconButton
        href={url}
        target="_blank"
        classes={{ root: classes.button || styles.button }}
        size="large"
      >
        <Search classes={{ root: classes.icon || styles.icon }} />
      </IconButton>
    </div>
  );
};
