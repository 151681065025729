import { ApproveSigRequest200, GetTransactionRequests200, UnapproveSigRequest200 } from "Specs/v1";
import { GetTransactionRequestsQueryParams } from "Specs/v1/getTransactionRequests/params/query";
import { capitalize } from "Utils/strings";

import { API } from "./api";

export class TransactionAPI {
  static UpdateReturnAddress = async (uuid, data) => {
    await API.Post(`/loans/${uuid}/return_address`, data);
  };

  static AddSignatureToSignatureRequest = async (data, uuid) => {
    const response = await API.Post(`/btc_tx_sig_requests/${uuid}/sign`, data);
    return response.data;
  };

  static QASign = async (data, uuid) => {
    const response = await API.Post(`/btc_tx_sig_requests/${uuid}/qasign`, data);
    return response.data;
  };

  static AddSignature = async (data, type, uuid, transactionType) => {
    const response = await API.Put(`/${type}s/${uuid}/${transactionType}/sign`, data);
    return response.data;
  };

  static BroadcastTransactionRequest = async uuid => {
    const response = await API.Post(`/btc_transaction_requests/${uuid}/broadcast`, {});
    return response.data;
  };

  /**
   * Deletes an initiated spending operation
   * @param type - accountType, enum: ['vault', 'loan']
   * @param uuid - unique uuid for the type
   * @param operationUUID - operation uuid currently in progress, @enum: ['transaction', 'redemption', 'liquidation']
   * @param operation - string for the operation type
   */

  static DeleteTransaction = async (type, uuid, operationUUID, operation) => {
    await API.Delete(`/${type}/${uuid}/${operation}/${operationUUID}`);
    return `${capitalize(operation)} successfully deleted`;
  };

  static ListBtcTransactionRequests = async (
    orgUUID,
    queryParams: GetTransactionRequestsQueryParams = {}
  ) => {
    const response = await API.Get<GetTransactionRequests200>(
      `/orgs/${orgUUID}/btc_transaction_requests`,
      queryParams,
      { explodeQueryParams: true }
    );

    return response.data;
  };

  static GetBtcTxSigRequestUnsignedPSBT = async (uuid, includeXPUBs, endorse) => {
    const params: { endorse?: boolean; include_xpubs?: boolean } = {};
    if (includeXPUBs) {
      params.include_xpubs = true;
    }
    if (endorse) {
      params.endorse = true;
    }
    const response = await API.Get(`/btc_tx_sig_requests/${uuid}/unsigned_psbt`, params);
    return response.data;
  };

  static RequestBtcTxSigRequest = async uuid => {
    const response = await API.Post(`/btc_tx_sig_requests/${uuid}/request`, {});
    return response.data;
  };

  static ApproveBtcTxSigRequest = async uuid => {
    const response = await API.Post<ApproveSigRequest200>(
      `/btc_tx_sig_requests/${uuid}/approve`,
      {}
    );
    return response.data;
  };

  static UnapproveBtcTxSigRequest = async uuid => {
    const response = await API.Post<UnapproveSigRequest200>(
      `/btc_tx_sig_requests/${uuid}/unapprove`,
      {}
    );
    return response.data;
  };
}
