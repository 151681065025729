import React from "react";

import { AddressString } from "@unchained/component-library";

export const AddressCell = ({ cell }) => {
  const cellData = cell.getValue();
  return (
    <AddressString
      className={`${cellData.error ? "!text-red-500" : ""}`}
      address={cellData.value}
      bolded={false}
    />
  );
};
