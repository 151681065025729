import PropTypes from "prop-types";

import { longCrypto } from "Utils/currency";
import { humanState } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

import { SliceMember } from "./slice_members";
import { Spend, spendProptypes } from "./spends";

export function sliceProptypes(admin, required) {
  return {
    state: required ? PropTypes.string.isRequired : PropTypes.string,
    unit: required ? PropTypes.string.isRequired : PropTypes.string,
    unconfirmed_balance: PropTypes.number,
    deposited_balance: PropTypes.number,
    address: PropTypes.string,
    updated_at: required ? PropTypes.string.isRequired : PropTypes.string,
    testnet: required ? PropTypes.bool.isRequired : PropTypes.bool,

    createdAt: required ? PropTypes.string.isRequired : PropTypes.object,
    readableCreatedAt: required ? PropTypes.string.isRequired : PropTypes.string,

    humanState: required ? PropTypes.string.isRequired : PropTypes.string,
    pendingBalanceWithUnit: PropTypes.string,
    confirmedBalanceWithUnit: PropTypes.string,
    additionalPendingWithUnit: PropTypes.string,

    // BTC only
    utxos: PropTypes.array,
    has_confirmed_balance: PropTypes.bool,
    spend: PropTypes.shape(spendProptypes()),
  };
}

function formatBalance(balance, unit) {
  if (balance == null) {
    return unit == null ? "?" : `? ${unit}`;
  } else {
    return unit == null ? formatCurrency(balance, 8) : `${formatCurrency(balance, 8)} ${unit}`;
  }
}

export function Slice(data) {
  if (data == null) {
    return data;
  }
  data.humanState = humanState(data);
  data.pendingBalanceWithUnit = formatBalance(data.pending_balance, data.unit);
  data.confirmedBalanceWithUnit = formatBalance(data.deposited_balance, data.unit);
  data.confirmedBalanceWithoutUnit = longCrypto(data.deposited_balance);
  data.additionalPendingWithUnit = formatBalance(data.unconfirmed_balance, data.unit);
  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);

  if (data.slice_members) {
    data.slice_members = data.slice_members.map(slice_member => SliceMember(slice_member));
  }

  data.value = prices => {
    if (
      data.deposited_balance !== null &&
      data.deposited_balance !== undefined &&
      prices &&
      prices[data.unit] &&
      prices[data.unit].value
    ) {
      return data.deposited_balance * prices[data.unit].value;
    } else {
      return null;
    }
  };

  data.valueWithUnit = prices => {
    let value = data.value(prices);
    if (value !== null) {
      return `$${formatCurrency(value)}`;
    } else {
      return null;
    }
  };

  if (data.spend) {
    data.spend = Spend(data.spend, data);
  }

  return data;
}
