import { ModalContent, ModalFooter } from "@unchained/component-library";
import { Formik, useFormikContext } from "formik";
import { SchemaOf } from "yup";

import {
  BTC,
  updateAmount,
  updateAmountCurrency,
  USD,
  useBuyBitcoinDispatch,
  useBuyBitcoinStore,
} from "Contexts/BuyBitcoin";
import { Currency, DestinationVault, Fee, TradeAmount } from "Contexts/BuyBitcoin/types";
import { formatCurrency } from "Utils/strings";
import { yup } from "Utils/yup";

import styles from "./BuyBitcoinModal.module.scss";
import { TransactionAmountInput } from "./TransactionAmountInput";

export interface BuyBitcoinValues {
  amount: string;
  currency: Currency;
  max: boolean;
  min: boolean;
}
type BuyBitcoinFormBaseProps = {
  bitcoinPrice: string;
  maximumPurchaseAmount: TradeAmount;
  minimumPurchaseAmount: TradeAmount;
  fees: Fee[];
};
export const BuyBitcoinFormBase = ({
  bitcoinPrice,
  minimumPurchaseAmount,
  maximumPurchaseAmount,
  fees,
}: BuyBitcoinFormBaseProps) => {
  const { errors, isValid, handleSubmit } = useFormikContext<BuyBitcoinValues>();

  return (
    <>
      <ModalContent className={styles.content}>
        <TransactionAmountInput
          inverseCurrencyProps={{ fees }}
          label="Enter amount"
          bitcoinPrice={bitcoinPrice}
          minMaxButtonProps={{
            minimumTransactionAmount: minimumPurchaseAmount,
            maximumTransactionAmount: maximumPurchaseAmount,
          }}
          currencies={[BTC, USD]}
        />
      </ModalContent>
      <ModalFooter
        className={styles.buttonContainer}
        actions={[
          {
            disabled: !isValid || Object.keys(errors).length !== 0,
            type: "primary",
            className: styles.button,
            onClick: handleSubmit,
            children: "Next",
          },
        ]}
      ></ModalFooter>
    </>
  );
};

interface BuyBitcoinFormProps {
  next: () => void;
  defaultVault: DestinationVault;
}

export const BuyBitcoinForm = ({ next, defaultVault }: BuyBitcoinFormProps) => {
  const dispatch = useBuyBitcoinDispatch();
  const {
    amount,
    minimumPurchaseAmount,
    maximumPurchaseAmount,
    amountCurrency,
    bitcoinPrice,
    fees,
  } = useBuyBitcoinStore();

  const handleSubmit = ({ amount, currency, max, min }: BuyBitcoinValues) => {
    let newAmount = amount;
    if (currency === USD) {
      newAmount = Number(newAmount).toFixed(2);
    } else {
      newAmount = Number(newAmount).toFixed(8);
    }

    if (max) {
      newAmount = maximumPurchaseAmount.USD;
    } else if (min) {
      newAmount = minimumPurchaseAmount.USD;
    }

    dispatch(updateAmount(newAmount));
    dispatch(updateAmountCurrency(max || min ? USD : currency));
    next();
  };

  const initialValues = {
    amount: amount !== "" ? amount : null,
    currency: amountCurrency,
  };

  const usdError = `Enter an amount between $${formatCurrency(
    minimumPurchaseAmount.USD
  )} and $${formatCurrency(maximumPurchaseAmount.USD)}`;
  const btcError = `Enter an amount between ${minimumPurchaseAmount.BTC} BTC and ${maximumPurchaseAmount.BTC} BTC`;

  const validationSchema: SchemaOf<BuyBitcoinValues> = yup.object({
    amount: yup
      .string()
      .nullable()
      .when("currency", {
        is: USD,
        then: schema =>
          schema
            .required(usdError)
            .bigNumber()
            .numInRange(
              { min: minimumPurchaseAmount.USD, max: maximumPurchaseAmount.USD },
              usdError
            ),
        otherwise: schema =>
          schema
            .required(btcError)
            .bigNumber()
            .numInRange(
              { min: minimumPurchaseAmount.BTC, max: maximumPurchaseAmount.BTC },
              btcError
            ),
      }),
    currency: yup.mixed().oneOf([USD, BTC]).defined(),
    max: yup.boolean(),
    min: yup.boolean(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      // TODO: This placeholder error is added if amount is empty (initial load)
      // so that the button doesn't flash before the user has entered anything.
      // Fix this as soon as Formik can better handle initial state.
      initialErrors={amount === "" ? { placeholder: "error" } : {}}
    >
      <BuyBitcoinFormBase
        bitcoinPrice={bitcoinPrice}
        maximumPurchaseAmount={maximumPurchaseAmount}
        minimumPurchaseAmount={minimumPurchaseAmount}
        fees={fees}
      />
    </Formik>
  );
};
