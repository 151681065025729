const LAST_ACTIVE_KEY = "UserLastActive";

export const ACTIVE_TIMEOUT = 1000 * 60 * 15;

export function setActive() {
  const now = Date.now();

  localStorage.setItem(LAST_ACTIVE_KEY, now as unknown as string);

  return now;
}

export function lastActive() {
  return localStorage.getItem(LAST_ACTIVE_KEY);
}

export function isActive() {
  const last = lastActive();

  if (!last) {
    return null;
  }

  return Date.now() - (last as unknown as number) < ACTIVE_TIMEOUT;
}

export function setInactive() {
  localStorage.removeItem(LAST_ACTIVE_KEY);

  return true;
}
