export const SET_OUTFLOW_ADDRESS = "SET_OUTFLOW_ADDRESS";
export const SET_OUTFLOW_DESTINATION_ACCOUNT = "SET_OUTFLOW_DESTINATION_ACCOUNT";
export const SET_OUTFLOW_CRYPTO_AMOUNT = "SET_OUTFLOW_CRYPTO_AMOUNT";
export const SET_OUTFLOW_FIAT_AMOUNT = "SET_OUTFLOW_FIAT_AMOUNT";
export const TOGGLE_OUTFLOW_CHOOSING_KEYS = "TOGGLE_OUTFLOW_CHOOSING_KEYS";
export const SET_OUTFLOW_PARTIAL_TRANSACTION = "SET_OUTFLOW_PARTIAL_TRANSACTION";
export const SET_OUTFLOW_MAX_PARTIAL_TRANSACTION = "SET_OUTFLOW_MAX_PARTIAL_TRANSACTION";
export const SET_OUTFLOW_TRANSACTION_TYPE = "SET_OUTFLOW_TRANSACTION_TYPE";
export const SET_OUTFLOW_KEY_1 = "SET_OUTFLOW_KEY_1";
export const SET_OUTFLOW_KEY_2 = "SET_OUTFLOW_KEY_2";
export const SET_OUTFLOW_KEY_3 = "SET_OUTFLOW_KEY_3";
export const SET_OUTFLOW_FEE_RATE = "SET_OUTFLOW_FEE_RATE";
export const CREATE_NEW_OUTFLOW = "CREATE_NEW_OUTFLOW";
export const RESET_OUTFLOW_FLOW = "RESET_OUTFLOW_FLOW";

export const setOutflowAddressAction = address => ({
  type: SET_OUTFLOW_ADDRESS,
  payload: address,
});

export const setOutflowDestinationAccountAction = account => ({
  type: SET_OUTFLOW_DESTINATION_ACCOUNT,
  payload: account,
});

export const setOutflowPartialTransactionAction = partial => ({
  type: SET_OUTFLOW_PARTIAL_TRANSACTION,
  payload: partial,
});

export const setOutflowMaxPartialTransactionAction = max => ({
  type: SET_OUTFLOW_MAX_PARTIAL_TRANSACTION,
  payload: max,
});

export const setOutflowTransactionTypeAction = type => ({
  type: SET_OUTFLOW_TRANSACTION_TYPE,
  payload: type,
});

export const updateOutflowCryptoAmountAction = cryptoAmount => ({
  type: SET_OUTFLOW_CRYPTO_AMOUNT,
  payload: cryptoAmount,
});

export const updateOutflowFiatAmountAction = fiatAmount => ({
  type: SET_OUTFLOW_FIAT_AMOUNT,
  payload: fiatAmount,
});

export const setOutflowKey1Action = fields => ({
  type: SET_OUTFLOW_KEY_1,
  payload: fields,
});

export const setOutflowKey2Action = fields => ({
  type: SET_OUTFLOW_KEY_2,
  payload: fields,
});

export const setOutflowKey3Action = fields => ({
  type: SET_OUTFLOW_KEY_3,
  payload: fields,
});

export const setOutflowFeeRateAction = fee => ({
  type: SET_OUTFLOW_FEE_RATE,
  payload: fee,
});

export const resetOutflowFlowAction = () => ({
  type: RESET_OUTFLOW_FLOW,
  payload: null,
});

export const createNewOutflowAction = outflowData => {
  return async dispatch => {
    dispatch({ type: CREATE_NEW_OUTFLOW, payload: outflowData });
  };
};
