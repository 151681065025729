import React, { useContext, useEffect } from "react";

import { satoshisToBitcoins } from "@caravan/bitcoin";
import Big from "big.js";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { getEstimatedFees } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";
import { FeeRateValidationCategory, useFeeRates, useValidateFeeRate } from "Shared/api/hooks/fees";
import { getConfig } from "Utils/config";
import { BITCOIND_MAX_FEE_SATS } from "Utils/constants";

import { AmountInput } from "./AmountInput";
import { FeesInput } from "./FeesInput";
import { getMaxSpendable } from "./functions";

export const AmountAndFees = () => {
  const vaultBalance: number = useMemoizedState("vaults.vaultShow.vault.balance");
  const depositedUtxCount = useMemoizedState("vaults.vaultShow.vault.wallet.deposited_utxo_count");
  const { recommended } = useFeeRates();
  const { amount, batchData, isBatchSpendFlow, isMaxSpend, feeRate, setState } =
    useContext(WithdrawModalContext);
  const feeRateValidation = useValidateFeeRate(parseInt(feeRate));

  const estimatedFeeSats = getEstimatedFees({
    inputCount: depositedUtxCount || 0,
    feeRate: feeRate || String(recommended), // Assume high range if unset
    isMaxSpend,
    isBatchSpendFlow,
    batchData,
  });
  const estimatedFeeBitcoin = satoshisToBitcoins(estimatedFeeSats);
  const maxFeeExceeded = Big(estimatedFeeSats).gt(BITCOIND_MAX_FEE_SATS);
  const maxSpendable = getMaxSpendable(vaultBalance, estimatedFeeBitcoin, isMaxSpend);
  useEffect(() => {
    // This effect enables the Next button when this wizard step is valid

    // Case: Amount is none, zero, or less than dust
    const DUST_LIMIT = getConfig("wallet.dust_limit.BTC");
    if (amount === "" || Big(amount).eq(0) || Big(amount).lt(DUST_LIMIT)) {
      return;
    }

    // Case: Amount is greater than spendable
    if (Big(amount).gt(maxSpendable)) {
      return;
    }

    // Case: Fee rate is below purge rate
    if (isNaN(parseInt(feeRate)) || feeRateValidation === FeeRateValidationCategory.BELOW_PURGE) {
      return;
    }

    // Everything is okay.
    setState({ nextDisabled: false });
  }, [amount, feeRate, feeRateValidation, maxSpendable, setState]);

  return (
    <>
      <AmountInput estimatedFeeBitcoin={estimatedFeeBitcoin} />
      <FeesInput maxFeeExceeded={maxFeeExceeded} estimatedFeeBitcoin={estimatedFeeBitcoin} />
    </>
  );
};
