import { API_V2 } from "Shared/api/api";
import {
  GetRekeyInfo200,
  GetRekeyTransactionQuotes200,
  GetReplacementKeyCandidates200,
} from "Specs/v2";

export class RekeysAPI {
  static async GetRekeyInfo(accountKeyUuid: string, orgUuid: string) {
    return (
      await API_V2.Get<GetRekeyInfo200>(`/account-keys/${accountKeyUuid}/rekey-info`, { orgUuid })
    ).data;
  }
  static async GetRekeyTransactionQuotes(accountKeyUuid: string) {
    return (
      await API_V2.Get<GetRekeyTransactionQuotes200>(
        `/account-keys/${accountKeyUuid}/rekey-transaction-quotes`
      )
    ).data;
  }
  static async GetReplacementKeyCandidates(accountKeyUuid: string, orgUuid: string) {
    return (
      await API_V2.Get<GetReplacementKeyCandidates200>(
        `/account-keys/${accountKeyUuid}/replacement-key-candidates`,
        { orgUuid }
      )
    ).data;
  }
}
