import { CryptoAPI } from "Shared/api/cryptoApi";
import { Logger } from "Shared/logging/Logger";

export const SET_CRYPTO_PRICES = "SET_CRYPTO_PRICES";
export const SET_BTC_CURRENT_PRICE = "SET_BTC_CURRENT_PRICE";
export const SET_HISTORICAL_CRYPTO_PRICES = "SET_HISTORICAL_CRYPTO_PRICES";

export const setCryptoPricesAction = prices => ({
  type: SET_CRYPTO_PRICES,
  payload: prices,
});

export const setBtcCurrentPriceAction = price => ({
  type: SET_BTC_CURRENT_PRICE,
  payload: price,
});

export const setHistoricalCryptoPricesAction = prices => ({
  type: SET_HISTORICAL_CRYPTO_PRICES,
  payload: prices,
});

export const getCryptoPricesAction = () => {
  return async dispatch => {
    try {
      const prices = await CryptoAPI.GetCryptoPrices();
      dispatch(setCryptoPricesAction(prices));
    } catch (e) {
      Logger.log("Could not retrieve prices");
    }
  };
};

export const getHistoricalCryptoPricesAction = (daysSince = 30) => {
  return async (dispatch, getState) => {
    const existingHistoricalPrices = getState().crypto.prices.historical;
    if (existingHistoricalPrices.BTC.length === 0) {
      const prices = await CryptoAPI.GetHistoricalCryptoPrices(3, daysSince);
      dispatch(setHistoricalCryptoPricesAction(prices));
    }
  };
};
