import React from "react";

import { AccessTime } from "@mui/icons-material";

import { AdvancedProfileModal } from "Components/Dashboard/AdvancedProfileModal";
import { DashboardCard } from "Components/Dashboard/DashboardCard";
import { useNavigate } from "Components/Link";
import { useIsBuyBitcoinFeatureEnabled } from "Components/TradingDesk/hooks";
import {
  TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE,
  TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING,
  useBuyBitcoinStore,
} from "Contexts/BuyBitcoin";
import { OnboardingRequirementStatus } from "Contexts/BuyBitcoin/types";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { featureOn } from "Utils/config";

import { BuyAndSellBitcoinButtons } from "../BuyAndSellBitcoinButtons";
import tradingOnboardingCardStyles from "./TradingOnboardingCard.module.scss";
import styles from "./TradingOnboardingIncompleteCard.module.scss";
import { TradingOnboardingItem } from "./TradingOnboardingItem";

/**
 * Component which displays a card describing steps needed to activate trading.
 * @returns {React.Component}
 */
const TradingOnboardingCardIncomplete = ({ currentOrg: org }: { currentOrg: CompleteOrg }) => {
  const {
    onboardingStatus: { hasVault, isTierThreeProfile, isBankAccountOnFile },
  } = useBuyBitcoinStore();

  const isSellBitcoinFeatureFlagEnabled = featureOn("sell_bitcoin", org) || undefined;

  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();

  const navigate = useNavigate();

  const isIraAccount = org.account_type === "ira";

  const createVaultButtonText = !hasVault ? "START" : "DONE";
  const isTier3ProfileText = () => {
    if (isTierThreeProfile === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING) {
      return <AccessTime className={styles.pendingIcon} />;
    } else if (isTierThreeProfile === TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE) {
      return "DONE";
    } else {
      return "SUBMIT";
    }
  };

  const hasBankAccountText = () => {
    if (isBankAccountOnFile === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING) {
      return <AccessTime className={styles.pendingIcon} />;
    } else if (isBankAccountOnFile === TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE) {
      return "DONE";
    } else {
      return "ADD";
    }
  };

  const onboardingItemLabel = (
    action: string,
    item: string,
    onboardingItemStatus: OnboardingRequirementStatus
  ) => {
    return onboardingItemStatus === TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE
      ? `${action} ${item}`
      : item;
  };

  const numberOfStepsText = isIraAccount ? "a couple of" : "three";

  const tradingDescription = isSellBitcoinFeatureFlagEnabled
    ? `Just ${numberOfStepsText} steps to start buying and selling bitcoin directly from your vaults.`
    : `Just ${numberOfStepsText} steps to start buying bitcoin directly to your vaults.`;

  return (
    <DashboardCard>
      <h1 className={styles.title}>Start trading today!</h1>
      <p className={styles.descriptionText}>
        {tradingDescription}{" "}
        <a href={"https://help.unchained.com/trading-desk-onboard-requirements"} target="_blank">
          View details.
        </a>
      </p>
      <div className={styles.minimumsBanner}>
        <h4 className={styles.minimumsTitle}>New Lower minimums!</h4>
        <p className={styles.minimumsDescriptionText}>Start trading today with just $2,000.</p>
      </div>
      <div className={styles.itemsContainer}>
        <TradingOnboardingItem
          onClick={() => {
            navigate("/vaults/new");
            AppModalManager.close();
          }}
          buttonText={createVaultButtonText}
          completedStatus={hasVault}
          label="Create vault"
          incompletedItemTooltipText="This is where your bitcoin will be stored once a trade is complete."
        />
        <TradingOnboardingItem
          onClick={() => AppModalManager.open(AdvancedProfileModal)}
          buttonText={isTier3ProfileText()}
          completedStatus={isTierThreeProfile}
          label={onboardingItemLabel("Submit", "complete profile", isTierThreeProfile)}
          incompletedItemTooltipText="Provide additional personal information to access trading."
          awaitingCompletionToolTipText="Awaiting complete profile approval. This usually takes less than one business day."
          showAwaitingCompletetionToolTip={
            isTierThreeProfile === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING
          }
          isDisabled={isTierThreeProfile === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING}
        />

        {!isIraAccount && (
          <TradingOnboardingItem
            onClick={() => {
              navigate("/payment-methods?tab=bankAccounts");
              AppModalManager.close();
            }}
            buttonText={hasBankAccountText()}
            completedStatus={isBankAccountOnFile}
            label={onboardingItemLabel("Link", "bank account", isBankAccountOnFile)}
            incompletedItemTooltipText="The bank you will wire funds from needs to be entered."
            awaitingCompletionToolTipText="Awaiting manual bank account verification. This usually takes less than one business day."
            showAwaitingCompletetionToolTip={
              isBankAccountOnFile === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING
            }
            isDisabled={isBankAccountOnFile === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING}
          />
        )}
      </div>
      <BuyAndSellBitcoinButtons
        isTradingFeatureAvailable={isTradingFeatureAvailable}
        showDisableButtonOnNotEnabled={true}
        className={tradingOnboardingCardStyles.buyBitcoinButton}
        sellBitcoinStyles={"ml-3 mt-9"}
        isSellBitcoinFeatureFlagEnabled={isSellBitcoinFeatureFlagEnabled}
      />
    </DashboardCard>
  );
};

export { TradingOnboardingCardIncomplete };
