import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import { AccountKeyListing } from "Models/account_key_listing";
import { Disbursement, disbursementProptypes } from "Models/disbursements";
import { LoanPayment, loanPaymentProptypes } from "Models/loan_payments";
import { MarginCall, marginCallProptypes } from "Models/margin_calls";
import { Prepayment, prepaymentProptypes } from "Models/prepayments";
import { PrincipalPayment } from "Models/principal_payments";
import { User, userProptypes } from "Models/users";
import { fiatWithUnit } from "Utils/currency";
import { stateIs } from "Utils/states";
import { formatCurrency, formatPercentage } from "Utils/strings";
import { parseTimestamp, readableDate, readableTime } from "Utils/time";

import { LoanListing, loanListingProptypes } from "./loan_listing";
import { Slice, sliceProptypes } from "./slices";
import { Wallet, walletProptypes } from "./wallets";

export function loanProptypes(admin) {
  if (admin) {
    return {
      ...loanProptypes(),
      ...{
        user: PropTypes.shape(userProptypes()),
        lender: PropTypes.shape(userProptypes()),

        spread: PropTypes.number,
        spreadPercentage: PropTypes.string,
        margin_callable: PropTypes.bool,
      },
    };
  } else {
    return {
      ...loanListingProptypes(),
      ...{
        original_collateral: PropTypes.number,
        original_principal: PropTypes.number,
        original_apr: PropTypes.number,
        ltv_ratio: PropTypes.number,
        interest_rate: PropTypes.number,
        fee_rate: PropTypes.number,
        term_interest_rate: PropTypes.number,
        term_fee_rate: PropTypes.number,
        lending_price: PropTypes.number,
        price_of_default: PropTypes.number,
        price_to_call_at: PropTypes.number,
        starts_at: PropTypes.string,
        ends_at: PropTypes.string,
        orgCollWithUnit: PropTypes.string,
        orgPrincipalWithUnit: PropTypes.string,
        current_account_keys: PropTypes.array,

        ctpRatio: PropTypes.func.isRequired,
        lendingPriceWithUnit: PropTypes.string,
        priceToCallAtWithUnit: PropTypes.string,
        priceOfDefaultWthUnit: PropTypes.string,
        ltvPercentage: PropTypes.string,
        ctpPercentage: PropTypes.func.isRequired,
        interestPercentage: PropTypes.string,
        feePercentage: PropTypes.string,
        termInterestPercentage: PropTypes.string,
        termFeePercentage: PropTypes.string,
        startsAt: PropTypes.object,
        startsOn: PropTypes.string,
        endsAt: PropTypes.object,
        endsOn: PropTypes.string,

        full_billing_period_interest: PropTypes.number,
        number_of_full_billing_periods: PropTypes.number,
        partial_billing_period_duration: PropTypes.number,
        number_of_interest_only_payments: PropTypes.number,
        total_interest: PropTypes.number,
        total_number_of_payments: PropTypes.number,

        fullBillingPeriodInterestWithUnit: PropTypes.string,
        finalBillingPeriodInterestWithUnit: PropTypes.string,
        totalInterestWithUnit: PropTypes.string,

        fee: PropTypes.number,
        advance: PropTypes.number,
        revenue: PropTypes.number,

        feeWithUnit: PropTypes.string,
        advanceWithUnit: PropTypes.string,
        revenueWithUnit: PropTypes.string,

        apr: PropTypes.number,

        denied_reason: PropTypes.string,

        is_deleteable_by_borrower: PropTypes.bool,
        is_deleteable_by_servicer: PropTypes.bool,

        updated_at: PropTypes.string,

        updatedAt: PropTypes.object.isRequired,

        readableUpdatedAt: PropTypes.string.isRequired,

        currentPayment: PropTypes.shape(loanPaymentProptypes()),
        currentMarginCall: PropTypes.shape(marginCallProptypes(admin)),
        disbursement: PropTypes.shape(disbursementProptypes(admin)),

        type: PropTypes.string,
        readableType: PropTypes.string,

        slice: PropTypes.shape(sliceProptypes()),
        wallet: PropTypes.shape(walletProptypes()),

        prepayment: PropTypes.shape(prepaymentProptypes()),

        principal_payments: PropTypes.array,
        margin_calls: PropTypes.array,

        depositInstructions: PropTypes.object,

        is_refundable: PropTypes.bool,
        is_liquidateable: PropTypes.bool,
        is_partially_refundable_by_servicer: PropTypes.bool,
        is_partially_refundable_by_borrower: PropTypes.bool,
        is_partially_transferrable: PropTypes.bool,
        is_partially_liquidateable: PropTypes.bool,
        is_depositable: PropTypes.bool,
        is_prepayable: PropTypes.bool,
        is_transferrable: PropTypes.bool,

        transferrable_products: PropTypes.array,
      },
    };
  }
}

export function Loan(data) {
  if (data == null) {
    return data;
  }

  data = LoanListing(data);

  data.lendingPriceWithUnit = fiatWithUnit(data.lending_price);
  data.priceToCallAtWithUnit =
    data.price_to_call_at === 0 ? "n/a" : fiatWithUnit(data.price_to_call_at);
  data.ltvPercentage = formatPercentage(data.ltv_ratio);
  data.interestPercentage = formatPercentage(data.interest_rate);
  data.feePercentage = formatPercentage(data.fee_rate);
  data.termInterestPercentage = formatPercentage(data.term_interest_rate);
  data.termFeePercentage = formatPercentage(data.term_fee_rate);
  data.startsAt = data.starts_at ? parseTimestamp(data.starts_at) : null;
  data.readableStartsOn = data.startsAt ? readableDate(data.startsAt) : null;
  data.endsAt = data.startsAt && data.term ? cloneDeep(data.startsAt).add(data.term, "days") : null;
  data.readableEndsOn = data.endsAt ? readableDate(data.endsAt) : null;

  data.fullBillingPeriodInterestWithUnit = fiatWithUnit(data.full_billing_period_interest);
  data.finalBillingPeriodInterestWithUnit = fiatWithUnit(data.final_billing_period_interest);
  data.totalInterestWithUnit = fiatWithUnit(data.total_interest);

  data.feeWithUnit = fiatWithUnit(data.fee);
  data.advanceWithUnit = fiatWithUnit(data.advance);

  data.aprWithUnit = data.apr ? formatPercentage(data.apr) : null;

  data.revenue = data.fee + data.total_interest;
  data.revenueWithUnit = fiatWithUnit(data.revenue);

  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTime(data.updatedAt);

  data.loanType = data.loan_type;

  data.spreadPercentage = data.spread ? formatPercentage(data.spread) : null;

  if (data.type) {
    data.readableType = data.type.charAt(0).toUpperCase() + data.type.slice(1);
  }

  if (data.user) {
    data.user = User(data.user);
  }

  if (data.lender) {
    data.lender = User(data.lender);
  }

  if (data.slice) {
    data.slice = Slice(data.slice);
  }

  if (data.wallet) {
    data.wallet = Wallet(data.wallet, data.slice);
  }

  if (data.disbursement) {
    data.disbursement = Disbursement(data.disbursement, data);
  }

  if (data.payments) {
    data.payments = data.payments.map(payment => LoanPayment(payment));
  }
  data.currentPayment = data.payments ? data.payments.find(payment => payment.current) : null;

  if (data.prepayment) {
    data.prepayment = Prepayment(data.prepayment, data);
  }

  if (data.principal_payments && data.principal_payments.length > 0) {
    data.principal_payments = data.principal_payments.map(ppm => PrincipalPayment(ppm, data));
    data.hasPrincipalPayments = true;
  }

  if (data.margin_calls && data.margin_calls.length > 0) {
    data.margin_calls = data.margin_calls.map(margin_call => MarginCall(margin_call, data));
    data.hasMarginCalls = true;
    data.currentMarginCall = data.margin_calls
      ? data.margin_calls.find(margin_call => margin_call.current)
      : null;
    data.completedMarginCalls = data.margin_calls.filter(margin_call => !margin_call.current);
    data.hasCompletedMarginCalls = data.completedMarginCalls.length > 0;
  } else {
    data.margin_calls = [];
    data.hasMarginCalls = false;
    data.currentMarginCall = null;
    data.completedMarginCalls = [];
    data.hasCompletedMarginCalls = false;
  }

  if (data.current_account_keys) {
    data.current_account_keys = data.current_account_keys.map(account_key =>
      AccountKeyListing(account_key)
    );
    data.currentAccountKeyFor = role =>
      data.current_account_keys.find(account_key => account_key.role === role);
  }

  data.depositInstructions = {
    message: `Address for Unchained loan ID: ${data.uuid}`,
    address: data.slice ? data.slice.address : null,
  };
  if (stateIs(data, "pending_deposit")) {
    const amount = data.original_collateral - data.collateral;
    if (amount > 0) {
      data.depositInstructions = {
        address: data.slice ? data.slice.address : null,
        message: `Deposit collateral for Unchained loan ID: ${data.uuid}`,
        amount: amount,
      };
    }
  }
  if (
    stateIs(data, "live") &&
    data.currentMarginCall &&
    data.currentMarginCall.needs_collateral_deposit
  ) {
    const amount = data.currentMarginCall.target_collateral - data.collateral;
    if (amount > 0) {
      data.depositInstructions = {
        address: data.slice ? data.slice.address : null,
        message: `Deposit additional collateral for Unchained loan ID: ${data.uuid}`,
        amount: amount,
      };
    }
  }
  if (data.depositInstructions.amount) {
    data.depositInstructions.amountCopyable = formatCurrency(data.depositInstructions.amount, 8);
    data.depositInstructions.amountWithUnit = `${formatCurrency(
      data.depositInstructions.amount,
      8
    )} ${data.unit}`;
  }

  return data;
}
