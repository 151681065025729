import { WizardStep, WizardSubStep } from "@unchained/component-library";
import { pick } from "lodash";

import { useSetClientReportExtra } from "Contexts/ClientReporter/ClientReporter";

type Content = {
  other?: Record<string, unknown>;
  shippingInfo?: { value?: { name: string; address: { country: string } } };
  manifest: WizardStep[];
};

const maskStep = (step: WizardStep | WizardSubStep) =>
  pick(step, "title", "complete", "disabled", "skip");

/**
 * Simplifies the tracking of anonymized onboarding state for client reports */
export const useSetOnboardingClientReportExtra = (name: string, content: Content) => {
  const manifest = content.manifest;
  const getSetterKeys = Object.keys(content.other).filter(
    k =>
      content.other[k]?.hasOwnProperty("value") &&
      content.other[k]?.hasOwnProperty("set") &&
      typeof (content.other[k] as { set: () => void })?.set === "function"
  );

  const getSetterValuesObj = getSetterKeys.reduce(
    (obj, k) => ({
      ...obj,
      [k]: (content.other[k] as { value: unknown }).value,
    }),
    {}
  );

  const other = Object.keys(content.other)
    .filter(k => !getSetterKeys.includes(k))
    .reduce(
      (obj, k) => ({
        ...obj,
        [k]: content.other[k],
      }),
      {} as Record<string, unknown>
    );

  const shippingInfo = {
    name: content.shippingInfo?.value?.name ? "[STRING]" : undefined,
    country: content.shippingInfo?.value?.address?.country,
  };

  const sanitizedManifest = manifest.map(step => ({
    ...maskStep(step),
    steps: step.steps?.map(subStep => maskStep(subStep)),
  }));

  useSetClientReportExtra(
    name,
    {
      manifest: sanitizedManifest,
      ...getSetterValuesObj,
      ...other,
      shippingInfo,
    },
    [...Object.values(other), manifest, ...Object.values(getSetterValuesObj), shippingInfo]
  );
};
