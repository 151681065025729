import { FetchQueryOptions, useQueryClient } from "react-query";

/**
 * Meant to approximate queryClient.ensureQuery, a v4 function:
 * https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientensurequerydata
 *
 * This fetches from the cache optimistically, or fetches from the server if the cache is empty.
 * It attaches a `fromCache` boolean to the returned object, in case the caller needs to know.
 *
 * ex:
 *
 * const ensureQuery = useEnsureQuery();
 *
 * // Use await in case the query is not cached.
 * const { data } = await ensureQuery({ queryKey: ['todos', { id: 5 }], queryFn: () => fetchTodo(5) });
 */
export const useEnsureQuery = () => {
  const queryClient = useQueryClient();

  function ensureQuery<T = unknown>({ queryKey, queryFn, ...options }: FetchQueryOptions) {
    const cached = queryClient.getQueryData(queryKey) as T;

    if (cached) {
      return Promise.resolve({ ...cached, fromCache: true } as T & { fromCache?: boolean });
    }

    return queryClient.fetchQuery({ queryKey, queryFn, ...options }) as Promise<
      T & { fromCache?: boolean }
    >;
  }

  return ensureQuery;
};
