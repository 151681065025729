import React from "react";

import { Toast, useToast } from "@unchained/component-library";

import styles from "./Documents.module.scss";

interface DocumentErrorToastProps {
  tradeId: string;
  toastKey?: string | number;
}

const DocumentErrorToast = React.forwardRef<HTMLDivElement, DocumentErrorToastProps>(
  ({ tradeId, toastKey }, ref) => {
    const { closeToast } = useToast();

    const email = (
      <a key="email" href="mailto:clientservices@unchained.com">
        clientservices@unchained.com
      </a>
    );

    const toastDescription = [
      "We’re having trouble accessing that file. If this issue persists, please contact support at ",
      email,
      ".",
    ];

    const handleToastClose = React.useCallback(() => {
      closeToast(toastKey);
    }, [toastKey, closeToast]);

    return (
      <Toast
        ref={ref}
        type="error"
        title="Can’t retrieve statement"
        description={toastDescription}
        className={styles.toast}
        onClose={handleToastClose}
        key={tradeId}
      />
    );
  }
);

export { DocumentErrorToast };
