import React, { useMemo } from "react";

import { DropdownProps } from "@unchained/component-library";
import { useField } from "formik";

import { FormikDropdown } from "../FormikDropdown";
import { CountryCode, countryStateData } from "./CountryStateData";

type FormikCountrySelectorProps = {
  name: string;
  disabled?: boolean;
  prioritizedCountryCodes?: CountryCode[];
  /** Track country name in formik, not country code */
  useFullCountry?: boolean;
} & Partial<DropdownProps>;

export const FormikCountrySelector = ({
  name,
  prioritizedCountryCodes = ["US", "GB", "CA"],
  label,
  useFullCountry = true,
  ...props
}: FormikCountrySelectorProps) => {
  const [field] = useField(name);
  const countryOptions = useMemo(
    () => countryStateData.countryOptions(prioritizedCountryCodes),
    [prioritizedCountryCodes]
  );
  const selectedCountry = countryStateData.getCountry(field.value);

  const prepareValue = useFullCountry
    ? (opt: { value: string; label: string }) => opt.label
    : undefined;

  return (
    <FormikDropdown
      label={label || "Country"}
      name={name}
      options={countryOptions}
      selectedLabels={selectedCountry ? [selectedCountry.name] : []}
      placeholder="Select a country"
      fullWidth
      data-testid="country"
      prepareValue={prepareValue}
      {...props}
    />
  );
};
