import React from "react";

import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

export const SignIcon = ({ viewBox = "0 0 24 24", htmlColor = "black", classes = {} }, props) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={{ ...classes }}>
    <path d="M 14.992188 3 C 14.992188 3 12.154877 3.012461 9.2578125 3.7167969 C 7.8092801 4.0689648 6.3285706 4.5861413 5.1269531 5.4453125 C 3.9253357 6.3044837 2.9882813 7.6232023 2.9882812 9.2753906 C 2.9882812 10.83241 3.71123 11.843485 4.234375 12.455078 C 4.75752 13.066671 5 13.236783 5 14 C 5 14.323767 4.6944475 14.848076 4.1914062 15.541016 C 3.688365 16.233955 3 17.102457 3 18.322266 C 3 19.590289 3.8953223 20.47611 4.6367188 20.765625 C 5.3781152 21.05514 6.0566406 20.998047 6.0566406 20.998047 L 5.9433594 19.001953 C 5.9433594 19.001953 5.6218849 19.003354 5.3632812 18.902344 C 5.1046777 18.80136 5 18.846242 5 18.322266 C 5 17.929074 5.311635 17.399404 5.8085938 16.714844 C 6.3055525 16.030283 7 15.199233 7 14 C 7 12.613217 6.2362613 11.720157 5.7539062 11.15625 C 5.2715512 10.592343 4.9882812 10.316372 4.9882812 9.2753906 C 4.9882812 8.329579 5.4280706 7.6892819 6.2910156 7.0722656 C 7.1539607 6.4552494 8.4262511 5.9772383 9.7304688 5.6601562 C 12.338904 5.0259921 15.007813 5 15.007812 5 L 14.992188 3 z M 19.357422 7 C 19.136297 7 18.914594 7.0849062 18.746094 7.2539062 L 17.5 8.5 L 20.5 11.5 L 21.746094 10.253906 C 22.083094 9.9159063 22.083094 9.36825 21.746094 9.03125 L 19.96875 7.2539062 C 19.79975 7.0849062 19.578547 7 19.357422 7 z M 16.085938 9.9140625 L 8 18 L 8 21 L 11 21 L 19.085938 12.914062 L 16.085938 9.9140625 z" />
  </SvgIcon>
);

SignIcon.propTypes = {
  classNames: PropTypes.string,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
