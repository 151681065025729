// @ts-nocheck
// We ts-nocheck because maskObject typing isn't yet working with API spec types
import { useGetAccount } from "Shared/api";
import { maskObject } from "Utils/mask/mask";
import { getOrgMask, memberMask, userMask } from "Utils/mask/objectMasks";

export const useMaskedAccount = () => {
  const { data } = useGetAccount();

  if (!data.currentOrgComplete) return null;

  const { user, currentOrg, personalOrg, memberships } = data;
  const ownerMask = v => {
    // Org type owner
    if (v?.account_type) return `[ORG OWNER: ${v.codename || v.uuid}]`;

    // User type owner
    return user.uuid === v?.uuid ? "[USER OWNER - SELF]" : `[USER OWNER - ${v.codename || v.uuid}]`;
  };

  return {
    userUuid: user.uuid,
    orgUuid: currentOrg.uuid,
    user: maskObject(user, userMask),
    currentOrg: maskObject(currentOrg, { ...getOrgMask(), owner: ownerMask }),
    personalOrg:
      // Avoid redundant serialization
      personalOrg.uuid === currentOrg.uuid
        ? "[CURRENT ORG]"
        : maskObject(personalOrg, { ...getOrgMask(), owner: ownerMask }),
    memberships: memberships.map(m =>
      maskObject(m, {
        ...memberMask,
        org:
          // Avoid redundant serialization
          m.org.uuid === currentOrg.uuid
            ? () => "[CURRENT ORG]"
            : { ...getOrgMask(true), owner: ownerMask },
      })
    ),
  };
};
