import { API } from "./api";

export class CredentialsAPI {
  static RequestEmailChange = async email => {
    const response = await API.Post("/credentials/email/change", email);
    return response.data;
  };

  static GetEmailChange = async token => {
    const response = await API.Get(`/credentials/email/change/${token}`);
    const { email_change } = response.data as { email_change: string };
    return email_change;
  };

  static ConfirmEmailChange = async token => {
    const response = await API.Post(`/credentials/email/change/${token}`);
    return response.data;
  };

  static ChangePassword = async password => {
    const response = await API.Post("/credentials/password/change", password);
    return response.data;
  };

  static ChangePhone = async phone => {
    const response = await API.Post("/credentials/phone/change", phone);
    let { data } = response as { data: object };
    return { ...data, ...phone };
  };

  static Setup2FA = async () => {
    const response = await API.Post("/2fa/create");
    const { data } = response as { data: { secret: string; uri: string } };
    return { ...data };
  };

  /**
   * User request to enable 2fa
   * @param {object} setupData
   * @param {string} setupData.token - User provided 2fa token
   */

  static Enable2FA = async setupData => {
    const response = await API.Post("/2fa/confirm", setupData);
    const { data } = response;
    return data;
  };

  static Disable2FA = async () => {
    const response = await API.Delete("/2fa");
    const { data } = response;
    return data;
  };

  static RequestPasswordReset = async (email: string, captcha: string) => {
    const response = await API.Post("/credentials/password/reset", { email, captcha });
    return response.data;
  };
}
