import { ArrowDownward } from "@mui/icons-material";

const DepositCollateralNotification = ({ uuid }: { uuid: string }) => {
  return {
    to: `/loans/${uuid}`,
    title: `Deposit collateral for loan ${uuid}`,
    Icon: ArrowDownward,
  };
};

export { DepositCollateralNotification };
