import React from "react";

import { BIP32CoinType } from "./wallet";

const BIP32_REGEX = /^m\/[/0-9']+$/;
const BIP32_DEEP_HARDENED_MULTISIG_REGEX = /^m\/45'(\/(0|[1-9][0-9]*)'?){2}$/;

export function validateBIP32Path(bip32Path) {
  if (bip32Path === "") {
    return "A BIP32 path is required.";
  }
  if (bip32Path.length > window.TREFOIL_CONFIG.max_length.bip32_path) {
    return "BIP32 path is too long.";
  }
  if (!BIP32_REGEX.test(bip32Path)) {
    return "BIP32 path is not well-formed.";
  }
  if (!BIP32_DEEP_HARDENED_MULTISIG_REGEX.test(bip32Path)) {
    return (
      <span>
        BIP32 path must be depth 3 and begin with <code>m/45'/</code>.
      </span>
    );
  }
  const segments = bip32Path.split("/");
  const [penultimate, last] = segments.slice(-2);
  if (last.includes(`'`) && !penultimate.includes(`'`)) {
    return "Cannot have hardened derivations succeeding unhardened. Remove apostrophes.";
  }
  return "";
}

export function defaultBIP32Path(coldcard) {
  return coldcard ? `m/45'/${BIP32CoinType()}/0` : `m/45'/${BIP32CoinType()}'/0'`;
}
