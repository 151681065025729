import React, { Fragment } from "react";

import { Link } from "Components/Link";
import { useMemoizedState } from "Redux/selectors/hooks";

import { LoanIcon } from "../../Icons/LoanIcon";
import { LoanLabel } from "../Labels";
import { loanAdminURL, loanURL } from "../Links";

const LoanLink = ({ uuid, iconProps = {}, linkProps = {} }) => {
  const orgType = useMemoizedState("account.orgs.current.type");
  const isUnchained = orgType === "unchained";

  if (typeof uuid !== "string" && !uuid) {
    return null;
  }

  return (
    <Fragment>
      <LoanIcon {...iconProps} />
      <Link
        style={{ display: "inline-block" }}
        to={isUnchained ? loanAdminURL({ uuid }) : loanURL({ uuid })}
        target="_blank"
        {...linkProps}
      >
        <LoanLabel loan={{ uuid }} />
      </Link>
    </Fragment>
  );
};

export { LoanLink };
