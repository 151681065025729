import { GetInheritanceProtoocolPriceInfo200 } from "Specs/v1";

import { ApiType } from "./apiType";

export const InheritanceAPIFactory = (API: ApiType) => ({
  GetPriceInfo: async (orgUUID: string) => {
    const response = await API.Get<GetInheritanceProtoocolPriceInfo200>(
      `/org/${orgUUID}/inheritance/price-info`
    );
    return response.data;
  },
});
