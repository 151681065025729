import {
  PhoneNumberField,
  PhoneNumberFieldProps,
  countryCodes,
} from "@unchained/component-library";
import { useField } from "formik";

export function FormikPhoneNumberField(props: PhoneNumberFieldProps) {
  const [{ onChange: _onChange, ...field }, _, { setValue }] = useField(props.name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  // exclude onBlur to not overwrite PhoneNumberField's onBlur (used to show error)
  const { onBlur, ...finalProps } = props;

  return (
    <PhoneNumberField
      value={field.value}
      onChange={handleChange}
      countryCodes={countryCodes}
      {...finalProps}
    />
  );
}
