import React from "react";

import { CircularProgress } from "@mui/material";
import { Button, ButtonProps } from "@unchained/component-library";
import cn from "classnames";

import { BuyBitcoinModalBase } from "Components/TradingDesk/modals";
import { DestinationVault } from "Contexts/BuyBitcoin/types";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { AppModalManager } from "Shared/components/Modals";

import { SellBitcoinButton } from "../sell/SellBitcoinButton";

interface BuyAndSellBitcoinButtonsProps extends Partial<ButtonProps> {
  buyTitle?: string;
  className?: string;
  style?: React.CSSProperties;
  defaultVault?: DestinationVault;
  showDisableButtonOnNotEnabled?: boolean;
  isTradingFeatureAvailable: boolean;
  isSellBitcoinFeatureFlagEnabled: boolean | "LOADING";
  sellBitcoinStyles?: string;
}

const BuyAndSellBitcoinButtons = React.memo(
  ({
    buyTitle = "Buy",
    defaultVault,
    showDisableButtonOnNotEnabled = false,
    isTradingFeatureAvailable,
    isSellBitcoinFeatureFlagEnabled,
    sellBitcoinStyles,
    className,
    ...other
  }: BuyAndSellBitcoinButtonsProps) => {
    const openModal = () =>
      AppModalManager.open(() => <BuyBitcoinModalBase defaultVault={defaultVault} />);

    const { isSaleInProgress } = useSellBitcoinStore();

    const isFullWidth = isSaleInProgress ? false : true;
    const sellButtonSaleInProgressStlyes = isSaleInProgress ? "flex-[1.60] !h-12 " : "";
    const buyButtonSaleInProgressStyles = isSaleInProgress ? "flex-[1] !h-12" : "";
    const isFeaturesLoading = isSellBitcoinFeatureFlagEnabled === "LOADING";

    return isTradingFeatureAvailable || showDisableButtonOnNotEnabled ? (
      <div className="flex flex-row">
        <Button
          {...other}
          fullWidth={isFullWidth}
          onClick={openModal}
          disabled={!isTradingFeatureAvailable || isFeaturesLoading}
          className={cn(className, buyButtonSaleInProgressStyles)}
        >
          {isFeaturesLoading ? (
            <CircularProgress
              data-testid="loading-streaming-quote-spinner"
              color="primary"
              size={16}
            />
          ) : (
            buyTitle
          )}
        </Button>
        {isSellBitcoinFeatureFlagEnabled === true ? (
          <SellBitcoinButton
            fullWidth={isFullWidth}
            className={cn(sellBitcoinStyles, sellButtonSaleInProgressStlyes, "ml-3")}
            disabled={!isTradingFeatureAvailable}
          />
        ) : null}
      </div>
    ) : null;
  }
);

export { BuyAndSellBitcoinButtons };
