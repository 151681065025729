import PropTypes from "prop-types";

import { humanState } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

export function principalPaymentProptypes() {
  return {
    principal_paid: PropTypes.number,
    paid_at: PropTypes.object,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    principalChangeWithUnit: PropTypes.string,
  };
}

export function PrincipalPayment(data) {
  if (data == null) {
    return data;
  }

  data.humanState = humanState(data);
  data.paid_at = parseTimestamp(data.paid_at);
  data.readablePaidAt = readableTime(data.paid_at);

  if (data.principal_paid != null) {
    data.principalChangeWithUnit = `-$${formatCurrency(data.principal_paid, 2)}`;
  }

  return data;
}
