import {
  AddOrgOnboarding200,
  ApproveOrg200,
  DeleteQuorumConfig200,
  DenyOrg200,
  DenyOrgRequest,
  GetIraContractSignerUrl200,
  GetIraOrg200,
  GetNonPlaidBankAccountsAllowedStatus200,
  ListAvailableDelegatedCustodyKeys200,
  ListOrgWalletConfigs200,
  RequestOrgResubmission200,
  RequestOrgResubmissionRequest,
  SearchOrgs200,
  UpdateAddresses200,
  UpdateAddressesRequest,
  UpdateAllowNonPlaidBankAccounts200,
} from "Specs/v1";
import { AddOrgOnboardingRequest } from "Specs/v1/addOrgOnboarding/request";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { SearchOrgsQueryParams } from "Specs/v1/searchOrgs/params/query";
import { camelCaseKeys } from "Utils/objects";
import { getLocalTimezone } from "Utils/time";

import { ApiType } from "./apiType";

export const OrgAPIFactory = (API: ApiType) => ({
  Create: async data => {
    const response = await API.Post<{ member: any }>("/orgs", data);
    trackEvent({
      category: "FUNNEL/ORG",
      action: "created",
    });
    const { member } = response.data;
    return member;
  },

  Get: async uuid => {
    const response = await API.Get<{ org: CompleteOrg }>(`/orgs/${uuid}`);
    const { org } = response.data;
    return org;
  },

  ChangeName: async (uuid, name) => {
    const response = await API.Post<{ message: any }>(`/orgs/${uuid}/name`, { name });
    return { message: response.data.message };
  },

  ChangeCodename: async (uuid, codename) => {
    const response = await API.Post<{ message: any }>(`/orgs/${uuid}/codename`, { codename });

    const { message } = response.data;
    return { message };
  },

  UpdateNote: async (uuid, note) => {
    const response = await API.Put<{ message: any }>(`/orgs/${uuid}/note`, note);
    const { message } = response.data;
    return { message };
  },

  GetRecentTransactions: async uuid => {
    const response = await API.Get<{ recent_transactions?: any[] }>(
      `/orgs/${uuid}/recent_transactions`
    );
    const { recent_transactions } = response.data;
    return recent_transactions || [];
  },

  //
  // Members
  //

  AcceptInvitation: async orgUUID => {
    const response = await API.Post(`/orgs/${orgUUID}/membership/accept`);
    return response.data;
  },

  RejectInvitation: async orgUUID => {
    const response = await API.Post(`/orgs/${orgUUID}/membership/reject`);
    return response.data;
  },

  Leave: async orgUUID => {
    const response = await API.Delete(`/orgs/${orgUUID}/membership`);
    return response.data;
  },

  //
  // Permissions
  //

  EnsureUsersInRoles: async (orgUUID, users_uuids, roles_uuids) => {
    const data = { users_uuids, roles_uuids };
    const response = await API.Post(`/orgs/${orgUUID}/users_roles/add`, data);
    return response.data;
  },

  EnsureUsersNotInRoles: async (orgUUID, users_uuids, roles_uuids) => {
    const data = { users_uuids, roles_uuids };
    const response = await API.Post(`/orgs/${orgUUID}/users_roles/remove`, data);
    return response.data;
  },

  GetLenders: async () => {
    const response = await API.Get<{ lenders: any }>(`/lenders`);
    const { lenders } = response.data;
    return lenders;
  },

  GetAvailableDelegatedCustodyKeys: async orgUUID => {
    const response = await API.Get<ListAvailableDelegatedCustodyKeys200>(
      `/orgs/${orgUUID}/available-delegated-custody-keys`
    );
    const { keys } = response.data;
    return keys;
  },

  GetPortfolioBalanceHistory: async (orgUUID, daysSince) => {
    const timezone = getLocalTimezone();
    let path = `/orgs/${orgUUID}/balance_history?timezone=${timezone}`;
    if (daysSince) path += `&days_since=${daysSince}`;
    const { data } = await API.Get<{ balance_history: any[] }>(path);
    return data.balance_history || [];
  },

  GetProductsCount: async orgUUID => {
    const response = await API.Get<{ num_active_vaults: number; num_active_loans: number }>(
      `/orgs/${orgUUID}/active_products_count`
    );
    const { num_active_vaults, num_active_loans } = response.data;
    return { num_active_loans, num_active_vaults };
  },

  GetIraOrg: async (orgUuid: string) => {
    const data = (await API.Get<GetIraOrg200>(`/orgs/${orgUuid}/ira`, {})).data;
    // Attach the uuid to the org response, for ease of access in the UI
    return { ...data, org: { ...data.org, uuid: orgUuid } };
  },

  Search: async (searchParams: SearchOrgsQueryParams) =>
    camelCaseKeys((await API.Get<SearchOrgs200>(`/orgs`, searchParams)).data),

  AddOrgOnboarding: async (orgUuid: string, onboarding: AddOrgOnboardingRequest) =>
    (await API.Put<AddOrgOnboarding200>(`/orgs/${orgUuid}/onboarding`, onboarding)).data,

  AdminApproveOrg: async (orgUuid: string) =>
    (await API.Post<ApproveOrg200>(`/orgs/${orgUuid}/profile/approve`)).data,

  AdminApproveOrgAdvanced: async (orgUuid: string) =>
    (await API.Post<ApproveOrg200>(`/orgs/${orgUuid}/profile/advanced/approve`)).data,

  AdminDenyOrg: async (orgUuid: string, reason: string) =>
    (await API.Post<DenyOrg200>(`/orgs/${orgUuid}/profile/deny`, { reason } as DenyOrgRequest))
      .data,

  AdminDenyOrgAdvanced: async (orgUuid: string, reason: string) =>
    (await API.Post<DenyOrg200>(`/orgs/${orgUuid}/profile/deny`, { reason } as DenyOrgRequest))
      .data,

  AdminRequestOrgResubmission: async (orgUuid: string, reason: string) =>
    (
      await API.Post<RequestOrgResubmission200>(`/orgs/${orgUuid}/profile/resubmission`, {
        reason,
      } as RequestOrgResubmissionRequest)
    ).data,

  AdminRequestAdvancedOrgResubmission: async (orgUUid: string, reason: string) =>
    (
      await API.Post<RequestOrgResubmission200>(`/orgs/${orgUUid}/profile/advanced/resubmission`, {
        reason,
      } as RequestOrgResubmissionRequest)
    ).data,

  GetIraSignerUrl: async (orgUuid: string) =>
    (await API.Get<GetIraContractSignerUrl200>(`/orgs/${orgUuid}/ira/contract/signer_url`)).data,

  DownloadIraContract: async (orgUuid: string) =>
    await API.Download(`/orgs/${orgUuid}/ira/contract`),

  DeleteQuorumConfig: async (orgUuid: string) =>
    (await API.Delete<DeleteQuorumConfig200>(`/orgs/${orgUuid}/quorum-config`)).data,

  UpdateAddresses: async (uuid: string, addresses: UpdateAddressesRequest["addresses"]) =>
    (await API.Post<UpdateAddresses200>(`/orgs/${uuid}/profile/addresses/update`, { addresses }))
      .data,

  UpdateNonPlaidBankAccountsAllowed: async (
    orgUuid: string,
    nonPlaidBankAccountsAllowed: boolean
  ) => {
    return (
      await API.Post<UpdateAllowNonPlaidBankAccounts200>(
        `/orgs/${orgUuid}/non-plaid-bank-accounts-allowed`,
        {
          nonPlaidBankAccountsAllowed,
        }
      )
    ).data;
  },

  GetNonPlaidBankAccountsAllowed: async (orgUuid: string) => {
    const res = await API.Get<GetNonPlaidBankAccountsAllowedStatus200>(
      `/orgs/${orgUuid}/bank_accounts/non-plaid`
    );
    return res?.data?.data?.nonPlaidBankAccountsAllowed;
  },

  WalletConfigs: async (orgUuid: string) => {
    return (await API.Get<ListOrgWalletConfigs200>(`/orgs/${orgUuid}/wallets/configs`)).data;
  },
});
