import React, { ReactElement, useContext } from "react";

import { Modal, ModalHeader, ModalTitle } from "@unchained/component-library";

import { AppModalManager } from "Shared/components/Modals";

import { WalletRegistrationContext } from "./WalletRegistrationWizard";

interface WalletRegistrationModalProps {
  children: ReactElement | ReactElement[];
  showNext?: boolean;
  nextDisabled?: boolean;
}

export const WalletRegistrationModal = ({ children }: WalletRegistrationModalProps) => {
  const {
    setStepIndex,
    stepIndex,
    title,
    subtitle,
    modalSize = "md",
    hideOnBack,
  } = useContext(WalletRegistrationContext);
  const onBack = () => setStepIndex(i => i - 1);

  const headerProps =
    stepIndex > 0 && !hideOnBack ? { back: { text: "Back", onClick: onBack } } : {};

  const modalSizes = {
    sm: "md:w-[30.5rem]",
    md: "md:w-[38.6rem]",
    lg: "md:min-w-3xl",
  };

  return (
    <Modal onDismiss={() => AppModalManager.close()} className={modalSizes[modalSize]}>
      <ModalHeader {...headerProps}>
        <ModalTitle subtitle={subtitle}>{title}</ModalTitle>
      </ModalHeader>
      {children}
    </Modal>
  );
};
