import React from "react";

import cn from "classnames";

import { SignKeyCardCheckbox } from "Components/TradingDesk/sell/components/SignKeyCardCheckbox";
import { SigningKeyCard } from "Components/TradingDesk/sell/components/SigningKeyCard";

import { ClientKey } from "./ClientKey";
import { DistinguishedKey } from "./DistinguishedKey";

interface Props {
  name: string;
  role?: string;
  distinguished?: boolean;
  isCard?: boolean;
  isCardCheckbox?: boolean;
  accountKey?: {
    name?: string;
    logoSlug?: string;
  };
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  shared?: boolean;
  ownerName?: string | boolean;
  active?: boolean;
  size: string;
  disabled?: boolean;
  signedPsbt?: string;
  ref?: React.MutableRefObject<null>;
  logoSlug?: string;
  isSigningPopperShowing?: boolean;
}

export const KeyItem = (props: Props) => {
  let distinguished = props.distinguished || props.logoSlug || props.accountKey?.logoSlug;

  if (props.isCard) {
    return (
      <SigningKeyCard
        className={cn("rounded-lg", {
          "opacity-50": !(props.active || !!props.signedPsbt),
          "opacity-25": props.disabled,
          "shadow-[0_4px_8px_-2px_rgba(30,72,66,0.25),0_4px_12px_-2px_rgba(30,72,66,0.25)]":
            props.isSigningPopperShowing && props.active,
        })}
        title={props.name}
        isUnchained={props.role === "unchained"}
        isSigned={!!props.signedPsbt}
        onClick={props.onClick}
        disabled={props.disabled}
        logoSlug={props.logoSlug}
        isDelegatedKey={props.role === "delegate"}
      />
    );
  } else if (props.isCardCheckbox) {
    return (
      <SignKeyCardCheckbox
        title={props.name}
        isKeySelected={props.active}
        isUnchained={props.role === "unchained"}
        onClick={props.onClick}
        isDisabled={props.disabled}
        logoSlug={props.logoSlug}
        isDelegatedKey={props.role === "delegate"}
      />
    );
  } else if (distinguished) {
    return <DistinguishedKey {...props} />;
  } else return <ClientKey {...props} />;
};
