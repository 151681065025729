import { useQuery, UseQueryOptions } from "react-query";

import { CheckIfOrgClosureIsAllowed200 } from "Specs/v2";

import { OrgAPI } from "..";

export const orgV2QueryKeys = {
  all: ["orgs-v2"] as const,
  closureAllowed: (orgId: string) => [...orgV2QueryKeys.all, orgId],
  get: (invoiceId: string) => [...orgV2QueryKeys.all, invoiceId],
} as const;

export const useClosureAllowed = (
  orgId: string,
  options?: UseQueryOptions<CheckIfOrgClosureIsAllowed200>
) =>
  useQuery<CheckIfOrgClosureIsAllowed200>(
    orgV2QueryKeys.closureAllowed(orgId),
    () => OrgAPI.GetClosureAllowed(orgId),
    options
  );
