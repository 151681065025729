import React from "react";

import { BasicPage } from "Components/Pages";

export class Forbidden extends React.Component {
  render() {
    return (
      <BasicPage narrow title="Forbidden">
        <h3 className="mb-4">You are not allowed to view this page.</h3>
      </BasicPage>
    );
  }
}
