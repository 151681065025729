import React, { useContext, useEffect, useState } from "react";

import {
  BatchSpendReviewTable,
  getBtcTotal,
} from "Components/Shared/Elements/BatchSpendReviewTable";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

export const BatchSpendReview = () => {
  const { batchData, setState } = useContext(WithdrawModalContext);
  const [stepValid, setStepValid] = useState(false);

  const btcTotal = getBtcTotal(batchData.map(el => el.amount.value));
  useEffect(() => {
    if (stepValid) {
      setState({
        amount: btcTotal.toFixed(8),
        batchHasError: false,
        nextDisabled: false,
      });
    } else {
      setState({ amount: "", batchHasError: true });
    }
  }, [stepValid, btcTotal, setState]);

  return (
    <BatchSpendReviewTable data={batchData} withSummary validateData onValidate={setStepValid} />
  );
};
