import { Avatar, Incrementer, Switch } from "@unchained/component-library";

import styles from "./OrgQuorumApprovalSettings.module.scss";
import { numMembersWhoCanApprove } from "./utils";

export const OrgQuorumApprovalSettings = ({
  org,
  isEditing,
  applyToAllTxs,
  minApprovals,
  setQuorumMinApprovalsChange,
  setQuorumApplyToAllTxsChange,
}) => {
  const quorumEnabled = org?.quorum_config?.min_approvals > 1;
  const numAvailableApprovers = numMembersWhoCanApprove(org);
  const approverMax = quorumEnabled ? Math.max(numAvailableApprovers, minApprovals) : 1;

  const handleToggleApplyToAllTxs = () => {
    setQuorumApplyToAllTxsChange(!applyToAllTxs);
  };

  return (
    <div className="flex flex-col gap-8 md:grid md:grid-cols-2">
      <div>
        <h3 className="mb-2">Approval policy</h3>
        <div className="mb-4">
          <p className="prose">
            All sensitive operations, such as withdrawing assets or changing account settings,
            require approval from multiple users of an organization. Approvals are required for:{" "}
          </p>
          <div className="my-4 flex items-center px-4">
            <Avatar shape="circle" name="1" color="blue" size="xs" />
            <p className="prose ms-2">Adding, editing, or removing users</p>
          </div>

          <div className="my-4 flex items-center px-4">
            <Avatar shape="circle" name="2" color="blue" size="xs" />
            <p className="prose ms-2">Any transaction requiring an Unchained or delegate key</p>
          </div>
          <p className="prose">
            The default quorum is set to one fewer than the total number of admins on your account.
            If the account only has two admins then the default is two approvals.
          </p>
        </div>

        <div className="prose font-semi">
          Note: changes to the quorum size will not reflect on your account until approved by the
          existing approval quorum.
        </div>
      </div>
      <div>
        <h3 className="mb-2">Quorum size</h3>
        <p className="prose">
          Users whose approvals are necessary for transactions and configuration changes
        </p>
        <div className="flex items-center">
          <Incrementer
            min={quorumEnabled ? 2 : 1}
            max={approverMax}
            value={minApprovals}
            disabled={!quorumEnabled || !isEditing}
            className={styles.incrementer}
            onChange={v => {
              setQuorumMinApprovalsChange(v);
            }}
          />
          <span className="ms-4 font-bold">out of {approverMax} approvers</span>
        </div>

        <h3 className="mb-2">Optional</h3>
        <div className="rounded border border-gray-200 bg-gray-50 py-1">
          <Switch
            disabled={!quorumEnabled || !isEditing}
            label="Require quorum approval for all transactions"
            checked={applyToAllTxs}
            onChange={handleToggleApplyToAllTxs}
            data-testid="quorum-apply-to-all-txs-switch"
          />
          <p className="mb-2 ml-3 font-[200]">
            Even when not using Unchained or a delegate as a key signer
          </p>
        </div>
      </div>
    </div>
  );
};
