export const breakpointNames = ["z", "xs", "sm", "md", "lg", "xl"];

/**
 *
 * Passed as an sx prop, hides only for the given breakpoints.
 * If no breakpoints given, returns an empty object.
 *
 * @param {[string]} breakpoints - array of breakpoints - z, xs, sm, md, lg, or xl - for which to hide element
 * @param {string} displayType - Type of display to default back to. Typically, "block" or "flex"
 * @returns {object}
 */
export const hideOnly = (breakpoints, displayType = "block") => {
  if (!breakpoints || !breakpoints.length) return {};

  const display = breakpointNames.reduce(
    (obj, key) => ({
      [key]: breakpoints.includes(key) ? "none" : displayType,
      ...obj,
    }),
    {}
  );

  return { display };
};

/**
 *
 * Passed as an sx prop, hides below the given breakpoint (inclusive).
 *
 * @param {[string]} breakpoint - z, xs, sm, md, lg, or xl - Element hidden below this (inclusive)
 * @param {string} displayType - Type of display to default back to. Typically, "block" or "flex"
 */
export const hideUnder = (breakpoint, displayType = "block") => {
  return {
    display: { z: "none", [breakpoint]: displayType },
  };
};
