import { HelperText } from "@unchained/component-library";
import cn from "classnames";
import { useField } from "formik";
import { pick } from "lodash";

import { setTouched } from "Components/FormFields/utils";
import { DocumentUpload } from "Components/Shared/Elements/DocumentUpload";

import styles from "./FormikUploadField.module.scss";

export type UploaderFile = {
  allowed_actions?: string[];
  newContent?: File;
  data: string;
  name: string;
  mimeType: string;
  size: number;
};

/**
 * Bridges the DocumentUpload component and a formik context.
 * Considered deprecated because it uses old upload-field designs.
 * */
export const DeprecatedFormikUploadField = ({
  name,
  header,
  description,
  deleteDocumentFromServer = Promise.resolve,
  className,
  disabled,
  ...other
}: {
  name: string;
  header?: string;
  description?: string;
  deleteDocumentFromServer?: () => Promise<void>;
  className?: string;
  disabled?: boolean;
}) => {
  const [field, meta, helpers] = useField<{
    newContent?: File;
    allowed_actions?: string[];
    allowedActions?: string[];
    header?: string;
    text?: string;
    title?: string;
  }>(name);

  const documentAlreadyInFormikContext = field.value;

  const blurToTriggerValidations = () => setTouched(helpers, true);

  let error: object | string = meta.touched && meta.error;
  if (error && typeof error === "object") error = Object.values(error)[0] as string;

  return (
    <div className={className} data-testid={other["data-testid"] || "document-upload"}>
      <DocumentUpload
        label={header}
        description={description}
        document={documentAlreadyInFormikContext}
        updateDocument={async (freshlyUploadedDoc?: { newContent: File; title?: string }) => {
          // Uploading
          if (freshlyUploadedDoc?.newContent) {
            const file = freshlyUploadedDoc.newContent;
            const existing = documentAlreadyInFormikContext || {};

            const doc = {
              ...existing,
              ...freshlyUploadedDoc,
              title: existing.title || freshlyUploadedDoc.title,
              data: "PRESENT",
              name: file.name,
              mimeType: file.type,
              size: file.size,
            };

            helpers.setValue(doc);
            helpers.setError(undefined);
            blurToTriggerValidations();
            return;
          }

          // Could be camel or snake cased depending on API
          const { allowed_actions, allowedActions } = documentAlreadyInFormikContext;

          // Deleting
          if (allowedActions || allowed_actions) {
            // Doc on server. Delete before updating formik context
            deleteDocumentFromServer()
              .then(() => {
                helpers.setValue(pick(document, "header", "text", "title"));
                blurToTriggerValidations();
              })
              .catch(() => {
                helpers.setError("Document deletion failed. Please refresh and try again.");
                blurToTriggerValidations();
              });
          } else {
            // No doc on server, just reset formik context
            helpers.setValue(pick(document, "header", "text", "title"));
            blurToTriggerValidations();
          }
        }}
        classes={{
          dropzone: cn(
            "py-4 h-auto w-full !ml-0 !mr-0 min-h-[8rem] flex flex-col items-center",
            disabled && "pointer-events-none"
          ),
          preview: cn(styles.filePreview, disabled && "pointer-events-none"),
        }}
        direction="column"
        previewMessage=""
      />
      {error && <HelperText error>{error}</HelperText>}
    </div>
  );
};
