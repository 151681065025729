import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { WireInstructionsAPI } from "../wireInstructions";

export const wireInstructionsKeys = {
  getWireInstructions: (orgUuid: string) => ["getWireInstructions", orgUuid],
};
export const useGetWireInstructionsQuery = (
  orgUuid: string,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    wireInstructionsKeys.getWireInstructions(orgUuid),
    () => WireInstructionsAPI.GetWireInstructions(orgUuid),
    {
      enabled: !!orgUuid,
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};
