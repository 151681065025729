import React from "react";

import { AppSlideoutManager, CLOSE_SLIDEOUT, OPEN_SLIDEOUT } from "./AppSlideoutManager";

interface SlideoutContainerBaseState {
  component?: React.FC;
  isOpen: boolean;
}
class SlideoutContainerBase extends React.Component<{}, SlideoutContainerBaseState> {
  constructor(props) {
    super(props);
    this.state = {
      component: undefined,
      isOpen: false,
    };
  }

  componentDidMount() {
    AppSlideoutManager.watch(OPEN_SLIDEOUT, (component, isClearQueryParams) => {
      if (isClearQueryParams) {
        window.history.pushState(null, "", window.location.pathname);
      }
      this.setState({ component: component(), isOpen: true });
    });

    AppSlideoutManager.watch(CLOSE_SLIDEOUT, () => {
      this.setState({ component: undefined, isOpen: false });
    });
  }

  render() {
    const Component = this.state.component;
    if (Component) {
      return <Component />;
    }

    return null;
  }
}

export const SlideoutContainer = SlideoutContainerBase;
