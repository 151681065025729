import React, { Fragment } from "react";

import { Collapse } from "@mui/material";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./ExpandInfo.module.scss";

class ExpandInfoBase extends React.Component {
  static propTypes = {
    base: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    afterBase: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    beforeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    expandContent: PropTypes.node.isRequired,
    classes: PropTypes.object,
  };

  static defaultProps = {
    classes: {
      baseContent: "",
      baseContentExpanded: "",
      base: "",
      containerExpanded: "",
      container: "",
    },
  };

  state = {
    expand: false,
  };

  onClick = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  render() {
    const { beforeBase, base, afterBase, expandContent, classes } = this.props;
    const { expand } = this.state;
    return (
      <div className={classnames({ [classes.containerExpanded]: expand }, [classes.container])}>
        <span
          className={classnames({ [classes.baseContentExpanded]: expand }, [classes.baseContent])}
        >
          {beforeBase && <span className={classes.beforeBase}>{beforeBase}</span>}

          <span onClick={this.onClick} className={classnames(classes.base, [styles.clickableText])}>
            {base}
          </span>

          {afterBase && <span>{afterBase}</span>}
        </span>

        <br />
        <Collapse in={expand}>{expandContent}</Collapse>
      </div>
    );
  }
}

export const ExpandInfo = ExpandInfoBase;
