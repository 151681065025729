import { AccountKeyAllowedActionsEnum } from "Specs/v1/getAccount/200";
import {
  SigRequest,
  SigRequestAllowedActionsEnum,
  SigRequestStateEnum,
} from "Specs/v1/getTransactionRequests/200";

import { BasePermission, RoleName } from "./BasePermission";

const FIN_COORD = "Finance Transaction Coordinators";
const BTC_COORD = "Bitcoin Transaction Coordinators";

export class SigRequestPermission extends BasePermission<SigRequestAllowedActionsEnum, SigRequest> {
  accountKey: BasePermission<AccountKeyAllowedActionsEnum>;
  constructor(model, currentUser) {
    super(model, currentUser);

    this.accountKey = new BasePermission<AccountKeyAllowedActionsEnum>(
      { allowed_actions: this.model.account_key.allowed_actions },
      this.currentUser
    );
  }

  canFinanceApprove = () =>
    this._stateIsIn("pending") &&
    this._hasAllRoles(FIN_COORD) &&
    !this._approvedByAll(FIN_COORD) &&
    this.accountKey._hasAllActions("approve_signature") &&
    this._needsAvailableApprovals();

  canCoordinatorApprove = () =>
    this._stateIsIn("pending") &&
    this._hasAllRoles(BTC_COORD) &&
    !this._approvedByAll(BTC_COORD) &&
    this.accountKey._hasAllActions("approve_signature") &&
    this._needsAvailableApprovals() &&
    this._hasPrecedentApprovalIfNeeded(FIN_COORD);

  canFinanceUnapprove = () =>
    this._stateIsIn("pending", "approved") &&
    this._hasAllRoles(FIN_COORD) &&
    this.accountKey._hasAllActions("approve_signature") &&
    this._needsAvailableApprovals() &&
    this._hasPrecedentApprovalIfNeeded(FIN_COORD);

  canCoordinatorUnapprove = () =>
    this._stateIsIn("pending", "approved") &&
    this._hasAllRoles(BTC_COORD) &&
    this.accountKey._hasAllActions("approve_signature") &&
    this._approvedByAll(BTC_COORD) &&
    this._needsAvailableApprovals();

  canUnapprove = () => this.canFinanceUnapprove() || this.canCoordinatorUnapprove();

  canApprove = () => this.canFinanceApprove() || this.canCoordinatorApprove();

  /** Returns the most salient action, if any, the current user can take on this sig request */
  approvalAction = (): "approve" | "unapprove" | undefined => {
    if (!this._needsAvailableApprovals()) return undefined;
    return this.canApprove() ? "approve" : this.canUnapprove() ? "unapprove" : undefined;
  };

  _approvedByAll = (...approvalNames: RoleName[]) =>
    approvalNames.every(name => this._approvalNames().includes(name));

  _approvalNames = () => this.model.approvals.map(app => app?.role?.name);

  _stateIsIn = (...states: SigRequestStateEnum[]) => states.includes(this.model.state);

  _needsAvailableApprovals = () =>
    this._roleNames().some(name => this.model.needed_approvals.some(role => role?.name === name));

  _needsAllApprovals = (...approvalNames: RoleName[]) =>
    this.model.needed_approvals.some(role => approvalNames.includes(role.name as RoleName));

  _hasPrecedentApprovalIfNeeded = (approvalName: RoleName) =>
    this._needsAllApprovals(approvalName) ? this._approvedByAll(approvalName) : true;
}
