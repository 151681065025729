import React from "react";

import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button } from "@unchained/component-library";
import classnames from "classnames";
import PropTypes from "prop-types";

import { PrimaryButton } from "Components/Shared/Elements/Buttons/PrimaryButton";

import styles from "./WizardNavButtons.module.scss";

export class WizardNavButtons extends React.PureComponent {
  static propTypes = {
    onNext: PropTypes.func,
    onBack: PropTypes.func,
    hideBack: PropTypes.bool,
    hideNext: PropTypes.bool,
    nextType: PropTypes.string,
    disableNext: PropTypes.bool,
    nextText: PropTypes.string,
    nextIcon: PropTypes.bool,
    backText: PropTypes.string,
    nextColor: PropTypes.string,
    wrapperClass: PropTypes.string,
    nextProps: PropTypes.object,
    backProps: PropTypes.object,
  };

  static defaultProps = {
    hideBack: false,
    hideNext: false,
    disableNext: false,
    nextText: "Next",
    nextIcon: true,
    backText: "Back",
    nextColor: "primary",
    wrapperClass: "",
    nextProps: {},
    backProps: {},
  };

  render() {
    const {
      nextType,
      onNext,
      onBack,
      hideBack,
      hideNext,
      nextText,
      nextIcon,
      backText,
      nextColor,
      disableNext,
      wrapperClass,
      nextProps,
      backProps,
    } = this.props;

    const nextClasses = nextProps.classes
      ? { ...nextProps.classes, root: classnames(nextProps.classes.root, [styles.nextButton]) }
      : { root: styles.nextButton };

    const backClasses =
      backProps && backProps.classes
        ? {
            ...backProps.classes,
            root: classnames(backProps.classes.root, [styles.backButton], {
              [styles.hideNext]: hideNext,
            }),
          }
        : { root: styles.backButton };

    return (
      <div className={classnames(wrapperClass, styles.container)}>
        {hideBack ? null : (
          <Button onClick={onBack} {...backProps} startIcon={<ArrowBack />} type="secondary">
            {backText}
          </Button>
        )}
        {hideNext ? null : (
          <PrimaryButton
            {...nextProps}
            type={nextType}
            onClick={onNext}
            color={nextColor}
            disabled={disableNext}
            endIcon={nextIcon && <ArrowForward />}
          >
            {nextText}
          </PrimaryButton>
        )}
      </div>
    );
  }
}
