import { updatedField } from "Utils/errors";

var initialState = {
  request: {
    status: "new",
    errors: {},
    email: "",
  },
  confirm: {
    status: "new",
    errors: {},
    password: "",
  },
};

function reduceRequestPasswordReset(state = initialState.request, action = {}) {
  switch (action.type) {
    case "PASSWORD_RESET/REQUEST/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "PASSWORD_RESET/REQUEST/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: null };
    case "PASSWORD_RESET/REQUEST/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "PASSWORD_RESET/REQUEST/SET/EMAIL":
      return updatedField(state, action, "email");
    default:
      return state;
  }
}

function reduceConfirmPasswordReset(state = initialState.confirm, action = {}) {
  switch (action.type) {
    case "PASSWORD_RESET/CONFIRM/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "PASSWORD_RESET/CONFIRM/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: null };
    case "PASSWORD_RESET/CONFIRM/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "PASSWORD_RESET/CONFIRM/SET/PASSWORD":
      return updatedField(state, action, "password");
    default:
      return state;
  }
}

export default (state = initialState, action = {}) => {
  if (action.type.startsWith("PASSWORD_RESET/REQUEST/")) {
    return {
      ...state,
      ...{
        request: reduceRequestPasswordReset(state.request, action),
      },
    };
  }
  if (action.type.startsWith("PASSWORD_RESET/CONFIRM/")) {
    return {
      ...state,
      ...{
        confirm: reduceConfirmPasswordReset(state.request, action),
      },
    };
  }
  return state;
};
