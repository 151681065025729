import autoBind from "auto-bind";
import Bowser from "bowser";
import _ from "lodash";

import { compatibilityMatrix } from "../components/Support/SupportData";

export class BrowserCompatibility {
  constructor() {
    this.browser = Bowser.getParser(window.navigator.userAgent);
    autoBind(this);
  }

  getOSName() {
    const currentOS = this.browser.getOS();
    return currentOS.name.toLowerCase();
  }

  getBrowserName() {
    const browser = this.browser.getBrowser();
    return browser.name.toLowerCase();
  }

  static getDeviceIssuesForBrowser(device, browserName) {
    return _.filter(device.issues, issue => {
      return issue.browsers ? issue.browsers.includes(browserName.toLowerCase()) : true;
    });
  }

  getDeviceIssues(walletType) {
    const deviceCompatibilityObjects =
      this._getCompatibilityObjectsByDeviceManufacturer(walletType);
    if (deviceCompatibilityObjects.length === 0) {
      return [];
    }
    const uniqueDeviceBrowserIssues = this._getDeviceBrowserCompatibilityIssues(
      deviceCompatibilityObjects
    );

    return this._filterDeviceIssuesForCurrentOS(uniqueDeviceBrowserIssues);
  }

  _getCompatibilityObjectsByDeviceManufacturer(walletType) {
    return _.filter(compatibilityMatrix, device => {
      return device.make === walletType;
    });
  }

  _getDeviceBrowserCompatibilityIssues(deviceCompatibilityObjects) {
    const currentBrowserName = this.getBrowserName();
    const combinedIssueList = _.flatMap(deviceCompatibilityObjects, device =>
      BrowserCompatibility.getDeviceIssuesForBrowser(device, currentBrowserName)
    );
    if (combinedIssueList.length === 0) {
      return [];
    }
    return BrowserCompatibility.filterForUniqueIssues(combinedIssueList);
  }

  static filterForUniqueIssues(compatibilityIssues) {
    return _.uniqBy(compatibilityIssues, "id");
  }

  _filterDeviceIssuesForCurrentOS(issues) {
    const osName = this.getOSName();
    return _.filter(issues, issue => {
      return issue.os ? issue.os.includes(osName) : true;
    });
  }
}
