import { API } from "./api";

export class CryptoAPI {
  static GetCryptoPrices = async (maxTries = 3) => {
    try {
      const response = await API.Get(`/prices`);
      return response.data;
    } catch (e) {
      if (maxTries === 1) {
        throw e;
      }
      return await CryptoAPI.GetCryptoPrices(maxTries - 1);
    }
  };

  static GetHistoricalCryptoPrices = async (maxTries = 3, daysSince = 30) => {
    try {
      const response = await API.Get(`/prices/historical?days_since=${daysSince}`);
      return response.data;
    } catch (e) {
      if (maxTries === 1) {
        throw e;
      }
      return await CryptoAPI.GetHistoricalCryptoPrices(maxTries - 1, daysSince);
    }
  };

  static GetIntradayBitcoinPrices = async (maxTries = 3, daysSince = 1) => {
    try {
      const response = await API.Get<{ prices: any }>(`/prices/intraday?days_since=${daysSince}`);
      return response.data.prices;
    } catch (e) {
      if (maxTries === 1) {
        throw e;
      }
      return await CryptoAPI.GetIntradayBitcoinPrices(maxTries - 1, daysSince);
    }
  };
}
