/**
 *  Api-Specs specifies:
 *  type ProductType = "vaults" | "loans";
 *
 *  However, our legacy js/jsx code is riddled with instances of
 *  'vault', 'Vault', 'loan', 'Loan', etc...
 *
 *  Because we do not have broad typesafety yet, we can avoid bugs
 *  related to unexpected formats of product type strings by using this function.
 *
 *  This function is intended to collapse these unknown product type string
 *  to the api-specs versions ("vaults" | "loans")
 *
 * @returns "vaults" | "loans"
 */

export function collapseProductType(input: string): "vaults" | "loans" {
  input = input.trim().toLowerCase();

  if (input.includes("vault")) {
    return "vaults";
  } else if (input.includes("loan")) {
    return "loans";
  } else {
    // If the trimmed and lowercased input doesn't contain either "vault" or "loan"
    // we simply throw an error.
    throw new Error(
      `Unknown/Invalid product type: ${input} received. Expected something which includes "vault" or "loan" after lowercasing.`
    );
  }
}
