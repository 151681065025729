import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { KEYSTORES } from "@caravan/wallets";

import { useMemoizedState } from "Redux/selectors/hooks";
import { useGetWalletConfig } from "Shared/api/hooks";
import { GetWalletConfig200 } from "Specs/v1";

import { KEYSTORE } from "../types";
import { WalletRegistrationModal } from "./WalletRegistrationModal";
import { getSteps } from "./getSteps";

interface WalletRegistrationContextType {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  setDeviceType: Dispatch<SetStateAction<KEYSTORE | null>>;
  title: string;
  subtitle?: string | ReactNode;
  deviceType: KEYSTORE | null;
  walletConfig: GetWalletConfig200;
  productUuid: string;
  modalSize: string;
  hideOnBack?: boolean;
  successMessage?: { title: string; message: string };
}

export const WalletRegistrationContext = createContext<
  WalletRegistrationContextType | Record<string, never>
>({});

export const WalletRegistrationWizard = () => {
  const vaultUuid = useMemoizedState<string>("vaults.vaultShow.vault.uuid");
  const loanUuid = useMemoizedState<string>("loans.loanShow.loan.uuid");
  const currentVaultWalletUuid = useMemoizedState<string>(
    "vaults.vaultShow.vault.current_wallet_uuid"
  );
  const currentLoanWalletUuid = useMemoizedState<string>("loans.loanShow.loan.current_wallet_uuid");

  const currentWalletUuid = currentVaultWalletUuid || currentLoanWalletUuid;
  const productUuid = vaultUuid || loanUuid;

  const currentWalletQuery = useGetWalletConfig(currentWalletUuid);

  const [deviceType, setDeviceType] = useState<KEYSTORE | null>(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  // get the manifest of steps based on the device type
  // this will default to the "baseManifest" which is just
  // the device selector. Once a device has been selected
  // it should fill out the rest of the manifest based on that device
  const steps = useMemo(() => getSteps(deviceType), [deviceType]);
  const step = steps[currentStepIndex];

  const { Component: CurrentStep } = step;

  // make sure we can't set the step index past the end of the steps
  const setStepIndex = useCallback(
    index => {
      if (index < 0 || index >= steps.length) {
        console.error(`Invalid step index: ${index}`);
      } else {
        setCurrentStepIndex(index);
      }
    },
    [steps.length]
  );

  const successMessage = useMemo(() => {
    if (deviceType === KEYSTORES.TREZOR) {
      return {
        title: "Your Trezor is ready!",
        message: "Your Trezor is already set up to be used with this wallet.",
      };
    }
    return {
      title: "Device registered successfully!",
      message: "You can register another device, or go back to your vault.",
    };
  }, [deviceType]);

  const context = useMemo<WalletRegistrationContextType>(
    () => ({
      stepIndex: currentStepIndex,
      title: step.title,
      subtitle: step.subtitle,
      hideOnBack: step.hideOnBack,
      setStepIndex,
      setDeviceType,
      deviceType,
      walletConfig: currentWalletQuery.data,
      modalSize: step.modalSize,
      productUuid,
      successMessage,
    }),
    [
      currentStepIndex,
      step,
      setStepIndex,
      deviceType,
      currentWalletQuery.data,
      productUuid,
      successMessage,
    ]
  );

  return (
    <WalletRegistrationContext.Provider value={context}>
      <WalletRegistrationModal>
        <CurrentStep />
      </WalletRegistrationModal>
    </WalletRegistrationContext.Provider>
  );
};
