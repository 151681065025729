import moment from "moment-timezone";

// Returns a date object the specified time ago
export const dateAgo = ({ years = 0, months = 0, days = 0, startingDate = new Date() }) => {
  const date = new Date(startingDate);
  if (years !== undefined) date.setFullYear(date.getFullYear() - years);
  if (months !== undefined) date.setMonth(date.getMonth() - months);
  if (days !== undefined) date.setDate(date.getDate() - days);
  return date;
};

export const dateFromNow = ({ years = 0, months = 0, days = 0, startingDate = new Date() }) => {
  const date = new Date(startingDate);
  if (years !== undefined) date.setFullYear(date.getFullYear() + years);
  if (months !== undefined) date.setMonth(date.getMonth() + months);
  if (days !== undefined) date.setDate(date.getDate() + days);
  return date;
};

/**
 * Returns a date string in the format YYYY-MM-DD.
 * If invalid date (or date string) is passed, returns undefined
 * */
export const toISODatestring = (date: Date | string | undefined): string | undefined => {
  if (!date) return undefined;

  try {
    // If given a string, initialize it in UTC
    const d = date instanceof Date ? date : utcFromDateString(date);
    return d.toISOString().split("T")[0];
  } catch (e) {
    return undefined;
  }
};

// Useful to avoid moment construction-from-non-ISO warning
export const legacyToIso = (date: string) => {
  const legacyRegex = /\d{4}\/\d{2}\/\d{2}/;
  return legacyRegex.test(date) ? date.replace(/\//g, "-") : date;
};

export const toLegacyDateString = (d: Date) => d.toISOString().split("T")[0].replace(/-/g, "/");

/** Creates a moment object in UTC timezone (00:00hh:mm) from the ISO date string */
export const utcFromDateString = (date: string) => moment.tz(legacyToIso(date), "UTC");

export const fullLengthMonthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/** Returns the suffix for the day (st, nd, rd, th) */
export const getNumberSuffix = (num: number) => {
  let n = num % 100;
  if (n > 3 && n < 21) return "th"; // Handle 11th, 12th, 13th
  switch (n % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

/**
 * Full date string w day suffix
 * Eg November 11th, 2024
 * */
export const fullDateString = (date: Date) => {
  const day = date.getDate();
  const month = fullLengthMonthList[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}${getNumberSuffix(day)}, ${year}`;
};
