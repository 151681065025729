import {
  RESET_NEW_KEY_FLOW,
  SET_NEW_WALLET_ACTIVE,
  SET_NEW_WALLET_BIP32_PATH,
  SET_NEW_WALLET_CURRENCY,
  SET_NEW_WALLET_NAME,
  SET_NEW_WALLET_SHOW_ADVANCED,
  SET_NEW_WALLET_SUCCESS_MESSAGE,
  SET_NEW_WALLET_TYPE,
  SET_NEW_WALLET_ORG,
  WALLET_NEW_SET_BIP32_NODE,
  WALLET_NEW_SET_XPUB,
  WALLET_NEW_SET_XFP,
} from "Actions/keyActions/keyNewWizardActions";
import { defaultBIP32Path } from "Utils/bip32";

const initialState = {
  walletName: "",
  walletCurrency: "",
  walletType: "",
  walletActive: false,
  get bip32_path() {
    return defaultBIP32Path();
  },
  bip32_node: {},
  advanced: false,
  successMessage: "We are redirecting you to your new key automatically.",

  org: {
    uuid: "",
    name: "",
    state: "",
  },
};

export const keyNewWizardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NEW_WALLET_TYPE: {
      return {
        ...state,
        walletType: payload,
      };
    }

    case SET_NEW_WALLET_NAME: {
      return {
        ...state,
        walletName: payload,
      };
    }

    case SET_NEW_WALLET_CURRENCY: {
      return {
        ...state,
        walletCurrency: payload,
      };
    }

    case SET_NEW_WALLET_BIP32_PATH: {
      return {
        ...state,
        bip32_path: payload,
      };
    }

    case WALLET_NEW_SET_XPUB: {
      return {
        ...state,
        xpub: payload,
      };
    }

    case WALLET_NEW_SET_XFP: {
      return {
        ...state,
        xfp: payload,
      };
    }

    case SET_NEW_WALLET_SHOW_ADVANCED: {
      return {
        ...state,
        advanced: payload,
      };
    }

    case SET_NEW_WALLET_ACTIVE: {
      return {
        ...state,
        walletActive: payload,
      };
    }

    case SET_NEW_WALLET_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: payload,
      };
    }

    case SET_NEW_WALLET_ORG: {
      return {
        ...state,
        org: payload,
      };
    }

    case RESET_NEW_KEY_FLOW: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
