import { useQuery, UseQueryOptions } from "react-query";

import { ListPurchasablesResponse } from "Specs/v2/components";

import { PurchasablesAPI } from "..";
import { Purchasable } from "../purchasables";

const queryKeys = {
  all: ["purchaseables"] as const,
};

export const usePurchasables = (options?: UseQueryOptions<ListPurchasablesResponse>) =>
  useQuery<ListPurchasablesResponse>(
    queryKeys.all,
    () => PurchasablesAPI.getPurchasables(),
    options
  );

export const getPurchaseable = (
  purchasables: ListPurchasablesResponse["purchasables"],
  sku: string
): Purchasable => purchasables.find(p => p.sku === sku) || { sku, usdPrice: "0" };

export const useSKUPurchasable = (sku: string): Purchasable => {
  const { data } = usePurchasables();
  return getPurchaseable(data?.purchasables || [], sku);
};
