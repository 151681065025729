export function hasFormFieldError(data) {
  if (data && data.errors) {
    return Object.keys(data.errors).length > 0;
  }
  return false;
}

export function hasAnError(errors, field) {
  if (errors) {
    if (field) {
      if (errors[field]) {
        if (errors[field].length > 0) {
          return true;
        }
      }
    } else {
      for (var f in errors) {
        if (errors[f]) {
          if (errors[f].length > 0) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export function hasManyErrors(errors, field) {
  if (errors) {
    if (field) {
      if (errors[field]) {
        if (errors[field].length > 1) {
          return true;
        }
      }
    } else {
      var totalErrors = 0;
      for (var f in errors) {
        totalErrors += (errors[f] || []).length;
      }
      return totalErrors > 1;
    }
  }
  return false;
}

export function hasAnErrorBesides(errors, field) {
  if (errors) {
    for (var f in errors) {
      if (f !== field) {
        if (errors[f]) {
          if (errors[f].length > 0) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export function updatedStatus(fieldName, newFieldErrors, existingStateErrors) {
  if (newFieldErrors || hasAnErrorBesides(existingStateErrors, fieldName)) {
    return "error";
  } else {
    return "new";
  }
}

export function noErrors(fieldName) {
  var errors = {};
  errors[fieldName] = null;
  return errors;
}

export function updatedErrors(fieldName, newFieldErrors, existingStateErrors) {
  return {
    ...existingStateErrors,
    ...(newFieldErrors || noErrors(fieldName)),
  };
}

export function updatedField(state, action, field) {
  var newState = {
    ...state,
    ...{
      status: updatedStatus(field, action.errors, state.errors),
      errors: updatedErrors(field, action.errors, state.errors),
    },
  };
  if (field.indexOf(".") >= 0) {
    var [a, b] = field.split(".");
    newState[a][b] = action[field];
  } else {
    newState[field] = action[field];
  }
  return newState;
}

export const editCommonMessages = errorMessage => {
  if (
    errorMessage.includes("CSRF token has expired") ||
    // expired CSRF tokens can cause a missing token message
    // to return so we want to catch that as well
    errorMessage.includes("CSRF session token is missing")
  )
    return "Your session has expired. Please refresh the page";

  switch (errorMessage) {
    case "The CSRF token is missing.":
      return "Your session has ended. Please log in.";
    case "The CSRF session token has expired.":
      return "Your session has ended. Please log in.";
    case "The CSRF tokens do not match.":
      return "Your credentials do not match. Please log in.";
    case "The CSRF token is invalid.":
      return "Your session has ended. Please log in.";
    case "The referrer does not match the host.":
      return "Cannot connect to server.";
    case "The referrer header is missing.":
      return "Please try again using https://";
    case "Only anonymous access is available to this resource.":
      return "You are already logged in.";
    default:
      return errorMessage;
  }
};
