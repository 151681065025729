import React, { FC, ReactNode } from "react";

import { Loader } from "@unchained/component-library";
import { Navigate } from "react-router-dom";
import { RequireAtLeastOne } from "type-fest";

import { ErrorPage } from "Components/ErrorBoundaries/ErrorPage";
import { GetAccount, useIraOrg, withAccount } from "Shared/api";
import {
  CompleteOrgAccountTypeEnum,
  CompleteOrgAllowedActionsEnum,
  CompleteOrgTypeEnum,
} from "Specs/v1/getAccount/200";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { featureOn } from "Utils/config";
import { useEasyToasts } from "Utils/toasts";

import { LockedForPayment } from "./LockedForPayment/LockedForPayment";

type BlockerOptions = {
  requireAction?: (account: GetAccount) => JSX.Element;
  requireAccountTypes?: (account: GetAccount) => JSX.Element;
  requireFeature?: (account: GetAccount) => JSX.Element;
  requireAnyOfFeature?: (account: GetAccount) => JSX.Element;
  requireOrgTypes?: (account: GetAccount) => JSX.Element;
  lockForPayment?: (account: GetAccount) => JSX.Element;
  requireIraConsent?: (account: GetAccount) => JSX.Element;
};

type BlockerName = keyof BlockerOptions;

type BaseProps = {
  /* If true, the alternate will be rendered if the org state is `pending_payment_renewal`. */
  lockForPayment?: boolean;
  /* Alternate will be rendered (default: redirect to /home)
   * unless the current org is one of the specified types. */
  requireOrgTypes?: CompleteOrgTypeEnum[];
  /* Alternate will be rendered (default: redirect to /home)
   * unless the current org is one of the specified account types. */
  requireAccountTypes?: CompleteOrgAccountTypeEnum[];
  /* Alternate will be rendered (default: redirect to /home)
   * unless the current org has the specified feature. */
  requireFeature?: string;
  /* Alternate will be rendered (default: redirect to /home)
   * unless the current org has any of the specified features. */
  requireAnyOfFeature?: string[];
  /* Alternate will be rendered (default: redirect to /home)
   * unless the current user has the specified allowed action on the org. */
  requireAction?: CompleteOrgAllowedActionsEnum;
  /** If the org is an IRA org, the fortis_agreement_consented_at field must be defined. */
  requireIraConsent?: boolean;
  /* An (un-rendered) React component that will be rendered if the protections pass. Only Component OR element can be passed. */
  Component?: FC | React.ComponentType;
  /* A (pre-rendered) React element/node that will be rendered if the protections pass. Only element OR Component can be passed. */
  children?: ReactNode;
  /* On a per-protection basis, what to be rendered if the protections fail. By default, this is a redirect to "/home", except for lockForPayment, which renders a specific page. */
  alternatives?: Partial<BlockerOptions>;
  waitForFullAccount?: boolean;
};

type Props = RequireAtLeastOne<BaseProps, "Component" | "children">;

export const NavigateWithToast = ({
  to = "/home",
  title = "Not allowed",
  description = "You do not have permission to view this page.",
}: {
  to: string;
  title?: string;
  description?: string;
}) => {
  const { showErrorToast } = useEasyToasts();
  showErrorToast(undefined, { title, description });
  return <Navigate to={to} />;
};

const defaultAlternatives: BlockerOptions = {
  requireAction: () => <NavigateWithToast to="/home" />,
  requireAccountTypes: () => <NavigateWithToast to="/home" />,
  requireFeature: () => <NavigateWithToast to="/home" />,
  requireAnyOfFeature: () => <NavigateWithToast to="/home" />,
  requireOrgTypes: () => <NavigateWithToast to="/home" />,
  lockForPayment: account => <LockedForPayment org_uuid={account.currentOrg.uuid} />,
  requireIraConsent: () => <NavigateWithToast to="/home" />,
};

export const _getBlockerName = (
  org: CompleteOrg,
  props: BaseProps,
  iraOrg?: IraOrg
): BlockerName | undefined => {
  const {
    lockForPayment,
    requireOrgTypes,
    requireAccountTypes,
    requireFeature,
    requireAnyOfFeature,
    requireAction,
    requireIraConsent,
  } = props;
  const { type, state, account_type: accountType, allowed_actions: allowedActions } = org;

  // prettier-ignore
  // Each entry is a tuple of [condition, blockerName]
  const conditionBlockerArray: [boolean, BlockerName][] = [
    [
      requireAction && !allowedActions.includes(requireAction),
      "requireAction"],
    [
      requireOrgTypes && !requireOrgTypes?.includes(type),
      "requireOrgTypes"],
    [
      requireAccountTypes && !requireAccountTypes.includes(accountType),
      "requireAccountTypes"],
    [
      requireAnyOfFeature && !requireAnyOfFeature.some(feature => featureOn(feature, org)),
      "requireAnyOfFeature",
    ],
    [
      requireFeature && !featureOn(requireFeature, org),
      "requireFeature"
    ],
    [
      lockForPayment && state === "pending_payment_renewal",
      "lockForPayment"
    ],
    [
      requireIraConsent && accountType === "ira" && !(iraOrg?.iraPlan?.fortisAgreementAcceptedAt || iraOrg?.iraPlan?.fortisAgreementDeclinedAt),
      "requireIraConsent",
    ],
  ];

  return conditionBlockerArray.find(([condition]) => condition)?.[1] || undefined;
};

export const _renderBlocker = (
  blockerName: BlockerName,
  account: GetAccount,
  alternatives: Partial<BlockerOptions>
) => {
  const alt = { ...defaultAlternatives, ...(alternatives || {}) }[blockerName];
  return alt ? alt(account) : null;
};

/**
 *
 * This component seeks to encapsulate, in one clear place, all of the logic related to blocking content (generally, but not exclusively, a route)
 * based on features of the current org (and in the future, potentially the user, etc).
 *
 * It takes a number of optional props that can be used to block the sub-element based on the current org's features:
 * - `lockForPayment` will block the sub-element if the org is locked for payment.
 * - `requireOrgTypes` will block the sub-element if the org is not of the specified type(s).
 * - `requireAccountTypes` will block the sub-element if the org is not of the specified account type(s).
 * - `requireFeature` will block the sub-element if the org does not have the specified feature.
 * - `requireAnyFeature` will block the sub-element if the org does not have any of the specified features.
 * - `requireAction` will block the sub-element if current user does not have the specified allowed action on the org.
 * - `requireIraConsent` will block the sub-element if the org is an IRA org and the user has not consented to the Fortis agreement.
 * - `waitForFullAccount` If true, a loader will display until the GET org request has resolved the full org.
 *
 * The sub-element can be either a Component (unrendered; will be rendered) or children.
 *
 * If blocked, an `alternative` will be rendered. By default this is a redirect to "/home" (except for LockedForPayment),
 * but it can be replaced with an arbitary ReactNode or object mapping block type to alternative.
 */
export const Protect = withAccount<Props>(props => {
  const { requireIraConsent, Component, waitForFullAccount, children, ...account } = props;

  const org = account?.currentOrg;
  const getIra = useIraOrg(org?.uuid, {
    enabled: requireIraConsent && org?.uuid && org?.account_type === "ira",
  });

  if (waitForFullAccount && !account.currentOrgComplete) return <Loader className="h-screen" />;
  if (getIra.isLoading) return <Loader className="h-screen" />;
  if (getIra.error) return <ErrorPage />;

  const blockerName = _getBlockerName(org, props, getIra?.data?.org);

  if (blockerName) {
    const blocker = _renderBlocker(blockerName, account, props.alternatives);
    if (blocker) {
      return blocker;
    } else {
      console.error(`No alternative found for blocking condition: ${blockerName}`);
      return <ErrorPage />;
    }
  }

  return Component ? <Component /> : <>{children}</>;
});
