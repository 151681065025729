import React from "react";

import { useTradingOnboardingCardStatus } from "Components/TradingDesk/hooks";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { TradingOnboardingCompletedCard } from "./TradingOnboardingCompletedCard";
import { TradingOnboardingCardIncomplete } from "./TradingOnboardingIncompleteCard";

interface TradingOnboardingCardProps {
  forceOnboardingCompletedCardToShow?: boolean;
  currentOrg: CompleteOrg;
}

/**
 * Component which displays a card describing steps needed to activate trading.
 * @returns {React.Component}
 */
const TradingOnboardingCard = ({
  forceOnboardingCompletedCardToShow = false,
  currentOrg,
}: TradingOnboardingCardProps) => {
  const { showTradingOnboardingIncompleteCard, showTradingOnboardingCompletedCard } =
    useTradingOnboardingCardStatus(forceOnboardingCompletedCardToShow, currentOrg);

  if (showTradingOnboardingIncompleteCard) {
    return <TradingOnboardingCardIncomplete currentOrg={currentOrg} />;
  } else if (showTradingOnboardingCompletedCard) {
    return (
      <TradingOnboardingCompletedCard
        currentOrg={currentOrg}
        isCloseable={!forceOnboardingCompletedCardToShow}
      />
    );
  } else {
    return null;
  }
};

export { TradingOnboardingCard };
