import React from "react";

import { PatternField, PatternFieldProps } from "@unchained/component-library";
import { useField } from "formik";
import { merge } from "lodash";
import type { NumberFormatValues } from "react-number-format";

/**
 * A UCL pattern field wrapped with formik
 * Passed a pattern (eg "(###) ###-####"), it uses it to mask the input.
 * The name given to the field is used to access the value in formik.
 */
export const FormikPatternField = ({
  name,
  helperText,
  showError = true,
  ...props
}: FormikPatternFieldProps) => {
  const [{ onChange: _onChange, ...field }, { error, touched }, { setValue }] = useField(name);

  const onValueChange = ({ value }: NumberFormatValues) => {
    setValue(value);
  };

  const hasError = showError && error && touched;

  const finalProps = merge(field, props, {
    value: field.value || "",
    error: hasError,
    helperText: hasError ? error : helperText,
    onValueChange,
  });

  return <PatternField {...finalProps} />;
};

interface FormikPatternFieldProps extends PatternFieldProps {
  name: string;
  showError?: boolean;
}
