import { exploreAddressURL } from "Utils/wallet";

import { ExploreBlockChain, ExploreBlockChainClasses } from "./ExploreBlockChain";

export const ExploreAddress = ({
  address,
  unit,
  showAddress,
  classes = {},
}: {
  address?: string;
  unit: string;
  showAddress?: boolean;
  classes?: ExploreBlockChainClasses;
}) => {
  const exploreUrl = exploreAddressURL(window.TREFOIL_CONFIG.wallet.testnet, unit, address);

  return (
    <ExploreBlockChain classes={classes} url={exploreUrl} item={address} showItem={showAddress} />
  );
};
