import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const TrezorLogo = ({ viewBox = "0 0 24 24", htmlColor = "black", classes = {} }, props) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={classes}>
    <path d="M17.8 7.4V5.6C17.8 2.5 15.2 0 12 0C8.80005 0 6.20005 2.5 6.20005 5.6V7.4H3.80005V20.2L12 24L20.2001 20.2V7.4H17.8ZM9.20005 5.6C9.20005 4.2 10.4 3 12 3C13.6 3 14.8 4.2 14.8 5.6V7.4H9.20005V5.6ZM16.9 18L12 20.3L7.10005 18V10.3H16.9V18Z" />
  </SvgIcon>
);

TrezorLogo.propTypes = {
  classes: PropTypes.object,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
