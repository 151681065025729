import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./DownForMaintenance.module.scss";

const DownForMaintenance = ({ userAuthenticated }) => (
  <div
    className={classnames({
      [styles.publicApp]: !userAuthenticated,
      [styles.privateApp]: userAuthenticated,
    })}
  >
    <h1> Down for Maintenance </h1>
    <p>Unchained is currently down for maintenance.</p>
  </div>
);

DownForMaintenance.propTypes = {
  userAuthenticated: PropTypes.bool.isRequired,
};

DownForMaintenance.defaultProps = {
  userAuthenticated: false,
};

export default DownForMaintenance;
