import React from "react";

import { Checkbox } from "@unchained/component-library";

import { SigningKeyCard } from "./SigningKeyCard";

type SignKeyCardSelectProps = {
  title: string;
  isDisabled: boolean;
  onClick: () => void;
  isKeySelected: boolean;
  isUnchained: boolean;
  isDelegatedKey: boolean;
  logoSlug: string | null;
};
export const SignKeyCardCheckbox = ({
  title,
  isDisabled,
  onClick,
  isKeySelected,
  isUnchained,
  isDelegatedKey,
  logoSlug,
}: SignKeyCardSelectProps) => {
  const cardOpacity = isKeySelected ? "opacity-100" : isDisabled ? "opacity-20" : "opacity-50";
  const checkboxOpacity = isDisabled ? "opacity-20" : undefined;

  return (
    <div
      className={`flex flex-col items-center justify-center`}
      onClick={isDisabled ? () => {} : onClick}
      data-testid={`signKeyCardCheckBox-${title}`}
    >
      <span className={`${cardOpacity}`}>
        <SigningKeyCard
          title={title}
          isUnchained={isUnchained}
          isDelegatedKey={isDelegatedKey}
          logoSlug={logoSlug}
          className={"!cursor-pointer"}
        />
      </span>
      <Checkbox
        label={""}
        name={title}
        disabled={isDisabled}
        noCard={true}
        checked={isKeySelected}
        className={!isKeySelected ? `!border-2 !border-gray-800 ${checkboxOpacity}` : undefined}
      />
    </div>
  );
};
