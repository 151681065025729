export const SET_DEVICE_CHECK_CURRENCY = "SET_DEVICE_CHECK_CURRENCY";
export const SET_DEVICE_CHECK_WALLET_TYPE = "SET_DEVICE_CHECK_WALLET_TYPE";
export const SET_DEVICE_CHECK_BIP32_NODE = "ACCOUNT_KEY/CHECK/SET/BIP32_NODE";
export const SET_DEVICE_CHECK_BIP32_PATH = "ACCOUNT_KEY/CHECK/SET/BIP32_PATH";
export const SET_DEVICE_CHECK_KEY_UUID = "SET_DEVICE_CHECK_KEY_UUID";
export const SET_DEVICE_CHECK_KEY_UNIT = "SET_DEVICE_CHECK_KEY_UNIT";
export const RESET_DEVICE_CHECK_FLOW = "RESET_DEVICE_CHECK_FLOW";
export const SET_DEVICE_CHECK_WALLET_ACTIVE = "SET_DEVICE_CHECK_WALLET_ACTIVE";
export const SET_DEVICE_CHECK_SUCCESS_MESSAGE = "SET_DEVICE_CHECK_SUCCESS_MESSAGE";

/**
 * Set Key Name action in New Key Flow
 * @param walletCurrency - User selection of currency, enum: ['BTC', 'ETH'], will be predetermined if created in context
 */
export const setWalletCurrencyDeviceCheckAction = walletCurrency => ({
  type: SET_DEVICE_CHECK_CURRENCY,
  payload: walletCurrency,
});

/**
 * Set Hardware Wallet Type in Key Check Flow
 * @param walletType - User selection of walletType, enum: ['Trezor', 'Ledger']
 */
export const setWalletTypeDeviceCheckAction = walletType => ({
  type: SET_DEVICE_CHECK_WALLET_TYPE,
  payload: walletType,
});

/**
 * Set Bip32 Path action in Key Check Flow
 * @param BIP32Path - Set by key being checked, string
 */
export const setBIP32PathDeviceCheckAction = BIP32Path => ({
  type: SET_DEVICE_CHECK_BIP32_PATH,
  payload: BIP32Path,
});

/**
 * Set Bip32 Path action in Key Check Flow
 * @param BIP32Node - Returned from TrezorConnect
 */
export const setBIP32NodeDeviceCheckAction = BIP32Node => ({
  type: SET_DEVICE_CHECK_BIP32_NODE,
  payload: BIP32Node,
});

export const resetBIP32NodeDeviceCheckAction = () => ({
  type: SET_DEVICE_CHECK_BIP32_NODE,
  payload: {},
});

export const setWalletActiveDeviceCheckAction = active => ({
  type: SET_DEVICE_CHECK_WALLET_ACTIVE,
  payload: active,
});

export const setKeyUuidDeviceCheckAction = uuid => ({
  type: SET_DEVICE_CHECK_KEY_UUID,
  payload: uuid,
});

export const setSuccessMessageDeviceCheckAction = successMessage => ({
  type: SET_DEVICE_CHECK_SUCCESS_MESSAGE,
  payload: successMessage,
});

export const resetDeviceCheckWizardAction = () => ({
  type: RESET_DEVICE_CHECK_FLOW,
  payload: null,
});
