import React, { useContext } from "react";

import { ModalContent, ModalFooter } from "@unchained/component-library";

import { Success } from "Components/Shared/Elements/Success";
import { AppModalManager } from "Shared/components/Modals";

import { WalletRegistrationContext } from "../WalletRegistrationWizard";

export const RegistrationComplete = () => {
  const { setStepIndex, setDeviceType, successMessage } = useContext(WalletRegistrationContext);
  const handleRegisterAnotherDevice = () => {
    setDeviceType(null);
    setStepIndex(0);
  };
  return (
    <div className="text-center">
      <ModalContent className="mt-0">
        <Success size="sm" successText="" />
        <h2>{successMessage.title}</h2>
        <p className="text-sm text-gray-500">{successMessage.message}</p>
      </ModalContent>
      <ModalFooter
        actions={[
          { children: "I'm done", onClick: () => AppModalManager.close() },
          { children: "Register another device", onClick: handleRegisterAnotherDevice },
        ]}
      ></ModalFooter>
    </div>
  );
};
