import { useMutation } from "react-query";

import { CreateEntityRequest, PutEntityRequest } from "Specs/v1";
import { AddressRoleEnum } from "Specs/v1/getAccount/200";
import { Address } from "Specs/v1/updateAddresses/request";

import { EntityAPI } from "../entityApi";

export const useCreateEntity = () =>
  useMutation((entity: CreateEntityRequest) => EntityAPI.Create(entity));

export const usePutEntity = () =>
  useMutation((entity: PutEntityRequest & { uuid: string; orgUuid: string }) =>
    EntityAPI.Put(entity.uuid, entity)
  );

export const useUpdateEntityAddress = (entityUuid: string) =>
  useMutation((address: Address & { role: AddressRoleEnum }) =>
    EntityAPI.UpdateAddress(entityUuid, address)
  );
