import React, { Component, useState } from "react";

import {
  GetApp as DownloadIcon,
  Search as PreviewIcon,
  CloudUpload as UploadIcon,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  Dropdown,
  Modal,
  ModalTitle,
  ModalHeader,
  ModalContent,
  ModalFooter,
  FileUploader,
} from "@unchained/component-library";
import PropTypes from "prop-types";

import { ID_TYPE_OPTIONS } from "Routes/onboard/(steps)";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";

import { FilePreviewModal } from "../FilePreviewModal";
import { SummaryTableCell } from "./SummaryTableCell";

const BIZ_DOCUMENT_TYPE_OPTIONS = [
  { label: "Articles of incorporation", value: "articles_of_incorporation" },
  { label: "Proof of business address", value: "proof_of_business_address" },
  { label: "Additional upload field", value: "certificate_of_good_standing" },
];

class FileCellBase extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    thumbnail_data: PropTypes.string,
    onDownload: PropTypes.func,
    onUpload: PropTypes.func,
    isBusiness: PropTypes.bool,
  };

  state = {
    isUploadModalOpen: false,
    documentType: undefined,
    selectedFile: undefined,
    isBusiness: this.props.isBusiness || false,
  };

  handleUploadClick = () => {
    this.setState(prevState => ({ isUploadModalOpen: !prevState.isUploadModalOpen }));
    AppModalManager.close();
  };

  render() {
    const { name, thumbnail_data, onDownload, onUpload } = this.props;
    const { isUploadModalOpen } = this.state;

    return (
      <>
        <SummaryTableCell>
          <div className="flex items-center gap-1">
            <span className="grow">{name}</span>
            {thumbnail_data && (
              <Tooltip title="Preview">
                <IconButton size="small" onClick={this.openPreviewModal}>
                  <PreviewIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {onDownload && (
              <Tooltip title="Download">
                <IconButton size="small" onClick={onDownload}>
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {onUpload && (
              <Tooltip title="Upload">
                <IconButton size="small" onClick={this.handleUploadClick}>
                  <UploadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </SummaryTableCell>
        {isUploadModalOpen && (
          <Modal
            onDismiss={() => {
              this.handleUploadClick();
            }}
          >
            <ModalHeader>
              <ModalTitle>Upload Document</ModalTitle>
            </ModalHeader>
            <ModalContent>
              <div className="m-1">
                {this.state.isBusiness ? (
                  <div>
                    <h3 className="mb-2 mt-6">Business Document</h3>
                    <p>
                      Upload business documents (e.g. articles of incorporation, proof of business
                      address)
                    </p>
                  </div>
                ) : (
                  <>
                    <h3 className="mb-2 mt-6">Identification Document</h3>
                    <p>
                      Please upload a valid driver's license, passport, or other government-issued
                      photo ID. If you reside outside of the United States, a valid passport is
                      required.
                    </p>
                    <p>
                      Please ensure that all four corners of the form of identification uploaded are
                      visible and the text in the picture is clear.
                    </p>
                  </>
                )}
                <br />
                <Dropdown
                  label="Document type"
                  options={this.state.isBusiness ? BIZ_DOCUMENT_TYPE_OPTIONS : ID_TYPE_OPTIONS}
                  fullWidth
                  className="mb-4"
                  value={this.state.documentType}
                  onSelect={event => {
                    this.setState({ documentType: event.value });
                  }}
                />
                <FileUploader
                  acceptableFormats={["png", "jpeg", "jpg", "pdf"]}
                  onAttach={file => {
                    this.setState({ selectedFile: file });
                  }}
                />
              </div>
            </ModalContent>
            <ModalFooter
              actions={[
                {
                  children: "Confirm",
                  onClick: () => {
                    const { documentType, selectedFile } = this.state;
                    onUpload(documentType, selectedFile);
                    this.handleUploadClick();
                  },
                },
                {
                  children: "Cancel",
                  onClick: () => {
                    this.handleUploadClick();
                  },
                },
              ]}
            />
          </Modal>
        )}
      </>
    );
  }

  openPreviewModal = () => {
    const { previewMessage, name, label, thumbnail_data } = this.props;
    AppModalManager.open(() => {
      return (
        <FilePreviewModal
          previewMessage={previewMessage}
          name={name}
          label={label}
          thumbnail_data={thumbnail_data}
        />
      );
    });
  };
}

export const FileCell = FileCellBase;
