import { summaryRowDatum, SummaryRowDatum } from "Components/Shared/Elements/Summary/SummaryTable";
import { initEmployment } from "Routes/onboard/[uuid]/(individual)/individualOrgValsAndValidations";
import { docTitleMap } from "Shared/api/hooks";
import "Specs/v1/getAccount/200";
import {
  Address,
  Citizenship,
  DecedentPerson,
  Document,
  EntityBeneficiary,
  IraOrg,
  PersonBeneficiary,
  PrincipalPerson,
} from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { titleCase } from "Utils/strings";

export const basicInfoData = (person: PrincipalPerson | DecedentPerson): SummaryRowDatum[] => {
  const rows = [
    summaryRowDatum(person, "firstName"),
    summaryRowDatum(person, "middleName"),
    summaryRowDatum(person, "lastName"),
    summaryRowDatum(person, "dob", { title: "Date of birth" }),
    summaryRowDatum(person, "ssn", {
      title: "Social security number",
      maskedValue: (person as { maskedSsn: string }).maskedSsn,
    }),
  ];

  if ("dateOfDeath" in person && person.dateOfDeath) {
    rows.push(summaryRowDatum(person, "dateOfDeath", { title: "Date of death" }));
  }

  return rows;
};

export const addressData = (address: Address): SummaryRowDatum[] => [
  summaryRowDatum(address, "line1"),
  summaryRowDatum(address, "line2"),
  summaryRowDatum(address, "city"),
  summaryRowDatum(address, "zip", { title: "Zip/postal" }),
  summaryRowDatum(address, "state", { title: "State/region" }),
  summaryRowDatum(address, "country"),
];

export const relationshipData = (spouse: PersonBeneficiary): SummaryRowDatum[] => {
  if (!spouse) return [];
  return [
    summaryRowDatum(spouse.person, "firstName"),
    summaryRowDatum(spouse.person, "middleName"),
    summaryRowDatum(spouse.person, "lastName"),
    { title: "Allocation %", value: "100% (as spouse)" },
    summaryRowDatum(spouse, "email"),
    summaryRowDatum(spouse.person, "dob", { title: "Date of birth" }),
    summaryRowDatum(spouse.person, "ssn", {
      title: "Social security number",
      maskedValue: spouse.person.maskedSsn,
    }),
  ];
};

export const citizenshipData = (citizenship: Citizenship): SummaryRowDatum[] => [
  summaryRowDatum(citizenship, "usCitizen", { title: "US citizen" }),
  summaryRowDatum(citizenship, "greenCard", { title: "Has green card" }),
  summaryRowDatum(citizenship, "usPerson", { title: "Is US person" }),
];

export const docData = (docs: Document[], orgUuid: string): SummaryRowDatum[] =>
  docs.map(doc =>
    summaryRowDatum(doc, "name", {
      title: doc.title,
      download: { label: docTitleMap[doc.title], orgUuid },
    })
  );

export const beneficiaryData = (ben: EntityBeneficiary | PersonBeneficiary): SummaryRowDatum[] => {
  const isPerson = "person" in ben;
  const rows: SummaryRowDatum[] = [
    { title: "Type", value: isPerson ? "Person" : "Trust" },
    {
      value: `${ben.percentage}%`,
      title: "Percent allocation",
    },
  ];

  if (isPerson) {
    basicInfoData(ben.person as PrincipalPerson).forEach(row => rows.push(row));
  } else {
    rows.push(summaryRowDatum(ben.entity, "name"));
  }

  if (!isPerson) {
    [
      summaryRowDatum(ben.entity, "incorporatedOn", { title: "Date of incorporation" }),
      summaryRowDatum(ben.entity, "ein", { title: "EIN" }),
    ].forEach(row => rows.push(row));
  }

  rows.push(summaryRowDatum(ben, "email"));
  return rows;
};

export const employmentData = (org: CompleteOrg | IraOrg) => {
  const employment = initEmployment(org);
  return [
    { value: titleCase(employment.status), title: "Status" },
    { value: employment.industry, title: "Industry" },
    { value: employment.employer, title: "Employer" },
    { value: employment.jobTitle, title: "Job title" },
  ];
};

export const conciergeData = (org: IraOrg): SummaryRowDatum[] => [
  { title: "Concierge onboarding", value: org.conciergeOnboarding ? "Yes" : "No" },
  { title: "Hardware wallets", value: (org.devicesRequested ?? "None").toString() },
];
