import React from "react";

import { getDollarValue } from "Components/VaultsView/Show/WithdrawModal/functions";
import { BatchSpendEntryType } from "Components/VaultsView/Show/WithdrawModal/types";
import { useMemoizedState } from "Redux/selectors/hooks";
import { formatCurrency } from "Utils/strings";

import { getBtcTotal } from "./functions";

export const SummaryLine = ({
  batchData,
  batchInvalid,
}: {
  batchData: BatchSpendEntryType[];
  batchInvalid: boolean;
}) => {
  const { value: usdPrice } = useMemoizedState("crypto.prices.current.BTC");

  const btcTotal = getBtcTotal(batchData.map(el => el.amount.value));
  const usdValue = getDollarValue(btcTotal, usdPrice);
  const formattedBtcTotal = formatCurrency(btcTotal, 8, false, true);
  const formattedUsdValue = `$${formatCurrency(usdValue, 2, false, true)}`;

  return (
    <div
      className={`
      ${batchInvalid ? "bg-red-100" : "bg-primary-100"} 
      h-12 
      rounded-md 
      p-3 
      text-gray-600 
    `}
    >
      {/* 
      A text-sm on the parent div does not seem to work as h700 will override 
      it. Also, align-middle doesn't look correct if all are aligned together 
      due to their different text size.
      */}
      <div className="float-left">
        <span className="h700 align-middle text-sm">{batchData.length}</span>
        <span className="align-middle text-sm"> addresses</span>
      </div>
      <div className="float-right">
        <span className="align-middle text-sm">Total: </span>
        <span className="h700 align-middle text-sm">{formattedBtcTotal}</span>
        <span className="align-middle text-sm"> BTC | </span>
        <span className="h700 align-middle text-sm">{formattedUsdValue}</span>
        <span className="align-middle text-sm"> USD</span>
      </div>
    </div>
  );
};
