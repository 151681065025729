import React from "react";

import { FormHelperText, InputAdornment, TextField } from "@mui/material";
import cx from "classnames";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import styles from "./NumberField.module.scss";

export const DeprecatedNumberField = ({
  topLabel,
  topLabelClasses = {},
  inputProps = { className: undefined },
  InputProps = { classes: undefined, endAdornment: undefined },
  unit,
  disabled = false,
  variant = "outlined",
  ...other
}) => {
  return (
    <div>
      {topLabel ? <FormHelperText classes={topLabelClasses}>{topLabel}</FormHelperText> : null}
      <NumericFormat
        customInput={TextField}
        variant={variant}
        inputProps={{
          ...inputProps,
          className: cx(
            inputProps.className,
            inputProps.classes && inputProps.classes.input,
            styles.input
          ),
        }}
        InputProps={{
          ...InputProps,
          classes: {
            ...InputProps.classes,
            disabled: styles.muiInput_disabled,
          },
          endAdornment: unit ? (
            <InputAdornment position="end">
              <span
                className={cx(styles.unit, {
                  [styles.unit_disabled]: disabled,
                })}
              >
                {unit}
              </span>
            </InputAdornment>
          ) : (
            InputProps.endAdornment
          ),
        }}
        disabled={disabled}
        valueIsNumericString
        {...other}
      />
    </div>
  );
};

DeprecatedNumberField.propTypes = {
  topLabel: PropTypes.string,
  topLabelClasses: PropTypes.object,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  unit: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined"]),
};
