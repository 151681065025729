import PropTypes from "prop-types";

// TODO: Add custom PropType for ensuring at least one is present
export const DestinationSelectFieldType = {
  name: PropTypes.string.isRequired,
  includeRedemption: PropTypes.bool,
  includeTransfer: PropTypes.bool,
  includeExternal: PropTypes.bool,
  testId: PropTypes.string,
};
