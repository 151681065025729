import React, { useState } from "react";

import { MobileDateTimePicker as DateTimePicker } from "@mui/x-date-pickers";
import cx from "classnames";
import { capitalize } from "lodash";
import PropTypes from "prop-types";

import { DeprecatedTextField } from "Components/Shared/Elements/Fields/Deprecated/DeprecatedTextField";

import styles from "./DateTimeField.module.scss";

export const DateTimeField = ({
  variant = "outlined",
  InputProps = { classes: undefined },
  bold = true,
  name,
  onChange,
  ...other
}) => {
  const [value, setValue] = useState(other.value || "");
  const label = other.label || name.split(/[-|_]/g).map(capitalize).join(" ");
  return (
    <DateTimePicker
      type="text"
      variant={variant}
      InputProps={{
        ...InputProps,
        classes: {
          ...InputProps.classes,
          input: cx(styles.muiInput, {
            [styles.muiInput_bold]: bold,
          }),
          disabled: styles.muiInput_disabled,
        },
      }}
      value={value}
      onChange={date => {
        (onChange || setValue)(date);
      }}
      renderInput={props => (
        <DeprecatedTextField
          {...props}
          {...InputProps}
          name={name}
          fullWidth={other.fullWidth}
          classes={{
            ...InputProps.classes,
            input: styles.muiInput,
            disabled: styles.muiInput_disabled,
          }}
          helperText={other.helperText}
          label={label}
        />
      )}
      {...other}
    />
  );
};

DateTimeField.propTypes = {
  variant: PropTypes.string,
  InputProps: PropTypes.object,
  bold: PropTypes.bool,
};
