import React, { ReactElement } from "react";

import cx from "classnames";

import styles from "./TransactionAmountSubtext.module.scss";

interface TransactionAmountSubTextProps {
  isValidAmount: boolean;
  label: string;
  text: string;
  suffixChild?: ReactElement;
}

/**
 * Component that displays sub text related to the transaction amount.
 * @param {bool} isValidAmount - Boolean related to is the transaction amount is valid.
 * @param {string} label - label describing the text.
 * @param {string} text - the text.
 * @param {React.Component} suffixChild - an optional element that will display to the right of the text.
 * @returns {React.Component}
 */
export const TransactionAmountSubtext = ({
  isValidAmount,
  label,
  text,
  suffixChild,
}: TransactionAmountSubTextProps) => {
  return (
    <p className={styles.label}>
      {label}:{" "}
      <span
        className={cx({
          [styles.text]: isValidAmount,
          [styles.defaultDisplay]: !isValidAmount,
        })}
      >
        {text}
        {suffixChild}
      </span>
    </p>
  );
};
