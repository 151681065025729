import { cloneDeep, omit, set } from "lodash";

import { AccountUser } from "Specs/v1/getAccount/200";

export type CurrentUser = AccountUser & {
  roles?: { name: string }[];
};

// All role names which are needed for permissioning.
// We probably don't need to vary the role set by model, but it's possible to overwrite while passing in type definitions below.
export type RoleName = "Finance Transaction Coordinators" | "Bitcoin Transaction Coordinators";

type CamelModel<AllowedAction> = { allowedActions: AllowedAction[] };
type SnakeModel<AllowedAction> = { allowed_actions: AllowedAction[] };

/**
 * All permissions sub-classes should extend this class.
 * A "permission object" relates a data model to the current user, to determine, based on that user's roles, as well as
 * the model's allowed actions, what behavior can be taken on the model object.
 *
 * The BasePermission defines a handful of private utility methods common to most permissioning, and sub-classes define
 * the more specific behavior.
 *
 * Permissions are initialized with:
 *
 * 1. a model object, eg Loan or SigRequest, which it assumes has allowedActions (or allowed_actions)
 * 2. a current user object, which it assumes has roles
 */
export class BasePermission<
  AllowedAction extends string,
  // The type of the model object. Passing this in as a type parameter makes other fields of that model accessible in the sub-class.
  Model = Record<string, unknown>,
  Role extends RoleName = RoleName
> {
  model: CamelModel<AllowedAction> & Model;
  currentUser?: CurrentUser;
  constructor(
    model: SnakeModel<AllowedAction> | CamelModel<AllowedAction>,
    currentUser?: CurrentUser
  ) {
    // Store allowed actions in camelCase internally
    this.model = set(
      cloneDeep(omit(model, "allowed_actions")),
      "allowedActions",
      "allowed_actions" in model ? model.allowed_actions : model.allowedActions
    ) as CamelModel<AllowedAction> & Model;

    this.currentUser = currentUser;
  }

  _hasAllRoles = (...roleNames: Role[]) =>
    roleNames.every(roleName => this._roleNames().includes(roleName));

  _hasAllActions = (...actions: AllowedAction[]) =>
    actions.every(action => this.model.allowedActions.includes(action));

  _roleNames = () => (this.currentUser?.roles || []).map(role => role.name);
}
