import React, { useContext } from "react";

import { LedgerConnectionOverviewModalStep } from "Components/wallets/LedgerConnectionOverviewModalStep";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";

export const ConnectLedgerOverviewStep = () => {
  const { handleNext, walletUuid } = useContext(ConfirmOnDeviceContext);

  return <LedgerConnectionOverviewModalStep walletUuid={walletUuid} handleNext={handleNext} />;
};
