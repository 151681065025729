import { useEffect, useState } from "react";

import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

import { CreateSubscriptionRequest, Subscription } from "Specs/v2/components";

import { SubscriptionAPI } from "..";

export const subscriptionQueryKeys = {
  all: ["subscriptions"] as const,
  list: (orgId: string) => [...subscriptionQueryKeys.all, orgId],
  listBySku: (orgId: string, sku: string) => [...subscriptionQueryKeys.all, orgId, sku],
  get: (subscriptionId: string) => [...subscriptionQueryKeys.all, subscriptionId],
} as const;

type Subscriptions = Awaited<ReturnType<typeof SubscriptionAPI.List>>;
export const useSubscription = (subscriptionId: string, options?: UseQueryOptions<Subscription>) =>
  useQuery<Subscription>(
    subscriptionQueryKeys.get(subscriptionId),
    () => SubscriptionAPI.Get(subscriptionId),
    options
  );

export const useOrgSubscriptions = (orgId: string, options?: UseQueryOptions<Subscriptions>) =>
  useQuery<Subscriptions>(
    subscriptionQueryKeys.list(orgId),
    () => SubscriptionAPI.List({ orgId }),
    options
  );

export const useOrgSubscriptionBySku = (
  orgId: string,
  sku: string,
  options?: UseQueryOptions<Subscriptions>
) =>
  useQuery<Subscriptions>(
    subscriptionQueryKeys.listBySku(orgId, sku),
    () => SubscriptionAPI.List({ orgId, sku }),
    options
  );

export const useFindOrCreateSubscription = (req: CreateSubscriptionRequest): Subscription => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const queryClient = useQueryClient();
  const findOrCreate = async (sku: string) => {
    const { data } = await SubscriptionAPI.List({ orgId: req.orgId, sku });
    queryClient.setQueryData(subscriptionQueryKeys.listBySku(req.orgId, sku), data);
    if (data.length > 0) {
      setSubscription(await SubscriptionAPI.Get(data[0].id));
    } else {
      const sub = await SubscriptionAPI.Create(req);
      setSubscription(sub);
    }
  };

  useEffect(() => {
    if (!req?.items?.length) return;
    const sku = req.items[0].sku;
    findOrCreate(sku);
  }, [req]);
  return subscription;
};
