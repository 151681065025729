import React, { Component } from "react";

import { CloudUpload as UploadIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
  spendingTransactionSelector,
} from "Redux/selectors/spendingSelectors";

import styles from "./ColdcardFileReader.module.scss";

class FileReaderBase extends Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    validFileFormats: PropTypes.string.isRequired,
  };

  static defaultProps = {
    maxFileSize: 2097152, // 2MB
  };

  constructor(props) {
    super(props);
    this.state = {
      state: "",
      errorMessage: "",
      selectedFile: null,
      fileType: props.fileType || "JSON",
      accepted_files: [],
      rejected_files: [],
    };
  }

  handleError = err => {
    this.setState({ state: "error", errorMessage: err });
  };

  singleAcceptedFile = (accepted_files, rejected_files) => {
    return rejected_files.length === 0 && accepted_files.length === 1;
  };

  onDrop = async (accepted_files, rejected_files) => {
    const stateUpdate = { accepted_files, rejected_files };
    const { onSuccess } = this.props;
    const { fileType } = this.state;
    if (this.singleAcceptedFile(accepted_files, rejected_files)) {
      const file = accepted_files[0];
      if (fileType === "JSON") {
        onSuccess(await file.text());
      } else {
        // With PSBT files, the actual spec says it should be stored in binary.
        // But it's not really required, and some vendors (incl. trefoil currently)
        // output the PSBT as base64 text in the PSBT file. We need to be able to
        // support both cases when a signed PSBT is uploaded.
        // Assume it is in the proper format, e.g. binary.
        const psbtData = await file.arrayBuffer();
        const psbtHex = Buffer.from(psbtData).toString("hex");
        // If the binary -> hex conversion starts with this magic number, we're
        // good to go. Otherwise - it was likely not a binary file, meaning
        // it was is probably base64 or hex, so we should try using text instead.
        if (psbtHex.startsWith("70736274ff")) {
          onSuccess(psbtHex);
        } else {
          onSuccess(await file.text());
        }
      }
    } else {
      this.setState(stateUpdate);
    }
  };

  render = () => {
    const { fileType, rejected_files } = this.state;
    const { maxFileSize, selectedSigRequest, validFileFormats } = this.props;
    const showDullDropzone = selectedSigRequest && !selectedSigRequest.active;
    return (
      <div>
        <Dropzone
          className={showDullDropzone ? styles.dropzoneDull : styles.dropzone}
          onDrop={this.onDrop}
          multiple={false}
          minSize={1}
          maxSize={maxFileSize}
          accept={validFileFormats}
        >
          <UploadIcon classes={{ root: styles.uploadIcon }} />
          <p>{fileType === "JSON" ? "Upload the XPUB" : "Upload signed PSBT"}</p>
        </Dropzone>

        {rejected_files.length > 0 && (
          <div className="has-danger invalid-feedback">
            <p>The file you attempted to upload was unacceptable.</p>
          </div>
        )}
      </div>
    );
  };
}

const psbtReaderMapStateToProps = state => {
  return {
    ...spendingOperationSelector(state),
    ...spendingTransactionSelector(state),
    ...spendingSigRequestsSelector(state),
  };
};

const jsonReaderMapStateToProps = state => {
  return {
    bip32Path: state.accountKeys.keyNewWizard.bip32_path,
    currentUser: state.account.user,
  };
};

export const PSBTFileReader = connect(psbtReaderMapStateToProps)(FileReaderBase);

export const JSONFileReader = connect(jsonReaderMapStateToProps)(FileReaderBase);
