export function initialPersonState() {
  return {
    uuid: "",
    status: "new",
    errors: {},

    step: "identity",

    first_name: "",
    middle_name: "",
    last_name: "",
    dob: "",
    ssn: "",

    income: "",
    income_sources: "",
    digital_assets: "",
    bitcoin_sources: [],
    bitcoin_source_notes: "",
    fiat_assets: "",
    fiat_asset_sources: "",

    physical_address: {},
    physical_address_line_1: "",
    physical_address_line_2: "",
    physical_address_line_3: "",
    physical_address_city: "",
    physical_address_state: "",
    physical_address_country: "United States",
    physical_address_zip: "",
    has_physical_address_error: false,

    mailing_address: {},
    mailing_address_line_1: "",
    mailing_address_line_2: "",
    mailing_address_line_3: "",
    mailing_address_city: "",
    mailing_address_state: "",
    mailing_address_country: "",
    mailing_address_zip: "",
    has_mailing_address_error: false,

    mailing_address_is_physical_address: true,

    state_id: {},
    state_id_previous_upload: {},
    state_id_accepted_files: [],
    state_id_rejected_files: [],

    title: "",
    business: false,

    profile_complete: false,
    advanced_profile_complete: false,
    submit_success: false,
  };
}
