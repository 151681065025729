import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  address: "",
  amount: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "WALLET/BITCOIN/SET/ADDRESS":
      return updatedField(state, action, "address");
    case "WALLET/BITCOIN/SET/AMOUNT":
      return updatedField(state, action, "amount");

    case "WALLET/BITCOIN/SEND_REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "WALLET/BITCOIN/SEND_REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "WALLET/BITCOIN/SEND_REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
