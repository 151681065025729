import React, { Component } from "react";

import { Button } from "@mui/material";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./ItemButton.module.scss";

class ItemButtonBase extends Component {
  static propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    onClick: PropTypes.func,
    distinguished: PropTypes.bool,
    active: PropTypes.bool,
    size: PropTypes.string,
    accountKey: PropTypes.object,
    disabled: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    icon: PropTypes.any,
    imageAltText: PropTypes.string,
  };

  static defaultProps = {
    active: false,
    size: "lg",
    ucClasses: {
      button: "",
      label: "",
      iconContainer: "",
      name: "",
      secondaryLabel: "",
      image: "",
      icon: "",
    },
  };

  IconImageChooser = () => {
    const { size, image, imageAltText, ucClasses } = this.props;
    const Icon = this.props.icon;
    if (Icon) {
      return (
        <Icon
          classes={{
            root: classnames([ucClasses.image], styles.logo, styles.image, {
              [styles.xsImage]: size === "xs",
              [styles.smImage]: size === "sm",
              [styles.mdImage]: size === "md",
            }),
          }}
        />
      );
    } else {
      return (
        <img
          src={image}
          alt={imageAltText}
          className={classnames([ucClasses.image], styles.image, {
            [styles.xsImage]: size === "xs",
            [styles.smImage]: size === "sm",
            [styles.mdImage]: size === "md",
          })}
        />
      );
    }
  };

  onClick = e => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { active, disabled, size, name, ucClasses } = this.props;

    return (
      <Button
        classes={{
          root: classnames(ucClasses.button, styles.container, {
            [styles.default]: !active,
            [styles.active]: active,
            [styles.disabled]: disabled,
          }),
          contained: classnames(ucClasses.label, styles.contentContainer, {
            [styles.xsContainer]: size === "xs",
            [styles.smContainer]: size === "sm",
            [styles.mdContainer]: size === "md",
            [styles.lgContainer]: size === "lg",
          }),
        }}
        variant="contained"
        disabled={disabled}
        onClick={this.onClick}
      >
        <div
          className={classnames(styles.iconContainer, {
            [ucClasses.iconContainer]: ucClasses && ucClasses.iconContainer,
          })}
        >
          <this.IconImageChooser />
        </div>
        <div
          className={classnames(styles.textContainer, {
            [styles.smTextContainer]: size === "xs" || size === "sm",
            [styles.lgTextContainer]: size === "md" || size === "lg",
          })}
        >
          {name}
        </div>
      </Button>
    );
  }
}

export const ItemButton = ItemButtonBase;
