import React from "react";

import { Select, MenuItem } from "@mui/material";
import { useField } from "formik";

import { useIsInValidateOnSubmitForm } from "Components/FormFields/Formik";
import { setTouched } from "Components/FormFields/utils";

import styles from "./NumberField.module.scss";

export const FormikMUISelect = ({
  name,
  options,
  placeholder = "",
  ...rest
}: {
  name: string;
  options: { label: string; value: string }[];
  placeholder?: string;
}) => {
  const [field, meta, helpers] = useField(name);
  const validateOnSubmit = useIsInValidateOnSubmitForm();

  return (
    <Select
      name={name}
      variant="outlined"
      {...rest}
      {...field}
      value={field.value}
      displayEmpty
      renderValue={value => {
        const styledPlaceholder = placeholder ? (
          <span className="text-gray-600 opacity-50">{placeholder}</span>
        ) : null;

        if (!value) return styledPlaceholder;
        const option = options.find(o => o.value === value);
        if (!option) return styledPlaceholder;
        return option.label;
      }}
      onChange={event => {
        helpers.setValue(event.target.value);
        setTouched(helpers, true);
        if (validateOnSubmit) helpers.setError(undefined);
      }}
      classes={{ select: styles.input }}
      sx={{ legend: { display: "none" }, fieldset: { marginTop: "4px" } }}
      inputProps={{ "data-testid": `${name}-select` }}
    >
      {options.map(o => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
};
