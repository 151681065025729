import { createSelector } from "reselect";

import { btcFeeCalcEstimate, calculateOutputCount } from "Utils/currency";

import { loanUnitSelector, loanWalletUtxosCountSelector } from "../loanSelectors";
import { outflowFeeRateSelector, outflowPartialSelector } from "../transactionSelectors";

// * -  -  -  -  -  -  -  -  -  -  * //
// * Calculate Fee Rate for loans * //
// * -  -  -  -  -  -  -  -  -  -  * //

// ** Calculate fees for a btc loan transaction  ** //

const outputCountBtcOutflowSelector = createSelector(outflowPartialSelector, calculateOutputCount);

// the first three selectors are the parameters to btcFeeCalcEstimate:
// (feeRateInSatoshis, numOutputs, numInputs)
export const feeEstimateBtcLoanSelector = createSelector(
  outflowFeeRateSelector, // initially set to the default fee rate, and can be changed by the user
  outputCountBtcOutflowSelector, // determined by checking whether or not this is a partial transaction (2 or 1)
  loanWalletUtxosCountSelector, // number of utxos for the loan (#inputs)
  btcFeeCalcEstimate // the calculation function
);

// ** Calculate fees for a eth loan transaction  ** //
// This is done in the common file since it's the same for loans and loans

// ** Calculate fees for this loan ** //
// Based on the this loans unit, we'll determine which way to calculate the fees
export const feeEstimateLoanSelector = createSelector(
  feeEstimateBtcLoanSelector,
  btcFeeEstimate => btcFeeEstimate
);
