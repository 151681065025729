import React, { Component } from "react";

import { TableRow } from "@mui/material";
import PropTypes from "prop-types";

import { formatCurrency } from "Utils/strings";

import { CryptoAmountsCell } from "./CryptoAmountsCell";
import { SummaryTableCell } from "./SummaryTableCell";

class SummaryBalanceRowBase extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    prices: PropTypes.object.isRequired,
  };

  renderAccountBalance = () => {
    const { account, prices } = this.props;
    if (account.product_type === "vault") {
      return (
        <CryptoAmountsCell
          crypto={account.confirmedBalanceWithUnit}
          fiat={account.fiatDisplayed ? "fiat" : ""}
        />
      );
    } else {
      return (
        <CryptoAmountsCell
          crypto={account.collWithUnit}
          fiat={account.collateralValueWithUnit(prices)}
        />
      );
    }
  };

  render() {
    const { account } = this.props;
    return (
      <TableRow>
        <SummaryTableCell component="th">
          {account.product_type === "vault" ? "Current balance" : "Current collateral"}
        </SummaryTableCell>
        {this.renderAccountBalance()}
      </TableRow>
    );
  }
}

export const BalanceRow = SummaryBalanceRowBase;
