import React from "react";

export const Support = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12,23c-3,0-5.7-1.2-7.7-3.1c0,0-0.1,0-0.1-0.1s-0.1-0.1-0.1-0.1C2.2,17.7,1,15,1,12c0-3,1.2-5.7,3.1-7.7
	c0,0,0-0.1,0.1-0.1s0.1,0,0.1-0.1C6.3,2.2,9,1,12,1c3,0,5.7,1.2,7.7,3.1c0,0,0.1,0,0.1,0.1s0.1,0.1,0.1,0.1C21.8,6.3,23,9,23,12
	s-1.2,5.7-3.1,7.7c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.1,0.1C17.7,21.8,15,23,12,23z M6.4,19c1.5,1.2,3.5,2,5.6,2c2.1,0,4.1-0.7,5.6-2
	l-2.1-2.1c-1,0.7-2.2,1.1-3.5,1.1s-2.5-0.4-3.5-1.1L6.4,19z M16.9,15.5l2.1,2.1c1.2-1.5,2-3.5,2-5.6c0-2.1-0.7-4.1-2-5.6l-2.1,2.1
	c0.7,1,1.1,2.2,1.1,3.5S17.6,14.5,16.9,15.5z M5,6.4C3.7,7.9,3,9.9,3,12c0,2.1,0.7,4.1,2,5.6l2.1-2.1C6.4,14.5,6,13.3,6,12
	s0.4-2.5,1.1-3.5L5,6.4z M12,8c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C16,9.8,14.2,8,12,8z M6.4,5l2.1,2.1
	C9.5,6.4,10.7,6,12,6s2.5,0.4,3.5,1.1L17.6,5c-1.5-1.2-3.5-2-5.6-2S7.9,3.7,6.4,5z"
    />
    <rect fill="none" width="24" height="24" />
  </svg>
);
