import React from "react";

export const UserSquare = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M17.2,23H6.8c-1.4,0-2.3,0-3.1-0.2c0,0,0,0-0.1,0c-0.2-0.1-0.3-0.1-0.5-0.2c-0.8-0.4-1.4-1-1.7-1.7
	C1,20,1,19,1,17.2V6.8C1,5,1,4,1.4,3.2c0.4-0.8,1-1.4,1.7-1.7C4,1,5,1,6.8,1h10.4C19,1,20,1,20.8,1.4c0.8,0.4,1.4,1,1.7,1.7
	C23,4,23,5,23,6.8v10.4c0,1.8,0,2.8-0.4,3.6c-0.4,0.8-1,1.4-1.7,1.7c-0.2,0.1-0.3,0.1-0.5,0.2c0,0,0,0-0.1,0
	C19.5,23,18.6,23,17.2,23z M5,21c0.4,0,1,0,1.8,0h10.4c0.8,0,1.3,0,1.8,0c0-0.2,0-0.4,0-0.6c-0.2-1.2-1.2-2.1-2.4-2.4
	C16.3,18,15.8,18,15,18H9c-0.8,0-1.3,0-1.6,0.1c-1.2,0.2-2.1,1.2-2.4,2.4C5,20.6,5,20.7,5,21z M9,16h6c1,0,1.5,0,2,0.1
	c1.9,0.4,3.4,1.8,3.9,3.7c0.2-0.5,0.2-1.2,0.2-2.6V6.8c0-1.5,0-2.3-0.2-2.7c-0.2-0.4-0.5-0.7-0.9-0.9C19.5,3,18.7,3,17.2,3H6.8
	C5.3,3,4.5,3,4.1,3.2C3.7,3.4,3.4,3.7,3.2,4.1C3,4.5,3,5.3,3,6.8v10.4c0,1.3,0,2.1,0.2,2.6c0.5-1.9,2-3.3,3.9-3.7C7.5,16,8,16,9,16z
	 M12,14.5c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S14.8,14.5,12,14.5z M12,6.5c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,6.5,12,6.5z
	"
    />
    <rect fill="none" width="24" height="24" />
  </svg>
);
