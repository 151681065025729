import type { PolicyHmac, RootFingerprint } from "@caravan/wallets";
import { PaginationState } from "@tanstack/react-table";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  GetFreshSlice200,
  GetSlices200,
  GetWalletConfig200,
  GetWalletKeys200,
  ListOrgWalletConfigs200,
} from "Specs/v1";
import { GetSlicesQueryParams } from "Specs/v1/getSlices/params/query";
import { ProductType, ProductUuid } from "Specs/v1/getWallets/params/path";
import { collapseProductType } from "Utils/collapseProductType";

import { OrgAPI } from "..";
import { WalletAPI, WalletUuid } from "../walletApi";
import { useGetPaginatedApiQuery } from "./helpers";
import { useGetLoanQuery, useGetVaultQuery } from "./products";

export const walletRequestKeys = {
  all: ["wallets"] as const,
  showWallet: uuid => [...walletRequestKeys.all, "show", uuid],
  getSlices: (uuid: WalletUuid, filters: GetSlicesQueryParams, pagination: PaginationState) => [
    ...walletRequestKeys.showWallet(uuid),
    "slices",
    { page: filters, ...filters, ...pagination },
  ],
  getConfig: uuid => [...walletRequestKeys.all, "config", uuid],
  slice: (uuid: WalletUuid) => [...walletRequestKeys.showWallet(uuid), "freshSlice"],
  getWalletKeys: (uuid: WalletUuid) => [...walletRequestKeys.showWallet(uuid), "walletKeys"],
  walletConfigsForOrg: (orgUuid: string) => ["wallets", "configs", orgUuid],
};

export const useGetWalletSlices = (
  uuid: WalletUuid,
  pagination: PaginationState = { pageIndex: 0, pageSize: 100 },
  filters: Partial<GetSlicesQueryParams> = {}
) =>
  useGetPaginatedApiQuery<GetSlices200>(
    pagination,
    walletRequestKeys.getSlices(uuid, filters, pagination),
    WalletAPI.GetSlices,
    uuid,
    filters
  );

export const useGetProductsCurrentWalletConfig = (
  productUuid: ProductUuid,
  productType: ProductType,
  sourceWalletUuid: WalletUuid = ""
) => {
  const type = collapseProductType(productType);
  let walletUuid;
  if (sourceWalletUuid) {
    walletUuid = sourceWalletUuid;
  } else if (type === "vaults") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: vault } = useGetVaultQuery(productUuid);
    walletUuid = vault?.current_wallet_uuid;
  } else if (type === "loans") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data: loan } = useGetLoanQuery(productUuid) as {
      // TODO: add current_wallet_uuid to Loan type in api-specs
      data: { current_wallet_uuid: string };
    };
    walletUuid = loan?.current_wallet_uuid;
  }

  return useQuery<GetWalletConfig200, Error>(
    walletRequestKeys.getConfig(walletUuid),
    () => WalletAPI.GetConfig(walletUuid),
    {
      // The query will not execute until the wallet uuid exists
      enabled: !!walletUuid,
    }
  );
};

export const useGetWalletConfig = (uuid: WalletUuid) => {
  return useQuery<GetWalletConfig200, Error>(
    walletRequestKeys.getConfig(uuid),
    () => WalletAPI.GetConfig(uuid),
    { staleTime: 30000 }
  );
};

/**
 * This is useful for updating the cache of the config in response to an update
 * of the hmac. The PUT call for updating the registrations returns an updated config
 * so we can just update the cache locally and avoid refetching anywhere that requires
 * the config for this wallet to be up to date.
 * @param walletUuid uuid of the wallet whose registration we want to update
 * @returns a react query mutation instance
 */
export const useUpdatePolicyHmacs = () => {
  const queryClient = useQueryClient();

  const onSuccess = (
    walletConfig: GetWalletConfig200,
    options: {
      walletUuid: WalletUuid;
      xfp: RootFingerprint;
      ledgerPolicyHmac: PolicyHmac;
    }
  ) => {
    queryClient.setQueryData(walletRequestKeys.getConfig(options.walletUuid), walletConfig);
  };

  return useMutation(WalletAPI.UpdatePolicyRegistration, { onSuccess });
};

export const useDeletePolicyHmac = () => {
  const queryClient = useQueryClient();

  const onSuccess = (
    walletConfig: GetWalletConfig200,
    options: {
      walletUuid: WalletUuid;
      xfp: RootFingerprint;
    }
  ) => {
    queryClient.setQueryData(
      walletRequestKeys.getConfig(options.walletUuid),
      queryClient.setQueryData(walletRequestKeys.getConfig(options.walletUuid), walletConfig)
    );
  };

  return useMutation(WalletAPI.DeletePolicyRegistration, { onSuccess });
};

export const useGetFreshWalletSlice = (walletUuid: string) =>
  useQuery<GetFreshSlice200, Error>(walletRequestKeys.slice(walletUuid), () =>
    WalletAPI.GetFreshSlice(walletUuid)
  );

export const useGetWalletKeys = (walletUuid: WalletUuid) =>
  useQuery<GetWalletKeys200, Error>(walletRequestKeys.getWalletKeys(walletUuid), () =>
    WalletAPI.GetKeys(walletUuid)
  );

export const useGetWalletConfigsForOrg = (orgUuid: string) =>
  useQuery<ListOrgWalletConfigs200>(
    walletRequestKeys.walletConfigsForOrg(orgUuid),
    () => OrgAPI.WalletConfigs(orgUuid),
    { staleTime: 30000 }
  );
