import { API_V2 } from "Shared/api/api";
import {
  CreateACashBalanceWithdrawal200,
  GetCashBalancesActivity200,
  GetAllPendingCashBalanceWithdrawal200,
  GetACashBalanceWithdrawal200,
  DeleteACashBalanceWithdrawal200,
  AdminGetCashBalanceForOrg200,
} from "Specs/v2";
import { CreateCashBalanceWithdrawalRequest } from "Specs/v2/components";
import { GetCashBalancesActivityQueryParams } from "Specs/v2/getCashBalancesActivity/params/query";
import { stringifyQueryParams, Params } from "Utils/uris";

export class CashBalanceAPI {
  static async GetCashBalance(
    orgUuid: string,
    requestQueryParams: GetCashBalancesActivityQueryParams
  ) {
    const stringQueryParams = stringifyQueryParams(requestQueryParams as Params, true);
    const response = await API_V2.Get<GetCashBalancesActivity200>(
      `/org/${orgUuid}/cash-balance?${stringQueryParams}`
    );
    return response.data;
  }

  static async AdminGetCashBalance(orgUuid: string) {
    const response = await API_V2.Get<AdminGetCashBalanceForOrg200>(
      `/admin/org/${orgUuid}/cash-balance`
    );
    return response.data;
  }
  static async CreateCashWithdrawal(orgUuid, body: CreateCashBalanceWithdrawalRequest) {
    const response = await API_V2.Post<CreateACashBalanceWithdrawal200>(
      `/org/${orgUuid}/cash-balance/withdrawals`,
      body
    );
    return response.data;
  }

  static async GetCashBalanceWithdrawals(orgUuid: string) {
    const response = await API_V2.Get<GetAllPendingCashBalanceWithdrawal200>(
      `/org/${orgUuid}/cash-balance/withdrawals`
    );
    return response.data;
  }

  static async GetCashBalanceWithdrawal(orgUuid: string, withdrawalId: string) {
    const response = await API_V2.Get<GetACashBalanceWithdrawal200>(
      `/org/${orgUuid}/cash-balance/withdrawals/${withdrawalId}`
    );
    return response.data;
  }
  static async CancelCashBalanceWithdrawal(orgUuid: string, withdrawalId: string) {
    const response = await API_V2.Delete<DeleteACashBalanceWithdrawal200>(
      `/org/${orgUuid}/cash-balance/withdrawals/${withdrawalId}`
    );
    return response.data;
  }
}
