import { OrgAPI } from "Shared/api";
import { AcceptedMemberUser as User, ChildRole } from "Specs/v1/getOrg/200";

import {
  addPendingMembersToCurrentOrgAction,
  addUserToCurrentOrgRoleAction,
  removeAcceptedMemberFromCurrentOrgAction,
  removePendingMemberFromCurrentOrgAction,
  removeUserFromCurrentOrgRoleAction,
} from "../accountActions/orgActions";

export const removeOrgPendingMemberAction = (orgUUID, email) => async dispatch => {
  await OrgAPI.RemoveMember(orgUUID, email);
  dispatch(removePendingMemberFromCurrentOrgAction(email));
};

//
// Managing org's user roles
//

export const addUserToRolesAction = (orgUUID, user: User, roles: ChildRole[]) => async dispatch => {
  const roleUuids = roles.map(role => role.uuid);
  await OrgAPI.EnsureUsersInRoles(orgUUID, [user.uuid], roleUuids);

  roles.forEach(role => dispatch(addUserToCurrentOrgRoleAction(user, role)));
};

export const removeUserFromRolesAction =
  (orgUUID, user: User, roles: ChildRole[]) => async dispatch => {
    await OrgAPI.EnsureUsersNotInRoles(
      orgUUID,
      [user.uuid],
      roles.map(role => role.uuid)
    );

    roles.forEach(role => {
      dispatch(removeUserFromCurrentOrgRoleAction(user, role));
    });
  };
