import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./DocumentMetadata.module.scss";

class DocumentMetadata extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  render() {
    const { label, description } = this.props;
    if (!label && !description) return null;

    let descriptionElement = null;
    if (typeof description === "function") {
      const Description = description;
      descriptionElement = <Description />;
    } else if (description) {
      descriptionElement = <p className={cn("my-4", styles.description)}>{description}</p>;
    }

    return (
      <div className={styles.metadata}>
        {label ? <h6 className={styles.label}>{label}</h6> : null}
        {descriptionElement}
      </div>
    );
  }
}

export { DocumentMetadata };
