import React from "react";

import { WireInstructionsModal } from ".";

import { Wizard } from "Components/Shared/wizard/Wizard";

import { AwaitingSettlementModal } from "./AwaitingSettlementModal";

/**
 * Component which displays an AwaitingSettlementModal and allows navigation to WireInstructionsModal.
 * @param {Function} setShowModalCloseButton - Function to set Boolean value to show or hide the modal close button.
 * @returns {React.Component}
 */
const AwaitingSettlementWizard = ({ setShowModalCloseButton }) => {
  const manifest = [
    {
      component: AwaitingSettlementModal,
    },
  ];

  return <Wizard childProps={{ setShowModalCloseButton }} manifest={manifest} />;
};

export { AwaitingSettlementWizard };
