import Big, { BigSource } from "big.js";
import validateBtcAddress from "bitcoin-address-validation";
import * as yup from "yup";

import { formatNumber } from "Utils/strings";

const isAbsent = value => value == null;

const BASE58_BITCOIN_MAINNET_ADDRESS_REGEXP = /^[13][A-HJ-NP-Za-km-z1-9]+$/;
const BECH32_BITCOIN_MAINNET_ADDRESS_REGEXP = /^(bc1)[ac-hj-np-z02-9]+$/;
const BASE58_BITCOIN_TESTNET_ADDRESS_REGEXP = /^[mn2][A-HJ-NP-Za-km-z1-9]+$/;
const BECH32_BITCOIN_TESTNET_ADDRESS_REGEXP = /^(tb1|bcrt1)[ac-hj-np-z02-9]+$/;

export function validateBitcoinAddress(this: yup.StringSchema) {
  return this.required("Address must not be blank.")
    .min(
      window.TREFOIL_CONFIG.min_length.bitcoin_address,
      ({ min }) => `Minimum of ${min} characters`
    )
    .max(
      window.TREFOIL_CONFIG.max_length.bitcoin_address,
      ({ max }) => `Maximum of ${max} characters`
    )
    .ensure()
    .test({
      name: "lower-case-bech",
      test: value =>
        value.toLowerCase().startsWith(window.TREFOIL_CONFIG.wallet.testnet ? "tb" : "bc")
          ? value === value.toLowerCase()
          : true,
      message: "Bech32 addresses should be in lowercase",
      exclusive: false,
    })
    .test({
      name: "address-start-testnet",
      test: value =>
        window.TREFOIL_CONFIG.wallet.testnet
          ? BASE58_BITCOIN_TESTNET_ADDRESS_REGEXP.test(value) ||
            BECH32_BITCOIN_TESTNET_ADDRESS_REGEXP.test(value)
          : true,
      message:
        "Address must start with one of 'm', 'n', '2', 'tb' or 'bcrt' followed by letters or digits.",
      exclusive: false,
    })
    .test({
      name: "address-start-mainnet",
      test: value =>
        !window.TREFOIL_CONFIG.wallet.testnet
          ? BASE58_BITCOIN_MAINNET_ADDRESS_REGEXP.test(value) ||
            BECH32_BITCOIN_MAINNET_ADDRESS_REGEXP.test(value)
          : true,
      message: "Address must start with either of '1', '3' or 'bc' followed by letters or digits.",
      exclusive: false,
    })
    .test({
      name: "valid-address",
      test: value => validateBtcAddress(value),
      message: "Please enter a valid bitcoin address.",
      exclusive: false,
    });
}

export function validateMaxStringLength(this: yup.StringSchema, message: string) {
  const maxLength = window.TREFOIL_CONFIG.max_length.string;
  return this.test({
    name: "maxStringLength",
    test: value => isAbsent(value) || value.length <= maxLength,
    message: message ? message : `Please limit to ${formatNumber(maxLength, 0)} characters.`,
    params: { maxLength },
    exclusive: false,
  });
}

export function validateMaxTextLength(this: yup.StringSchema, message?: string) {
  const maxLength = window.TREFOIL_CONFIG.max_length.text;
  return this.test({
    name: "maxTextLength",
    test: value => isAbsent(value) || value.length <= maxLength,
    message: message ? message : `Please limit to ${formatNumber(maxLength, 0)} characters.`,
    params: { maxLength },
    exclusive: false,
  });
}

export interface RangeParams {
  min: BigSource;
  max: BigSource;
  inclusive?: boolean;
}

/** Validate a stringified integer within a given range (eg, from 20-100) */
export function validateNumInRange(
  this: yup.StringSchema,
  { min, max, inclusive = true }: RangeParams,
  message?: string
) {
  return this.test({
    test: value => {
      try {
        return inclusive
          ? Big(value).gte(min) && Big(value).lte(max)
          : Big(value).gt(min) && Big(value).lt(max);
      } catch {
        return false;
      }
    },
    message: message ? message : `Value must be between ${min} and ${max}.`,
  });
}

/** Validate a string of digits of a certain length (either specific or a range) */
export function validateNumDigits(
  this: yup.StringSchema,
  range: [number, number?],
  name = "Field"
) {
  const [start, end] = range;
  const regex = new RegExp(`^\\d{${end ? range.join(",") : start}}$`, "g");

  return this.matches(
    regex,
    `${name} must be ${end ? `between ${start} and ${end}` : start} digit${
      start + (end || 0) === 1 ? "" : "s"
    }.`
  );
}

export function validateSsn(this: yup.StringSchema) {
  return this.numDigits([9], "SSN");
}

export function validateEin(this: yup.StringSchema) {
  return this.numDigits([9], "EIN or Tax ID");
}

export function validateBigNumber(this: yup.StringSchema, message?: string) {
  return this.test({
    name: "is-big-num",
    test: value => {
      try {
        Big(value);
      } catch {
        return false;
      }
      return true;
    },
    message: message ? message : "Invalid number",
  });
}

export function validateEq(this: yup.StringSchema, num: BigSource, message?: string) {
  return this.test({
    name: "is-eq",
    test: value => {
      try {
        return Big(value).eq(num);
      } catch {
        return false;
      }
    },
    params: { num },
    message: message ? message : `Must be equal to ${num}`,
  });
}

export function validateLt(this: yup.StringSchema, num: BigSource, message?: string) {
  return this.test({
    name: "is-lt",
    test: value => {
      try {
        return Big(value).lt(num);
      } catch {
        return false;
      }
    },
    params: { num },
    message: message ? message : `Must be less than ${num}`,
  });
}

export function validateLte(this: yup.StringSchema, num: BigSource, message?: string) {
  return this.test({
    name: "is-lte",
    test: value => {
      try {
        return Big(value).lte(num);
      } catch {
        return false;
      }
    },
    params: { num },
    message: message ? message : `Must be less than or equal to ${num}`,
  });
}

export function validateGt(this: yup.StringSchema, num: BigSource, message?: string) {
  return this.test({
    name: "is-gt",
    test: value => {
      try {
        return Big(value).gt(num);
      } catch {
        return false;
      }
    },
    params: { num },
    message: message ? message : `Must be greater than ${num}`,
  });
}

export function validateGte(this: yup.StringSchema, num: BigSource, message?: string) {
  return this.test({
    name: "is-gte",
    test: value => {
      try {
        return Big(value).gte(num);
      } catch {
        return false;
      }
    },
    params: { num },
    message: message ? message : `Must be greater than or equal to ${num}`,
  });
}

export function validateOrgName(this: yup.StringSchema) {
  return this.matches(
    /^[a-zA-Z 0-9_.:'&-]*$/,
    "Only letters, numbers, spaces, and the symbols _.:'&- are allowed."
  );
}

type TrefoilConfigLength = keyof typeof window.TREFOIL_CONFIG.min_length;

/** Applies a min length based on values in the TREFOIL_CONFIG */
export function trefoilMin(this: yup.StringSchema, minName: TrefoilConfigLength) {
  const min = window.TREFOIL_CONFIG.min_length[minName];
  return this.min(min);
}

/** Applies a max length based on values in the TREFOIL_CONFIG */
export function trefoilMax(this: yup.StringSchema, maxName: TrefoilConfigLength) {
  const max = window.TREFOIL_CONFIG.max_length[maxName];
  return this.max(max);
}

/** Applies a min and max length based on values in the TREFOIL_CONFIG */
export function trefoilMinMax(this: yup.StringSchema, lengthName: TrefoilConfigLength) {
  return this.trefoilMin(lengthName).trefoilMax(lengthName);
}

export function validatePhone(this: yup.StringSchema) {
  return this.label("Phone number")
    .min(8)
    .max(30)
    .matches(
      /^\+?[\d\-(). x]+$/,
      `Phone number can only contain numbers, spaces, "x", the characters "(", ")", "-", and ".", and an optional leading "+".`
    );
}
