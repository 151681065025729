import { ValueOf } from "type-fest";

const handleNullOrUndefined = (value: unknown) => {
  if (value === null) {
    return "[NULL]";
  } else if (value === undefined) {
    return "[UNDEFINED]";
  }
};

const handleInvalidNumber = (value: number) =>
  typeof value === "string"
    ? `[INVALID NUMBER, 'string']`
    : isNaN(value)
    ? "[INVALID NUMBER, 'NaN']"
    : undefined;

type Primitive = string | number | null | undefined | boolean;

const DIRECT_MASKS = {
  STRING: (s: string) => handleNullOrUndefined(s) || (s ? "[STRING]" : ""),
  NUMBER: (n: number) => handleNullOrUndefined(n) || handleInvalidNumber(n) || "[NUMBER]",
  BOOLEAN: (b: boolean) => handleNullOrUndefined(b) || "[BOOLEAN]",
  STRING_W_COUNT: (s: string) => handleNullOrUndefined(s) || `[STRING: ${s.length} chars]`,
  NUMBER_W_COUNT: (n: number) =>
    handleNullOrUndefined(n) ||
    handleInvalidNumber(n) ||
    `[NUMBER: ${n.toString().length} digits, ${
      !n.toString().includes(".") ? "integer" : "decimal"
    }]`,
  // Preserve original value unmasked
  PERMIT: (v: string | number | boolean | null | undefined) => v,
} as const;

type MaskFunction = ValueOf<typeof DIRECT_MASKS>;

// Metamasks :)
const OMIT = () => "[OMIT]";
const IF =
  (
    test: (value: Primitive) => boolean,
    maskIfTrue: (v: Primitive) => Primitive,
    maskIfFalse: (v: Primitive) => Primitive = OMIT
  ) =>
  (value: Primitive) => {
    try {
      return test(value) ? maskIfTrue(value) : maskIfFalse(value);
    } catch (e) {
      console.error("Error in IF mask", e);
      return "[IF MASK ERROR]";
    }
  };
const MASK_STRING_IF_PRESENT = IF((s: string) => !!s, DIRECT_MASKS.STRING);
const OMIT_STRING_IF_PRESENT = IF(
  (s: string) => !!s,
  OMIT,
  () => "[MISSING STRING]"
);

const LIST_TRUTHY_KEYS = ((value: Record<string, unknown>) =>
  Object.keys(value).filter(key => !!value[key])) as unknown as Record<string, MaskFunction>;

const LIST_ALL_KEYS = ((value: Record<string, unknown>) => Object.keys(value)) as unknown as Record<
  string,
  MaskFunction
>;

export const MASKS = {
  ...DIRECT_MASKS,
  LIST_TRUTHY_KEYS,
  LIST_ALL_KEYS,
  OMIT,
  IF,
  MASK_STRING_IF_PRESENT,
  OMIT_STRING_IF_PRESENT,
};

export type NestedRecord =
  | {
      [key: string]: Primitive | NestedRecord | Array<Primitive | NestedRecord>;
    }
  | Record<string, unknown>;
interface NestedMask {
  [key: string]: MaskFunction | NestedMask | NestedMask; // For arrays of objects
}

type MaskResult<V extends NestedRecord, M extends NestedMask> = {
  [K in keyof V & keyof M]: V[K] extends Array<infer Elem>
    ? Elem extends Primitive
      ? Primitive[] // Result for arrays of primitives
      : Elem extends NestedRecord
      ? M[K] extends NestedMask // Ensure M[K] is actually a NestedMask
        ? MaskResult<Elem, M[K]>[] // Result for arrays of objects
        : never
      : never
    : M[K] extends NestedMask
    ? V[K] extends NestedRecord
      ? MaskResult<V[K], M[K]>
      : never
    : Primitive;
};

type MaskFor<O extends NestedRecord> = Partial<{
  [K in keyof O]: O[K] extends Primitive
    ? MaskFunction
    : O[K] extends Array<infer Elem>
    ? Elem extends Primitive
      ? MaskFunction // Single mask function for arrays of primitives
      : Elem extends NestedRecord
      ? MaskFor<Elem> // Mask for objects inside arrays
      : never
    : O[K] extends NestedRecord
    ? MaskFor<O[K]>
    : never;
}>;

/**
 * Takes a (nested) input object and a (nested) mask and returns a (nested) masked object,
 * in which:
 *
 * - Primitive values are masked according to the mask function
 * - Nested inputs are recursively masked
 * - Only keys present in both the mask and input are included in the result
 * - Arrays of objects in the input are mapped to array of masks (objects or primitives)
 *
 * See the tests for examples.
 */
export const maskObject = <V extends NestedRecord, M extends MaskFor<V>>(
  values: V,
  masks: M
): MaskResult<V, M> => {
  const result = {} as MaskResult<V, M>;

  const maskKeys = Object.keys(masks).filter(
    key => key in values && !!masks[key]
  ) as (keyof typeof result)[];

  maskKeys.forEach(key => {
    const value = values[key];
    const mask = masks[key];
    try {
      if (Array.isArray(value)) {
        // Handle arrays
        if (typeof mask === "function") {
          // @ts-ignore
          result[key] = value.map(item => mask(item as Primitive)) as Primitive[];
        } else if (typeof mask === "object") {
          // @ts-ignore
          result[key] = value.map(item =>
            // @ts-ignore
            maskObject(item as NestedRecord, mask as NestedMask)
          ) as MaskResult<NestedRecord, NestedMask>[];
        }
      } else if (typeof mask === "function") {
        // @ts-ignore
        const masked = mask(value as Primitive) as Primitive;
        // @ts-ignore
        if (masked !== "[OMIT]") result[key] = masked;
      } else if (typeof value === "object" && value !== null) {
        // @ts-ignore
        result[key] = maskObject(value, mask);
      }
    } catch (e) {
      console.error(`Error masking key "${key as string}"`, e);
    }
  });

  return result;
};
