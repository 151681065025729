import React from "react";

import PropTypes from "prop-types";

const LedgerBrowserNotSupported = ({ browser }) => {
  return (
    <div>
      <p>
        Ledger has deprecated its U2F support. The Ledger devices will now work with webusb. If you
        have disabled the new-usb-backend mode before in Chrome browser, you will need to re-enable
        it. Steps to enable: Go to 'chrome://flags/#new-usb-backend' in your URL bar. Make sure the
        setting is set to Default or Enabled.
      </p>
    </div>
  );
};

LedgerBrowserNotSupported.propTypes = {
  browser: PropTypes.string.isRequired,
};

export { LedgerBrowserNotSupported };
