export const SET_ACCOUNT_USER_CONFIG = "/ACCOUNT/USER/SET/TREFOIL_USER";
export const UPDATE_ACCOUNT_USER_NAME = "/ACCOUNT/USER/SET/NAME";
export const UPDATE_ACCOUNT_USER_EMAIL = "/ACCOUNT/USER/SET/EMAIL";
export const UPDATE_ACCOUNT_USER_DATA = "/ACCOUNT/USER/UPDATE";
export const UPDATE_ACCOUNT_USER_STATUS = "/ACCOUNT/USER/STATUS/UPDATE";
export const SET_ACCOUNT_USER_2FA_STATUS = "/ACCOUNT/USER/CREDENTIALS/2FA";
export const UPDATE_ACCOUNT_USER_PERSON = "/ACCOUNT/USER/PERSON/UPDATE";

export const setCurrentUserAccountDataAction = userData => ({
  type: SET_ACCOUNT_USER_CONFIG,
  payload: userData,
});

export const updateAccountUserStatusAction = status => ({
  type: UPDATE_ACCOUNT_USER_STATUS,
  payload: status,
});

export const updateAccountUserAction = user => ({
  type: UPDATE_ACCOUNT_USER_DATA,
  payload: user,
});

export const updateUserEmailAction = email => ({
  type: UPDATE_ACCOUNT_USER_EMAIL,
  payload: email,
});

export const updateUserNameAction = name => ({
  type: UPDATE_ACCOUNT_USER_NAME,
  payload: name,
});

/**
 @param {boolean} status - Current user's 2fa setting, boolean is true if it's enabled
 **/
export const set2faStatusAction = status => ({
  type: SET_ACCOUNT_USER_2FA_STATUS,
  payload: status,
});

export const updateUserAccountPersonAction = person => ({
  type: UPDATE_ACCOUNT_USER_PERSON,
  payload: person,
});
