import React, { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import { CircularProgress, Fade } from "@mui/material";
import { Button, useBreakpoint } from "@unchained/component-library";
import { useDispatch, useSelector } from "react-redux";

import { setOverlay } from "Actions/overlayActions";
import { setSpendingRequestStatusAction } from "Actions/transactionActions/spendingActions";
import { setVaultShowStatusAction } from "Actions/vaultActions/vaultShowActions";
import { FullPageWizard } from "Components/Shared/Layouts/FullPageWizard";
import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import {
  deleteSellBitcoinInProgress,
  setCloseConfirmedSale,
  setIsShowCancelModal,
  setSaveProcessAndClose,
} from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  useSellBitcoinDispatch,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import { SellStatus } from "Contexts/SellBitcoin/types";
import { getCurrentOrg, getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { REQUEST_STATUS } from "Shared/api";
import { SELL_STEPS, useDetermineInitialSellStep } from "Shared/api/hooks/trading";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";

import { ApproveTransactionStep } from "./steps/ApproveTransactionStep";
import { ConfirmationStep } from "./steps/ConfirmationStep";
import { StreamingQuoteSellStep } from "./steps/FinalizeSaleStep";
import { MobileUnavailableStep } from "./steps/MobileUnavailableStep";
import { NetworkErrorStep } from "./steps/NetworkErrorStep";
import { ReviewTransactionStep } from "./steps/ReviewTransactionStep";
import { SaleDetailsStep } from "./steps/SaleDetailsStep";
import { SelectKeysStep } from "./steps/SelectKeysStep";
import { SellLimitReachedStep } from "./steps/SellLimitReachedStep";
import { SellingUnavailableStep } from "./steps/SellingUnavailableStep";
import { SignTransactionStep } from "./steps/SignTransactionStep";

const fadeOutAnimationTimeout = 750;

/**
 * This Component will access all SellBitcoinStore state and pass values to the
 * the memoized SellBitcoinStepperWizardBase component.
 * This wrapper component combined with the SellBitcoinStepperWizardBase React.memo
 * is needed to prevent the SellBitcoinStepperWizardBase component from unnecessary rerenders,
 * which would occur if the useSellBitcoinStore hook was used directly
 * in the SellBitsoinStepperWizardBase anytime any SellBitcoinStore state was updated,
 * even if the values were not used in the SellBitsoinStepperWizardBase component.
 */
const SellBitcoinStepperWithStoreState = React.memo(
  ({ closeStepper }: { closeStepper: () => void }) => {
    const { sellStatus, isSaleInProgress, selectedKeys } = useSellBitcoinStore();
    const orgUuid: string = useSelector(getCurrentOrgId);

    const currentOrg = useSelector(getCurrentOrg);

    const quorumSize = currentOrg.quorum_config?.min_approvals || 0;

    const hasApprovalQuorum = quorumSize > 0;

    const isUnchainedKeySelected = !!selectedKeys.some(key => key.isUnchained);
    const isDelegateKeySelected = !!selectedKeys.some(key => key.isDelegatedKey);

    const isQuorumRequiredOnSale =
      (hasApprovalQuorum && currentOrg.quorum_config?.apply_to_all_txs) ||
      (hasApprovalQuorum && isUnchainedKeySelected) ||
      (hasApprovalQuorum && isDelegateKeySelected);

    const initialStep = useDetermineInitialSellStep(orgUuid, isQuorumRequiredOnSale);

    /*
  If web socket is closed but the streamingQuoteStatus is in a state that
  is actively expecting a response then the web socket is in an unexpected state.
  */
    return (
      <SellBitcoinStepperWizardBase
        closeStepper={closeStepper}
        sellStatus={sellStatus}
        initialStep={initialStep}
        isSaleInProgress={isSaleInProgress}
        doesUserHaveApprovalQuorum={isQuorumRequiredOnSale}
      />
    );
  }
);

type SellBitcoinStepperWizardBaseProps = {
  closeStepper: () => void;
  sellStatus: SellStatus;
  initialStep: SELL_STEPS;
  isSaleInProgress: boolean;
  doesUserHaveApprovalQuorum: boolean;
};
const SellBitcoinStepperWizardBase = React.memo(
  ({
    closeStepper,
    sellStatus,
    initialStep,
    isSaleInProgress,
    doesUserHaveApprovalQuorum,
  }: SellBitcoinStepperWizardBaseProps) => {
    const [isSaleDetailsStepComplete, setIsSaleDetailsStepComplete] = useState(false);
    const [isSelectKeysStepCompleted, setIsSelectKeysStepCompleted] = useState(false);
    const [isStageTransactionStepComplete, setIsStageTransactionStepComplete] = useState(false);
    const [isApproveTransactionStepComplete, setIsApproveTransactionStepComplete] = useState(false);
    const [isSignTransactionStepComplete, setIsSignTransactionStepComplete] = useState(false);
    const [isFinalizeAndBroadCastStepComplete, setIsFinalizeAndBroadCastStepComplete] =
      useState(false);
    const [isInitialOnlineStepSet, setIsInitialOnlineStepSet] = useState(false);

    // set each online sell step's completeness based on the initial step if
    // the step is past the first step (sale details)
    useEffect(() => {
      if (initialStep === SELL_STEPS.SALE_DETAILS) {
        setIsInitialOnlineStepSet(true);
      } else if (initialStep === SELL_STEPS.SELECT_KEYS) {
        setIsSaleDetailsStepComplete(true);
        setIsInitialOnlineStepSet(true);
      } else if (initialStep === SELL_STEPS.REVIEW_TX) {
        setIsSaleDetailsStepComplete(true);
        setIsSelectKeysStepCompleted(true);
        setIsInitialOnlineStepSet(true);
      } else if (initialStep === SELL_STEPS.APPOVE_TX) {
        setIsSaleDetailsStepComplete(true);
        setIsSelectKeysStepCompleted(true);
        setIsStageTransactionStepComplete(true);
        setIsInitialOnlineStepSet(true);
      } else if (initialStep === SELL_STEPS.SIGN_TX) {
        setIsSaleDetailsStepComplete(true);
        setIsSelectKeysStepCompleted(true);
        setIsStageTransactionStepComplete(true);
        setIsApproveTransactionStepComplete(true);
        setIsInitialOnlineStepSet(true);
      } else if (initialStep === SELL_STEPS.FINALIZE_AND_BROADCAST) {
        setIsSaleDetailsStepComplete(true);
        setIsSelectKeysStepCompleted(true);
        setIsStageTransactionStepComplete(true);
        setIsApproveTransactionStepComplete(true);
        setIsSignTransactionStepComplete(true);
        setIsInitialOnlineStepSet(true);
      } else if (initialStep === SELL_STEPS.CONFIRMATION) {
        setIsSaleDetailsStepComplete(true);
        setIsSelectKeysStepCompleted(true);
        setIsStageTransactionStepComplete(true);
        setIsApproveTransactionStepComplete(true);
        setIsSignTransactionStepComplete(true);
        setIsFinalizeAndBroadCastStepComplete(true);
        setIsInitialOnlineStepSet(true);
      }
    }, [
      setIsFinalizeAndBroadCastStepComplete,
      setIsStageTransactionStepComplete,
      setIsSelectKeysStepCompleted,
      setIsSaleDetailsStepComplete,
      initialStep,
    ]);

    const dispatch = useSellBitcoinDispatch();
    const globalDispatch = useDispatch();

    const highlightSellInProgressButton = React.useCallback(() => {
      const sidebar = document.getElementById("sidebar");

      // the sidebar position must be changed from sticky to relative
      // in order to put the in progress button's
      // z index stacking context the same as the global overlay.
      // Without this the overlay and the button will be in different stacking contexts
      // and will not be able to stack based on each other's z-indexes.
      if (sidebar?.style) {
        sidebar.style["position"] = "relative";
      }

      // make sure the user is scrolled to the top
      // so that the sell in progress button is visible
      // with no whitespace below it.
      document.documentElement.scrollTop = 0;

      globalDispatch(setOverlay(true));

      setTimeout(() => {
        // This in a timeout to allow for the
        // SellBitocinStepperWizard's overflow manipulation to run first
        // which ends with the setting of the overflow to auto.
        // if this component's setting of the overflow to hidden runs first
        // it will incorrrectly be overwritten shortly after by the "auto" overflow.

        // hide the overflow since the sidebar position
        // must change from sticky to relative as explained above.
        document.body.style["overflow"] = "hidden";

        // run right after the fadeout completes
      }, fadeOutAnimationTimeout + 1);

      setTimeout(() => {
        // After 1.5 seconds remove the overlay, and return the sidebar
        // and body overflow to their default settings.
        document.body.style["overflow"] = "auto";
        if (sidebar?.style) {
          sidebar.style["position"] = "sticky";
        }
        globalDispatch(setOverlay(false));
      }, 1500);
    }, [globalDispatch]);

    /**
     * There are four possible options for handling the closing of the stepper.
     * 1. We are cancelling a sale.
     * 2. We are closing after completing a sale
     * 3. We are saving the state of a current sale.
     * 4. We are closing a sale that has not progressed beyond the first step
     */
    const onCloseStepper: (isCancellingSale?: boolean) => void = React.useCallback(
      (isCancellingSale = false) => {
        if (window.location.pathname.includes("/vaults")) {
          // the vaults data is potentially stale
          // therefore mark it as so, so it is refetched
          globalDispatch(setVaultShowStatusAction("stale"));
        }

        if (window.location.pathname.includes("/transactions")) {
          // the redux transaction data has potentially changed
          // to the sell related data,
          // therefore before we close set that data as stale,
          // ensuring that the current /transaction specific
          // data is refetched.
          globalDispatch(setSpendingRequestStatusAction(REQUEST_STATUS.STALE));
        }

        if (isCancellingSale === true) {
          // remove all data associated with the current sale.
          // Use a settimeout to interact better with the
          // SellBitcoinStepperWizard fadeout
          setTimeout(() => {
            dispatch(deleteSellBitcoinInProgress());
            closeStepper();
          }, 200);
        } else if (isFinalizeAndBroadCastStepComplete) {
          // we are closing the stepper and we completed the
          // sale, therefore close sale session.
          // Use a settimeout to interact better with the
          // SellBitcoinStepperWizard fadeout
          setTimeout(() => {
            dispatch(setCloseConfirmedSale());
            closeStepper();
          }, 200);
        } else {
          if (isSaleInProgress) {
            highlightSellInProgressButton();
          }

          dispatch(setSaveProcessAndClose());
          closeStepper();
        }
      },
      [
        closeStepper,
        isSaleInProgress,
        highlightSellInProgressButton,
        dispatch,
        isFinalizeAndBroadCastStepComplete,
        globalDispatch,
      ]
    );

    const isLoading = initialStep === SELL_STEPS.INITIAL_LOADING;
    const isError = initialStep === SELL_STEPS.NETWORK_ERROR;

    const isInSavableState =
      isSaleDetailsStepComplete &&
      !isFinalizeAndBroadCastStepComplete &&
      !isError &&
      sellStatus === SellStatus.ONLINE;

    const isShowCancelTransactionFooter =
      !isFinalizeAndBroadCastStepComplete &&
      !isError &&
      sellStatus === SellStatus.ONLINE &&
      isInitialOnlineStepSet;

    const openCancelModal = React.useCallback(() => {
      dispatch(setIsShowCancelModal(true));
    }, [dispatch]);

    const { widthIsBelow } = useBreakpoint();
    const isMobile = widthIsBelow("sm");

    // Once a user has finished the stage transaction step
    // they no longer can access any previous steps.
    const hasReachedStateInWhenAUserCanNotGoBack = isStageTransactionStepComplete;

    const wizardSteps = React.useMemo(() => {
      const baseSteps = [
        {
          title: "Enter details",
          Component: () => (
            <SaleDetailsStep
              onCancel={onCloseStepper}
              onContinue={() => setIsSaleDetailsStepComplete(true)}
            />
          ),
          complete: isSaleDetailsStepComplete,
          disabled: sellStatus !== SellStatus.ONLINE || hasReachedStateInWhenAUserCanNotGoBack,
        },
        {
          title: "Select Keys",
          Component: () => (
            <SelectKeysStep
              onClose={onCloseStepper}
              onContinue={() => setIsSelectKeysStepCompleted(true)}
            />
          ),
          complete: isSelectKeysStepCompleted,
          disabled:
            !isSaleDetailsStepComplete ||
            sellStatus !== SellStatus.ONLINE ||
            hasReachedStateInWhenAUserCanNotGoBack,
        },
        {
          title: "Review transaction",
          Component: () => (
            <ReviewTransactionStep
              onClose={onCloseStepper}
              onContinue={() => setIsStageTransactionStepComplete(true)}
            />
          ),
          complete: isStageTransactionStepComplete,
          disabled:
            !isSelectKeysStepCompleted ||
            sellStatus !== SellStatus.ONLINE ||
            hasReachedStateInWhenAUserCanNotGoBack,
        },
        {
          title: "Sign transaction",
          Component: () => (
            <SignTransactionStep
              onClose={onCloseStepper}
              onContinue={() => setIsSignTransactionStepComplete(true)}
            />
          ),
          complete: isSignTransactionStepComplete,
          disabled:
            !isStageTransactionStepComplete ||
            (!isApproveTransactionStepComplete && doesUserHaveApprovalQuorum) ||
            sellStatus !== SellStatus.ONLINE ||
            isSignTransactionStepComplete,
        },
        {
          title: "Broadcast transaction",
          Component: () => (
            <StreamingQuoteSellStep
              onClose={onCloseStepper}
              onContinue={() => setIsFinalizeAndBroadCastStepComplete(true)}
            />
          ),
          complete: isFinalizeAndBroadCastStepComplete,
          disabled:
            !isSignTransactionStepComplete ||
            sellStatus !== SellStatus.ONLINE ||
            isFinalizeAndBroadCastStepComplete,
        },
        {
          title: "Summary",
          Component: () => <ConfirmationStep onClose={onCloseStepper} />,
          complete: false,
          disabled:
            !isFinalizeAndBroadCastStepComplete ||
            ![SellStatus.ONLINE, SellStatus.SALE_TX_AWAITING_CONFIRMATION].includes(sellStatus),
        },
      ];

      if (doesUserHaveApprovalQuorum) {
        const approveStep = {
          title: "Approve transaction",
          Component: () => (
            <ApproveTransactionStep
              onClose={onCloseStepper}
              onContinue={() => setIsApproveTransactionStepComplete(true)}
            />
          ),
          complete: isApproveTransactionStepComplete,
          disabled:
            !isStageTransactionStepComplete ||
            sellStatus !== SellStatus.ONLINE ||
            isApproveTransactionStepComplete,
        };
        // Add approve step after the review step and before
        // the sign step
        baseSteps.splice(3, 0, approveStep);
      }
      return baseSteps;
    }, [
      onCloseStepper,
      hasReachedStateInWhenAUserCanNotGoBack,
      isFinalizeAndBroadCastStepComplete,
      isSaleDetailsStepComplete,
      isSelectKeysStepCompleted,
      isSignTransactionStepComplete,
      isStageTransactionStepComplete,
      sellStatus,
      doesUserHaveApprovalQuorum,
      isApproveTransactionStepComplete,
    ]);

    const handleCloseButtonClick = React.useCallback(() => {
      if (!isInSavableState) {
        onCloseStepper();
      } else {
        openCancelModal();
      }
    }, [isInSavableState, onCloseStepper, openCancelModal]);

    const StepperFooter = () => <Close onClick={isMobile ? closeStepper : openCancelModal} />;
    const CancelTransactionFooter = () => {
      return (
        <Button
          fullWidth={true}
          type="destructive"
          className="w-full !rounded-none"
          onClick={handleCloseButtonClick}
        >
          Cancel transaction
        </Button>
      );
    };

    const selectLandingComponent = React.useCallback(() => {
      if (isMobile) {
        return () => <MobileUnavailableStep onClose={closeStepper} />;
      } else if (isLoading || (!isInitialOnlineStepSet && sellStatus === SellStatus.ONLINE)) {
        return () => (
          <CircularProgress
            data-testid="sell-bitcoin-wizard-loading-spinner"
            color="primary"
            size={53}
          />
        );
      } else if (isError) {
        return () => <NetworkErrorStep onClose={onCloseStepper} />;
      } else if (initialStep === SELL_STEPS.SELLING_UNAVAILABLE) {
        return () => <SellingUnavailableStep onClose={onCloseStepper} />;
      } else if (initialStep === SELL_STEPS.NO_BITCOIN_AVAILABLE) {
        return () => <SellLimitReachedStep onClose={onCloseStepper} />;
      } else if (sellStatus === SellStatus.ONLINE) {
        // if we are online then no landing component is needed
        // navigate to the initial step in the manifest.
        return undefined;
      }
    }, [
      sellStatus,
      isError,
      onCloseStepper,
      isLoading,
      initialStep,
      isMobile,
      closeStepper,
      isInitialOnlineStepSet,
    ]);

    const manifest = React.useMemo(() => {
      return [
        {
          title: "Sell bitcoin",
          AlternateIcon: () => <CloseButton onClose={handleCloseButtonClick} />,
          steps: wizardSteps,
          Component: selectLandingComponent(),
        },
      ];
    }, [wizardSteps, selectLandingComponent, handleCloseButtonClick]);

    // React.memo is needed in order to prevent unnecessary rerenders which resulted in
    // not allowing stepper components to use the WizardStepper's goToNext and goToPrev functions.
    // These functions are needed to handle the navigation between steps.
    const Stepper = React.memo(() => (
      <>
        <FullPageWizard
          startingIndices={"firstIncomplete"}
          isShowUnchainedLogo={isMobile || !isShowCancelTransactionFooter ? true : false}
          stepperFooter={
            isMobile ? (
              <StepperFooter />
            ) : isShowCancelTransactionFooter ? (
              <CancelTransactionFooter />
            ) : null
          }
          footerStyles={isMobile || !isShowCancelTransactionFooter ? undefined : "w-full"}
          manifest={manifest}
          headerBackgroundColor={"bg-primary-100"}
        />
      </>
    ));
    return <Stepper />;
  }
);

export const SellBitcoinStepperWizard = React.memo(() => {
  const [isShowing, setIsShowing] = useState(true);

  // First run the fadeout animation by setting
  // the isShowing to false, then close the stepper
  // once the animation has finished per the fadeOutAnimationTimeout.
  const closeStepper = React.useCallback(() => {
    setIsShowing(false);
    setTimeout(() => {
      AppModalManager.close();
    }, fadeOutAnimationTimeout);
  }, []);

  return (
    <Fade in={isShowing} timeout={fadeOutAnimationTimeout}>
      <div>
        <SellBitcoinStepperWithStoreState closeStepper={closeStepper} />
      </div>
    </Fade>
  );
});
