import { omit } from "lodash";
import { useMutation } from "react-query";

import {
  CreateIraPlanBeneficiaryRequest,
  PatchIraPlanBeneficiaryRequest,
  PatchOrgIraPlanRequest,
} from "Specs/v1";

import { IraPlanAPI } from "../";

export const usePatchIraPlan = (orgUuid: string) =>
  useMutation((plan: PatchOrgIraPlanRequest) => IraPlanAPI.PatchIraPlan(orgUuid, plan));

export const useCreateSpouseBeneficiary = (orgUuid: string) =>
  useMutation((partialBen: { personUuid: string; email: string }) => {
    const beneficiary = { ...partialBen, percentage: "100", spouse: true, primary: true };
    return IraPlanAPI.CreateBeneficiary(orgUuid, beneficiary);
  });

export const useCreateBeneficiary = (orgUuid: string) =>
  useMutation((beneficiary: CreateIraPlanBeneficiaryRequest) =>
    IraPlanAPI.CreateBeneficiary(orgUuid, beneficiary)
  );

export const usePatchBeneficiary = () =>
  useMutation((beneficiary: PatchIraPlanBeneficiaryRequest & { orgUuid: string; uuid: string }) =>
    IraPlanAPI.PatchBeneficiary(
      beneficiary.uuid,
      beneficiary.orgUuid,
      omit(beneficiary, "uuid", "orgUuid")
    )
  );

export const useDeleteBeneficiary = (orgUuid: string) =>
  useMutation((uuid: string) => IraPlanAPI.DeleteBeneficiary(orgUuid, uuid));
