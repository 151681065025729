import React, { createContext, useContext, useReducer, ReactNode } from "react";

type StoreProviderType = ({ children }: { children: ReactNode }) => JSX.Element;
type UseDispatchType<ActionType> = () => React.Dispatch<ActionType>;

/**
 * Create a provider, store, and dispatch for a context and reducer
 * @param {function} reducer - The reducer for the context store
 * @param {Object} initialState - The initialState for the context store
 * @return {Object[]} - An array of the provider, store hook, and dispatch hook for the context
 *
 * The return type is explicitly written out because array destructioning in typescript has trouble dynamically interpreting the types.
 */
export const makeStore = <ActionType, StateType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType
): [StoreProviderType, () => StateType, UseDispatchType<ActionType>] => {
  const storeContext = createContext<StateType>(undefined);
  const dispatchContext = createContext(undefined);

  const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer<React.Reducer<StateType, ActionType>>(
      reducer,
      initialState
    );

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  const useStore = () => {
    return useContext(storeContext);
  };

  const useDispatch = () => {
    return useContext(dispatchContext);
  };

  return [StoreProvider, useStore, useDispatch];
};
