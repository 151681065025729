import React, { ReactNode } from "react";

import cn from "classnames";

const PageTitle = ({ className, children }: { className?: string; children: ReactNode }) => {
  return (
    <h1
      className={cn(
        "flex flex-row items-center whitespace-pre-wrap text-d-md font-bold text-gray-800",
        className
      )}
    >
      {children}
    </h1>
  );
};

export { PageTitle };
