import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  return_address: "",
  errors: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOAN/SET_RETURN_ADDRESS/SET/RETURN_ADDRESS": // redundant much?
      return updatedField(state, action, "return_address");
    case "LOAN/SET_RETURN_ADDRESS/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "LOAN/SET_RETURN_ADDRESS/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "LOAN/SET_RETURN_ADDRESS/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
