import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  old_account_key_signable: false,
  new_account_key_uuid: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "ACCOUNT_KEY/REPLACE/SET/OLD_ACCOUNT_KEY_SIGNABLE":
      return updatedField(state, action, "old_account_key_signable");
    case "ACCOUNT_KEY/REPLACE/SET/NEW_ACCOUNT_KEY_UUID":
      return updatedField(state, action, "new_account_key_uuid");

    case "ACCOUNT_KEY/REPLACE/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "ACCOUNT_KEY/REPLACE/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "ACCOUNT_KEY/REPLACE/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
