import {
  CreateIraPlanBeneficiary200,
  CreateIraPlanBeneficiaryRequest,
  PatchIraPlanBeneficiary200,
  PatchIraPlanBeneficiaryRequest,
  PatchOrgIraPlan200,
  PatchOrgIraPlanRequest,
} from "Specs/v1";

import { ApiType } from "./apiType";

export const IraPlanAPIFactory = (API: ApiType) => ({
  PatchIraPlan: async (orgUuid: string, plan: PatchOrgIraPlanRequest) =>
    (await API.Patch<PatchOrgIraPlan200>(`/orgs/${orgUuid}/ira-plan`, plan)).data,

  CreateBeneficiary: async (orgUuid: string, beneficiary: CreateIraPlanBeneficiaryRequest) =>
    (
      await API.Post<CreateIraPlanBeneficiary200>(
        `/orgs/${orgUuid}/ira-plan/beneficiaries`,
        beneficiary
      )
    ).data,

  PatchBeneficiary: async (
    uuid: string,
    orgUuid: string,
    beneficiary: PatchIraPlanBeneficiaryRequest
  ) =>
    (
      await API.Patch<PatchIraPlanBeneficiary200>(
        `/orgs/${orgUuid}/ira-plan/beneficiaries/${uuid}`,
        beneficiary
      )
    ).data,

  DeleteBeneficiary: async (orgUuid: string, uuid: string) =>
    (await API.Delete(`/orgs/${orgUuid}/ira-plan/beneficiaries/${uuid}`)).data,
});
