import React from "react";

import { Checkbox, CheckboxProps } from "@unchained/component-library";
import { useField } from "formik";

export const FormikCheckboxField = ({ name, ...props }: { name: string } & CheckboxProps) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Checkbox
      {...props}
      name={name}
      checked={field.value}
      onChange={e => {
        helpers.setValue(!field.value);
        // Blur to trigger validation
        setTimeout(() => helpers.setTouched(true), 0);
      }}
    />
  );
};
