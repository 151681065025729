import React, { ReactNode } from "react";

import { Table, TableBody, TableRow } from "@mui/material";
import { Avatar } from "@unchained/component-library";
import cn from "classnames";

import {
  SummaryTableCell,
  SummaryTableHeaderCell,
} from "Components/Shared/Elements/Summary/SummaryTableCell";

interface Instruction {
  title: string;
  subtitle?: string | ReactNode;
}

export const InstructionList = ({ steps }: { steps: Instruction[] | string[] }) => {
  return (
    <>
      {steps.map((step, index) => (
        <div className="auto mb-3 grid grid-cols-[50px_1fr] items-center font-bold" key={index}>
          <Avatar
            shape="circle"
            initials={(index + 1).toString()}
            color="lightGray"
            className="!font-bold"
          />{" "}
          <p className="text-md font-semi text-gray-700">{step.title || step}</p>
          {step.subtitle && (
            <p className="col-start-2 -mt-1 text-md font-reg text-gray-600">{step.subtitle}</p>
          )}
        </div>
      ))}
    </>
  );
};

interface InstructionTableRow {
  header: string;
  content: string | ReactNode;
}

export const InstructionTable = ({
  rows,
  className,
}: {
  rows: InstructionTableRow[];
  className?: string;
}) => {
  return (
    <Table className={cn(className)}>
      <TableBody>
        {rows.map(({ header, content }, index) => (
          <TableRow key={index}>
            <SummaryTableHeaderCell>{header}</SummaryTableHeaderCell>
            <SummaryTableCell className="break-words break-all !py-2 font-mono !leading-tight">
              {content}
            </SummaryTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
