import React from "react";

import { SignIcon } from "Components/Shared/Icons/SignIcon";
import { signIraContractPath } from "Utils/paths";

export const SignIraContractNotification = ({ org: { uuid } }: { org: { uuid: string } }) => {
  return {
    to: signIraContractPath(uuid),
    title: `Your Unchained IRA agreement is ready to sign`,
    Icon: SignIcon,
  };
};
