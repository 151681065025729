let initialState = {
  payments_table: 1,
  principal_payments_table: 1,
};

function updatedPage(state, paginator, action) {
  let newState = {
    ...state,
  };
  newState[paginator] = action[paginator] + 1;
  return newState;
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "PAGINATION/SET/PAYMENTS_TABLE":
      return updatedPage(state, "payments_table", action);
    case "PAGINATION/SET/PRINCIPAL_PAYMENTS_TABLE":
      return updatedPage(state, "principal_payments_table", action);
    default:
      return state;
  }
};
