import { PaginationState } from "@tanstack/react-table";
import { GetTradingBuyInfo200 } from "@unchained/api-specs/cosimo/v1/getTradingBuyInfo/200";
import { GetTradingSellInfo200 } from "@unchained/api-specs/cosimo/v1/getTradingSellInfo/200";
import {
  ConfirmStreamingQuoteResponse,
  InitiateStreamingQuoteResponse,
} from "@unchained/api-specs/cosimo/v1/webSocketSellBitcoin/101/";
import moment from "moment-timezone";

import {
  handleTradeWebSocketMessages,
  setWebSocketStatus,
  WEB_SOCKET_CLOSED,
  WEB_SOCKET_OPEN,
} from "Contexts/BuyBitcoin";
import { BuyBitcoinActions } from "Contexts/BuyBitcoin/types";
import {
  handleSellBitcoinWebSocketMessages,
  setSellBitcoinWebSocketStatus,
} from "Contexts/SellBitcoin/SellBitcoinActions";
import { SellBitcoinActions } from "Contexts/SellBitcoin/types";
import {
  CreateBatchSettlement200,
  CreateBatchSettlementRequest,
  CreateLpSettlement200,
  CreateLpSettlementRequest,
  CreateLpTrade200,
  CreateLpTradeRequest,
  CreateTradingClientSellBitcoin200,
  CreateTradingStart200,
  GetAdminTradingMessages200,
  GetBuyActivity200,
  GetLiquidityProviders200,
  GetLiquidityProviderSummary200,
  GetLpTrade200,
  GetLpTrades200,
  GetLpTradesForSettlement200,
  GetOrgSellLimit200,
  GetPendingLpSettlements200,
  GetSellActivity200,
  GetSellBitcoinOperation200,
  GetSettleableTrades200,
  GetSettleableTradesSummary200,
  GetSettlementVaults200,
  GetStateEligibity200,
  GetTraders200,
  GetTradeStatements200,
  GetTradingAgreements200,
  GetTradingCustomValues200,
  GetTradingEnabledStatuses200,
  GetTradingGlobalDefaultValues200,
  GetTradingStatus200,
  PutAdminTradingMessages200,
  PutTradingCustomValues200,
  PutTradingCustomValuesRequest,
  PutTradingEnabledStatuses200,
  PutTradingGlobalDefaultValues200,
  PutTradingGlobalDefaultValuesRequest,
  SellBitcoinDetailsOperationRequest,
  UpdateLpSettlement200,
  UpdateLpSettlementRequest,
  UpdateLpTrade200,
  UpdateLpTradeRequest,
  UpdateOrgSellLimit200,
  UpdateOrgSellLimitRequest,
  UpdateSellBitcoinConfirmedSignatures200,
  UpdateTradingClientBuyBitcoinCanceled200,
  UpdateTradingClientBuyBitcoinCopied200,
  UpdateTradingClientBuyBitcoinFunded200,
  UpdateTradingClientBuyBitcoinUncopied200,
  UpdateTradingClientBuyBitcoinUnfunded200,
  UpdateTradingClientSellBitcoinCanceled200,
  UpdateTradingClientSellBitcoinCopied200,
  UpdateTradingClientSellBitcoinUncopied200,
  UpdateTradingClientSellBitcoinWireSent200,
  UpdateTradingClientSellBitcoinWireUnsent200,
  UpdateTradingStatus200,
} from "Specs/v1";
import { Status as BuyStatuses } from "Specs/v1/getBuyActivity/params/query";
import { GetLiquidityProviderSummaryQueryParams } from "Specs/v1/getLiquidityProviderSummary/params/query";
import { GetLpTradesQueryParams } from "Specs/v1/getLpTrades/params/query";
import {
  Status as SellStatuses,
  AccountType as OrgAccountType,
} from "Specs/v1/getSellActivity/params/query";
import "Specs/v1/getSettleableTrades/200";
import {
  StateEligibilityStatuses,
  TerritoryEligibilityStatuses,
} from "Specs/v1/getStateEligibity/200";
import { Traders } from "Specs/v1/getTraders/200";
import { SellBitcoinDetailsOperation200 } from "Specs/v1/sellBitcoinDetailsOperation/200";
import { SellBitcoinReviewOperation200 } from "Specs/v1/sellBitcoinReviewOperation/200";
import { SellBitcoinSelectedKeysOperation200 } from "Specs/v1/sellBitcoinSelectedKeysOperation/200";
import { GetACsvForBuyActivityQueryParams } from "Specs/v2/getACsvForBuyActivity/params/query";
import { GetACsvForLpTradeActivityQueryParams } from "Specs/v2/getACsvForLpTradeActivity/params/query";
import { GetACsvForSellActivityQueryParams } from "Specs/v2/getACsvForSellActivity/params/query";
import { SERVER_TIME_RECEIVE_FORMAT } from "Utils/time";
import { Params, stringifyQueryParams } from "Utils/uris";

import { API, API_V2, CosimoAPI } from "./api";
import { SortDirection } from "./hooks";

export class TradingAPI {
  static GetTradingInfo = async (jwt: string) => {
    const response: { data: GetTradingBuyInfo200 } = await CosimoAPI.Get(
      "/trading/buy/info",
      {},
      { Authorization: `Bearer ${jwt}` }
    );

    return response.data.data;
  };

  static GetTradingSellInfo = async (jwt: string) => {
    const response: { data: GetTradingSellInfo200 } = await CosimoAPI.Get(
      "/trading/sell/info",
      {},
      { Authorization: `Bearer ${jwt}` }
    );

    return response.data.data;
  };
  static GetTradingStatus = async (orgUuid: string) => {
    const response: { data: GetTradingStatus200 } = await API.Get(
      `/trading/org/${orgUuid}/trading-status`
    );

    return response.data.data;
  };

  static UpdateTradingStatus = async (orgUuid: string, data) => {
    const response = await API.Patch<UpdateTradingStatus200>(
      `/trading/org/${orgUuid}/trading-status`,
      data
    );
    return response.data.data;
  };

  static StartTradingSession = async (orgUuid: string) => {
    const response: { data: CreateTradingStart200 } = await API.Post(
      `/trading/org/${orgUuid}/start`
    );
    return response.data.data;
  };

  static OpenTradeWebSocket = (tradingJwt: string) => {
    return CosimoAPI.OpenWebSocket(`/trading/buy?jwt=${tradingJwt}`);
  };

  static SetTradeWebSocketLifeCycleMethods = (ws, dispatch: React.Dispatch<BuyBitcoinActions>) => {
    ws.onopen = () => {
      dispatch(setWebSocketStatus(WEB_SOCKET_OPEN));
    };
    ws.onclose = e => {
      dispatch(setWebSocketStatus(WEB_SOCKET_CLOSED));
    };
    ws.onerror = e => {
      dispatch(setWebSocketStatus(WEB_SOCKET_CLOSED));
    };
    ws.onmessage = rawResponse => {
      handleTradeWebSocketMessages(rawResponse, dispatch);
    };
  };

  static OpenSellBitcoinWebSocket = (tradingJwt: string) => {
    return CosimoAPI.OpenWebSocket(`/trading/sell?jwt=${tradingJwt}`);
  };

  static SetSellBitcoinWebSocketLifeCycleMethods = (
    ws,
    dispatch: React.Dispatch<SellBitcoinActions>
  ) => {
    ws.onopen = () => {
      dispatch(setSellBitcoinWebSocketStatus(WEB_SOCKET_OPEN));
    };
    ws.onclose = e => {
      dispatch(setSellBitcoinWebSocketStatus(WEB_SOCKET_CLOSED));
    };
    ws.onerror = e => {
      dispatch(setSellBitcoinWebSocketStatus(WEB_SOCKET_CLOSED));
    };
    ws.onmessage = rawResponse => {
      const response = JSON.parse(rawResponse.data) as
        | InitiateStreamingQuoteResponse
        | ConfirmStreamingQuoteResponse;

      handleSellBitcoinWebSocketMessages(response, dispatch);
    };
  };

  static GetTradingEnabled = async () => {
    const response = await API.Get<GetTradingEnabledStatuses200>("/trading/admin/enabled");
    return response.data.data;
  };

  static UpdateTradingEnabled = async ({
    buyEnabled,
    sellEnabled,
  }: {
    buyEnabled?: boolean;
    sellEnabled?: boolean;
  }) => {
    await API.Put<PutTradingEnabledStatuses200>("/trading/admin/enabled", {
      buyEnabled,
      sellEnabled,
    });
  };

  static GetDefaultTradingMessages = async () => {
    const response = await API.Get<GetAdminTradingMessages200>("/trading/admin/messages");
    return response.data.data;
  };

  static UpdateDefaultTradingMessage = async data => {
    await API.Put<PutAdminTradingMessages200>("/trading/admin/messages", data);
  };

  static GetDefaultTradingValues = async () => {
    const response = await API.Get<GetTradingGlobalDefaultValues200>(
      "/trading/admin/default-values"
    );

    return response.data.data;
  };

  static UpdateDefaultTradingValues = async (data: PutTradingGlobalDefaultValuesRequest) => {
    await API.Put<PutTradingGlobalDefaultValues200>("/trading/admin/default-values", data);
  };

  static GetScheduledDowntime = async () => {
    const response = await API.Get<{ data: any }>("/trading/admin/scheduled-downtime");
    const {
      data: { data },
    } = response;
    const start = data.downtime.start;
    const end = data.downtime.end;
    data.downtime.start = start ? moment(start, SERVER_TIME_RECEIVE_FORMAT) : null;
    data.downtime.end = end ? moment(end, SERVER_TIME_RECEIVE_FORMAT) : null;
    return data;
  };

  static UpdateScheduledDowntime = async data => {
    await API.Put("/trading/admin/scheduled-downtime", data);
  };

  static GetOrgTradingEnabled = async (orgUuid: string) => {
    const response = await API.Get<{ data: any }>(`/trading/admin/org/${orgUuid}/enabled`);
    return response.data.data;
  };

  static PostApproveOrgForTrading = async (orgUuid: string) => {
    await API.Post(`/trading/admin/org/${orgUuid}/approve-org-for-trading`);
  };

  static DeleteDenyOrgForTrading = async (orgUuid: string) => {
    await API.Delete(`/trading/admin/org/${orgUuid}/deny-org-for-trading`);
  };

  static GetOrgTradingCustomValues = async (orgUuid: string) => {
    const response = await API.Get<GetTradingCustomValues200>(
      `/trading/admin/org/${orgUuid}/custom-values`
    );
    return response.data.data;
  };

  static UpdateOrgTradingCustomValues = async (
    orgUuid: string,
    data: PutTradingCustomValuesRequest | { enabled: boolean }
  ) => {
    await API.Put<PutTradingCustomValues200>(`/trading/admin/org/${orgUuid}/custom-values`, data);
  };

  // Buy activity routes

  static GetClientBuyBitcoinActivity = async (
    pageIndex?: number,
    pageSize?: number,
    sortDirection?: SortDirection,
    search?: string,
    status?: BuyStatuses,
    accountType?: OrgAccountType,
    startDate?: string,
    endDate?: string
  ) => {
    const params = {
      page: pageIndex,
      per_page: pageSize,
      sortDirection,
      search,
      status,
      accountType,
      startDate,
      endDate,
    };
    const response = await API.Get<GetBuyActivity200>("/trading/admin/buy-activity", params);
    return response.data.data;
  };

  static GetClientBuyBitcoinActivityCsv = async (
    requestQueryParams: GetACsvForBuyActivityQueryParams
  ) => {
    const stringQueryParams = stringifyQueryParams(requestQueryParams as Params, true);
    await API_V2.Download(`/trading/admin/buy-activity/csv?${stringQueryParams}`);
  };

  // Sell activity routes

  static GetClientSellBitcoinActivity = async (
    pageIndex?: number,
    pageSize?: number,
    sortDirection?: SortDirection,
    search?: string,
    status?: SellStatuses,
    accountType?: OrgAccountType,
    startDate?: string,
    endDate?: string
  ) => {
    const params = {
      page: pageIndex,
      per_page: pageSize,
      sortDirection,
      search,
      status,
      accountType,
      startDate,
      endDate,
    };
    const response = await API.Get<GetSellActivity200>("/trading/admin/sell-activity", params);
    return response.data.data;
  };

  static GetClientSellBitcoinActivityCsv = async (
    requestQueryParams: GetACsvForSellActivityQueryParams
  ) => {
    const stringQueryParams = stringifyQueryParams(requestQueryParams as Params, true);
    await API_V2.Download(`/trading/admin/sell-activity/csv?${stringQueryParams}`);
  };

  static GetOrgTraders = async (orgUuid: string) => {
    const response = await API.Get<GetTraders200>(`/trading/org/${orgUuid}/traders`);
    return response.data.data;
  };

  static UpdateOrgTraders = async (orgUuid: string, data: Traders["traders"]) => {
    await API.Post(`/trading/org/${orgUuid}/traders`, data);
  };

  static GetTradeStatements = async (orgUuid: string, page = 1, perPage = 10) => {
    const response: { data: GetTradeStatements200 } = await API.Get(
      `/trading/org/${orgUuid}/trade-statements?page=${page}&perPage=${perPage}`
    );
    return response.data;
  };

  static GetTradeStatementsCSV = async (
    orgUuid: string,
    tradeType?: string,
    year?: string,
    product?: string,
    productUuid?: string
  ) => {
    const params = { year, tradeType, product, productUuid };
    const queryParams = stringifyQueryParams(params, true);
    await API.Download(`/trading/org/${orgUuid}/trade-statements/csv?${queryParams}`);
  };

  static GetBuyStatementDocument = async (orgUuid: string, tradeId: string) => {
    const response = await API.Download(`/trading/org/${orgUuid}/trade-statements/${tradeId}`);
    return response;
  };

  static GetSellStatementDocument = async (orgUuid: string, csbUuid: string) => {
    const response = await API.Download(`/trading/org/${orgUuid}/trade-statements/sell/${csbUuid}`);
    return response;
  };

  static GetSettleableTrades = async (pagination: PaginationState) => {
    const response = await API.Get<GetSettleableTrades200>(
      "/trading/admin/client-buy-bitcoin/settleable-trades",
      {
        page: pagination.pageIndex,
        per_page: pagination.pageSize,
      }
    );
    return response.data.data;
  };

  static GetSettleableTradesSummary = async () => {
    const response = await API.Get<GetSettleableTradesSummary200>(
      "/trading/admin/client-buy-bitcoin/settleable-trades-summary"
    );
    return response.data.data;
  };

  static GetSettlementVaults = async () => {
    const response = await API.Get<GetSettlementVaults200>(
      "/trading/admin/batch-settlement/settlement-vaults"
    );
    return response.data.data;
  };

  static CreateBatchSettlement = async (data: CreateBatchSettlementRequest) => {
    const response = await API.Post<CreateBatchSettlement200>(
      "/trading/admin/batch-settlement",
      data
    );

    return response.data;
  };

  static GetAllTradingAgreements = async (orgUuid: string) => {
    const response = await API.Get<GetTradingAgreements200>(
      `/trading/org/${orgUuid}/trading-agreements`
    );
    return response.data.data;
  };

  static DownloadTradingAgreement = async (orgUuid: string, contractId: string) => {
    const response = await API.Download(
      `/trading/org/${orgUuid}/trading-agreement/${contractId}/download`
    );
    return response;
  };

  static GetStateEligibilities = async () => {
    const response = await API.Get<GetStateEligibity200>(`/trading/admin/state-eligibility`);
    return response.data.data;
  };

  static UpdateStateEligibilities = async (
    states: StateEligibilityStatuses,
    territories: TerritoryEligibilityStatuses
  ) => {
    await API.Put("/trading/admin/state-eligibility", { states, territories });
  };

  static CreateSellBitcoin = async (orgUuid: string) => {
    const response = await API.Post<CreateTradingClientSellBitcoin200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin`
    );
    return response.data.data;
  };

  static GetSellBitcoinInProgress = async (orgUuid: string, saleUuid: string) => {
    const response = await API.Get<GetSellBitcoinOperation200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin/${saleUuid}`
    );
    return response.data.data;
  };

  static DeleteSellBitcoinInProgress = async (orgUuid: string, saleUuid: string) => {
    const response = await API.Delete<GetSellBitcoinOperation200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin/${saleUuid}`
    );
    return response.data.data;
  };

  static UpdateSellBitcoinSaleDetails = async (
    orgUuid: string,
    saleUuid: string,
    saleDetails: SellBitcoinDetailsOperationRequest
  ) => {
    const response = await API.Patch<SellBitcoinDetailsOperation200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin/${saleUuid}/details`,
      saleDetails
    );
    return response.data.data;
  };
  static UpdateSellBitcoinSelectedKeys = async (
    orgUuid: string,
    saleUuid: string,
    selectedVaultKeyIds: string[]
  ) => {
    const response = await API.Patch<SellBitcoinSelectedKeysOperation200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin/${saleUuid}/selected-keys`,
      { selectedVaultKeyIds }
    );
    return response.data.data;
  };
  static UpdateSellBitcoinTxReview = async (orgUuid: string, saleUuid: string) => {
    const response = await API.Patch<SellBitcoinReviewOperation200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin/${saleUuid}/review`,
      { hasReviewedTx: true }
    );
    return response.data.data;
  };
  static UpdateSellBitcoinConfirmSignatures = async (orgUuid: string, saleUuid: string) => {
    const response = await API.Patch<UpdateSellBitcoinConfirmedSignatures200>(
      `/trading/org/${orgUuid}/client-sell-bitcoin/${saleUuid}/confirmed-signatures`,
      { hasConfirmedSignatures: true }
    );
    return response.data.data;
  };

  static GetSellTradeStatementDocument = async (orgUuid: string, tradeId: string) => {
    const response = await API.Download(`/trading/org/${orgUuid}/trade-statements/sell/${tradeId}`);
    return response;
  };

  static GetOrgSellPower = async (orgUuid: string) => {
    const response = await API.Get<GetOrgSellLimit200>(`/trading/admin/org/${orgUuid}/sell-limit`);
    return response.data.data;
  };

  static UpdateOrgSellPower = async (orgUuid: string, data: UpdateOrgSellLimitRequest) => {
    const response = await API.Put<UpdateOrgSellLimit200>(
      `/trading/admin/org/${orgUuid}/sell-limit`,
      data
    );
    return response.data.data;
  };

  static GetLPTrades = async (requestParams: GetLpTradesQueryParams) => {
    const stringQueryParams = stringifyQueryParams(requestParams as Params, true);
    const response = await API.Get<GetLpTrades200>(
      `/trading/admin/lp-trade-bitcoin?${stringQueryParams}`
    );

    return response.data;
  };

  static GetLpTradesCsv = async (requestParams: GetACsvForLpTradeActivityQueryParams) => {
    const stringQueryParams = stringifyQueryParams(requestParams as Params, true);
    await API_V2.Download(`/trading/admin/lp-trade-bitcoin/csv?${stringQueryParams}`);
  };

  static GetLiquidityProviders = async () => {
    const response = await API.Get<GetLiquidityProviders200>(`/trading/admin/liquidity-provider`);

    return response.data.data;
  };

  static GetLpSettlementSummary = async (requestParams: GetLiquidityProviderSummaryQueryParams) => {
    const stringQueryParams = stringifyQueryParams(requestParams as Params, true);
    const response = await API.Get<GetLiquidityProviderSummary200>(
      `/trading/admin/liquidity-provider/summary?${stringQueryParams}`
    );

    return response.data.data;
  };

  static GetLpSettlements = async () => {
    const response = await API.Get<GetPendingLpSettlements200>(
      `/trading/admin/lp-trade-bitcoin/settlement`
    );

    return response.data.data;
  };

  static CreateLpSettlementRequest = async (lpSettlement: CreateLpSettlementRequest) => {
    const response = await API.Post<CreateLpSettlement200>(
      `/trading/admin/lp-trade-bitcoin/settlement`,
      lpSettlement
    );

    return response.data;
  };

  static GetLpSettlementTrades = async (liquidityProvider: string) => {
    const response = await API.Get<GetLpTradesForSettlement200>(
      `/trading/admin/lp-trade-bitcoin/settlement/open-trades?liquidityProvider=${liquidityProvider}`
    );

    return response.data.data;
  };

  static UpdateBatchSettlement = async (
    batchSettlementUuid: string,
    bachSettlementRequestBody: UpdateLpSettlementRequest
  ) => {
    const response = await API.Patch<UpdateLpSettlement200>(
      `/trading/admin/lp-trade-bitcoin/settlement/${batchSettlementUuid}`,
      bachSettlementRequestBody
    );

    return response.data;
  };

  static DeleteBatchSettlement = async (batchSettlementUuid: string) => {
    const response = await API.Delete(
      `/trading/admin/lp-trade-bitcoin/settlement/${batchSettlementUuid}`
    );

    return response.data;
  };
  static DownloadBatchSettlementTradesCsv = async (batchSettlementUuid: string) => {
    await API.Download(`/trading/admin/lp-trade-bitcoin/settlement/${batchSettlementUuid}/csv`);
  };

  static CreateLPTrade = async (lpTrade: CreateLpTradeRequest) => {
    const response = await API.Post<CreateLpTrade200>(`/trading/admin/lp-trade-bitcoin`, lpTrade);

    return response.data;
  };

  static UpdateLPTrade = async (lpTradeUuid: string, lpTradeUpdates: UpdateLpTradeRequest) => {
    const response = await API.Patch<UpdateLpTrade200>(
      `/trading/admin/lp-trade-bitcoin/${lpTradeUuid}`,
      lpTradeUpdates
    );

    return response.data;
  };

  static GetLPTrade = async (lpTradeUuid: string) => {
    const response = await API.Get<GetLpTrade200>(`/trading/admin/lp-trade-bitcoin/${lpTradeUuid}`);
    return response.data.data;
  };
}
