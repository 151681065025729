import React from "react";

import { Button } from "@unchained/component-library";

import { Link, useNavigate } from "Components/Link";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { SellStatus } from "Contexts/SellBitcoin/types";
import { getConfig } from "Utils/config";
import { findAndReplaceTextWithComponent } from "Utils/strings";

import { SellHeader } from "../components/SellHeader";

type SellingUnavailableStepProps = {
  onClose: () => void;
};
export const SellingUnavailableStep = ({ onClose }: SellingUnavailableStepProps) => {
  const { offlineStatus, sellStatus } = useSellBitcoinStore();
  const isNoBanksStatus = sellStatus === SellStatus.NO_BANKS;
  const buttonText = isNoBanksStatus ? "Add a bank account" : "Close";

  const navigate = useNavigate();
  const handleOnClose = () => {
    if (isNoBanksStatus) {
      navigate("/payment-methods?tab=bankAccounts");
    }

    onClose();
  };
  const email = getConfig("email.help");

  const findAndReplaceClientServicesEmailTextWithLink = () => {
    const emailComponent = (
      <Link key={"clientServicesEmail"} to={`mailto:${email}`}>
        {email}
      </Link>
    );

    return findAndReplaceTextWithComponent(emailComponent, offlineStatus.description, email);
  };

  const descriptionTextWithEmailLink: React.ReactNode =
    findAndReplaceClientServicesEmailTextWithLink();

  const SubTitle = () => <p className="text-md">{descriptionTextWithEmailLink}</p>;

  return (
    <div className="w-8/12 max-w-md">
      <SellHeader title={offlineStatus.title} subTitle={<SubTitle />} />
      <Button onClick={handleOnClose} fullWidth={true} type={"secondary"}>
        {buttonText}
      </Button>
    </div>
  );
};
