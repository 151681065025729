import React from "react";

import { SignDocIcon } from "Components/Shared/Icons/SignDocIcon";

const SignDocumentNotification = ({ uuid }: { uuid: string }) => {
  return {
    to: `/loans/${uuid}`,
    title: `Sign documents for loan ${uuid}`,
    Icon: SignDocIcon,
  };
};

export { SignDocumentNotification };
