import { Moment } from "moment-timezone";

import { makeStore } from "Contexts/makeStore";
import "Specs/v1";
import { GetLoanFeeRates200 } from "Specs/v1/getLoanFeeRates/200";

export type FeeRateInfo = GetLoanFeeRates200["data"];

const reducer = (
  state: InitialStateTypes,
  action: { type: CollateralOperationActions; payload: any }
) => {
  const { payload } = action;

  switch (action.type) {
    case CollateralOperationActions.setFeeRate: {
      const { feeRate } = payload as { feeRate: number };
      return { ...state, feeRate };
    }
    case CollateralOperationActions.setFeeRateInfo: {
      const { feeRateInfo } = payload as { feeRateInfo: FeeRateInfo };
      return { ...state, feeRateInfo };
    }
    case CollateralOperationActions.setSales: {
      // TODO: ensure these types are inferred after the api specs are added
      const { pending, liquidated } = payload as {
        pending?: CollateralSale[];
        liquidated?: CollateralSale[];
      };
      return { ...state, pending, liquidated };
    }
    case CollateralOperationActions.setEditing: {
      const { editing } = payload;
      return { ...state, editing };
    }
    case CollateralOperationActions.resetEditing: {
      return { ...state, editing: "" };
    }
    case CollateralOperationActions.addPendingSale: {
      const { collateralSale } = payload;
      return { ...state, pending: [...state.pending, collateralSale as CollateralSale] };
    }
    case CollateralOperationActions.updatePendingSale: {
      const { uuid, trade_id, note } = payload;
      const newPending = [...state.pending];
      const index = newPending.findIndex(p => p.uuid === uuid);
      newPending[index].trade_id = trade_id;
      newPending[index].note = note;
      return { ...state, pending: newPending };
    }
    case CollateralOperationActions.setFullLiquidation: {
      const { isFullLiquidation } = payload;
      return { ...state, isFullLiquidation };
    }
    case CollateralOperationActions.setFullLiquidationDetails: {
      const { fullLiquidationDetails } = payload;
      return { ...state, fullLiquidationDetails };
    }
    default: {
      return state;
    }
  }
};

export enum CollateralOperationActions {
  setFeeRateInfo = "SET_FEE_RATE_INFO",
  setFeeRate = "SET_FEE_RATE",
  setSales = "SET_SALES",
  setEditing = "SET_EDITING",
  resetEditing = "RESET_EDITING",
  addPendingSale = "ADD_PENDING_SALE",
  updatePendingSale = "UPDATE_PENDING_SALE",
  setFullLiquidation = "SET_FULL_LIQUIDATION",
  setFullLiquidationDetails = "SET_FULL_LIQUIDATION_DETAILS",
}

export interface CollateralSale {
  trade_id: string;
  sale_date: Moment;
  exchange: string;
  btc_sold: number;
  usd_received: number;
  price: number;
  uuid: string;
  note?: string;
  full_liquidation_date?: Moment;
  reason?: string;
}

interface InitialStateTypes {
  pending?: CollateralSale[];
  liquidated?: CollateralSale[];
  feeRateInfo?: FeeRateInfo;
  feeRate?: number;
  editing: string;
  isFullLiquidation: boolean;
  fullLiquidationDetails:
    | {
        full_liquidation_interest_amounts: number;
        fullAmount: number;
        collateral: number;
      }
    | "";
}

const initialState: InitialStateTypes = {
  pending: [],
  liquidated: [],
  feeRateInfo: {
    min: 0,
    default: 0,
    max: 0,
  },
  feeRate: undefined,
  editing: "",
  isFullLiquidation: false,
  fullLiquidationDetails: "",
};

const [
  CollateralOperationsProvider,
  useCollateralOperationsStore,
  useCollateralOperationsDispatch,
] = makeStore(reducer, initialState);

export {
  CollateralOperationsProvider,
  useCollateralOperationsStore,
  useCollateralOperationsDispatch,
};
