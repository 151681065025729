import { useContext } from "react";

import { useMutation } from "react-query";

import { useSwitchCurrentOrg } from "Components/Layout/Nav/hooks";
import { useNavigate } from "Components/Link";
import { useLoadingContext } from "Contexts/LoadingContext";
import { ManifestStep } from "Routes/onboard/(shared)";
import { OnboardBasicContext } from "Routes/onboard/(utils)";
import { OrgAPI } from "Shared/api";
import { CompleteOrgAccountTypeEnum, COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

export default function ConfirmUserIsTrustee() {
  const loading = useLoadingContext();
  const navigate = useNavigate();
  const switchCurrentOrg = useSwitchCurrentOrg();
  const { showErrorToast } = useEasyToasts();
  const { from } = useContext(OnboardBasicContext) || {};
  const createOrg = useMutation(
    ({ name, accountType }: { name: string; accountType: CompleteOrgAccountTypeEnum }) =>
      OrgAPI.Create({ name, accountType })
  );
  const createTrustOrg = () => {
    loading.set(true);
    createOrg.mutate(
      {
        name: "Trust account",
        accountType: COMPLETE_ORG_ACCOUNT_TYPE.TRUST,
      },
      {
        onSuccess: async resp => {
          const uuid = resp.org?.uuid;
          await switchCurrentOrg(uuid);
          loading.set(false);
          await navigate([`/onboard/${uuid}`, from].filter(Boolean).join("?from="));
        },
        onError: error => {
          loading.set(false);
          showErrorToast(error);
        },
      }
    );
  };

  return (
    <ManifestStep
      width="max-w-lg"
      actions={[
        {
          children: "I am the trustee.",
          onClick: createTrustOrg,
        },
      ]}
      description={
        <>
          <p>
            To create an Unchained Trust account you must be a trustee of an existing trust and have
            access to all of the required trust information, which includes the personal information
            of all trustees and the grantor. Creating this account without a prior trust agreement
            in place may result in significant adverse action. Please consult your attorney if you
            have questions regarding your trust.
          </p>

          <p>
            Unchained is not a Trust company. It does not establish, create, or otherwise manage
            trusts.
          </p>
        </>
      }
      title={"Are you the Trustee?"}
    >
      <></>
    </ManifestStep>
  );
}
