import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";
import { spendingSigRequestsSelector } from "Redux/selectors/spendingSelectors";

import styles from "./NotRequiredForSigning.module.scss";

class WaitingForApprovalBase extends Component {
  static propTypes = {
    selectedSigRequest: PropTypes.object.isRequired,
  };

  render() {
    return (
      <WizardComponentWrapper size="sm">
        <h3 className={styles.smTitle}>Waiting for Approval</h3>
        <div>
          <p className={styles.content}>
            You will be able to sign once an adminstrator approves this request.
          </p>
        </div>
      </WizardComponentWrapper>
    );
  }
}

const mapStateToProps = state => {
  return spendingSigRequestsSelector(state);
};

export const WaitingForApproval = connect(mapStateToProps, null)(WaitingForApprovalBase);
