import { createSelector } from "reselect";

import { btcAccountKeys } from "../keys/accountKeySelectors";

const currency = state => state.loans.loanNewWizard.loanCurrency;

const getKeysBasedOnCurrency = createSelector(
  currency,
  btcAccountKeys,
  (currency, btcKeys, ethKeys) => {
    if (currency === "BTC") {
      return btcKeys;
    } else {
      return ethKeys;
    }
  }
);

export const getKeyOptionsForNewLoans = createSelector(getKeysBasedOnCurrency, assetKey => {
  return assetKey.filter(
    ak =>
      ak.is_valid &&
      !ak.distinguished &&
      (ak.allowed_actions || []).includes("use") &&
      (ak.allowed_actions || []).includes("create_signature") &&
      !ak.current_outbound_rekey
  );
});
