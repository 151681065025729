import { useContext, useState } from "react";

import { WizardStepperContext } from "@unchained/component-library";
import { useMutation } from "react-query";

import { Formik, FormikPhoneNumberField } from "Components/FormFields";
import { useLoadingContext } from "Contexts/LoadingContext";
import { ManifestStep } from "Routes/onboard/(shared)";
import { initPhone, OnboardBasicContext, validatePhone } from "Routes/onboard/(utils)";
import { useGetAccount, UserAPI } from "Shared/api";
import { useEasyToasts } from "Utils/toasts";

export const PhoneNumber = () => {
  const info = useContext(OnboardBasicContext);
  const { goToFirstReadyStep, goToNext } = useContext(WizardStepperContext);
  const { showErrorToast } = useEasyToasts();
  const [refetchAccount, setRefetchAccount] = useState(false);
  const loading = useLoadingContext();

  useGetAccount({ enabled: refetchAccount });

  const changePhone = useMutation(UserAPI.ChangePhone, {
    onSuccess: () => {
      setRefetchAccount(true);
      // Since we're not waiting for the refetch to complete,
      // give it a sec
      setTimeout(() => {
        loading.set(false);
        goToNext();
      }, 100);
    },
    onError: err => {
      showErrorToast(err);
    },
  });

  const initVals = initPhone(info);

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={values => {
        // Our API throws an error if the number doesn't change!
        if (values.phone === info.user.phone) return goToFirstReadyStep();

        loading.set(true);
        changePhone.mutate(values.phone);
      }}
      validationSchema={validatePhone}
      validateOnChange={true}
      debugName="PhoneNumber"
    >
      {({ handleSubmit, isValid }) => {
        return (
          <ManifestStep
            width="max-w-sm"
            actions={[
              {
                disabled: info.personalOrg.state === "pending_activation" || !isValid,
                onClick: handleSubmit,
                children: "Continue",
                buttonType: "submit",
              },
            ]}
            title="Phone number"
            description="We'll use this number to get in contact with you."
          >
            <FormikPhoneNumberField name="phone" label="Phone number" />
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
