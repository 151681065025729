import { FormikTextField, FormikTextFieldProps } from "./FormikTextField";

export const FormikSsnField = ({
  name = "ssn",
  label = "Social security number",
  fullWidth = true,
  ...other
}: Omit<FormikTextFieldProps, "name"> & { name?: string }) => {
  return <FormikTextField fullWidth={fullWidth} name={name} label={label} {...other} />;
};
