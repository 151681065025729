import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";

import { AppModalManager } from "Shared/components/Modals";

export const NoVaultsIraModal = () => {
  return (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>Vault required</ModalTitle>
      </ModalHeader>
      <ModalContent className="prose">
        You must create a vault before starting the IRA funding process.
      </ModalContent>
      <ModalFooter
        actions={[{ children: "Create vault", to: "/vaults/new", onClick: AppModalManager.close }]}
      />
    </Modal>
  );
};
