import cx from "classnames";

import { BuyPositionSummary } from "Components/TradingDesk/components/BuyPositionSummary";
import { DestinationVault } from "Contexts/BuyBitcoin/types";

import { TradeModalContainer } from "../TradeModalContainer";
import tradeModalContainerStyles from "../TradeModalContainer.module.scss";
import { BuyBitcoinForm } from "./BuyBitcoinForm";
import styles from "./BuyBitcoinModal.module.scss";

interface BuyBitcoinModalProps {
  next: () => void;
  defaultVault: DestinationVault;
}

/**
 * Component modal that allows a user to select an amount to buy, and a destination vault.
 * @param {func} next - Function to handle BuyBitcoinModal proceeding to the next modal.
 * @param {string} defaultVault - Initial selected vault in the vault select drop down.
 * @returns {React.Component}
 */
const BuyBitcoinModal = ({ next, defaultVault }: BuyBitcoinModalProps) => {
  return (
    <div
      className={cx(
        tradeModalContainerStyles.container,
        styles.container,
        styles.container_withoutWireAmountDue
      )}
    >
      <TradeModalContainer
        title="Buy bitcoin"
        containerClassName={styles.showModal}
        dismissButtonClassName={styles.closeModalButton}
      >
        <BuyPositionSummary />
        <BuyBitcoinForm defaultVault={defaultVault} next={next} />
      </TradeModalContainer>
    </div>
  );
};

export { BuyBitcoinModal };
