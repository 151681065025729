import React from "react";

import { FormControlLabel, Switch } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

export const DeprecatedFormikMuiSwitch = ({ name, color = "primary", label, ...other }) => {
  const [field] = useField({ name, type: "checkbox" });

  const SwitchComponent = <Switch color={color} {...field} {...other} />;

  return label ? <FormControlLabel control={SwitchComponent} label={label} /> : <SwitchComponent />;
};

DeprecatedFormikMuiSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
