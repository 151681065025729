import interval from "interval-promise";

import { getFirstTxRequest } from "Redux/selectors/spendingSelectors";
import { REQUEST_STATUS } from "Shared/api/api";
import { LoanAPI } from "Shared/api/loanApi";
import { TransactionAPI } from "Shared/api/transactionApi";
import { VaultAPI } from "Shared/api/vaultApi";
import { hasPermission } from "Utils/acls";
import { splitOperationType } from "Utils/operationTypes";

export const SET_SPENDING_DATA = "/SPENDING/SET/SPENDING_DATA";
export const SET_SPENDING_REQUEST_STATUS = "/SPENDING/SET/REQUEST_STATUS";
export const SET_SPENDING_SELECTED_SIG_REQUEST = "/SPENDING/SET/SELECTED_SIG_REQUEST";
export const SET_SPENDING_SELECTED_SIG_REQUEST_ACTIVE = "/SPENDING/SET/SELECTED_SIG_REQUEST/ACTIVE";
export const SET_SPENDING_SELECTED_SIG_REQUEST_REQUESTED =
  "/SPENDING/SET/SELECTED_SIG_REQUEST/REQUESTED";
export const SET_SPENDING_BROADCAST_STATUS = "/SPENDING/SET/BROADCAST_STATUS";
export const RESET_SPENDING_WIZARD = "RESET_SPENDING_WIZARD";
export const SET_SPENDING_DEVICE_SPECIFIC_UNSIGNED_PSBT =
  "/SPENDING/SET/DEVICE_SPECIFIC_UNSIGNED_PSBT";
export const SET_SPENDING_SIG_REQUEST = "/SPENDING/SET/SIG_REQUEST";
export const SET_VERIFICATION_STATE_PENDING_PROCESSING = "VERIFICATION/RECORD/REQUEST/SUBMITTING";
export const SET_VERIFICATION_STATE_PENDING_RECORDING = "VERIFICATION/RECORD/REQUEST/ERROR";

export const setSpendingSigRequestAction = sigRequest => ({
  type: SET_SPENDING_SIG_REQUEST,
  payload: sigRequest,
});

export const setSpendingDataAction = operation => ({
  type: SET_SPENDING_DATA,
  payload: operation,
});

export const setSpendingRequestStatusAction = status => ({
  type: SET_SPENDING_REQUEST_STATUS,
  payload: status,
});

export const setSelectedSigRequestAction = sigRequest => ({
  type: SET_SPENDING_SELECTED_SIG_REQUEST,
  payload: sigRequest,
});

export const setSelectedSigRequestActiveAction = value => ({
  type: SET_SPENDING_SELECTED_SIG_REQUEST_ACTIVE,
  payload: value,
});

const setSelectedSigRequestRequestedAction = (uuid, requested) => ({
  type: SET_SPENDING_SELECTED_SIG_REQUEST_REQUESTED,
  payload: { uuid, requested },
});

export const setSpendingBroadcastStatusAction = status => ({
  type: SET_SPENDING_BROADCAST_STATUS,
  payload: status,
});

export const resetSpendingWizardAction = () => ({
  type: RESET_SPENDING_WIZARD,
  payload: null,
});

export const getAccountSpendingDataAction = (
  productType: string,
  productUUID: string,
  operationType: string,
  operationUUID: string,
  navigate?: (path: string) => void
) => {
  return async dispatch => {
    // in the new operations model world, the product and
    // operation types can be combined in a single string.
    const [_productType, _operationType] = splitOperationType(operationType);

    if (_productType && _operationType) {
      productType = _productType;
      operationType = _operationType;
    }

    const apiMethod = {
      vault_sale: VaultAPI.GetTransaction,
      vault_transaction: VaultAPI.GetTransaction,
      vault_sweep: VaultAPI.GetSweep,
      vault_settlement: VaultAPI.GetSettlement,
      loan_redemption: LoanAPI.GetRedemption,
      loan_liquidation: LoanAPI.GetLiquidation,
      loan_closing: LoanAPI.GetClosing,
      loan_sweep: LoanAPI.GetSweep,
    }[`${productType}_${operationType}`];

    if (!apiMethod) {
      dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.ERROR));
      return {};
    }

    try {
      const { operation } = await apiMethod(productUUID, operationUUID);

      if (!operation) {
        if (navigate) navigate(`/${productType}s/${productUUID}`);
        dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.NOT_FOUND));
        return {};
      }
      dispatch(setSpendingDataAction(operation));
      dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.SUCCESS));
      return operation;
    } catch (e) {
      // FIXME better error messaging?
      dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.ERROR));
      return {};
    }
  };
};

export const setNextUnsignedSigRequestAction = () => {
  return (dispatch, getState) => {
    const txRequestState = getFirstTxRequest(getState()).state;
    const signatureRequests = getFirstTxRequest(getState()).signature_requests;

    // if not in a state that's ready to take signatures then we can skip
    if (txRequestState !== "ready_to_sign" && txRequestState !== "partially_signed") return;
    for (const sigReq of signatureRequests) {
      if (
        sigReq.state === "approved" &&
        sigReq.account_key.role === "client" &&
        hasPermission(sigReq.allowed_actions, "view")
      ) {
        return dispatch(setSelectedSigRequestAction(sigReq));
      }
      if (
        sigReq.account_key.role === "unchained" &&
        hasPermission(sigReq.account_key.allowed_actions, "create_signature")
      ) {
        return dispatch(setSelectedSigRequestAction(sigReq));
      }
    }
  };
};

export const continuousGetOperationUntilStatusUpdateAction = (
  operationType,
  accountUUID,
  operationUUID,
  targetSpendState,
  iterations = Infinity,
  navigate
) => {
  return async dispatch => {
    return interval(
      async (iteration, stop) => {
        const operation = await dispatch(
          getAccountSpendingDataAction(null, accountUUID, operationType, operationUUID, navigate)
        );
        if (operation.state === targetSpendState) {
          stop();
        }
        return operation;
      },
      2000,
      { iterations }
    );
  };
};

export const getDeviceSpecificUnsignedPSBTAction = (sigRequestUUID, includeXPUBs, endorse) => {
  return async dispatch => {
    const { unsigned_psbt } = (await TransactionAPI.GetBtcTxSigRequestUnsignedPSBT(
      sigRequestUUID,
      includeXPUBs,
      endorse
    )) as { unsigned_psbt: string };
    // setTimeout prevents UI flash for when this is loading
    return setTimeout(
      () =>
        dispatch({
          type: SET_SPENDING_DEVICE_SPECIFIC_UNSIGNED_PSBT,
          payload: unsigned_psbt,
        }),
      500
    );
  };
};

export const requestSelectedSigRequestAction = uuid => async dispatch => {
  await TransactionAPI.RequestBtcTxSigRequest(uuid);
  dispatch(setSelectedSigRequestRequestedAction(uuid, true));
};
