import { Button } from "@unchained/component-library";

import { Link } from "Components/Link";
import { getConfig } from "Utils/config";

import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { SellHeader } from "../components/SellHeader";

type WebSocketFailureStepProps = {
  onTryAgain: () => void;
  onClose: () => void;
};
export const WebSocketFailureStep = ({ onClose, onTryAgain }: WebSocketFailureStepProps) => {
  const contactEmail = getConfig("email.help");
  const SubTitle = () => (
    <p className="text-md">
      We're currently having trouble retrieving streaming quotes. We apologize for any
      inconvenience. Please try again or contact
      <Link to={`mailto:${contactEmail}`}> {contactEmail} </Link> if this persists.
    </p>
  );
  return (
    <div className="w-8/12 max-w-md">
      <CancelModalWrapper closeStepper={onClose}>
        <SellHeader title="Network error" subTitle={<SubTitle />} />
        <Button onClick={onTryAgain} fullWidth={true} type={"secondary"}>
          Try again
        </Button>
        <Button className="mt-3" onClick={onClose} fullWidth={true} type={"text"}>
          Close
        </Button>
      </CancelModalWrapper>
    </div>
  );
};
