export const getVaultStatus = vaultState => {
  switch (vaultState) {
    case "live":
      return "Active";
    case "pending_escrow":
      return "Creating";
    case "escrow_created":
      return "Creating";
    default:
      return "Closed";
  }
};
