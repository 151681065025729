import React from "react";

import { MenuItem, TextField } from "@mui/material";

import { DestinationSelectFieldType } from "./propTypes";
import styles from "./styles.module.scss";

export const DestinationSelectField = ({
  value,
  includeRedemption,
  includeTransfer,
  includeExternal = false,
  testId,
  disabled,
  onChange,
  error,
  helperText,
}) => {
  const numChoices = [includeRedemption, includeTransfer, includeExternal].filter(Boolean).length;

  return (
    <TextField
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      select
      onChange={onChange}
      SelectProps={{
        required: true,
        displayEmpty: true,
        value,
        "data-testid": testId ? testId : null,
      }}
      InputProps={{
        classes: {
          root: styles.muiInput,
        },
      }}
      inputProps={{
        className: styles.input,
      }}
      disabled={disabled || numChoices === 1}
    >
      {numChoices !== 1 && (
        <MenuItem value="" classes={{ root: styles.menuItem }}>
          - Select -
        </MenuItem>
      )}
      {includeRedemption && (
        <MenuItem value="redemption" classes={{ root: styles.menuItem }}>
          Current redemption address
        </MenuItem>
      )}
      {includeTransfer && (
        <MenuItem value="transfer" classes={{ root: styles.menuItem }}>
          Choose vault or loan
        </MenuItem>
      )}
      {includeExternal && (
        <MenuItem value="external" classes={{ root: styles.menuItem }}>
          Enter address manually
        </MenuItem>
      )}
    </TextField>
  );
};

DestinationSelectField.propTypes = DestinationSelectFieldType;
