import React, { ReactNode } from "react";

import { HelperText, RadioGroup, RadioGroupProps } from "@unchained/component-library";
import { useField } from "formik";

/**
 * A UCL radio group whose value is mapped to a formik field.
 */
export const FormikRadioGroup = ({
  name,
  children,
  classes = {},
  showError = false,
  onChange,
  ...other
}: FormikRadioGroupProps) => {
  const [field, { touched, error }, { setValue, setTouched, setError }] = useField(name);

  const hasError = showError && error && touched;

  return (
    <RadioGroup
      {...other}
      {...field}
      onChange={event => {
        if (onChange) {
          onChange(event);
        } else {
          setValue(event.target.value);
          setError(undefined);
        }
        // Blur field for validations
        setTimeout(() => setTouched(true), 0);
      }}
    >
      {children}
      {hasError ? <HelperText error={hasError}>{error}</HelperText> : null}
    </RadioGroup>
  );
};

interface FormikRadioGroupProps extends RadioGroupProps {
  name: string;
  children: ReactNode;
  classes?: Record<string, string>;
  /** Display field error below the radio group if present and group has been "touched". */
  showError?: boolean;
}
