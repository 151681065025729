import React from "react";

import PropTypes from "prop-types";

import { ErrorPage } from "Components/ErrorBoundaries/ErrorPage";

import styles from "./DownForMaintenance.module.scss";

const GeneralError = ({ userAuthenticated }) =>
  userAuthenticated ? (
    <div className="flex h-screen w-full items-center justify-center">
      <ErrorPage size="lg" />
    </div>
  ) : (
    <div className={styles.publicApp}>
      <h1>Error</h1>
      <p>An error has occurred.</p>
    </div>
  );

GeneralError.propTypes = {
  userAuthenticated: PropTypes.bool.isRequired,
};

GeneralError.defaultProps = {
  userAuthenticated: false,
};

export default GeneralError;
