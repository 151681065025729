import { useMutation } from "react-query";

import { countryStateData } from "Components/FormFields/FormikAddressForm/CountryStateData";
import { notCopyable, SummaryTable } from "Components/Shared/Elements/Summary/SummaryTable";
import { ManifestStep, ReviewSectionHeader } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { employmentData } from "Routes/onboard/[uuid]/(ira)/steps/IraInfo/(utils)";
import { ProfileAPI } from "Shared/api";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { addDollarSignsToMoneyRange } from "../../individualOrgValsAndValidations";

export const ReviewIndividual = ({ org }: { org: CompleteOrg }) => {
  const { uuid, advanced_profile_state } = org;
  const wrapManifestMutation = useWrapManifestMutation();

  const submitAdvanced = wrapManifestMutation(useMutation(() => ProfileAPI.SubmitPersonal(uuid)));
  const { person } = org;
  const nationality = countryStateData.getCountry(person.nationality)?.name;
  const submit = submitAdvanced.mutate;

  return (
    <ManifestStep
      width="narrow"
      actions={[
        {
          children: "Submit for review",
          onClick: () => submit(),
          disabled: ["pending_approval", "approved"].includes(advanced_profile_state),
        },
      ]}
      title="Review individual information"
      description="Please review the information provided and update if needed."
    >
      <div className="flex flex-col gap-4">
        <SummaryTable
          rows={notCopyable([{ value: person.ssn, maskedValue: person.masked_ssn, title: "SSN" }])}
        />

        <ReviewSectionHeader stepName="Finish your account">
          Social security number
        </ReviewSectionHeader>
        <SummaryTable
          rows={notCopyable([
            { value: nationality, title: "Nationality" },
            {
              value: person.ssn,
              maskedValue: person.masked_ssn,
              title: person.nationality === "US" ? "SSN" : "TIN",
            },
          ])}
        />

        <ReviewSectionHeader stepName="Finish your account">Employment status</ReviewSectionHeader>
        <SummaryTable rows={employmentData(org)} />

        <ReviewSectionHeader stepName="Finish your account">Personal income</ReviewSectionHeader>
        <SummaryTable
          rows={[
            { value: addDollarSignsToMoneyRange(person.income), title: "Income range" },
            { value: person.income_sources, title: "Sources" },
          ]}
        />

        <ReviewSectionHeader stepName="Finish your account">
          Wealth & digital assets
        </ReviewSectionHeader>
        <SummaryTable
          className="mb-4 rounded-sm"
          rows={[
            { value: addDollarSignsToMoneyRange(person.estimated_wealth), title: "Wealth range" },
            { value: person.estimated_wealth_notes, title: "Wealth sources" },
          ]}
        />
      </div>
    </ManifestStep>
  );
};
