import _ from "lodash";
import { createSelector } from "reselect";

// get the price from the store
const rawUserKeys = state => state.accountKeys.keyIndex.userKeys;

export const sortedUserKeys = createSelector(rawUserKeys, rawUserKeys => {
  const userKeys = Object.keys(rawUserKeys).map(k => rawUserKeys[k]);
  return _.sortBy(userKeys, ["name"]);
});

export const btcAccountKeys = createSelector(sortedUserKeys, userKeys => {
  return _.filter(userKeys, { unit: "BTC" });
});
