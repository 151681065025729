import { SET_OVERLAY } from "Actions/overlayActions";

const initialState = {
  isOverlay: false,
};

export const overlayReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_OVERLAY: {
      return {
        ...state,
        isOverlay: payload,
      };
    }
    default:
      return state;
  }
};
