import React, { createContext, ReactNode, useContext } from "react";

import { useAndRemoveQueryParams } from "Utils/uris";

/**
 * Generates a context and hook for consuming that context.
 * When the context is rendered, it parses the specified values from the query string and makes them
 * available to all consumers (which use the hook). It hides the values in the query string
 * once it has parsed them.
 *
 * @example
 *
 * const {
 *  QueryStringContext: MyQsContext,
 *  useQueryStringContext: useMyQs
 *  // Not used in example, but alternative to above hook
 *  withQueryStringContext
 * } =
 *   makeQueryStringContext<{ uuid: string; accountType: CompleteOrgAccountTypeEnum }>(['uuid', 'accountType']);
 *
 * // => Sub-components can access uuid and accountType (passed via query string) from the context,
 * // and the values will no longer be visible in the query string.
 *  */
export function makeQueryStringContext<T extends Record<string, string>>(
  paramsToLookFor: (keyof T)[]
) {
  const context = createContext<T>(null);
  const Provider = context.Provider;
  const useQueryStringContext = () => {
    const ctx = useContext(context);
    if (!ctx) {
      throw new Error("useQueryStringContext must be used within a QueryStringContextProvider");
    }
    return ctx;
  };

  const QueryStringContext = ({ children }: { children: ReactNode }) => {
    const qs = useAndRemoveQueryParams(...(paramsToLookFor as string[])) as T;
    return <Provider value={qs}>{children}</Provider>;
  };

  const withQueryStringContext = (Component: React.ComponentType<any>) => {
    return (props: any) => (
      <QueryStringContext>
        <Component {...props} />
      </QueryStringContext>
    );
  };

  return {
    QueryStringContext,
    useQueryStringContext,
    withQueryStringContext,
  };
}
