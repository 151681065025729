import React, { useCallback, useContext, useEffect, useState } from "react";

import { KEYSTORES } from "@caravan/wallets";
import type { LEDGER } from "@caravan/wallets";
import { ModalContent, ModalFooter } from "@unchained/component-library";

import { SigningDeviceSelector } from "Components/SigningDevices/SigningDeviceSelector";
import { useGetWalletConfig } from "Shared/api";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";

export const ChooseDeviceStep = () => {
  const [nextEnabled, setNextEnabled] = useState(false);
  const { setDeviceType, deviceType, walletUuid, accountKey, handleNext } =
    useContext(ConfirmOnDeviceContext);
  const walletConfigQuery = useGetWalletConfig(walletUuid);

  useEffect(() => {
    if (deviceType) setNextEnabled(true);
    else setNextEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNext = useCallback(() => {
    let registration;
    if (walletConfigQuery.data?.ledgerPolicyHmacs) {
      registration = walletConfigQuery.data?.ledgerPolicyHmacs.find(
        registration => registration.xfp === accountKey.xfp
      );
    }
    if (deviceType === KEYSTORES.LEDGER && !registration) {
      setDeviceType(`unregisteredledger`);
    }
    handleNext();
  }, [
    accountKey.xfp,
    deviceType,
    handleNext,
    setDeviceType,
    walletConfigQuery.data?.ledgerPolicyHmacs,
  ]);

  const handleSetDeviceType = type => {
    setDeviceType(type);
    setNextEnabled(true);
  };

  return (
    <>
      <ModalContent>
        <div className="py-3">
          <SigningDeviceSelector
            onDeviceSelected={handleSetDeviceType}
            size="lg"
            defaultActiveDeviceType={deviceType}
          />
        </div>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: "Next",
            onClick: onNext,
            disabled: !nextEnabled,
          },
        ]}
      />
    </>
  );
};
