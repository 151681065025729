import {
  UPDATE_USER_PERSONAL_ORG,
  UPDATE_USER_PERSONAL_ORG_STATE,
  UPDATE_USER_PERSONAL_ORG_ADVANCED_PROFILE_STATE,
  UPDATE_USER_PERSONAL_ORG_BANK_ACCOUNT,
  UPDATE_USER_PERSONAL_ORG_MULTI_INSITUTION_VAULTS,
  UPDATE_USER_PERSONAL_ORG_CODENAME,
  UPDATE_USER_PERSONAL_ORG_DENIED_REASON,
} from "Actions/userActions/personalOrgActions";
import { RESET_USER_DATA } from "Actions/userActions/userActions";
import { REQUEST_STATUS } from "Shared/api/api";

const initialState = {
  uuid: "",
  name: "",
  personal: true,
  type: "client",
  denied_reason: "",
  state: "pending_basic_info",
  advanced_profile_state: "pending_submission",
  multi_institution_vaults: false,
  codename: "",

  person: {},

  accepted_members: [],
  pending_members_emails: [],
  pending_members: [],
  pending_signups: [],
  max_members: 1,
  available_memberships: 0,

  child_roles: [],
  child_org_groups: [],
  user_groups: [],
  account_key_groups: [],
  vault_groups: [],
  loan_groups: [],

  bank_accounts: [],

  basicProfileStatus: REQUEST_STATUS.SUCCESS,
  accountKeys: {},
  vaults: {},
  loans: [],

  allowed_actions: [],
};

const updateBankAccount = (state, payload) => {
  return {
    ...state,
    bank_accounts: state.bank_accounts.map(bank_account => {
      return bank_account.uuid === payload.uuid ? payload : bank_account;
    }),
  };
};

export const personalOrgReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USER_PERSONAL_ORG: {
      return {
        ...state,
        ...payload,
      };
    }

    case UPDATE_USER_PERSONAL_ORG_STATE: {
      return {
        ...state,
        state: payload,
      };
    }

    case UPDATE_USER_PERSONAL_ORG_CODENAME: {
      return {
        ...state,
        codename: payload,
      };
    }

    case UPDATE_USER_PERSONAL_ORG_DENIED_REASON: {
      return {
        ...state,
        denied_reason: payload,
      };
    }

    case UPDATE_USER_PERSONAL_ORG_ADVANCED_PROFILE_STATE: {
      return {
        ...state,
        advanced_profile_state: payload,
      };
    }

    case UPDATE_USER_PERSONAL_ORG_BANK_ACCOUNT: {
      return updateBankAccount(state, payload);
    }

    case UPDATE_USER_PERSONAL_ORG_MULTI_INSITUTION_VAULTS: {
      return {
        ...state,
        multi_institution_vaults: payload,
      };
    }

    case RESET_USER_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};
