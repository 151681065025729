import { logoMap } from "Components/Shared/Elements/Keys/logoMap";

import { getConfig } from "./config";

export const arbiterInfo = {
  name: getConfig("arbiter", "Fortis Bank").name,
  logoSlug: getConfig("arbiter", "fortis").logo_slug,
  logo: logoMap[getConfig("arbiter", "fortis").logo_slug],
  css: "arbiter",
};
