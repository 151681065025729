import React from "react";

import { FormHelperText, TextField as MuiTextField } from "@mui/material";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./TextField.module.scss";

export const DeprecatedTextField = ({
  topLabel,
  topLabelClasses = {},
  variant = "outlined",
  bold = true,
  mono = false,
  InputProps = { classes: undefined },
  inputProps = { className: undefined },
  multiline = false,
  label,
  ...other
}) => {
  return (
    <div>
      {topLabel ? <FormHelperText classes={topLabelClasses}>{topLabel}</FormHelperText> : null}
      <MuiTextField
        InputProps={{
          ...InputProps,
          classes: {
            ...InputProps.classes,
            root: cx(
              InputProps.classes ? InputProps.classes.root : undefined,
              styles.muiInput,
              label ? null : styles.noLabel
            ),
            disabled: styles.disabled,
          },
        }}
        inputProps={{
          ...inputProps,
          className: cx(inputProps.className, {
            [styles.input]: !multiline,
            [styles.input_bold]: bold && !multiline,
            [styles.input_mono]: mono,
          }),
        }}
        variant={variant}
        multiline={multiline}
        label={label}
        {...other}
      />
    </div>
  );
};

DeprecatedTextField.propTypes = {
  topLabel: PropTypes.string,
  topLabelClasses: PropTypes.object,
  variant: PropTypes.string,
  bold: PropTypes.bool,
  mono: PropTypes.bool,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  multiline: PropTypes.bool,
};
