import { useState } from "react";
import { useDispatch } from "react-redux";

type ActionlessKey<K extends string> = K extends `${infer V}Action` ? V : K;

type ActionsProps<T> = {
  [K in keyof T as ActionlessKey<K & string>]: T[K];
};

/**
 * @param actions {object} - An object of action creator functions.
 *
 * @returns An object where each of these functions is wrapped in a dispatch call.
 *          If the action function name ends in "Action", that string will be removed from
 *          the name of the dispatch function. Action types will be "inherited" by the wrapping
 *          dispatch function.
 *
 *          Similar, in a way, to a hooks-based mapDispatchToProps. Makes using actions in a component
 *          and passing on their function types cleaner.
 *
 * @example
 *
 * const doSomethingAction = (firstArg: string, secondArg: number) => ({
 *  type: "DO_SOMETHING",
 *  payload: { firstArg, secondArg }
 * });
 *
 * // In component:
 *
 * const { doSomething } = useActions({ doSomethingAction });
 * doSomething("foo", 42); // Types look good!
 * doSomething("foo"); // Type check fails -- missing secondArg!
 * doSomething("foo", 42, "bar"); // Type check fails -- too many arguments!
 * doSomething(42, "foo"); // Type check fails -- wrong argument types!
 */
export function useActions<T extends object>(actions: T): ActionsProps<T> {
  const [prevActions, setPrevActions] = useState<ActionsProps<T> | null>(null);
  const dispatch = useDispatch();
  if (prevActions) return prevActions;

  const keys = Object.keys(actions);
  const dispatchableActions = keys.reduce(
    (acc, key) => ({
      ...acc,
      [key.replace(/Action$/g, "")]: (...args: any[]) => dispatch((actions as any)[key](...args)),
    }),
    {}
  ) as any;

  setPrevActions(dispatchableActions);
  return dispatchableActions;
}
