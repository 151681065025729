import React from "react";

import { useBreakpoint, useToast } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { setIsShowCancelModal } from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  useSellBitcoinDispatch,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import { getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { useDeleteSellBitcoinOperation } from "Shared/api";

import { sellBitcoinNetworkFailureToastDescription } from "../../helpers";
import { LeaveSellFlowModal } from "./LeaveSellFlowModal";

export const CancelModalWrapper = ({
  children,
  closeStepper,
}: {
  children?: React.ReactNode;
  closeStepper: (isCancellingSale?: boolean) => void;
}) => {
  const { isShowCancelModal, currentSaleUuid } = useSellBitcoinStore();
  const dispatch = useSellBitcoinDispatch();

  const orgUuid: string = useSelector(getCurrentOrgId);
  const { enqueueSimpleToast } = useToast();

  const mobileLeaveFlowModalStyles = "!h-[250px] w-[400px]";
  // margin left is needed to offset the stepper sidebar and center the modal
  const desktopLeaveFlowModalStyle = "ml-[312px] w-[400px]";

  const { widthIsBelow } = useBreakpoint();
  const isMobile = widthIsBelow("sm");

  const handleDeleteTxError = () => {
    enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
  };

  const handleOnSuccessDelete = () => {
    dispatch(setIsShowCancelModal(false));
    closeStepper(true);
  };

  const { mutate: deleteSellBitcoinOperation, isLoading: isDeleting } =
    useDeleteSellBitcoinOperation({
      orgUuid,
      currentSaleUuid,
      handleOnSuccess: handleOnSuccessDelete,
      handleOnError: handleDeleteTxError,
    });

  const cancelTransactionAndClose = () => {
    deleteSellBitcoinOperation();
  };

  const closeModal = () => {
    dispatch(setIsShowCancelModal(false));
    closeStepper();
  };
  return (
    <div>
      {children}
      {isShowCancelModal ? (
        <LeaveSellFlowModal
          className={isMobile ? mobileLeaveFlowModalStyles : desktopLeaveFlowModalStyle}
          onCloseClick={() => {
            closeModal();
          }}
          onCancelAndClose={cancelTransactionAndClose}
          isDeleting={isDeleting}
        />
      ) : null}
    </div>
  );
};
