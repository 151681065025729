import { useToast } from "@unchained/component-library";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { CamelCasedProperties } from "type-fest";

import {
  AddOrgOnboardingRequest,
  GetIraContractSignerUrl200,
  GetIraContractSignerUrlErrors,
  GetIraOrg200,
} from "Specs/v1";
import { AddOrUpdateAddressRequest } from "Specs/v1/addOrUpdateAddress/request";
import { CreateTrustGrantorRequest } from "Specs/v1/createTrustGrantor/request";
import { CreateTrustTrusteeRequest } from "Specs/v1/createTrustTrustee/request";
import { Address, AddressRoleEnum, IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { SearchOrgsQueryParams } from "Specs/v1/searchOrgs/params/query";
import { setDefaults, snakeCaseKeys } from "Utils/objects";

import { OrgAPI, ProfileAPI, TrustAPI } from "../";
import { ChangeProposalAPI } from "../changeProposalApi";
import { ApiError } from "./helpers";

export const orgQueryKeys = {
  all: ["orgs"] as const,
  search: (options: SearchOrgsQueryParams) => [...orgQueryKeys.all, snakeCaseKeys(options)],
  showIra: uuid => [...orgQueryKeys.all, "showIra", uuid],
  show: uuid => [...orgQueryKeys.all, "show", uuid],
  iraSignerUrl: uuid => [...orgQueryKeys.all, "signerUrl", uuid],
  productCount: uuid => ["getProductsCount", uuid],
  recentTransactions: uuid => ["recentTransactions", uuid],
  nonPlaidBankAccountsAllowed: uuid => ["nonPlaidBankAccountsAllowed", uuid],
  changeProposals: uuid => ["changeProposals", uuid],
  payingCustomer: uuid => ["payingCustomer", uuid],
};

export const useSearchOrgs = (searchParams: CamelCasedProperties<SearchOrgsQueryParams> = {}) => {
  const queryParams = snakeCaseKeys(
    setDefaults<CamelCasedProperties<SearchOrgsQueryParams>>(searchParams, {
      page: 1,
      perPage: 20,
    })
  );

  return useQuery(orgQueryKeys.search(queryParams), () => OrgAPI.Search(queryParams));
};

type IraWithUuid = { org: IraOrg & { uuid: string } };
/** Returns the query result for an IRA Org get */
export const useIraOrg = (uuid: string, options?: UseQueryOptions<IraWithUuid>) =>
  useQuery<IraWithUuid>(orgQueryKeys.showIra(uuid), () => OrgAPI.GetIraOrg(uuid), options);

export const useGetOrg = (uuid: string, options?: UseQueryOptions) =>
  useQuery<CompleteOrg>(orgQueryKeys.show(uuid), () => OrgAPI.Get(uuid), options);

/** Returns a mutation for adding an org's onboarding info */
export const useAddOrgOnboarding = (uuid: string) =>
  useMutation(({ conciergeOnboarding, devicesRequested }: AddOrgOnboardingRequest) =>
    OrgAPI.AddOrgOnboarding(uuid, { conciergeOnboarding, devicesRequested })
  );

/** Returns a function that takes the state of the org and replaces it with a new one */
export const useUpdateLocalOrg = orgUuid => {
  const queryClient = useQueryClient();
  const key = orgQueryKeys.showIra(orgUuid);

  return (getNewOrg: (org: IraOrg) => IraOrg) => {
    const prevData = queryClient.getQueryData(key) as GetIraOrg200;
    const newOrg = getNewOrg(prevData.org);
    return queryClient.setQueryData(key, { org: newOrg });
  };
};

/** Returns a mutation for updating the address associated with an org profile. */
export const useUpdateProfileAddress = (orgUuid: string, snakeCase = false) =>
  useMutation((address: AddOrUpdateAddressRequest | Address) => {
    const addr = (snakeCase ? snakeCaseKeys(address) : address) as AddOrUpdateAddressRequest;
    return ProfileAPI.UpdateAddress(orgUuid, address.role, addr);
  });

export const useDeleteOrgAddress = (orgUuid: string) =>
  useMutation((role: AddressRoleEnum) => {
    return ProfileAPI.DeleteAddress(orgUuid, role);
  });

/** Submits an org for admin approval */
export const useSubmitOrg = (orgUuid: string) => useMutation(() => ProfileAPI.SubmitBasic(orgUuid));

// Admin action
export const useApproveOrg = (orgUuid: string) =>
  useMutation(() => OrgAPI.AdminApproveOrg(orgUuid));

export const useApproveOrgAdvanced = (orgUuid: string) =>
  useMutation(() => OrgAPI.AdminApproveOrgAdvanced(orgUuid));

// Admin action
export const useDenyOrg = (orgUuid: string) =>
  useMutation((reason: string) => OrgAPI.AdminDenyOrg(orgUuid, reason));

export const useDenyOrgAdvanced = (orgUuid: string) =>
  useMutation((reason: string) => OrgAPI.AdminDenyOrgAdvanced(orgUuid, reason));

// Admin action
export const useRequestOrgResubmission = (orgUuid: string) =>
  useMutation((reason: string) => OrgAPI.AdminRequestOrgResubmission(orgUuid, reason));

export const useRequestAdvancedOrgResubmission = (orgUuid: string) =>
  useMutation((reason: string) => OrgAPI.AdminRequestAdvancedOrgResubmission(orgUuid, reason));
export const useIraSignerUrl = (orgUuid: string) =>
  useQuery<GetIraContractSignerUrl200, ApiError<GetIraContractSignerUrlErrors>>(
    orgQueryKeys.iraSignerUrl(orgUuid),
    () => OrgAPI.GetIraSignerUrl(orgUuid)
  );

export const useGetRecentTransactions = (uuid: string, options?: UseQueryOptions) =>
  useQuery(
    orgQueryKeys.recentTransactions(uuid),
    () => OrgAPI.GetRecentTransactions(uuid),
    options
  );

export const useGetProductCount = (uuid: string) =>
  useQuery<{ num_active_loans: number; num_active_vaults: number }>(
    orgQueryKeys.productCount(uuid),
    () => OrgAPI.GetProductsCount(uuid)
  );

export const useInviteMembersProposal = (orgUuid: string) =>
  useMutation((users: { email: string; roles: string[] }[]) =>
    ChangeProposalAPI.CreateChangeProposalGroup(orgUuid, {
      changeProposals: users.map(user => ({
        type: "OrgMemberInviteChangeProposal",
        ...user,
      })),
    })
  );

export const useRemoveMemberProposal = (orgUuid: string) =>
  useMutation((users: { email: string }[]) =>
    ChangeProposalAPI.CreateChangeProposalGroup(orgUuid, {
      changeProposals: users.map(user => ({
        type: "OrgMemberRemoveChangeProposal",
        ...user,
      })),
    })
  );

export const useCreateTrustGrantor = (orgUuid: string) =>
  useMutation((grantor: CreateTrustGrantorRequest) => TrustAPI.CreateGrantor(orgUuid, grantor));

export const useCreateTrustTrustee = (orgUuid: string) =>
  useMutation((trustee: CreateTrustTrusteeRequest) => TrustAPI.CreateTrustee(orgUuid, trustee));

export const useUpdateNonPlaidStatus = (orgUuid: string) => {
  const queryClient = useQueryClient();
  const { enqueueSimpleToast } = useToast();

  return useMutation(
    (nonPlaidBankAccountsAllowed: boolean) =>
      OrgAPI.UpdateNonPlaidBankAccountsAllowed(orgUuid, nonPlaidBankAccountsAllowed),
    {
      onSuccess: () => {
        enqueueSimpleToast({
          type: "success",
          title: "Success",
          description: "Org's non-plaid bank account status has been updated.",
          dismissable: true,
        });
        queryClient.invalidateQueries(orgQueryKeys.nonPlaidBankAccountsAllowed(orgUuid));
      },
      onError: () => {
        enqueueSimpleToast({
          title: "Error",
          type: "error",
          description: "Could not update org's non-plaid bank account status.",
          dismissable: true,
          persist: true,
        });
      },
    }
  );
};

export const useNonPlaidBankAccountsAllowedQuery = (uuid: string) =>
  useQuery(
    orgQueryKeys.nonPlaidBankAccountsAllowed(uuid),
    () => OrgAPI.GetNonPlaidBankAccountsAllowed(uuid),
    {
      refetchOnWindowFocus: true,
    }
  );

export const useOrgChangeProposals = (uuid: string, pending = false) =>
  useQuery(orgQueryKeys.changeProposals(uuid), () =>
    ChangeProposalAPI.GetOrgChangeProposalGroups(uuid, pending)
  );

export const useOrgPayingCustomer = (uuid: string) =>
  useQuery(orgQueryKeys.payingCustomer(uuid), () => OrgAPI.GetPayingCustomer(uuid));
