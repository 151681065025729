import React, { Component } from "react";

import { CircularProgress } from "@mui/material";
import { Button } from "@unchained/component-library";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { flash } from "Actions/flash";
import { requestSelectedSigRequestAction } from "Actions/transactionActions/spendingActions";
import wizStyles from "Components/Shared/wizard/WizardComponent.module.scss";
import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";
import { spendingSigRequestsSelector } from "Redux/selectors/spendingSelectors";

import styles from "./NotRequiredForSigning.module.scss";
import { SigningWizard } from "./SigningWizard";

class NotRequiredForSigningBase extends Component {
  static propTypes = {
    selectedSigRequest: PropTypes.object.isRequired,
    isTxPastThreshold: PropTypes.bool.isRequired,
  };

  state = {
    signAnyway: false,
    requestPending: false,
    product: null,
  };

  handleSignAnywayClick = () => {
    this.setState({ signAnyway: true });
  };

  signableKeyContent = () => {
    const { selectedSigRequest } = this.props;
    return (
      <div className="flex flex-col items-center">
        <p className={styles.content}>This key was not selected to sign.</p>
        <Button onClick={this.handleSignAnywayClick}>
          Sign for Key {selectedSigRequest.account_key.name}
        </Button>
      </div>
    );
  };

  onRequestSignatureClick = async () => {
    const { selectedSigRequest, requestSigRequest, flash, isTxPastThreshold } = this.props;
    try {
      this.setState({ requestPending: true });
      await requestSigRequest(selectedSigRequest.uuid);
      if (isTxPastThreshold) window.location.reload();
    } catch (e) {
      flash("error", e.message);
      this.setState({ requestPending: false });
    }
  };

  unchainedMessage = () => {
    const { requestPending } = this.state;
    const { selectedSigRequest, isTxPastThreshold, isLoadingProduct } = this.props;
    if (isLoadingProduct) return <CircularProgress />;
    const verificationMessage = isTxPastThreshold
      ? "If you would like Unchained to sign this transaction, a video verification will be required."
      : "";
    return (
      <div className="flex flex-col items-center">
        <p className={styles.content}>
          Unchained was not selected as a key for this transaction. You may submit a request for
          Unchained to sign below.
          <br />
          {verificationMessage}
        </p>
        {selectedSigRequest.allowed_actions.includes("view") &&
          selectedSigRequest.can_be_requested && (
            <>
              <br />
              <Button onClick={this.onRequestSignatureClick} disabled={requestPending}>
                {requestPending ? <CircularProgress /> : "Request signature"}
              </Button>
            </>
          )}
      </div>
    );
  };

  getContent = () => {
    const { selectedSigRequest } = this.props;
    if (selectedSigRequest?.account_key?.allowed_actions.includes("create_signature")) {
      return <div>{this.signableKeyContent()}</div>;
    }
    if (selectedSigRequest?.account_key?.role === "unchained") {
      return <div>{this.unchainedMessage()}</div>;
    } else {
      return (
        <div>
          <p className={styles.content}>This key was not selected to sign.</p>
        </div>
      );
    }
  };

  render() {
    const { signAnyway } = this.state;
    if (signAnyway) {
      return <SigningWizard />;
    } else {
      return (
        <WizardComponentWrapper size="sm">
          <h3 className={styles.smTitle}>Request to sign?</h3>
          <div className={wizStyles.response}>{this.getContent()}</div>
        </WizardComponentWrapper>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    ...spendingSigRequestsSelector(state),
  };
};

const mapDispatchToProps = {
  requestSigRequest: requestSelectedSigRequestAction,
  flash,
};

export const NotRequiredForSigning = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotRequiredForSigningBase);
