import get from "lodash/get";

import { flash } from "Actions/flash";
import { OrgAPI } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";
import { ProfileAPI } from "Shared/api/profileApi";

export const REPLACE_ORG_SHOW = "/ORGS/SHOW/UPDATE";
export const RESET_ORG_SHOW = "/ORGS/SHOW/CLEAR";
export const SET_ORG_SHOW_STATUS = "/ORGS/SHOW/SET/STATUS";
export const UPDATE_ORG_BANK_ACCOUNT = "/ORGS/SHOW/BANK_ACCOUNTS/UPDATE";
export const UPDATE_ORG_MULTI_INSTITUTION_VAULTS = "/ORGS/SHOW/MULTI_INSTITUTION_VAULTS/UPDATE";
export const UPDATE_ORG_NOTE = "/ORGS/SHOW/NOTE/UPDATE";
export const UPDATE_ORG_CODENAME = "/ORGS/SHOW/CODENAME/UPDATE";
export const UPDATE_ORG_DENIED_REASON = "/ORGS/SHOW/DENIED_REASON/UPDATE";

// Replaces the current show org with the new one
export const replaceOrgShowAction = org => ({
  type: REPLACE_ORG_SHOW,
  payload: org,
});

export const resetOrgShowAction = () => ({
  type: RESET_ORG_SHOW,
  payload: null,
});

export const updateOrgBankAccountAction = bankAccount => ({
  type: UPDATE_ORG_BANK_ACCOUNT,
  payload: bankAccount,
});

export const updateOrgMultiInstitutionVaultsAction = status => ({
  type: UPDATE_ORG_MULTI_INSTITUTION_VAULTS,
  payload: status,
});

export const updateOrgNoteAction = note => ({
  type: UPDATE_ORG_NOTE,
  payload: note,
});

export const updateOrgCodenameAction = codename => ({
  type: UPDATE_ORG_CODENAME,
  payload: codename,
});

export const setOrgShowStatusAction = status => ({
  type: SET_ORG_SHOW_STATUS,
  payload: status,
});

export const getOrgShowAction = uuid => async dispatch => {
  try {
    dispatch(setOrgShowStatusAction(REQUEST_STATUS.PENDING));
    const org = await OrgAPI.Get(uuid);
    dispatch(replaceOrgShowAction(org));
    dispatch(setOrgShowStatusAction(REQUEST_STATUS.SUCCESS));
    return org;
  } catch (e) {
    const status = get(e, "response.status", 0);
    switch (status) {
      case 403:
        dispatch(setOrgShowStatusAction(REQUEST_STATUS.FORBIDDEN));
        break;
      case 404:
        dispatch(setOrgShowStatusAction(REQUEST_STATUS.NOT_FOUND));
        break;
      default:
        dispatch(setOrgShowStatusAction(REQUEST_STATUS.ERROR));
        break;
    }
  }
};

//
// Multi-Institution Vaults
//

export const submitEnableNonPersonalOrgMultiInstitutionVaultsAction = uuid => async dispatch => {
  try {
    const response = await ProfileAPI.EnableMultiInstitutionVaults(uuid);
    dispatch(updateOrgMultiInstitutionVaultsAction(true));
    dispatch(flash("success", response.message));
  } catch (e) {
    dispatch(flash("error", e.response.message));
  }
};

export const submitDisableNonPersonalOrgMultiInstitutionVaultsAction = uuid => async dispatch => {
  try {
    const response = await ProfileAPI.DisableMultiInstitutionVaults(uuid);
    dispatch(updateOrgMultiInstitutionVaultsAction(false));
    dispatch(flash("success", response.message));
  } catch (e) {
    dispatch(flash("error", e.response.message));
  }
};
