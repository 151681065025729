import { useMutation } from "react-query";

import {
  CreatePersonRequest,
  PatchPersonRequest,
  PutPersonCitizenshipRequest,
  PutPersonEmploymentRequest,
} from "Specs/v1";

import { PersonAPI } from "..";

export const usePutPersonCitizenship = (uuid: string) =>
  useMutation((citizenship: PutPersonCitizenshipRequest) =>
    PersonAPI.PutCitizenship(uuid, citizenship)
  );

export const usePutPersonEmployment = (personUuid: string) =>
  useMutation((employment: PutPersonEmploymentRequest) =>
    PersonAPI.PutEmployment(personUuid, employment)
  );

export const useCreatePerson = () =>
  useMutation((person: CreatePersonRequest) => PersonAPI.Create(person));

export const usePatchPerson = () =>
  useMutation((person: PatchPersonRequest & { uuid: string }) =>
    PersonAPI.Patch(person.uuid, person)
  );

export const useCreateOrUpdatePersonDocuments = () =>
  useMutation(
    ({
      uuid,
      docs,
    }: {
      uuid: string;
      docs: { state_id?: Document; death_certificate?: Document };
    }) => PersonAPI.CreateOrUpdatePersonDocuments(uuid, docs)
  );
