import React from "react";

import { Grid } from "@mui/material";

import { readableLocalDate, currentUTCTime } from "./time";

export function verificationScript(body) {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <p>Today is {readableLocalDate(currentUTCTime())}.</p>
      </Grid>
      <Grid item>
        <p>
          My name is <code>[YOUR FULL NAME]</code>.
        </p>
      </Grid>
      {body}
    </Grid>
  );
}

export function validateAddress(address) {
  if (!address) {
    return false;
  }
  const { line_1, city, state, zip, country } = address;

  return (
    line_1 &&
    line_1 !== "" &&
    city &&
    city !== "" &&
    state &&
    state !== "" &&
    zip &&
    zip !== "" &&
    country &&
    country !== ""
  );
}
