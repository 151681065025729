import React, { Component } from "react";

import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { Tooltip } from "@unchained/component-library";
import classnames from "classnames";
import PropTypes from "prop-types";

import { KeyIcon } from "../../Icons/KeyIcon";
import styles from "./ClientKey.module.scss";
import { SignedRibbon } from "./SignedRibbon";

class ClientKeyBase extends Component {
  static propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    onClick: PropTypes.func,
    shared: PropTypes.bool,
    ownerName: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    distinguished: PropTypes.bool,
    active: PropTypes.bool,
    size: PropTypes.string,
    accountKey: PropTypes.object,
    disabled: PropTypes.bool,
    signedPsbt: PropTypes.string,
    invalid: PropTypes.bool,
  };

  static defaultProps = {
    active: true,
    size: "md",
  };

  IconChooser = () => {
    const { size } = this.props;
    return (
      <KeyIcon
        classes={{
          root: classnames({
            [styles.xsIcon]: size === "xs" || size === "sm",
            [styles.mdIcon]: size === "md",
            [styles.lgIcon]: size === "lg",
          }),
        }}
      />
    );
  };

  onClick = e => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { active, ownerName, shared, disabled, size, name, signedPsbt, invalid } = this.props;

    return (
      <Button
        classes={{
          root: classnames(styles.container, {
            [styles.default]: !active,
            [styles.active]: active,
            [styles.disabled]: disabled || !name,
            [styles.partner]: shared,
            [styles.invalid]: invalid,
          }),
          contained: classnames(styles.contentContainer, {
            [styles.xsContainer]: size === "xs",
            [styles.smContainer]: size === "sm",
            [styles.mdContainer]: size === "md",
            [styles.lgContainer]: size === "lg",
          }),
        }}
        variant="contained"
        disabled={disabled}
        onClick={this.onClick}
        ref={this.props.ref}
      >
        {signedPsbt && <SignedRibbon />}
        <div className={styles.iconContainer}>
          <this.IconChooser />
        </div>
        <div className={styles.textContainer}>
          {name}
          <FormHelperText
            classes={{
              root: classnames(styles.username, {
                [styles.usernameActive]: active,
                [styles.smUsername]: size === "xs" || size === "sm",
              }),
            }}
            style={{ marginTop: 0 }}
          >
            {ownerName}
          </FormHelperText>
        </div>
      </Button>
    );
  }
}

export const ClientKey = ClientKeyBase;
