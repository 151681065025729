import React from "react";

import PropTypes from "prop-types";

import { humanState } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

export function disbursementProptypes(admin) {
  if (admin) {
    return {
      ...disbursementProptypes(),
      ...{},
    };
  } else {
    return {
      state: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      initiated_at: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,

      humanState: PropTypes.string.isRequired,
      amountWithUnit: PropTypes.string.isRequired,

      readableCreatedAt: PropTypes.string.isRequired,
      readableUpdatedAt: PropTypes.string.isRequired,
      readableInitiatedAt: PropTypes.string,
    };
  }
}

export function Disbursement(data, loan) {
  if (data == null || loan == null) {
    return data;
  }

  data.humanState = humanState(data);

  data.amountWithUnit = `$${formatCurrency(data.amount, 2)}`;

  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTime(data.updatedAt);
  data.initiatedAt = parseTimestamp(data.initiated_at);
  data.readableInitiatedAt = readableTime(data.initiatedAt);

  return data;
}
