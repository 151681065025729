import Big from "big.js";

import { BTC, USD } from "Contexts/BuyBitcoin";
import { Currency, Fee, MutableTrader } from "Contexts/BuyBitcoin/types";
import { Traders } from "Specs/v1/getTraders/200";
import { getConfig } from "Utils/config";

export const convertUsdToBtc = (usdAmount: string | number, btcPrice: string): string | null => {
  if (usdAmount && btcPrice) {
    try {
      return Big(usdAmount).div(btcPrice).toFixed(8);
    } catch {
      return null;
    }
  }
};

export const convertBtcToUsd = (btcAmount: string | number, btcPrice: string): string | null => {
  if (btcAmount && btcPrice) {
    try {
      return Big(btcPrice).times(btcAmount).toFixed(2);
    } catch {
      return null;
    }
  }
};

export const getInverseBtcUsdAmount = (
  currency: Currency,
  amount: string | number,
  bitcoinPrice: string
) => {
  if (currency === USD) {
    return convertUsdToBtc(amount, bitcoinPrice);
  } else {
    return convertBtcToUsd(amount, bitcoinPrice);
  }
};

/**
 * Calculate the additional cost from a fee rate on a baseCost.
 * @param {string | number} baseCost - initial baseCost used to generate a total cost with.
 * @param { number } feeRatePct - fee rate percent used to calculate the total cost.
 */
export const calculateFeeTotal = (baseCost: string | number, feeRatePct: string): string | null => {
  if (baseCost && feeRatePct) {
    try {
      // Fees are computed as inclusive of the baseCost:
      // fee = baseCost * (1 - 1/(1 + feeRate))
      const feeRate = Big(feeRatePct).div(100);
      const fee = Big(baseCost).times(Big(1).minus(Big(1).div(Big(1).plus(feeRate))));
      return fee.toFixed(2);
    } catch {
      return null;
    }
  }
};

/**
 * Select the fee rate percent corresponding with the USD purchase amount.
 * @param {Fee} fees - An array containing all the possible fee rates.
 * @param {string} purchaseAmountUSD - Bitcoin purchase amount in USD.
 */
export const getFeeRatePctFromFeesForUsdPurchaseAmount = (
  fees: Fee[],
  purchaseAmountUSD: string | number
): string | null => {
  try {
    const feesBelowPurchaseAmount = fees.filter(fee =>
      Big(fee.minPurchaseAmountUsd).lte(purchaseAmountUSD)
    );

    const selectedFee = feesBelowPurchaseAmount.reduce(function (prev, current) {
      return Big(prev.minPurchaseAmountUsd).gte(current.minPurchaseAmountUsd) ? prev : current;
    });

    return selectedFee.feeRatePct;
  } catch (e) {
    return null;
  }
};

export const getBuyBitcoinDefaultVaultFromVault = (vault = { uuid: null, name: null }) => {
  const defaultVault = vault.uuid ? { name: vault.name, id: vault.uuid } : null;
  return defaultVault;
};

/**
 *  Determine if an amount is in a max and min range
 * @param {string | number} amount - the amount to be checked for validity in a max min range.
 * @param {string | number} maxAmount - the maximum amount
 * @param {string | number} minAmount - the minimum amount
 * @returns {boolean}
 */
export const isValidMinMaxAmount = (
  amount: string | number,
  maxAmount: string | number,
  minAmount: string | number
) => {
  try {
    const isAmountLargerThanMax = Big(amount).gt(Big(maxAmount));
    const isAmountLessThanMin = Big(amount).lt(Big(minAmount));
    return !isAmountLargerThanMax && !isAmountLessThanMin;
  } catch {
    // Most likely a bigjs value was not valid therefore the enteredAmount is not valid
    // and we shall return false.
    return false;
  }
};

/**
 * Get the inverse currency between BTC and USD,
 * @param {string} currency - either USD or BTC
 * @returns USD if curreny is BTC, or returns BTC is currency is USD
 */
export const getInverseCurrency = (currency: Currency): Currency => {
  const inverseCurrency = currency === USD ? BTC : USD;
  return inverseCurrency;
};

/**
 * The traders returned from the backend are separated into two arrays and each trader object does not clearly
 * mark in a single object if a trader has tradingEnabled or not. This function takes each trader, adds an isTradingEnabled prop
 * and returns a single array of all traders.
 * @param traders
 * @returns { MutableTrader[] }
 */
export const convertAllowedForbiddenTradersToMutableTraders: (
  traders: Traders["traders"]
) => MutableTrader[] = traders => {
  const unSelectedTraders = traders["forbidden"].map(trader => {
    trader["isTradingEnabled"] = false;
    return trader as MutableTrader;
  });
  const selectedTraders = traders["allowed"].map(trader => {
    trader["isTradingEnabled"] = true;
    return trader as MutableTrader;
  });
  return [...selectedTraders, ...unSelectedTraders];
};

export const sellBitcoinNetworkFailureToastDescription = {
  persist: true,
  type: "error",
  title: "We were unable to process your request, please try again.",
  description: `If this error persists, please contact ${getConfig("email.help")}`,
  dismissable: true,
};

export const calculateSaleAmountInUSD = (bitcoinPrice: string, saleAmountBTC: string) => {
  try {
    const amountUsd = new Big(bitcoinPrice).times(saleAmountBTC).valueOf();
    return amountUsd;
  } catch (err) {
    return NaN;
  }
};
export const calculateFeeAmountInUSD = (saleAmountInUsd: string | number, feeRates: Fee[]) => {
  try {
    let currentFeeRatePct = getFeeRatePctFromFeesForUsdPurchaseAmount(feeRates, saleAmountInUsd);

    if (!currentFeeRatePct) {
      // if the currentFee was never set use the smallestFee as the currentFee.
      const smallestFeeRate = feeRates.reduce(function (prev, current) {
        return Big(prev.minPurchaseAmountUsd).lt(current.minPurchaseAmountUsd) ? prev : current;
      });
      currentFeeRatePct = smallestFeeRate.feeRatePct;
    }

    const feeTotal: string | null = calculateFeeTotal(saleAmountInUsd, currentFeeRatePct);
    return feeTotal || "";
  } catch (err) {
    return "";
  }
};

export const calculateAmountUSDToBeSentToClient = (
  saleAmountInUsd: string | number,
  feeAmountInUsd: string | number
) => {
  try {
    const amountUSDToBeSentToClient = new Big(saleAmountInUsd).minus(feeAmountInUsd).toString();
    return amountUSDToBeSentToClient;
  } catch (err) {
    return "";
  }
};
