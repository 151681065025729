import React from "react";

import { Banner, Modal, ModalHeader, ModalTitle, ModalContent } from "@unchained/component-library";
import { get } from "lodash";

import { VideoWithScript } from "Components/Shared/Elements/VideoWithScript/VideoWithScript";
import { createVerificationScript } from "Components/Transactions/Spending/VerificationStep";
import { useLocationGetSpendInfo, useGetTransaction } from "Shared/api/hooks";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { stateIs } from "Utils/states";

type SpendDetails = {
  spendType: string;
  productUuid: string;
  operationUuid: string;
  productType: string;
};
export const SpendVerificationVideo = ({
  title,
  onClose = AppModalManager.close,
  spendDetails,
}: {
  title: string;
  onClose?: () => void;
  spendDetails?: SpendDetails;
}) => {
  const {
    spendType: spendTypeLocation,
    productUuid: productUuidLocation,
    operationUuid: operationUuidLocation,
    productType: productTypeLocation,
  } = useLocationGetSpendInfo();
  const spendType = spendDetails?.spendType || spendTypeLocation;
  const productUuid = spendDetails?.productUuid || productUuidLocation;
  const operationUuid = spendDetails?.operationUuid || operationUuidLocation;
  const productType = spendDetails?.productType || productTypeLocation;

  const { data: transaction, error } = useGetTransaction(productUuid, operationUuid, spendType);

  const verification = transaction?.operation?.verification;
  const isProcessing = stateIs(verification, "pending_processing");
  const isProcessedOrSigned = stateIs(verification, "processed", "signed");
  const video = verification?.download_urls?.video;
  const type = verification?.mime_types?.video;
  const showRejection =
    stateIs(verification, "pending_recording") && Boolean(verification.rejected_reason);
  const isApproved = stateIs(verification, "signed");

  let content = null;

  if (isProcessing) {
    content = <p>Your verification video is currently being processed.</p>;
  } else if (isProcessedOrSigned && transaction?.operation) {
    const script = createVerificationScript(
      transaction.operation,
      "BTC",
      productType,
      productUuid,
      get(transaction, "operation.btc_transaction_requests[0]")
    );
    content = (
      <div style={{ gap: "1rem", display: "flex", flexDirection: "column" }}>
        {Boolean(video) && Boolean(type) && <VideoWithScript videoSrcUrl={video} script={script} />}

        {error && (
          <Banner type="error" title="Error loading verification video" style={{ width: "100%" }}>
            {error?.toString()}
          </Banner>
        )}

        {showRejection && (
          <Banner type="error" title="Rejected for the following reason" style={{ width: "100%" }}>
            {verification.rejected_reason}
          </Banner>
        )}

        {isApproved && (
          <Banner
            type="info"
            title="Verification approved"
            style={{ width: "100%", backgroundColor: "#ebffe5", borderColor: "#3a8f21" }}
          >
            The verification video has been approved.
          </Banner>
        )}
      </div>
    );
  }

  return (
    <Modal onDismiss={onClose} className="max-w-[44rem]">
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
    </Modal>
  );
};
