import PropTypes from "prop-types";

import { humanState } from "Utils/states";
import { formatCurrency, formatPercentage } from "Utils/strings";
import { parseTimestamp, readableDate, readableTimeCentral } from "Utils/time";

export function marginCallProptypes() {
  return {
    state: PropTypes.string.isRequired,
    collateral_increase: PropTypes.number,
    principal_decrease: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    due_at: PropTypes.string,
    price: PropTypes.number,
    completed_at: PropTypes.string.isRequired,
    current: PropTypes.bool.isRequired,
    target_collateral: PropTypes.number.isRequired,
    needs_collateral_deposit: PropTypes.bool.isRequired,
    is_margin_call: PropTypes.bool.isRequired,
    partial_outflow_type: PropTypes.string,
    partial_outflow_uuid: PropTypes.string,

    createdAt: PropTypes.object.isRequired,
    updatedAt: PropTypes.object.isRequired,
    readableCreatedAt: PropTypes.string.isRequired,
    readableUpdatedAt: PropTypes.string.isRequired,
    dueAt: PropTypes.object.isRequired,
    readableDueAt: PropTypes.string.isRequired,
    humanState: PropTypes.string.isRequired,
    priceWithUnit: PropTypes.string,
    ctpRatio: PropTypes.number,
    ctpPercentage: PropTypes.string,

    collateralIncreaseWithUnit: PropTypes.number,
    principalDecreaseWithUnit: PropTypes.number,

    totalCollateralIncrease: PropTypes.number,
    totalCollateralIncreaseWithUnit: PropTypes.string,
    currentCollateralIncrease: PropTypes.number,
    currentCollateralIncreaseWithUnit: PropTypes.string,
  };
}

export function MarginCall(data, loan) {
  if (data == null) {
    return data;
  }

  data.humanState = humanState(data);

  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTimeCentral(data.createdAt);
  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTimeCentral(data.updatedAt);
  data.completedAt = parseTimestamp(data.completed_at);
  data.readableCompletedAt = readableTimeCentral(data.completedAt);

  if (data.due_at) {
    data.dueAt = parseTimestamp(data.due_at);
    data.readableDueOn = readableDate(data.dueAt);
    data.readableDueAt = readableTimeCentral(data.dueAt);
  }

  if (data.price) {
    data.priceWithUnit = `$${formatCurrency(data.price, 2)}`;
  }

  if (data.collateral_increase != null) {
    data.ctpRatio = ((loan.collateral + data.collateral_increase) * data.price) / loan.principal;
    data.collateralIncreaseCopyable = formatCurrency(data.collateral_increase, 8);
    data.collateralIncreaseWithUnit = `${formatCurrency(data.collateral_increase, 8)} ${loan.unit}`;
    data.totalCollateralIncrease = data.collateral_increase;
    data.totalCollateralIncreaseWithUnit = `${formatCurrency(data.totalCollateralIncrease, 8)} ${
      loan.unit
    }`;
    if (loan.wallet.deposited_balance != null && data.target_collateral) {
      data.currentCollateralIncrease = data.target_collateral - loan.current_wallet.balance;
      data.currentCollateralIncreaseWithUnit = `${formatCurrency(
        data.currentCollateralIncrease,
        8
      )} ${loan.unit}`;
    }
  }

  if (data.principal_decrease != null) {
    data.principalDecreaseWithUnit = `$${formatCurrency(data.principal_decrease, 2)}`;
    data.ctpRatio = (loan.collateral * data.price) / (loan.principal + data.principal_decrease);
  }

  if (data.ctpRatio) {
    data.ctpPercentage = formatPercentage(data.ctpRatio);
  }

  return data;
}
