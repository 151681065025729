import React, { useEffect, useState } from "react";

import { Table, TableProps } from "@unchained/component-library";

import { BatchDropzone } from "Components/VaultsView/Show/WithdrawModal/BatchDropzone";
import { BatchSpendEntryType } from "Components/VaultsView/Show/WithdrawModal/types";
import { usePagination } from "Utils/hooks";

import { AddressCell } from "./AddressCell";
import { AmountCell } from "./AmountCell";
import { BatchValidationMessage } from "./BatchValidationMessage";
import { SummaryLine } from "./SummaryLine";

const columns: TableProps<BatchSpendEntryType>["columns"] = [
  {
    accessorKey: "address",
    header: "ADDRESS",
    enableSorting: true,
    Cell: AddressCell,
  },
  {
    accessorKey: "amount",
    header: "AMOUNT",
    enableSorting: true,
    Cell: AmountCell,
    muiTableHeadCellProps: {
      align: "right",
    },
    muiTableBodyCellProps: {
      align: "right",
    },
  },
];

export const BatchSpendReviewTable = ({
  data,
  withSummary = false,
  validateData = false,
  onValidate = () => {},
}: {
  data: BatchSpendEntryType[];
  withSummary?: boolean;
  validateData?: boolean;
  onValidate?: (isValid: boolean) => void;
}) => {
  // Assume it's invalid first
  const [batchInvalid, setBatchInvalid] = useState(true);
  const [pagination, setPagination] = usePagination(0, 10);

  useEffect(() => {
    if (validateData && batchInvalid) {
      setPagination({ pageIndex: 0, pageSize: 5 });
      onValidate(false);
    } else if (validateData) {
      setPagination({ pageIndex: 0, pageSize: 10 });
      onValidate(true);
    }
  }, [validateData, batchInvalid, setPagination, onValidate]);

  // Greater than the error page size and error, or greater than 10
  const displayPagination = (data.length > 5 && batchInvalid) || data.length > 10;

  let props: TableProps<BatchSpendEntryType> = {
    data,
    columns,
    enableSorting: true,
    muiTableBodyCellProps: { className: "!h-12 !pr-0" },
    muiTableHeadCellProps: { className: "!pb-1 !pr-2" },
  };

  if (displayPagination) {
    props.state = { pagination };
    props.onPaginationChange = setPagination;
  }

  return (
    <>
      {withSummary && <SummaryLine batchData={data} batchInvalid={validateData && batchInvalid} />}
      {validateData && <BatchValidationMessage data={data} setBatchInvalid={setBatchInvalid} />}
      <br />
      <Table {...props} />
      {!displayPagination && <br />}
      {validateData && batchInvalid && <BatchDropzone />}
    </>
  );
};
