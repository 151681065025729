import { camelCase, startCase } from "lodash";

export const bitcoinSourceTypes = new Map([
  ["NOT_APPLICABLE", { key: "NOT_APPLICABLE", label: "Not applicable – I am buying bitcoin" }],
  ["PURCHASED", { key: "PURCHASED", label: "Purchased" }],
  ["MINING", { key: "MINING", label: "Mining" }],
  ["BUSINESS_OWNERSHIP", { key: "BUSINESS_OWNERSHIP", label: "Business Ownership" }],
  ["EMPLOYMENT", { key: "EMPLOYMENT", label: "Employment" }],
  ["INHERITANCE", { key: "INHERITANCE", label: "Inheritance" }],
  ["EARLY_ADOPTER", { key: "EARLY_ADOPTER", label: "Early Adopter" }],
  ["OTHER", { key: "OTHER", label: "Other" }],
]);

export const displayBitcoinSource = source => {
  const sourceType = bitcoinSourceTypes.get(source);
  if (sourceType) {
    return sourceType.label;
  }
  return startCase(camelCase(source));
};

export const displayBitcoinSources = sources => {
  const labels = [];
  bitcoinSourceTypes.forEach(sourceType => {
    if (sources.includes(sourceType.key)) {
      labels.push(displayBitcoinSource(sourceType.key));
    }
  });
  return labels.join(", ");
};

export const getBitcoinSourceKeys = () => {
  return [...bitcoinSourceTypes.keys()];
};

export const btcOperationTypes = [
  "discovered_bitcoin_transaction",
  "external_vault_deposit",
  "external_loan_deposit",
  "vault_transaction",
  "vault_sweep",
  "loan_redemption",
  "loan_liquidation",
  "loan_sweep",
  "loan_closing",
  "client_buy_bitcoin",
  "vault_sale_transaction",
  "batch_settlement",
].reduce((enumObject, value) => {
  enumObject[value] = value;
  return enumObject;
}, {});
