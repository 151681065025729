import React, { createContext, useMemo, useReducer } from "react";

import {
  IWithdrawModalContext,
  IWithdrawModalState,
} from "Components/VaultsView/Show/WithdrawModal/types";

const stateReducer = (
  oldState: IWithdrawModalState,
  newState: Partial<IWithdrawModalState>
): IWithdrawModalState => {
  return {
    ...oldState,
    nextDisabled: true, // Always disable on change. Step validation will reenable
    ...newState,
  };
};

export const defaultContextState: IWithdrawModalState = {
  amount: "",
  batchData: [],
  batchHasError: false,
  feeRate: "",
  isBatchSpendFlow: false,
  isTransferFlow: false,
  isMaxSpend: false,
  maxSpendLocked: false,
  nextDisabled: true,
  singleDestinationAddress: "",
  signingKeys: [],
  submittingTransaction: false,
  submissionErrorMessage: "",
  step: 0,
  transferDestination: null,
};

export const WithdrawModalContext = createContext<IWithdrawModalContext>({
  ...defaultContextState,
  setState: () => {},
});

export const withContext = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  initialContextState = defaultContextState
) => {
  const ComponentWithContext = (props: P) => {
    const [state, setState] = useReducer(stateReducer, initialContextState);
    const context = useMemo<IWithdrawModalContext>(
      () => ({
        ...state,
        setState,
      }),
      [state]
    );

    return (
      <WithdrawModalContext.Provider value={context}>
        <WrappedComponent {...props} />
      </WithdrawModalContext.Provider>
    );
  };

  return ComponentWithContext;
};
