import React from "react";

import Big from "big.js";
import cx from "classnames";

import { fiatWithUnit } from "Utils/currency";

import styles from "./AvailableCreditTooltip.module.scss";

interface AvailableCreditDataProps {
  label: string;
  amount: string;
  bold?: boolean;
  amountDirectionPrefix?: "-" | "+";
}

const AvailableCreditData = ({
  label,
  amount,
  bold = false,
  amountDirectionPrefix,
}: AvailableCreditDataProps) => {
  const formattedAmount = fiatWithUnit(parseFloat(amount));
  const negative = amountDirectionPrefix === "-";

  return (
    <div className={cx(styles.data, { [styles.data_bold]: bold })}>
      <span>{label}:</span>
      <span className={cx({ [styles.datum_negative]: negative })}>
        {amountDirectionPrefix && amountDirectionPrefix + " "}
        {formattedAmount}
      </span>
    </div>
  );
};

interface AvailableCreditTooltipProps {
  limit: string;
  cashBalance: string;
  maxPurchase: string;
}

export const AvailableCreditTooltip = ({
  limit,
  cashBalance,
  maxPurchase,
}: AvailableCreditTooltipProps) => {
  return (
    <div className={styles.container}>
      <AvailableCreditData label="Cash balance" amount={cashBalance} />
      <AvailableCreditData label="Post-fund limit" amount={limit} amountDirectionPrefix={"+"} />
      <AvailableCreditData label="Maximum purchase" amount={maxPurchase} bold />
    </div>
  );
};
