import { API_V2 } from "Shared/api/api";
import { GetCurrentWireInstructions200 } from "Specs/v2";

export class WireInstructionsAPI {
  static async GetWireInstructions(orgUuid: string) {
    const response = await API_V2.Get<GetCurrentWireInstructions200>(
      `/org/${orgUuid}/wire-instructions`
    );
    return response.data;
  }
}
