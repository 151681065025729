import React, { useEffect, useState } from "react";

import { LedgerRegisterWalletPolicy, MultisigWalletConfig } from "@caravan/wallets";
import { ModalContent, ModalFooter } from "@unchained/component-library";

import { InstructionList } from "Components/Shared/Elements/InstructionList";
import { handleDeviceError } from "Components/SigningDevices/DeviceErrorHandling";
import { useGetWalletConfig } from "Shared/api";
import { useErrorToast, ErrorToastOptions } from "Utils/toasts";

const steps = [
  { title: "Plug in and unlock your Ledger", subtitle: "Enter the PIN code for that device." },
  { title: "Open the Bitcoin app", subtitle: `Ensure the screen says "Bitcoin is ready".` },
  {
    title: "Select “Next” to continue",
    subtitle: "Your browser will attempt to connect.",
  },
];

interface LedgerConnectionOverviewModalStepProps {
  walletUuid: string;
  handleNext: () => void;
}

export const LedgerConnectionOverviewModalStep = ({
  walletUuid,
  handleNext,
}: LedgerConnectionOverviewModalStepProps) => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const walletConfigQuery = useGetWalletConfig(walletUuid);
  const showErrorToast = useErrorToast();

  // enable next after a 3 second timeout
  useEffect(() => {
    setTimeout(() => setNextDisabled(false), 3000);
  }, []);

  const handleClick = async () => {
    try {
      const interaction = new LedgerRegisterWalletPolicy(
        walletConfigQuery.data as MultisigWalletConfig
      );
      // first check that the ledger is connected and is the right version
      const isAppSupported = await interaction.isAppSupported();
      if (!isAppSupported) {
        showErrorToast(
          {},
          {
            title: "Unsupported Ledger app version",
            description:
              "Please update your Ledger Bitcoin App to the latest version and try again.",
          }
        );
        return;
      }
      handleNext();
    } catch (e) {
      let error: ErrorToastOptions = { persist: false };
      if (e.message.includes("No device selected")) {
        error.title = "Unable to find connected device.";
        error.description = "Make sure your Ledger is plugged in and unlocked and try again.";
      } else {
        const cleanMessage = handleDeviceError(e);
        error.title = "Something went wrong.";
        error.description = cleanMessage;
      }

      showErrorToast(e, error);
    }
  };

  return (
    <>
      <ModalContent>
        <InstructionList steps={steps} />
      </ModalContent>
      <ModalFooter actions={[{ children: "Next", onClick: handleClick, disabled: nextDisabled }]} />
    </>
  );
};
