import React from "react";

import { P2SH, generateMultisigFromHex } from "@caravan/bitcoin";
import PropTypes from "prop-types";

import { getConfig } from "Utils/config";
import { humanState } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";
import { bitcoinNetwork } from "Utils/wallet";

import { Signature } from "./signatures";
import { SliceMember } from "./slice_members";

export function spendProptypes() {
  return {
    state: PropTypes.string.isRequired,
    fee: PropTypes.number,
    unsigned_data: PropTypes.string.isRequired,
    signatures: PropTypes.array.isRequired,
    txid: PropTypes.string,
    amount: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    broadcasted_at: PropTypes.string,
    confirmed_at: PropTypes.string,
    unit: PropTypes.string.isRequired,
    signable: PropTypes.bool.isRequired,
    slice_members: PropTypes.array.isRequired,

    needsSignature: PropTypes.bool.isRequired,
    unsignedDataHardwareWallet: PropTypes.func,
    unsignedDataOfflineWallet: PropTypes.func,

    humanState: PropTypes.string.isRequired,

    fees_per_byte: PropTypes.number,
    fee_limit: PropTypes.number,
    parsedUnsignedData: PropTypes.object,
    feesPerByteUnit: PropTypes.string,
    redeem_script: PropTypes.string,
    psbt: PropTypes.string,

    createdAt: PropTypes.object.isRequired,
    updatedAt: PropTypes.object.isRequired,
    readableCreatedAt: PropTypes.string.isRequired,
    readableUpdatedAt: PropTypes.string.isRequired,

    amountAtPrice: PropTypes.func.isRequired,
    feeAtPrice: PropTypes.func.isRequired,

    amountAtPriceWithUnit: PropTypes.func.isRequired,
    feeAtPriceWithUnit: PropTypes.func.isRequired,
  };
}

function SpendBase(data) {
  if (data == null) {
    return data;
  }

  data.humanState = humanState(data);

  data.amountWithUnit = data.amount ? `${formatCurrency(data.amount, 8)} ${data.unit}` : null;
  data.feeWithUnit = data.fee ? `${formatCurrency(data.fee, 8)} ${data.unit}` : null;

  data.amountAtPrice = price => data.amount * price.value;
  data.feeAtPrice = price => (data.fee ? data.fee * price.value : null);

  data.amountAtPriceWithUnit = price =>
    `${formatCurrency(data.amountAtPrice(price), 2)} ${price.unit}`;
  data.feeAtPriceWithUnit = price =>
    data.fee ? `${formatCurrency(data.feeAtPrice(price), 2)} ${price.unit}` : null;

  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTime(data.updatedAt);

  if (data.broadcasted_at) {
    data.broadcastedAt = parseTimestamp(data.broadcasted_at);
    data.readableBroadcastedAt = readableTime(data.broadcastedAt);
  }

  if (data.confirmed_at) {
    data.confirmedAt = parseTimestamp(data.confirmed_at);
    data.readableConfirmedAt = readableTime(data.confirmedAt);
  }

  if (data.slice_members) {
    data.slice_members = data.slice_members.map(slice_member => SliceMember(slice_member));
    data.signable = data.slice_members.find(
      slice_member => slice_member.allowed_actions.includes("create_signature") != null
    );
    data.needsSignature = data.slice_members.find(slice_member => !slice_member.signature) != null;
  } else {
    data.signable = false;
    data.needsSignature = false;
  }

  return data;
}

function BitcoinSpend(data) {
  if (data == null) {
    return data;
  }
  data = SpendBase(data, "BTC");
  data.fee_limit = getConfig("wallet").fees.BTC.max;
  data.signatures = (data.signatures || []).map(signature => Signature(signature));
  data.feesPerByteWithUnit = `${data.fees_per_byte} sats/vByte`;

  try {
    data.parsedUnsignedData = JSON.parse(data.unsigned_data);
  } catch (e) {
    data.parsedUnsignedData = {
      error: "Unable to parse transaction.",
      inputs: [],
      outputs: [],
      refTx: [],
    };
  }

  data.unsignedData = () => {
    const multisig = generateMultisigFromHex(bitcoinNetwork(), P2SH, data.redeem_script);
    return {
      inputs: data.parsedUnsignedData.inputs.map((input, inputIndex) => ({
        index: input.prev_index,
        txid: input.prev_hash,
        multisig,
        transactionHex: data.parsedUnsignedData.rawRefTx[inputIndex],
        amountSats: input.amount,
      })),
      outputs: data.parsedUnsignedData.outputs.map(output => ({
        address: output.address,
        amountSats: output.amount,
      })),
    };
  };

  return data;
}

export function Spend(data) {
  if (data == null) {
    return data;
  }
  if (data.unit === "BTC") {
    return BitcoinSpend(data);
  }
}
