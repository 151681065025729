import { EventEmitter } from "events";

export const OPEN_SLIDEOUT = "openSlideout";
export const CLOSE_SLIDEOUT = "closeSlideout";

class AppSlideoutManagerSingleton extends EventEmitter {
  watch = this.on;

  open = (component, isClearQueryParams = true) => {
    this.emit(OPEN_SLIDEOUT, () => component, isClearQueryParams);
  };

  close = () => {
    this.emit(CLOSE_SLIDEOUT);
  };
}
export const AppSlideoutManager = new AppSlideoutManagerSingleton();
