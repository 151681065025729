import React from "react";

import { Link } from "Components/Link";

import { useIsOperationSuspicious } from "./useIsOperationSuspicious";

export const SuspiciousOperationBanner = ({ operation, children }) => {
  const isSuspicious = useIsOperationSuspicious(operation);

  return (
    <div className="relative">
      {isSuspicious && (
        <div className="absolute left-0 right-0 top-0 z-10 flex items-center justify-between gap-x-4 rounded-t-lg bg-red-700 px-3 py-3 text-white">
          <p className="font-normal pl-2 text-sm leading-5 text-white">
            This transaction appears suspicious.
          </p>
          <div className="pr-2 text-white">
            Report suspicious activity to{" "}
            <Link to="mailto:abuse@unchained.com" className="font-bold text-white hover:text-white">
              abuse@unchained.com
            </Link>
            .
          </div>
        </div>
      )}

      {isSuspicious
        ? React.cloneElement(children, {
            className: `${children?.props?.className || ""} pt-14 relative`,
          })
        : children}
    </div>
  );
};
