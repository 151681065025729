import { ROLE_OPTIONS, tradingManagedRoles } from "Routes/manage/utils";
import { OrgUserRoleChangeProposal } from "Shared/api/changeProposalApi";
import { ChildRole, CompleteOrg } from "Specs/v1/getOrg/200";

import { TwoBoxChange } from "./utils";

export default function OrgUserRoleChange({
  changeProposal,
  childRoles,
  org,
}: {
  changeProposal: OrgUserRoleChangeProposal;
  childRoles: ChildRole[];
  org: CompleteOrg;
}) {
  const user =
    changeProposal.user ||
    org.accepted_members.find(m => m.user.uuid === changeProposal.userUuid)?.user;

  if (!changeProposal.roles && changeProposal.roleUuids) {
    changeProposal.roles = changeProposal.roleUuids.map(uuid => {
      const { name, managed } = childRoles.find(r => r.uuid === uuid);
      return { uuid, name, managed };
    });
  }

  const currentRole = ROLE_OPTIONS.find(option =>
    changeProposal.currentRoles.find(role => role.name === option.plural)
  ).label;

  let currentRoleDisplay = `Role: ${currentRole}`;
  const targetRole = ROLE_OPTIONS.find(option =>
    changeProposal.roles.find(role => role.name === option.plural)
  ).label;
  const roleChange = targetRole !== currentRole;

  let tradingChange: boolean;
  let targetTrading: boolean;

  const curentTrading = changeProposal.currentRoles.some(role =>
    tradingManagedRoles.includes(role.managed)
  );

  currentRoleDisplay += ` | Trading: ${curentTrading ? "Yes" : "No"}`;
  targetTrading = changeProposal.roles.some(role => tradingManagedRoles.includes(role.managed));
  tradingChange = targetTrading !== curentTrading;

  let targetRoleDisplay = (
    <>
      Role: <span className={roleChange ? "font-semi text-gray-800" : undefined}>{targetRole}</span>
      <>
        {" | Trading: "}
        <span className={tradingChange ? "font-semi text-gray-800" : undefined}>
          {targetTrading ? "Yes" : "No"}
        </span>
      </>
    </>
  );

  return (
    <div>
      <h3 className="mb-2">Member role change</h3>

      <TwoBoxChange
        current={{ title: user?.email, caption: currentRoleDisplay }}
        target={{ title: user?.email, caption: targetRoleDisplay }}
      />
    </div>
  );
}
