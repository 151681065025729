import { useContext, useEffect, useState } from "react";

import { WizardStepperContext } from "@unchained/component-library";
import { UseMutationResult, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useLoadingContext } from "Contexts/LoadingContext";
import { OrgAPI, orgQueryKeys } from "Shared/api";
import { useEasyToasts } from "Utils/toasts";

type MutationOptions = {
  onSuccess?: () => void;
  onError?: (err: unknown) => void;
};

/** For essentially each step in the onboarding manifests, data is mutated,
 * and upon mutation, the relevant org is refetched. We want to react only when the refetch is complete,
 * and the manifest has recalculated based on that data.
 *
 * This hook handles that, returning a "wrapper" for the mutation, and then "watching" the relevant query
 * for a complete refetch. Only when the refetch is complete does it then call the onRefetchComplete function,
 * which, by default, proceeds to the next step in the wizard. While the mutation and refetch are in progress,
 * it displays a loading state.
 *
 * @example
 *
 * const updatePersonInfo = wrapManifestMutation(
 *  useMutation((values: typeof initVals) =>
 *    // IRA-constructed API expects camel-cased keys
 *    PersonAPI.Patch(person.uuid, camelCaseKeys(values))
 *  )
 * );
 *
 * // Handles onSuccess and onError, forwarding on completion
 * updatePersonInfo.mutate(values);
 */
export const useWrapManifestMutation = ({
  orgUuid,
  isIra,
}: {
  orgUuid?: string;
  isIra?: boolean;
} = {}) => {
  const { uuid: paramUuid } = useParams<{ uuid: string }>();
  const uuid = orgUuid || paramUuid;

  const loading = useLoadingContext();
  const { showErrorToast } = useEasyToasts();
  const [readyToProceed, setReadyToProceed] = useState(false);
  const { goToFirstReadyStep } = useContext(WizardStepperContext);

  const queryClient = useQueryClient();
  const apiFunc = isIra ? OrgAPI.GetIraOrg : OrgAPI.Get;
  const queryKey = isIra ? orgQueryKeys.showIra(uuid) : orgQueryKeys.show(uuid);

  useEffect(() => {
    if (readyToProceed) goToFirstReadyStep();
  }, [readyToProceed, goToFirstReadyStep]);

  /** Wraps a mutation with a watcher that refetches the org query
   * and detects when it has been refreshed.*/
  return <T>(mutation: UseMutationResult<unknown, unknown, T>) => ({
    mutate: (values: T, options: MutationOptions = {}) => {
      loading.set(true);

      mutation.mutate(values, {
        ...options,
        onSuccess: async resp => {
          if (options.onSuccess) return options.onSuccess();

          try {
            const data = await apiFunc(uuid);
            await queryClient.setQueryData(queryKey, data);
            setReadyToProceed(true);
          } catch (err) {
            console.log(err);
          } finally {
            setTimeout(() => loading.set(false), 50);
          }
        },
        onError: options?.onError || (err => loading.set(false).then(() => showErrorToast(err))),
      });
    },
  });
};
