import { SKUS } from "Shared/api/v2";
import { COMPLETE_ORG_BUSINESS_TIER } from "Specs/v1/getAccount/200";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg, COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { SubscriptionItem } from "Specs/v2/components";
import { camelCaseKeys } from "Utils/objects";

import { bizTiers } from "../(business)/steps/CreateYourAccount/02BusinessTier";
import { trustUserOptions, TrustUserOptionValue } from "../(trust)/payment";

const { BUSINESS, INDIVIDUAL, IRA, TRUST } = COMPLETE_ORG_ACCOUNT_TYPE;

type ConciergePaymentInfo = Record<
  "concierge" | "noConcierge",
  { copy: string; note?: string; priceDisplayPrefix?: string }
>;

const bizOrgConciergePaymentInfo = (
  userCount: 1 | 2
): Record<"pro" | "basic", ConciergePaymentInfo> =>
  [
    { tier: "basic", price: [750, 1000][userCount - 1] },
    { tier: "pro", price: 2500 },
  ].reduce((acc, { tier, price }) => {
    acc[tier] = {
      concierge: {
        price: price,
        copy: "Build your vault with the guidance and expertise of one of our onboarding experts.",
      },
      noConcierge: {
        price: 0,
        copy: "Set up your vault by yourself. Choose this option if you have two supported devices and are comfortable using them.",
      },
    };
    return acc;
  }, {} as Record<"pro" | "basic", ConciergePaymentInfo>);

const iraOrgConciergePaymentInfo: ConciergePaymentInfo = {
  concierge: {
    copy: "Sign up for a one-hour video call with one of our bitcoin experts and we'll guide you safely through your IRA vault setup. Inheritance package included.",
  },
  noConcierge: {
    copy: "Want to build your IRA vault on your own? Choose this option.",
  },
};

const individualOrgConciergePaymentInfo: ConciergePaymentInfo = {
  concierge: {
    priceDisplayPrefix: "+",
    copy: "Sign up for a one-hour video call with one of our bitcoin experts and we'll guide you safely through vault setup. Inheritance Protocol included.",
  },
  noConcierge: {
    priceDisplayPrefix: "+",
    copy: "Use two supported hardware wallets to build a personal vault on your own.",
  },
};

const trustOrgConciergePaymentInfo: ConciergePaymentInfo = {
  concierge: {
    copy: "Sign up for a one-hour video call with one of our bitcoin experts and we'll guide you safely through vault setup. Price varies by number of users.",
    note: "starting price",
  },
  noConcierge: {
    copy: "Set up your vault by yourself. Choose this option if you have two supported devices and are comfortable using them. ",
  },
};
const normalizeOrg = (org: CompleteOrg | IraOrg) => {
  const camelCased = camelCaseKeys(org);
  return {
    ...camelCased,
    businessTier: "businessTier" in camelCased ? camelCased.businessTier : undefined,
    accountType: camelCased.accountType || IRA,
  };
};

export const getConciergePaymentOptions = (org: CompleteOrg | IraOrg, userCount?: 1 | 2 | 3) => {
  const { accountType, businessTier } = normalizeOrg(org);

  const object = {
    [BUSINESS]: bizOrgConciergePaymentInfo(userCount as 1 | 2)[businessTier],
    [INDIVIDUAL]: individualOrgConciergePaymentInfo,
    [IRA]: iraOrgConciergePaymentInfo,
    [TRUST]: trustOrgConciergePaymentInfo,
  }[accountType] as ConciergePaymentInfo;

  return object;
};

export const paymentComplete = (org: CompleteOrg | IraOrg) =>
  org["account_type"] === "individual"
    ? org.state === "live"
    : !["pending_activation", "pending_payment"].includes(org.state);

export const mapOrgAndUsersToConciergeSKU = (
  org: CompleteOrg | IraOrg,
  userCount: 1 | 2 | TrustUserOptionValue
): string => {
  if ("iraPlan" in org) {
    return SKUS.CONCIERGE_ONBOARDING_IRA;
  }

  switch (org.account_type) {
    case "trust":
      switch (userCount) {
        case 2:
          return SKUS.CONCIERGE_ONBOARDING_TRUST_TWO;
        case 3:
          return SKUS.CONCIERGE_ONBOARDING_TRUST_THREE;
        default:
          return SKUS.CONCIERGE_ONBOARDING_TRUST_ONE;
      }
    case "business":
      if (org.business_tier === "pro") return SKUS.CONCIERGE_ONBOARDING_BUS_THREE;
      switch (userCount) {
        case 2:
          return SKUS.CONCIERGE_ONBOARDING_BUS_TWO;
        default:
          return SKUS.CONCIERGE_ONBOARDING_BUS_ONE;
      }
    default:
      return SKUS.CONCIERGE_ONBOARDING_PERSONAL;
  }
};

const getIndividualSubscriptionItems = (org: CompleteOrg) => {
  const addInvoiceItems = [];

  if (org.concierge_onboarding) {
    addInvoiceItems.push({ sku: SKUS.CONCIERGE_ONBOARDING_PERSONAL, quantity: 1 });
    if (org.devices_requested) {
      addInvoiceItems.push({ sku: SKUS.TREZOR_SAFE_THREE, quantity: org.devices_requested });
    }
  }

  return [[{ sku: SKUS.INDIVIDUAL_ACCOUNT, quantity: 1 }], addInvoiceItems];
};

const getTrustSubscriptionItems = (org: CompleteOrg, userCount: TrustUserOptionValue) => {
  const addInvoiceItems = [];

  if (org.concierge_onboarding) {
    const CO_SKU = trustUserOptions.find(o => o.value === userCount)?.sku;
    addInvoiceItems.push({ sku: CO_SKU, quantity: 1 });
    if (org.devices_requested) {
      addInvoiceItems.push({ sku: SKUS.TREZOR_SAFE_THREE, quantity: org.devices_requested });
    }
  }

  return [[{ sku: SKUS.TRUST_ACCOUNT, quantity: 1 }], addInvoiceItems];
};

const getBusinessSubscriptionItems = (org: CompleteOrg, userCount: 1 | 2) => {
  const addInvoiceItems = [];

  if (org.concierge_onboarding) {
    const CO_SKU = mapOrgAndUsersToConciergeSKU(org, userCount);
    addInvoiceItems.push({ sku: CO_SKU, quantity: 1 });
    if (org.devices_requested) {
      addInvoiceItems.push({ sku: SKUS.TREZOR_SAFE_THREE, quantity: org.devices_requested });
    }
  }

  let subscriptionSku = bizTiers.find(tier => tier.value === org.business_tier).sku;
  if (org.business_tier === "basic" && userCount === 2) {
    subscriptionSku = SKUS.BUSINESS_ACCOUNT_BASIC_TWO;
  }

  return [[{ sku: subscriptionSku, quantity: 1 }], addInvoiceItems];
};

const getIraLineItems = (org: IraOrg) => {
  const addInvoiceItems: { sku: string; quantity: number }[] = [];

  if (org.conciergeOnboarding) {
    addInvoiceItems.push({ sku: SKUS.CONCIERGE_ONBOARDING_IRA, quantity: 1 });
    if (org.devicesRequested) {
      addInvoiceItems.push({ sku: SKUS.TREZOR_SAFE_THREE, quantity: org.devicesRequested });
    }
  }

  return [[{ sku: SKUS.IRA_ACCOUNT, quantity: 1 }], addInvoiceItems];
};

export const getSubscriptionItems = (
  org: CompleteOrg | IraOrg,
  userCount?: 1 | 2 | TrustUserOptionValue
): [SubscriptionItem[], SubscriptionItem[]] => {
  const { accountType } = normalizeOrg(org);

  const [recurringItems, additionalInvoiceItems] = {
    [BUSINESS]: () => getBusinessSubscriptionItems(org as CompleteOrg, userCount as 1 | 2),
    [INDIVIDUAL]: () => getIndividualSubscriptionItems(org as CompleteOrg),
    [IRA]: () => getIraLineItems(org as IraOrg),
    [TRUST]: () => getTrustSubscriptionItems(org, userCount as TrustUserOptionValue),
  }[accountType]();

  return [recurringItems, additionalInvoiceItems];
};
