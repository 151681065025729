import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const TransactionIcon = (
  { viewBox = "0 0 24 24", htmlColor = "black", classes = {} },
  props
) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={classes}>
    <g>
      <path d="M9.8,7.6L5.4,2.7L1,7.6" />
      <path d="M4.4,7.6c0,3.9,0,6,0,6h2.1l0-6" />
      <path
        d="M9.9,17.8c-0.1,0-0.3,0-0.4,0c-0.8-0.1-1.4-0.5-2-1.1c-0.7-0.7-1.1-1.5-1.1-3.3H4.4c0,3,0.8,4.2,1.9,5.1
		c0.9,0.8,2.1,1.4,3.7,1.4c1.8,0-0.6,0,1.2,0v-2.1H9.9z"
      />
    </g>
    <g>
      <path d="M14.2,16.4l4.4,4.9l4.4-4.9" />
      <path d="M19.6,16.4c0-3.9,0-6,0-6h-2.1l0,6" />
      <path
        d="M14.1,6.2c0.1,0,0.3,0,0.4,0c0.8,0.1,1.4,0.5,2,1.1c0.7,0.7,1.1,1.5,1.1,3.3h2.1c0-3-0.8-4.2-1.9-5.1
		c-0.9-0.8-2.1-1.4-3.7-1.4c-1.8,0,0.6,0-1.2,0v2.1H14.1z"
      />
    </g>
  </SvgIcon>
);

TransactionIcon.propTypes = {
  classes: PropTypes.object,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
