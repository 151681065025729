import React from "react";

import PropTypes from "prop-types";

class FAIcon extends React.Component {
  className = () => {
    let theClassName = `${this.props.className || ""} ${this.props.prefix} fa-${this.props.name}`;
    if (this.props.spin) {
      theClassName += " fa-spin";
    }
    if (this.props.pulse) {
      theClassName += " fa-pulse";
    }
    if (this.props.size) {
      theClassName += ` fa-${this.props.size}`;
    }
    if (this.props.fixedWidth) {
      theClassName += " fa-fw";
    }
    if (this.props.replaceBullet) {
      theClassName += " fa-li";
    }
    if (this.props.border) {
      theClassName += " fa-border";
    }
    if (this.props.pullRight) {
      theClassName += " fa-pull-right";
    }
    if (this.props.pullLeft) {
      theClassName += " fa-pull-left";
    }
    return theClassName;
  };

  title = () => `${this.props.title || ""}`;

  render() {
    return this.title() === "" ? (
      <i className={this.className()} aria-hidden="true" />
    ) : (
      <i className={this.className()} aria-hidden="true" title={this.title()} />
    );
  }
}

FAIcon.defaultProps = {
  prefix: "fas",
};

FAIcon.propTypes = {
  prefix: PropTypes.string,
  name: PropTypes.string.isRequired,
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
  size: PropTypes.string,
  fixedWidth: PropTypes.bool,
  replaceBullet: PropTypes.bool,
  border: PropTypes.bool,
  pullRight: PropTypes.bool,
  pullLeft: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default FAIcon;
