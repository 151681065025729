import _, { orderBy } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "src/reducers";

import { VaultMap } from "Components/VaultsView/Create/types";
import { getVaultStatus } from "Models/get_vault_status";
import { Vault } from "Specs/v1/listOrgVaults/200";
import { cryptoToFiat, fiatWithUnit, shortCrypto, shortCryptoWithUnit } from "Utils/currency";

import { currentPriceSelector } from "../priceSelectors";

const orgVaultsSelector = (state: RootState) => state.vaults.vaultIndex.userVaults.vaults;
const orgUUIDSelector = (state: RootState) => state.account.orgs.current.uuid;

export type VaultWithAmounts = ReturnType<typeof vaultWithAmounts>;

export const vaultWithAmounts = (vault: Vault, btcPriceUsd: number) => {
  const { deposited_balance, unit, wallet_type, state } = vault;
  const btcAmount = shortCrypto(deposited_balance);
  const btcDisplayed = shortCryptoWithUnit(deposited_balance, unit);
  const fiatValue = cryptoToFiat(deposited_balance, unit, {
    BTC: { [unit as string]: btcPriceUsd },
  });
  const fiatDisplayed = fiatWithUnit(fiatValue);
  const custodyType = wallet_type === "multiorg" ? "Multi-institution" : "Client-controlled";
  const currentStatus = state ? getVaultStatus(state) : "";

  return {
    ...vault,
    currentStatus,
    custodyType,
    fiatValue,
    fiatDisplayed,
    cryptoAmount: btcAmount,
    cryptoDisplayed: btcDisplayed,
  };
};

export const vaultsListFromRawObject = (rawVaults: VaultMap, btcPriceUsd: number) =>
  Object.entries(rawVaults).map(([uuid, vault]) => vaultWithAmounts(vault, btcPriceUsd));

const vaultsList = createSelector(
  orgVaultsSelector,
  currentPriceSelector,
  (rawVaults: VaultMap, prices) =>
    vaultsListFromRawObject(rawVaults as VaultMap, parseFloat(prices.BTC.unit))
);

export const orgVaults = (rawVaults: Record<string, Vault>, orgUuid: string) => {
  const vaults = Object.values(rawVaults).reduce(
    (array, vault) => (vault.owner.uuid === orgUuid ? [...array, vault] : array),
    []
  );
  return orderBy(vaults, ["unit", "deposited_balance", "created_at"], ["asc", "desc", "desc"]);
};

export const getOwnVaults = createSelector(
  vaultsList,
  orgUUIDSelector,
  (vaults: VaultWithAmounts[], orgUUID: string) => {
    let ownVaults = vaults.filter(vault => vault.owner.uuid === orgUUID);
    return _.orderBy(
      ownVaults,
      ["unit", "deposited_balance", "created_at"],
      ["asc", "desc", "desc"]
    );
  }
);

export const getSharedVaults = createSelector(
  vaultsList,
  orgUUIDSelector,
  (vaults: VaultWithAmounts[], orgUUID: string) => {
    let sharedVaults = vaults.filter(vault => vault.owner.uuid !== orgUUID);
    return _.orderBy(
      sharedVaults,
      [v => v.owner.name, "deposited_balance", "created_at"],
      ["asc", "desc", "desc"]
    );
  }
);
