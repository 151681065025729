import { updatedField } from "Utils/errors";

var initialState = {
  broadcasted: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SPEND/SET/BROADCASTED":
      return updatedField(state, action, "broadcasted");
    default:
      return state;
  }
};
