import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./Wizard.scss";

export class Wizard extends React.Component {
  static propTypes = {
    manifest: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        component: PropTypes.any,
      })
    ),
    onFlowComplete: PropTypes.func,
    onBackComponent: PropTypes.func,
    onNextComponent: PropTypes.func,
    startIndex: PropTypes.number,
    childProps: PropTypes.any,
    onBackFromStart: PropTypes.func,
    shouldGoToNext: PropTypes.func,
  };

  static defaultProps = {
    shouldGoToNext: () => true,
  };

  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
  }

  componentDidMount() {
    if (this.props.startIndex) {
      this.setState({ currentIndex: this.props.startIndex });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.startIndex !== prevProps.startIndex) {
      this.setState({ currentIndex: this.props.startIndex });
    }
  }

  next = childData => {
    const { onNextComponent, shouldGoToNext } = this.props;
    const flowData = {
      nextIndex: this.state.currentIndex + 1,
      componentData: childData,
    };
    const test = shouldGoToNext(flowData);
    if (!test) return;
    // the Wizard knows through State where the user is through currentIndex.
    let nextIndex = flowData.nextIndex;
    if (onNextComponent) {
      const i = onNextComponent(flowData);
      if (i != null) {
        nextIndex = i;
      }
    }
    this.setState({ currentIndex: nextIndex });
  };

  back = childData => {
    const isFirstBack = this.detectFirstBack();
    const { onBackFromStart } = this.props;

    if (isFirstBack) {
      if (onBackFromStart) onBackFromStart();
    } else {
      const flowData = {
        nextIndex: this.state.currentIndex - 1,
        componentData: childData,
      };
      const { onBackComponent, onFlowComplete } = this.props;
      let nextIndex = flowData.nextIndex;

      if (onBackComponent) {
        const i = onBackComponent(flowData);
        if (i != null) {
          nextIndex = i;
        }
      }

      this.setState({ currentIndex: nextIndex }, () => {
        if (this.state.currentIndex < 0) {
          if (onFlowComplete) {
            onFlowComplete(this.state);
          }
        }
      });
    }
  };

  detectFirstBack = () => {
    const { currentIndex } = this.state;
    const beforeIndex = currentIndex - 1;
    return beforeIndex === -1;
  };

  renderComponent = () => {
    const { manifest, onFlowComplete, childProps } = this.props;

    const { currentIndex } = this.state;

    // Simple algorithm to find the manifest entry the wizard should render.
    const currentManifestEntry = manifest.find((entry, index) => index === currentIndex);

    // Final condition for the wizard - if the current entry is null or undefined, then we do not render
    // any other component and invoke the onDone event.
    if (!currentManifestEntry) {
      if (onFlowComplete) onFlowComplete();
      return;
    }

    // If there is an entry, render the component, and pass on data for the child components to do
    // their job.
    const { component, render } = currentManifestEntry;
    if (render) {
      return render({ next: this.next, back: this.back, currentIndex: this.state.currentIndex });
    }

    if (component) {
      const CurrentComponent = component;

      return (
        <CurrentComponent
          next={this.next}
          back={this.back}
          isWizard
          currentIndex={this.state.currentIndex}
          {...childProps}
        />
      );
    }
  };

  render() {
    return <Fragment>{this.renderComponent()}</Fragment>;
  }
}
