import { OrgQuorumApplyToAllTxsChangeProposal } from "Shared/api/changeProposalApi";

import { TwoBoxChange } from "./utils";

export function OrgQuorumApplyToAllTxsChange({
  changeProposal,
}: {
  changeProposal: OrgQuorumApplyToAllTxsChangeProposal;
}) {
  return (
    <div>
      <h3 className="mb-2">Require quorum approval on all transactions?</h3>

      <TwoBoxChange
        current={{ title: changeProposal.current ? "Yes" : "No", caption: "Current" }}
        target={{ title: changeProposal.enabled ? "Yes" : "No", caption: "Target" }}
      />
    </div>
  );
}
