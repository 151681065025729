import { WizardStep } from "@unchained/component-library";

import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import { AwaitApproval } from "Routes/onboard/(shared)";
import { CreateOrgSubscriptionAndRedirectToPayment } from "Routes/onboard/(shared)/CreateOrgSubscriptionAndRedirectToPayment";
import { ShippingAddressStep } from "Routes/onboard/(shared)/ShippingAddressStep";
import { hideSkippedSteps } from "Routes/onboard/(utils)/manifestUtils";
import { OnboardingGoal, simplifiedOrgStates } from "Routes/onboard/(utils)/simplifiedOrgStates";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import {
  ConciergeOnboarding,
  HardwareWallets,
  paymentComplete,
  PaymentConfirmation,
  SocialSecurityNumber,
} from "../(shared)";
import { OnboardIndividualOrgContextType } from "./OnboardIndividualOrg";
import {
  initEmployment,
  initIncome,
  initSsn,
  initWealth,
  validateEmployment,
  validateIncome,
  validateSsn,
  validateWealth,
} from "./individualOrgValsAndValidations";
import { EmploymentStatus, PersonalIncome, WealthDigitalAssets } from "./steps";
import { IndividualPaymentConfirmation } from "./steps/Checkout/IndividualPaymentConfirmation";
import { ReviewIndividual } from "./steps/FinishAccount/05ReviewIndividual";

const advancedProfileInfoSteps = (org: CompleteOrg, { from }: OnboardIndividualOrgContextType) => {
  const { editable, submitted, resubmittable } = simplifiedOrgStates(
    org,
    OnboardingGoal.INDIVIDUAL
  );
  const steps = [
    {
      title: "Social security number",
      complete: validateSsn.isValidSync(initSsn(org)),
      Component: () => <SocialSecurityNumber org={org} canSkipSsn={!Boolean(from?.length)} />,
      disabled: !editable,
    },
    {
      title: "Employment status",
      complete: validateEmployment.isValidSync(initEmployment(org)),
      disabled: !editable,
      Component: () => <EmploymentStatus org={org} />,
    },
    {
      title: "Personal income",
      complete: validateIncome.isValidSync(initIncome(org)),
      disabled: !editable,
      Component: () => <PersonalIncome org={org} />,
    },
    {
      title: "Wealth & digital assets",
      complete: validateWealth.isValidSync(initWealth(org)),
      disabled: !editable,
      Component: () => <WealthDigitalAssets org={org} />,
    },
  ] as WizardStep[];

  const readyToReview = steps.every(s => s.complete);

  steps.push({
    title: "Review info",
    complete: submitted,
    Component: () => <ReviewIndividual org={org} />,
    disabled: !readyToReview,
  });

  steps.push({
    title: "Awaiting approval",
    disabled: !submitted,
    Component: () => <AwaitApproval org={org} type={OnboardingGoal.INDIVIDUAL} />,
    excludeFromNavigation: true,
    fullPage: !resubmittable,
  });

  return steps;
};

const conciergeOnboardingSteps = (org: CompleteOrg, paid: boolean) => [
  {
    title: "Concierge Onboarding",
    complete: org.concierge_onboarding !== undefined || paid,
    disabled: paid,
    Component: () => (
      <ConciergeOnboarding
        org={org}
        title="Package & pricing"
        description="Personal accounts have an annual fee of $250/year. Choose your onboarding plan."
      />
    ),
  },
  {
    title: "Hardware wallets",
    complete: org.devices_requested !== undefined || paid,
    disabled: paid,
    Component: () => <HardwareWallets org={org} buttonText="Continue" />,
    skip: !org.concierge_onboarding,
  },
];

export const individualManifest = (
  org: CompleteOrg,
  context: OnboardIndividualOrgContextType
): WizardStep[] => {
  const paid = paymentComplete(org);
  const { from = "" } = context;

  const onboardingSteps = conciergeOnboardingSteps(org, paid);
  const onboardingStepsComplete = onboardingSteps.every(s => s.complete);
  if (paid && from) {
    return [
      {
        title: "Advanced profile info",
        AlternateIcon: () => <CloseButton to={from || "/home"} />,
        steps: advancedProfileInfoSteps(org, context),
      },
    ];
  }

  // Coming from dashboard, etc, not from basic onboarding
  const treatedAsModal = Boolean(from && from !== "/onboard");

  const { concierge_onboarding, devices_requested } = org;
  const hasNotChosenOnboarding =
    concierge_onboarding === undefined ||
    (concierge_onboarding === true && devices_requested === undefined);

  const isSignature = context?.user?.signature;

  const steps = hideSkippedSteps([
    { title: "Create your profile", complete: true, disabled: true, skip: paid || treatedAsModal },
    {
      title: "Hardware & onboarding options",
      complete: onboardingStepsComplete || paid,
      steps: onboardingSteps,
      disabled: paid || context.selectedPricing.value,
      skip: treatedAsModal,
    },
    {
      title: "Shipping",
      complete: context.shippingInfo.value !== undefined || paid,
      disabled: paid,
      skip: !org.concierge_onboarding,
      Component: () => <ShippingAddressStep org={org} shippingInfo={context.shippingInfo} />,
    },
    {
      title: "Checkout",
      complete: paid,
      disabled: hasNotChosenOnboarding,
      Component: () => (
        <CreateOrgSubscriptionAndRedirectToPayment
          org={org}
          setSelectedPricing={context.selectedPricing.set}
          userCount={1}
          shippingInfo={context.shippingInfo}
        />
      ),
    },
    {
      Component: () =>
        isSignature ? (
          <PaymentConfirmation isSignature={isSignature} />
        ) : (
          <IndividualPaymentConfirmation />
        ),
      title: "Payment confirmation",
      excludeFromNavigation: true,
      fullPage: true,
    },
  ]);

  if (treatedAsModal) {
    const firstVisibleStep = steps.find(s => !s.excludeFromNavigation);
    if (firstVisibleStep) {
      firstVisibleStep.AlternateIcon = () => <CloseButton to={from || "/home"} />;
    }
  }

  return steps;
};
