import React from "react";

import { useField } from "formik";
import moment from "moment";
import PropTypes from "prop-types";

import { DateTimeField } from "./DateTimeField";

export const FormikDateTimeField = ({ name, helperText, timezone, error = false, ...other }) => {
  const [, { value, error: fieldError, touched }, { setTouched, setValue }] = useField(name);

  const handleBlur = e => {
    setTouched(true);
  };

  const handleChange = dateTime => {
    let newTime;
    if (dateTime === null) {
      newTime = null;
    } else if (timezone) {
      newTime = moment(dateTime).tz(timezone);
    } else {
      newTime = moment(dateTime);
    }
    setValue(newTime);
  };

  const hasError = (fieldError || error) && touched;

  return (
    <DateTimeField
      name={name}
      value={value}
      onChange={handleChange}
      onClose={handleBlur}
      error={hasError}
      helperText={hasError ? fieldError : helperText}
      {...other}
    />
  );
};

FormikDateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  timezone: PropTypes.string,
  error: PropTypes.bool,
};
