import _, { orderBy } from "lodash";
import { createSelector } from "reselect";

import { LoanListing } from "Models/loan_listing";
import { Product } from "Utils/productUtils";

const orgLoansSelector = state =>
  state.loans.loanIndex.userLoans.loans.map(loan => LoanListing(loan));
const orgUUIDSelector = state => state.account.orgs.current.uuid;

export type Loan = Product & { borrower: { uuid: string } };

export const orgLoans = (rawLoans: Record<string, Loan>, orgUUID: string) => {
  const loans = Object.values(rawLoans).filter(loan => loan.borrower.uuid === orgUUID);
  return orderBy(loans, ["unit", "collateral", "created_at"], ["asc", "desc", "desc"]);
};

export const getOwnLoans = createSelector(orgLoansSelector, orgUUIDSelector, (loans, orgUUID) =>
  orgLoans(loans, orgUUID)
);

export const getSharedLoans = createSelector(
  orgLoansSelector,
  orgUUIDSelector,
  (loans, orgUUID) => {
    let sharedLoans = loans.filter(loans => loans.borrower.uuid !== orgUUID);
    return _.orderBy(
      sharedLoans,
      [l => l.borrower.name, "unit", "collateral", "created_at"],
      ["asc", "asc", "desc", "desc"]
    );
  }
);
