import { pick } from "lodash";

import {
  Address,
  addressFields,
  citizenshipFields,
  Document,
  employmentFields,
  EntityBeneficiary,
  IraOrg,
  PersonBeneficiary,
} from "Specs/v1/getIraOrg/200";

export type Beneficiary = PersonBeneficiary | EntityBeneficiary;
export const getOwner = (iraOrg: IraOrg) => iraOrg.entity?.principals?.[0]?.person;

export const getCitizenship = (iraOrg: IraOrg) =>
  pick(getOwner(iraOrg).citizenship || {}, citizenshipFields);
export const getEmployment = (iraOrg: IraOrg) =>
  pick(getOwner(iraOrg).employment || {}, employmentFields);

export const getAddresses = (iraOrg: IraOrg): Address[] =>
  (getOwner(iraOrg)?.addresses || []).map(a => pick(a, addressFields));

export const getBeneficiaries = (iraOrg: IraOrg) => iraOrg.iraPlan.beneficiaries || [];

export const getSpouse = (iraOrg: IraOrg): PersonBeneficiary | undefined =>
  getBeneficiaries(iraOrg).find(ben => ben.spouse) as PersonBeneficiary;

export const getDocs = (iraOrg: IraOrg): Document[] => getOwner(iraOrg)?.documents || [];
export const getDoc = (iraOrg: IraOrg, type: string): Document | undefined =>
  getDocs(iraOrg).find(doc => doc.title === type);

export const getAddress = (iraOrg: IraOrg, role: Address["role"]): Address => {
  const addr = getAddresses(iraOrg).find(addr => addr.role === role);
  return addr ? { ...addr, role } : { role };
};
