import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { BitcoinNetwork } from "@caravan/bitcoin";
import {
  ACTIVE,
  KEYSTORE_TYPES,
  LEDGER,
  LedgerRegisterWalletPolicy,
  PENDING,
  RegisterWalletPolicy,
  SignMultisigTransaction,
} from "@caravan/wallets";
import type {
  LedgerInteraction,
  MultisigWalletConfig,
  RootFingerprint,
  TrezorInteraction,
  UnsupportedInteraction,
} from "@caravan/wallets";
import { CircularProgress } from "@mui/material";
import { Button } from "@unchained/component-library";
import { AxiosError } from "axios";
import classnames from "classnames";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedSigRequestActiveAction } from "Actions/transactionActions/spendingActions";
import { getOperationSourceWalletUuid } from "Redux/selectors/spendingSelectors";
import { useGetProductQuery } from "Shared/api/hooks";
import { useGetProductsCurrentWalletConfig, useUpdatePolicyHmacs } from "Shared/api/hooks/wallets";
import { ProductType } from "Specs/v1/getWallets/params/path";
import { useIsMounted } from "Utils/hooks";
import { bitcoinNetwork } from "Utils/wallet";

import InteractionMessages from "../../../SigningDevices/InteractionMessages";
import styles from "./SignKeyContent.module.scss";

type DeviceInteraction = TrezorInteraction | LedgerInteraction | UnsupportedInteraction;

interface SignKeyContentProps {
  active: boolean;
  getSignButton: () => ReactElement;
  errorMessage: string | ReactElement;
  warningMessage: ReactElement;
  onUnmount: () => void;
  preSignAction: () => void;
  originalPreSignButtonText: string;
  alreadyPreSignedButtonText: string;
  productType: ProductType;
  productUuid: string;
  keystore: KEYSTORE_TYPES;
  psbt: string;
  signingXfp: RootFingerprint;
  bip32Path: string;
  classes: { mainMessage: string };
  setErrorMessage: (string) => void;
  handlePostSig: ({ signed_psbt }) => Promise<void>;
  handleDeviceError: (Error) => string;
  reportDeviceError: (object) => void;
  selectedSigRequestActive: boolean;
}
export const SignKeyContent = ({
  active,
  getSignButton,
  errorMessage,
  warningMessage,
  onUnmount,
  preSignAction,
  originalPreSignButtonText,
  alreadyPreSignedButtonText,
  productType,
  productUuid,
  keystore,
  psbt,
  signingXfp,
  bip32Path,
  classes = { mainMessage: "" },
  setErrorMessage,
  handlePostSig,
  handleDeviceError,
  reportDeviceError,
  selectedSigRequestActive,
}: SignKeyContentProps) => {
  // ReactQuery hooks

  const sourceWalletUuid = useSelector(getOperationSourceWalletUuid);
  // get the product that is responsible for this transaction being signed for
  const productQuery = useGetProductQuery({ [productType]: { uuid: productUuid } });
  // Get the wallet config for the source wallet of this operation or fallback to product
  // current wallet.
  // This relies on the product query hook internally which needs to resolve
  // before it knows the wallet uuid to request. Eventually this will have to support
  // arbitrary wallets when we can spend from old wallets or multiple products.
  // Ideally all bitcoin operations would have a source wallet and we can use
  // that for every query, but currently ONLY sweeps do.
  const walletConfigQuery = useGetProductsCurrentWalletConfig(
    productUuid,
    productType,
    sourceWalletUuid
  );
  // If the user has a ledger and registers the wallet with the device, we want
  // to update the config on our server to persist the policyHmacs. This hook
  // PUTs the data to the server and updates the local cache for the wallet config.
  const updatePolicyHmacs = useUpdatePolicyHmacs();

  // state hooks
  const [alreadyPreSigned, setAlreadyPresigned] = useState(false);
  const [interaction, setInteraction] = useState<DeviceInteraction>();
  const [presignInteraction, setPresignInteraction] = useState<DeviceInteraction>();
  const [walletUuid, setWalletUuid] = useState<string>();
  const [presignActionActive, setPresignActionActive] = useState(false);
  const [policyHmac, setPolicyHmac] = useState<string | void>(null);
  const isMounted = useIsMounted();

  // There are a few properties that impact loading state.
  // To avoid too dramatic of state change "flashes" we'll default to false
  // and aggregate all in an effect hook
  const [isAllDataLoaded, setIsAllDataLoaded] = useState<boolean>(false);

  // utilities
  const dispatch = useDispatch();
  // ledger has a preSignAction local to this component
  const hasPreSignAction = preSignAction || keystore === LEDGER;
  const ACTION_BUTTON_ACTIVE_TEXT = "Connecting...";

  // useEffect hooks
  useEffect(() => {
    if (onUnmount) return onUnmount;
  }, [onUnmount]);

  // needs some cleanup since the policy state might be different
  // between keys
  useEffect(() => {
    return () => {
      if (!isMounted) setIsAllDataLoaded(false);
    };
  }, [isMounted]);

  /**
   * Check if all data has been loaded to turn off loading state
   *  for initial load we should be able to set data as loaded after this is done.
   *  policyHmac starts as undefined but when the config has been checked it will be a string
   *  so we can know at that point that all data has been loaded
   */
  useEffect(() => {
    if (psbt && walletConfigQuery.isFetched && typeof policyHmac === "string")
      setIsAllDataLoaded(true);
  }, [policyHmac, psbt, walletConfigQuery.isFetched]);

  /**
   * Computes policyHmac from config if exists
   *   Once we have a wallet config from the server, we want to check if there
   *   is a registration already for this key's xfp.
   *   Since useUpdatePolicyHmacs also updates the wallet config in the cache
   *   this will also be triggered after a wallet is registered.
   */
  useEffect(() => {
    if (walletConfigQuery.isFetched) {
      const hmac =
        walletConfigQuery.data?.ledgerPolicyHmacs?.find(hmac => hmac.xfp === signingXfp)
          ?.policyHmac || "";
      // If this is signing for a ledger and there is a matching policyHmac
      // we can mark this presign action as complete
      if (hmac && keystore === LEDGER) {
        setAlreadyPresigned(true);
      }
      setPolicyHmac(hmac);
    }
  }, [keystore, psbt, signingXfp, walletConfigQuery.data, walletConfigQuery.isFetched]);

  /**
   * set a presign interaction
   * currently only required for ledger v2
   */
  useEffect(() => {
    if (keystore === LEDGER && walletConfigQuery?.data) {
      setPresignInteraction(
        RegisterWalletPolicy({
          keystore,
          verify: false,
          ...(walletConfigQuery?.data as MultisigWalletConfig),
        }) as DeviceInteraction
      );
    }
  }, [keystore, walletConfigQuery?.data]);

  // Once we have info about the current product, we know its current
  // wallet and therefore the wallet that's being spent from
  // TODO: Eventually we will have to support spending from old wallets too
  // so this value will have to come from elsewhere more explicitly
  useEffect(() => {
    if (productQuery?.data?.current_wallet_uuid)
      setWalletUuid(productQuery.data.current_wallet_uuid);
  }, [productQuery.data]);

  // policyHmac and walletConfigQuery are the only two dependencies to this hook
  // not from props and so are changing in response to user actions or network requests.
  // If either of these change, we may have to update the interaction. For example,
  // if a ledger is registered then we now have a policyHmac we need to update the
  // interaction with so it can sign directly without registering first.
  useEffect(() => {
    if (!psbt) return;
    const options = {
      keystore,
      network: bitcoinNetwork() as BitcoinNetwork,
      psbt,
      keyDetails: {
        xfp: signingXfp,
        path: bip32Path,
      },
      walletConfig: walletConfigQuery.data as MultisigWalletConfig,
      policyHmac: undefined,
    };

    if (policyHmac) options.policyHmac = policyHmac;

    const interaction = SignMultisigTransaction(options);
    setInteraction(interaction as DeviceInteraction);
  }, [bip32Path, keystore, policyHmac, psbt, signingXfp, walletConfigQuery.data]);

  useEffect(() => {
    if (updatePolicyHmacs.error) {
      const error = updatePolicyHmacs.error as AxiosError;
      if (!errorMessage || errorMessage !== error.message) {
        setErrorMessage(error.message);
        setAlreadyPresigned(false);
      }
    }
  }, [errorMessage, setErrorMessage, updatePolicyHmacs.error]);

  // view  and utility methods

  const handleError = useCallback(
    async (error: Error) => {
      const e: Record<string, string> = { ...error };
      e.errorType = "Device signing error";
      e.deviceError = e.message;
      e.cleanMessage = handleDeviceError(e);
      setErrorMessage(e.cleanMessage);
      await reportDeviceError(e);
    },
    [handleDeviceError, reportDeviceError, setErrorMessage]
  );

  // Runs the registration interaction and catches the situation where the
  // device doesn't support registration or xfps don't match.
  const registerLedger = useCallback(async () => {
    try {
      const xfp = await (presignInteraction as unknown as LedgerRegisterWalletPolicy).getXfp();
      if (xfp !== signingXfp) {
        setErrorMessage("This device does not match the key selected.");
        return "";
      }
      const hmac: string | void = await presignInteraction.run();
      return hmac;
    } catch (e) {
      // registration only works for newer ledgers so need to catch
      // unsupported app errors
      if (e.message.match(/method not supported/i)) {
        setErrorMessage(
          "Your Ledger is out of date. Update in Ledger Live to use the most recent features, or continue to sign."
        );
      } else {
        throw e;
      }
    }
  }, [presignInteraction, setErrorMessage, signingXfp]);

  // Some keystores require a "presign" action, like Coldcard's download
  // of a PSBT or Ledger v2's registering of a wallet
  const preSign = useCallback(async () => {
    setErrorMessage("");
    if (keystore === LEDGER) {
      // If there isn't a policyHmac for this key, then we want to register the wallet with the device.
      // We should always have a walletUuid at this point but put the check in just in case
      if (!policyHmac) {
        setPresignActionActive(true);
        try {
          const ledgerPolicyHmac = await registerLedger();

          if (ledgerPolicyHmac) {
            // if it successfully registered then we can send the hmac
            // up to the db. This RQ hook will update the config in the cache
            // to have the new registration, which should trigger subsequent render updates
            updatePolicyHmacs.mutate({
              walletUuid: sourceWalletUuid || walletUuid,
              xfp: signingXfp,
              ledgerPolicyHmac,
            });
            setAlreadyPresigned(true);
          }
        } catch (e) {
          handleError(e);
        }
        setPresignActionActive(false);
      }
    } else {
      setAlreadyPresigned(true);
    }
    // Ledger uses presign without having a preSignAction passed in from parent
    // so it could be undefined
    if (preSignAction) preSignAction();
  }, [
    handleError,
    keystore,
    policyHmac,
    preSignAction,
    registerLedger,
    setErrorMessage,
    signingXfp,
    sourceWalletUuid,
    updatePolicyHmacs,
    walletUuid,
  ]);

  // View methods used in the returned jsx

  const sign = async () => {
    try {
      dispatch(setSelectedSigRequestActiveAction(true));
      setErrorMessage("");
      let result = await interaction.run();
      await handlePostSig({ signed_psbt: result });
    } catch (e) {
      handleError(e);
    }
    dispatch(setSelectedSigRequestActiveAction(false));
  };

  const interactionMessages = useMemo(() => {
    const interactionState = active ? ACTIVE : PENDING;

    try {
      return interaction?.messagesFor({ state: interactionState }) || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [active, interaction]);

  const presignInteractionMessages = useMemo(() => {
    const interactionState = presignActionActive ? ACTIVE : PENDING;
    const messagesForOptions: { state: string; code?: string } = { state: interactionState };

    if (keystore === LEDGER) messagesForOptions.code = "register";

    try {
      return presignInteraction?.messagesFor(messagesForOptions) || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }, [keystore, presignActionActive, presignInteraction]);

  const getPresignedActionText = useMemo(() => {
    if (alreadyPreSigned) return alreadyPreSignedButtonText;
    if (presignActionActive) return ACTION_BUTTON_ACTIVE_TEXT;
    return originalPreSignButtonText;
  }, [
    alreadyPreSigned,
    alreadyPreSignedButtonText,
    originalPreSignButtonText,
    presignActionActive,
  ]);

  if (!isAllDataLoaded)
    return (
      <div className={classnames(styles.container)}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classnames(styles.container, "gap-4")}>
      <div className={classnames(classes.mainMessage, styles.mainMessage, "gap-4")}>
        <InteractionMessages messages={interactionMessages} excludeCodes={["bip32"]} size="sm" />
        {hasPreSignAction && (
          <div className="flex flex-col items-center gap-4">
            {!alreadyPreSigned && (
              <InteractionMessages messages={presignInteractionMessages} size="sm" />
            )}

            <Button
              onClick={preSign}
              disabled={alreadyPreSigned || presignActionActive || selectedSigRequestActive}
            >
              {getPresignedActionText}
            </Button>
          </div>
        )}
      </div>
      {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
      {getSignButton() || (
        <Button onClick={sign} disabled={selectedSigRequestActive || presignActionActive}>
          {selectedSigRequestActive
            ? ACTION_BUTTON_ACTIVE_TEXT
            : `Sign with ${capitalize(keystore)}`}
        </Button>
      )}
      {warningMessage || <div className={styles.warningPlaceholder} />}
    </div>
  );
};
