import { FC, ReactNode, useState } from "react";

import {
  HeroIcon,
  IconProps,
  Icons,
  InputLabel,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  TextArea,
} from "@unchained/component-library";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { Link } from "Components/Link";
import { ClientReportsState } from "Reducers/clientReportReducer";
import { RootState } from "Reducers/index";
import { ClientReportsAPI } from "Shared/api/v2";
import { AppModalManager } from "Shared/components/Modals";
import { ClientReportData } from "Specs/v2/components";

import { useMaskedAccount } from "./useMaskedAccount";

const getSystemInfo = () => {
  try {
    return {
      userAgent: navigator?.userAgent,
      windowSize: {
        width: window?.innerWidth,
        height: window?.innerHeight,
      },
      timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};

const IconContent = ({
  icon,
  color,
  text,
}: {
  icon: FC<IconProps>;
  color: "red" | "green";
  text: ReactNode;
}) => (
  <div className="my-2 flex h-full flex-col items-center justify-center">
    <div className="flex flex-col items-center gap-6">
      <HeroIcon color={color} Icon={icon} size="lg" />
      <p className="[&>a]:!font-bold [&>a]:!underline">{text}</p>
    </div>
  </div>
);

export const ClientReportModal = ({ error }: { error?: Record<string, unknown> }) => {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { errors, pathHistory, extra, requests } = useSelector<RootState>(
    state => state.clientReports
  ) as ClientReportsState;

  const maskedAccount = useMaskedAccount();
  const [description, setDescription] = useState("");
  const [state, setState] = useState<"error" | "success">();

  const submitReport = useMutation((data: ClientReportData) => ClientReportsAPI.Create(data), {
    onSuccess: () => {
      setState("success");
    },
    onError: (error: unknown) => {
      console.error(error);
      setState("error");
    },
  });

  const fullyLoaded = !!maskedAccount && !submitReport.isLoading;

  let content;
  if (state === "success") {
    content = (
      <IconContent
        color="green"
        icon={Icons.CheckmarkCircle}
        text="Thank you. We'll be in touch."
      />
    );
  } else if (state === "error") {
    const text = (
      <>
        Report sharing failed. Please <Link to="mailto:support@unchained.com">let us know</Link>.
      </>
    );
    content = <IconContent color="red" icon={Icons.AlertCircle} text={text} />;
  } else if (fullyLoaded) {
    content = (
      <>
        <div className="prose">
          <p>
            Fill in the details of your issue below, and then submit to send a report to Unchained.
            The report will include{" "}
            <strong>
              <em>redacted</em>
            </strong>{" "}
            basic info about your current app state, including:
          </p>
          <ul>
            <li>Errors that may recently have occured.</li>
            <li>Your recent Unchained navigation history.</li>
            <li>
              Account details <em>excluding personally-identifying information</em> (but including
              unique IDs specific administrators can use to follow up with you and get more
              information).
            </li>
            <li>Recent API requests (omitting request and response content).</li>
            <li>Anonymized information relevant to the context of the flow you are in.</li>
          </ul>
        </div>
        <div className="flex w-full flex-col gap-2">
          <InputLabel>Describe the issue</InputLabel>
          <TextArea
            fullWidth
            placeholder="What happened? What were you trying to do?"
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
        </div>
      </>
    );
  } else {
    content = <Loader className="my-6 h-full w-full" size="md" />;
  }

  const onSendReport = () => {
    try {
      const json = {
        userUuid: maskedAccount?.userUuid,
        orgUuid: maskedAccount?.orgUuid,
        triggeredByError: !!error,
        errors: [error, ...errors.slice(0, 19)].filter(Boolean),
        pathHistory: pathHistory.slice(0, 30).map(p => p.path),
        system: getSystemInfo(),
        extra,
        requestHistory: requests
          .slice(0, 80)
          .map(r => `[${r.code}] ${r.method?.toUpperCase()} ${r.path}`),
        account: maskedAccount,
      };
      submitReport.mutate({ description, report: json });
    } catch (err) {
      setState("error");
    }
  };

  return (
    <Modal onDismiss={AppModalManager.close} className={!!state && "sm:min-w-[350px]"}>
      <ModalHeader>
        <ModalTitle
          subtitle={state ? undefined : "Did something go wrong? Send a secure report to Unchained"}
        >
          {state === "success"
            ? "Info reported"
            : state === "error"
            ? "Report failed"
            : "Share more about your problem"}
        </ModalTitle>
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ModalFooter
        actions={
          !!state
            ? [{ children: "OK", onClick: AppModalManager.close }]
            : [
                {
                  children: "Send report",
                  disabled: !!state || !fullyLoaded || submitReport.isLoading || !description,
                  onClick: onSendReport,
                },
                {
                  children: "Cancel",
                  onClick: AppModalManager.close,
                },
              ]
        }
      />
    </Modal>
  );
};

export const openClientReportModal = (error?: Record<string, unknown>) =>
  AppModalManager.open(() => <ClientReportModal error={error} />);
