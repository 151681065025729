import React from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import { DocumentMetadata } from "./DocumentMetadata";
import { DocumentPreview } from "./DocumentPreview";
import { FileUpload } from "./FileUpload";
import styles from "./FileUpload.module.scss";

class DocumentUpload extends React.Component {
  static propTypes = {
    mimeTypes: PropTypes.string,
    maxFileSize: PropTypes.number,
    uploadMessage: PropTypes.string,
    previewMessage: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    document: PropTypes.object,
    updateDocument: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    required: PropTypes.bool,
    onDownload: PropTypes.func,
    newContent: PropTypes.object,
    direction: PropTypes.oneOf(["column", "row"]),
    classes: PropTypes.shape({
      dropzone: PropTypes.string,
      preview: PropTypes.string,
    }),
  };
  static defaultProps = {
    direction: "row",
  };
  getGridSizeFromDirection = direction => {
    if (direction.includes("column")) {
      return {
        documentMetaData: 12,
        fileUpload: 12,
      };
    }
    return {
      documentMetaData: 5,
      fileUpload: 7,
    };
  };
  delete = () => {
    const { updateDocument, onDelete } = this.props;
    updateDocument({});
    if (onDelete) {
      onDelete();
    }
  };

  render() {
    return <div className={styles.uploadContainer}>{this.renderContent()}</div>;
  }

  renderContent() {
    const {
      document,
      description,
      label,
      uploadMessage,
      previewMessage,
      updateDocument,
      onDownload,
      required,
      direction,
      classes = {},
    } = this.props;
    if (document && document.name) {
      return (
        <DocumentPreview
          label={label}
          previewMessage={previewMessage}
          description={description}
          onDownload={onDownload}
          remove={this.delete}
          direction={direction}
          className={classes.preview}
          {...document}
        />
      );
    } else {
      const sizes = this.getGridSizeFromDirection(direction);
      return (
        <Grid container direction={direction}>
          <Grid item xs={sizes.documentMetaData}>
            <DocumentMetadata label={label} description={description} />
          </Grid>

          <Grid item xs={sizes.fileUpload}>
            <FileUpload
              className={classes.dropzone}
              required={required}
              uploadMessage={uploadMessage}
              previewMessage={previewMessage}
              updateDocument={updateDocument}
            />
          </Grid>
        </Grid>
      );
    }
  }
}

export { DocumentUpload };
