import React, { useState } from "react";

import { HelpOutline } from "@mui/icons-material";
import { Collapse, Divider } from "@mui/material";
import { AddressString, Button, Tooltip } from "@unchained/component-library";

import { Fee } from "Contexts/BuyBitcoin/types";
import { ReceivingAccountType } from "Contexts/SellBitcoin/types";
import { formatCurrency } from "Utils/strings";

import { PreviewContentItem } from "../../components/PreviewContentItem";
import { TradingFeesTooltip } from "../../components/TradingFeesTooltip";
import {
  FeeEstimateSummary,
  FeeRateSummary,
  ReceivingBankAccountSummary,
  ReceivingCashAccountSummary,
} from "../steps/ReviewTransactionStep";

const itemContainerStyles = "flex flex-row justify-between mt-3";
const itemContentStyles = "gray-600, m-0 text-md font-med";
const itemTitleStyles = "gray-600, m-0 text-md font-reg";

type SaleSummaryCardProps = {
  vaultName: string;
  vaultId: string;
  saleAmountBTC: string;
  streamingQuoteUniqueId?: string;
  isFirstStreamingQuote?: boolean;
  saleAmountUSD: string;
  feeRates: Fee[];
  bitcoinPrice: string;
  feeAmountUSD: string;
  amountUSDToBeSentToClient: string;
  isSaleComplete: boolean;
  btcRecieveAddress: string;
  btcRecieveAddressUnchained: string;
  transactionFeeAmountBTC: string;
  transactionFeeAmountUSD: string;
  bankAccountName: string;
  bankAccountLastFourDigits: string;
  transactionFeeSatsVByte: string;
  receivingAccountType: ReceivingAccountType;
  cashBalanceUsd?: string;
  isIraOrg: boolean;
};
export const SaleSummaryCard = ({
  vaultName,
  vaultId,
  saleAmountBTC,
  streamingQuoteUniqueId,
  isFirstStreamingQuote,
  feeRates,
  saleAmountUSD,
  bitcoinPrice,
  feeAmountUSD,
  amountUSDToBeSentToClient,
  isSaleComplete,
  transactionFeeAmountBTC,
  transactionFeeAmountUSD,
  btcRecieveAddressUnchained,
  btcRecieveAddress,
  bankAccountName,
  bankAccountLastFourDigits,
  transactionFeeSatsVByte,
  receivingAccountType,
  cashBalanceUsd,
  isIraOrg,
}: SaleSummaryCardProps) => {
  const feeCostFormatted = feeAmountUSD ? `$${formatCurrency(feeAmountUSD)}` : "---";
  const backgroundColor = isSaleComplete ? "bg-primary-50" : "bg-gray-50 border-gray-200";
  const btcAmountColor = isSaleComplete ? "text-primary-700" : "text-gray-800";
  const dividerColor = isSaleComplete ? "border-primary-300" : "bg-gray-300";

  const [isShowAdditionalDetails, setIsShowAdditionalDetails] = useState(false);

  const handleShowDetails = () => {
    setIsShowAdditionalDetails(true);
  };
  const handleHideDetails = () => {
    setIsShowAdditionalDetails(false);
  };

  return (
    <div>
      <div
        data-testid="sellBitcoinFinalizeStepContentContainer"
        className={`flex flex-col justify-between p-4 ${backgroundColor}`}
      >
        <h2 className={`text-xl font-semi ${btcAmountColor}`}>{saleAmountBTC} BTC</h2>
        <p className="mb-4 text-sm text-gray-600">
          {isSaleComplete ? "Sold from: " : "To be sold from: "}
          <span className="font-med text-gray-800">
            {vaultName} ({vaultId})
          </span>
        </p>
        <Divider className={dividerColor} />
        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-bitcoinPricekey`}
          animateOnChange={!isFirstStreamingQuote}
          title="Bitcoin price"
          content={`$${formatCurrency(bitcoinPrice)}`}
          containerStyles={itemContainerStyles}
          contentStyles={itemContentStyles}
          titleStyles={itemTitleStyles}
        />

        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-saleAmountUSDKey`}
          animateOnChange={!isFirstStreamingQuote}
          title={`Amount`}
          content={`$${formatCurrency(saleAmountUSD)}`}
          containerStyles={itemContainerStyles}
          contentStyles={itemContentStyles}
          titleStyles={itemTitleStyles}
        />
        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-FeeKey`}
          animateOnChange={!isFirstStreamingQuote}
          title={
            <>
              {[
                "Unchained fee",
                <TradingFeesTooltip
                  infoIconClass={"ml-1"}
                  key="tradingFeesToolTip"
                  fees={feeRates}
                />,
              ]}
            </>
          }
          content={feeCostFormatted}
          containerStyles="mb-4 flex flex-row justify-between mt-3"
          contentStyles={itemContentStyles}
          titleStyles={itemTitleStyles}
        />
        <Divider className={dividerColor} />
        <PreviewContentItem
          key={`${streamingQuoteUniqueId}-wireAmountOwed`}
          animateOnChange={!isFirstStreamingQuote}
          title={
            <>
              {isIraOrg
                ? "Amount to be sent to IRA cash balance"
                : "Amount to be sent to your account"}
              {isSaleComplete ? null : (
                <Tooltip
                  arrow
                  placement="top"
                  compact
                  content={
                    "Due to bitcoin price fluctuations, exact amounts won’t be set until this transaction is finalized and broadcasted."
                  }
                  data-testid="tradingEstimateToolTip"
                >
                  <HelpOutline className={"relative top-[-1px] ml-1 text-lg text-gray-400"} />
                </Tooltip>
              )}
            </>
          }
          content={`$${formatCurrency(amountUSDToBeSentToClient)}`}
          contentStyles={"gray-800, m-0 text-md font-semi"}
          titleStyles={"gray-800, m-0 text-md font-semi"}
          containerStyles="mt-4 flex flex-row justify-between"
        />
      </div>

      {/* additional details section */}

      <div className={` ${backgroundColor} mt-4 p-4`}>
        <Collapse in={isShowAdditionalDetails}>
          <>
            <PreviewContentItem
              animateOnChange={false}
              title={"Destination (Unchained)"}
              content={<AddressString bolded address={btcRecieveAddressUnchained} />}
              containerStyles={"flex flex-row justify-between mt-0"}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <PreviewContentItem
              animateOnChange={false}
              title={"Change address"}
              content={<AddressString bolded address={btcRecieveAddress} />}
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <Divider className={`mb-1 mt-4 ${dividerColor}`} />
            <PreviewContentItem
              animateOnChange={false}
              title={
                <>
                  Mining fee rate
                  <Tooltip
                    arrow
                    placement="top"
                    compact
                    content={
                      "The mining fee rate is set to high priority to ensure that your bitcoin transaction is processed as quickly as possible."
                    }
                    data-testid="feeRateToolTip"
                  >
                    <HelpOutline className={"relative top-[-2px] ml-1 text-lg text-gray-400"} />
                  </Tooltip>
                </>
              }
              content={<FeeRateSummary feeRate={transactionFeeSatsVByte} />}
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <PreviewContentItem
              animateOnChange={false}
              title={"Mining fee total"}
              content={
                <FeeEstimateSummary
                  feeAmountInBtc={transactionFeeAmountBTC}
                  feeAmountInUsd={transactionFeeAmountUSD}
                />
              }
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <Divider className={`mb-1 mt-4 ${dividerColor}`} />
            <PreviewContentItem
              animateOnChange={false}
              title={"Receiving account"}
              content={
                receivingAccountType === ReceivingAccountType.CASH_BALANCE ? (
                  <ReceivingCashAccountSummary
                    cashBalanceUsd={cashBalanceUsd}
                    isIraCashAccount={isIraOrg}
                  />
                ) : (
                  <ReceivingBankAccountSummary
                    bankAccountLastFourDigits={bankAccountLastFourDigits}
                    bankAccountName={bankAccountName}
                  />
                )
              }
              containerStyles={itemContainerStyles}
              contentStyles={itemContentStyles}
              titleStyles={itemTitleStyles}
            />
            <Button
              onClick={handleHideDetails}
              className={`mt-3 !text-sm !font-med !text-primary-600 hover:!text-gray-800`}
              type={"text"}
            >
              Hide additional details
            </Button>
          </>
        </Collapse>
        {!isShowAdditionalDetails ? (
          <Button
            onClick={handleShowDetails}
            className={`!text-sm !font-med !text-primary-600 hover:!text-gray-800`}
            type={"text"}
          >
            Show additional details
          </Button>
        ) : null}
      </div>
    </div>
  );
};
