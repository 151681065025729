import React from "react";

import cx from "classnames";
import { useSelector } from "react-redux";

import { usePrevious } from "Utils/hooks";

import styles from "./Overlay.module.scss";

const Overlay = () => {
  const getOverlay = state => state.overlay.isOverlay;
  const isOverlay = useSelector(getOverlay);
  const previousIsOverlay: string = usePrevious(isOverlay);
  const isOverlayUnmounting = previousIsOverlay && !isOverlay;

  return (
    <div
      className={cx({
        [styles.appOverlay]: isOverlay,
        [styles.fadeOutAndHideOnUnmount]: isOverlayUnmounting,
      })}
    />
  );
};

export { Overlay };
