import React, { Component } from "react";

import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import classnames from "classnames";
import { capitalize } from "lodash";
import get from "lodash/get";
import PropTypes from "prop-types";

import defaultLogo from "Assets/images/default_key.png";
import unchainedLogo from "Assets/images/unchained_logo.png";
import { arbiterInfo } from "Utils/arbiter";

import styles from "./DistinguishedKey.module.scss";
import { SignedRibbon } from "./SignedRibbon";
import { logoMap } from "./logoMap";

class DistinguishedKeyBase extends Component {
  static propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    onClick: PropTypes.func,
    username: PropTypes.string,
    distinguished: PropTypes.bool,
    active: PropTypes.bool,
    size: PropTypes.string,
    accountKey: PropTypes.object,
    disabled: PropTypes.bool,
    signedPsbt: PropTypes.string,
    logoSlug: PropTypes.string,
  };

  static defaultProps = {
    active: true,
    size: "sm",
  };

  LogoChooser = () => {
    const { size } = this.props;
    const logoSlug = this.props?.accountKey?.logo_slug ?? this.props.logoSlug;
    // Do we even need this?
    let img;
    if (logoSlug) {
      img = logoMap[logoSlug] ?? defaultLogo;
    } else {
      const role = this.getRole();
      img = role === "unchained" ? unchainedLogo : arbiterInfo.logo;
    }
    return (
      <img
        src={img}
        alt={`${this.keyName()} Logo`}
        className={classnames({
          [styles.xsLogo]: size === "xs",
          [styles.smLogo]: size === "sm",
          [styles.mdLogo]: size === "md",
          [styles.lgLogo]: size === "lg",
        })}
      />
    );
  };

  keyName = () => {
    const name = this.props?.accountKey?.name ?? this.props.name;
    if (name) {
      return name;
    }
    const role = this.getRole();
    const distinguishedName = role === "unchained" ? role : arbiterInfo.name;
    return capitalize(distinguishedName);
  };

  getRole = () => {
    const { accountKey, role } = this.props;
    const theRole = role || get(accountKey, "role", "");
    return theRole;
  };

  onClick = e => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(e);
    }
  };

  render() {
    const { active, username, disabled, size, signedPsbt } = this.props;
    return (
      <Button
        classes={{
          root: classnames(styles.container, {
            [styles.default]: !active,
            [styles.active]: active,
            [styles.disabled]: disabled,
          }),
          contained: classnames(styles.contentContainer, {
            [styles.xsContainer]: size === "xs",
            [styles.smContainer]: size === "sm",
            [styles.mdContainer]: size === "md",
            [styles.lgContainer]: size === "lg",
          }),
        }}
        variant="contained"
        disabled={disabled}
        onClick={this.onClick}
        ref={this.props.ref}
      >
        {signedPsbt && <SignedRibbon />}
        <div className={styles.iconContainer}>
          <this.LogoChooser />
        </div>
        <div className={`${styles.textContainer} overflow-auto break-words`}>
          {this.keyName()}
          <FormHelperText style={{ marginTop: 0 }}>{username}</FormHelperText>
        </div>
      </Button>
    );
  }
}

export const DistinguishedKey = DistinguishedKeyBase;
