import PropTypes from "prop-types";

import { formatCurrency } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

import { Slice } from "./slices";
import { Sweep, sweepProptypes } from "./sweeps";

export function walletProptypes() {
  return {
    unit: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,

    testnet: PropTypes.bool,
    revealed_slices: PropTypes.array,
    sweep: PropTypes.shape(sweepProptypes()),

    createdAt: PropTypes.object,
    readableCreatedAt: PropTypes.string.isRequired,

    isReturnable: PropTypes.bool.isRequired,

    fees_per_byte_estimate: PropTypes.number,

    deposits: PropTypes.array,
    totalDepositsWithUnit: PropTypes.string,
  };
}

export function Wallet(data) {
  if (data == null) {
    return data;
  }

  data.isReturnable = data.return_address && data.return_address != null ? true : false;

  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);

  if (data.revealed_slices) {
    data.revealed_slices = data.revealed_slices.map(slice => Slice(slice));
  }
  if (data.sweep) {
    data.sweep = Sweep(data.sweep);
  }

  if (data.completed_sweeps) {
    data.completed_sweeps = data.completed_sweeps.map(sweep => Sweep(sweep));
  }

  if (data.deposits) {
    var i;
    for (i = 0; i < data.deposits.length; i++) {
      var deposit = data.deposits[i];
      deposit.amountWithUnit = `${formatCurrency(deposit.amount, 8)} ${data.unit}`;
      if (deposit.broadcast_at) {
        deposit.broadcastAt = parseTimestamp(deposit.broadcast_at);
        deposit.readableBroadcastAt = readableTime(deposit.broadcastAt);
      }
      if (deposit.confirmed_at) {
        deposit.confirmedAt = parseTimestamp(deposit.confirmed_at);
        deposit.readableConfirmedAt = readableTime(deposit.confirmedAt);
      }
    }
    data.totalDepositsWithUnit = `${formatCurrency(
      data.deposits.reduce((a, b) => a + b.amount, 0),
      8
    )} ${data.unit}`;
  }

  return data;
}
