import { detableize } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

import { Signership } from "./signerships";

export function AccountKeyListing(data) {
  if (data == null) {
    return data;
  }

  data.humanRole = detableize(data.role);

  if (data.created_at) {
    data.createdAt = parseTimestamp(data.created_at);
    data.readableCreatedAt = readableTime(data.createdAt);
  }

  data.signershipFor = username =>
    (data.users_account_keys || []).find(signership => signership.username === username);
  if (data.users_account_keys) {
    data.users_account_keys = data.users_account_keys
      ? data.users_account_keys.map(signership => Signership(signership))
      : null;
  }

  if (data.name && data.shared) {
    data.bestName = `${data.partner_username}: ${data.name}`;
  } else {
    data.bestName = data.name;
  }

  return data;
}
