import PropTypes from "prop-types";

import { LoanListing } from "./loan_listing";
import { VaultListing } from "./vault_listing";

export function productListingProptypes(admin) {
  if (admin) {
    return {
      ...productListingProptypes(),
      ...{
        note: PropTypes.string,
      },
    };
  } else {
    return {
      uuid: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      humanState: PropTypes.string,
      unit: PropTypes.string,
      created_at: PropTypes.string,
      createdAt: PropTypes.object,
      readableCreatedAt: PropTypes.string,
      product_type: PropTypes.string,
      username: PropTypes.string,
    };
  }
}

export function ProductListing(data) {
  if (data == null) {
    return data;
  }
  if (data.product_type === "loan") {
    return LoanListing(data);
  }
  if (data.product_type === "vault") {
    return VaultListing(data);
  }
}
