import { API_V2 } from "Shared/api/api";
import { AdminLockBankAccountResponse } from "Specs/v2/components";

export class BankAccountApiV2 {
  static async DeleteBankAccount(orgUuid: string, bankAccountUuid: string) {
    const response = await API_V2.Delete<any>(`/orgs/${orgUuid}/bank_account/${bankAccountUuid}`);
  }

  static async UpdateBankAccountNickname(
    orgUuid: string,
    bankAccountUuid: string,
    nickname: string
  ) {
    return await API_V2.Patch<any>(`/orgs/${orgUuid}/bank_account/${bankAccountUuid}/nickname`, {
      nickname,
    });
  }

  static async AdminLockBankAccount(bankAccountUuid: string, lockAccount: boolean) {
    return await API_V2.Post<AdminLockBankAccountResponse>(
      `/bank_accounts/${bankAccountUuid}/lock`,
      {
        lockAccount,
      }
    );
  }
}
