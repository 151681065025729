import { SignIcon } from "Components/Shared/Icons/SignIcon";
import { btcOperationTypes } from "Utils/enums";

const operationVerbClauseMap = {
  // These operation types can not generate SignTransactionNotifications
  //discovered_bitcoin_transaction: "discovered transfer",
  // manual_change: "manual transfer",
  // rekey: "rekey",
  // external_vault_deposit: "deposit",
  // external_loan_deposit: "deposit",
  // loan_payment: "pay",
  // loan_principal_payment: "pay",
  // loan_prepayment: "prepay",
  // loan_disbursement: "disburse from",
  // loan_collateral_sale: "sell from",

  vault_transaction: "transfer from",
  vault_sweep: "sweep of",
  loan_redemption: "transfer from",
  loan_liquidation: "liquidate from",
  loan_sweep: "sweep of",
  loan_closing: "closing of",
  batch_settlement: "batch settlement from",
};

const generateOperationVerbClause = operationType => {
  if (operationVerbClauseMap[operationType]) {
    return operationVerbClauseMap[operationType];
  }

  // Error?
  return operationType;
};

const ApproveSignatureRequestNotification = ({
  operation_type,
  product_name,
  account_key_name,
}: {
  operation_type: string;
  product_name: string;
  account_key_name: string;
}) => {
  const isSaleTransaction = operation_type === btcOperationTypes.vault_sale_transaction;
  const operationVerbClause = generateOperationVerbClause(operation_type);
  const title = isSaleTransaction
    ? `Approve pending sell transaction from ${product_name}`
    : `Approve signing with key ${account_key_name} for ${operationVerbClause} ${product_name}`;

  return {
    to: "/btc_transaction_requests",
    title: title,
    Icon: SignIcon,
  };
};

export { ApproveSignatureRequestNotification };
