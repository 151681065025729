import React from "react";

import { CircularProgress, Collapse } from "@mui/material";
import { Card } from "@unchained/component-library";
import cx from "classnames";
import PropTypes from "prop-types";

import { REQUEST_STATUS } from "Shared/api/api";

import { Forbidden, NotFound } from "../errors";
import styles from "./DashboardCard.module.scss";

const LoadingComponent = () => (
  <div className={styles.loadingContainer}>
    <CircularProgress className={styles.loadingCircle} />
  </div>
);

const ErrorComponent = () => (
  <div>
    <p className={styles.error}>
      There was a problem loading the contents of this card. This error has been reported.
    </p>
  </div>
);

export const DashboardCard = ({ className, render, status, children }) => {
  let Content;

  switch (status) {
    case REQUEST_STATUS.PENDING:
      Content = LoadingComponent;
      break;
    case REQUEST_STATUS.SUCCESS:
      Content = render;
      break;
    case REQUEST_STATUS.NOT_FOUND:
      Content = NotFound;
      break;
    case REQUEST_STATUS.FORBIDDEN:
      Content = Forbidden;
      break;
    case REQUEST_STATUS.ERROR:
      Content = ErrorComponent;
      break;
    default:
      Content = null;
      break;
  }

  return (
    <Card className={cx(styles.dashboardCard, className)}>
      {children}
      {status ? (
        <Collapse in={status === REQUEST_STATUS.SUCCESS} collapsedSize="150px" timeout={500}>
          {Content && <Content />}
        </Collapse>
      ) : (
        ""
      )}
    </Card>
  );
};

DashboardCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.values(REQUEST_STATUS)),
  render: PropTypes.func,
};
