import { useCurrentOrgDeprecated } from "Redux/selectors/hooks";
import { useGetAccount } from "Shared/api";
import { OrgMember, OrgMemberRole } from "Specs/v1/getAccount/200";

/**
 * Returns `true` if the user has the provided role on the current org.
 */
export const useUserHasRole = (managedRole: OrgMemberRole["managed"]) => {
  const { uuid: currentOrgUuid } = useCurrentOrgDeprecated();
  const {
    data: { memberships },
  } = useGetAccount();

  const membership =
    memberships.find(membership => membership.org.uuid === currentOrgUuid) ||
    ({ roles: [] } as Partial<OrgMember>);

  for (const role of membership.roles) {
    if (role.managed === managedRole) {
      return true;
    }
  }

  return false;
};

/**
 * Returns `true` if the user has the viewer role on the current org.
 */
export const useUserIsViewer = () => {
  return useUserHasRole("viewers");
};

/**
 * Returns `true` if the user has the viewer role on the current org.
 */
export const useUserIsViewerNotAdmin = () => {
  const isViewer = useUserHasRole("viewers");
  const isAdmin = useUserHasRole("admins");
  return isViewer && !isAdmin;
};
