import React from "react";

export const Vaults = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M19.4,22H4.6c-0.7,0-1.1,0-1.5-0.2c-0.4-0.2-0.7-0.5-0.9-0.9C2,20.5,2,20.1,2,19.4v-0.8c0-0.7,0-1.1,0.2-1.5
	c0.2-0.4,0.5-0.7,0.9-0.9C3.4,16.1,3.7,16,4,16v-6c-0.3,0-0.6-0.1-0.9-0.2C2.7,9.6,2.4,9.3,2.2,8.9C2,8.5,2,8.1,2,7.4V6.3
	C2,5.7,2,5.4,2.2,5c0.1-0.3,0.4-0.6,0.7-0.8C3.2,3.9,3.5,3.9,4,3.7l7.4-1.6c0.2,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.4,0
	c0.1,0,0.2,0,0.4,0.1L20,3.7c0.5,0.1,0.9,0.2,1.2,0.4c0.3,0.2,0.5,0.5,0.7,0.8C22,5.4,22,5.7,22,6.3v1.1c0,0.7,0,1.1-0.2,1.5
	c-0.2,0.4-0.5,0.7-0.9,0.9C20.6,9.9,20.3,10,20,10v6c0.3,0,0.6,0.1,0.9,0.2c0.4,0.2,0.7,0.5,0.9,0.9c0.2,0.4,0.2,0.9,0.2,1.5v0.8
	c0,0.7,0,1.1-0.2,1.5c-0.2,0.4-0.5,0.7-0.9,0.9C20.5,22,20.1,22,19.4,22z M4.6,18C4.3,18,4,18,4,18c0,0,0,0.3,0,0.6v0.8
	C4,19.7,4,20,4,20c0,0,0.3,0,0.6,0h14.8c0.3,0,0.6,0,0.6,0c0,0,0-0.3,0-0.6v-0.8c0-0.3,0-0.6,0-0.6c0,0-0.3,0-0.6,0H4.6z M15.5,16
	H18v-6h-2.5V16z M10.5,16h3v-6h-3V16z M6,16h2.5v-6H6V16z M12,4l-0.1,0c0,0,0,0,0,0L4.5,5.7C4.3,5.7,4,5.8,4,5.8c0,0,0,0.2,0,0.5
	v1.1C4,7.7,4,8,4,8c0,0,0.3,0,0.6,0h14.8C19.7,8,20,8,20,8c0,0,0-0.3,0-0.6V6.3c0-0.2,0-0.5,0-0.5c0,0-0.2,0-0.5-0.1L12,4z
	 M11.7,3.1L11.7,3.1L11.7,3.1z"
    />
    <rect fill="none" width="24" height="24" />
  </svg>
);
