/* global trackEvent ga */
import _ from "lodash";

import { getLocalTimezone, timestampFromServerTime } from "Utils/time";

import { API } from "./api";

export const vaultListKeyedByUuid = vaults => (vaults.length > 0 ? _.keyBy(vaults, "uuid") : {});

export class VaultAPI {
  static List = async (page = 1, perPage = 20) => {
    const params = { page, per_page: perPage };
    const response = await API.Get(`/vaults`, params);

    const { vaults, total, total_pages, per_page } = response.data;

    return {
      vaults: vaultListKeyedByUuid(vaults),
      total,
      totalPages: total_pages,
      perPage: per_page,
    };
  };

  static ListForOrg = async orgUUID => {
    const response = await API.Get(`/orgs/${orgUUID}/vaults`);

    const { vaults } = response.data;
    return vaultListKeyedByUuid(vaults);
  };

  static Create = async (orgUUID, data) => {
    const response = await API.Post(`/orgs/${orgUUID}/vaults`, data);
    trackEvent({
      category: "FUNNEL/VAULT",
      action: "created",
      label: data.unit,
    });
    return response.data;
  };

  static Get = async uuid => {
    const response = await API.Get(`/vaults/${uuid}`);
    const { vault } = response.data;
    return vault;
  };

  static Rename = async (uuid, data) => {
    const response = await API.Put(`/vaults/${uuid}`, data);
    return response.data;
  };

  static CreateThresholdChange = async (uuid, data) => {
    const response = await API.Put(`/vaults/${uuid}/threshold`, data);
    return response.data;
  };

  static CloseUsed = async uuid => {
    const response = await API.Post(`/vaults/${uuid}/close`);
    return response.data;
  };

  static Delete = async uuid => {
    const response = await API.Delete(`/vaults/${uuid}`);
    return response.data;
  };

  static UpdateNote = async (uuid, note) => {
    const response = await API.Put(`/vaults/${uuid}/note`, note);
    const { message } = response.data;
    return { message };
  };

  static GetBtcTransactions = async (
    { pageIndex, pageSize },
    uuid,
    sortBy,
    sortOrder,
    include_own_transfer_locations = false,
    includeUnbroadcasted = false
  ) => {
    const pageParams = {
      page: pageIndex,
      per_page: pageSize,
      include_own_transfer_locations,
      include_unbroadcasted: Boolean(includeUnbroadcasted) ? 1 : 0,
      sortBy,
      sortOrder,
    };
    const response = await API.Get(`/vaults/${uuid}/btc/transactions`, pageParams);
    const { btc_transactions, total, total_pages, per_page } = response.data;
    return {
      btc_transactions,
      total,
      totalPages: total_pages,
      perPage: per_page,
    };
  };

  static CreateTransaction = async (uuid, feeRate, accountKeyUUIDs, outputs) => {
    const response = await API.Post(`/vaults/${uuid}/transactions`, {
      fee_rate: feeRate,
      account_key_uuids: accountKeyUUIDs,
      outputs,
    });
    return response.data;
  };

  static GetTransaction = async (vaultUUID, operationUUID) => {
    const response = await API.Get(`/vaults/${vaultUUID}/transactions/${operationUUID}`);
    return response.data;
  };

  static GetSettlement = async (vaultUUID, operationUUID) => {
    const response = await API.Get(`/vaults/${vaultUUID}/settlements/${operationUUID}`);
    return response.data;
  };

  static GetSweep = async (vaultUUID, operationUUID) => {
    const response = await API.Get(`/vaults/${vaultUUID}/sweeps/${operationUUID}`);
    return response.data;
  };

  static GetHistoricalBalances = async vaultUUID => {
    const timezone = getLocalTimezone();
    const response = await API.Get(`/vaults/${vaultUUID}/historical_balances?timezone=${timezone}`);
    if (response.data && response.data.historical_balances) {
      const balances = response.data.historical_balances;
      const data = [];
      for (const balance of balances) {
        const point = { ...balance, time: timestampFromServerTime(balance.time) };
        data.push(point);
      }
      return data;
    } else return [];
  };

  static GetBalances = async vaultUUID => {
    const response = await API.Get(`/vaults/${vaultUUID}/balances`);
    return response.data;
  };
}
