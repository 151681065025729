import React, { ChangeEvent, ReactNode, useRef } from "react";

import { Checkbox, FormControlLabel, Radio, useRadioGroup } from "@mui/material";
import cx from "classnames";

import styles from "./RadioButton.module.scss";

interface DetailedRadioLabelProps {
  title: string;
  description: string;
  checked?: boolean;
  disabled?: boolean;
}

const DetailedRadioLabel = ({ title, description, checked, disabled }: DetailedRadioLabelProps) => (
  <div
    className={cx(styles.detailedRadioLabel, {
      [styles.detailedRadioLabel_checked]: checked,
      [styles.detailedRadioLabel_disabled]: disabled,
    })}
  >
    {checked && <span className={styles.ripple} />}
    <div className={styles.textContainer}>
      <h4 className={styles.detailedTitle}>{title}</h4>
      <p className={styles.detailedDescription}>{description}</p>
    </div>
  </div>
);

/**
 * flush - whether or not to remove left margin
 * compact - whether or not to remove bottom margin
 */
interface RadioButtonProps {
  name?: string;
  value: string;
  label?: string | ReactNode;
  title?: string;
  variant?: "primary" | "secondary" | "disabled";
  description?: string;
  disabled?: boolean;
  className?: string;
  onBlur?: (any: any) => void;
  type: "radio" | "checkbox";
  compact: boolean;
  flush: boolean;
  checked: boolean;
  onChange: (e: ChangeEvent) => unknown;
}

export const RadioButton = ({
  name,
  value,
  label,
  title,
  description,
  variant = "primary",
  disabled,
  className,
  flush,
  compact,
  type = "radio",
  onBlur,
  ...props
}: RadioButtonProps) => {
  const radioRef = useRef(null);

  const radioGroup = useRadioGroup();

  let checked = props.checked;

  if (radioGroup) {
    checked = disabled ? false : radioGroup.value === value;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(e);
    checked = disabled ? false : e.target.checked;
  };

  let labelContent;
  if (title && description)
    labelContent = (
      <DetailedRadioLabel
        title={title}
        description={description}
        checked={checked}
        disabled={disabled}
      />
    );
  else labelContent = label;

  return (
    <FormControlLabel
      classes={{
        root: cx(className, styles.radioButton, {
          [styles.radioButton_flush]: flush,
          [styles.radioButton_compact]: compact,
        }),
        label: cx(styles.label, { [styles.label_disabled]: disabled }),
      }}
      control={
        type === "checkbox" ? (
          <Checkbox
            className={cx(styles.icon, {
              [styles.icon_primary]: variant === "primary" && checked,
              [styles.icon_secondary]: variant === "secondary" && checked,
              [styles.icon_disabled]: disabled,
            })}
          />
        ) : (
          <Radio
            className={cx(styles.icon, {
              [styles.icon_primary]: variant === "primary" && checked,
              [styles.icon_secondary]: variant === "secondary" && checked,
              [styles.icon_disabled]: disabled,
            })}
          />
        )
      }
      name={name}
      value={value}
      label={labelContent}
      onChange={handleChange}
      inputRef={radioRef}
      checked={checked}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};
