import React from "react";

import { capitalize } from "lodash";

import { LoanLink } from "Components/Shared/Elements/Account/LoanLink";
import { VaultLink } from "Components/Shared/Elements/Account/VaultLink";

export const SpendingWizardTitle = ({ productType, operationType, uuid, name }) => {
  if (!operationType) return null;

  const Link = productType === "loan" ? LoanLink : VaultLink;
  return (
    <div className="inline">
      {capitalize(operationType.toLowerCase())} from{" "}
      <Link
        uuid={uuid}
        name={name}
        iconProps={{ style: { fontSize: "inherit", marginLeft: "0.3em", marginRight: "0.3em" } }}
      />
    </div>
  );
};
