import React, { useContext } from "react";

import { TableRow } from "@mui/material";

import { KeyItem } from "Components/Shared/Elements/Keys/KeyItem";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { getSelectedAccountKeysFromSigningKeys } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";

export const SigningKeysRow = () => {
  const orgUUID = useMemoizedState("vaults.vaultShow.vault.owner.uuid");
  const { signingKeys } = useContext(WithdrawModalContext);

  const selectedAccountKeys = getSelectedAccountKeysFromSigningKeys(signingKeys);

  return (
    <TableRow>
      <SummaryTableCell component="th">Signing keys</SummaryTableCell>
      <SummaryTableCell>
        <div className="flex">
          {selectedAccountKeys.map((key, index) => {
            const shared = orgUUID && key.owner.uuid !== orgUUID;
            const logoSlug = key.role === "delegate" ? key.logo_slug || key.logoSlug : "";
            return (
              <div className="pr-2" key={index}>
                <KeyItem
                  accountKey={key}
                  name={key.name}
                  shared={shared}
                  ownerName={shared && key.owner.name}
                  distinguished={key.distinguished}
                  disabled={true}
                  size="xs"
                  role={key.role}
                  logoSlug={logoSlug}
                />
              </div>
            );
          })}
        </div>
      </SummaryTableCell>
    </TableRow>
  );
};
