import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const KeyIcon = ({ viewBox = "0 0 28 28", htmlColor = "black", classes = {} }, props) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={classes}>
    <path d="M 17.625 0.1875 C 15.945313 0.1875 14.382813 0.867188 13.09375 2.15625 C 11.628906 3.621094 10.960938 5.421875 11.15625 7.34375 C 11.304688 8.773438 11.953125 10.210938 13 11.5 L 5.75 18.75 L 4.4375 17.4375 C 3.796875 16.796875 3.265625 17.328125 2.625 17.96875 L 2 18.625 C 1.359375 19.265625 0.828125 19.765625 1.46875 20.40625 L 2.78125 21.71875 L 1.75 22.75 C 1.109375 23.390625 1.109375 24.421875 1.75 25.0625 C 2.390625 25.703125 3.421875 25.703125 4.0625 25.0625 L 15.4375 13.6875 C 16.667969 14.460938 18.007813 14.875 19.3125 14.875 C 20.988281 14.875 22.554688 14.195313 23.84375 12.90625 C 25.308594 11.441406 25.976563 9.644531 25.78125 7.71875 C 25.605469 5.988281 24.6875 4.246094 23.21875 2.78125 C 21.554688 1.117188 19.574219 0.1875 17.625 0.1875 Z M 17.6875 2.25 C 19.082031 2.25 20.527344 2.960938 21.78125 4.21875 C 22.894531 5.332031 23.589844 6.617188 23.71875 7.875 C 23.851563 9.179688 23.394531 10.417969 22.375 11.4375 C 21.460938 12.347656 20.398438 12.8125 19.25 12.8125 C 17.855469 12.8125 16.410156 12.101563 15.15625 10.84375 C 14.042969 9.734375 13.347656 8.445313 13.21875 7.1875 C 13.085938 5.882813 13.542969 4.644531 14.5625 3.625 C 15.472656 2.714844 16.535156 2.25 17.6875 2.25 Z " />
  </SvgIcon>
);

KeyIcon.propTypes = {
  classNames: PropTypes.string,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
