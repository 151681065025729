import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { KEYSTORES, LedgerBitcoinInteraction, UnsupportedInteraction } from "@caravan/wallets";
import { AddressString, ModalContent, ModalFooter } from "@unchained/component-library";

import { InstructionTable } from "Components/Shared/Elements/InstructionList";
import { handleDeviceError } from "Components/SigningDevices/DeviceErrorHandling";
import { ErrorToastOptions, useErrorToast } from "Utils/toasts";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";
import { useGetConfirmAddressInteraction } from "../hooks";

export const ConfirmOnLedgerStep = () => {
  const { walletUuid, accountKey, address, relativeBip32Path, handleNext } =
    useContext(ConfirmOnDeviceContext);
  const [nextDisabled, setNextDisabled] = useState(false);
  const showErrorToast = useErrorToast();
  const interaction = useGetConfirmAddressInteraction({
    walletUuid,
    xfp: accountKey.xfp,
    relativeBip32Path,
    keystore: KEYSTORES.LEDGER,
  });

  const confirmAddress = useCallback(async () => {
    if (interaction) {
      try {
        return await interaction.run();
      } catch (e) {
        const cleanMessage = handleDeviceError(e);
        const error: ErrorToastOptions = {
          persist: false,
          title: "Something went wrong.",
          description: cleanMessage,
        };
        showErrorToast(e, error);
      }
    }
  }, [interaction, showErrorToast]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    (() => {
      // cleanup of the interaction if in progress and component dismounts
      return async () => {
        try {
          // cleanup of the interaction if in progress and component dismounts
          if (interaction && interaction instanceof LedgerBitcoinInteraction) {
            await interaction.closeTransport();
          }
        } catch (e) {
          // if this fails then another action is in progress or it was already was closed and we can
          // safely swallow and ignore
          return;
        }
      };
    }) as unknown as React.EffectCallback,
    [interaction]
  );

  const onClick = async () => {
    setNextDisabled(true);
    const deviceAddress = await confirmAddress();
    setNextDisabled(false);
    if (deviceAddress && deviceAddress === address) handleNext();
  };

  const rows = useMemo(
    () => [
      { header: "Key name", content: accountKey.name },
      { header: "Wallet UUID", content: walletUuid },
      { header: "Address", content: <AddressString address={address} /> },
    ],
    [accountKey.name, address, walletUuid]
  );

  return (
    <>
      <ModalContent>
        <InstructionTable rows={rows} />
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: nextDisabled ? "Communicating..." : "Connect to device",
            onClick,
            disabled: nextDisabled,
          },
        ]}
      />
    </>
  );
};
