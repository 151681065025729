import { combineReducers } from "redux";

import { orgsReducer as orgs } from "./orgsReducer";
import { userReducer as user } from "./userReducer";

const accountReducers = combineReducers({
  user,
  orgs,
});

export { accountReducers };
