import React from "react";

import { MenuItem, TextField } from "@mui/material";
import { useField } from "formik";

import { DestinationSelectFieldType } from "./propTypes";
import styles from "./styles.module.scss";

export const FormikDestinationSelectField = ({
  name,
  includeRedemption,
  includeTransfer,
  includeExternal = false,
  testId,
  disabled,
}) => {
  const [field, { touched, error }] = useField(name);

  const numChoices = [includeRedemption, includeTransfer, includeExternal].filter(Boolean).length;

  return (
    <TextField
      variant="outlined"
      fullWidth
      error={error && touched}
      helperText={touched ? error : null}
      select
      SelectProps={{
        required: true,
        displayEmpty: true,
        ...field,
        "data-testid": testId ? testId : null,
      }}
      InputProps={{
        classes: {
          root: styles.muiInput,
        },
      }}
      inputProps={{
        className: styles.input,
      }}
      disabled={disabled || numChoices === 1}
    >
      {numChoices !== 1 && (
        <MenuItem value="" classes={{ root: styles.menuItem }}>
          - Select -
        </MenuItem>
      )}
      {includeRedemption && (
        <MenuItem value="redemption" classes={{ root: styles.menuItem }}>
          Current redemption address
        </MenuItem>
      )}
      {includeTransfer && (
        <MenuItem value="transfer" classes={{ root: styles.menuItem }}>
          Choose vault or loan
        </MenuItem>
      )}
      {includeExternal && (
        <MenuItem value="external" classes={{ root: styles.menuItem }}>
          Enter address manually
        </MenuItem>
      )}
    </TextField>
  );
};

FormikDestinationSelectField.propTypes = DestinationSelectFieldType;
