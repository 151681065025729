import type { PolicyHmac, RootFingerprint } from "@caravan/wallets";
import { PaginationState } from "@tanstack/react-table";

import {
  DeletePolicyHmac200,
  GetFreshSlice200,
  GetSlices200,
  GetWalletAccountKeys200,
  GetWalletConfig200,
  GetWallets200,
  PutPolicyHmac200,
} from "Specs/v1";
import { GetSlicesQueryParams } from "Specs/v1/getSlices/params/query";
import { Wallet } from "Specs/v1/getWallets/200";
import { ProductType, ProductUuid } from "Specs/v1/getWallets/params/path";

import { API } from "./api";

export type WalletUuid = Wallet["uuid"];
export class WalletAPI {
  static Get = () => API.Get(`/wallet/BTC`);

  static ListForProduct = async (
    productType: ProductType,
    uuid: ProductUuid
  ): Promise<GetWallets200> => {
    const response = await API.Get(`/${productType}/${uuid}/wallets`);
    return response.data;
  };

  static GetConfig = async (walletUuid: WalletUuid): Promise<GetWalletConfig200> => {
    const response = await API.Get<GetWalletConfig200>(`/wallets/${walletUuid}/config`);
    return response.data;
  };

  static GetAccountKeys = async (walletUuid: WalletUuid): Promise<GetWalletAccountKeys200> => {
    const response = await API.Get<GetWalletAccountKeys200>(`/wallets/${walletUuid}/account_keys`);
    return response.data;
  };

  static GetFreshSlice = async (walletUuid: WalletUuid): Promise<GetFreshSlice200> => {
    const response = await API.Get<GetFreshSlice200>(`/wallets/${walletUuid}/slice`);
    return response.data;
  };

  static GetSlices = async function (
    pagination: PaginationState,
    uuid: string,
    filters: Partial<GetSlicesQueryParams> = {}
  ): Promise<GetSlices200> {
    const response: { data: GetSlices200 } = await API.Get(`/wallets/${uuid}/slices`, {
      ...filters,
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
    });
    return response.data;
  };

  static UpdatePolicyRegistration = async ({
    walletUuid,
    xfp,
    ledgerPolicyHmac,
  }: {
    walletUuid: WalletUuid;
    xfp: RootFingerprint;
    ledgerPolicyHmac: PolicyHmac;
  }): Promise<PutPolicyHmac200> => {
    const response = await API.Put<PutPolicyHmac200>(`/wallets/${walletUuid}/config/${xfp}`, {
      ledgerPolicyHmac,
    });

    return response.data;
  };

  static DeletePolicyRegistration = async ({
    walletUuid,
    xfp,
  }: {
    walletUuid: WalletUuid;
    xfp: RootFingerprint;
  }): Promise<DeletePolicyHmac200> => {
    // change this to Delete when available
    const response = await API.Delete<PutPolicyHmac200>(`/wallets/${walletUuid}/config/${xfp}`);

    return response.data;
  };

  static GetKeys = async (walletUuid: WalletUuid): Promise<unknown> => {
    const response = await API.Get(`/wallets/${walletUuid}/keys`);
    return response.data;
  };
}
