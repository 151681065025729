import React, { useContext, useEffect, useMemo, useState } from "react";

import { AddressString, ModalContent, ModalFooter } from "@unchained/component-library";

import { InstructionList, InstructionTable } from "Components/Shared/Elements/InstructionList";
import { SupportEmailLink } from "Components/Shared/Elements/Links";
import { useGetWalletConfig } from "Shared/api";

import { getColdcardConfigUuid } from "../../../../utils/downloadColdcardConfig";
import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";

const steps = [
  { title: "Select “Address Explorer”", subtitle: "Ensure you have popups enabled." },
  {
    title: `Read the instructions and press option "4"`,
  },
  {
    title: "Locate your vault",
    subtitle: "Scroll down the list of address explorer options until you find your vault ID.",
  },
  {
    title: "Find address and confirm",
    subtitle: (
      <>
        Verify that the address on your Coldcard matches the one shown below. In the event of a
        mismatch, <span className="font-bold">do not proceed</span>; contact <SupportEmailLink />.
      </>
    ),
  },
];

export const ConfirmOnColdcardOverviewStep = () => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const { address, relativeBip32Path, handleNext, walletUuid, productUuid } =
    useContext(ConfirmOnDeviceContext);
  const walletConfig = useGetWalletConfig(walletUuid);
  const ccWalletId = useMemo(
    () => getColdcardConfigUuid(walletConfig.data, productUuid),
    [walletConfig.data, productUuid]
  );
  const rows = useMemo(
    () => [
      {
        header: "Wallet ID",
        content: ccWalletId,
      },
      {
        header: "Address index",
        content: `.../${relativeBip32Path.split("/").join("/")}`,
      },
      {
        header: "Address",
        content: <AddressString address={address} />,
      },
    ],
    [address, ccWalletId, relativeBip32Path]
  );

  // enable next after a 3 second timeout
  useEffect(() => {
    setTimeout(() => setNextDisabled(false), 3000);
  }, []);

  return (
    <>
      <ModalContent>
        <InstructionList steps={steps} />
        <InstructionTable rows={rows} className="mt-6" />
      </ModalContent>
      <ModalFooter actions={[{ children: "Next", onClick: handleNext, disabled: nextDisabled }]} />
    </>
  );
};
