export const getOutputBucketsFromTxOutputs = (
  transactionOutputs: {
    address?: string;
    product?: { product_type: string; uuid: string };
    amount?: number;
  }[],
  accountType: string,
  accountUUID: string
) => {
  let changeOutputs = [],
    productOutputs = [],
    externalOutputs = [];
  for (const output of transactionOutputs) {
    const relatedProductExists = Boolean(output.product) && Boolean(output.product.product_type);
    if (
      relatedProductExists &&
      output.product.product_type === accountType &&
      output.product.uuid === accountUUID
    ) {
      changeOutputs.push(output);
    } else if (relatedProductExists) {
      productOutputs.push(output);
    } else {
      externalOutputs.push(output);
    }
  }
  return { changeOutputs, productOutputs, externalOutputs };
};
