import React from "react";

import { BasicPage } from "Components/Pages";

export class ServerError extends React.Component {
  render = () => (
    <BasicPage narrow title="Server error">
      <div className="prose">
        <h3>We just experienced an error preventing us from rendering this page.</h3>

        <p>
          Our engineering staff actively monitors this application for errors and they will be
          investigating this one promptly.
        </p>

        <p>Please try your request again later.</p>
      </div>
    </BasicPage>
  );
}
