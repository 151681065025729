import { getConfig } from "./config";
import { parseTimestamp, readableTime, parseDate, readableDate } from "./time";

export const userLabel = user => {
  if (!Boolean(user)) {
    return "User";
  }
  if (Boolean(user.addressed_as)) {
    return user.addressed_as;
  }
  if (Boolean(user.name)) {
    return user.name;
  }
  if (Boolean(user.codename)) {
    return user.codename;
  }
  if (Boolean(user.uuid)) {
    return user.uuid;
  }
  return "User";
};

export const orgLabel = org => {
  if (!Boolean(org)) {
    return "Account";
  }
  if (org.personal) {
    if (Boolean(org.addressed_as)) return org.addressed_as;
    return userLabel(org.owner);
  } else {
    if (Boolean(org.name)) {
      return org.name;
    }
    if (Boolean(org.codename)) {
      return org.codename;
    }
    if (Boolean(org.uuid)) {
      return org.uuid;
    }
    return "Account";
  }
};

export const orgLegalLabel = org => {
  if (!Boolean(org)) {
    return "Account";
  }
  if (org.personal) {
    return userLabel(org.person);
  } else {
    if (Boolean(org.entity && org.entity.name)) {
      return org.entity.name;
    }
    if (Boolean(org.codename)) {
      return org.codename;
    }
    if (Boolean(org.uuid)) {
      return org.uuid;
    }
    return "Account";
  }
};

export const accountKeyLabel = account_key => {
  if (!Boolean(account_key)) {
    return "Key";
  }
  if (account_key.role === "unchained") {
    return "Unchained";
  }
  if (account_key.role === "arbiter") {
    return getConfig("arbiter").name;
  }
  if (Boolean(account_key.name)) {
    return account_key.name;
  }
  if (Boolean(account_key.uuid)) {
    return `Key ${account_key.uuid}`;
  }
  return "Key";
};

export const vaultLabel = vault => {
  if (!Boolean(vault)) {
    return "Vault";
  }
  if (Boolean(vault.name)) {
    return vault.name;
  }
  if (Boolean(vault.uuid)) {
    return `Vault ${vault.uuid}`;
  }
  return "Vault";
};

export const loanLabel = loan => {
  if (!Boolean(loan)) {
    return "Loan";
  }
  if (Boolean(loan.uuid)) {
    return `Loan ${loan.uuid}`;
  }
  return "Loan";
};

export const timeLabel = (time, short) => {
  if (Boolean(time)) {
    return readableTime(parseTimestamp(time), short);
  } else {
    return null;
  }
};

export const dateLabel = date => {
  if (Boolean(date)) {
    return readableDate(parseDate(date));
  } else {
    return null;
  }
};

export const operationLabel = operation => {
  return operation.description;
};
