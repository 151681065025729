import React, { ReactNode } from "react";

import { ModalHeader, ModalTitle } from "@unchained/component-library";

import styles from "./TradeModalContainer.module.scss";

interface TradeModalContainerProps {
  children?: ReactNode;
  title: string | JSX.Element;
  onBack?: () => void;
  showBackArrow?: boolean;
  containerClassName?: string;
  dismissButtonClassName?: string;
}

/**
 * Component container for Trading modals.
 * @param  children - Components to be displayed in the Modal container.
 * @param title - Modal title.
 * @param  onBack - Function run when back arrow clicked.
 * @param showBackArrow - Boolean to display a back arrow or not.
 * @param containerClassName - String css className for outer container.
 * @param dismissButtonClassName -  String css className for dismiss button classname
 * @returns {React.Component}
 */
const TradeModalContainer = ({
  children,
  title,
  onBack,
  showBackArrow = false,
  containerClassName = styles.container,
  dismissButtonClassName,
}: TradeModalContainerProps) => {
  const backArrowProps = showBackArrow
    ? { onClick: onBack, text: "Back", className: styles.backArrow }
    : undefined;

  return (
    <div className={containerClassName}>
      <ModalHeader dismissButtonClassName={dismissButtonClassName} back={backArrowProps}>
        <ModalTitle className={styles.title}>{title}</ModalTitle>
      </ModalHeader>
      {children}
    </div>
  );
};

export { TradeModalContainer };
