import { satoshisToBitcoins } from "@caravan/bitcoin";
import Big from "big.js";

import { getEstimatedFees } from "Components/VaultsView/Show/WithdrawModal/functions";
// These probably belong in a different place if this shared component is
// importing them from a specific component.
import { BatchSpendEntryType } from "Components/VaultsView/Show/WithdrawModal/types";
import { BITCOIND_MAX_FEE_SATS } from "Utils/constants";

import { BatchError } from "./types";

// This limits the number of destination outputs.
export const BATCH_DESTINATION_LIMIT = 500;

/**
 * Sums a list of bitcoin amount strings. Skips values which are not numbers or
 * numeric strings. Returns the sum as a number.
 *
 * @param amounts string[] of bitcoin amounts
 * @returns number
 */
export const getBtcTotal = (amounts: string[]) => {
  return amounts
    .map(el => {
      try {
        return Big(el);
      } catch (err) {
        // probably NaN. This is an error row. Assume 0 since it has to be fixed
        // anyway.
        return Big("0");
      }
    })
    .reduce((sum, amount) => {
      try {
        return Big(sum).add(amount).toNumber();
      } catch (err) {
        // Something weird went wrong. Invalid numbers were coming
        // through when testing against a binary document pretending to be a
        // csv.
        return sum;
      }
    }, 0);
};

/**
 * Checks for: Output count gt BATCH_DESTINATION_LIMIT, total btc exceeds
 * product balance + fast estimated fee, or if the individual rows have errors
 * already assigned to them (see BatchError type).
 *
 * @param batchData BatchSpendEntryType[]
 * @param product \{ balance: number; wallet: { deposited_utxo_count } } usually
 * from vault or loan state
 * @param fastFeeRate number
 * @returns BatchError[]
 */
export const validateBatchSpendTableData = (
  batchData: BatchSpendEntryType[],
  product: { balance: number; wallet: { deposited_utxo_count } },
  fastFeeRate: number
) => {
  const btcTotal = getBtcTotal(batchData.map(el => el.amount.value));
  // There may be an additional output added on the transaction for change.
  const batchOutputCount = batchData.length;

  const estimatedFeesSats = getEstimatedFees({
    inputCount: product.wallet.deposited_utxo_count || 0,
    feeRate: String(fastFeeRate), // Fees haven't been selected yet. Assume high range.
    isMaxSpend: false, // Batch spends can't be max spends yet
    isBatchSpendFlow: true, // It better be if we got this far.
    batchData,
  });
  const estimatedFeesBtc = satoshisToBitcoins(estimatedFeesSats);

  let errors: BatchError[] = [];

  // Case: Too many outputs
  if (batchOutputCount > BATCH_DESTINATION_LIMIT) {
    errors.push(BatchError.TOO_MANY_OUTPUTS);
  }

  // Case: Exceeds bitcoind max fee limit
  if (Big(estimatedFeesSats).gt(BITCOIND_MAX_FEE_SATS)) {
    errors.push(BatchError.EXCEEDS_BITCOIND_MAX_FEE);
  }

  // Case: Total exceeds product balance + fast fees
  if (Big(btcTotal).plus(estimatedFeesBtc).gt(product.balance)) {
    errors.push(BatchError.BALANCE_EXCEEDED);
  }

  // Case: Has row errors
  if (batchData.find(el => el.address.error || el.amount.error)) {
    errors.push(BatchError.ROW_ERRORS);
  }

  return errors;
};
