import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const KeyIcon3 = (
  { viewBox = "0 0 37 17", htmlColor = "#FFF", classes = { root: "h-[17px] w-[37px]" } },
  props
) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={classes}>
    <path
      d="M37 7.81793L37 9.16273C37 9.53594 36.6982 9.83837 36.3258 9.83837L33.4299 9.83837C32.6915 9.83837 32.0879 10.4432 32.0879 11.1832L32.0879 16.112C32.0879 16.3565 31.8888 16.5624 31.6384 16.5624L29.8469 16.5624C29.6029 16.5624 29.3975 16.363 29.3975 16.112L29.3975 14.6771C29.3975 13.5832 28.582 12.6181 27.4968 12.5409C26.874 12.4958 26.3025 12.7339 25.898 13.1393C25.5256 13.5125 25.3008 14.0272 25.3008 14.5935L25.3008 16.112C25.3008 16.3565 25.1018 16.5624 24.8514 16.5624L23.0599 16.5624C22.8159 16.5624 22.6104 16.363 22.6104 16.112L22.6104 11.1832C22.6104 10.4432 22.0068 9.83837 21.2684 9.83837L15.0575 9.83837C13.7091 9.83837 12.5083 10.707 12.0845 11.9939C11.1085 14.9667 8.91892 17 6.31196 17C2.77396 17 8.50827e-05 13.2615 8.46664e-05 8.49998C8.42496e-05 3.73201 2.77396 2.99214e-06 6.31196 2.68283e-06C8.91891 2.45493e-06 11.115 2.03331 12.0845 5.00605C12.5083 6.29295 13.7026 7.16161 15.0575 7.16161L36.3194 7.16161C36.6918 7.16161 36.9936 7.46403 36.9936 7.83723L37 7.81793ZM9.95274 8.49354C9.95274 5.34063 8.28966 2.68318 6.32482 2.68318C4.35997 2.68318 2.69694 5.34063 2.69694 8.49354C2.69694 11.6465 4.35997 14.3039 6.32482 14.3039C8.29608 14.3039 9.95274 11.6465 9.95274 8.49354Z"
      fill="white"
    />
  </SvgIcon>
);

KeyIcon3.propTypes = {
  classNames: PropTypes.string,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
