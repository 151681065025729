import { ClientErrorAPI } from "Shared/api/clientErrorAPI";

export const postRenderErrorAction = (error, info) => {
  return async () => {
    try {
      await ClientErrorAPI.reportRenderError(error, info);
    } catch (e) {
      console.log(e.response);
    }
  };
};

export const postDeviceErrorAction = (error, contextInfo) => {
  return async () => {
    try {
      await ClientErrorAPI.reportDeviceError(error, contextInfo);
    } catch (e) {
      console.log(e.response);
    }
  };
};
