// Needed for the way we spy in PendingOperations test and MarginCallCountdown test
// https://stackoverflow.com/questions/67872622/jest-spyon-not-working-on-index-file-cannot-redefine-property
import { useCountdownTimer as configurableUseCountdownTimer } from "./useCountdownTimer";
import { useGetOperationBtcTxReq as configurableUseGetOperation } from "./useGetOperationBtcTxReq";

export * from "./useActions";

export * from "./useBreakpoint";
export * from "./useDidUpdate";
export * from "./useHover";
export * from "./useHtmlTitleTag";
export * from "./useIncrementIndexTimer";
export * from "./useIsMounted";
export * from "./useOnClickOutside";
export * from "./usePagination";
export * from "./usePopover";
export * from "./usePrevious";
export * from "./useWindowDimensions";
export { useUserHasRole, useUserIsViewer, useUserIsViewerNotAdmin } from "./useUserHasRole";

export const useGetOperationBtcTxReq = configurableUseGetOperation;

export const useCountdownTimer = configurableUseCountdownTimer;
