import { set } from "lodash";
import { ValueOf } from "type-fest";

import { REQUEST_STATUS } from "Shared/api/api";
import { Verification } from "Specs/v1/getAccount/200";
import { TransactionRequest } from "Specs/v1/getTransactionRequests/200";

import {
  RESET_SPENDING_WIZARD,
  SET_SPENDING_BROADCAST_STATUS,
  SET_SPENDING_DATA,
  SET_SPENDING_DEVICE_SPECIFIC_UNSIGNED_PSBT,
  SET_SPENDING_REQUEST_STATUS,
  SET_SPENDING_SELECTED_SIG_REQUEST,
  SET_SPENDING_SELECTED_SIG_REQUEST_ACTIVE,
  SET_SPENDING_SELECTED_SIG_REQUEST_REQUESTED,
  SET_SPENDING_SIG_REQUEST,
  SET_VERIFICATION_STATE_PENDING_PROCESSING,
  SET_VERIFICATION_STATE_PENDING_RECORDING,
} from "../../actions/transactionActions/spendingActions";

export type SpendingApproval = {
  approver: {
    name: string;
    uuid: string;
  };
  created_at: string;
};

export type SpendingOperation = {
  uuid: string;
  verification_required: boolean;
  btc_transaction_requests: TransactionRequest[];
  verification: Verification | { state: string; context: string[] };
  type?: string;
  state?: string;
  allowed_actions?: string[];
  description?: string;
  source_wallet_uuid?: string;
  loan?: {
    uuid: string;
  };
  vault?: {
    uuid: string;
  };
};

type SpendingState = {
  operation: SpendingOperation;
  status: ValueOf<typeof REQUEST_STATUS>;
  selectedSigRequestUUID: string;
  selectedSigRequestActive: boolean;
  deviceSpecificUnsignedPSBT: string;
};

const initialState: SpendingState = {
  operation: {
    uuid: "",
    verification_required: false,
    btc_transaction_requests: [],
    verification: {
      context: [],
      state: "",
    },
  },
  status: REQUEST_STATUS.PENDING,
  selectedSigRequestUUID: "",
  selectedSigRequestActive: false,
  deviceSpecificUnsignedPSBT: "",
};

type SpendingAction = {
  type:
    | typeof RESET_SPENDING_WIZARD
    | typeof SET_SPENDING_BROADCAST_STATUS
    | typeof SET_SPENDING_DATA
    | typeof SET_SPENDING_REQUEST_STATUS
    | typeof SET_SPENDING_SELECTED_SIG_REQUEST
    | typeof SET_SPENDING_SELECTED_SIG_REQUEST_ACTIVE
    | typeof SET_SPENDING_SELECTED_SIG_REQUEST_REQUESTED
    | typeof SET_SPENDING_DEVICE_SPECIFIC_UNSIGNED_PSBT
    | typeof SET_SPENDING_SIG_REQUEST
    | typeof SET_VERIFICATION_STATE_PENDING_RECORDING
    | typeof SET_VERIFICATION_STATE_PENDING_PROCESSING;
  payload: any;
};
export const spendingReducer = (state = initialState, action: SpendingAction) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SPENDING_DATA: {
      return {
        ...state,
        ...{ operation: payload },
      };
    }

    case SET_SPENDING_REQUEST_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case SET_SPENDING_SIG_REQUEST: {
      let sigReqInd;
      // Find the index of the transaction request with this sig request
      // and the index of the sig request within it
      const txReqIndex = state.operation.btc_transaction_requests.findIndex(req => {
        if (isNaN(sigReqInd)) {
          sigReqInd = req.signature_requests.findIndex(req => req.uuid === payload.uuid);
        }
        return !isNaN(sigReqInd);
      });

      const newTxRequests = set(
        state.operation.btc_transaction_requests,
        `[${txReqIndex}].signature_requests[${sigReqInd}]`,
        payload
      );

      return {
        ...state,
        operation: {
          ...state.operation,
          btc_transaction_requests: newTxRequests,
        },
      };
    }

    case SET_SPENDING_SELECTED_SIG_REQUEST: {
      return {
        ...state,
        selectedSigRequestUUID: payload.uuid,
      };
    }

    case SET_SPENDING_SELECTED_SIG_REQUEST_ACTIVE: {
      return {
        ...state,
        selectedSigRequestActive: payload,
      };
    }

    case SET_SPENDING_SELECTED_SIG_REQUEST_REQUESTED: {
      return {
        ...state,
        operation: {
          ...state.operation,
          btc_transaction_requests: state.operation.btc_transaction_requests.map(
            transactionRequest => {
              return {
                ...transactionRequest,
                signature_requests: transactionRequest.signature_requests.map(sigRequest => {
                  if (sigRequest.uuid === payload.uuid) {
                    return {
                      ...sigRequest,
                      requested: payload.requested,
                    };
                  } else {
                    return sigRequest;
                  }
                }),
              };
            }
          ),
        },
      };
    }

    case SET_SPENDING_BROADCAST_STATUS: {
      return {
        ...state,
        broadcastStatus: payload,
      };
    }

    case SET_SPENDING_DEVICE_SPECIFIC_UNSIGNED_PSBT: {
      return {
        ...state,
        deviceSpecificUnsignedPSBT: payload,
      };
    }

    case RESET_SPENDING_WIZARD: {
      return {
        ...initialState,
      };
    }

    case SET_VERIFICATION_STATE_PENDING_PROCESSING: {
      const operation = { ...state.operation };
      const verification = { ...operation.verification, state: "pending_processing" };

      return {
        ...state,
        operation: {
          ...operation,
          verification: verification,
        },
      };
    }

    case SET_VERIFICATION_STATE_PENDING_RECORDING: {
      const operation = { ...state.operation };
      const verification = { ...operation.verification, state: "pending_recording" };

      return {
        ...state,
        operation: {
          ...operation,
          verification: verification,
        },
      };
    }

    default:
      return state;
  }
};
