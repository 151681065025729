import { Button, ButtonProps } from "@unchained/component-library";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { setOverlay } from "Actions/overlayActions";
import { useNavigationDispatch } from "Contexts/Navigation";
import { openAppModal } from "Shared/components/Modals/AppModal";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { TradingOnboardingCard } from "./TradingOnboarding/TradingOnboardingCard";

interface HighlightOnboardingIncompleteCardButtonProps extends Partial<ButtonProps> {
  mobile: boolean;
  currentOrg: CompleteOrg;
}

const HighlightOnboardingIncompleteCardButton = ({
  mobile,
  currentOrg,
  ...props
}: HighlightOnboardingIncompleteCardButtonProps) => {
  const { setDrawerOpen } = useNavigationDispatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const highlightOnboardingIncompleteCard = () => {
    const onboardingCard = document.getElementById("trading-onboard-card");

    dispatch(setOverlay(true));

    // scroll to the onboarding card
    onboardingCard?.scrollIntoView({ behavior: "smooth" });
    if (mobile) {
      setDrawerOpen(false);
    }

    // remove the overlay after 3 seconds
    setTimeout(() => {
      dispatch(setOverlay(false));
    }, 3000);
  };
  const isHomepage = location.pathname === "/home";

  return (
    <Button
      {...props}
      onClick={() => {
        // On homepage, highlight the onboarding card
        if (isHomepage) {
          highlightOnboardingIncompleteCard();
        } else {
          // Off homepage, there is no onboarding card, so show it in a modal instead
          openAppModal(<TradingOnboardingCard currentOrg={currentOrg} />);
        }
      }}
      data-testid="HighlightOnboardingIncompleteCardButton"
    >
      {props.children ? props.children : "Start trading"}
    </Button>
  );
};

export { HighlightOnboardingIncompleteCardButton };
