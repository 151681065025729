import { OrgQuorumMinApprovalsChangeProposal } from "Shared/api/changeProposalApi";

import { TwoBoxChange } from "./utils";

export function OrgQuorumMinApprovalsChange({
  changeProposal,
}: {
  changeProposal: OrgQuorumMinApprovalsChangeProposal;
}) {
  return (
    <div>
      <h3 className="mb-2">Quorum size change</h3>

      <TwoBoxChange
        current={{ title: `${changeProposal.current} approvals`, caption: "Current" }}
        target={{ title: `${changeProposal.minApprovals} approvals`, caption: "Target" }}
      />
    </div>
  );
}
