var initialState = {
  message: "",
  level: "info",
  duration: 6000,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "FLASH/NEW_MESSAGE":
      return { ...state, ...action };

    case "FLASH/CLOSE_MESSAGE":
      return initialState;

    default:
      return state;
  }
};
