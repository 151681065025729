import {
  bitcoinsToSatoshis,
  estimateMultisigTransactionFee,
  validateOutputAmount,
} from "@caravan/bitcoin";
import Big from "big.js";
import BigNumber from "bignumber.js";
import Papa from "papaparse";

import { SigningKey } from "Components/Transactions/ConfirmSigningKeys";
import { AccountKey } from "Components/VaultsView/types";
import { trefoilStore } from "Redux/store";
import { getConfig } from "Utils/config";
import { validateBitcoinAddress } from "Utils/validation";
import { outflowIsPassedThreshold } from "Utils/vaultUtils";

import { BatchSpendEntryType, IWithdrawModalContext } from "./types";

// This is the data used to construct the sample template CSV. The object
// attribute names will be used for header column names.
const sampleData = [
  { Address: "2Mt8PYmhuucr4eqRpaNpSLJei1rtU8BjTKo", "Amount (BTC)": "4.34561287" },
  { Address: "2Mtjps1o3GkBg3SLgwVKGCJsYL4zhSKDbYr", "Amount (BTC)": "12.98531274" },
  { Address: "2MtqY6QhLJhM4ou1uwPfUpe4PnPHBPyQtj1", "Amount (BTC)": "0.58643218" },
];

const validateAddress = async (address: string, data) => {
  if (data.filter(el => el[0] === address).length > 1) {
    return "Duplicate address";
  }

  const vault = trefoilStore.getState().vaults.vaultShow.vault;
  return await validateBitcoinAddress(address);
};

const validateDecimalPlaces = (value: string | number) => {
  if (!isNaN(Number(value)) && String(value).split(".", 2)[1]?.length > 8) {
    return "The bitcoin amount has a precision greater than 8 decimal places.";
  }
  return "";
};

const transformBatchData = async (data: any): Promise<BatchSpendEntryType[]> => {
  const DUST_LIMIT_SATS = new BigNumber(bitcoinsToSatoshis(getConfig("wallet.dust_limit.BTC")));

  let validEntries: BatchSpendEntryType[] = [];

  // If it has a header row, remove it.
  // Papaparse gives the option to segregate a header row, but handling it here
  // instead should allow for headerless CSVs to be accepted. This check assumes
  // it's a header row if the first value matches the first key on sampleData.
  if (data[0][0] === Object.keys(sampleData[0])[0]) {
    data.shift();
  }

  for (const el of data) {
    const addressError = await validateAddress(el[0], data);
    const amountError =
      validateOutputAmount(
        bitcoinsToSatoshis(el[1]),
        bitcoinsToSatoshis(100000),
        DUST_LIMIT_SATS.toString()
      ) || validateDecimalPlaces(el[1]);

    if (addressError || amountError) {
      // Put the errored rows first
      validEntries.unshift({
        address: { value: el[0], error: addressError },
        amount: { value: el[1], error: amountError },
      });
    } else {
      validEntries.push({
        address: { value: el[0] },
        amount: { value: el[1] },
      });
    }
  }

  return validEntries;
};

const getOutputCount = (
  isMaxSpend: boolean,
  isBatchSpendFlow: boolean,
  batchData: BatchSpendEntryType[]
) => {
  if (isBatchSpendFlow) {
    return batchData.length + 1; // +1 for change output.
  }

  return isMaxSpend ? 1 : 2; // No change output with a max spend.
};

export const genCsvTemplateContents = () => {
  return btoa(Papa.unparse(sampleData));
};

export const getDollarValue = (btcAmount: string | number, usdPrice: number) => {
  try {
    return Big(btcAmount).mul(usdPrice).toNumber();
  } catch (err) {
    // probably NaN.
    return null;
  }
};

/**
 * Returns the estimated fee amount (in satoshis) based on input
 * @param \{
    inputCount: number;
    feeRate: string;
    isMaxSpend: boolean;
    isBatchSpendFlow: boolean;
    batchData: BatchSpendEntryType[];
  }
 * @returns string. amount in satoshis.
 */
export const getEstimatedFees = ({
  inputCount,
  feeRate,
  isMaxSpend,
  isBatchSpendFlow,
  batchData,
}: {
  inputCount: number;
  feeRate: string;
  isMaxSpend: boolean;
  isBatchSpendFlow: boolean;
  batchData: BatchSpendEntryType[];
}): string => {
  const numInputs = inputCount;
  const numOutputs = getOutputCount(isMaxSpend, isBatchSpendFlow, batchData);
  const feesPerByteInSatoshis = feeRate;

  if (isNaN(parseInt(feesPerByteInSatoshis))) {
    return "0";
  }

  return estimateMultisigTransactionFee({
    addressType: "P2SH",
    m: 2,
    n: 3,
    numInputs,
    numOutputs,
    feesPerByteInSatoshis,
  });
};

export const handleFileDrop = async (
  files: any, // Dropzone files
  callback: (data: BatchSpendEntryType[]) => void
) => {
  if (!files[0]) {
    return;
  }
  const contents = await files[0].text();
  const { data } = Papa.parse(contents, { skipEmptyLines: true });

  const transformedData = await transformBatchData(data);

  callback(transformedData);
};

/**
 * Determines if the transaction will need a video verification from the product
 * owner in the case that they have set a verification threshold.
 * @param amount string
 * @param vault any
 * @param accountKeys AccountKey[]
 * @param signingKeys SigningKey[]
 * @returns boolean
 */
export const isVerificationRequired = (
  amount: string,
  vault: any,
  accountKeys: AccountKey[],
  signingKeys: SigningKey[]
) => {
  if (signingKeys.length === 0) {
    return false; // The keys haven't been initialized yet.
  }
  const unchainedSigningKeys = signingKeys.filter(
    key => accountKeys.find(accountKey => key.uuid === accountKey.uuid).role === "unchained"
  );
  const unchainedAskedToSign = unchainedSigningKeys.some(key => key.active);
  return unchainedAskedToSign && outflowIsPassedThreshold(vault, { cryptoAmount: Number(amount) });
};

export const getSelectedAccountKeysFromSigningKeys = (signingKeys: SigningKey[]) => {
  const accountKeys: AccountKey[] =
    trefoilStore.getState().vaults.vaultShow.vault.current_account_keys;
  return signingKeys
    .filter(key => key.active)
    .map(chosenKey => accountKeys.find(key => key.uuid === chosenKey.uuid));
};

export const getUsdAmountFromBtc = (btcAmount: string, usdPrice: number) => {
  const dollarValue = getDollarValue(btcAmount, usdPrice);
  if (dollarValue === null) {
    return "";
  }
  return dollarValue.toFixed(2);
};

export const getBtcAmountFromUsd = (usdAmount: string, usdPrice: number) => {
  if (usdAmount === "") {
    return "";
  }
  return Big(usdAmount).div(usdPrice).toFixed(8);
};
