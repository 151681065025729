import { useEffect, useState } from "react";

/**
 * Custom hook that will return current screen width and height, updating when screen resizes.
 * @param {object}
 * width - number, current screen width in pxs
 * height - number, current screen height in pxs
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
