export const VaultCustodyEnum = {
  USER_CONTROLLED: "USER_CONTROLLED",
  MULTI_INST: "MULTI_INST",
};

export const SET_NEW_VAULT_NAME = "SET_NEW_VAULT_NAME";
export const SET_NEW_VAULT_CUSTODY_MODEL = "SET_NEW_VAULT_CUSTODY_MODEL";
export const RESET_NEW_VAULT_FLOW = "RESET_NEW_VAULT_FLOW";
export const SET_NEW_VAULT_USER_KEY_1 = "SET_NEW_VAULT_USER_KEY_1";
export const SET_NEW_VAULT_USER_KEY_2 = "SET_NEW_VAULT_USER_KEY_2";
export const SET_NEW_VAULT_UNCHAINED_ROLE = "SET_NEW_VAULT_UNCHAINED_ROLE";
export const SET_NEW_VAULT_ARBITER_ROLE = "SET_NEW_VAULT_ARBITER_ROLE";
export const SET_NEW_VAULT_SELECTING_KEY = "SET_NEW_VAULT_SELECTING_KEY";
export const SET_NEW_VAULT_ORG = "SET_NEW_VAULT_ORG";

/**
 * Set Vault Name action in New Vault Flow
 * @param vaultName - User input vault name, default is generated
 */
export const setVaultNameAction = vaultName => ({
  type: SET_NEW_VAULT_NAME,
  payload: vaultName,
});

export const setVaultCustodyModelAction = vaultCustodyModel => ({
  type: SET_NEW_VAULT_CUSTODY_MODEL,
  payload: vaultCustodyModel,
});

export const setNewVaultUserKey1Action = fields => ({
  type: SET_NEW_VAULT_USER_KEY_1,
  payload: fields,
});

export const setNewVaultUserKey2Action = fields => ({
  type: SET_NEW_VAULT_USER_KEY_2,
  payload: fields,
});

export const setNewVaultUnchainedKeyRoleAction = fields => ({
  type: SET_NEW_VAULT_UNCHAINED_ROLE,
  payload: fields,
});

export const setNewVaultArbiterKeyRoleAction = fields => ({
  type: SET_NEW_VAULT_ARBITER_ROLE,
  payload: fields,
});

export const setNewVaultSelectingKeyAction = key => ({
  type: SET_NEW_VAULT_SELECTING_KEY,
  payload: key,
});

export const setNewVaultOrgAction = org => ({
  type: SET_NEW_VAULT_ORG,
  payload: org,
});

export const resetNewVaultFlowAction = () => ({
  type: RESET_NEW_VAULT_FLOW,
  payload: null,
});

export const setKeysBasedOnVaultCustodyModelAction = vaultCustodyModel => {
  return dispatch => {
    switch (vaultCustodyModel) {
      case VaultCustodyEnum.USER_CONTROLLED: {
        dispatch(setNewVaultUserKey2Action({ required: true, signingRole: "cosigner" }));
        dispatch(setNewVaultUnchainedKeyRoleAction({ signingRole: "backup" }));
        dispatch(setNewVaultArbiterKeyRoleAction({ required: false }));
        break;
      }

      case VaultCustodyEnum.MULTI_INST: {
        dispatch(setNewVaultUserKey2Action({ required: false }));
        dispatch(setNewVaultUnchainedKeyRoleAction({ signingRole: "cosigner" }));
        dispatch(setNewVaultArbiterKeyRoleAction({ required: true }));
        break;
      }

      default:
        break;
    }
  };
};
