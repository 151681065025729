import React, { useContext } from "react";

import { satoshisToBitcoins } from "@caravan/bitcoin";
import { Table, TableBody, TableRow } from "@mui/material";
import { Banner } from "@unchained/component-library";

import { AccountNameCell } from "Components/Shared/Elements/Summary/AccountNameCell";
import { BalanceRow } from "Components/Shared/Elements/Summary/BalanceRow";
import { CryptoAmountsCell } from "Components/Shared/Elements/Summary/CryptoAmountsCell";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import {
  getDollarValue,
  getEstimatedFees,
  isVerificationRequired,
} from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";
import { useFeeRates } from "Shared/api/hooks/fees";
import { formatCurrency } from "Utils/strings";

import { SigningKeysRow } from "./SigningKeysRow";
import { ToDestinationsCell } from "./ToDestinationsCell";

export const TransactionSummary = () => {
  const vault = useMemoizedState("vaults.vaultShow.vault");
  const prices = useMemoizedState("crypto.prices.current");
  const {
    amount,
    batchData,
    feeRate,
    isBatchSpendFlow,
    isMaxSpend,
    signingKeys,
    submissionErrorMessage,
  } = useContext(WithdrawModalContext);
  const { recommended } = useFeeRates();

  const usdPrice = prices?.BTC?.value || 0;
  const availableBtcFormatted = formatCurrency(vault.deposited_balance, 8, true, false);
  const usdValue = getDollarValue(amount, usdPrice);
  const usdAmountFormatted = formatCurrency(usdValue, 2);
  const btcAmountFormatted = formatCurrency(amount, 8, true, false);

  const estimatedFeeSats = getEstimatedFees({
    inputCount: vault.wallet.deposited_utxo_count || 0,
    feeRate: feeRate || String(recommended),
    isMaxSpend,
    isBatchSpendFlow,
    batchData,
  });
  const estimatedFeeBitcoin = satoshisToBitcoins(estimatedFeeSats);
  const estimatedFeeFormatted = formatCurrency(estimatedFeeBitcoin, 8, true, false);
  const estimatedFeeDollars = getDollarValue(estimatedFeeBitcoin, usdPrice);
  const usdFeeFormatted = formatCurrency(estimatedFeeDollars, 2, false, true);

  const verificationRequired = isVerificationRequired(
    amount,
    vault,
    vault.current_account_keys,
    signingKeys
  );

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <SummaryTableCell className="!w-36" component="th">
              From
            </SummaryTableCell>
            <AccountNameCell
              name={vault.name}
              uuid={vault.uuid}
              product_type={vault.product_type}
            />
          </TableRow>
          <BalanceRow
            account={{
              product_type: "vault",
              confirmedBalanceWithUnit: `${availableBtcFormatted} BTC`,
              fiatDisplayed: false,
            }}
            prices={prices}
          />
          {vault.wallet_type !== "singleorg" && <SigningKeysRow />}
        </TableBody>
      </Table>
      <br />
      <Table>
        <TableBody>
          <TableRow>
            <SummaryTableCell className="!w-36" component="th">
              To
            </SummaryTableCell>
            <ToDestinationsCell />
          </TableRow>
          <TableRow>
            <SummaryTableCell component="th">Amount</SummaryTableCell>
            <CryptoAmountsCell
              crypto={`${btcAmountFormatted} BTC`}
              fiat={`$${usdAmountFormatted}`}
            />
          </TableRow>
          <TableRow>
            <SummaryTableCell component="th">Fee estimate</SummaryTableCell>
            <CryptoAmountsCell
              crypto={`${estimatedFeeFormatted} BTC`}
              fiat={`$${usdFeeFormatted}`}
            />
          </TableRow>
          <TableRow>
            <SummaryTableCell component="th">Fee rate (sat/vB)</SummaryTableCell>
            <SummaryTableCell>{feeRate || String(recommended)}</SummaryTableCell>
          </TableRow>
        </TableBody>
      </Table>
      {submissionErrorMessage !== "" && (
        <div className="block min-h-[1.125rem] text-center text-xs text-red-500">
          {submissionErrorMessage}
        </div>
      )}
      {verificationRequired && (
        <>
          <br />
          <Banner
            type="tip"
            title="This transaction exceeds the threshold set"
            className="text-left"
          >
            For security reasons, you will have to record a verification video to create this
            transaction.
          </Banner>
        </>
      )}
    </>
  );
};
