type UuidItem = string | { uuid: string };
const getUuid = (item: UuidItem) => (typeof item === "object" ? item.uuid : item);

export const orgAdminPath = (org: UuidItem) => {
  const uuid = getUuid(org);
  return uuid ? `/orgs/${uuid}/admin` : null;
};

export const iraOrgAdminPath = (org: UuidItem) => `/orgs/ira/${getUuid(org)}/admin`;

export const signIraContractPath = (org: UuidItem) => `/orgs/ira/${getUuid(org)}/contract`;
