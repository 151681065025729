import React from "react";

import PropTypes from "prop-types";

import { parseTimestamp, readableTime, formatDate } from "Utils/time";

export function signatureProptypes() {
  return {
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,

    signed_data: PropTypes.string,

    createdAt: PropTypes.object.isRequired,
    updatedAt: PropTypes.object.isRequired,
    readableCreatedAt: PropTypes.string.isRequired,
    readableUpdatedAt: PropTypes.string.isRequired,

    // BTC only
    signedData: PropTypes.object,
  };
}

function SignatureBase(data) {
  if (data == null) {
    return data;
  }

  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTime(data.updatedAt);

  return data;
}

function BitcoinSignature(data) {
  if (data == null) {
    return data;
  }
  data = SignatureBase(data);

  if (data.signed_data && data.signed_data.length > 0) {
    data.signedData = JSON.parse(data.signed_data);
  }

  return data;
}

export function Signature(data) {
  if (data == null) {
    return data;
  }
  return BitcoinSignature(data);
}
