import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  reason: "",
  errors: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOAN_DENY/SET/REASON":
      return updatedField(state, action, "reason");

    case "LOAN_DENY/DENY_REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "LOAN_DENY/DENY_REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors || {} };
    case "LOAN_DENY/DENY_REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
