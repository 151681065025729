import { camelCase, cloneDeep, get, kebabCase, merge, set, snakeCase } from "lodash";
import { CamelCasedProperties, KebabCasedProperties, SnakeCasedProperties } from "type-fest";

export interface TransformOptions {
  keyTransform?: (key: string) => string;
  valTransform?: (val: any) => any;
  keyFilter?: (key: string) => boolean;
  valFilter?: (val: any) => boolean;
}

/**
 * Takes an object, and returns it transformed given optional options:
 * - keyTransform - Function for changing keys in the object
 * - valTransform - Function for changing values in the object
 * - keyFilter - Function for determining if an entry should exist based on its key
 * - valFilter - Function for determining if an entry should exist based on its value
 * */
export const transformObject = (object, options: TransformOptions = {}) => {
  const keyTransform = options.keyTransform || (key => key);
  const valTransform = options.valTransform || (val => val);
  const keyFilter = options.keyFilter || (key => !!key);
  const valFilter = options.valFilter || (key => !!key);

  return Object.entries(object).reduce(
    (obj, [key, val]) =>
      keyFilter(key) && valFilter(val) ? { ...obj, [keyTransform(key)]: valTransform(val) } : obj,
    {}
  );
};

export const snakeCaseKeys = <T, R extends SnakeCasedProperties<T>>(object: T): R => {
  return Object.entries(object).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [snakeCase(key)]: val,
    }),
    {} as R
  );
};

export const camelCaseKeys = <T>(object: T): CamelCasedProperties<T> => {
  return Object.entries(object).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [camelCase(key)]: val,
    }),
    {} as CamelCasedProperties<T>
  );
};

export const kebabCaseKeys = <T>(object: T): KebabCasedProperties<T> => {
  return Object.entries(object).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [kebabCase(key)]: val,
    }),
    {} as KebabCasedProperties<T>
  );
};

export const setDefaults = <T>(object: Partial<T>, defaults: Partial<T>): T =>
  merge({}, defaults, object) as T;

/**
 * Non-mutatively overwrites an original object with another, expecting the keys in the second object to be deep setters.
 * Note that because of this, more general keys must precede specific keys. If the original object has a key, its value will be overwritten.
 *
 * @param {object} original The object to "overwrite"
 * @param {object} overwriteWith The object of deep setters to set in the original object
 * @returns {object}
 *
 * @example:
 *
 * const original = {
 *   a: {
 *     b: {
 *       c: 1,
 *     },
 *     d: 2,
 *   },
 *   e: {
 *     f: 3,
 *   },
 *   g: 4
 * };
 * const overwriteWith = {
 *   'a.b.c': 3,
 *   g: 5,
 *
 *   // Bad idea! Would overwrite `a.b.c` above
 *   // a: { c: 4 }
 * }
 *
 * overwriteWithDeepKeys(original, overwriteWith);
 * => {
      a: {
        b: {
          c: 1,
        },
        d: 2,
      },
      e: {
        f: 3,
      },
    }
 *
 */
export const overwriteWithDeepKeys = (
  original: Record<string, any>,
  overwriteWith: Record<string, any>
) =>
  Object.entries(overwriteWith).reduce(
    (acc, [key, value]) => set(acc, key, value),
    cloneDeep(original)
  );

/**
 * Non-mutatively merges an original object with another, expecting the keys in the second object to be deep setters.
 * Note that because of this, more general keys must precede specific keys. If the original object has an object-type key, its value will be merged.
 *
 * @param {object} original The object to "overwrite"
 * @param {object} mergeWith The object of deep setters to set in the original object
 * @returns {object}
 *
 * @example:
 *
 * const original = {
 *   a: {
 *     b: {
 *       c: 1,
 *     },
 *     d: 2,
 *   },
 *   e: {
 *     f: 3,
 *   },
 *   g: 4
 * };
 * const mergeWith = {
 *   'a.b': { h: 3 },
 * };
 *
 * mergeWithDeepKeys(original, mergeWith);
 * => {
      a: {
        b: {
          c: 1,
          h: 3
        },
        d: 2,
      },
      e: {
        f: 3,
      },
    }
 *
 */
export const mergeWithDeepKeys = (original: Record<string, any>, mergeWith: Record<string, any>) =>
  Object.entries(mergeWith).reduce((acc, [key, value]) => {
    const found = get(acc, key);
    if (found && typeof found === "object") {
      return set(acc, key, { ...found, ...value });
    } else {
      return set(acc, key, value);
    }
  }, cloneDeep(original));

export const objTruthy = (obj?: object) => obj && Object.keys(obj).length > 0;

/**
 * @example
 * const obj = {
 *   a: {
 *     b: 1,
 *     c: 2,
 *   },
 *   d: [1, 2, { e: 3 }],
 * }
 * flattenDeepObjectIntoSingleTieredObjectWithCombinedKeys(obj);
 * => {
 *  "a.b": 1,
 *  "a.c": 2,
 *  "d[0]": 1,
 *  "d[1]": 2,
 *  "d[2].e": 3,
 * }
 */
export const flattenDeepObjectIntoSingleTieredObjectWithCombinedKeys = (
  obj: Record<string, unknown>
) => {
  const flattenedObject = {};
  const flatten = (obj: Record<string, unknown>, prefix = "") => {
    Object.keys(obj).forEach(key => {
      const keyIsNum = typeof key === "number" || parseInt(key).toString() === key;
      const nextKey = keyIsNum ? `[${key}]` : key;
      const joiner = keyIsNum ? "" : ".";
      const newKey = [prefix, nextKey].filter(Boolean).join(joiner);

      if (typeof obj[key] === "object" && obj[key] !== null) {
        flatten(obj[key] as Record<string, unknown>, newKey);
      } else {
        flattenedObject[newKey] = obj[key];
      }
    });
  };
  flatten(obj);
  return flattenedObject;
};
