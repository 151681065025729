import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { setBtcCurrentPriceAction } from "Actions/cryptoActions/priceActions";

import { CryptoAPI } from "..";

interface PricesResponse {
  BTC: {
    exchange: "bitstamp";
    asset: "USD";
    unit: "USD";
    value: number;
    time: string;
  };
}

const priceRequestKeys = {
  current: ["prices", "current"] as const,
};

export const useGetBtcPriceQuery = () => {
  const dispatch = useDispatch();
  return useQuery(priceRequestKeys.current, () => CryptoAPI.GetCryptoPrices(), {
    refetchInterval: 2 * 60 * 1000, //2 minutes
    select: (data: PricesResponse) => data.BTC.value,
    onSuccess: data => {
      // TODO: This is only needed as a stopgap while we maintain
      // use of the store for storing the btc price
      dispatch(setBtcCurrentPriceAction(data));
    },
  });
};
