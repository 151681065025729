import { API_V2 } from "./api";

interface TrimmedUser {
  uuid: string;
  username: string;
  email: string;
  person?: {
    first_name: string;
    last_name: string;
  };
}

export enum ChangeProposalType {
  OrgUserRoleChangeProposal = "org_user_role_change_proposal",
  OrgMemberInviteProposal = "org_member_invite_change_proposal",
  OrgMemberRemoveProposal = "org_member_remove_change_proposal",
  OrgQuorumMinApprovalsChangeProposal = "org_quorum_min_approvals_change_proposal",
  OrgQuorumApplyToAllTxsChangeProposal = "org_quorum_apply_to_all_txs_change_proposal",
}

export const changePropsalTypeMap = {
  OrgUserRoleChangeProposal: ChangeProposalType.OrgUserRoleChangeProposal,
  OrgMemberInviteProposal: ChangeProposalType.OrgMemberInviteProposal,
  OrgMemberRemoveChangeProposal: ChangeProposalType.OrgMemberRemoveProposal,
  OrgQuorumMinApprovalsChangeProposal: ChangeProposalType.OrgQuorumMinApprovalsChangeProposal,
  OrgQuorumApplyToAllTxsChangeProposal: ChangeProposalType.OrgQuorumApplyToAllTxsChangeProposal,
};

export interface ChangeProposal {
  uuid: string;
  state: "pending" | "executed" | "canceled" | "denied";
  type: ChangeProposalType;
}

export interface OrgUserRoleChangeProposal extends ChangeProposal {
  user: TrimmedUser;
  roles: {
    managed: string;
    name: string;
    uuid: string;
  }[];
  currentRoles: {
    managed: string;
    name: string;
    uuid: string;
  }[];
  userUuid?: string;
  roleUuids?: string[];
}

export interface OrgMemberChangeProposal extends ChangeProposal {
  email: string;
  roles?: {
    managed: string;
    name: string;
    uuid: string;
  }[];
}

export interface OrgQuorumMinApprovalsChangeProposal extends ChangeProposal {
  minApprovals: number;
  current: number;
}

export interface OrgQuorumApplyToAllTxsChangeProposal extends ChangeProposal {
  enabled: boolean;
  current: boolean;
}

export interface LiveVote {
  user: TrimmedUser;
  vote: "approve" | "deny";
  createdAt: string;
  updatedAt: string;
  ipAddress: string;
}

export interface ChangeProposalGroup {
  uuid: string;
  requester: TrimmedUser;
  state: "pending" | "executed" | "canceled" | "denied";
  changeProposals: ChangeProposal[];
  liveVotes: any[];
  createdAt: string;
  updatedAt: string;
}

export class ChangeProposalAPI {
  static CreateChangeProposalGroup = async (orgUuid: string, data: any) =>
    (await API_V2.Post(`/orgs/${orgUuid}/change-proposal-groups`, data)).data;

  static GetOrgChangeProposalGroups = async (orgUuid: string, pending = false) =>
    (
      await API_V2.Get<{ changeProposalGroups: ChangeProposalGroup[] }>(
        `/orgs/${orgUuid}/change-proposal-groups?${pending ? "state=pending" : ""}`
      )
    ).data;

  static ApproveChangeProposalGroup = async (orgUuid: string, groupUuid: string) =>
    (await API_V2.Post<string>(`/orgs/${orgUuid}/change-proposal-groups/${groupUuid}/approve`))
      .data;

  static RejectChangeProposalGroup = async (orgUuid: string, groupUuid: string) =>
    (await API_V2.Post<string>(`/orgs/${orgUuid}/change-proposal-groups/${groupUuid}/deny`)).data;

  static RevokeChangeProposalGroup = async (orgUuid: string, groupUuid: string) =>
    (await API_V2.Post<string>(`/orgs/${orgUuid}/change-proposal-groups/${groupUuid}/cancel`)).data;
}
