import { isEqual } from "lodash";

type ExtraReport = {
  name: string;
  value: unknown;
};

export type RequestReport = {
  path: string;
  method: string;
  code: number;
  requestData?: unknown;
  responseData?: unknown;
};

type PathReport = {
  path: string;
};

type ErrorReport = {
  message: string;
  stack: string;
};

type TimeStamp = { when: string };

// All client report data is stored in reverse order, most recent first.
export type ClientReportsState = {
  errors: (ErrorReport & TimeStamp)[];
  pathHistory: (PathReport & TimeStamp)[];
  extra: (ExtraReport & TimeStamp)[];
  // Requests are a placeholder. In the future, we aim to use this to track
  // API requests, along with masked request/response data.
  requests: (RequestReport & TimeStamp)[];
};

const initialClientReportState: ClientReportsState = {
  errors: [],
  pathHistory: [],
  extra: [],
  requests: [],
};

export const reportRequest = (payload: RequestReport) =>
  ({ type: "CLIENT_REPORT_REQUEST", payload } as const);

export const reportExtra = (payload: ExtraReport) =>
  ({ type: "CLIENT_REPORT_EXTRA", payload } as const);

export const reportError = (payload: ErrorReport) =>
  ({ type: "CLIENT_REPORT_ERROR", payload } as const);

export const reportPath = (payload: string) =>
  ({
    type: "CLIENT_REPORT_PATH",
    payload: { path: payload } as PathReport,
  } as const);

export const clientReportsReducer = (
  state: ClientReportsState = initialClientReportState,
  action:
    | ReturnType<typeof reportError>
    | ReturnType<typeof reportRequest>
    | ReturnType<typeof reportExtra>
    | ReturnType<typeof reportPath>
) => {
  const { type, payload } = action;
  const key = (
    {
      CLIENT_REPORT_REQUEST: "requests",
      CLIENT_REPORT_PATH: "pathHistory",
      CLIENT_REPORT_ERROR: "errors",
    } as const
  )[type];

  // For all the "regular" array keys, just prepend the new entry.
  if (key)
    return {
      ...state,
      [key]: [{ ...payload, when: new Date().toISOString() }, ...state[key]],
    };

  /*
   * This one is a special case:
   * - If the most recent entry shares the new ("proposed") entry's name, update it or leave it if identical.
   * - If the most recent entry doesn't share the new entry's name, add the new entry to the top of the list.
   */
  if (type === "CLIENT_REPORT_EXTRA") {
    const index = state.extra.findIndex(e => e.name === payload.name);
    const entry = { ...payload, when: new Date().toISOString() };

    if (index === 0) {
      const existing = state.extra[0];

      // If the new value is the same as the existing value, leave it be.
      if (isEqual(existing.value, payload.value)) return state;
      // If it's different, replace the existing entry.
      else return { ...state, extra: [entry, ...state.extra.slice(1)] };
    } else {
      // If the entry doesn't exist, add it to the top of the list.
      return { ...state, extra: [entry, ...state.extra] };
    }
  }

  return state;
};
