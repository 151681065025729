import React from "react";

import { ThumbsUpDown } from "@mui/icons-material";
import { capitalize } from "lodash";

import { SignIcon } from "Components/Shared/Icons/SignIcon";
import { SellBitcoinStepperWizard } from "Components/TradingDesk/sell/SellBitcoinStepperWizard";
import { AppModalManager } from "Shared/components/Modals";
import { btcOperationTypes } from "Utils/enums";
import { splitOperationType } from "Utils/operationTypes";

export const BtcTransactionApprovalNotification = ({
  operation_type,
  operation_uuid,
  product_uuid,
  action_type,
}) => {
  const isSaleTransaction = operation_type === btcOperationTypes.vault_sale_transaction;

  const split = splitOperationType(operation_type);
  if (split.length > 2) return null;
  const [productType, opType] = split;

  const transactionText = isSaleTransaction ? "sell transaction" : "transaction";

  const text =
    action_type === "approve_btc_transaction"
      ? `Approve pending ${transactionText}`
      : `${capitalize(transactionText)} is approved and ready to sign`;

  const icon = action_type === "approve_btc_transaction" ? ThumbsUpDown : SignIcon;
  const to = isSaleTransaction
    ? ""
    : `/${productType}s/${product_uuid}/${opType}s/${operation_uuid}`;

  const openSellBitcoinFlow = () =>
    AppModalManager.open(() => {
      return <SellBitcoinStepperWizard />;
    });
  const additionalAction = isSaleTransaction ? openSellBitcoinFlow : undefined;

  return {
    title: text,
    Icon: icon,
    to: to,
    additionalAction: additionalAction,
  };
};
