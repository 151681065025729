import { AnyAction } from "redux";

import { RESET_VAULT_INDEX, SET_VAULT_INDEX_USER } from "Actions/vaultActions/vaultIndexActions";

// TODO: Define types in API specs, and specificially define vault type

const initialState = {
  userVaults: {
    vaults: {},
    status: "pending",
  },
};

type VaultIndexState = typeof initialState;

export const vaultIndexReducer = (
  state: VaultIndexState = initialState,
  action: AnyAction
): VaultIndexState => {
  const { type, payload } = action;

  switch (type) {
    case SET_VAULT_INDEX_USER: {
      return {
        ...state,
        userVaults: payload,
      };
    }

    case RESET_VAULT_INDEX: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
