import {
  Banner,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import { useLocation } from "react-router-dom";

import { Link } from "Components/Link";
import { withAccount } from "Shared/api";
import { AppModalManager } from "Shared/components/Modals";
import { AccountPersonalOrg, CompleteOrg } from "Specs/v1/getAccount/200";

export const AdvancedProfileModal = withAccount<{ currentOrg?: CompleteOrg | AccountPersonalOrg }>(
  ({ currentOrg }) => {
    const location = useLocation();
    const items =
      currentOrg.account_type === "business"
        ? ["The social security numbers of all owners"]
        : [
            "Your SSN",
            "Your employment status",
            "Details about your income and sources",
            "Details about your wealth and your digital assets",
          ];
    return (
      <Modal onDismiss={AppModalManager.close}>
        <ModalHeader>
          <ModalTitle
            subtitle={
              <>
                Please provide us with additional information in order to gain access to our broader
                suite of financial services.{" "}
                <Link to="https://help.unchained.com/what-personal-information-is-needed-to-set-up-a-vault">
                  More about how we handle this info.
                </Link>
              </>
            }
          >
            We need some additional information
          </ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Banner type="info" title="Information you will be asked to provide">
            <ul>
              {items.map(i => (
                <li key={i}>{i}</li>
              ))}
            </ul>
          </Banner>
        </ModalContent>
        <ModalFooter
          actions={[
            {
              children: "Continue",
              to: `/onboard/${currentOrg.uuid}?from=${location.pathname}`,
              onClick: AppModalManager.close,
            },
          ]}
        />
      </Modal>
    );
  }
);
