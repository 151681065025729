import _ from "lodash";

import { API } from "./api";

export class FeaturesAPI {
  static List = async () => {
    const response = await API.Get("/features");
    return response;
  };

  static CreateUserFeature = async (uuid, name, enabled) => {
    const response = await API.Post(`/users/${uuid}/features`, {
      feature_name: name,
      enabled,
    });
    return response;
  };

  static UpdateUserFeature = async (uuid, name, enabled) => {
    const response = await API.Put(`/users/${uuid}/features/${name}`, { enabled });
    return response;
  };

  static DeleteUserFeature = async (uuid, name) => {
    const response = await API.Delete(`/users/${uuid}/features/${name}`);
    return response;
  };

  static CreateOrgFeature = async (uuid, name, enabled) => {
    const response = await API.Post(`/orgs/${uuid}/features`, { feature_name: name, enabled });
    return response;
  };

  static UpdateOrgFeature = async (uuid, name, enabled) => {
    const response = await API.Put(`/orgs/${uuid}/features/${name}`, { enabled });
    return response;
  };

  static DeleteOrgFeature = async (uuid, name) => {
    const response = await API.Delete(`/orgs/${uuid}/features/${name}`);
    return response;
  };
}
