export const RESET_NEW_LOAN_FLOW = "RESET_NEW_LOAN_FLOW";
export const SET_NEW_LOAN_ACH_TYPE = "SET_NEW_LOAN_ACH_TYPE";
export const SET_NEW_LOAN_INTEREST_ACCOUNT = "SET_NEW_LOAN_INTEREST_ACCOUNT";
export const SET_NEW_LOAN_DISBURSEMENT_ACCOUNT = "SET_NEW_LOAN_DISBURSEMENT_ACCOUNT";
export const SET_NEW_LOAN_COLLATERAL = "SET_NEW_LOAN_COLLATERAL";
export const SET_NEW_LOAN_PRINCIPAL = "SET_NEW_LOAN_PRINCIPAL";
export const SET_NEW_LOAN_STARTS_ON = "SET_NEW_LOAN_STARTS_ON";
export const SET_NEW_LOAN_TERM_BILLING_PERIODS = "SET_NEW_LOAN_TERM_BILLING_PERIODS";
export const SET_NEW_LOAN_PURPOSE = "SET_NEW_LOAN_PURPOSE";
export const SET_NEW_LOAN_ACTIVE_MILITARY = "SET_NEW_LOAN_ACTIVE_MILITARY";
export const SET_NEW_LOAN_USER_KEY = "SET_NEW_LOAN_USER_KEY";
export const SET_NEW_LOAN_ORG = "SET_NEW_LOAN_ORG";
export const CREATE_NEW_LOAN = "CREATE_NEW_LOAN";

/**
 * Set Loan ach type action in New Loan Flow
 * @param achType - either `manual` or `auto`. Selected by user.
 */
export const setLoanAchType = achType => ({
  type: SET_NEW_LOAN_ACH_TYPE,
  payload: achType,
});

/**
 * Set Loan interest payment account action in New Loan Flow
 * @param loanInterestAccount - String containing UUID and name of the interst
 * account in one string. Ex. "uuid123:Plaid Checking"
 */
export const setLoanInterestAccountAction = loanInterestAccount => ({
  type: SET_NEW_LOAN_INTEREST_ACCOUNT,
  payload: loanInterestAccount,
});

/**
 * Set Loan disbursement account action in New Loan Flow
 * @param loanDisbursementAccount - String containing UUID and name of the disbursement
 * account in one string. Ex. "uuid123:Plaid Checking"
 */
export const setLoanDisbursementAccountAction = loanDisbursementAccount => ({
  type: SET_NEW_LOAN_DISBURSEMENT_ACCOUNT,
  payload: loanDisbursementAccount,
});

/**
 * Set Loan collateral action in New Loan Flow
 * @param loanCollateral - User input for collateral amount
 */
export const setLoanCollateralAction = loanCollateral => ({
  type: SET_NEW_LOAN_COLLATERAL,
  payload: loanCollateral,
});

/**
 * Set Loan principal action in New Loan Flow
 * @param loanPrincipal - User input for principal amount
 */
export const setLoanPrincipalAction = loanPrincipal => ({
  type: SET_NEW_LOAN_PRINCIPAL,
  payload: loanPrincipal,
});

/**
 * Set Loan starts on action in New Loan Flow
 * @param loanStartsOn - User input for starts on date
 */
export const setLoanStartsOnAction = loanStartsOn => ({
  type: SET_NEW_LOAN_STARTS_ON,
  payload: loanStartsOn,
});

/**
 * Set Loan term in billing periods action in New Loan Flow
 * @param loanTermBillingPeriods - User input for term in 30 day billing periods
 */
export const setLoanTermBillingPeriodsAction = loanTermBillingPeriods => ({
  type: SET_NEW_LOAN_TERM_BILLING_PERIODS,
  payload: loanTermBillingPeriods,
});

/**
 * Set Loan purpose action in New Loan Flow
 * @param loanPurpose - User input for purpose
 */
export const setLoanPurposeAction = loanPurpose => ({
  type: SET_NEW_LOAN_PURPOSE,
  payload: loanPurpose,
});

/**
 * Set Loan active military action in New Loan Flow
 * @param loanActiveMilitary - User input for purpose
 */
export const setLoanActiveMilitaryAction = loanActiveMilitary => ({
  type: SET_NEW_LOAN_ACTIVE_MILITARY,
  payload: loanActiveMilitary,
});

export const setNewLoanUserKeyAction = fields => ({
  type: SET_NEW_LOAN_USER_KEY,
  payload: fields,
});

export const setNewLoanOrgAction = org => ({
  type: SET_NEW_LOAN_ORG,
  payload: org,
});

export const resetNewLoanFlowAction = () => ({
  type: RESET_NEW_LOAN_FLOW,
  payload: null,
});

export const createNewLoanAction = inputLoan => {
  return async dispatch => {
    dispatch({ type: CREATE_NEW_LOAN, payload: inputLoan });
    // TODO: Maybe dispatch once more here to get new loan data.
    // TODO: Maybe handle that specifically in components.
  };
};
