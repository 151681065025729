import { FC, ReactNode } from "react";

import { TableRow } from "@mui/material";

import Copyable from "Components/helpers/Copyable";
import { useDownloadProfileDoc } from "Shared/api/hooks";
import { useEasyToasts } from "Utils/toasts";

import { FileCell } from "./FileCell";
import { MaskedCell } from "./MaskedCell";
import { SummaryRowDatum } from "./SummaryTable";
import { SummaryTableCell } from "./SummaryTableCell";

interface SummaryTextRowProps extends Omit<SummaryRowDatum, "value"> {
  className?: string;
  children: ReactNode | string;
}

const DownloadCell = ({ download, title, children }) => {
  const downloadProfileDoc = useDownloadProfileDoc(download.orgUuid, download.principalUuid);
  const { showErrorToast } = useEasyToasts();

  return (
    <FileCell
      name={children}
      label={title}
      onDownload={e => {
        e.preventDefault();

        try {
          if (download.onDownload) {
            download.onDownload();
            return;
          }

          downloadProfileDoc.mutate(download.label);
        } catch (err) {
          showErrorToast(err);
        }
      }}
    />
  );
};

export const SummaryTextRow: FC<SummaryTextRowProps> = ({
  title,
  copyable,
  maskedValue,
  children,
  className,
  download,
}) => {
  let Cell = () => <SummaryTableCell className={className}>{children}</SummaryTableCell>;

  if (download) {
    Cell = () => (
      <DownloadCell download={download} title={title}>
        {children || title}
      </DownloadCell>
    );
  } else if (maskedValue) {
    Cell = () => (
      <MaskedCell
        copyable={copyable}
        className={className}
        value={children}
        maskedValue={maskedValue}
      />
    );
  } else if (copyable) {
    Cell = () => (
      <SummaryTableCell className={className}>
        <Copyable text={children}>{children}</Copyable>
      </SummaryTableCell>
    );
  }

  return (
    <TableRow>
      <SummaryTableCell component="th">{title}</SummaryTableCell>
      <Cell />
    </TableRow>
  );
};
