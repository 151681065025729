import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";

import { AppModal } from "Shared/components/Modals/AppModal";

import styles from "./FilePreviewModal.module.scss";

class FilePreviewModal extends React.Component {
  static propTypes = {
    previewMessage: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail_data: PropTypes.string.isRequired,
  };

  render() {
    const { previewMessage, label } = this.props;
    return (
      <AppModal>
        <div className={styles.modal}>
          {label ? <h3 className={cn(styles.header, "mb-4")}>{label}</h3> : null}
          {this.previewImage()}
          <p>{previewMessage}</p>
        </div>
      </AppModal>
    );
  }

  previewImage = () => {
    const { previewMessage, thumbnail_data, name, label } = this.props;
    if (name.toLowerCase().endsWith(".pdf")) {
      return <embed type="application/pdf" src={thumbnail_data} alt={previewMessage} />;
    } else {
      return <img src={thumbnail_data} alt={previewMessage} />;
    }
  };
}

export { FilePreviewModal };
