import React, { ReactNode } from "react";

import { CopyablePopup } from "Components/helpers/CopyablePopup";

import styles from "./WireInstructionItem.module.scss";

interface WireInstructionsStyles {
  container?: string;
  title?: string;
  content?: string;
}

interface WireInstructionItemProps {
  title: string | ReactNode;
  content: string | ReactNode[];
  textToBeCopied?: string;
  isCopyable?: boolean;
  instructionStyles?: WireInstructionsStyles;
}

const WireInstructionItem = ({
  title,
  content = "",
  textToBeCopied,
  isCopyable = true,
  instructionStyles = {},
}: WireInstructionItemProps) => {
  const parseTextToBeCopyable = () => {
    if (textToBeCopied) {
      return textToBeCopied;
    } else if (typeof content === "string") {
      return content;
    } else {
      const contentStringOnly = content.filter(contentItem => typeof contentItem === "string");
      return contentStringOnly.join(" ");
    }
  };

  const copyableText = parseTextToBeCopyable();

  return (
    <div className={instructionStyles?.container || styles.container}>
      <div className={instructionStyles?.title || styles.title}>{title}</div>
      <div className={styles.copyable}>
        <div>
          <div className={instructionStyles?.content || styles.content}>
            {content}
            {isCopyable ? (
              <CopyablePopup
                copyText={copyableText}
                containerClassName={styles.copyablePopUpContainer}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { WireInstructionItem };
