import { useEffect } from "react";

/** Changes the <title> tag while the component is on the page, and changes it back on unmount */
export const useHtmlTitleTag = (pageTitle: string) => {
  useEffect(() => {
    const title = document.getElementsByTagName("title")[0];
    let prevTitle;
    if (title) {
      prevTitle = title.innerHTML;
      title.innerHTML = pageTitle;
    }

    return () => {
      if (title) title.innerHTML = prevTitle;
    };
  });
};

export const HtmlTitle = ({ title }: { title: string }) => {
  useHtmlTitleTag(title);
  return null;
};
