import React from "react";

import { Close } from "@mui/icons-material";
import { Dialog, IconButton, useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

import styles from "./AppModal.module.scss";
import { AppModalManager } from "./AppModalManager";

class AppModalBehavior {
  constructor(componentProps) {
    this._props = componentProps;
  }

  handleClose = () => {
    const { onModalClose } = this._props;
    if (onModalClose) {
      onModalClose();
    }
    AppModalManager.close();
  };
}

const ModalButton = ({ onClick, closeButtonClassName = styles.closeButton }) => {
  return (
    <IconButton
      data-testid="AppModalCloseButton"
      classes={{ root: closeButtonClassName }}
      onClick={onClick}
      size="large"
    >
      <Close />
    </IconButton>
  );
};

class AppModalBase extends React.PureComponent {
  static propTypes = {
    size: PropTypes.string,
    onModalClose: PropTypes.func,
    disableClose: PropTypes.bool,
    dialogProps: PropTypes.object,
    confirmBack: PropTypes.bool,
    closeButtonClassName: PropTypes.string,
    dialogBoxClassName: PropTypes.string,
  };

  static defaultProps = {
    size: "md",
    dialogProps: {},
    dialogBoxClassName: styles.dialogBox,
  };

  render() {
    const behavior = new AppModalBehavior(this.props);
    const { children, size, disableClose, dialogProps, closeButtonClassName, dialogBoxClassName } =
      this.props;

    return (
      <Dialog
        open
        maxWidth={size}
        classes={{ paper: dialogBoxClassName }}
        disableEscapeKeyDown={disableClose}
        onClose={behavior.handleClose}
        {...dialogProps}
      >
        {!disableClose && (
          <ModalButton closeButtonClassName={closeButtonClassName} onClick={behavior.handleClose} />
        )}
        {children}
      </Dialog>
    );
  }
}

export const AppModal = ({ dialogProps, preventFullScreen = false, ...other }) => {
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = preventFullScreen ? false : mediaQuery;

  const modalDialogProps = { ...(dialogProps || {}), fullScreen };

  return <AppModalBase {...other} dialogProps={modalDialogProps} />;
};

AppModal.propTypes = {
  dialogProps: PropTypes.object,
  preventFullScreen: PropTypes.bool,
};

export const openAppModal = component => {
  AppModalManager.open(() => <AppModal>{component}</AppModal>);
};
