import { RequireAtLeastOne } from "type-fest";

import { useCurrentUser, useMemoizedState } from "Redux/selectors/hooks";
import { useSelectedSigRequest } from "Redux/selectors/spendingSelectors";
import { SigRequest } from "Specs/v1/getTransactionRequests/200";
import { Loan } from "Specs/v1/showLoan/200";

import { LoanPermission } from "./LoanPermission";
import { SigRequestPermission } from "./SigRequestPermission";

type FullLoan = RequireAtLeastOne<Loan, "allowed_actions">;

/** Returns a permissions object relating the current user to the passed loan, or the loanShow object in Redux state */
export const useLoanPermission = (passedLoan?: FullLoan) => {
  const derivedLoan = useMemoizedState("loans.loanShow.loan") as FullLoan;
  const currentUser = useCurrentUser();
  return new LoanPermission(passedLoan || derivedLoan, currentUser);
};

/** Returns a permissions object relating the current user to the passed sigRequest, or the selectedSigRequest object in Redux state */
export const useSigReqPermission = (sigReq?: SigRequest) => {
  const derivedSigReq = useSelectedSigRequest();
  const currentUser = useCurrentUser();
  return new SigRequestPermission(sigReq || derivedSigReq, currentUser);
};
