import { useEffect, useState } from "react";

import moment from "moment-timezone";

/**
 * @description given a target point in time, returns the live countdown
 * to that time.
 *
 * The hook assumes that the desired output format is "hh:mm:ss"
 *
 * @param {object} targetTime - a moment abject
 *
 * @returns {string} remainingTime format is: hh:mm:ss
 */
export const useCountdownTimer = targetTime => {
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const targetTimestamp = moment(targetTime);
      const currentTime = moment();
      let hoursDiff: number | string = targetTimestamp.diff(currentTime, "hours");
      let minutesDiff: number | string = targetTimestamp.diff(currentTime, "minutes") % 60;
      let secondsDiff: number | string = targetTimestamp.diff(currentTime, "seconds") % 60;

      hoursDiff = hoursDiff < 0 ? "0" : hoursDiff.toString();
      minutesDiff = minutesDiff < 0 ? "0" : minutesDiff.toString();
      secondsDiff = secondsDiff < 0 ? "0" : secondsDiff.toString();

      setRemainingTime(
        `${hoursDiff.padStart(2, "0")}:${minutesDiff.padStart(2, "0")}:${secondsDiff.padStart(
          2,
          "0"
        )}`
      );
    }, 1000);
    return () => clearInterval(intervalId);
  }, [targetTime]);

  return remainingTime;
};
