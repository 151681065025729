import React from "react";

import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";

export const WithdrawWizard = () => {
  return (
    <>
      <ModalHeader />
      <ModalContent />
      <ModalFooter />
    </>
  );
};
