import { useEffect, useState } from "react";

import { useQuery } from "react-query";

import { FeesAPI } from "Shared/api/feesApi";
import { FeeRates } from "Specs/v1/getRecommendedFeeRates/200";

const feesKeys = {
  all: ["feeRates"] as const,
};

export const useFeeRatesQuery = <T = FeeRates>(select?: (data: FeeRates) => T) => {
  return useQuery(feesKeys.all, () => FeesAPI.GetRecommended(), { select });
};

/**
 * A hook for destructuring and defaulting the useFeeRatesQuery hook.
 *
 * Don't use the values being returned from here to do your own fee rate
 * validation. Instead, use the useValidateFeeRate hook below.
 */
export const useFeeRates = () => {
  const [state, setState] = useState<FeeRates>({
    purge_rate: 0,
    max: 0,
    recommended: 0,
    fast: 0,
    slow: 0,
  });
  const { data: feeRates } = useFeeRatesQuery();

  useEffect(() => {
    if (feeRates) {
      setState({ ...feeRates });
    }
  }, [feeRates]);

  return state;
};

export const useFastFeeRateQuery = (min: number) => {
  return useFeeRatesQuery(data => (min ? Math.max(data.fast, min) : data.fast));
};

/**
 * The category which the fee rate falls under. Generally (with some
 * exceptions), these values indicate the fee rate belongs to the right
 * half-open interval between its namesake and the next value in the enum. For
 * example, if a validation is FeeRateValidationCategory.PURGE, then it means
 * that fee rate is at least equal to the purge rate but not equal to or greater
 * than slow.
 */
export enum FeeRateValidationCategory {
  BELOW_PURGE,
  PURGE,
  SLOW,
  RECOMMENDED,
  FAST,
  MAX,
  GREATER_THAN_MAX,
}

/**
 * Validates a fee rate in sats/vB against the trefoil fee rate api endpoint.
 * Returns a category for the fee validation.
 */
export const useValidateFeeRate = (feeRate: number) => {
  const { purge_rate, slow, recommended, fast, max } = useFeeRates();

  if (feeRate < purge_rate) {
    return FeeRateValidationCategory.BELOW_PURGE;
  }
  if (feeRate < slow) {
    return FeeRateValidationCategory.PURGE;
  }
  if (feeRate < recommended) {
    return FeeRateValidationCategory.SLOW;
  }
  if (feeRate < fast) {
    return FeeRateValidationCategory.RECOMMENDED;
  }
  if (feeRate < max) {
    return FeeRateValidationCategory.FAST;
  }
  if (feeRate === max) {
    return FeeRateValidationCategory.MAX;
  }
  if (feeRate >= max) {
    return FeeRateValidationCategory.GREATER_THAN_MAX;
  }

  return null;
};
