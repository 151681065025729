import validateBtcAddress from "bitcoin-address-validation";
import moment from "moment";
import validate from "validate.js";

import { SliceAPI } from "Shared/api/sliceApi";
import { getConfig, minLength, maxLength } from "Utils/config";
import { capitalize, trim } from "Utils/strings";
import { SERVER_DATE_SEND_FORMAT, SERVER_TIME_SEND_FORMAT } from "Utils/time";

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    if (value instanceof moment) {
      return value;
    }
    return moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    let format = options.dateOnly ? SERVER_DATE_SEND_FORMAT : SERVER_TIME_SEND_FORMAT;
    return moment.utc(value).format(format);
  },
});

export function validateAndReturnError(value, rules, name) {
  let errors = validate.single(value, rules);
  if (errors) {
    let message = errors[0];
    if (name) {
      message = capitalize(name) + " " + errors[0];
    }
    return message;
  }
  return "";
}

export function validateField(name, value, rules) {
  let errors = validate.single(value, rules);
  if (errors) {
    let fieldErrors = {};
    fieldErrors[name] = errors;
    return fieldErrors;
  } else {
    return null;
  }
}

export function isPresent(value) {
  if (typeof value == "undefined" || value == null) {
    return false;
  } else {
    if (typeof value == "string") {
      return value.replace(/ /g, "").length > 0;
    } else if (typeof value == "object") {
      return Object.keys(value).length > 0;
    } else {
      return true;
    }
  }
}

export function arePresent(...values) {
  return values.every(isPresent);
}

export function anyMissing(...values) {
  return !arePresent(...values);
}

// These functions provide an API to use in forms to validate and then
// set (via Redux actions) the application state from user input.

export function validateAndSet(setter, context, name, value, rules) {
  let errors = validateField(name, value, rules);
  setter(context, name, value, errors);
}

export function validateAndSetFloat(setter, context, name, rawValue, rules, maxPrecision) {
  let parsedValue = parseFloat(rawValue);
  let errors = validateField(name, parsedValue, rules);

  if (maxPrecision) {
    let parts = rawValue.split(".", 2);
    if (parts[1] && parts[1].length > maxPrecision) {
      if (typeof errors == "undefined" || errors == null) {
        errors = {};
      }
      if (typeof errors[name] == "undefined" || errors[name] == null) {
        errors[name] = [];
      }
      errors[name].push(`cannot have more than ${maxPrecision} digits after the decimal place`);
    }
  }
  setter(context, name, rawValue, errors);
}

export function validateAndSetDate(setter, context, name, date, rules) {
  let errors = validateField(name, date.startOf("day"), rules);
  setter(context, name, date, errors);
}

// These functions wrap the validateAndSet* functions for use as
// onChange handlers in form inputs.

export function changedValidateAndSet(setter, context, name, rules) {
  return event => {
    validateAndSet(setter, context, name, event.target.value, rules);
  };
}

export function changedValidateAndSetFloat(setter, context, name, rules, maxPrecision) {
  return event => {
    validateAndSetFloat(setter, context, name, event.target.value, rules, maxPrecision);
  };
}

export function changedValidateAndSetDate(setter, context, name, rules) {
  return date => {
    date.utc().startOf("day");
    validateAndSetDate(setter, context, name, date, rules);
  };
}

export function validateLength(string, name, bounds, thingsName) {
  if (bounds.min !== null && bounds.min !== undefined) {
    if (string.length < bounds.min) {
      return `Minimum of ${bounds.min} ${thingsName || "characters"}`;
    }
  }
  if (bounds.max !== null && bounds.max !== undefined) {
    if (string.length > bounds.max) {
      return `Maximum ${bounds.max} ${thingsName || "characters"}`;
    }
  }
  return "";
}

const CODENAME_REGEXP = /^[a-z]{2}[a-z]+-[a-z]{2}[a-z]+$/;

export function validateCodename(codename) {
  if (trim(codename || "") === "") {
    return "Cannot be blank";
  }

  const minCodenameLength = minLength("codename");
  const maxCodenameLength = maxLength("codename");
  if (codename.length < minCodenameLength) {
    return `Cannot be shorter than ${minCodenameLength} characters`;
  }
  if (codename.length > maxCodenameLength) {
    return `Cannot be longer than ${maxCodenameLength} characters`;
  }

  if (!CODENAME_REGEXP.test(codename)) {
    return "Must be two hyphen-separated lowercase words";
  }
  return "";
}

const USERNAME_REGEXP = /^[-a-zA-Z0-9_]+$/;

export function validateUsername(username) {
  if (trim(username) === "") {
    return "Cannot be blank";
  }
  if (!USERNAME_REGEXP.test(username)) {
    return "Can only contain letters, digits, underscores, or dashes";
  }
  return validateLength(username, "Username", {
    min: window.TREFOIL_CONFIG.min_length.username,
    max: window.TREFOIL_CONFIG.max_length.username,
  });
}

export function validatePassword(password, oldPassword) {
  if (trim(password) === "") {
    return "Cannot be blank";
  }
  if (oldPassword && oldPassword === password) {
    return "Must be a new password";
  }
  return validateLength(password, "Password", {
    min: window.TREFOIL_CONFIG.min_length.password,
    max: window.TREFOIL_CONFIG.max_length.password,
  });
}

export function validatePasswordSignUp(password) {
  let validation = {
    containsLetter: false,
    containsNumber: false,
    containsSymbol: false,
    isLongEnough: false,
  };

  validation.isLongEnough = password.length >= window.TREFOIL_CONFIG.min_length.password;
  validation.containsLetter = /[a-zA-Z]/.test(password);
  validation.containsNumber = /[\d]/.test(password);
  validation.containsSymbol = /[^a-zA-Z\d\s]/.test(password);

  return validation;
}

// Don't try too hard here...
const EMAIL_REGEXP = /^.+@.+\..+$/;

export function validateEmail(email) {
  if (trim(email) === "") {
    return "Email cannot be blank.";
  }
  if (!EMAIL_REGEXP.test(email)) {
    return `Must be an email address.`;
  }
  return validateLength(email, "Email", {
    min: window.TREFOIL_CONFIG.min_length.email,
    max: window.TREFOIL_CONFIG.max_length.email,
  });
}

// Don't try too hard here...
const PHONE_REGEXP = /^[-()0-9 \\.x+]+$/;

export function validatePhone(phone, oldPhone) {
  if (trim(phone) === "") {
    return "Cannot be blank.";
  }
  if (!PHONE_REGEXP.test(phone)) {
    return `Must be a US phone number with area code.`;
  }
  if (oldPhone && phone === oldPhone) {
    return "New phone number must be different.";
  }
  return validateLength(phone, "Phone", {
    min: getConfig("min_length.phone_number"),
    max: getConfig("max_length.phone_number"),
  });
}

const TOKEN_REGEXP = /^[0-9]{6}$/;

export function validate2FA(token) {
  if (!TOKEN_REGEXP.test(token)) {
    return "Must contain exactly six digits.";
  }
  return "";
}

const BASE58_BITCOIN_MAINNET_ADDRESS_REGEXP = /^[13][A-HJ-NP-Za-km-z1-9]+$/;
const BECH32_BITCOIN_MAINNET_ADDRESS_REGEXP = /^(bc1)[ac-hj-np-z02-9]+$/;
const BASE58_BITCOIN_TESTNET_ADDRESS_REGEXP = /^[mn2][A-HJ-NP-Za-km-z1-9]+$/;
const BECH32_BITCOIN_TESTNET_ADDRESS_REGEXP = /^(tb1|bcrt1)[ac-hj-np-z02-9]+$/;
const BECH32_BITCOIN_MAINNET_TAPROOT_ADDRESS_REGEXP = /^(bc1p)[ac-hj-np-z02-9]+$/;
const BECH32_BITCOIN_TESTNET_TAPROOT_ADDRESS_REGEXP = /^(tb1p)[ac-hj-np-z02-9]+$/;

export function validateBitcoinAddress(address) {
  if (trim(address) === "") {
    return "Address cannot be blank.";
  }

  const lengthError = validateLength(address, "Address", {
    min: window.TREFOIL_CONFIG.min_length.bitcoin_address,
    max: window.TREFOIL_CONFIG.max_length.bitcoin_address,
  });
  if (lengthError !== "") {
    return lengthError;
  }

  if (address.startsWith(window.TREFOIL_CONFIG.wallet.testnet ? "TB" : "BC")) {
    if (validateBtcAddress(address)) {
      return "Bech32 addresses should be in lowercase";
    }
  }

  if (window.TREFOIL_CONFIG.wallet.testnet) {
    if (
      !BASE58_BITCOIN_TESTNET_ADDRESS_REGEXP.test(address) &&
      !BECH32_BITCOIN_TESTNET_ADDRESS_REGEXP.test(address) &&
      !BECH32_BITCOIN_TESTNET_TAPROOT_ADDRESS_REGEXP.test(address)
    ) {
      return "Address must start with one of 'm', 'n', '2', 'tb', 'bcrt' or 'tb1p' followed by letters or digits.";
    }
  } else {
    if (
      !BASE58_BITCOIN_MAINNET_ADDRESS_REGEXP.test(address) &&
      !BECH32_BITCOIN_MAINNET_ADDRESS_REGEXP.test(address) &&
      !BECH32_BITCOIN_MAINNET_TAPROOT_ADDRESS_REGEXP.test(address)
    ) {
      return "Address must start with either of '1', '3', 'bc' or 'bc1p' followed by letters or digits.";
    }
  }

  if (!validateBtcAddress(address)) {
    return "Address is invalid";
  }

  return "";
}

export function validateBlockchainAddress(address, unit) {
  switch (unit) {
    case "BTC":
      return validateBitcoinAddress(address);
    default:
      return "Invalid address unit."; // shouldn't reach here...
  }
}

// Since return addresses are long term, we want to make sure that the
// user understands that setting a return address that points to a vault
// or a loan might end up being problematic, so they should click through
// an override checkbox anyway.

export async function validateReturnAddressOverride(address, account) {
  const result = { addressError: "", overrideable: false };

  const addressError = validateBlockchainAddress(address, account.unit);
  if (addressError) {
    result.addressError = addressError;
    return result;
  }

  try {
    const { product } = await SliceAPI.GetProduct(address);
    let productTypeMessage;
    if (product.product_type === "loan") {
      productTypeMessage = `loan ${product.uuid}`;
    } else {
      productTypeMessage = `'${product.name}' vault`;
    }
  } catch (e) {
    // address not found means that we are free to outflow to it.
  }

  return result;
}

// Outflows are much more immediate than return addresses. This is where we
// give the user the maximum flexibility in where to send funds. We try to warn
// them when they do something ill-advised, but ultimately we let them do it,
// with the exception of sending funds to the same address they are coming from.

export async function validateOutflowAddressOverride(address, account) {
  const result = { addressError: "", overrideable: false };

  const addressError = validateBlockchainAddress(address, account?.unit);
  if (addressError) {
    result.addressError = addressError;
    return result;
  }

  try {
    const { product } = await SliceAPI.GetProduct(address);
    if (product?.uuid === account?.uuid) {
      if (product.product_type) {
        result.addressError = `Address associated with current ${product.product_type}.`;
        result.overrideable = true;
      }
    }
  } catch (e) {
    // address not found means that we are free to outflow to it.
    if (e?.response?.status === 404) {
      return result;
    }
  }

  return result;
}

const MAX_NAME_LENGTH = 20;

export function validateResourceName(name) {
  if (trim(name) === "") {
    return "Name cannot be blank.";
  }
  if (name.length > MAX_NAME_LENGTH) {
    return "Name cannot be longer than 20 characters.";
  }
  return "";
}
