import React from "react";

import { getDollarValue } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";
import { formatCurrency } from "Utils/strings";

export const AmountCell = ({ cell }) => {
  const {
    BTC: { value: usdPrice },
  } = useMemoizedState("crypto.prices.current");
  const cellData = cell.getValue();

  if (cellData.error) {
    return (
      <div className="text-right">
        <div className="text-sm text-red-500">{cellData.value} BTC</div>
        <div className="text-xs text-gray-600">$---</div>
      </div>
    );
  }

  const usdValue = getDollarValue(cellData.value, usdPrice);
  const formattedUsdValue = `$${formatCurrency(usdValue, 2, false, true)}`;
  const btcAmountFormatted = `${formatCurrency(cell.getValue().value, 8, false, false)} BTC`;

  return (
    <div className="text-right">
      <div className="text-sm text-gray-600">{btcAmountFormatted}</div>
      <div className="text-xs text-gray-600">{formattedUsdValue}</div>
    </div>
  );
};
