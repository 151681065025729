import { Button } from "@unchained/component-library";
import cn from "classnames";

import Key from "Assets/images/dashboard/Key.png";
import Vault from "Assets/images/dashboard/Vault.png";

const titleOpts = {
  default: "Set up Unchained on your own",
  keys: "Want to learn how to easily add your keys?",
  vaults: "Want to learn how to create a vault?",
};

export const SELF_ONBOARDING_LINK = "https://diy.unchained.com/";

export function DIYOnboardingCTA({ type }: { type: "keys" | "vaults" }) {
  let title = titleOpts.default;
  if (type === "keys") {
    title = titleOpts.keys;
  }
  if (type === "vaults") {
    title = titleOpts.vaults;
  }

  return (
    <div className="relative mx-auto flex max-w-[1000px] flex-col content-center items-center gap-4 overflow-hidden bg-gradient-to-b from-[#F1EBE0] to-[#F9F7F2] p-8 text-center md:min-w-[744px]">
      <h2 className="z-10 font-serif text-d-sm font-med">{title}</h2>
      <p className="z-10 max-w-[524px]">
        Get started quickly using the Self-Service Onboarding guide, which covers everything you
        need to know about using Unchained.
      </p>
      <Button className="z-10 max-w-[280px]" type="primary" to={SELF_ONBOARDING_LINK} rel="">
        Open Self-Service Onboarding
      </Button>

      <img
        className={cn(
          "absolute -right-8 z-0",
          type === "vaults" ? "top-8 max-w-[250px]" : "top-1 max-w-[210px]"
        )}
        src={type === "vaults" ? Vault : Key}
        alt="Unchained key"
      ></img>
    </div>
  );
}
