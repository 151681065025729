import React from "react";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { IconButton, Tooltip } from "@mui/material";
import cx from "classnames";

import { ConfirmOnDeviceWizard } from "Components/wallets/ConfirmOnDeviceWizard";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";

import styles from "./ConfirmOnDeviceIcon.module.scss";

export const ConfirmOnDeviceIcon = ({
  address,
  onClick,
  classNames,
}: {
  address: string;
  onClick?: () => void;
  classNames?: string;
}) => {
  const handleClick = onClick
    ? onClick
    : () => AppModalManager.open(() => <ConfirmOnDeviceWizard address={address} />);
  return (
    <Tooltip title="Confirm on device">
      <IconButton
        onClick={handleClick}
        classes={{ root: cx(styles.button, classNames) }}
        size="large"
        data-testid="confirm-on-device-button"
      >
        <AssignmentTurnedInIcon classes={{ root: styles.icon }} />
      </IconButton>
    </Tooltip>
  );
};
