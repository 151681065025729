import React, { useMemo } from "react";

import { DropdownProps } from "@unchained/component-library";
import { useField } from "formik";

import { FormikDropdown } from "../FormikDropdown";
import { CountryCode, countryStateData } from "./CountryStateData";

type FormikStateSelectorProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  countryCode?: CountryCode;
  /** Track state name in formik, not state code */
  useFullState?: boolean;
} & DropdownProps;

export const FormikStateSelector = ({
  name,
  countryCode = "US",
  useFullState = true,
  ...props
}: Partial<FormikStateSelectorProps>) => {
  const [field] = useField(name);
  const isInternational = countryCode !== "US";

  const regions = useMemo(() => countryStateData.regionOptions(countryCode), [countryCode]);
  const selectedRegion = useMemo(
    () => countryStateData.getRegion(countryCode, field.value),
    [countryCode, field.value]
  );

  const prepareValue = useFullState
    ? (opt: { value: string; label: string }) => opt.label
    : undefined;

  return (
    <FormikDropdown
      options={regions}
      label={isInternational ? "Region" : "State"}
      name={name}
      selectedLabels={selectedRegion ? [selectedRegion.name] : []}
      placeholder={props.placeholder || "Select a state"}
      fullWidth
      data-testid="state"
      prepareValue={prepareValue}
      {...props}
    />
  );
};
