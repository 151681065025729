import React, { useContext, useEffect, useState } from "react";

import { ModalContent, ModalFooter } from "@unchained/component-library";

import { InstructionList } from "Components/Shared/Elements/InstructionList";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";

const steps = [
  { title: "Plug in Trezor", subtitle: "Ensure you have popups enabled." },
  {
    title: "Open Trezor Connect, follow instructions",
    subtitle: "Confirm in the Trezor Connect window that you want to 'Export multiple addresses'.",
  },
  {
    title: "Return to this screen",
    subtitle:
      "Once you see the address on your device, confirm that it matches the one shown in the next step. (Do not compare to Trezor Connect.)",
  },
];

export const TrezorConnectionOverviewStep = () => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const { handleNext } = useContext(ConfirmOnDeviceContext);

  useEffect(() => {
    setTimeout(() => setNextDisabled(false), 3000);
  }, []);

  return (
    <>
      <ModalContent>
        <InstructionList steps={steps} />
      </ModalContent>
      <ModalFooter actions={[{ children: "Next", onClick: handleNext, disabled: nextDisabled }]} />
    </>
  );
};
