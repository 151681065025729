import React, { Fragment, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import { Fade, Grid, Paper, Popper } from "@mui/material";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedSigRequestAction } from "Actions/transactionActions/spendingActions";
import { KeyItem } from "Components/Shared/Elements/Keys/KeyItem";
import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";
import { AccountKey } from "Components/VaultsView/types";
import { useMemoizedState } from "Redux/selectors/hooks";
import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
} from "Redux/selectors/spendingSelectors";
import { useGetProductQuery } from "Shared/api/hooks";
import { transactionIsPastThreshold } from "Utils/vaultUtils";

import { KeySignedPsbt } from "../KeySignedPsbt";
import wizStyles from "../SpendingWizard.module.scss";
import { TransactionDetails } from "../TransactionDetails";
import { NotRequiredForSigning } from "./NotRequiredForSigning";
import styles from "./SigningKeyList.module.scss";
import { SigningWizard } from "./SigningWizard";
import { WaitingForApproval } from "./WaitingForApproval";
import { WaitingForSigning } from "./WaitingForSigning";

interface SigRequestProps {
  sigRequest: {
    name: string;
    account_key: AccountKey;
    signed_psbt?: string;
    uuid: string;
  };
  handleSelectKey: (object, HTMLButtonElement) => void;
  selectedSigRequestUUID: string;
  orgUUID: string;
  currentKey: MutableRefObject<HTMLSpanElement>;
  isUnchainedSigAutoRequested?: boolean;
  isKeyCard?: boolean;
  onClick: (object, HTMLButtonElement) => void;
  isSigningPopperShowing?: boolean;
}

const SigRequest = ({
  sigRequest,
  handleSelectKey,
  selectedSigRequestUUID,
  orgUUID,
  currentKey,
  isUnchainedSigAutoRequested,
  isKeyCard,
  onClick,
  isSigningPopperShowing,
}: SigRequestProps) => {
  const selected = sigRequest.uuid === selectedSigRequestUUID;
  const ref = useRef<HTMLSpanElement>(null);
  const name = get(sigRequest, "account_key.name");
  const accountKey = get(sigRequest, "account_key");
  const shared = orgUUID && get(sigRequest, "account_key.owner.uuid") !== orgUUID;
  const ownerName = shared && get(sigRequest, "account_key.owner.name");
  const logoSlug = accountKey.logo_slug || accountKey.logoSlug || "";

  const isKeyDisabled =
    isUnchainedSigAutoRequested && accountKey.role === "unchained" && !sigRequest.signed_psbt;

  const handleClick = e => {
    onClick(sigRequest, e.currentTarget);
  };

  useEffect(() => {
    if (selected && ref.current) {
      currentKey.current = ref.current;
      handleSelectKey(sigRequest, ref.current.firstChild);
    }
  }, [currentKey, handleSelectKey, selected, sigRequest]);

  return (
    <span ref={ref}>
      <KeyItem
        name={name}
        accountKey={accountKey}
        size={selected ? "md" : "sm"}
        active={selected}
        shared={shared}
        ownerName={ownerName}
        onClick={handleClick}
        signedPsbt={sigRequest.signed_psbt}
        disabled={isKeyDisabled}
        isCard={isKeyCard}
        isSigningPopperShowing={isSigningPopperShowing}
        role={accountKey.role}
        logoSlug={logoSlug}
      />
    </span>
  );
};

interface SigningKeyContentProps {
  selectedSigRequest: SignatureRequest;
  txRequestState: string;
  isLoadingProduct: boolean;
  isTxPastThreshold: boolean;
}
const SigningKeyContent = ({
  selectedSigRequest,
  txRequestState,
  isLoadingProduct,
  isTxPastThreshold,
}: SigningKeyContentProps) => {
  if (selectedSigRequest.state === "complete") {
    return (
      <KeySignedPsbt
        signedPsbt={selectedSigRequest.signed_psbt}
        operationUUID={selectedSigRequest.uuid}
        accountKeyName={selectedSigRequest.account_key.name || selectedSigRequest.account_key.uuid}
      />
    );
  }
  if (["ready_to_sign", "partially_signed"].includes(txRequestState)) {
    if (selectedSigRequest?.account_key?.allowed_actions.includes("create_signature")) {
      if (selectedSigRequest.state === "pending") {
        return <WaitingForApproval />;
      } else {
        return <SigningWizard />;
      }
    } else {
      if (selectedSigRequest.requested || selectedSigRequest?.account_key?.role === "arbiter") {
        return <WaitingForSigning />;
      } else {
        return (
          <NotRequiredForSigning
            isTxPastThreshold={isTxPastThreshold}
            isLoadingProduct={isLoadingProduct}
          />
        );
      }
    }
  }

  // We should never get here but will catch just in case
  return (
    <WizardComponentWrapper size="sm">
      <h3 className="mb-4">NO SIGNATURE PROVIDED</h3>
    </WizardComponentWrapper>
  );
};

const useTypedSelector = (selector): Record<string, any> => useSelector(selector);
export const SigningKeyList = ({
  autoRequestUnchainedKeys = false,
  isUseKeyCards = false,
  showTransactionSummary = true,
  isPopperOverFlowParent = false,
  isPopperHideable = false,
  keyHolderStyles = styles.keyHolder,
  hideUnrequestedSignatures = false,
  onPopperChange,
}: {
  autoRequestUnchainedKeys?: boolean;
  isUseKeyCards?: boolean;
  showTransactionSummary?: boolean;
  isPopperOverFlowParent?: boolean;
  isPopperHideable?: boolean;
  keyHolderStyles?: string;
  hideUnrequestedSignatures?: boolean;
  onPopperChange?: (isOpen: boolean) => void;
}) => {
  const { orgUUID } = useMemoizedState("account.orgs.current.uuid");
  const { selectedSigRequestUUID, selectedSigRequest, sigRequests } = useTypedSelector(
    spendingSigRequestsSelector
  );
  // This operation is coming back without the product on it!?!
  const { spending, txRequestState, operation } = useTypedSelector(spendingOperationSelector);

  const currentKey = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const [arrowRef, setArrowRef] = useState();

  const [hidePopper, setHidePopper] = useState(isPopperHideable);

  const dispatch = useDispatch();
  const setSelectedSigRequest = useCallback(
    sigRequest => dispatch(setSelectedSigRequestAction(sigRequest)),
    [dispatch]
  );

  const [previousSelectedSigRequest, setPreviousSelectedSigRequest] =
    useState(selectedSigRequestUUID);

  const vaultQuery = useGetProductQuery(operation);
  // True if the operation is spending more than product's spend-threshold is set to.
  const isTxPastThreshold =
    vaultQuery.isSuccess && transactionIsPastThreshold(vaultQuery.data, spending.transaction);

  const open = Boolean(selectedSigRequest) && !hidePopper;
  const id = open ? "signing-key-details" : null;

  useEffect(() => {
    let request;
    if (Array.isArray(sigRequests)) {
      // Get the first unsigned sig request (unchained sig req should be last)

      request =
        sigRequests.find(el => !el.signed_psbt && el.state === "approved") || sigRequests[0];
    }
    if (request) {
      if (isPopperHideable) {
        // if the popper is hideable then the user controls the
        // popper and we can hide the popper after each request
        // change.
        setHidePopper(true);
        setPreviousSelectedSigRequest(request.uuid);
      } else {
        setSelectedSigRequest(request);
      }
    }
  }, [sigRequests, setSelectedSigRequest, setHidePopper, isPopperHideable]);

  useEffect(() => {
    if (onPopperChange) {
      onPopperChange(open);
    }
  }, [onPopperChange, open]);

  const handleArrowRef = node => setArrowRef(node);

  const handleSelectKey = React.useCallback(
    (sigRequest, el) => {
      setAnchorEl(el);
      setSelectedSigRequest(sigRequest);
    },
    [setSelectedSigRequest]
  );

  const handleSigRequestClick = React.useCallback(
    (selectedSigRequest, el) => {
      if (isPopperHideable) {
        if (previousSelectedSigRequest === selectedSigRequest.uuid && !hidePopper) {
          // if we are clicking on the same key as the previous one we clicked on
          // and the popper is already showing, hide the popper.
          setHidePopper(true);
        } else {
          // if we are clicking on a different key
          // show the popper
          setHidePopper(false);
        }
        setPreviousSelectedSigRequest(selectedSigRequest.uuid);
      }

      handleSelectKey(selectedSigRequest, el);
    },
    [handleSelectKey, hidePopper, isPopperHideable, previousSelectedSigRequest]
  );

  const SigRequests = React.useMemo(() => {
    let sigRequestsToDisplay = sigRequests;

    if (hideUnrequestedSignatures) {
      sigRequestsToDisplay = sigRequests.filter(sigRequest => sigRequest.requested === true);
    }

    const sigRequestsComponents: [React.ReactNode] = sigRequestsToDisplay.map(request => {
      if (request.account_key?.allowed_actions?.length === 0 || !request.account_key?.uuid) {
        // Then the user has no permissions to view or use the key
        return null;
      }

      return (
        <Grid key={request.uuid} item>
          <SigRequest
            sigRequest={request}
            handleSelectKey={handleSelectKey}
            selectedSigRequestUUID={selectedSigRequestUUID}
            orgUUID={orgUUID}
            currentKey={currentKey}
            isUnchainedSigAutoRequested={autoRequestUnchainedKeys}
            isKeyCard={isUseKeyCards}
            onClick={handleSigRequestClick}
            isSigningPopperShowing={!hidePopper}
          />
        </Grid>
      );
    });

    return () => (sigRequestsComponents.length ? <>{sigRequestsComponents}</> : null);
  }, [
    autoRequestUnchainedKeys,
    handleSelectKey,
    handleSigRequestClick,
    hidePopper,
    hideUnrequestedSignatures,
    isUseKeyCards,
    orgUUID,
    selectedSigRequestUUID,
    sigRequests,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid
        container
        item
        xs={12}
        md={showTransactionSummary ? 6 : 12}
        classes={{ container: styles.keyContent }}
      >
        <Grid container item xs={12} spacing={2} classes={{ container: keyHolderStyles }}>
          <SigRequests />
        </Grid>
        <Grid item>
          {anchorEl && document.body.contains(anchorEl) && (
            <Popper
              style={isPopperOverFlowParent ? { zIndex: 1300 } : undefined}
              id={id}
              open={open}
              anchorEl={anchorEl}
              disablePortal={isPopperOverFlowParent ? false : true}
              placement="bottom"
              modifiers={[
                {
                  name: "flip",
                  options: {
                    behavior: ["bottom"],
                  },
                },
                {
                  name: "preventOverflow",
                  enabled: true,
                  options: {
                    enabled: true,
                    boundariesElement: "scrollParent",
                  },
                },
                {
                  name: "arrow",
                  enabled: true,
                  options: {
                    enabled: true,
                    element: arrowRef,
                  },
                },
              ]}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Fragment>
                    <span className={styles.arrowUp} ref={handleArrowRef} />
                    <Paper classes={{ root: styles.contents }} elevation={3}>
                      <SigningKeyContent
                        selectedSigRequest={selectedSigRequest}
                        txRequestState={txRequestState}
                        isTxPastThreshold={isTxPastThreshold}
                        isLoadingProduct={vaultQuery.isLoading}
                      />
                    </Paper>
                  </Fragment>
                </Fade>
              )}
            </Popper>
          )}
        </Grid>
      </Grid>
      {showTransactionSummary ? (
        <Grid className={wizStyles.transactionDetails} item xs={12} md={6}>
          <TransactionDetails showApprovals={true} />
        </Grid>
      ) : null}
    </Grid>
  );
};
