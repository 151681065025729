export const operationVerbClauseMap = {
  discovered_bitcoin_transaction: "discovered transfer",
  manual_change: "manual transfer",
  rekey: "rekey",
  external_vault_deposit: "deposit",
  external_loan_deposit: "deposit",
  loan_payment: "pay",
  loan_principal_payment: "pay",
  loan_prepayment: "prepay",
  loan_disbursement: "disburse from",
  loan_collateral_sale: "sell from",
  vault_transaction: "transfer from",
  vault_sweep: "sweep of",
  loan_redemption: "redeem from",
  loan_liquidation: "liquidate from",
  loan_sweep: "sweep of",
  loan_closing: "closing of",
  batch_settlement: "batch settlement from",
};

/**
 * Returns a verb that would fit the sentence "By this action, I intend to __ 8 btc".
 * Should only be used for operations that spend coins.
 * @param {string} operationType The relevant Operation.type.
 */
export function presentTransactionVerb(operationType) {
  if (operationType.includes("liquidation")) {
    return "liquidate";
  }
  if (operationType.includes("sweep")) {
    return "sweep";
  }
  if (operationType.includes("redemption") || operationType === "loan_closing") {
    return "redeem";
  }
  // else
  return "send";
}
