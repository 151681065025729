import React from "react";

export const Settings = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12 23c-.6 0-1.2-.2-1.8-.5s-.9-.8-1.2-1.4l-.6-1.3c-.1-.3-.3-.5-.6-.7-.2-.1-.5-.2-.8-.2l-1.4.2c-.6.1-1.2 0-1.8-.3-.6-.3-1-.7-1.3-1.3-.3-.5-.5-1.2-.5-1.8 0-.6.2-1.2.6-1.7l.8-1.2c.2-.2.3-.5.3-.8s-.1-.6-.3-.8L2.7 10c-.4-.5-.6-1.1-.7-1.7 0-.6.2-1.3.5-1.8s.8-1 1.3-1.3c.6-.2 1.2-.4 1.8-.3l1.4.2c.3 0 .6 0 .9-.2.3-.2.5-.4.6-.7l.6-1.3c.3-.6.7-1 1.2-1.4 1-.7 2.5-.7 3.5 0 .5.3.9.8 1.2 1.4l.6 1.3c.1.3.3.5.6.7.2.1.5.2.8.2l1.4-.2c.6-.1 1.2 0 1.8.3.6.3 1 .7 1.3 1.3.3.5.5 1.2.5 1.8 0 .6-.2 1.2-.6 1.7l-.8 1.2c-.2.2-.3.5-.3.8 0 .3.1.6.3.8l.8 1.2c.4.5.6 1.1.6 1.7 0 .6-.1 1.2-.4 1.8-.3.5-.8 1-1.3 1.3s-1.2.4-1.8.3l-1.5-.2c-.3 0-.6 0-.9.2-.3.2-.5.4-.6.7l-.6 1.3c-.3.6-.7 1-1.2 1.4-.5.3-1.1.5-1.7.5zm-1.7-4l.6 1.3c.1.2.3.4.4.5.4.3.9.3 1.3 0 .2-.1.4-.3.4-.5l.6-1.3c.3-.7.8-1.2 1.4-1.6.6-.4 1.3-.5 2.1-.4l1.4.2c.2 0 .5 0 .7-.1.2-.1.4-.3.5-.5.1-.2.2-.4.2-.7 0-.2-.1-.5-.2-.6l-.8-1.3c-.4-.6-.7-1.3-.7-2s.2-1.4.7-2l.8-1.2c.1-.2.2-.4.2-.6 0-.2 0-.5-.2-.7-.1-.2-.3-.4-.5-.5-.2-.1-.4-.1-.7-.1l-1.4.2c-.7.1-1.4-.1-2.1-.4-.5-.5-1-1-1.3-1.7l-.6-1.3c-.1-.2-.3-.4-.4-.5-.4-.3-.9-.3-1.3 0-.2.1-.4.3-.4.5L10.3 5c-.3.7-.8 1.2-1.4 1.6-.6.4-1.4.5-2.1.5l-1.4-.2c-.2 0-.5 0-.7.1-.2.1-.4.3-.5.5-.1.2-.2.4-.2.7s.1.5.2.6l.9 1.2c.4.6.7 1.3.7 2s-.2 1.4-.7 2l-.8 1.2c-.1.2-.2.4-.2.6s0 .5.2.7c.1.2.3.4.5.5.2.1.4.1.7.1l1.4-.2c.7-.1 1.4.1 2.1.4.5.4 1 1 1.3 1.7z"
    />
    <path
      fill="currentColor"
      d="M12 16c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
    />
    <path d="M0 0H24V24H0z" />
  </svg>
);
