import React, { useContext } from "react";

import { LedgerWalletRegistrationStep } from "Components/wallets/WalletRegistrationWizard/steps";
import { useGetWalletConfig } from "Shared/api";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";
import { ModalLoader } from "./ModalLoader";

export const UnregisteredLedgerStep = () => {
  const { walletUuid, handleNext, accountKey } = useContext(ConfirmOnDeviceContext);
  const walletConfigQuery = useGetWalletConfig(walletUuid);

  if (walletConfigQuery.isLoading) return <ModalLoader />;

  return (
    <LedgerWalletRegistrationStep
      walletConfig={walletConfigQuery.data}
      handleNext={handleNext}
      xfp={accountKey.xfp}
    />
  );
};
