import { ROLE_OPTIONS } from "Routes/manage/utils";
import { ChangeProposalType, OrgMemberChangeProposal } from "Shared/api/changeProposalApi";

export default function OrgMemberChange({
  changeProposal,
}: {
  changeProposal: OrgMemberChangeProposal;
}) {
  const roles = changeProposal.roles || [];

  const firstMatchingRole = ROLE_OPTIONS.find(option =>
    roles.find(role => role.name === option.plural)
  );

  const action =
    changeProposal.type === ChangeProposalType.OrgMemberInviteProposal ? "Invite" : "Remove";

  return (
    <div>
      <h3 className="mb-2">{action} member</h3>
      <div className="mb-4 w-80 rounded border border-gray-200 bg-gray-50 px-3 py-2">
        <div className="text-md font-semi">{changeProposal?.email}</div>
        <div className="text-gray-500">{firstMatchingRole?.label}</div>
      </div>
    </div>
  );
}
