import { pluralize } from "Utils/strings";

export const splitOperationType = operationType => {
  let [product, operation] = operationType.split("_");
  if (product === "batch") {
    product = "vault";
  }
  return [product, operation];
};

export const splitOperationTypePlural = operationType =>
  splitOperationType(operationType).map(word => pluralize(word));

export const splitOperationDescription = (operationDescription = "") => {
  const splitOperation = operationDescription.split(" ");

  return { operationType: splitOperation[0], operationUUID: splitOperation[1] };
};
