import $ from "jquery";

const ga = window.ga;

(function () {
  function trackEvent(args) {
    try {
      var event = {
        hitType: "event",
        eventCategory: args.category,
        eventAction: args.action,
      };
      let eventDescription = "GA: Event " + args.category + " -> " + args.action;
      var label = args.label;
      var value = args.value;
      if (typeof label != "undefined" && label != null) {
        event.eventLabel = label;
        eventDescription += " (" + label + ")";
      }
      if (typeof value != "undefined" && value != null) {
        event.eventValue = value;
        eventDescription += " [" + value + "]";
      }
      if (process.env.REACT_APP_UNCHAINED_LOG) {
        console.log(eventDescription);
      }
      ga("send", event);
    } catch (e) {
      return null;
    }
  }

  function handleEventTracking() {
    var elem = $(this);
    trackEvent({
      category: elem.attr("data-category"),
      action: elem.attr("data-action"),
      label: elem.attr("data-label"),
      value: elem.attr("data-value"),
    });
    return true;
  }

  function getTrackingID() {
    try {
      return ga.getAll()[0].get("clientId");
    } catch (e) {
      return null;
    }
  }

  function enableEventTracking() {
    $("a.track-click").click(handleEventTracking);
    $("button.track-submit").submit(handleEventTracking);
    $("button.track-click").click(handleEventTracking);
  }

  $(document).ready(function () {
    enableEventTracking();
  });

  window.trackEvent = trackEvent;
  window.getTrackingID = getTrackingID;
})();
