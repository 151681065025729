import { Button, ButtonProps } from "@unchained/component-library";
import { omit } from "lodash";

/**
 * For all previous button components (PrimaryButton, SecondaryButton, WarningButton, etc),
 * converts incoming props to new Button props, in one place.
 * */
export const mapOldButtonProps = (
  {
    link,
    text,
    icon,
    iconPosition,
    iconClasses,
    type,
    buttonClasses,
    href,
    variant,
    ...sharedIncoming
  },
  defaultType: ButtonProps["type"]
): ButtonProps => {
  const outgoing = { ...sharedIncoming };
  if (link || href) {
    outgoing.to = link || href;
  }
  if (icon) {
    const Icon = icon;
    const position = !iconPosition || iconPosition === "left" ? "startIcon" : "endIcon";
    outgoing[position] = <Icon />;
  }
  if (type) {
    outgoing.buttonType = type;
  }
  if (text) {
    outgoing.children = text;
  }
  if (buttonClasses) {
    outgoing.className = outgoing.className
      ? `${outgoing.className} ${buttonClasses}`
      : buttonClasses;
  }
  outgoing.type = variant === "text" ? "text" : defaultType;
  return omit(outgoing, "color", "external") as ButtonProps;
};

/** Deprecated! Just use the Button component from the CL directly */
export const WarningButton = props => {
  return <Button {...mapOldButtonProps(props, "destructive")} />;
};
