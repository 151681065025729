import { Rekey } from "Specs/v1/getRekey/200";
import { Vault } from "Specs/v1/getVault/200";
import { Loan } from "Specs/v1/showLoan/200";

import { KeyAPI, LoanAPI, VaultAPI } from "..";

type ObjectRequest<T> = (uuid: string) => Promise<T>;

export const getValidatedObjectWithRetries = async <T>({
  objectUuid,
  Get,
  objectValidator,
  tries = 5,
  retryDelay = 300,
}: {
  objectUuid: string;
  Get: ObjectRequest<T>;
  objectValidator: (object: T) => boolean;
  tries?: number;
  retryDelay?: number;
}) => {
  const object: T = await Get(objectUuid);
  if (object && objectValidator(object)) {
    return object;
  } else if (tries === 1) {
    // If we got here then there was probably a problem with
    // creating the object being requested. Will need to check
    // backend logs
    throw new Error(`Could not retrieve object ${objectUuid}`);
  } else {
    // Wait for 300ms before retrying
    await new Promise(resolve => setTimeout(resolve, retryDelay));
    return await getValidatedObjectWithRetries({
      objectUuid,
      Get,
      objectValidator,
      tries: tries - 1,
    });
  }
};

export const getRekeyWithRetries = async (accountKeyUuid: string, tries = 5) => {
  const validator = ({ rekey }: { rekey: Rekey }) => Boolean(rekey && rekey.state);
  return await getValidatedObjectWithRetries({
    objectUuid: accountKeyUuid,
    Get: KeyAPI.GetRekey,
    objectValidator: validator,
    tries,
  });
};

export const getVaultWithRetries = async (vaultUuid: string, tries = 5) => {
  const validator = (vault: Vault) => Boolean(vault.current_wallet_uuid);
  return await getValidatedObjectWithRetries({
    objectUuid: vaultUuid,
    Get: VaultAPI.Get,
    objectValidator: validator,
    tries,
  });
};

export const getLoanWithRetries = async (loanUuid: string, tries = 5) => {
  const validator = (loan: Loan) => Boolean(loan.current_wallet_uuid);
  return await getValidatedObjectWithRetries({
    objectUuid: loanUuid,
    Get: LoanAPI.Get,
    objectValidator: validator,
    tries,
  });
};
