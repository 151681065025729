import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { LoanAPI, TransactionAPI, VaultAPI } from "Shared/api";
import { ApproveSigRequest200, GetTransactionRequests200, UnapproveSigRequest200 } from "Specs/v1";
import { TransactionRequest } from "Specs/v1/getTransactionRequests/200";
import { GetTransactionRequestsQueryParams } from "Specs/v1/getTransactionRequests/params/query";

export const txRequestsKeys = {
  all: ["txRequestList"] as const,
  list: (orgUuid, queryParams) => [...txRequestsKeys.all, orgUuid, { queryParams }],
  transaction: (productUuid, operationUuid, spendType) => [
    "transaction",
    productUuid,
    operationUuid,
    spendType,
  ],
};

export const useTxRequestListQuery = (
  orgUuid: string,
  queryParams: GetTransactionRequestsQueryParams = {}
) =>
  useQuery<GetTransactionRequests200, Error>(txRequestsKeys.list(orgUuid, queryParams), () =>
    TransactionAPI.ListBtcTransactionRequests(orgUuid, queryParams)
  );

export const useBtcTxSigRequestMutation = (approve: boolean, sigReqUuid: string) => {
  const queryKey = txRequestsKeys.all;
  const queryClient = useQueryClient();
  const mutationFunction = approve
    ? TransactionAPI.ApproveBtcTxSigRequest
    : TransactionAPI.UnapproveBtcTxSigRequest;

  return useMutation<ApproveSigRequest200 | UnapproveSigRequest200, Error>(
    () => mutationFunction(sigReqUuid),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(txRequestsKeys.all);
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (_err, _updatedItem, context: { previousList: TransactionRequest[] }) => {
        queryClient.setQueryData(queryKey, context.previousList);
      },
    }
  );
};

export const useGetTransaction = (productUuid: string, operationUUID: string, spendType: string) =>
  useQuery(txRequestsKeys.transaction(productUuid, operationUUID, spendType), async () => {
    const apiCall = {
      vault_sale: VaultAPI.GetTransaction,
      vault_transaction: VaultAPI.GetTransaction,
      vault_sweep: VaultAPI.GetSweep,
      vault_settlement: VaultAPI.GetSettlement,
      loan_redemption: LoanAPI.GetRedemption,
      loan_liquidation: LoanAPI.GetLiquidation,
      loan_closing: LoanAPI.GetClosing,
      loan_sweep: LoanAPI.GetSweep,
    }[`${spendType}`];

    if (!apiCall) {
      throw new Error(`Invalid spendType: ${spendType}`);
    }

    return apiCall(productUuid, operationUUID);
  });

export const useLocationGetSpendInfo = (): {
  spendType: string;
  productUuid: string;
  operationUuid: string;
  productType: string;
} => {
  const { uuid: productUuid, operation_uuid: operationUuid } = useParams<{
    uuid: string;
    operation_uuid: string;
  }>();
  const location = useLocation();
  const [_, productTypePlural, __, operationTypePlural] = location.pathname.split("/");
  const spendType = `${productTypePlural?.slice(0, -1)}_${operationTypePlural?.slice(0, -1)}`;
  const productType = productTypePlural?.slice(0, -1);

  return {
    spendType,
    productUuid,
    operationUuid,
    productType,
  };
};
