export function setField(context, name, value, errors) {
  var action = {
    type: `${context.toUpperCase()}/SET/${name.toUpperCase()}`,
    errors: errors,
  };
  action[name] = value;
  return action;
}

export function toggleField(context, name) {
  return {
    type: `${context.toUpperCase()}/TOGGLE/${name.toUpperCase()}`,
  };
}

export function submittingRequest(context, name) {
  return {
    type: [context, name, "SUBMITTING"].join("/"),
  };
}

export function requestFailed(context, name, errors) {
  return {
    type: [context, name, "ERROR"].join("/"),
    errors: errors,
  };
}

export function requestSuccess(context, name, args = {}) {
  return {
    ...{ type: [context, name, "SUCCESS"].join("/") },
    ...args,
  };
}

export function requestReset(context, name) {
  return {
    ...{ type: [context, name, "RESET"].join("/") },
  };
}

// No longer operates through Redux. Kept here to not change too much.
// This just cuts the querystring from the URL. It does not refresh the page.
export function reloadPage() {
  const newUrl = window.location.href.split("?")[0];
  window.history.pushState({ path: newUrl }, "", newUrl);
}

export function scrollToTop() {
  return {
    type: "SCROLL/TOP",
  };
}
