import React, { useContext } from "react";

import { LedgerConnectionOverviewModalStep } from "Components/wallets/LedgerConnectionOverviewModalStep";

import { WalletRegistrationContext } from "../WalletRegistrationWizard";

export const LedgerRegistrationOverviewStep = () => {
  const { setStepIndex, stepIndex, walletConfig } = useContext(WalletRegistrationContext);

  return (
    <LedgerConnectionOverviewModalStep
      walletUuid={walletConfig.uuid}
      handleNext={() => setStepIndex(stepIndex + 1)}
    />
  );
};
