import React from "react";

export const Loans = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill="currentColor"
      d="M9 23c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM9 9c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm11 5.9c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4C20.4 12.1 21 10.6 21 9c0-3.3-2.7-6-6-6-1.6 0-3.1.6-4.2 1.7-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4C10.9 1.8 12.9 1 15 1c4.4 0 8 3.6 8 8 0 2.1-.8 4.1-2.3 5.6-.2.2-.4.3-.7.3z"
    ></path>
    <path fill="none" d="M0 0H24V24H0z" />
  </svg>
);
