import React from "react";

import { useField } from "formik";
import merge from "lodash/merge";
import PropTypes from "prop-types";

import { DeprecatedTextField } from "./DeprecatedTextField";

export const DeprecatedFormikTextField = ({ name, helperText, showError = true, ...props }) => {
  const [field, { error, touched }] = useField(name);

  const hasError = error && touched;

  // Prioritize explicitly passed props over Formik-extracted props,
  // and default to empty string to avoid uncontrolled field error
  // if value is undefined (eg from API).
  const finalProps = merge(field, props, {
    value: props.value || field.value || "",
    error: hasError,
    helperText: hasError && showError ? error : helperText,
  });

  return <DeprecatedTextField {...finalProps} />;
};

DeprecatedFormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};
