import { matchPath } from "react-router-dom";

interface Options {
  get?: string;
  post?: string;
  put?: string;
  delete?: string;
  patch?: string;
}

/**
 * Tracks all uses of Prism mocks in the application.
 * Any example registered here will be redirected to Prism by any call using our API class.
 *
 * // Register that calls to this path should return the default example from Prism
 * TrefoilMockExamples.register("/orgs/:orgUuid", { get: "default" })
 * ...
 * TrefoilMockExamples.get("/orgs/a12sdf3adf9", "get")
 *
 * */
class PrismMockExamplesSingleton {
  paths = new Map();

  register = (path: string, options: Options) => {
    this.paths.set(path.split("?")[0], options);
  };

  get = (path: string, method: string): string => {
    let match;

    for (const pattern of this.paths.keys()) {
      const foundMatch = matchPath(pattern, path.split("?")[0]);
      if (foundMatch) {
        if (match) {
          console.error(`Multiple mock matches found for path pattern ${pattern}`);
        }
        match = this.paths.get(pattern);
      }
    }
    return match && match[method];
  };

  unregister = (path: string) => {
    this.paths.delete(path);
  };
}

export const TrefoilMockExamples = new PrismMockExamplesSingleton();
