import PropTypes from "prop-types";

import { bip32SequenceFromPath } from "Utils/wallet";

import { AccountKeyListing } from "./account_key_listing";
import { accountKeyProptypes } from "./account_keys_proptypes";
import { Signature, signatureProptypes } from "./signatures";

export function sliceMemberProptypes() {
  return {
    bip32_path: PropTypes.string,
    bip32_sequence: PropTypes.array,
    signature: PropTypes.shape(signatureProptypes()),
    created_at: PropTypes.string,
    strand: PropTypes.shape({
      account_key: PropTypes.shape(accountKeyProptypes()),
    }),
    public_key: PropTypes.string,
  };
}

export function SliceMember(data) {
  if (data == null) {
    return data;
  }
  if (data.signature) {
    data.signature = Signature(data.signature);
  }
  data.account_key = AccountKeyListing(data.account_key);
  if (data.bip32_path) {
    data.bip32_sequence = bip32SequenceFromPath(data.bip32_path);
  }
  return data;
}
