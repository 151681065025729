import React from "react";

import { useField } from "formik";
import PropTypes from "prop-types";

import { BitcoinAmountField } from "./BitcoinAmountField";

export const FormikBitcoinAmountField = ({ name, ...other }) => {
  const [field, { error, touched }] = useField(name);

  const hasError = error && touched;

  return (
    <BitcoinAmountField
      helperText={hasError ? error : null}
      error={hasError}
      {...field}
      {...other}
    />
  );
};

FormikBitcoinAmountField.propTypes = {
  name: PropTypes.string.isRequired,
};
