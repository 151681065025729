import React, { Component } from "react";

import cx from "classnames";

import styles from "./Loading.module.scss";

class LoadingBase extends Component {
  static propTypes = {};
  render() {
    const { loaderStyles } = this.props;

    const allLoaderStyles = cx(styles.loader, loaderStyles);

    return (
      <div data-testid="LoadingComponent" className={styles.container}>
        <div className={allLoaderStyles}>
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.ball} />
        </div>
      </div>
    );
  }
}

export const Loading = LoadingBase;
