import { HelpOutline } from "@mui/icons-material";
import { Button, ContentSwitcher, Tooltip } from "@unchained/component-library";

import { getDollarValue } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";
import { formatCurrency } from "Utils/strings";

import { CustomFees } from "./CustomFees";
import { FeeRateInfoBanner } from "./FeeRateInfoBanner";
import { StandardFees } from "./StandardFees";

export const FeesInput = ({
  estimatedFeeBitcoin,
  maxFeeExceeded,
}: {
  estimatedFeeBitcoin: string;
  maxFeeExceeded: boolean;
}) => {
  const {
    BTC: { value: usdPrice },
  } = useMemoizedState("crypto.prices.current");

  const estimatedFeeDollars = getDollarValue(estimatedFeeBitcoin, usdPrice);
  const btcFeeFormatted = `${formatCurrency(estimatedFeeBitcoin, 8, false, false)} BTC`;
  const usdFeeFormatted = `$${formatCurrency(estimatedFeeDollars, 2, false, true)}`;

  return (
    <>
      <span className="text-sm text-gray-700">Select fee rate</span>{" "}
      <Tooltip
        placement="right-end"
        content={
          <p className="md:w-64">
            This is the fee paid to bitcoin miners to confirm your transaction.
            <br />
            <br />
            Choose <b>high priority</b> to get your transaction confirmed urgently.
            <br />
            <br />
            Choose <b>low priority</b> if your transaction is less time-sensitive.
            <br />
            <br />
            <Button
              type="text"
              onClick={() => {
                window.open("https://unchained.com/blog/bitcoin-transaction-fees/", "_blank");
              }}
            >
              Learn more
            </Button>
          </p>
        }
      >
        <HelpOutline className="text-sm text-gray-400" />
      </Tooltip>
      <ContentSwitcher
        tabs={[
          {
            title: "Standard",
            content: <StandardFees maxFeeExceeded={maxFeeExceeded} />,
          },
          {
            title: "Custom",
            content: <CustomFees />,
          },
        ]}
      />
      <br />
      <FeeRateInfoBanner />
      <br />
      <div className="text-sm text-gray-700">
        <span>Fee estimate:</span>
        <div className="mt-2">
          <strong>{`${usdFeeFormatted} (${btcFeeFormatted})`}</strong>
        </div>
      </div>
    </>
  );
};
