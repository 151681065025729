/* global trackEvent ga */
import {
  AddOrUpdateAddress200,
  AddOrUpdateAddressRequest,
  ApproveOrg200,
  DeleteProfileDocument200,
  OrgPrincipalsAddUserLinkRequest,
  PutProfile200,
  PutProfileRequest,
  SubmitOrg200,
  UpdateProfileDocuments200,
  UpdateProfileDocumentsRequest,
  UpdateProfilePrincipal200,
  UpdateProfilePrincipalRequest,
} from "Specs/v1";
import { AddressRoleEnum } from "Specs/v1/getIraOrg/200";

import { API, API_V2 } from "./api";
import { DocLabel } from "./hooks";

export class ProfileAPI {
  //
  // Basic
  //

  static ActivatePersonal = async orgUUID => {
    const response = await API.Post(`/orgs/${orgUUID}/activate`);
    return response.data;
  };

  static UpdateBasic = async (orgUUID: string, data: PutProfileRequest) => {
    const response = await API.Put<PutProfile200>(`/orgs/${orgUUID}/profile`, data);
    return response.data;
  };

  static UpdateDocuments = async (orgUUID: string, data: UpdateProfileDocumentsRequest) => {
    const response = await API.PutFiles<UpdateProfileDocuments200>(
      `/orgs/${orgUUID}/profile/documents`,
      data
    );
    return response.data;
  };

  // admin/fcc doc update endpoint
  static PatchOrgDocuments = async (orgUUID: string, data: UpdateProfileDocumentsRequest) => {
    const response = await API_V2.PatchFiles<UpdateProfileDocuments200>(
      `/orgs/${orgUUID}/profile/documents`,
      data
    );
    return response.data;
  };

  static DeleteDocument = async (orgUUID: string, label: DocLabel) => {
    const response = await API.Delete<DeleteProfileDocument200>(
      `/orgs/${orgUUID}/profile/documents/${label}`
    );
    return response.data;
  };

  static DownloadDocument = async (label: DocLabel, orgUUID: string, principalUUID?: string) => {
    let url = `/orgs/${orgUUID}/profile`;
    if (principalUUID) url = `${url}/principals/${principalUUID}`;
    url = `${url}/documents/${label}`;
    await API.Download(url);
  };

  static UpdateAddress = async (
    orgUUID: string,
    role: AddressRoleEnum,
    data: AddOrUpdateAddressRequest
  ) => {
    const response = await API.Put<AddOrUpdateAddress200>(
      `/orgs/${orgUUID}/profile/addresses/${role}`,
      data
    );
    return response.data;
  };

  static DeleteAddress = async (orgUUID: string, role: AddressRoleEnum) => {
    const response = await API.Delete(`/orgs/${orgUUID}/profile/addresses/${role}`);
    return response.data;
  };

  static SubmitBasic = async orgUUID => {
    const response = await API.Post<SubmitOrg200>(`/orgs/${orgUUID}/profile/submit`);
    return response.data;
  };

  static SubmitPersonal = async orgUUID => {
    const response = await API_V2.Post(`/orgs/${orgUUID}/profile/submit-personal`);
    return response.data;
  };

  static ApproveBasic = async orgUUID => {
    const response = await API.Post<ApproveOrg200>(`/orgs/${orgUUID}/profile/approve`);
    return response.data;
  };

  static ApprovePersonalBasic = async orgUUID => {
    const response = await API_V2.Post(`/orgs/${orgUUID}/profile/approve-personal-basic`);
    return response.data;
  };

  static DenyBasic = async (orgUUID, data) => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/deny`, data);
    return response.data;
  };

  static DenyPersonalBasic = async (orgUUID, data) => {
    const response = await API_V2.Post(`/orgs/${orgUUID}/profile/deny-personal-basic`, data);
    return response.data;
  };

  static RequestBasicResubmission = async (orgUUID, data) => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/resubmission`, data);
    return response.data;
  };

  static RequestPersonalBasicResubmission = async (orgUUID, data) => {
    const response = await API_V2.Post(
      `/orgs/${orgUUID}/profile/resubmission-personal-basic`,
      data
    );
    return response.data;
  };

  //
  // Advanced Profile
  //

  static UpdateAdvanced = async (orgUUID, data) => {
    const response = await API.Put(`/orgs/${orgUUID}/profile/advanced`, data);
    return response.data;
  };

  static SubmitAdvanced = async orgUUID => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/advanced/submit`);
    return response.data;
  };

  static ApproveAdvanced = async orgUUID => {
    const data = { approved_for_lending: true };
    const response = await API.Post(`/orgs/${orgUUID}/profile/advanced/approve`, data);
    return response.data;
  };

  static ApprovePersonalAdvanced = async orgUUID => {
    const response = await API_V2.Post(`/orgs/${orgUUID}/profile/approve-personal-advanced`);
    return response.data;
  };

  static DenyAdvanced = async (orgUUID, data) => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/advanced/deny`, data);
    return response.data;
  };

  static DenyPersonalAdvanced = async (orgUUID, data) => {
    const response = await API_V2.Post(`/orgs/${orgUUID}/profile/deny-personal-advanced`, data);
    return response.data;
  };

  static RequestAdvancedResubmission = async (orgUUID, data) => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/advanced/resubmission`, data);
    return response.data;
  };

  static RequestPersonalAdvancedResubmission = async (orgUUID, data) => {
    const response = await API_V2.Post(
      `/orgs/${orgUUID}/profile/resubmission-personal-advanced`,
      data
    );
    return response.data;
  };

  //
  // Principals
  //

  static UpdatePrincipalBasic = async (orgUUID: string, data: UpdateProfilePrincipalRequest) => {
    const response = await API.Put<UpdateProfilePrincipal200>(
      `/orgs/${orgUUID}/profile/principals`,
      data
    );
    const { uuid } = response.data;
    return uuid;
  };

  static DeletePrincipal = async (orgUUID, personUuid) => {
    const response = await API.Delete(`/orgs/${orgUUID}/profile/principals/${personUuid}`);
    return response.data;
  };

  static UpdatePrincipalAdvanced = async (orgUUID, principalUUID, data) => {
    const response = await API.Put(
      `/orgs/${orgUUID}/profile/advanced/principals/${principalUUID}`,
      data
    );
    return response.data;
  };

  //
  // Addresses -- Principals
  //

  static UpdatePrincipalAddress = async (orgUUID, principalUUID, role, data) => {
    const response = await API.Put(
      `/orgs/${orgUUID}/profile/principals/${principalUUID}/addresses/${role}`,
      data
    );
    return response.data;
  };

  static DeletePrincipalAddress = async (orgUUID, principalUUID, role) => {
    const response = await API.Delete(
      `/orgs/${orgUUID}/profile/principals/${principalUUID}/addresses/${role}`
    );
    return response.data;
  };

  static UpdatePrincipalDocuments = async (orgUUID, principalUUID, data) => {
    const response = await API.PutFiles(
      `/orgs/${orgUUID}/profile/principals/${principalUUID}/documents`,
      data
    );
    return response.data;
  };

  //
  // Multi-Institution Vaults
  //

  static EnableMultiInstitutionVaults = async orgUUID => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/multi_institution_vaults/enable`);
    return response.data;
  };

  static DisableMultiInstitutionVaults = async orgUUID => {
    const response = await API.Post(`/orgs/${orgUUID}/profile/multi_institution_vaults/disable`);
    return response.data;
  };

  static AddUserLink = async (orgUuid: string, userUuid: string) => {
    const response = await API.Post<null>(`/orgs/${orgUuid}/profile/principals/add-user-link`, {
      userUuid,
    } as OrgPrincipalsAddUserLinkRequest);
    return response.data;
  };
}
