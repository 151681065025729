import React from "react";

export const RoleTooltip = () => (
  <div className="prose">
    <h3>User roles and rights</h3>
    <p>
      Each role is granted by the account Owner with a set of actions they are authorized perform.
      Briefly:
    </p>
    <h4>Admin</h4>
    <p>
      Admins have full administrative rights over the account. Admins can see and manage everything.
    </p>
    <h4>Approver</h4>
    <p>
      Approvers are limited to only approving transactions and account configuration changes.
      Trading must be enabled separately.
    </p>
    <h4>Viewer</h4>
    <p>Viewers have read-only access to all information in the platform.</p>
  </div>
);
