import { Dispatch, SetStateAction, useState } from "react";

import { PaginationState } from "@tanstack/react-table";

/**
 * a simple utility hook for managing react-table compliant
 * pagination properties
 * @param startPage
 * @param defaultRowsPerPage
 */
export const usePagination = (
  startPage = 0,
  defaultRowsPerPage = 10
): [PaginationState, Dispatch<SetStateAction<PaginationState>>] => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: startPage,
    pageSize: defaultRowsPerPage,
  });

  return [pagination, setPagination];
};
