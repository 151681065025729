import React, { Component, useState } from "react";

import { BCUREncoder, KEYSTORES, SignMultisigTransaction } from "@caravan/wallets";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Switch,
} from "@unchained/component-library";
import get from "lodash/get";
import moment from "moment";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";

import { postDeviceErrorAction } from "Actions/clientErrorActions/clientErrorActions";
import {
  getDeviceSpecificUnsignedPSBTAction,
  setSelectedSigRequestActiveAction,
} from "Actions/transactionActions/spendingActions";
import { Link } from "Components/Link";
import { ExpandInfo } from "Components/Shared/Elements/ExpandInfo";
import { Success } from "Components/Shared/Elements/Success";
import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";
import { ColdcardRegistrationModalContent } from "Components/wallets/WalletRegistrationWizard/steps/ColdcardRegistrationStep";
import {
  getOperationSourceWalletUuid,
  spendingOperationSelector,
  spendingSigRequestsSelector,
  spendingTransactionSelector,
} from "Redux/selectors/spendingSelectors";
import { ClientErrorAPI } from "Shared/api/clientErrorAPI";
import { useGetProductQuery, useGetWalletConfig } from "Shared/api/hooks";
import { TransactionAPI } from "Shared/api/transactionApi";
import { AppModal } from "Shared/components/Modals/AppModal";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { downloadTextFile } from "Utils/download";
import { bitcoinNetwork } from "Utils/wallet";

import {
  handleDeviceError,
  HermitQRCodeDisplayer,
  HermitQRCodeReader,
  PSBTFileReader,
} from "../../../SigningDevices";
import styles from "./SignKey.module.scss";
import { SignKeyContent } from "./SignKeyContent";

export const getPassphrase = (name = "") => {
  // Look for content [in brackets] at the end of the string
  const reg = /^[^[\n]+\[([^\]]*)\]$/g;
  let matches = reg.exec(name.trim());

  return matches ? matches[1] : undefined;
};

const ledgerBTCUnknownFeeWarning = partial => {
  return (
    <div className={styles.warningPrompt}>
      <ExpandInfo
        beforeBase="Seeing "
        base="fees display as `UNKNOWN`"
        afterBase={`?`}
        expandContent={ledgerBTCUnknownFeeExpanded(partial)}
        classes={{
          container: styles.ledgerFeeWarning,
          containerExpanded: styles.ledgerFeeExpanded,
        }}
      />
    </div>
  );
};

const ledgerBTCUnknownFeeExpanded = partial => {
  return (
    <p className={styles.ledgerFeeWarningContent}>
      The ledger application has a&nbsp;
      <a
        href="https://github.com/LedgerHQ/ledgerjs/issues/232"
        target="_blank"
        className="track-click"
      >
        known issue
      </a>
      &nbsp;which may cause the fees to display as `UNKNOWN`.
      <br />
      <span className={styles.ledgerWarningSpacer}>
        If the {partial ? "addresses and amounts " : "address and amount "}
        are correct, you should still sign the transaction.
      </span>
    </p>
  );
};

const trezorBip32Warning = bip32Path => {
  return (
    <div className={styles.warningPrompt}>
      <ExpandInfo
        beforeBase="Using a "
        base="Trezor model T"
        afterBase={<span className={styles.warningPrompt}>?</span>}
        expandContent={
          <p className={styles.trezorBip32Content}>
            Your device will ask you if <code>{bip32Path}</code> is an acceptable BIP32 path. Click
            "Yes". <br /> Press “Yes” for each deposit made into the address you are spending from.
          </p>
        }
        classes={{
          container: styles.trezorBip32,
          containerExpanded: styles.trezorBip32Expanded,
        }}
      />
    </div>
  );
};

const ColdcardUnknownMultisigWalletWarning = ({ accountUUID, accountType }) => {
  const { data: product } = useGetProductQuery({ [accountType]: { uuid: accountUUID } });
  const walletUuid = useSelector(getOperationSourceWalletUuid) || product.current_wallet_uuid;
  const walletConfig = useGetWalletConfig(walletUuid);

  if (!walletUuid) {
    return null;
  }

  return (
    <div className={styles.warningPrompt}>
      <div className={styles.coldcardConfigWarning}>
        <span>Seeing </span>
        <span
          onClick={() =>
            coldcardConfigModal({
              accountUUID,
              walletConfig: walletConfig.data,
            })
          }
          className={styles.clickableText}
        >
          'Failure: Unknown multisig wallet'
        </span>
        <span>?</span>
      </div>
    </div>
  );
};

function coldcardConfigModal({ accountUUID, walletConfig }) {
  AppModalManager.open(() => {
    const [nextEnabled, setNextEnabled] = useState(false);

    return (
      <Modal onDismiss={() => AppModalManager.close()}>
        <ModalHeader>
          <ModalTitle
            subtitle={
              <>
                Registering your Coldcard is easy, just follow the instructions below. For your
                security, this step is required before you can sign transactions with this device.{" "}
                <Link to="https://help.unchained.com/what-does-device-registration-mean">
                  Learn more.
                </Link>
              </>
            }
          >
            Register Coldcard with this wallet
          </ModalTitle>
        </ModalHeader>
        <ColdcardRegistrationModalContent
          walletConfig={walletConfig}
          productUuid={accountUUID}
          onDownloadConfig={() => setNextEnabled(true)}
        />
        <ModalFooter
          actions={[
            { children: "Next", disabled: !nextEnabled, onClick: () => AppModalManager.close() },
          ]}
        />
      </Modal>
    );
  });
}

class SignKeyBase extends Component {
  static propTypes = {
    selectedSigRequest: PropTypes.object.isRequired,
    name: PropTypes.string,
    unit: PropTypes.string,
    onSignSuccess: PropTypes.func.isRequired,
    setSelectedSigRequestActive: PropTypes.func.isRequired,
    utxoCount: PropTypes.number,
  };

  state = {
    errorMessage: "",
    showSuccess: false,
    psbt: this.props.selectedSigRequest.psbt,
    approve: false,
  };

  componentDidMount = async () => {
    const { walletType, selectedSigRequest, getDeviceSpecificUnsignedPSBT } = this.props;
    if (walletType === "hermit") {
      await getDeviceSpecificUnsignedPSBT(
        selectedSigRequest.uuid,
        // We want to include xpubs.
        true,

        // We want the server to endorse the PSBT.
        // true
        // FIXME -- but not yet, we haven't gotten it working.
        false
      );
    } else {
      // We don't need / can't understand xpubs in the PSBT and we don't need/can't understand server endorsement.
      await getDeviceSpecificUnsignedPSBT(selectedSigRequest.uuid);
    }
  };

  // only used for indirect key stores, for the
  // non-run methods (which are done in SignKeyContent)
  interaction() {
    const { selectedSigRequest } = this.props;
    try {
      const options = {
        keystore: this.props.walletType,
        network: bitcoinNetwork(),
        psbt: selectedSigRequest.unsigned_psbt,
        keyDetails: {
          xfp: selectedSigRequest.signing_xfp,
          path: selectedSigRequest.bip32_root,
        },
      };
      return SignMultisigTransaction(options);
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  encoder = () => {
    return BCUREncoder;
  };

  signIt = async () => {
    const { setSelectedSigRequestActive, selectedSigRequest, onSignSuccess } = this.props;
    const { approve } = this.state;
    try {
      setSelectedSigRequestActive(true);
      this.setState({ errorMessage: "" });

      const passphrase = getPassphrase(get(selectedSigRequest, "account_key.name", ""));

      let data = { approve };

      data.passphrase = passphrase || "";

      await TransactionAPI.QASign(data, selectedSigRequest.uuid);
      this.setState({ showSuccess: true });
      onSignSuccess();
    } catch (e) {
      e.errorType = "QA signing error";
      e.deviceError = e.message;
      e.cleanMessage = handleDeviceError(e);
      this.setState({ errorMessage: e.cleanMessage });
      await this._reportDeviceError(e);
    }
    setSelectedSigRequestActive(false);
  };

  handlePostSignature = async signature_data => {
    const { selectedSigRequest, onSignSuccess } = this.props;
    try {
      await TransactionAPI.AddSignatureToSignatureRequest(signature_data, selectedSigRequest.uuid);
      this.setState({ showSuccess: true });
      onSignSuccess();
    } catch (e) {
      const errorMessage = get(e, "response.data.message", "There was an error");
      this.setState({ errorMessage });
    }
  };

  _reportDeviceError = async e => {
    const { walletType, unit, currentSliceMember, accountType, accountUUID } = this.props;
    let info = {
      component: "SignKey",
      storeState: {
        walletType,
        unit,
        currentSliceMember,
        accountType,
        accountUUID,
      },
      errorType: e.errorType,
      response: e.response,
      message: e.cleanMessage,
      deviceError: e.deviceError,
    };
    await ClientErrorAPI.reportDeviceError(e, info);
  };

  getButton = () => {
    const { selectedSigRequestActive, walletType } = this.props;

    if (walletType === KEYSTORES.HERMIT) {
      return (
        <Button onClick={this.openHermitScanSignatureModal} disabled={selectedSigRequestActive}>
          {selectedSigRequestActive ? "Scanning..." : `Scan signature`}
        </Button>
      );
    }
    if (walletType === KEYSTORES.COLDCARD || walletType === KEYSTORES.CUSTOM) {
      return (
        <PSBTFileReader
          state="active"
          fileType="PSBT"
          validFileFormats=".psbt"
          currentSliceMember
          onSuccess={data => this.handlePSBTSignatureSubmission(data)}
        />
      );
    }
    return null;
  };

  openHermitSignatureRequestModal = () => {
    AppModalManager.open(() => (
      <AppModal>
        <HermitQRCodeDisplayer
          onCancel={() => AppModalManager.close()}
          onNext={() => {
            AppModalManager.close();
            this.openHermitScanSignatureModal();
          }}
          nextText="Scan signature"
          parts={this.interaction().request()}
        />
      </AppModal>
    ));
  };

  openHermitScanSignatureModal = () => {
    this.setState({ errorMessage: "" });
    AppModalManager.open(() => (
      <AppModal>
        <HermitQRCodeReader
          onSuccess={data => {
            AppModalManager.close();
            const signedPSBT = this.interaction().parse(data);
            this.handlePSBTSignatureSubmission(signedPSBT);
          }}
          onCancel={() => AppModalManager.close()}
          onError={errorMessage => this.setState({ errorMessage })}
        />
      </AppModal>
    ));
  };

  handlePSBTDownloadClick = () => {
    const { selectedSigRequest, setSelectedSigRequestActive, accountUUID } = this.props;
    let body = selectedSigRequest.unsigned_psbt;
    const timestamp = moment().format("HHmm");
    const filename = `${timestamp}-${accountUUID.substr(0, 4)}.psbt`;
    downloadTextFile(body, filename);
    setSelectedSigRequestActive(true);
    this.setState({
      psbt: body,
      errorMessage: "",
    });
  };

  handleAbandonPSBTSigning = () => {
    const { setSelectedSigRequestActive } = this.props;
    setSelectedSigRequestActive(false);
  };

  handlePSBTSignatureSubmission = async data => {
    const { setSelectedSigRequestActive } = this.props;
    try {
      await this.handlePostSignature({ signed_psbt: data });
    } catch (e) {
      e.errorType = "Coldcard signing error";
      this.setState({ errorMessage: e.message });
      await this._reportDeviceError(e);
    }
    setSelectedSigRequestActive(false);
  };

  toggleChecked = () => {
    const { approve } = this.state;
    this.setState({ approve: !approve });
  };

  getBtcContent = () => {
    const { walletType, selectedSigRequest, selectedSigRequestActive, accountUUID, accountType } =
      this.props;
    const signingKeyOptions = {
      keystore: walletType,
      psbt: selectedSigRequest.unsigned_psbt,
      signingXfp: selectedSigRequest.signing_xfp,
      bip32Path: selectedSigRequest.bip32_root,
      productType: accountType,
      productUuid: accountUUID,
      setErrorMessage: message => this.setState({ errorMessage: message }),
      handlePostSig: signature_data => this.handlePostSignature(signature_data),
      handleDeviceError,
      reportDeviceError: e => this._reportDeviceError(e),
      selectedSigRequestActive,
    };
    const { errorMessage, approve } = this.state;
    switch (walletType) {
      case KEYSTORES.TREZOR:
        return (
          <SignKeyContent
            classes={{ mainMessage: styles.trezorMessage }}
            warningMessage={trezorBip32Warning(selectedSigRequest.bip32_path)}
            active={selectedSigRequestActive}
            getSignButton={this.getButton}
            errorMessage={errorMessage}
            {...signingKeyOptions}
          />
        );
      case KEYSTORES.LEDGER:
        return (
          <SignKeyContent
            warningMessage={ledgerBTCUnknownFeeWarning()}
            active={selectedSigRequestActive}
            getSignButton={this.getButton}
            errorMessage={errorMessage}
            {...signingKeyOptions}
            originalPreSignButtonText="Register on Ledger"
            alreadyPreSignedButtonText="Register on Ledger"
          />
        );
      case KEYSTORES.COLDCARD:
        return (
          <SignKeyContent
            warningMessage={
              <ColdcardUnknownMultisigWalletWarning
                accountUUID={accountUUID}
                accountType={accountType}
              />
            }
            active={selectedSigRequestActive}
            getSignButton={this.getButton}
            errorMessage={errorMessage}
            preSignAction={this.handlePSBTDownloadClick}
            originalPreSignButtonText="Download PSBT"
            alreadyPreSignedButtonText="Re-download PSBT"
            onUnmount={this.handleAbandonPSBTSigning}
            {...signingKeyOptions}
          />
        );
      case KEYSTORES.CUSTOM:
        return (
          <SignKeyContent
            active={selectedSigRequestActive}
            getSignButton={this.getButton}
            preSignAction={this.handlePSBTDownloadClick}
            errorMessage={errorMessage}
            originalPreSignButtonText="Download PSBT"
            alreadyPreSignedButtonText="Re-download PSBT"
            onUnmount={this.handleAbandonPSBTSigning}
            {...signingKeyOptions}
          />
        );
      case KEYSTORES.HERMIT:
        return (
          <SignKeyContent
            active={selectedSigRequestActive}
            getSignButton={this.getButton}
            errorMessage={errorMessage}
            preSignAction={this.openHermitSignatureRequestModal}
            originalPreSignButtonText="Display signature request"
            alreadyPreSignedButtonText="Re-display signature request"
            onUnmount={this.handleAbandonPSBTSigning}
            {...signingKeyOptions}
          />
        );
      case "QA":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              height: "100%",
              minHeight: "12rem",
              marginBottom: "auto",
              padding: "0 0.5rem",
            }}
          >
            <div className="flex flex-col gap-2">
              <Switch
                onChange={this.toggleChecked}
                checked={approve}
                label="Approve all btc tx sig requests?"
              />
              <Button onClick={this.signIt}>Do It</Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    const { selectedSigRequest } = this.props;
    if (this.state.showSuccess) {
      return (
        <Success
          message={`${get(
            selectedSigRequest,
            "account_key.name",
            "This key"
          )} has signed successfully!`}
          size="sm"
        />
      );
    }
    return (
      <WizardComponentWrapper size="sm" classes={styles.container}>
        <h3 className={styles.smTitle}>
          Sign for Key {get(selectedSigRequest, "account_key.name", "")}
        </h3>
        {this.getBtcContent()}
      </WizardComponentWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...spendingOperationSelector(state),
    ...spendingTransactionSelector(state),
    ...spendingSigRequestsSelector(state),
  };
};

const mapDispatchToProps = {
  reportDeviceError: postDeviceErrorAction,
  setSelectedSigRequestActive: setSelectedSigRequestActiveAction,
  getDeviceSpecificUnsignedPSBT: getDeviceSpecificUnsignedPSBTAction,
};

export const SignKey = connect(mapStateToProps, mapDispatchToProps)(SignKeyBase);
