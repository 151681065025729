import React from "react";

import PropTypes from "prop-types";

import { parseTimestamp, readableTime, formatDate } from "Utils/time";

export function verificationProptypes(admin) {
  return {
    uuid: PropTypes.string,
    context: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    title: PropTypes.string,
    state: PropTypes.string,
    upload_params: PropTypes.object,
    download_urls: PropTypes.object,
    mime_types: PropTypes.object,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    username: PropTypes.string,
    rejected_reason: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  };
}

export function Verification(data) {
  if (data == null) {
    return data;
  }
  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  return data;
}
