import React, { useContext, useEffect, useRef, useState } from "react";

import { TextAddon, TextField } from "@unchained/component-library";
import { Timeout } from "react-number-format/types/types";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

const handleChange = (
  value: string,
  setInputState: React.Dispatch<React.SetStateAction<string>>
) => {
  if (!value.match(/^\d*$/) && !value.match(/^$/)) {
    // Value is only digits or empty string
    return;
  }

  setInputState(value);
};

export const CustomFees = () => {
  const { feeRate, setState } = useContext(WithdrawModalContext);
  const [inputState, setInputState] = useState(feeRate);
  const delay = useRef<Timeout>(null);

  useEffect(() => {
    if (inputState !== feeRate) {
      // Wait for the input delay before updating state. This delays how quickly
      // the FeeRateInfoBanner and Next button will update effectively
      // eliminating the jarring rerenders of these components as the user
      // types.
      clearTimeout(delay.current);
      delay.current = setTimeout(() => setState({ feeRate: inputState }), 500);
    }
  }, [inputState]);

  return (
    <TextField
      placeholder="enter a value"
      autoFocus
      fullWidth
      value={inputState}
      onChange={e => handleChange(e.target.value, setInputState)}
      endAddon={<TextAddon>sats/vB</TextAddon>}
    />
  );
};
