import React, { ReactElement, useContext, useState, useEffect } from "react";

import { HelpOutline } from "@mui/icons-material";
import { Divider, Collapse } from "@mui/material";
import {
  AddressString,
  Button,
  Tooltip,
  useToast,
  WizardStepperContext,
} from "@unchained/component-library";
import { useSelector } from "react-redux";

import { Fee } from "Contexts/BuyBitcoin/types";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { ReceivingAccountType } from "Contexts/SellBitcoin/types";
import { getCurrentOrg, getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { useUpdateSellBitcoinTxReview } from "Shared/api";
import { formatCurrency } from "Utils/strings";

import { TradingFeesTooltip } from "../../components/TradingFeesTooltip";
import {
  sellBitcoinNetworkFailureToastDescription,
  calculateSaleAmountInUSD,
  calculateFeeAmountInUSD,
  calculateAmountUSDToBeSentToClient,
} from "../../helpers";
import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { LoadableButton } from "../components/LoadableButton";
import { SaveProgressAndCloseButton } from "../components/SaveProgressAndCloseButton";
import { SellHeader } from "../components/SellHeader";
import { SigningKeyCard } from "../components/SigningKeyCard";

type ReviewTransactionStepProps = {
  onContinue: () => void;
  onClose: () => void;
};
export const ReviewTransactionStep = ({ onContinue, onClose }: ReviewTransactionStepProps) => {
  const { goToNext, goToPrev } = useContext(WizardStepperContext);
  const orgUuid: string = useSelector(getCurrentOrgId);
  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg.account_type === "ira";

  const { enqueueSimpleToast } = useToast();

  const {
    selectedKeys,
    saleAmountBTC,
    selectedSource,
    bankName,
    bankLastFourDigits,
    transactionFeeSatsVByte,
    feeRates,
    currentSaleUuid,
    bitcoinPrice,
    receivingAccountType,
    cashBalanceUsd,
  } = useSellBitcoinStore();

  const signingKeyCardsDisplay = selectedKeys.map(keyData => (
    <SigningKeyCard
      key={keyData.name}
      className="mr-4"
      title={keyData.name}
      isUnchained={keyData.isUnchained}
      isDelegatedKey={keyData.isDelegatedKey}
      logoSlug={keyData.logoSlug}
    />
  ));

  const handleOnContinueSuccess = () => {
    onContinue();
    goToNext();
  };

  const handleOnContinueError = () => {
    enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
  };

  const { mutate: updateSellBitcoinTxReview, isLoading: isLoadingUpdateSellBitcoinTxReview } =
    useUpdateSellBitcoinTxReview({
      orgUuid,
      currentSaleUuid,
      handleOnSuccess: handleOnContinueSuccess,
      handleOnError: handleOnContinueError,
    });
  const handleNext = () => {
    updateSellBitcoinTxReview();
  };

  const saleAmountUSD = calculateSaleAmountInUSD(bitcoinPrice, saleAmountBTC);
  const feeAmountUSD = calculateFeeAmountInUSD(saleAmountUSD, feeRates);
  const amountUSDToBeSentToClient = calculateAmountUSDToBeSentToClient(saleAmountUSD, feeAmountUSD);

  return (
    <CancelModalWrapper closeStepper={onClose}>
      <div className="max-h-screen w-[50vw] max-w-[700px]">
        <SellHeader
          onBack={goToPrev}
          title="Review transaction"
          subTitle="Carefully review the details below before proceeding."
        />
        <div className="flex w-full flex-col">
          <p className="mb-1 text-sm font-reg">Signing keys</p>
          <div className="mb-5 flex flex-col min-[765px]:flex-row">{signingKeyCardsDisplay}</div>
          <div className="mb-10 mr-8 w-full">
            <SummaryTable
              saleAmount={saleAmountBTC}
              vaultName={selectedSource?.name}
              feeAmountInUsd={feeAmountUSD}
              vaultId={selectedSource?.id}
              amountUSDToBeSentToClient={amountUSDToBeSentToClient}
              bankAccountName={bankName}
              bankAccountLastFourDigits={bankLastFourDigits}
              transactionFeeSatsVByte={transactionFeeSatsVByte}
              feeRates={feeRates}
              receivingAccountType={receivingAccountType}
              cashBalanceUsd={cashBalanceUsd}
              isIraOrg={isIraOrg}
            />
          </div>

          <div className="mb-4 rounded-lg border border-solid border-gray-300 pb-3 pl-4 pr-4 pt-3">
            <p className=" text-md font-med text-gray-600">
              Continuing to the next screen creates a transaction. No other transactions can be
              initiated from this vault until the sale is completed or canceled.
            </p>
          </div>

          <LoadableButton
            isLoading={isLoadingUpdateSellBitcoinTxReview}
            fullWidth={true}
            disabled={false || isLoadingUpdateSellBitcoinTxReview}
            type={"primary"}
            onClick={handleNext}
          >
            Continue
          </LoadableButton>
          <SaveProgressAndCloseButton onClose={onClose} />
        </div>
      </div>
    </CancelModalWrapper>
  );
};

type SummaryTableItemProps = {
  title: string | ReactElement;
  item: ReactElement | string;
  className?: string;
  toolTipContent?: string;
};
const SummaryTableItem = ({ title, item, className, toolTipContent }: SummaryTableItemProps) => {
  return (
    <div className={`flex flex-row justify-between ${className} mt-3`}>
      <div className="flex flex-row">
        <p className=" text-md font-reg text-gray-600">{title}</p>
        {toolTipContent ? (
          <Tooltip arrow placement="top" compact content={toolTipContent}>
            <HelpOutline className="relative top-[4px] ml-[3px]  text-lg text-gray-400" />
          </Tooltip>
        ) : null}
      </div>

      {item}
    </div>
  );
};

const boldStyle = "text-md font-med text-gray-800";
const normalStyle = "text-md font-reg text-gray-600";

type SummaryTableProps = {
  saleAmount: string;
  vaultName: string;
  vaultId: string;
  amountUSDToBeSentToClient: string;
  btcRecieveAddress?: string;
  btcRecieveAddressUnchained?: string;
  transactionFeeAmountBTC?: string;
  feeAmountInUsd: string;
  bankAccountName: string;
  bankAccountLastFourDigits: string;
  transactionFeeSatsVByte: string;
  transactionFeeAmountUSD?: string;
  isTruncatable?: boolean;
  feeRates: Fee[];
  receivingAccountType: ReceivingAccountType;
  cashBalanceUsd?: string;
  isIraOrg: boolean;
};
export const SummaryTable = ({
  saleAmount,
  vaultName,
  vaultId,
  amountUSDToBeSentToClient,
  btcRecieveAddress,
  transactionFeeAmountBTC,
  feeAmountInUsd,
  btcRecieveAddressUnchained,
  bankAccountName,
  bankAccountLastFourDigits,
  transactionFeeSatsVByte,
  transactionFeeAmountUSD,
  isTruncatable = false,
  feeRates,
  receivingAccountType,
  cashBalanceUsd,
  isIraOrg,
}: SummaryTableProps) => {
  const [isShowAdditionalDetails, setIsShowAdditionalDetails] = useState(false);

  const handleShowClick = () => {
    setIsShowAdditionalDetails(true);
  };
  const handleHideClick = () => {
    setIsShowAdditionalDetails(false);
  };

  return (
    <div className="w-full border border-gray-200 bg-gray-50 p-4 pt-1">
      <SummaryTableItem
        title="Bitcoin to be sold"
        item={<SaleAmountSummary saleAmount={saleAmount} />}
      />

      <Collapse in={!isTruncatable || (isTruncatable && isShowAdditionalDetails)}>
        <div>
          <SummaryTableItem
            title="Selling vault"
            item={<SellingFromSummary vaultId={vaultId} vaultName={vaultName} />}
          />
          {btcRecieveAddressUnchained ? (
            <SummaryTableItem
              title="Destination (Unchained)"
              item={<AddressString bolded address={btcRecieveAddressUnchained} />}
            />
          ) : null}
          {btcRecieveAddress ? (
            <SummaryTableItem
              title="Change address"
              item={<AddressString bolded address={btcRecieveAddress} />}
            />
          ) : null}

          <Divider className="mb-1 mt-4" />

          <SummaryTableItem
            title="Mining fee rate"
            item={<FeeRateSummary feeRate={transactionFeeSatsVByte} />}
            toolTipContent="The mining fee rate is set to high priority to ensure that your bitcoin transaction is processed as quickly as possible."
          />
          {transactionFeeAmountBTC && transactionFeeAmountUSD ? (
            <SummaryTableItem
              title="Mining fee estimate"
              item={
                <FeeEstimateSummary
                  feeAmountInBtc={transactionFeeAmountBTC}
                  feeAmountInUsd={transactionFeeAmountUSD}
                />
              }
            />
          ) : null}

          <Divider className="mb-1 mt-4" />

          <SummaryTableItem
            title="Receiving account"
            item={
              receivingAccountType === ReceivingAccountType.CASH_BALANCE ? (
                <ReceivingCashAccountSummary
                  cashBalanceUsd={cashBalanceUsd}
                  isIraCashAccount={isIraOrg}
                />
              ) : (
                <ReceivingBankAccountSummary
                  bankAccountLastFourDigits={bankAccountLastFourDigits}
                  bankAccountName={bankAccountName}
                />
              )
            }
          />
          <SummaryTableItem
            title={
              <>
                {[
                  "Unchained fee",
                  <TradingFeesTooltip
                    infoIconClass={"ml-1"}
                    key="tradingFeesToolTip"
                    fees={feeRates}
                  />,
                ]}
              </>
            }
            item={<p className={boldStyle}>${formatCurrency(feeAmountInUsd)}</p>}
          />
        </div>
      </Collapse>
      <SummaryTableItem
        title="Estimated proceeds"
        toolTipContent="Due to bitcoin price fluctuations, exact values won’t be shown until this transaction is finalized and broadcasted."
        item={<p className={boldStyle}>${formatCurrency(amountUSDToBeSentToClient)}</p>}
      />
      {!isShowAdditionalDetails && isTruncatable ? (
        <div className=" flex w-full justify-end">
          <Button
            onClick={handleShowClick}
            className={`mt-3 !text-xs !font-med !text-primary-600 hover:!text-gray-800`}
            type={"text"}
          >
            Show details
          </Button>
        </div>
      ) : null}
      {isShowAdditionalDetails && isTruncatable ? (
        <div className=" flex w-full justify-end">
          <Button
            onClick={handleHideClick}
            className={`mt-3 !text-xs !font-med !text-primary-600 hover:!text-gray-800`}
            type={"text"}
          >
            Hide details
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const SaleAmountSummary = ({ saleAmount }: { saleAmount: string }) => (
  <div>
    <p className={boldStyle}>
      {saleAmount} <span className={normalStyle}>BTC</span>
    </p>
  </div>
);

const SellingFromSummary = ({ vaultName, vaultId }: { vaultName: string; vaultId: string }) => {
  return (
    <div>
      <p className={boldStyle}>
        {vaultName} <span className={normalStyle}>ID: {vaultId}</span>
      </p>
    </div>
  );
};
export const FeeRateSummary = ({ feeRate }: { feeRate: string }) => {
  return (
    <div>
      <p className={boldStyle}>
        {feeRate} <span className={normalStyle}>sats/vB</span>
      </p>
    </div>
  );
};

export const FeeEstimateSummary = ({
  feeAmountInBtc,
  feeAmountInUsd,
}: {
  feeAmountInBtc: string;
  feeAmountInUsd: string;
}) => {
  return (
    <div>
      <p className={boldStyle}>
        {feeAmountInBtc} <span className={normalStyle}>BTC</span> ($
        {formatCurrency(feeAmountInUsd)})
      </p>
    </div>
  );
};

export const ReceivingBankAccountSummary = ({
  bankAccountName,
  bankAccountLastFourDigits,
}: {
  bankAccountName: string;
  bankAccountLastFourDigits: string;
}) => {
  return (
    <p className={boldStyle}>
      {bankAccountName} <span className={normalStyle}>(ending {bankAccountLastFourDigits})</span>
    </p>
  );
};

export const ReceivingCashAccountSummary = ({
  cashBalanceUsd,
  isIraCashAccount = false,
}: {
  cashBalanceUsd: string;
  isIraCashAccount: boolean;
}) => {
  const title = isIraCashAccount ? "IRA cash balance" : "Cash balance";
  return (
    <p className={boldStyle}>
      {title} <span className={normalStyle}>(${formatCurrency(cashBalanceUsd)})</span>
    </p>
  );
};
