import get from "lodash/get";

import { VaultAPI } from "Shared/api/vaultApi";

export const SET_VAULT_SHOW_VAULT_DATA = "SET_VAULT_SHOW_VAULT_DATA";
export const SET_VAULT_SHOW_STATUS = "SET_VAULT_SHOW_STATUS";
export const SET_VAULT_SHOW_ROLE = "SET_VAULT_SHOW_ROLE";
export const SET_VAULT_SHOW_ACCOUNT_KEYS = "SET_VAULT_SHOW_ACCOUNT_KEYS";
export const RESET_VAULT_SHOW_USER = "RESET_VAULT_SHOW_USER";
export const SET_VAULT_SHOW_NAME = "SET_VAULT_SHOW_NAME";
export const SET_VAULT_SHOW_THRESHOLD = "SET_VAULT_SHOW_THRESHOLD";
export const SET_VAULT_SHOW_CLOSE_TYPE = "SET_VAULT_SHOW_CLOSE_TYPE";
export const SET_VAULT_SHOW_NOTE = "SET_VAULT_SHOW_NOTE";

/**
 * Set Individual Vault action for the current user
 * @param userVault - retrieve user vault via an api call
 */

export const setVaultShowVaultDataAction = userVault => ({
  type: SET_VAULT_SHOW_VAULT_DATA,
  payload: userVault,
});

/**
 * Set Vault API request status action
 * @param status - set the api call status - action triggered based on current vault in store & new vault retrieved
 *
 */

export const setVaultShowStatusAction = status => ({
  type: SET_VAULT_SHOW_STATUS,
  payload: status,
});

/**
 * Set the view role for the show page as requested
 * @param role - view role as requested enum: [user, admin, arbiter, signer]
 *
 */

export const setVaultShowRoleAction = role => ({
  type: SET_VAULT_SHOW_ROLE,
  payload: role,
});

export const setVaultShowKeysAction = accountKeys => ({
  type: SET_VAULT_SHOW_ACCOUNT_KEYS,
  payload: accountKeys,
});

export const setVaultShowNameAction = name => ({
  type: SET_VAULT_SHOW_NAME,
  payload: name,
});

export const setVaultShowThresholdAction = threshold => ({
  type: SET_VAULT_SHOW_THRESHOLD,
  payload: threshold,
});

export const setVaultShowCloseTypeAction = type => ({
  type: SET_VAULT_SHOW_CLOSE_TYPE,
  payload: type,
});

export const resetVaultShowAction = () => ({
  type: RESET_VAULT_SHOW_USER,
  payload: null,
});

/**
 * Get Individual Vault action for the current user
 * @param uuid - specific uuid for vault being requested
 */

export const getVaultShowUserAction = uuid => {
  return async dispatch => {
    try {
      dispatch(setVaultShowRoleAction("user"));
      const vault = await VaultAPI.Get(uuid);
      dispatch(setVaultShowVaultDataAction(vault));
      dispatch(setVaultShowStatusAction("loaded"));
      dispatch(setVaultShowRoleAction("user"));
      return vault;
    } catch (e) {
      const status = get(e, "response.status", 0);
      switch (status) {
        case 403:
          dispatch(setVaultShowStatusAction("forbidden"));
          break;
        case 404:
          dispatch(setVaultShowStatusAction("not_found"));
          break;
        default:
          dispatch(setVaultShowStatusAction("error"));
          break;
      }
    }
  };
};

export const setVaultShowNoteAction = note => ({
  type: SET_VAULT_SHOW_NOTE,
  payload: note,
});
