import { useQuery } from "react-query";

import { InheritanceAPI } from "Shared/api";

const inheritancePurchaseKeys = {
  inheritancePriceInfo: (orgUUID: string) => [`inheritancePriceInfo`, orgUUID],
};

export const useInheritancePriceInfo = (orgUUID: string) => {
  const { data, isLoading, isError } = useQuery(
    inheritancePurchaseKeys.inheritancePriceInfo(orgUUID),
    () => InheritanceAPI.GetPriceInfo(orgUUID)
  );

  return { data, isLoading, isError };
};
