import React from "react";

import { MenuItem, TextField } from "@mui/material";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./AddressSelectField.module.scss";

const ProductMenuItem = ({ productType, name, uuid }) => {
  return productType === "vault" ? (
    <>
      <span className={styles.menuItemLabel}>Vault name:</span> {name}
    </>
  ) : (
    <>
      <span className={styles.menuItemLabel}>Loan ID:</span> {uuid}
    </>
  );
};

export const AddressSelectField = ({
  value,
  destination,
  returnAddress,
  products,
  testId,
  disabled,
  onChange,
  error,
  helperText,
}) => {
  return destination === "redemption" ? (
    <TextField
      fullWidth
      variant="outlined"
      value={returnAddress}
      disabled={true}
      InputProps={{
        classes: {
          root: styles.muiInput,
        },
        "data-testid": testId ? testId : null,
      }}
      inputProps={{
        className: styles.returnInput,
      }}
      error={error}
      helperText={helperText}
    />
  ) : destination === "transfer" ? (
    <TextField
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      disabled={disabled}
      select
      SelectProps={{ required: true, displayEmpty: true, value }}
      InputProps={{
        classes: {
          root: styles.muiInput,
        },
        "data-testid": testId ? testId : null,
      }}
      inputProps={{
        className: styles.input,
      }}
      onChange={onChange}
    >
      <MenuItem classes={{ root: styles.menuItem }} value="">
        - Select -
      </MenuItem>
      {products.map(({ product_type, name, uuid }) => (
        <MenuItem key={uuid} value={uuid} classes={{ root: styles.menuItem }}>
          <ProductMenuItem productType={product_type} name={name} uuid={uuid} />
        </MenuItem>
      ))}
    </TextField>
  ) : destination === "external" ? (
    <TextField
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      InputProps={{
        classes: {
          root: styles.muiInput,
        },
        "data-testid": testId ? testId : null,
      }}
      inputProps={{
        className: cn(styles.input, styles.returnInput),
      }}
      placeholder="Paste or enter address"
      value={value}
      onChange={onChange}
    />
  ) : (
    <TextField
      variant="outlined"
      disabled
      fullWidth
      InputProps={{
        classes: {
          root: styles.muiInput,
        },
        "data-testid": testId ? testId : null,
      }}
      inputProps={{
        className: styles.input,
      }}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
    />
  );
};

AddressSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  destination: PropTypes.oneOf(["redemption", "transfer", "external", ""]).isRequired,
  returnAddress: PropTypes.string,
  testId: PropTypes.string,
};
