import { useMemo } from "react";

import {
  deriveMultisigByPath,
  ExtendedPublicKey,
  generateBraid,
  getMaskedDerivation,
  Network,
  P2SH,
} from "@caravan/bitcoin";
import { ConfirmMultisigAddress, KEYSTORES } from "@caravan/wallets";
import type { MultisigWalletConfig, RootFingerprint } from "@caravan/wallets";
import { ValueOf } from "type-fest";

import { useGetWalletConfig } from "Shared/api";
import { WalletUuid } from "Shared/api/walletApi";

/**
 * In order to generate the TrezorConfirmAddress interaction, we need the "spend info"
 * for a given slice-key pair. The interaction for the API is pretty convoluted so we need
 * to do some work to get things in the right shape. In the @caravan/bitcoin library
 * the final spend info object is typically called `multisig`. Since that feels confusing and vague,
 * we're calling it something else here and encapsulating all the weird logic needed to get this info
 * to streamline the interaction instantiation a bit.
 */
export const useSpendInfoForKeyFromWallet = (walletUuid: WalletUuid, relativeBip32Path: string) => {
  // This is called in a lot of other places, but since it's just using React Query it should all be
  // cached and easy to retrieve. It felt better than passing the full config around. It also makes
  // it more reusable and composable.
  const walletConfigQuery = useGetWalletConfig(walletUuid);

  const spendInfo = useMemo(() => {
    if (!walletConfigQuery.data) return null;

    // To get the spend info we need a braid and the relative bip32 path (which we already have).
    // To get the braid, we need the network, the script type (always P2SH for now),
    // extended public keys serialized in a very specific way, and the braid index.

    const {
      // 1. network
      network,
      extendedPublicKeys,
      quorum: { requiredSigners },
    } = walletConfigQuery.data;

    // 2. script type
    // This should come from the slice/wallet, but does not... yet.
    const scriptType = P2SH;

    // 3. braid index
    // @caravan/bitcoin wants a relative path like "0/1"
    const cleanRelativePath = relativeBip32Path
      .split("/")
      .filter(index => Boolean(index))
      .join("/");

    const braidIndex = cleanRelativePath.split("/")[0];

    // 4. xpubs serialized in a specific way
    // (this could probably be changed to KeyOrigins eventually)
    const xpubs = extendedPublicKeys.map(key => {
      const xpub = ExtendedPublicKey.fromBase58(key.xpub);
      xpub.addBase58String();
      xpub.setRootFingerprint(key.xfp);
      xpub.setBip32Path(getMaskedDerivation(key));
      return xpub;
    });

    // 5. generate the braid given the above info
    const braid = generateBraid(network, scriptType, xpubs, requiredSigners, braidIndex);

    // 6. now we can generate the spend info
    const spendInfo = deriveMultisigByPath(braid, cleanRelativePath);
    // Yeah I know. We need to create the braid to create the multisig object but then we need to attach the
    // serialized braid details to the multisig object??
    // Seriously, I'm sorry about this API. Such a mess and needs to be consolidated.
    spendInfo.braidDetails = braid.toJSON();
    return spendInfo;
  }, [relativeBip32Path, walletConfigQuery.data]);

  return spendInfo;
};

export const useGetConfirmAddressInteraction = ({
  walletUuid,
  xfp,
  relativeBip32Path,
  keystore,
}: {
  walletUuid: WalletUuid;
  xfp: RootFingerprint;
  relativeBip32Path: string;
  keystore: ValueOf<typeof KEYSTORES>;
}) => {
  // These two objects should really be redundant but the ConfirmMultisigAddress interaction
  // needs to first be made to support just receiving a wallet config and key origin information
  // to reduce the complexity of the API.
  const spendInfoForKey = useSpendInfoForKeyFromWallet(walletUuid, relativeBip32Path);
  const walletConfigQuery = useGetWalletConfig(walletUuid);

  const interaction = useMemo(() => {
    if (!spendInfoForKey || !walletConfigQuery.data || walletConfigQuery.isLoading) {
      return null;
    }

    const { extendedPublicKeys, ledgerPolicyHmacs = [] } = walletConfigQuery.data;

    // parent path is dependent on the key we're verifying.
    const parentPath = extendedPublicKeys.find(key => key.xfp === xfp).bip32Path;
    // build the full bip32 path by combining derivation from the key plus the relative path
    // which is just the braid + address index and must be the same for all keys in the quorum.
    const bip32Path = [...parentPath.split("/"), ...relativeBip32Path.split("/")].join("/");

    const hmac = ledgerPolicyHmacs.find(hmac => hmac.xfp === xfp)?.policyHmac;

    // This API is really very messy and should be cleaned up in uc-wallets at some
    // point. There is a lot of redundant overlap between the wallet config information
    // and the multisig/spendInfo data. The former is used directly by the ledger interaction
    // and the latter by trezor's interaction. These should be consolidated.
    return ConfirmMultisigAddress({
      network: walletConfigQuery.data.network as Network,
      walletConfig: walletConfigQuery.data as MultisigWalletConfig,
      keystore,
      multisig: spendInfoForKey,
      policyHmac: hmac,
      bip32Path,
    });
  }, [
    keystore,
    relativeBip32Path,
    spendInfoForKey,
    walletConfigQuery.data,
    walletConfigQuery.isLoading,
    xfp,
  ]);

  return interaction;
};
