import React from "react";

import { BasicPage } from "Components/Pages";

class DocumentError extends React.Component {
  render = () => (
    <BasicPage narrow title="Document error">
      <h3 className="mb-4">We are unable to generate documents at this time.</h3>

      <div className="prose">
        <p>
          Our engineering staff actively monitors this application for errors and they will be
          investigating this one promptly.
        </p>

        <p>Please try your request again later.</p>
      </div>
    </BasicPage>
  );
}

export default DocumentError;
