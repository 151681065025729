import React from "react";

export const Keys = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M7 22H4.6c-.7 0-1.1 0-1.5-.2-.4-.2-.7-.5-.9-.9-.2-.4-.2-.8-.2-1.5v-1.7c0-.3 0-.5.1-.7 0-.2.1-.4.2-.6.1-.2.3-.3.5-.5l5.1-5.1.3-.3s0-.2-.1-.5C8 9.6 8 9.3 8 9c0-3.9 3.1-7 7-7s7 3.1 7 7-3.1 7-7 7c-.3 0-.6 0-.9-.1-.3 0-.4-.1-.6-.1 0 0-.1.1-.2.3l-1.6 1.6c-.2.2-.4.3-.7.3h-1v1c0 .6-.4 1-1 1H8v1c0 .6-.4 1-1 1zm8-18c-2.8 0-5 2.2-5 5v.7c.1.5.1.8.1 1.1 0 .3-.1.5-.2.7-.1.2-.3.4-.6.7l-5.1 5.1c-.1 0-.2.1-.2.1V20h2v-1c0-.6.4-1 1-1h1v-1c0-.6.4-1 1-1h1.6l1.3-1.3c.3-.3.5-.5.7-.6.2-.1.4-.2.7-.2.3 0 .5 0 1.1.1h.7c2.8 0 5-2.2 5-5S17.8 4 15 4zm2 6c-.6 0-1-.4-1-1 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3-.6 0-1-.4-1-1s.4-1 1-1c.8 0 1.6.3 2.1.9.6.5.9 1.3.9 2.1 0 .6-.4 1-1 1z"
    />
    <path fill="none" d="M0 0H24V24H0z" />
  </svg>
);
