import React from "react";

import { LoanIcon } from "Components/Shared/Icons/LoanIcon";

const ReturnAddressNotification = ({ uuid, name }: { uuid: string; name: string }) => {
  return {
    to: `/loans/${uuid}`,
    title: `Set redemption address for ${name}`,
    Icon: LoanIcon,
  };
};

export { ReturnAddressNotification };
