import React from "react";

import PropTypes from "prop-types";

const TrezorBrowserNotSupported = ({ browser }) => {
  return (
    <div>
      <p>
        Trezor does not officially support {browser} browser. You may experience difficulty
        connecting to Trezor Bridge. Since Trezor does not officially support {browser} browser, we
        recommend you proceed with caution. You can find more information{" "}
        <a href="https://wiki.trezor.io/Supported_browsers" target="_blank">
          here
        </a>
        .
      </p>
    </div>
  );
};

TrezorBrowserNotSupported.propTypes = {
  browser: PropTypes.string.isRequired,
};

export { TrezorBrowserNotSupported };
