import { useRef, useEffect } from "react";

/**
 * @description a custom hook to track if a component is mounted or not
 * this is useful if you want an effect to only run when
 * a component has been unmounted but it needs a reference
 * to updated props
 * @returns {object} isMounted.current as a boolean if the component is mounted
 */
export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};
