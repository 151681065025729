import { HelpCircle, Tooltip, TooltipProps } from "@unchained/component-library";

type AvailableCashTooltipProps = {
  placement?: TooltipProps["placement"];
};
export const AvailableCashTooltip = ({ placement = "top-start" }: AvailableCashTooltipProps) => {
  return (
    <Tooltip
      placement={placement}
      compact={true}
      content={
        <p className="m-0 p-0">
          <span className="font-semi text-gray-600">Available</span> refers to cash settled in your
          account that you may use immediately.
        </p>
      }
    >
      <div>
        <HelpCircle className="relative top-0.5 h-4 text-gray-400" />
      </div>
    </Tooltip>
  );
};
