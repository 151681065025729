/* eslint-disable no-console */

const PRINT_LOGS = process.env.NODE_ENV === "development";

// Intended to replace console logs in the applications
// Moving towards a holistic logging solution for production.
// Consider this a stub class for now.
class AppLogger {
  error = (...args) => {
    if (!PRINT_LOGS) return;
    console.error(...args);
  };

  warn = (...args) => {
    if (!PRINT_LOGS) return;
    console.warn(...args);
  };

  info = (...args) => {
    if (!PRINT_LOGS) return;
    console.info(...args);
  };

  log = (...args) => {
    if (!PRINT_LOGS) return;
    console.log(...args);
  };

  debug = (...args) => {
    if (!PRINT_LOGS) return;
    console.debug(...args);
  };
}

export const Logger = new AppLogger();
