import { useQuery } from "react-query";

import { GetSlice200, GetSliceAccountKeys200 } from "Specs/v1";

import { SliceAPI } from "../sliceApi";

export const sliceRequestKeys = {
  showSlice: address => ["slice", address],
  showSliceAccountKeys: address => [...sliceRequestKeys.showSlice(address), "account_keys"],
};

export const useGetSliceAccountKeys = address =>
  useQuery<GetSliceAccountKeys200, Error>(sliceRequestKeys.showSliceAccountKeys(address), () =>
    SliceAPI.GetAccountKeys(address)
  );

export const useGetSlice = address =>
  useQuery<GetSlice200, Error>(sliceRequestKeys.showSlice(address), () =>
    SliceAPI.GetSlice(address)
  );
