import React, { Component } from "react";

import { Slide } from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";

import { AppModal } from "Shared/components/Modals/AppModal";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { BrowserCompatibility } from "Utils/compatibility";

import {
  DEVICE_INTERACTION_TYPE_ENUM,
  DEVICE_ISSUE_DISPLAY_FORMAT_ENUM,
} from "../Support/SupportData";
import styles from "./DeviceInteractionStep.module.scss";
import { SlideDeviceIssue } from "./SlideDeviceIssue";

class DeviceInteractionStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSlideOverContent: false,
      showSlideOverContent: false,
      issue: {},
    };
  }

  componentDidMount() {
    this.checkForIssues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.walletType !== this.props.walletType) {
      this.checkForIssues();
    }
  }

  checkForIssues = () => {
    const { walletType, size } = this.props;
    const browserCompatibility = new BrowserCompatibility();
    const issues = browserCompatibility.getDeviceIssues(walletType);

    // find first issue where type is slide over
    if (issues.length > 0) {
      const slideIssue = _.find(
        issues,
        issue => issue.displayFormat === DEVICE_ISSUE_DISPLAY_FORMAT_ENUM.SLIDE
      );
      if (slideIssue) {
        if (size === "sm") {
          this.setState({ hasModalIssue: true, issue: slideIssue });
          setTimeout(this.showModalIssue, 500);
        } else {
          this.setState({ hasSlideOverContent: true, issue: slideIssue });
          setTimeout(this.toggleSlideContent, 1200);
        }
      }
    }
  };

  showModalIssue = () => {
    const { interactionType } = this.props;
    const { issue } = this.state;
    AppModalManager.open(() => (
      <AppModal disableClose>
        <SlideDeviceIssue {...issue} interactionType={interactionType} onClick={this.closeModal} />
      </AppModal>
    ));
  };

  closeModal = () => {
    AppModalManager.close();
  };

  handleIssueClick = () => {
    const { issue, hasSlideOverContent } = this.state;
    if (!issue) {
      return;
    }
    if (hasSlideOverContent) {
      this.toggleSlideContent();
    } else {
      this.showModalIssue();
    }
  };

  toggleSlideContent = () => {
    const { showSlideOverContent } = this.state;
    this.setState({ showSlideOverContent: !showSlideOverContent });
  };

  render() {
    const { interactionType, InteractionComponent, walletType, ...rest } = this.props;
    const { issue, showSlideOverContent } = this.state;
    return (
      <div className={styles.componentWrapper}>
        {issue.title && (
          <Slide
            mountOnEnter
            unmountOnExit
            direction="down"
            in={showSlideOverContent}
            timeout={1200}
          >
            <div className={styles.slideContainer}>
              <SlideDeviceIssue
                {...issue}
                interactionType={interactionType}
                onClick={this.toggleSlideContent}
              />
            </div>
          </Slide>
        )}

        <div className={styles.mainContent}>
          <InteractionComponent
            {...rest}
            walletType={walletType}
            handleIssueClick={this.handleIssueClick}
            issueText={issue.prompt}
          />
        </div>
      </div>
    );
  }
}

DeviceInteractionStep.propTypes = {
  interactionType: PropTypes.oneOf([
    DEVICE_INTERACTION_TYPE_ENUM.CHECKING,
    DEVICE_INTERACTION_TYPE_ENUM.SIGNING,
    DEVICE_INTERACTION_TYPE_ENUM.CONFIRMING,
  ]),
  InteractionComponent: PropTypes.func.isRequired,
  walletType: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default DeviceInteractionStep;
