import React from "react";

export const Notifications = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M12,23c-1.2,0-2.4-0.4-3.3-1.2c-0.4-0.4-0.5-1-0.1-1.4c0.4-0.4,1-0.5,1.4-0.1c1.1,1,2.9,1,4,0
	c0.4-0.4,1-0.3,1.4,0.1c0.4,0.4,0.3,1-0.1,1.4C14.4,22.6,13.2,23,12,23z M18.6,18H5.4c-1.7,0-2.1,0-2.5-0.3
	c-0.4-0.3-0.6-0.6-0.6-1.1c0-0.5,0.2-0.8,1.2-2.5C4.5,12.5,5,10.4,5,8c0-1.9,0.7-3.6,2.1-4.9C8.4,1.7,10.1,1,12,1s3.6,0.7,4.9,2.1
	C18.3,4.4,19,6.1,19,8c0,2.4,0.5,4.5,1.5,6.1c1.1,1.7,1.3,2.1,1.2,2.5c0,0.5-0.2,0.8-0.6,1.1C20.7,18,20.3,18,18.6,18z M4.2,16.8
	L4.2,16.8L4.2,16.8z M19.8,16.8L19.8,16.8L19.8,16.8z M4.7,16c0.2,0,0.5,0,0.7,0h13.2c0.3,0,0.5,0,0.7,0c-0.2-0.3-0.3-0.6-0.5-0.9
	C17.6,13.2,17,10.8,17,8c0-1.3-0.5-2.6-1.5-3.5C14.6,3.5,13.3,3,12,3c-1.3,0-2.6,0.5-3.5,1.5C7.5,5.4,7,6.7,7,8
	c0,2.8-0.6,5.2-1.8,7.1C5,15.4,4.8,15.7,4.7,16z M3.8,16L3.8,16L3.8,16z M20.2,16L20.2,16L20.2,16z"
    />
    <rect fill="none" width="24" height="24" />
  </svg>
);
