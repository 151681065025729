import React from "react";

import { useLocation, useParams } from "react-router-dom";

import { useNavigate } from "Components/Link";
import { queryParams } from "Utils/uris";

/**
 * This function injects routing props (location, navigate, and params)
 * into the wrapped component. For propTypes, see the connectWithRouter component.
 *
 * For use in class components that can't use React Router hooks.
 */
export const withRouter = Component => props => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  location.query = queryParams(location);
  location.fullPathname = [location.pathname, location.search].filter(s => s.length).join("");

  // Passed props should take precedence, unless they are undefined.
  const definedProps = Object.keys(props).reduce(
    (object, key) => (props[key] === undefined ? object : { ...object, [key]: props[key] }),
    {}
  );

  const finalProps = {
    navigate,
    location,
    params,
    ...definedProps,
  };

  return <Component {...finalProps} />;
};
