import { set } from "lodash";
import { AnyAction } from "redux";

import {
  SET_CRYPTO_PRICES,
  SET_HISTORICAL_CRYPTO_PRICES,
  SET_BTC_CURRENT_PRICE,
} from "Actions/cryptoActions/priceActions";

// TODO: Define types in API Specs
export type BtcPrice = {
  exchange?: string;
  asset?: string;
  unit?: string;
  value?: number;
  time?: string;
};

const initialState = {
  current: {
    BTC: {} as BtcPrice,
  },
  historical: {
    BTC: [],
  },
};

type CryptoPriceReducerState = typeof initialState;
export const priceReducer = (
  state: CryptoPriceReducerState = initialState,
  action: AnyAction
): CryptoPriceReducerState => {
  const { type, payload } = action;

  switch (type) {
    case SET_CRYPTO_PRICES: {
      return {
        ...state,
        ...{
          current: payload,
        },
      };
    }
    case SET_BTC_CURRENT_PRICE: {
      return set(state, "current.BTC.value", payload);
    }
    case SET_HISTORICAL_CRYPTO_PRICES: {
      return {
        ...state,
        ...{
          historical: payload,
        },
      };
    }
    default:
      return state;
  }
};
