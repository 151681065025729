import { Invoice, SKUS, SubscriptionAPI } from "Shared/api/v2";
import { CreateSubscriptionRequest, Subscription } from "Specs/v2/components";

/*
 * This function is used to get the redirect link for the completed payment
 * based on the presence of specific line items in the invoice. Primarily, this controls
 * the redirect link for the different org types. Personal accounts should go to /home,
 * others should go back to onboarding to complete org creation.
 */
export const getCompletedPaymentRedirectLink = (invoice: Invoice, from?: string) => {
  if (from && from.includes("/onboard")) {
    if (invoice.lineItems.some(item => item.sku === SKUS.INDIVIDUAL_ACCOUNT)) {
      return "/home";
    }

    if (
      invoice.lineItems.some(item =>
        (
          [
            SKUS.TRUST_ACCOUNT,
            SKUS.BUSINESS_ACCOUNT_BASIC_ONE,
            SKUS.BUSINESS_ACCOUNT_BASIC_TWO,
            SKUS.BUSINESS_ACCOUNT_PRO,
            SKUS.BUSINESS_ACCOUNT_ENTERPRISE,
            SKUS.IRA_ACCOUNT,
          ] as string[]
        ).includes(item.sku)
      )
    ) {
      return `/onboard/${invoice.orgId}`;
    }
  }

  return from || "/documents";
};

export const getItemsFromSubscription = (sub: Subscription) => {
  const items = sub.items;
  const addInvoiceItems = sub.invoices[0].lineItems.filter(
    item => !items.some(subscriptionItem => subscriptionItem.sku === item.sku)
  );

  return [items, addInvoiceItems];
};

/*
 * This function is used to apply a coupon code to a subscription. It creates a new subscription
 * with the coupon code applied and cancels the old subscription. It will throw an error if the coupon
 * code is invalid.
 */
export const applyCouponCodeToSubscription = async (
  couponCode: string,
  subscriptionId: string,
  orgId: string,
  shipping?: CreateSubscriptionRequest["shipping"]
) => {
  const subscription = await SubscriptionAPI.Get(subscriptionId);
  const [items, addInvoiceItems] = getItemsFromSubscription(subscription);

  const newSubWithCoupon = await SubscriptionAPI.Create({
    orgId,
    items,
    addInvoiceItems,
    couponCode,
    shipping,
  });

  await SubscriptionAPI.Cancel(subscription.id);

  return newSubWithCoupon;
};
