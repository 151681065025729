import { pick } from "lodash";

import { addressValidationSchema } from "Components/FormFields";
import { UploaderFile } from "Components/Shared/Elements/Fields/Deprecated/DeprecatedFormikUploadField";
import { DocLabel } from "Shared/api";
import { toISODatestring } from "Utils/dates";
import { date, object, string } from "Utils/yup";

import { OnboardingInfo } from "./context";

/** Name and DOB */

export const validateNameAndDob = object({
  first_name: string().label("First name").required(),
  last_name: string().label("Last name").required(),
  middle_name: string().label("Middle name"),
  dob: date().label("Birth date").required(),
});

export const initNameAndDob = ({ person }: OnboardingInfo) => {
  const { first_name, last_name, middle_name, dob } = person;
  return { first_name, last_name, middle_name, dob: toISODatestring(dob) };
};

/** Place of Residence */

export const initPlaceOfRes = ({ person }: OnboardingInfo) => pick(person, ["physical_address"]);

export const validatePlaceOfRes = object({
  physical_address: addressValidationSchema(),
});

/** Phone Number */

export const validatePhone = object({
  phone: string().phone().required(),
});

export const initPhone = ({ user }: OnboardingInfo) => pick(user, ["phone"]);

/** User ID */

export const validateUserId = object({
  file: object({
    title: string().label("File").required(),
  })
    .label("File")
    .required(),
  document_type: string().label("Document type").required(),
});

export const initUserId = ({ person }: OnboardingInfo) => {
  const docType = ["state_id", "passport"].find(dt => !!person[dt]) || undefined;

  return {
    file: person[docType] || {},
    document_type: docType,
  } as { document_type: DocLabel; file: UploaderFile };
};

/** Whole basic profile (if given account) */

export const basicProfileValid = (info: OnboardingInfo) =>
  validateNameAndDob.isValidSync(initNameAndDob(info)) &&
  validatePlaceOfRes.isValidSync(initPlaceOfRes(info)) &&
  validatePhone.isValidSync(initPhone(info)) &&
  validateUserId.isValidSync(initUserId(info));
