export const hasPermission = (allowedActions = [], types, every = true) => {
  if (typeof types === "string") {
    return allowedActions.includes(types);
  } else if (Array.isArray(types)) {
    let isAllowed = every ? false : true;
    for (let type of types) {
      const allowed = allowedActions.includes(type);
      // doesn't have one of the acls and must have every permission
      // return false
      if (!allowed && every) return false;
      // if user does have acl and only needs one, then can return true
      else if (allowed && !every) return true;
      // if doesn't have acl and don't need all to match then continue
      // if has acl and needs every to match, continue to confirm all match
      // in either case set isAllowed to the latest permission
      else isAllowed = allowed;
    }
    return isAllowed;
  } else {
    throw new Error(`Unrecognized type: ${typeof types}. Expected array or string`);
  }
};
