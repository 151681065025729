// Org menu & lens
export const UPDATE_ACCOUNT_ORGS = "/ACCOUNT/ORGS/UPDATE";
export const REPLACE_ACCOUNT_CURRENT_ORG = "/ACCOUNT/ORGS/CURRENT/REPLACE";
export const UPDATE_ACCOUNT_CURRENT_ORG_STATUS = "/ACCOUNT/ORGS/CURRENT/STATUS/UPDATE";

// Manging memberships of the orgs an account belongs to.
export const REMOVE_ACCOUNT_ORG_MEMBERSHIP = "/ACCOUNTS/ORGS/MEMBERSHIPS/REMOVE";

// Managing current org's members
export const ADD_PENDING_MEMBERS_TO_ACCOUNT_CURRENT_ORG =
  "/ACCOUNT/ORGS/CURRENT/PENDING_MEMBERS/ADD";
export const REMOVE_PENDING_MEMBER_FROM_ACCOUNT_CURRENT_ORG =
  "/ACCOUNT/ORGS/CURRENT/PENDING_MEMBERS/REMOVE";
export const REMOVE_ACCEPTED_MEMBER_FROM_ACCOUNT_CURRENT_ORG =
  "/ACCOUNT/ORGS/CURRENT/ACCEPTED_MEMBERS/REMOVE";

// Managing current org's users' roles
export const REMOVE_USER_FROM_ACCOUNT_CURRENT_ORG_ROLE = "/ACCOUNT/ORGS/CURRENT/ROLES/USERS/REMOVE";
export const ADD_USER_TO_ACCOUNT_CURRENT_ORG_ROLE = "/ACCOUNT/ORGS/CURRENT/ROLES/USERS/ADD";

//
// Org menu & lens
//

export const updateAccountOrgsAction = (personal, memberships) => ({
  type: UPDATE_ACCOUNT_ORGS,
  personal,
  memberships,
});

export const replaceAccountCurrentOrgAction = org => ({
  type: REPLACE_ACCOUNT_CURRENT_ORG,
  payload: org,
});

export const updateAccountCurrentOrgStatusAction = status => ({
  type: UPDATE_ACCOUNT_CURRENT_ORG_STATUS,
  payload: status,
});

//
// Managing org's members
//

export const addPendingMembersToCurrentOrgAction = emails => ({
  type: ADD_PENDING_MEMBERS_TO_ACCOUNT_CURRENT_ORG,
  payload: emails,
});

export const removePendingMemberFromCurrentOrgAction = email => ({
  type: REMOVE_PENDING_MEMBER_FROM_ACCOUNT_CURRENT_ORG,
  payload: email,
});

export const removeAcceptedMemberFromCurrentOrgAction = user => ({
  type: REMOVE_ACCEPTED_MEMBER_FROM_ACCOUNT_CURRENT_ORG,
  payload: user,
});

//
// Managing org's user roles.
//

export const addUserToCurrentOrgRoleAction = (user, role) => ({
  type: ADD_USER_TO_ACCOUNT_CURRENT_ORG_ROLE,
  user,
  role,
});

export const removeUserFromCurrentOrgRoleAction = (user, role) => ({
  type: REMOVE_USER_FROM_ACCOUNT_CURRENT_ORG_ROLE,
  user,
  role,
});
