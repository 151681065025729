import { REQUEST_STATUS } from "Shared/api/api";
import { KeyAPI } from "Shared/api/keyApi";

export const SET_KEY_INDEX_USER = "SET_KEY_INDEX_USER";
export const SET_KEY_INDEX_STATUS = "SET_KEY_INDEX_STATUS";

export const setKeyIndexAction = userKeys => ({
  type: SET_KEY_INDEX_USER,
  payload: userKeys,
});

export const setKeyIndexStatusAction = userKeys => ({
  type: SET_KEY_INDEX_STATUS,
  payload: userKeys,
});

/**
 * Set List of Keys action for the current org.
 */
export const getKeyIndexAction = orgUUID => {
  return async (dispatch, getState) => {
    if (!orgUUID) {
      const state = getState();
      orgUUID = state.account.orgs.current.uuid;
    }
    try {
      const userKeys = await KeyAPI.ListForOrg(orgUUID);
      dispatch(setKeyIndexAction(userKeys));
      dispatch(setKeyIndexStatusAction(REQUEST_STATUS.SUCCESS));
      return userKeys;
    } catch (e) {
      dispatch(setKeyIndexStatusAction(REQUEST_STATUS.ERROR));
      throw e;
    }
  };
};
