import { createSelector } from "reselect";

import { cryptoToFiatPartial, fiatToCryptoPartial } from "Utils/currency";

import { currentPriceSelector } from "./priceSelectors";

// get the price from the store
export const loanUnitSelector = state => state.loans.loanShow.loan.unit;
export const loanWalletUtxosCountSelector = state =>
  state.loans.loanShow.loan.wallet.deposited_utxo_count;

export const cryptoToFiatLoanSelector = createSelector(
  loanUnitSelector,
  currentPriceSelector,
  cryptoToFiatPartial
);

export const fiatToCryptoLoanSelector = createSelector(
  loanUnitSelector,
  currentPriceSelector,
  fiatToCryptoPartial
);
