import {
  RESET_OUTFLOW_FLOW,
  SET_OUTFLOW_ADDRESS,
  SET_OUTFLOW_CRYPTO_AMOUNT,
  SET_OUTFLOW_DESTINATION_ACCOUNT,
  SET_OUTFLOW_FEE_RATE,
  SET_OUTFLOW_FIAT_AMOUNT,
  SET_OUTFLOW_KEY_1,
  SET_OUTFLOW_KEY_2,
  SET_OUTFLOW_KEY_3,
  SET_OUTFLOW_PARTIAL_TRANSACTION,
  SET_OUTFLOW_MAX_PARTIAL_TRANSACTION,
  SET_OUTFLOW_TRANSACTION_TYPE,
  TOGGLE_OUTFLOW_CHOOSING_KEYS,
} from "Actions/transactionActions/outflowActions";

const initialState = {
  address: "",
  destination: {
    uuid: "",
  },

  cryptoAmount: "",
  fiatAmount: "",

  choosingKeys: false,
  key1: {
    uuid: "",
    active: false,
  },
  key2: {
    uuid: "",
    active: false,
  },
  key3: {
    uuid: "",
    active: false,
  },
  feeRate: 0,
  partial: true,
  max: false,
  type: "",
};

export const outflowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_OUTFLOW_PARTIAL_TRANSACTION: {
      return {
        ...state,
        partial: payload,
      };
    }

    case SET_OUTFLOW_MAX_PARTIAL_TRANSACTION: {
      return {
        ...state,
        max: payload,
      };
    }

    case SET_OUTFLOW_TRANSACTION_TYPE: {
      return {
        ...state,
        type: payload,
      };
    }

    case SET_OUTFLOW_DESTINATION_ACCOUNT: {
      return {
        ...state,
        destination: payload,
      };
    }

    case SET_OUTFLOW_ADDRESS: {
      return {
        ...state,
        address: payload,
      };
    }

    case SET_OUTFLOW_CRYPTO_AMOUNT: {
      return {
        ...state,
        cryptoAmount: payload,
      };
    }

    case SET_OUTFLOW_FIAT_AMOUNT: {
      return {
        ...state,
        fiatAmount: payload,
      };
    }

    case TOGGLE_OUTFLOW_CHOOSING_KEYS: {
      return {
        ...state,
        choosingKeys: payload,
      };
    }

    case SET_OUTFLOW_KEY_1: {
      return {
        ...state,
        key1: {
          ...state.key1,
          ...payload,
        },
      };
    }

    case SET_OUTFLOW_KEY_2: {
      return {
        ...state,
        key2: {
          ...state.key2,
          ...payload,
        },
      };
    }

    case SET_OUTFLOW_KEY_3: {
      return {
        ...state,
        key3: {
          ...state.key3,
          ...payload,
        },
      };
    }

    case SET_OUTFLOW_FEE_RATE: {
      return {
        ...state,
        feeRate: payload,
      };
    }

    case RESET_OUTFLOW_FLOW: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
