import React from "react";

import { kebabCase, startCase } from "lodash";

/**
 * @description Converts string s to sentence case.
 * e.g: "hello_world" => "Hello world"
 * @param {string} s a string containing an underscore.
 * @returns {string} the provided string in sentence case .
 */
export function detableize(s) {
  if (!s || s.length === 0) {
    return "";
  }
  s = s.toString();
  s = s.toLowerCase();
  s = s.replace(/_/g, " ");
  s = s.charAt(0).toUpperCase() + s.slice(1);
  return s;
}

/**
 * @description provides a plural version of the string if asked for.
 * If a count is indicated that will take priority, if no count is provided
 * the function assumes you want it to be pluralized (if it's not already)
 * @param {string} s string to be pluralized
 * @param {number} [count] can indicate if you want this to be pluralized if there's
 * a count greater than 1
 * @returns {string} pluralized version of the string if necessary
 */
export const pluralize = (s = "", count?: number) => {
  if ((!count || count > 1) && s.slice(-2) === "ss") {
    return s + "es";
  } else if (count === 1 || s[s.length - 1] === "s") {
    return s;
  } else {
    return s + "s";
  }
};

export function wordWrap(s: string, lineLength = 80) {
  var lines = [];
  var index = 0;
  var element = 0;
  while (index <= s.length) {
    lines.push(<span key={element}>{s.slice(index, (index += lineLength))}</span>);
    lines.push(<br key={element + 1} />);
    element += 2;
  }
  return lines;
}

/**
 * Takes a string `s` and displays the first `frontLength` letters, and the last
 * `backlength` letters, with a "..." between them.
 * @param {string} s
 * @param {number} frontLength
 * @param {number} backLength
 * @returns {string}
 */
export function condense(s: string, frontLength?: number, backLength?: number) {
  var front = s.substring(0, frontLength || 5);
  var back = s.slice(0 - (backLength || 3));
  return `${front}...${back}`;
}

// See http://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function humanSize(bytes: number, decimals?: number) {
  if (bytes === 0) return "0 B";
  var k = 1000,
    dm = decimals + 1 || 3,
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function formatNumber(n, places?: number, noCommas?: boolean, rounding = true) {
  places = typeof places == "undefined" ? 2 : places; // places can be 0
  var rounded = parseFloat(n).toFixed(places);
  if (places > 2 && rounding) {
    if (rounded.endsWith("0")) {
      rounded = rounded.replace(/00+$/, "0");
    }
  }

  var finalFormat = parseFloat(rounded).toLocaleString("en", {
    maximumFractionDigits: places,
    minimumFractionDigits: rounding ? Math.min(2, places) : places,
  });

  if (noCommas) {
    return finalFormat.replace(/,/g, "");
  } else {
    return finalFormat;
  }
}

// This function is used to format numbers that require a high level of
// decimal place precision, but would like to be formated as a currency.
// The formatNumber method uses parseFloat which can not handle
// numbers with excessive precision, like 16 decimal places.
export const formatPreciseNumberToCurrency = (preciseNumber: string): string => {
  const [wholeNumber, decimalPlaceValue] = preciseNumber.split(".");
  const formattedWholeNumber = formatNumber(wholeNumber, 0, false);
  return `${formattedWholeNumber}.${decimalPlaceValue}`;
};

export var formatCurrency = formatNumber;

export function formatPercentage(n, places?: number, noCommas?: boolean) {
  if (n || n === 0 || n === "0") {
    return `${formatNumber(n * 100, places, noCommas)}%`;
  }
  return null;
}

// https://stackoverflow.com/questions/1418050/string-strip-for-javascript
export function trim(s) {
  return (s || "").replace(/^\s+|\s+$/g, "");
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  if (s && s.length > 0) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  } else {
    return "";
  }
}

export function maskDisplay(value, shownPlaces = 4) {
  if (!value) {
    return "";
  }
  if (value.length < shownPlaces) {
    return "*".repeat(value.length);
  }
  return (
    "*".repeat(value.length - shownPlaces) +
    value.substring(value.length - shownPlaces, value.length)
  );
}

export function formatSSN(ssn) {
  if (!Boolean(ssn)) {
    return null;
  }
  return [ssn.slice(0, 3), ssn.slice(3, 5), ssn.slice(5)].join("-");
}

export function formatEIN(ein) {
  if (!Boolean(ein)) {
    return null;
  }
  return [ein.slice(0, 2), ein.slice(2)].join("-");
}

export function maskSSN(ssn) {
  if (!Boolean(ssn)) {
    return null;
  }
  return formatSSN(`*****${ssn.slice(5)}`);
}

/**
 * A function which will take in a fullText string and replace parts of it with a react component.
 * It will return an array that maintains the order of the fullText but is element separated by the Component which have replaced some textToBeReplaced.
 *
 * @param {React.Component} Component - Component to replace text.
 * @param {String} fullText - Entire text which contains textToBeReplaced.
 * @param {String} textToBeReplaced - Text to be replaced.
 * @returns {[String, React.Component]} - Array containing Strings and React.Components which have replaced specific strings.
 */
export function findAndReplaceTextWithComponent(Component, fullText, textToBeReplaced) {
  const textToBeReplacedAsRegexCapture = new RegExp(`(${textToBeReplaced})`);
  const fullTextArraySplit = fullText.split(textToBeReplacedAsRegexCapture);
  const fullTextArrayWithTextReplacedByComponent = fullTextArraySplit.map(subText => {
    if (subText === textToBeReplaced) {
      return Component;
    } else {
      return subText;
    }
  });

  return fullTextArrayWithTextReplacedByComponent;
}

/**
 * Given a url path (eg /hello/there) and a positive or negative index,
 * returns the segment of the url corresponding to the index.
 *
 * Eg,
 * - getPathSegment('/this/is/a/path', -1) => 'path'
 * - getPathSegment('/this/is/a/path', -2) => 'a'
 * - getPathSegment('/this/is/a/path', 1) => 'is'
 *
 * @param {string} path
 * @param {number} index
 *
 * @returns string
 */
export const getPathSegment = (path, index) => {
  const splitPath = path.split("/");
  return index > 0 ? splitPath[index] : splitPath[splitPath.length + index];
};

/**
 * Converts a value from string to bool or number if it matches format:
 * - "true" => true
 * - "false" => false
 * - "1" => 1
 * - "1.0" => 1.0
 * - '{ "hello": "there" }' => { hello: "there" }
 * - "{ hello: 'there' }" => "{ hello: 'there' }" // Still a string. Not proper JSON
 * - "Nothing special" => "Nothing special"
 *
 * @param {string} stringifiedVal The value string
 * */
export const unstringify = stringifiedVal => {
  if (typeof stringifiedVal !== "string") return stringifiedVal;

  if (["true", "false"].includes(stringifiedVal)) {
    return stringifiedVal === "true" ? true : false;
  } else if (stringifiedVal.match(/^\d+$/g)) {
    return parseInt(stringifiedVal);
  } else if (stringifiedVal.match(/^\d+\.\d+$/g)) {
    return parseFloat(stringifiedVal);
  }

  try {
    const json = JSON.parse(stringifiedVal);
    return json;
  } catch (err) {
    return stringifiedVal;
  }
};

/** Turns "This Is a Title" or "this-is-a-title" (etc) into "This is a title" */
export const sentenceCase = (string: string) =>
  startCase(string)
    .split(" ")
    .map((word, index) => (index === 0 ? word : word.toLowerCase()))
    .join(" ");

const lowCaseWords = [
  "a",
  "an",
  "the",
  "and",
  "but",
  "or",
  "for",
  "nor",
  "on",
  "at",
  "to",
  "from",
  "by",
  "of",
  "in",
];

/** Turns this-is-a-string (etc) into This is a String */
export const titleCase = (string: string) => {
  const split = kebabCase(string).split("-");
  return split
    .map((word, index) =>
      index === 0 || index === split.length - 1
        ? capitalize(word)
        : lowCaseWords.includes(word)
        ? word
        : capitalize(word)
    )
    .join(" ");
};

/** Turns a variety of string formats into PascalCase */
export const pascalCase = (string: string) => kebabCase(string).split("-").map(capitalize).join("");

export const percentageRegex = /^100$|^\d{1,2}$/;

export const indefiniteArticle = (word: string) => (/^[aeiou]/i.test(word) ? "an" : "a");

/** Adds a hyphen after every three digits, unless it's at the end */
export const hyphenateNumber = (num, digits = 3) => {
  const regex = new RegExp(`\\B(?=(\\d{${digits}})+(?!\\d))`, "g");
  return num.replace(regex, "-");
};

export const ordinals = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
];
