import $ from "jquery";

var initialState = {};

function scrollToTop() {
  scrollTo("html");
}

function scrollTo(selector) {
  $("body,html").animate(
    {
      scrollTop: $(selector).offset().top,
    },
    800
  );
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SCROLL/TOP":
      scrollToTop();
      break;
    default:
      break;
  }
  return state;
};
