import { AdminShowReset200, CompleteOtpReset200, DeleteOtpReset200 } from "Specs/v1";

import { API, resolveData } from "./api";

class OTPResetsApi {
  static Get2FAResets = () => resolveData(API.Get(`/2fa/resets`));
  static Get2FAReset = token => resolveData(API.Get(`/2fa/resets/${token}`));
  static Get2FAResetAdmin = async token =>
    (await API.Get<AdminShowReset200>(`/2fa/resets/${token}/admin`)).data;
  static CompleteOtpReset = async (uuid: string) =>
    (await API.Post<CompleteOtpReset200>(`/2fa/resets/${uuid}/completed`)).data;
  static DeleteOtpReset = async (uuid: string) =>
    (await API.Delete<DeleteOtpReset200>(`/2fa/resets/${uuid}`)).data;
  static Submit = async (uuid: string) => (await API.Post(`/users/${uuid}/2fa/reset`)).data;
}

export { OTPResetsApi };
