import { REQUEST_STATUS } from "Shared/api/api";
import { LoanAPI } from "Shared/api/loanApi";

export const SET_LOAN_INDEX_USER = "SET_LOAN_INDEX_USER";
export const RESET_ALL_LOAN_INDEXES = "RESET_ALL_LOAN_INDEXES";

/**
 * Set List of Loans action for the current user
 * @param userLoans - retrieve via an api call
 */

export const setLoansIndexUserAction = userLoans => ({
  type: SET_LOAN_INDEX_USER,
  payload: userLoans,
});

export const getLoansIndexUserAction = orgUUID => {
  return async dispatch => {
    const loans = await LoanAPI.ListForOrg(orgUUID);
    const userLoans = {
      loans,
      status: REQUEST_STATUS.SUCCESS,
    };
    dispatch(setLoansIndexUserAction(userLoans));
  };
};

export const resetAllLoansAction = () => ({
  type: RESET_ALL_LOAN_INDEXES,
});
