import moment from "moment";

import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  due_on: moment.utc().startOf("day"),
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "PREPAYMENT/CREATE/SET/DUE_ON":
      return updatedField(state, action, "due_on");

    case "PREPAYMENT/CREATE/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "PREPAYMENT/CREATE/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "PREPAYMENT/CREATE/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    case "PREPAYMENT/CREATE/REQUEST/RESET":
      return { ...state, status: "new", errors: {} };
    default:
      return state;
  }
};
