import React, { Component } from "react";

import PropTypes from "prop-types";

import { exploreTransactionURL } from "Utils/wallet";

import { ExploreBlockChain } from "./ExploreBlockChain";

class ExploreTransactionBase extends Component {
  static propTypes = {
    txid: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    showTxid: PropTypes.bool,
  };

  static defaultProps = {
    showTxid: true,
  };

  exploreUrl = () => {
    const { txid, unit } = this.props;
    return exploreTransactionURL(window.TREFOIL_CONFIG.wallet.testnet, unit, txid);
  };

  render() {
    const { txid, showTxid } = this.props;

    return <ExploreBlockChain url={this.exploreUrl()} item={txid} showItem={showTxid} />;
  }
}

export const ExploreTransaction = ExploreTransactionBase;
