import { Banner } from "@unchained/component-library";

import { Link } from "Components/Link";
import { useFeeRates } from "Shared/api/hooks/fees";

interface FeeInfoBannerProps {
  feeRate: string; // in sats/vB
}

export const FeeInfoBanner = ({ feeRate }: FeeInfoBannerProps) => {
  const { fast: highPriority, slow: lowPriority } = useFeeRates();

  let type;
  let title;
  let subtitle;

  if (Number(feeRate) < lowPriority) {
    title = "Probable confirmation delay";
    type = "warning";
    subtitle =
      "Your transaction is at risk of not being confirmed. You should consider canceling and restarting the transaction with a higher fee rate.";
  } else {
    title = "Fee information";
    type = "pending";
  }

  return (
    <Banner title={title} type={type} className="mb-5">
      <div className="max-w-1xl grid gap-2">
        {subtitle && (
          <div>
            <p className="my-0">{subtitle}</p>
          </div>
        )}
        <div style={{ maxWidth: "30rem" }} className="grid gap-2">
          <div className="grid h-4 grid-cols-11 items-center">
            <div className="col-span-3"></div>
            <div className="col-span-8 flex justify-center">
              <span className="font-semibold text-gray-300">┌─</span>
              <span
                style={{ marginTop: "0.08rem" }}
                className="font-semibold px-1 text-xs tracking-widest text-primary-600"
              >
                CURRENT RATES
              </span>
              <span className="font-semibold text-gray-300">─┐</span>
            </div>
          </div>
          <div className="grid grid-cols-11">
            <div className="items-left col-span-3 flex flex-col border-r border-gray-400">
              <div className="inline-flex w-[fit-content] flex-col items-center">
                <span className="font-bold">Your fee rate</span>
                <span>{feeRate} sats/vB</span>
              </div>
            </div>
            <div className="col-span-4 flex flex-col items-center">
              <span className="font-bold">Low priority</span>
              <span>{lowPriority} sats/vB</span>
            </div>
            <div className="col-span-4 flex flex-col items-center">
              <span className="font-bold">High priority</span>
              <span>{highPriority} sats/vB</span>
            </div>
          </div>
        </div>

        <Link
          target="_blank"
          to="https://help.unchained.com/why-does-unchained-capital-make-me-pay-such-high-bitcoin-transaction-fees"
          className="mt-4 text-primary-600"
        >
          Learn more about fees
        </Link>
      </div>
    </Banner>
  );
};
