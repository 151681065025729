import { useContext } from "react";

import { Banner } from "@unchained/component-library";
import Big from "big.js";

import { FeeRateValidationCategory, useFeeRates, useValidateFeeRate } from "Shared/api/hooks/fees";

import { WithdrawModalContext } from "../context";

const KNOWLEDGEBASE_URL =
  "https://help.unchained.com/why-does-unchained-capital-make-me-pay-such-high-bitcoin-transaction-fees";

/**
 * Finds the whole integer percentage above max.
 */
const getExcessFeeRatePercent = (feeRate: string, max: number) => {
  const feeRateNumber = parseInt(feeRate);
  if (isNaN(feeRateNumber) || !max) {
    return 0;
  }
  return Big(feeRateNumber).div(max).minus(1).mul(100).round(0).toNumber();
};

export const FeeRateInfoBanner = () => {
  const { feeRate } = useContext(WithdrawModalContext);
  const feeRateValidation = useValidateFeeRate(parseInt(feeRate));
  const { purge_rate, slow, max } = useFeeRates();

  let props: {
    type: "info" | "error" | "warning";
    title: string;
    text: string;
    linkClassName: "font-semi text-primary-600" | "font-semi";
  } = {
    type: "info",
    title: "Recommended fee rate",
    text: `We suggest a fee rate of at least ${slow} sats/vB.`,
    linkClassName: "font-semi text-primary-600",
  };

  if (feeRateValidation === FeeRateValidationCategory.BELOW_PURGE) {
    props.type = "error";
    props.title = "Increase fee rate";
    props.text = `This fee rate is below our current minimum fee (${purge_rate} sats/vB). Please increase to proceed.`;
    props.linkClassName = "font-semi";
  } else if (feeRateValidation === FeeRateValidationCategory.PURGE) {
    props.type = "warning";
    props.title = "Low fee rate";
    props.text =
      "This fee rate is below the estimated value needed for confirmation. Proceed with caution.";
  } else if (feeRateValidation === FeeRateValidationCategory.GREATER_THAN_MAX) {
    const excessFeeRatePercent = getExcessFeeRatePercent(feeRate, max);
    if (excessFeeRatePercent > 0) {
      props.type = "warning";
      props.title = "High fee warning";
      props.text = `Your fee rate is ${excessFeeRatePercent}% over our recommended amount. You may be overpaying; update amount or proceed.`;
    }
  }

  return (
    <Banner type={props.type} title={props.title}>
      {props.text}
      <br />
      <br />
      <a className={props.linkClassName} href={KNOWLEDGEBASE_URL} target="_blank">
        Learn more about fees
      </a>
    </Banner>
  );
};
