import { combineReducers } from "redux";

import { outflowReducer as outflow } from "./outflowReducer";
import { spendingReducer as spending } from "./spendingReducer";

const transactionReducers = combineReducers({
  outflow,
  spending,
});

export { transactionReducers };
