import { ModalFooter } from "@unchained/component-library";
import Big from "big.js";
import { Formik, useFormikContext } from "formik";
import { SchemaOf } from "yup";

import {
  setStreamingQuoteStatus,
  updateVault,
  USD,
  useBuyBitcoinDispatch,
  useBuyBitcoinStore,
} from "Contexts/BuyBitcoin";
import { AWAITING_NEW_STREAMING_QUOTE } from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { BuyDestination, DestinationVault } from "Contexts/BuyBitcoin/types";
import { fiatWithUnit } from "Utils/currency";
import { yup } from "Utils/yup";

import { VaultSelectDropdown } from "./BuyBitcoinModal/VaultSelectDropdown";
import { TradeModalContainer } from "./TradeModalContainer";

export interface SourceAndDestinationValues {
  destination: string;
}

type SourceAndDestinationFormProps = {
  back: () => void;
};
const SourceAndDestinationForm = ({ back }: SourceAndDestinationFormProps) => {
  const {
    amountCurrency,
    amount,
    bitcoinPrice,
    destinations: { vaults, loans },
  } = useBuyBitcoinStore();

  const getInverseCurrencyAmount = () => {
    if (amountCurrency === USD) {
      return Big(amount).div(bitcoinPrice).toFixed(8);
    } else {
      return Big(amount).times(bitcoinPrice).toFixed(2);
    }
  };

  const btcAmount = amountCurrency === USD ? getInverseCurrencyAmount() : amount;
  const usdAmount = amountCurrency === USD ? amount : getInverseCurrencyAmount();
  const isMoreThanOneDestination = vaults.length + loans.length > 1;
  const dropdownLabel = isMoreThanOneDestination ? "Choose destination" : "Destination";

  const { errors, isValid, handleSubmit } = useFormikContext<SourceAndDestinationValues>();
  return (
    <TradeModalContainer title="Buy bitcoin" showBackArrow={true} onBack={back}>
      <div className="row mt-1 flex items-center px-4">
        <p className="text-xl font-semi text-gray-500">{btcAmount} BTC</p>
        <div className="ml-2 mr-2 h-6 border-2 border-gray-300" />
        <p className="text-xl font-semi text-gray-500">{fiatWithUnit(parseFloat(usdAmount))}</p>
      </div>
      <VaultSelectDropdown
        label={dropdownLabel}
        isSelectable={isMoreThanOneDestination}
        vaults={vaults}
        loans={loans}
      />

      <p className={"mt-9 text-xs text-gray-600"}>
        Exact trading values will be shown on the preview buy screen.
      </p>
      <ModalFooter
        className="!mt-2"
        actions={[
          {
            disabled: !isValid || Object.keys(errors).length !== 0,
            type: "primary",
            children: "Preview buy",
            onClick: handleSubmit,
            className: "!mt-0",
          },
        ]}
      />
    </TradeModalContainer>
  );
};

type SourceAndDestinationModalProps = {
  next: () => void;
  back: () => void;
  defaultVault: DestinationVault;
};
export const SourceAndDestinationModal = ({
  next,
  back,
  defaultVault,
}: SourceAndDestinationModalProps) => {
  const {
    amount,
    destinations: { vaults, loans },
    destination,
  } = useBuyBitcoinStore();
  const dispatch = useBuyBitcoinDispatch();

  const handleSubmit = ({ destination: destinationId }: { destination: string }) => {
    const newDestination: BuyDestination = { id: "", type: "vault" };
    const vault = vaults.find(v => v.id === destinationId);
    if (vault) {
      newDestination.id = vault.id;
    } else {
      const loan = loans.find(l => l.id === destinationId);
      newDestination.id = loan.id;
      newDestination.type = "loan";
    }

    dispatch(updateVault(newDestination));
    dispatch(setStreamingQuoteStatus(AWAITING_NEW_STREAMING_QUOTE));
    next();
  };

  const validationSchema: SchemaOf<SourceAndDestinationValues> = yup.object({
    destination: yup.string().defined(),
  });

  const initialValues = {
    destination: destination.id || defaultVault?.id,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      initialErrors={amount === "" ? { placeholder: "error" } : {}}
    >
      <SourceAndDestinationForm back={back} />
    </Formik>
  );
};
