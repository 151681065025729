import { AttachMoney } from "@mui/icons-material";

import { parseTimestamp, readableDate } from "Utils/time";

const UpcomingPaymentNotification = ({ uuid, due_at }: { uuid: string; due_at: string }) => {
  return {
    to: `/loans/${uuid}`,
    title: `Payment due on ${readableDate(parseTimestamp(due_at))} for Loan ${uuid}`,
    Icon: AttachMoney,
  };
};

const PastDuePaymentNotification = ({ uuid, due_at }: { uuid: string; due_at: string }) => {
  return {
    to: `/loans/${uuid}`,
    title: `Payment past due for loan ${uuid}`,
    subtitle: readableDate(parseTimestamp(due_at)),
    Icon: AttachMoney,
    urgent: true,
  };
};
export { UpcomingPaymentNotification, PastDuePaymentNotification };
