import { defaultBIP32Path } from "Utils/bip32";

export const SET_NEW_WALLET_NAME = "SET_NEW_WALLET_NAME";
export const SET_NEW_WALLET_CURRENCY = "SET_NEW_WALLET_CURRENCY";
export const SET_NEW_WALLET_TYPE = "SET_NEW_WALLET_TYPE";
export const WALLET_NEW_SET_XPUB = "WALLET/NEW/SET/XPUB";
export const WALLET_NEW_SET_XFP = "WALLET/NEW/SET/XFP";
export const SET_NEW_WALLET_BIP32_PATH = "WALLET/NEW/SET/BIP32_PATH";
export const SET_NEW_WALLET_SHOW_ADVANCED = "SET_NEW_WALLET_SHOW_ADVANCED";
export const RESET_NEW_KEY_FLOW = "RESET_NEW_KEY_FLOW";
export const SET_NEW_WALLET_ACTIVE = "SET_NEW_WALLET_ACTIVE";
export const SET_NEW_WALLET_SUCCESS_MESSAGE = "SET_NEW_WALLET_SUCCESS_MESSAGE";
export const SET_NEW_WALLET_ORG = "SET_NEW_WALLET_ORG";

/**
 * Set Key Currency action in New Key Flow
 * @param walletCurrency - User selection of currency, enum: ['BTC', 'ETH'], will be predetermined if created in context
 */
export const setWalletCurrencyAction = walletCurrency => ({
  type: SET_NEW_WALLET_CURRENCY,
  payload: walletCurrency,
});

/**
 * Set Key Name action in New Key Flow
 * @param walletName - User input wallet name, or generated w/ the suggestion generator
 */
export const setNewKeyNameAction = walletName => ({
  type: SET_NEW_WALLET_NAME,
  payload: walletName,
});

/**
 * Set Hardware Wallet type action in New Key Flow
 * @param walletType - User selection of keyType, enum: ['Trezor', 'Ledger']
 */
export const setNewWalletTypeAction = walletType => ({
  type: SET_NEW_WALLET_TYPE,
  payload: walletType,
});

/**
 * Set Bip32 path used be new key in New Key Flow
 * This is either user entered or
 * @param BIP32Path - Default initially populated dynamically based on Hardware Wallet & currency
 * Can be overridden by the user in the advanced drop down
 */
export const setNewWalletBIP32PathAction = BIP32Path => ({
  type: SET_NEW_WALLET_BIP32_PATH,
  payload: BIP32Path,
});

export const setNewWalletXpubAction = xpub => ({
  type: WALLET_NEW_SET_XPUB,
  payload: xpub,
});

export const setNewWalletXFPAction = xfp => ({
  type: WALLET_NEW_SET_XFP,
  payload: xfp,
});

export const setDefaultBip32PathAction = coldcard => {
  return dispatch => {
    const bip32Path = defaultBIP32Path(coldcard);
    dispatch(setNewWalletBIP32PathAction(bip32Path));
    dispatch(setNewWalletXpubAction(""));
  };
};

export const setNewWalletAdvancedAction = advanced => ({
  type: SET_NEW_WALLET_SHOW_ADVANCED,
  payload: advanced,
});

export const setNewWalletActiveAction = active => ({
  type: SET_NEW_WALLET_ACTIVE,
  payload: active,
});

export const setNewWalletSuccessMessageAction = successMessage => ({
  type: SET_NEW_WALLET_SUCCESS_MESSAGE,
  payload: successMessage,
});

export const resetNewKeyWizardAction = () => ({
  type: RESET_NEW_KEY_FLOW,
  payload: null,
});

export const setNewWalletOrgAction = org => ({
  type: SET_NEW_WALLET_ORG,
  payload: org,
});
