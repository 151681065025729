import { combineReducers } from "redux";

import { deviceCheckWizardReducer as deviceCheckWizard } from "./deviceCheckReducer";
import { keyNewWizardReducer as keyNewWizard } from "./keyNewWizardReducer";
import { keyIndexReducer as keyIndex } from "./keysIndexReducer";

const accountKeyReducers = combineReducers({
  keyIndex,
  keyNewWizard,
  deviceCheckWizard,
});

export { accountKeyReducers };
