import { createSelector } from "reselect";

import { getConfig } from "Utils/config";
import { btcFeeCalcEstimate, btcToSats, calculateOutputCount, satsToBtc } from "Utils/currency";

import { outflowFeeRateSelector, outflowPartialSelector } from "../transactionSelectors";
import { vaultWalletUtxosCountSelector, vaultUnitSelector } from "./vaultShowSelectors";

const balanceSelector = state => state.vaults.vaultShow.vault.balance;
const outflowMaxSelector = state => state.transaction.outflow.max;

// *  -  -  -  -  -  -  -  -  * //
// * Calculate Fee for vaults * //
// *  -  -  -  -  -  -  -  -  * //

// ** Calculate fees for a btc vault transaction  ** //

const outputCountBtcVaultOutflowSelector = createSelector(
  outflowPartialSelector,
  outflowMaxSelector,
  calculateOutputCount
);

// the first three selectors are the parameters to btcFeeCalcEstimate:
// (feeRateInSatoshis, numOutputs, numInputs)
export const feeEstimateBtcVaultSelector = createSelector(
  outflowFeeRateSelector, // initially set to the default fee rate, and can be changed by the user
  outputCountBtcVaultOutflowSelector, // determined by checking whether or not this is a partial transaction (2 or 1)
  vaultWalletUtxosCountSelector, // number of utxos for the vault (#inputs)
  btcFeeCalcEstimate // the calculation function
);

// ** Calculate fees for a eth vault transaction  ** //
// This is done in the common file since it's the same for vaults and loans

// ** Calculate fees for this vault ** //
// Based on the this vaults unit, we'll determine which way to calculate the fees
export const feeEstimateVaultSelector = createSelector(
  outflowFeeRateSelector, // initially set to the default fee rate, and can be changed by the user
  outputCountBtcVaultOutflowSelector, // determined by checking whether or not this is a partial transaction (2 or 1)
  vaultWalletUtxosCountSelector, // number of utxos for the vault (#inputs)
  btcFeeCalcEstimate
);

// *  -  -  -  -  -  -  -  * //
// * Calculate Max Amount  * //
// *  -  -  -  -  -  -  -  * //

const maxAmountBtcVaultSelector = createSelector(
  balanceSelector,
  feeEstimateVaultSelector,
  outflowMaxSelector,
  (confirmedBalance, feeEstimate, max) => {
    let amount = btcToSats(confirmedBalance) - btcToSats(feeEstimate);
    if (!max) {
      amount -= btcToSats(getConfig("wallet.dust_limit.BTC"));
    }
    return satsToBtc(amount);
  }
);

export const maxAmountVaultSelector = createSelector(
  vaultUnitSelector,
  maxAmountBtcVaultSelector,
  (unit, btcMaxAmount, ethMaxAmount) => {
    return btcMaxAmount;
  }
);
