import React from "react";

import { TransactionIcon } from "Components/Shared/Icons/TransactionIcon";
import { SellBitcoinStepperWizard } from "Components/TradingDesk/sell/SellBitcoinStepperWizard";
import { AppModalManager } from "Shared/components/Modals";
import { btcOperationTypes } from "Utils/enums";
import { splitOperationTypePlural } from "Utils/operationTypes";

const operationVerbClauseMap = {
  // These operation types can not generate BroadcastTransactionNotifications
  //discovered_bitcoin_transaction: "discovered transfer",
  // manual_change: "manual transfer",
  // rekey: "rekey",
  // external_vault_deposit: "deposit",
  // external_loan_deposit: "deposit",
  // loan_payment: "pay",
  // loan_principal_payment: "pay",
  // loan_prepayment: "prepay",
  // loan_disbursement: "disburse from",
  // loan_collateral_sale: "sell from",

  vault_transaction: "transfer from",
  vault_sweep: "sweep of",
  batch_settlement: "batch settlement from",
  loan_redemption: "transfer from",
  loan_liquidation: "liquidate from",
  loan_sweep: "sweep of",
  loan_closing: "closing of",
};

const generateOperationVerbClause = operationType => {
  if (operationVerbClauseMap[operationType]) {
    return operationVerbClauseMap[operationType];
  }

  // Error?
  return operationType;
};

const BroadcastTransactionNotification = ({
  operation_type,
  operation_uuid,
  product_name,
  product_uuid,
}: {
  operation_type: string;
  operation_uuid: string;
  product_name: string;
  product_uuid: string;
}) => {
  const isSaleTransaction = operation_type === btcOperationTypes.vault_sale_transaction;

  const [products, operations] = splitOperationTypePlural(operation_type);
  const link = isSaleTransaction
    ? ""
    : `/${products}/${product_uuid}/${operations}/${operation_uuid}`;

  const operationVerbClause = generateOperationVerbClause(operation_type);

  const title = isSaleTransaction
    ? `Sell transaction from ${product_name} is ready to broadcast`
    : `Broadcast transaction for ${operationVerbClause} ${product_name}`;

  const openSellBitcoinFlow = () =>
    AppModalManager.open(() => {
      return <SellBitcoinStepperWizard />;
    });
  const additionalAction = isSaleTransaction ? openSellBitcoinFlow : undefined;

  return {
    to: link,
    title: title,
    Icon: TransactionIcon,
    additionalAction: additionalAction,
  };
};

export { BroadcastTransactionNotification };
