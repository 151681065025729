import React, { ReactNode } from "react";

import { DefaultPlayer as Video } from "react-html5video";

import styles from "./VideoWithScript.module.scss";

type VideoWithScriptProps = {
  videoSrcUrl: string;
  script: string | ReactNode;
};

export const VideoWithScript = ({ videoSrcUrl, script }: VideoWithScriptProps) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="relative aspect-[1/1] max-h-[21rem] w-full overflow-hidden rounded-lg">
        <Video
          controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
          className={`relative aspect-[1/1] h-full w-full rounded-lg ${styles.videoParent}`}
        >
          <source src={videoSrcUrl} type="video/webm" />
        </Video>
      </div>
      <div className="relative aspect-[1/1] max-h-[21rem] w-full rounded-md bg-gray-100 p-4 text-left">
        {script}
      </div>
    </div>
  );
};
