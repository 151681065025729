import { splitOperationTypePlural } from "Utils/operationTypes";
import { pluralize } from "Utils/strings";

import { API } from "./api";

export class OperationAPI {
  /**
   * @description returns a specific operation for a given product
   * @param {string} productUUID
   * @param {string} operationType
   * @param {string} operationUUID
   * @returns {object} an operation returned from the server
   */
  static Get = async (productUUID, operationType, operationUUID) => {
    const [productType, operation] = splitOperationTypePlural(operationType);
    const response = await API.Get(`/${productType}/${productUUID}/${operation}/${operationUUID}`);
    return response.data.operation;
  };

  /**
   * @description returns a paginated list of operations matching the given state
   * @param {string} productType - one of "loan" or "vault"
   * @param {string} productUUID
   * @param {array} [states=["created"]] - e.g. ["created"] or ["created", "pending"]
   * @param {number} [page=1]
   * @param {number} [perPage=20]
   * @returns {object} pagination response with list of operations, total, totalPages, perPage
   */
  static List = async (
    productType = "",
    productUUID = "",
    states = ["created"],
    page = 1,
    perPage = 20
  ) => {
    const params = { page, per_page: perPage };

    let resourceUrl = `/${pluralize(productType)}/${productUUID}/operations`;
    resourceUrl +=
      states && states.length > 0 && Array.isArray(states)
        ? "?state=" + states.join(`&state=`)
        : "";

    const response = await API.Get(resourceUrl, params);

    const { operations, total, total_pages, per_page } = response.data;
    return {
      operations,
      total,
      totalPages: total_pages,
      perPage: per_page,
    };
  };
}
