import { SKUS } from "Shared/api/v2";

export const trustAnnualFee = 250;

const trustUserOptionValues = [1, 2, 3] as const;
export type TrustUserOptionValue = (typeof trustUserOptionValues)[number];
export const trustUserOptions = [
  {
    label: "1 user",
    value: trustUserOptionValues[0],
    sku: SKUS.CONCIERGE_ONBOARDING_TRUST_ONE,
    description: "If there will only be one user managing keys and vaults on the platform.",
  },
  {
    label: "2 users",
    value: trustUserOptionValues[1],
    sku: SKUS.CONCIERGE_ONBOARDING_TRUST_TWO,
    description: "If there will only be two users managing keys on the platform.",
  },
  {
    label: "3-5 users",
    value: trustUserOptionValues[2],
    sku: SKUS.CONCIERGE_ONBOARDING_TRUST_THREE,
    description: "If there will be multiple users managing keys and vaults on the platform.",
  },
] as const;
