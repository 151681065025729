import { GetLoanFeeRates200 } from "Specs/v1";
import { GetRecommendedFeeRates200 } from "Specs/v1/getRecommendedFeeRates/200";
import { getConfig } from "Utils/config";

import { API } from "./api";

/**
 * This should not be used directly whenever possible. Instead, use the
 * useFeeRates hook found at Shared/api/hooks/fees.
 */
export class FeesAPI {
  static GetRecommended = async () => {
    try {
      const response = await API.Get<GetRecommendedFeeRates200>(`/fee_rates`);
      const { fee_rates } = response.data;
      return fee_rates;
    } catch (e) {
      console.error("Problem retrieving dynamic fee recommendations");
      return {
        purge_rate: getConfig("wallet.fee_rates.BTC.min"),
        recommended: getConfig("wallet.fee_rates.BTC.fallback"),
        fast: getConfig("wallet.fee_rates.BTC.fallback"),
        slow: getConfig("wallet.fee_rates.BTC.min"),
        max: getConfig("wallet.fee_rates.BTC.max"),
      };
    }
  };

  static GetLoanFeeRates = async () => {
    const response = await API.Get<GetLoanFeeRates200>(`/loans/fee-rates`);
    return response.data.data;
  };
}
