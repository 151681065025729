/**This module is intended to store global constants which may be used across
the app but which are not necessarily a configuration concern which relies on
trefoil or which the coupling is undesirable.
*/

/**This value is a hard limit on fee size for a bitcoin transaction as enforced
by bitcoind. Exceeding this limit, the transaction broadcast attempt will
error with "absurdly-high-fee". Newer versions of bitcoind call this error
"max-fee-exceeded", and in these newer versions it is configurable and can
also use fee rate instead.
*/
export const BITCOIND_MAX_FEE_SATS = 10000000;
