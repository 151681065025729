import { UserAPI } from "Shared/api/userApi";

export const UPDATE_USER_DATA = "/USER/UPDATE";
export const RESET_USER_DATA = "/USER/RESET";
export const UPDATE_USER_STATUS = "/USER/STATUS/UPDATE";
export const UPDATE_USER_NOTE = "/USER/NOTE/UPDATE";
export const UPDATE_USER_CODENAME = "/USER/CODENAME/UPDATE";

export const updateUserAction = user => ({
  type: UPDATE_USER_DATA,
  payload: user,
});

export const updateUserNoteAction = note => ({
  type: UPDATE_USER_NOTE,
  payload: note,
});

export const updateUserStatusAction = status => ({
  type: UPDATE_USER_STATUS,
  payload: status,
});

export const updateUserCodenameAction = codename => ({
  type: UPDATE_USER_CODENAME,
  payload: codename,
});

export const resetUserAction = () => ({
  type: RESET_USER_DATA,
});

export const submitUserChangeCodenameAction = codename => async (dispatch, getState) => {
  const uuid = getState().user.user.uuid;
  await UserAPI.ChangeCodename(uuid, codename);
  dispatch(updateUserCodenameAction(codename));
};
