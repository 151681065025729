import { useContext } from "react";

import { PricingTable, PricingTableProps, formatCurrency } from "@unchained/component-library";
import { useMutation } from "react-query";

import { ManifestStep } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { ProfileAPI } from "Shared/api";
import { SKUS } from "Shared/api/v2";
import { usePurchasables } from "Shared/api/v2/hooks/purchasables";
import { CompleteOrgBusinessTierEnum } from "Specs/v1/getAccount/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { OnboardBizOrgContext } from "../../OnboardBizOrgContext";

export const bizTiers = [
  {
    header: "Basic",
    subheader: "For 1-2 users",
    amountDescription: "per year",
    features: [
      "Best-in-class collaborative custody",
      "Unlimited vaults",
      "Distribute key control across your team",
      "Video verification for sensitive vault tasks",
      "Supports key replacements for staff changes",
    ],
    description: "per user",
    featuresHeader: "FEATURES",
    value: "basic",
    userLimit: 2,
    sku: SKUS.BUSINESS_ACCOUNT_BASIC_ONE,
  },
  {
    header: "Pro",
    subheader: "For 3-5 users",
    amountDescription: "per year",
    features: [
      "Transaction auditability and customizable approval roles",
      "Unlimited view-only users",
    ],
    description: "covers all users",
    featuresHeader: "FEATURES",
    featuresSubheader: (
      <span>
        Everything in <strong>Basic</strong>, plus...
      </span>
    ),
    chip: "Recommended",
    value: "pro",
    userLimit: 5,
    sku: SKUS.BUSINESS_ACCOUNT_PRO,
  },
  {
    header: "Enterprise",
    subheader: "For 6+ users",
    amountTitle: "Custom",
    description: "Schedule a consultation today",
    featuresHeader: "FEATURES",
    featuresSubheader: (
      <span>
        Everything in <strong>Pro</strong>, plus...
      </span>
    ),
    features: ["Dedicated technical support"],
    value: "enterprise",
    userLimit: Infinity,
  },
] as (PricingTableProps & { value: CompleteOrgBusinessTierEnum; userLimit: number; sku: string })[];

export const BusinessTier = ({ org }: { org: CompleteOrg }) => {
  const wrapManifestMutation = useWrapManifestMutation();
  const purchasables = usePurchasables();
  const { userCount } = useContext(OnboardBizOrgContext);
  const updateTier = wrapManifestMutation(
    useMutation((businessTier: CompleteOrgBusinessTierEnum) =>
      ProfileAPI.UpdateBasic(org.uuid, { business_tier: businessTier })
    )
  );

  const selectTier = (tier: CompleteOrgBusinessTierEnum) => {
    userCount.set(undefined);
    updateTier.mutate(tier);
  };

  if (!purchasables.data) return null;

  const tables = bizTiers.map(({ value, ...tier }) => {
    let amountTitle: string;
    if (value === "enterprise") {
      amountTitle = "Custom";
    } else {
      const purchasable = purchasables.data.purchasables.find(p => p.sku === tier.sku);
      if (!purchasable) throw new Error(`Purchasable not found for sku ${tier.sku}`);
      amountTitle = formatCurrency(parseFloat(purchasable.usdPrice), "USD");
    }
    return (
      <PricingTable
        {...tier}
        amountTitle={amountTitle}
        key={tier.header as string}
        className={org.business_tier === value ? "bg-primary-50" : ""}
        actions={[
          {
            children: value === "enterprise" ? "Contact sales" : "Get started",
            onClick: () => selectTier(value),
          },
        ]}
      />
    );
  });

  return (
    <ManifestStep
      title="Select your business plan"
      description="Plan options based on the number of users who will need access to the Unchained platform."
    >
      <div className="grid max-w-6xl grid-cols-1 gap-4 lg:grid-cols-3">{tables}</div>
    </ManifestStep>
  );
};
