import { updatedField } from "Utils/errors";

var initialState = {
  asset: "",
  name: "",
  path: "",
  errors: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "WALLET/MEMBERSHIP/SET/ASSET":
      return updatedField(state, action, "asset");
    case "WALLET/MEMBERSHIP/SET/NAME":
      return updatedField(state, action, "name");
    case "WALLET/MEMBERSHIP/SET/PATH":
      return updatedField(state, action, "path");

    case "WALLET/MEMBERSHIP/CONFIRMATION/ERROR":
      return { ...state, errors: action.errors };
    case "WALLET/MEMBERSHIP/CONFIRMATION/SUCCESS":
      return { ...state, errors: {} };
    default:
      return state;
  }
};
