import Big from "big.js";
import get from "lodash/get";

import { FeesAPI } from "Shared/api/feesApi";
import { formatCurrency } from "Utils/strings";

export const isNumberOrNumericString = value => {
  if (typeof value === "string" && value !== "") {
    const castedValue = Number(value);
    return typeof castedValue === "number" && !isNaN(castedValue);
  }
  if (typeof value === "number") {
    return !isNaN(value);
  }
  return false;
};

export const cryptoToFiat = (crypto, unit, prices) => {
  const cryptoValue = parseFloat(crypto);
  if (isNaN(cryptoValue)) {
    return null;
  }
  if (prices && prices[unit] && prices[unit].value && prices[unit].value > 0) {
    return cryptoValue * prices[unit].value;
  } else {
    return null;
  }
};

export const bitcoinToFiat = (bitcoinAmount, price) => {
  const bitcoinValue = parseFloat(bitcoinAmount);
  if (isNaN(bitcoinValue)) {
    return null;
  }
  if (price && price > 0) {
    return bitcoinValue * price;
  } else {
    return null;
  }
};

export const cryptoToFiatPartial = (unit, prices) => crypto => {
  return cryptoToFiat(crypto, unit, prices);
};

export const fiatToCrypto = (fiat, unit, prices) => {
  const fiatValue = parseFloat(fiat);
  if (isNaN(fiatValue)) {
    return null;
  }
  if (prices && prices[unit] && prices[unit].value && prices[unit].value > 0) {
    return fiatValue / prices[unit].value;
  } else {
    return null;
  }
};

export const fiatToCryptoPartial = (unit, prices) => fiat => {
  return fiatToCrypto(fiat, unit, prices);
};

export const fiatWithUnit = (fiatAmount, places, noCommas, rounding) => {
  return typeof fiatAmount === "number" && !isNaN(fiatAmount)
    ? `$${formatCurrency(fiatAmount, places, noCommas, rounding)}`
    : null;
};

export const fiatWithUnitWithoutCents = fiatAmount => {
  const fullAmount = fiatWithUnit(Number(fiatAmount));

  return fullAmount?.includes(".") ? fullAmount.split(".")[0] : fullAmount;
};

export const cryptoWithUnit = (cryptoAmount, unit) => {
  if (typeof cryptoAmount === "number" || typeof cryptoAmount === "string") {
    return `${cryptoAmount} ${unit}`;
  } else {
    return null;
  }
};

export const btcToSats = cryptoAmount => {
  if (!isNumberOrNumericString(cryptoAmount)) {
    return "";
  }
  return Big(cryptoAmount).times(Big(10).pow(8)).toString();
};

export const satsToBtc = satoshiAmount => {
  if (!isNumberOrNumericString(satoshiAmount)) {
    return "";
  }
  return Big(satoshiAmount).div(Big(10).pow(8)).toString();
};

/**
 * Rounds down crypto amount to 4 digits to display following industry practices
 * @param {number} cryptoAmount - A number
 * @return {Number|null}
 */
export const shortCrypto = cryptoAmount => {
  if (typeof cryptoAmount === "number") {
    return Math.floor(cryptoAmount * 10000) / 10000;
  } else {
    return null;
  }
};

/**
 * Formats a crypto amount to 4 decimal places with the unit concatenated as a post-fix
 * @param {number|null} cryptoAmount - The amount to display
 * @param {string} unit - The currency's identifier
 * @return {string} - The amount with exactly 4 decimals places and the unit or an empty string
 */
export const shortCryptoWithUnit = (cryptoAmount, unit = "BTC") => {
  if (typeof cryptoAmount === "number") {
    return cryptoWithUnit(
      cryptoAmount.toLocaleString(undefined, { minimumFractionDigits: 4 }),
      unit
    );
  } else {
    return "";
  }
};

/**
 * Formats a crypto amount to 8 decimal places to display following industry practices
 * @param {number} cryptoAmount
 * @returns {Number|null}
 */
export const longCrypto = cryptoAmount => {
  return typeof cryptoAmount === "number" ? cryptoAmount.toFixed(8) : null;
};

/**
 * Formats a crypto amount to 8 decimal places with the unit concatenated as a post-fix
 * @param {number|null} cryptoAmount - The amount to display
 * @param {string} unit - The currency's identifier
 * @return {string} - The amount with exactly 8 decimals places and the unit or an empty string
 */
export const longCryptoWithUnit = (cryptoAmount, unit) => {
  if (typeof cryptoAmount === "number") {
    return cryptoWithUnit(longCrypto(cryptoAmount), unit);
  } else {
    return "";
  }
};

export const longBitcoinWithUnit = amount => {
  return longCryptoWithUnit(amount, "BTC");
};

/**
 * Estimates the bitcoin fee for a transaction from an account with a particular set
 *   of UTXOs and the given parameters.
 * @param account {Object} Contains the properties:
 *     * slice {Object} Contains the properties:
 *       * utxos {Array<Object>}
 * @param partial {boolean} If true, is a partial-spend. If false, is a max spend.
 * @param feeRateInSatsPerByte {number|string}
 * @returns {string}
 * TODO: Should be changed to return a number, not a string.
 */
export const btcFeeEstimate = (account, partial, feeRateInSatsPerByte) => {
  const numUTXOs = get(account, "wallet.deposited_utxo_count", 0);
  const numOutputs = calculateOutputCount(partial);
  return btcFeeCalcEstimate(feeRateInSatsPerByte, numOutputs, numUTXOs);
};

/**
 * Calculates the number of outputs a transaction will have depending on whether its a max spend or not.
 * @param partial {boolean} Is true if this is a partial spend, false if its a max spend.
 * @returns {number}
 */
export const calculateOutputCount = partial => {
  return partial ? 2 : 1;
};

/** Returns the number of utxos our current loan has (total number of inputs).
 * @param utxos {Array<?Object>} A list of objects, where each object has the properties:
 *   * state {string} The state of the UTXO (TODO: What values are possible here?)
 * @returns {number}
 */
export const calculateUtxoCount = utxos => {
  if (!utxos) {
    return 0;
  }
  return utxos.filter(utxo => utxo && utxo.state === "confirmed").length;
};

/**
 * Estimates the fee for a transaction with the given parameters.
 * @param feeRateInSatsPerByte {number|string}
 * @param numOutputs {number|string}
 * @param numUTXOs {number|string}
 * @returns {string} The estimated fee as a formatted number of bitcoin.
 *   Returns the empty string if the parameters are malformatted.
 * TODO: Should return a number, not a string. Formatting of the number should be done elsewhere.
 */
export const btcFeeCalcEstimate = (feeRateInSatsPerByte, numOutputs, numUTXOs) => {
  if (
    !isNumberOrNumericString(feeRateInSatsPerByte) ||
    !isNumberOrNumericString(numOutputs) ||
    !isNumberOrNumericString(numUTXOs)
  ) {
    return "";
  }
  const txLengthInBytes = 10 + 297 * numUTXOs + 34 * numOutputs;
  const feeInSatoshis = Big(txLengthInBytes).times(feeRateInSatsPerByte);
  const feeInBTC = feeInSatoshis.div(100000000); // 1e8 Satoshi per BTC
  return feeInBTC.toFixed(8);
};

/**
 * Ensure a string value of a currency number has exactly two decimal places.
 * @param input {string}
 * @returns {string} The input with exactly two decimal places.
 */
export const ensureTwoDecimalPlaces = input => {
  let parts = input.split(".");
  if (parts.length < 2) {
    return input + ".00";
  } else if (parts[1].length === 1) {
    return input + "0";
  } else if (parts[1].length > 2) {
    return parts[0] + "." + parts[1].substring(0, 2);
  } else {
    return input;
  }
};
