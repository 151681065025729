import { WizardStep } from "@unchained/component-library";

import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import { CompleteOrgAccountTypeEnum } from "Specs/v1/getAccount/200";
import { CompleteOrg, COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";

import { AwaitApproval } from "../(shared)";
import {
  BusinessName,
  CreateYourAccount,
  CreateYourProfile,
  NameAndDob,
  PhoneNumber,
  PlaceOfResidence,
  UserIdentification,
} from "../(steps)";
import ConfirmUserIsTrustee from "../(steps)/CreateYourAccount/01ConfirmUserIsTrustee";
import { ReviewProfile } from "../(steps)/CreateYourProfile/05ReviewProfile";
import { EmploymentStatus, PersonalIncome, WealthDigitalAssets } from "../[uuid]/(individual)";
import {
  initEmployment,
  initIncome,
  initSsn,
  initWealth,
  validateEmployment,
  validateIncome,
  validateSsn,
  validateWealth,
} from "../[uuid]/(individual)/individualOrgValsAndValidations";
import { paymentComplete, SocialSecurityNumber } from "../[uuid]/(shared)";
import {
  initNameAndDob,
  initPhone,
  initPlaceOfRes,
  initUserId,
  validateNameAndDob,
  validatePhone,
  validatePlaceOfRes,
  validateUserId,
} from "./basicInfoValsAndValidations";
import { OnboardBasicContextType, OnboardingInfo } from "./context";
import { hideSkippedSteps } from "./manifestUtils";
import { OnboardingGoal, simplifiedOrgStates } from "./simplifiedOrgStates";

const { BUSINESS, TRUST } = COMPLETE_ORG_ACCOUNT_TYPE;

const createProfileSubSteps = (info: OnboardingInfo, context: OnboardBasicContextType) => {
  const { personalOrg } = info;
  const { editable, approved } = simplifiedOrgStates(personalOrg, OnboardingGoal.BASIC);

  const { state } = personalOrg;
  const inactive = state === "pending_activation";

  const disableProfileSteps = !(editable || inactive);

  const mainSteps: WizardStep["steps"] = [
    {
      title: "Create your profile",
      Component: () => <CreateYourProfile />,
      excludeFromNavigation: true,
      complete: !inactive,
    },
    {
      title: "Legal name and date of birth",
      complete: (!editable && !inactive) || validateNameAndDob.isValidSync(initNameAndDob(info)),
      Component: () => <NameAndDob />,
      disabled: disableProfileSteps,
    },
    {
      title: "Place of residence",
      complete: (!editable && !inactive) || validatePlaceOfRes.isValidSync(initPlaceOfRes(info)),
      Component: () => <PlaceOfResidence />,
      disabled: disableProfileSteps,
    },
    {
      title: "Phone number",
      complete: (!editable && !inactive) || validatePhone.isValidSync(initPhone(info)),
      Component: () => <PhoneNumber />,
      disabled: disableProfileSteps,
    },
    {
      title: "User identification",
      complete: (!editable && !inactive) || validateUserId.isValidSync(initUserId(info)),
      Component: () => <UserIdentification />,
      disabled: disableProfileSteps,
    },
    {
      title: "Social security number",
      complete: validateSsn.isValidSync(initSsn(personalOrg)),
      Component: () => <SocialSecurityNumber org={personalOrg} canSkipSsn={false} />,
      disabled: disableProfileSteps,
    },
    {
      title: "Employment status",
      complete: validateEmployment.isValidSync(initEmployment(personalOrg)),
      disabled: disableProfileSteps,
      Component: () => <EmploymentStatus org={personalOrg} />,
    },
    {
      title: "Personal income",
      complete: validateIncome.isValidSync(initIncome(personalOrg)),
      disabled: disableProfileSteps,
      Component: () => <PersonalIncome org={personalOrg} />,
    },
    {
      title: "Wealth & digital assets",
      complete: validateWealth.isValidSync(initWealth(personalOrg)),
      disabled: disableProfileSteps,
      Component: () => <WealthDigitalAssets org={personalOrg} />,
    },
  ];

  const profileStepsComplete = mainSteps.every(step => step.complete);

  const reviewDisabled = !profileStepsComplete || !editable;

  const review: WizardStep["steps"] = [
    {
      title: "Review and finish",
      complete: !editable && !inactive,
      disabled: reviewDisabled,
      Component: () => <ReviewProfile />,
    },
    {
      title: "Wait for KYC",
      excludeFromNavigation: true,
      fullPage: true,
      complete: approved,
      Component: () => (
        <AwaitApproval org={personalOrg as CompleteOrg} type={OnboardingGoal.BASIC} />
      ),
    },
  ];

  return [...mainSteps, ...review];
};

const createAccountSteps = (accountType?: CompleteOrgAccountTypeEnum) =>
  [
    {
      title: "Create your account",
      Component: () => <CreateYourAccount />,
      excludeFromNavigation: true,
      complete: !!accountType,
    },
    {
      title: "Name your business",
      Component: () => <BusinessName />,
      complete: false,
      skip: accountType !== BUSINESS,
    },
    {
      title: "Trustee confirmation",
      Component: () => <ConfirmUserIsTrustee />,
      complete: false,
      skip: accountType !== TRUST,
    },
  ] as WizardStep["steps"];

export const basicInfoManifest = (account: OnboardingInfo, context: OnboardBasicContextType) => {
  const { personalOrg } = account;
  const { person } = personalOrg;
  const { approved, terminal } = simplifiedOrgStates(personalOrg, OnboardingGoal.BASIC);
  const info = { person, ...account };

  const profileSubSteps = createProfileSubSteps(info, context);
  const accountSteps = createAccountSteps(context.accountType.value);

  const paid = paymentComplete(personalOrg);

  const hideProfileAndOnboarding = approved && !!(paid || context.from);

  const manifest: (WizardStep & { skip?: boolean })[] = [
    {
      title: "Create your profile",
      complete: approved || profileSubSteps.every(step => step.complete) || terminal,
      steps: profileSubSteps,
      skip: hideProfileAndOnboarding,
      disabled: terminal,
    },
    {
      title: "Create your account",
      complete: false,
      steps: accountSteps,
      disabled: !approved,

      // let them cancel out, and return to where they came from
      AlternateIcon: hideProfileAndOnboarding
        ? () => <CloseButton to={context.from || "/home"} />
        : undefined,
    },
    // Fake steps below! These are unclickable on this view,
    // But once the previous step takes you to a new page,
    // the identically named steps there are clickable.
    {
      title: "Hardware & onboarding options",
      disabled: true,
      skip: hideProfileAndOnboarding,
    },
    {
      title: approved ? "Checkout" : "Checkout and launch Unchained",
      disabled: true,
      skip: hideProfileAndOnboarding,
    },
  ];

  return hideSkippedSteps(manifest);
};
