import { createSelector } from "reselect";

import { Loan } from "Models/loans";
import { cryptoToFiat, fiatWithUnit } from "Utils/currency";

import { currentPriceSelector } from "../priceSelectors";

const loanSelector = state => state.loans.loanShow.loan;
export const loanUnitSelector = state => state.loans.loanShow.loan.unit;
export const loanCollateralSelector = state => state.loans.loanShow.loan.collateral;
export const loanOriginalCollateralSelector = state =>
  state.loans.loanShow.loan.original_collateral;

export const getLoan = createSelector(loanSelector, loan => {
  return { ...Loan(loan) };
});

export const collFiatValueWithUnitSelector = createSelector(
  loanCollateralSelector,
  loanUnitSelector,
  currentPriceSelector,
  (collateral, unit, prices) => {
    if (collateral >= 0 && prices && prices[unit]) {
      return fiatWithUnit(cryptoToFiat(collateral, unit, prices));
    } else {
      return null;
    }
  }
);

export const orgCollFiatValueWithUnitSelector = createSelector(
  loanOriginalCollateralSelector,
  loanUnitSelector,
  currentPriceSelector,
  (originalCollateral, unit, prices) => {
    if (originalCollateral >= 0 && prices && prices[unit]) {
      return fiatWithUnit(cryptoToFiat(originalCollateral, unit, prices));
    } else {
      return null;
    }
  }
);

export const getFormattedLoan = createSelector(
  loanSelector,
  collFiatValueWithUnitSelector,
  orgCollFiatValueWithUnitSelector,
  currentPriceSelector,
  (loan, collFiatValueWithUnit, orgCollFiatValueWithUnit, prices) => {
    return {
      ...Loan(loan, prices),
      collFiatValueWithUnit,
      orgCollFiatValueWithUnit,
    };
  }
);
