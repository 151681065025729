import {
  CreateTrustGrantor200,
  CreateTrustTrustee200,
  DeleteTrustGrantor200,
  DeleteTrustTrustee200,
  PatchOrgTrust200,
  PatchOrgTrustRequest,
  PatchTrustGrantor200,
  PatchTrustGrantorRequest,
} from "Specs/v1";
import { CreateTrustGrantorRequest } from "Specs/v1/createTrustGrantor/request";
import { CreateTrustTrusteeRequest } from "Specs/v1/createTrustTrustee/request";
import { DeleteTrustGrantorPathParams } from "Specs/v1/deleteTrustGrantor/params/path";
import { DeleteTrustTrusteePathParams } from "Specs/v1/deleteTrustTrustee/params/path";
import { PatchTrustGrantorPathParams } from "Specs/v1/patchTrustGrantor/params/path";

import { API } from "./api";

export class TrustAPI {
  static PatchTrust = async (orgUuid: string, trust: PatchOrgTrustRequest) =>
    (await API.Patch<PatchOrgTrust200>(`/orgs/${orgUuid}/trust`, trust)).data;

  static CreateGrantor = async (orgUuid: string, grantor: CreateTrustGrantorRequest) =>
    (await API.Post<CreateTrustGrantor200>(`/orgs/${orgUuid}/trust/grantors`, grantor)).data;

  static UpdateGrantor = async (
    params: PatchTrustGrantorPathParams,
    grantor: PatchTrustGrantorRequest
  ) =>
    (
      await API.Patch<PatchTrustGrantor200>(
        `/orgs/${params.orgUuid}/trust/grantors/${params.grantorUuid}`,
        grantor
      )
    ).data;

  static DeleteGrantor = async (params: DeleteTrustGrantorPathParams) =>
    (
      await API.Delete<DeleteTrustGrantor200>(
        `/orgs/${params.orgUuid}/trust/grantors/${params.grantorUuid}`
      )
    ).data;

  static CreateTrustee = async (orgUuid: string, trustee: CreateTrustTrusteeRequest) =>
    (await API.Post<CreateTrustTrustee200>(`/orgs/${orgUuid}/trust/trustees`, trustee)).data;

  static DeleteTrustee = async (params: DeleteTrustTrusteePathParams) =>
    (
      await API.Delete<DeleteTrustTrustee200>(
        `/orgs/${params.orgUuid}/trust/trustees/${params.trusteeUuid}`
      )
    ).data;

  static DownloadTrustCertification = async (orgUuid: string) => {
    await API.Download(`/orgs/${orgUuid}/trust/certification`);
  };
}
