import React from "react";

import { KEYSTORES } from "@caravan/wallets";

import { KEYSTORE } from "../types";
import {
  ChooseDevice,
  ColdcardRegistrationStep,
  LedgerRegistrationOverviewStep,
  LedgerWalletRegistrationStepWithContext,
  RegistrationComplete,
} from "./steps";

export interface Step {
  title: string;
  subtitle?: string | React.ReactNode;
  Component: () => React.ReactElement;
  modalSize?: "sm" | "md" | "lg";
  hideOnBack?: boolean;
}

const manifestBase: Step[] = [
  {
    title: "Register your device",
    subtitle: (
      <>
        Which device do you want to register this wallet with? (You can register another one later,
        if you have multiple.){" "}
        {/* We use an a tag, not a Link, here to avoid circular dependencies I couldn't source. */}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://help.unchained.com/what-does-device-registration-mean"
        >
          Learn more.
        </a>
      </>
    ),
    Component: () => <ChooseDevice />,
    modalSize: "sm",
  },
];

const ledgerManifest: Step[] = [
  {
    title: "Register a Ledger with this wallet",
    Component: () => <LedgerRegistrationOverviewStep />,
    modalSize: "sm",
  },
  {
    title: "Register a Ledger with this wallet",
    subtitle: (
      <>
        Make sure the info shown on your device matches that shown in the table below. Press
        "Approve" on your device for each item. Once all information is confirmed on the device, you
        will be automatically advanced forward.
      </>
    ),
    Component: () => <LedgerWalletRegistrationStepWithContext />,
    modalSize: "lg",
  },
];

const coldcardManifest = [
  {
    title: "Register Coldcard with this wallet",
    subtitle: (
      <>
        Registering your Coldcard is easy, just follow the instructions below. For your security,
        this step is required before you can sign transactions with this device.{" "}
        {/* We use an a tag, not a Link, here to avoid circular dependencies I couldn't source. */}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://help.unchained.com/what-does-device-registration-mean"
        >
          Learn more.
        </a>
      </>
    ),
    Component: () => <ColdcardRegistrationStep />,
    modalSize: "md",
  },
];

const finalSteps: Step[] = [
  {
    title: "",
    Component: () => <RegistrationComplete />,
    hideOnBack: true,
  },
];

const trezorManifest = [];

const manifestMap = {
  [KEYSTORES.LEDGER]: [...manifestBase, ...ledgerManifest],
  [KEYSTORES.TREZOR]: [...manifestBase, ...trezorManifest],
  [KEYSTORES.COLDCARD]: [...manifestBase, ...coldcardManifest],
};

export const getSteps = (keystore: KEYSTORE, includeConfirmation = true): Step[] => {
  const manifest = keystore ? manifestMap[keystore] : manifestBase;
  return includeConfirmation ? [...manifest, ...finalSteps] : manifest;
};
