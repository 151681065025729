import Big, { BigSource } from "big.js";

import { getConfig } from "Utils/config";

export const getMaxSpendable = (
  vaultBalance: BigSource,
  estimatedFee: BigSource,
  isMaxSpend: boolean
) => {
  let amount = Big(vaultBalance).minus(estimatedFee);
  if (!isMaxSpend) {
    // To prevent a change dust
    amount = amount.minus(getConfig("wallet.dust_limit.BTC"));
  }
  return amount.toNumber();
};
