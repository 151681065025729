import { updatedField } from "Utils/errors";

var initialState = {
  active: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "COLLATERAL_HEALTH_GAUGE/SET/ACTIVE":
      return updatedField(state, action, "active");
    default:
      return state;
  }
};
