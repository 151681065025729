import { ReactNode } from "react";

import { useDispatch } from "react-redux";

import { uploadVerificationVideo, verificationWasUploaded } from "Actions/verification_uploads";
import { Link } from "Components/Link";
import { VideoRecordingModal } from "Shared/components/Modals/VideoRecordingModal/VideoRecordingModal";
import { Verification } from "Specs/v1/getAccount/200";

type VerificationRecordModalProps = {
  verification: Verification;
  script: ReactNode;
  isRequired?: boolean;
  afterUploaded?: () => void;
  onCancellation?: () => void;
  onClose?: () => void;
};
export const VerificationRecordModal = ({
  verification,
  script,
  isRequired,
  afterUploaded,
  onCancellation,
  onClose,
}: VerificationRecordModalProps) => {
  const dispatch = useDispatch();

  const handleSubmit = async (video: Blob) => {
    return await dispatch(
      uploadVerificationVideo(verification, video, () => {
        dispatch(
          verificationWasUploaded(
            verification,
            () => {
              if (afterUploaded) afterUploaded();
              else window.location.reload();
            },
            false
          )
        );
      })
    );
  };

  return (
    <VideoRecordingModal
      onClose={onClose}
      title="Video verification"
      subtitle={
        <p>
          Please start recording and read the provided script when ready. Press stop recording when
          finished.{" "}
          <Link to="https://help.unchained.com/how-do-i-set-up-identity-and-intent-verification">
            Learn more
          </Link>
          .
        </p>
      }
      script={script}
      cancelButton={
        onCancellation
          ? {
              children: "Cancel transaction",
              type: "tertiary",
              onClick: onCancellation,
            }
          : undefined
      }
      instructions={
        isRequired ? (
          <p>We need to record a video of you reading the following script:</p>
        ) : (
          <div className="prose mb-4">
            <p>
              You may want to record a video reading the following script if you are asking
              Unchained or a colleague to countersign. This will aide them in confirming the receive
              address for your transaction.
            </p>
            <p>
              To make this a requirement for spending from your vault, upload an identity
              verification video in <Link to="/settings">settings</Link> and add or increase the
              threshold for spending to your vault.
            </p>
          </div>
        )
      }
      submit={handleSubmit}
    />
  );
};
