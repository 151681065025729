import {
  SET_FEATURES_STATUS,
  UPDATE_FEATURES,
  UPSERT_USER_FEATURE,
  DELETE_USER_FEATURE,
  UPSERT_ORG_FEATURE,
  DELETE_ORG_FEATURE,
} from "Actions/features";
import { REQUEST_STATUS } from "Shared/api/api";
import { updatedField } from "Utils/errors";

const initialState = {
  status: REQUEST_STATUS.PENDING,
  global_features: {},
  users_features: {},
  orgs_features: {},
};

const deleteKey = (keyToDelete, obj) =>
  Object.keys(obj).reduce((result, key) => {
    if (key !== keyToDelete) {
      result[key] = obj[key];
    }
    return result;
  }, {});

const upsertUserFeature = (users_features, username, feature) => {
  const existingUsernames = Object.keys(users_features);
  if (existingUsernames.includes(username)) {
    return Object.keys(users_features).reduce(function (updatedUsersFeatures, existingUsername) {
      const userFeatures = users_features[existingUsername].features;
      const updatedUserFeatures =
        existingUsername === username
          ? {
              ...userFeatures,
              ...feature,
            }
          : userFeatures;
      updatedUsersFeatures[existingUsername] = {
        ...users_features[existingUsername],
        features: updatedUserFeatures,
      };
      return updatedUsersFeatures;
    }, {});
  } else {
    return {
      ...users_features,
      [username]: { features: feature },
    };
  }
};

const deleteUserFeature = (users_features, username, name) => {
  return Object.keys(users_features).reduce(function (updatedUsersFeatures, existingUsername) {
    const userFeatures = users_features[existingUsername].features;
    const updatedUserFeatures =
      existingUsername === username ? deleteKey(name, userFeatures) : userFeatures;
    if (Object.keys(updatedUserFeatures).length > 0) {
      updatedUsersFeatures[existingUsername] = {
        ...users_features[existingUsername],
        features: updatedUserFeatures,
      };
    }
    return updatedUsersFeatures;
  }, {});
};

const upsertOrgFeature = (orgs_features, uuid, feature) => {
  const existingUUIDs = Object.keys(orgs_features);
  if (existingUUIDs.includes(uuid)) {
    return Object.keys(orgs_features).reduce(function (updatedOrgsFeatures, existingUUID) {
      const orgFeatures = orgs_features[existingUUID].features;
      const updatedOrgFeatures =
        existingUUID === uuid
          ? {
              ...orgFeatures,
              ...feature,
            }
          : orgFeatures;
      updatedOrgsFeatures[existingUUID] = {
        ...orgs_features[existingUUID],
        features: updatedOrgFeatures,
      };
      return updatedOrgsFeatures;
    }, {});
  } else {
    return {
      ...orgs_features,
      [uuid]: { features: feature },
    };
  }
};

const deleteOrgFeature = (orgs_features, uuid, name) => {
  return Object.keys(orgs_features).reduce(function (updatedOrgsFeatures, existingUUID) {
    const orgFeatures = orgs_features[existingUUID].features;
    const updatedOrgFeatures = existingUUID === uuid ? deleteKey(name, orgFeatures) : orgFeatures;
    if (Object.keys(updatedOrgFeatures).length > 0) {
      updatedOrgsFeatures[existingUUID] = {
        ...orgs_features[existingUUID],
        features: updatedOrgFeatures,
      };
    }
    return updatedOrgsFeatures;
  }, {});
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_FEATURES_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case UPDATE_FEATURES: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPSERT_USER_FEATURE: {
      return {
        ...state,
        users_features: upsertUserFeature(state.users_features, action.username, action.payload),
      };
    }

    case DELETE_USER_FEATURE: {
      return {
        ...state,
        users_features: deleteUserFeature(state.users_features, action.username, action.payload),
      };
    }

    case UPSERT_ORG_FEATURE: {
      return {
        ...state,
        orgs_features: upsertOrgFeature(state.orgs_features, action.uuid, action.payload),
      };
    }

    case DELETE_ORG_FEATURE: {
      return {
        ...state,
        orgs_features: deleteOrgFeature(state.orgs_features, action.uuid, action.payload),
      };
    }

    default:
      return state;
  }
};
