import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { LoanAPI } from "Shared/api/loanApi";

export const loansKeys = {
  getOrgLoans: (orgUuid: string) => ["getCashBalanceWithdrawal", orgUuid],
};
export const useGetOrgLoans = (
  orgUuid: string,
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(loansKeys.getOrgLoans(orgUuid), () => LoanAPI.ListForOrg(orgUuid), {
    refetchOnWindowFocus: false,
    enabled: !!orgUuid,
    onSuccess: data => {
      if (handleOnSuccess) {
        handleOnSuccess(data);
      }
    },
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
  });
};
