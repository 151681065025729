import { Banner } from "@unchained/component-library";

export const ConditionalForeignAddressWarning = ({
  country,
  isBiz,
}: {
  country?: string;
  isBiz?: boolean;
}) => {
  if (!country || ["United States", "US", "USA"].includes(country)) return null;

  return (
    <Banner type="error">
      Unchained does not currently support onboarding non-US {isBiz ? "businesses" : "clients"} at
      this time. Any onboarding application submitted{" "}
      {isBiz ? "for a non-US business" : "by a non-US person"} will be automatically declined.
    </Banner>
  );
};
