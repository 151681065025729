import React, { createContext, useContext, useMemo } from "react";

import {
  Formik as FormikCore,
  FormikConfig,
  FormikErrors,
  FormikProps,
  FormikTouched,
  FormikValues,
} from "formik";

type FormTrackerEntry = Pick<FormikProps<unknown>, "values" | "errors" | "isValid" | "touched"> & {
  when: string;
  formName: string;
  triggerValidation: () => void;
};

/** Represents the state history of a single form */
class FormTracker {
  limit = 50; // Don't track more than 50 historical states
  public entries: FormTrackerEntry[] = [];
  public name: string;
  public logEachChange = false;

  constructor(name: string) {
    this.name = name;
  }

  push = (args: FormikProps<unknown>) => {
    const { values, errors, isValid, touched } = args;
    this.entries.push({
      formName: this.name,
      values,
      errors,
      isValid,
      touched,
      when: new Date().toISOString(),
      triggerValidation: () => {
        try {
          args.validateForm();
        } catch (err) {
          console.error(err);
        }
      },
    });

    // If we've exceeded the limit, remove the first entry
    if (this.entries.length > this.limit) {
      this.entries.shift();
    }

    if (this.logEachChange) {
      this.logLast();
    }
  };

  lastEntry = () => this.entries[this.entries.length - 1];

  logLast = () => {
    Object.entries(this.lastEntry()).forEach(([key, value]) => {
      console.log(`%c${key.toUpperCase()}:`, "background-color: #DDD; color: black", value);
    });
  };
}

/** Tracks a whole group of forms by name */
class FormsTracker {
  public forms: FormTracker[] = [];

  names = () => this.forms.map(form => form.name);

  byIndex = (index: number) => {
    if (index < 0) {
      return this.forms[this.forms.length + index];
    } else {
      return this.forms[index];
    }
  };

  byName = (name: string) => {
    // Put the named history object to the end of the array if it's there
    // and if it isn't, add it.
    const index = this.forms.findIndex(form => form.name === name);

    const history = index === -1 ? new FormTracker(name) : this.forms.splice(index, 1)[0];
    this.forms.push(history);
    return history;
  };

  append = (name: string, args: FormikProps<unknown>) => this.byName(name).push(args);

  last = () => this.byIndex(-1);
  lastEntry = () => this.last().lastEntry();
}

const tracker = new FormsTracker();

const CustomFormikContext = createContext<{
  validateOnSubmit: boolean;
  enableReinitialize: boolean;
}>({
  validateOnSubmit: false,
  enableReinitialize: false,
});
export const useIsInValidateOnSubmitForm = () => !!useContext(CustomFormikContext).validateOnSubmit;
export const useCustomFormikContext = () => useContext(CustomFormikContext);

/** Recurses through an object, (non-mutatively) turning each non-object value
 * into a boolean representing whether it is truthy. */
const recursivelyReduceToBooleanObject = (obj: Record<string, unknown>): object => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === "object") {
      return { ...acc, [key]: recursivelyReduceToBooleanObject(value as Record<string, unknown>) };
    } else {
      return { ...acc, [key]: !!value };
    }
  }, {});
};

/**
 * Wrapper for Formik which sets default validation logic and _allows for easier debugging._
 * The debugging functionality attaches form state to a global object, `window.forms`.
 *
 * This object then continuously tracks the state of all forms on the page (and other pages), and allows you to access
 * forms by name, or by index. (The last rendered form is always the last in the FormHistory array.)
 *
 * This form tracking doesn't log to console and only happens on "development", so it's safe to keep a debugName prop passed.
 *
 * Example usage (in the browser console):
 *
 * forms.names() // => ["form1", "form2", "form3"]
 * forms.get("form3").lastEntry() // => { values: { name: "John", age: 30 }, errors: { name: "Name is required" }, isValid: false, when: "2020-01-01T00:00:00.000Z" }
 * forms.last().entries // => [{ values: { name: "John", age: 30 }, errors: { name: "Name is required" }, isValid: false, when: "2020-01-01T00:00:00.000Z" }]
 * forms.last().lastEntry().values // => { name: "John", age: 30 }
 * forms.lastEntry().values // Shorthand for the above
 * forms.last().logLast() // =>
 * // VALUES: { name: "", age: 30 }
 * // ERRORS: { name: "Name is required" }
 * // ISVALID: false
 * // WHEN: 2020-01-01T00:00:00.000Z
 *
 * You can set a particular FormHistory to log each change by setting `logEachChange` to true.
 * forms.last().logEachChange = true;
 **/
export const Formik = <T extends FormikValues>({
  validateOnBlur = false,
  validateOnMount = false,
  validateOnChange = false,
  validateOnSubmit = true,
  debugName,
  children,
  validationSchema,
  ...rest
}: FormikConfig<T> & {
  /** If passed, form state will be tracked in window under this key. See component docs for more details. */
  debugName?: string;
  /**
   * If passed, the form will consider itself valid until attempted submission, at which point it will validate
   * and then show errors. Sub-fields can detect whether this prop is present (from the custom context), and handle
   * validation logic accordingly. See FormikTextField for an example: It doesn't validate until submission, and then
   * only validates fields that have errors, clearing the errors (and stopping validation) as they are fixed.
   */
  validateOnSubmit?: boolean;
  /** Children only allowed in this passed props format, to allow for custom form props */
  children: (props: FormikProps<T>) => React.ReactNode;
}) => {
  const context = useMemo(
    () => ({ validateOnSubmit: !!validateOnSubmit, enableReinitialize: !!rest.enableReinitialize }),
    [validateOnSubmit, rest.enableReinitialize]
  );

  return (
    <CustomFormikContext.Provider value={context}>
      <FormikCore<T>
        validateOnBlur={validateOnBlur}
        validateOnMount={validateOnMount}
        validateOnChange={validateOnChange}
        validationSchema={validationSchema}
        {...rest}
      >
        {args => {
          if (debugName && ["development", "test"].includes(process.env.NODE_ENV) && window) {
            tracker.append(debugName, args);
            (window as unknown as { forms: FormsTracker }).forms = tracker;
          }

          const finalArgs = {
            ...args,
            handleSubmit: (e: React.FormEvent<HTMLFormElement>) => {
              e?.preventDefault?.();
              args.submitForm();
            },
          };

          if (validateOnSubmit) {
            if (validateOnBlur || validateOnChange) {
              console.warn(`Overwriting other validations with validateOnSubmit`);
            }

            finalArgs.validateOnBlur = false;
            finalArgs.validateOnChange = false;
            finalArgs.handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
              e?.preventDefault?.();
              try {
                const errors = await args.validateForm();
                const isValid = Object.keys(errors).length === 0;

                if (isValid) {
                  args.submitForm();
                } else {
                  const newTouched = recursivelyReduceToBooleanObject(errors);
                  args.setTouched(newTouched);
                  args.setErrors(errors);
                }
              } catch (err) {
                console.error(err);
              }
            };
          }

          return children(finalArgs);
        }}
      </FormikCore>
    </CustomFormikContext.Provider>
  );
};

const getFirstStringValue = (object: Record<string, unknown>) => {
  const key = Object.keys(object)[0];
  return typeof object[key] === "object"
    ? getFirstStringValue(object[key] as Record<string, unknown>)
    : (object[key] as string);
};

/** Gets first error message provided form has been touched */
export const getFirstTouchedErrorMessage = ({
  errors,
  touched,
}: {
  errors: FormikErrors<unknown>;
  touched: FormikTouched<unknown>;
}) => {
  try {
    const isTouched = Object.keys(touched).length > 0;
    if (!isTouched) return undefined;

    return getFirstStringValue(errors);
  } catch (err) {
    console.error("Error getting first touched error message", err);
    return undefined;
  }
};
