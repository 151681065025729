import React from "react";

import { EmailChipInput } from "@unchained/component-library";
import { useField } from "formik";

import { setTouched } from "./utils";

export const FormikEmailChipInput = ({ name, className }: { name: string; className?: string }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <EmailChipInput
      {...field}
      className={className}
      values={field.value}
      onChange={values => {
        helpers.setValue(values);
        setTouched(helpers, true);
      }}
    />
  );
};
