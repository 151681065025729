import { ReactNode } from "react";

import { ButtonProps } from "@unchained/component-library";

import {
  StepColumn,
  StepDescription,
  StepTitle,
  StepTop,
} from "Components/Shared/Layouts/FullPageWizard";
import { useLoadingContext } from "Contexts/LoadingContext";

/** This component simplifies the creation of a typical onboarding step with a single continuation action,
 * title, description, and optional loading state. */
export const ManifestStep = ({
  children,
  title,
  description,
  actions,
  width,
  className,
  onBack,
}: {
  children: ReactNode;
  title: string;
  description?: ReactNode;
  actions?: [ButtonProps] | [ButtonProps, ButtonProps];
  width?: string;
  className?: string;
  onBack?: () => void;
}) => {
  const loading = useLoadingContext();

  return (
    <StepColumn
      width={width}
      loading={loading.value}
      actions={actions}
      className={className}
      onBack={onBack}
    >
      <StepTop>
        <StepTitle>{title}</StepTitle>
        {description && <StepDescription>{description}</StepDescription>}
      </StepTop>
      {children}
    </StepColumn>
  );
};
