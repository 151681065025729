import React from "react";

import type { LEDGER } from "@caravan/wallets";
import { KEYSTORES } from "@caravan/wallets";

import { SupportEmailLink } from "Components/Shared/Elements/Links";

import { KEYSTORE } from "../types";
import {
  ChooseKeyStep,
  ChooseDeviceStep,
  ConnectLedgerOverviewStep,
  UnregisteredLedgerStep,
  SuccessStep,
  TrezorConnectionOverviewStep,
} from "./steps";
import { ConfirmOnColdcardOverviewStep } from "./steps/ConfirmOnColdcardOverviewStep";
import { ConfirmOnLedgerStep } from "./steps/ConfirmOnLedgerStep";
import { ConfirmOnTrezorStep } from "./steps/ConfirmOnTrezorStep";

export interface Step {
  hideTitle?: boolean;
  subtitle?: string | React.ReactNode;
  Component: () => React.ReactElement;
  modalSize?: "sm" | "md" | "lg";
  hideOnBack?: boolean;
  // An optional callback to call when the back nav is clicked
  // useful for times when you need to skip a previous step.
  // It takes as arguments the current index and the callback to set the step index.
  handleBack?: (index: number, cb: (index: number) => void) => void;
}

const manifestBase: Step[] = [
  {
    subtitle: "Which key are you using to confirm the safety of this address?",
    Component: () => <ChooseKeyStep />,
    modalSize: "sm",
  },
  {
    subtitle: "Choose the hardware device for this key.",
    Component: () => <ChooseDeviceStep />,
    modalSize: "md",
  },
];

const confirmOnLedgerStep: Step = {
  subtitle: (
    <>
      <span className="inline-block">
        Verify on your device that the address matches the one shown below. Select{" "}
        <strong>Approve</strong> on your device to confirm.{" "}
      </span>
      <span className="mt-3 inline-block">
        In the event of a mismatch, <strong>do not proceed</strong>; contact <SupportEmailLink />.
      </span>
    </>
  ),
  Component: () => <ConfirmOnLedgerStep />,
  modalSize: "lg",
};

const ledgerManifest: Step[] = [
  {
    subtitle: "Get the device ready.",
    Component: () => <ConnectLedgerOverviewStep />,
    modalSize: "sm",
  },
  confirmOnLedgerStep,
];

const unregisteredLedgerManifest: Step[] = [
  {
    subtitle: "First, register your Ledger with this wallet.",
    Component: () => <ConnectLedgerOverviewStep />,
  },
  {
    subtitle: (
      <span>
        Complete registering this device by ensuring the info shown on your device matches that
        shown in the table below. Press <strong>Approve</strong> on your device for each item. Once
        all information is confirmed on the device, proceed to the next step where you can confirm
        the address.
      </span>
    ),
    Component: () => <UnregisteredLedgerStep />,
    modalSize: "lg",
  },
  {
    ...confirmOnLedgerStep,
    // if in the unregistered flow, if the user has already registered successfully
    // a back call should go back to the device type chooser
    handleBack: (i, cb) => {
      cb(i - 3);
    },
  },
];

const trezorManifest: Step[] = [
  {
    subtitle: "Get the device ready.",
    Component: () => <TrezorConnectionOverviewStep />,
  },
  {
    subtitle: (
      <>
        <span>
          Verify on your device that the address matches the one shown below. If the addresses
          match, press <strong>Confirm</strong> (Trezor One) or the green checkmark (Trezor Model T)
          on your device.{" "}
        </span>
        <span className="mt-5">
          In the event of a mismatch, <strong>do not proceed</strong>; contact <SupportEmailLink />.
        </span>
      </>
    ),
    Component: () => <ConfirmOnTrezorStep />,
    modalSize: "lg",
  },
];

const coldcardManifest: Step[] = [
  {
    subtitle: "Follow these instructions on your Coldcard.",
    Component: () => <ConfirmOnColdcardOverviewStep />,
    modalSize: "lg",
  },
];

const finalSteps: Step[] = [
  {
    hideTitle: true,
    Component: () => <SuccessStep />,
    hideOnBack: true,
  },
];

export type ManifestTypes = KEYSTORE | `unregistered${typeof LEDGER}`;

const manifestMap = {
  [KEYSTORES.LEDGER]: [...manifestBase, ...ledgerManifest],
  [`unregistered${KEYSTORES.LEDGER}`]: [...manifestBase, ...unregisteredLedgerManifest],
  [KEYSTORES.TREZOR]: [...manifestBase, ...trezorManifest],
  [KEYSTORES.COLDCARD]: [...manifestBase, ...coldcardManifest],
};

export const getSteps = (keystore: ManifestTypes): Step[] => {
  const manifest = keystore ? manifestMap[keystore] : manifestBase;
  return [...manifest, ...finalSteps];
};
