import { IraOrg } from "Specs/v1/getIraOrg/200";
import {
  CompleteOrg,
  CompleteOrgAdvancedProfileStateEnum,
  CompleteOrgStateEnum,
} from "Specs/v1/getOrg/200";

export enum OnboardingGoal {
  BASIC = "basic",
  INDIVIDUAL = "individual",
  BUSINESS = "business",
  IRA = "ira",
  TRUST = "trust",
}

const combinedStates = [
  "terminal",
  "editable",
  "pendingApproval",
  "resubmittable",
  "denied",
  "approved",
  "submitted",
] as const;
type CombinedStateKey = (typeof combinedStates)[number];

const basicStateMap: Record<CombinedStateKey, CompleteOrgStateEnum[]> = {
  terminal: ["live", "denied"],
  editable: ["pending_basic_info", "pending_resubmission"],
  pendingApproval: ["pending_approval"],
  resubmittable: ["pending_resubmission"],
  denied: ["denied"],
  approved: ["live", "pending_payment"],
  submitted: ["pending_resubmission", "pending_approval", "live", "denied"],
};

const advancedStateMap: Record<CombinedStateKey, CompleteOrgAdvancedProfileStateEnum[]> = {
  terminal: ["approved", "denied", "ineligible"],
  editable: ["pending_submission", "pending_resubmission"],
  pendingApproval: ["pending_approval"],
  resubmittable: ["pending_resubmission"],
  denied: ["denied"],
  approved: ["approved"],
  submitted: ["pending_resubmission", "pending_approval", "approved", "denied"],
};

const stateMap = {
  state: basicStateMap,
  advancedState: advancedStateMap,
} as Record<
  "advancedState" | "state",
  Record<CombinedStateKey, (CompleteOrgAdvancedProfileStateEnum | CompleteOrgStateEnum)[]>
>;

export const getRelevantState = (org: CompleteOrg | IraOrg, onboardingGoal: OnboardingGoal) => {
  const relevantStateKey = [
    OnboardingGoal.INDIVIDUAL,
    OnboardingGoal.BUSINESS,
    OnboardingGoal.TRUST,
  ].includes(onboardingGoal)
    ? "advancedState"
    : "state";

  const [state, advancedState] = [
    org.state,
    "iraPlan" in org ? org.advancedProfileState : org.advanced_profile_state,
  ];

  const relevantState = { advancedState, state }[relevantStateKey];
  return { relevantState, relevantStateKey };
};
/**
 * A light org wrapper useful for determining practical org state
 * by combining state and advanced state into context-relevant states.
 */
export const simplifiedOrgStates = (org: CompleteOrg | IraOrg, onboardingGoal: OnboardingGoal) => {
  if (onboardingGoal === OnboardingGoal.BASIC) {
    // For basic onboarding, adding custom logic to combine both advanced and basic states
    // TBD if we actually need this. Suposedlly effective will either approval/deny/request_resubmission
    // on the whole profile, so we might be able to just use basic profile state.
    // Only manual approve/deny/resubmit from admin will cause divergence between basic and advanced states.
    let personalOrg = org as CompleteOrg;
    const stateSet = {
      resubmittable:
        org.state === "pending_resubmission" ||
        personalOrg.advanced_profile_state === "pending_resubmission",
      denied: org.state === "denied",
      approved:
        ["live", "pending_payment", "pending_payment_renewal"].includes(org.state) &&
        ["approved", "pending_submission"].includes(personalOrg.advanced_profile_state),
    } as Record<CombinedStateKey, boolean>;

    stateSet.pendingApproval =
      !stateSet.resubmittable &&
      (org.state === "pending_approval" ||
        personalOrg.advanced_profile_state === "pending_approval");
    stateSet.terminal = stateSet.denied || stateSet.approved;
    stateSet.submitted = stateSet.resubmittable || stateSet.pendingApproval || stateSet.approved;
    stateSet.editable =
      stateSet.resubmittable ||
      (org.state === "pending_basic_info" &&
        personalOrg.advanced_profile_state === "pending_submission");
    return stateSet;
  }

  const { relevantState, relevantStateKey } = getRelevantState(org, onboardingGoal);

  const stateSet = combinedStates.reduce(
    (obj, s) => ({ ...obj, [s]: stateMap[relevantStateKey][s].includes(relevantState) }),
    {}
  ) as Record<CombinedStateKey, boolean>;

  return stateSet;
};
