import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./Success.module.scss";

export const Success = ({ message, size, successText, successTextClasses }) => {
  return (
    <div
      className={classnames({
        [styles.lgSuccess]: size === "lg",
        [styles.mdSuccess]: size === "md",
        [styles.smSuccess]: size === "sm",
      })}
    >
      <div
        className={classnames({
          [styles.lgCircle]: size === "lg",
          [styles.mdCircle]: size === "md",
          [styles.smCircle]: size === "sm",
        })}
      >
        <div
          className={classnames({
            [styles.lgBackground]: size === "lg",
            [styles.mdBackground]: size === "md",
            [styles.smBackground]: size === "sm",
          })}
        />
        <div
          className={classnames({
            [styles.lgCheckmark]: size === "lg",
            [styles.mdCheckmark]: size === "md",
            [styles.smCheckmark]: size === "sm",
            [styles.lgDraw]: size === "lg",
            [styles.mdDraw]: size === "md",
            [styles.smDraw]: size === "sm",
          })}
        />
      </div>
      <h2
        className={classnames(successTextClasses, "mt-4", {
          "text-d-md": size === "lg",
          "text-d-sm": size === "md",
          "text-d-xs": size === "sm",
        })}
      >
        {successText}
      </h2>
      <p
        className={classnames({
          "mt-12 text-lg": size === "lg",
          "mt-4 text-sm": size === "md",
          "mt-4 text-xs": size === "sm",
        })}
      >
        {message}
      </p>
    </div>
  );
};

Success.propTypes = {
  message: PropTypes.string,
  size: PropTypes.string,
  successTextClasses: PropTypes.string,
};
Success.defaultProps = {
  size: "lg",
  successText: "Success!",
};
