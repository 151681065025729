import { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { reportExtra, reportPath, reportRequest } from "Reducers/clientReportReducer";
import { withAccount } from "Shared/api";

import { openClientReportModal } from "./ClientReportModal";

/**
 * The precise "konomi code" string a user must type while this component is rendered
 * in order to trigger the modal.
 **/
const KONOMI = "REPORT";

export const useSetClientReportExtra = (name: string, value: unknown, deps: unknown[] = []) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reportExtra({ name, value }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

const _ClientReporter = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [konomiCode, setKonomiCode] = useState<string[]>([]);

  // Track axios request metadata
  useEffect(() => {
    let interceptor = axios.interceptors?.response?.use(
      response => {
        const { status, config } = response;
        const { method, url } = config;

        dispatch(reportRequest({ path: url, method, code: status }));
        return response;
      },
      error => {
        const { status, config } = error.response;
        const { method, url } = config;
        dispatch(reportRequest({ path: url, method, code: status }));
        return Promise.reject(error);
      }
    );

    () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      dispatch(reportPath(location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const openModalOnKonomiCode = useCallback(
    (e: KeyboardEvent) => {
      const constructedCode = [...konomiCode, e.key].join("");
      const isWorkingTowardsKonomi = KONOMI.includes(constructedCode);
      // They've hit it! Open the modal
      if (constructedCode === KONOMI) {
        setKonomiCode([]);
        openClientReportModal();
        // They're typing it! Let them continue
      } else if (isWorkingTowardsKonomi) {
        setKonomiCode([...konomiCode, e.key]);
        // They've typed something else, reset
      } else {
        setKonomiCode([]);
      }
    },
    [konomiCode]
  );

  // Listen for keydown events
  // If the user types the "konomi code" ("REPORT", all caps), open the client report modal
  useEffect(() => {
    window.addEventListener("keydown", openModalOnKonomiCode);
  }, [openModalOnKonomiCode]);

  // Cleanup
  useEffect(
    () => () => {
      window.removeEventListener("keydown", openModalOnKonomiCode);
    },
    [openModalOnKonomiCode]
  );

  return <>{children}</>;
};

export const ClientReporter = withAccount(_ClientReporter);
