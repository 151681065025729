import moment from "moment";

import {
  RESET_NEW_LOAN_FLOW,
  SET_NEW_LOAN_ACH_TYPE,
  SET_NEW_LOAN_ACTIVE_MILITARY,
  SET_NEW_LOAN_COLLATERAL,
  SET_NEW_LOAN_DISBURSEMENT_ACCOUNT,
  SET_NEW_LOAN_INTEREST_ACCOUNT,
  SET_NEW_LOAN_ORG,
  SET_NEW_LOAN_PRINCIPAL,
  SET_NEW_LOAN_PURPOSE,
  SET_NEW_LOAN_STARTS_ON,
  SET_NEW_LOAN_TERM_BILLING_PERIODS,
  SET_NEW_LOAN_USER_KEY,
} from "Actions/loanActions/loanNewWizardActions";
import { CENTRAL_TIME_ZONE } from "Utils/time";

const initialState = {
  loanInterestAccountUuid: "",
  loanInterestAccountName: "",
  loanDisbursementAccountUuid: "",
  loanDisbursementAccountName: "",
  loanCurrency: "BTC",
  loanCollateral: "",
  loanPrincipal: "",
  loanStartsOn: moment.tz(CENTRAL_TIME_ZONE).utc(true).add(2, "days"),
  loanTermBillingPeriods: 12,
  loanPurpose: "",
  loanActiveMilitary: false,
  loanCustodyModel: "MULTI_INST",
  userKey: {
    name: "",
    uuid: "",
    username: "",
    role: "cosigner",
  },
  org: {
    uuid: "",
    name: "",
    state: "",
    advanced_profile_state: "",
  },
};

export const loanNewWizardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NEW_LOAN_ACH_TYPE: {
      return {
        ...state,
        achType: payload,
      };
    }

    case SET_NEW_LOAN_INTEREST_ACCOUNT: {
      const { uuid, name, account_number } = payload;

      const lastFourDigits = account_number?.slice(-4);

      return {
        ...state,
        loanInterestAccountUuid: uuid,
        loanInterestAccountName: `${name} (ending ${lastFourDigits})`,
      };
    }

    case SET_NEW_LOAN_DISBURSEMENT_ACCOUNT: {
      const { uuid, name, account_number } = payload;

      const lastFourDigits = account_number?.slice(-4);

      return {
        ...state,
        loanDisbursementAccountUuid: uuid,
        loanDisbursementAccountName: `${name} (ending ${lastFourDigits})`,
      };
    }

    case SET_NEW_LOAN_COLLATERAL: {
      return {
        ...state,
        loanCollateral: payload,
      };
    }

    case SET_NEW_LOAN_PRINCIPAL: {
      return {
        ...state,
        loanPrincipal: payload,
      };
    }

    case SET_NEW_LOAN_STARTS_ON: {
      return {
        ...state,
        loanStartsOn: payload,
      };
    }

    case SET_NEW_LOAN_TERM_BILLING_PERIODS: {
      return {
        ...state,
        loanTermBillingPeriods: payload,
      };
    }

    case SET_NEW_LOAN_PURPOSE: {
      return {
        ...state,
        loanPurpose: payload,
      };
    }

    case SET_NEW_LOAN_ACTIVE_MILITARY: {
      return {
        ...state,
        loanActiveMilitary: payload,
      };
    }

    case SET_NEW_LOAN_USER_KEY: {
      return {
        ...state,
        userKey: {
          ...state.userKey,
          ...payload,
        },
      };
    }

    case SET_NEW_LOAN_ORG: {
      return {
        ...state,
        org: payload,
      };
    }

    case RESET_NEW_LOAN_FLOW: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
