import { HtmlHTMLAttributes, ReactNode, useRef } from "react";

import { Transition } from "react-transition-group";

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

// Custom transition for AccountSwitcher opening upward
export const FadeUp = (props: {
  children: ReactNode;
  in: boolean;
  className?: string;
  style?: HtmlHTMLAttributes<HTMLDivElement>["style"];
}) => {
  const nodeRef = useRef(null);
  return (
    <Transition nodeRef={nodeRef} in={props.in} timeout={duration}>
      {state => (
        <div
          ref={nodeRef}
          className={props.className}
          style={{
            ...defaultStyle,
            ...props.style,
            ...transitionStyles[state],
          }}
        >
          {props.children}
        </div>
      )}
    </Transition>
  );
};
