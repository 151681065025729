import { CountryCode, CountryName, CountryTriplet, countryRegionData } from "./data";

/**
 * This class is only ever accessed through the singleton exported at the bottom of the file.
 * It processes the country/state data we have (once per build), and provides methods to efficiently access it.
 */
class CountryStateData {
  _data: {
    name: string;
    code: CountryCode;
    regions: { name: string; code: string }[];
  }[] = [];

  _countryOptions: { value: string; label: string }[] = [];

  _countryIndexMap: Record<string, number> = {};

  // Do all the calculation during construction of the singleton, to speed up look up etc
  constructor() {
    countryRegionData.forEach((triplet, index) => {
      const [name, code, regionString] = triplet;
      // Store the indices under both code and country, for faster lookup
      this._countryIndexMap[code] = index;
      this._countryIndexMap[name] = index;

      const country = {
        code,
        name: name,
        regions: regionString
          .split("|")
          .map(region => ({ name: region.split("~")[0], code: region.split("~")[1] })),
      };

      this._countryOptions.push({ value: code, label: name });

      this._data.push(country);
    });
  }

  countryOptions(priorityCountryList: CountryCode[] = []) {
    if (!priorityCountryList.length) return this._countryOptions;

    const priorities = priorityCountryList.map((code: CountryCode) => {
      const country = this.getCountry(code);
      return {
        value: code,
        label: country?.name,
        priority: true,
      };
    });

    return [...(Object.values(priorities) as object[]), ...this._countryOptions] as {
      value: string;
      label: string;
      priority?: boolean;
    }[];
  }

  regionOptions(countryCodeOrName: string) {
    return this.getRegions(countryCodeOrName).map(region => ({
      value: region.code,
      label: region.name,
    }));
  }

  getCountry(codeOrName: string) {
    const index = this._countryIndexMap[codeOrName];
    if (index === undefined) return undefined;
    return this._data[index];
  }

  getRegions(countryCode: string) {
    const country = this.getCountry(countryCode);
    return country?.regions || [];
  }

  getRegion(countryCodeOrName: string, regionCodeOrName: string) {
    return this.getRegions(countryCodeOrName)?.find(
      region => region.code === regionCodeOrName || region.name === regionCodeOrName
    );
  }
}

export const countryStateData = new CountryStateData();
export type { CountryCode, CountryName, CountryTriplet };
