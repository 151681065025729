/* global UNCHAINED_LOG GA_IGNORE_NEXT_PAGEVIEW  TREFOIL_USER trackEvent ga */
import "Assets/js/tracking";
import { trackPageview } from "Utils/tracking";

function trackLocationChange(action) {
  if (action.payload) {
    var path = action.payload.pathname;
    if (action.search && action.search !== "") {
      path += "?" + action.search;
    }
    trackPageview(path);
  }
}

function actionShouldBeTrackedAsGAEvent(action) {
  return false;
  // if (action.type == null || typeof(action.type) == "undefined") { return false; }
  // // Logins, signups, & password resets
  // if (action.type.includes("SET/PASSWORD"))         { return false; }
  // // Password changes
  // if (action.type.includes("SET/CURRENT_PASSWORD")) { return false; }
  // if (action.type.includes("SET/NEW_PASSWORD"))     { return false; }
  // return true;
}

export default store => next => action => {
  if (action) {
    if (action.type === "@@router/LOCATION_CHANGE") {
      trackLocationChange(action);
    }
    if (actionShouldBeTrackedAsGAEvent(action)) {
      trackEvent({ category: "redux", action: action.type });
    }
  }
  var result = next(action);
  return result;
};
