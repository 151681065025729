import React, { useState } from "react";

import { FileCopyOutlined } from "@mui/icons-material";
import cx from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";

import styles from "./CopyablePopup.module.scss";

interface CopyablePopup {
  copyText: string;
  onCopiedDisplayText?: string;
  className?: string;
  containerClassName?: string;
}

const CopyablePopup = ({
  copyText,
  onCopiedDisplayText = "Copied",
  className,
  containerClassName = styles.container,
}: CopyablePopup) => {
  const [showClipboard, setShowClipboard] = useState("HIDE");

  /** Run fade in animation for 2 seconds then fade out. */
  const onCopy = () => {
    setShowClipboard("FADE_IN");

    setTimeout(() => {
      setShowClipboard("FADE_OUT");
    }, 2000);
  };

  return (
    <div className={containerClassName}>
      <CopyToClipboard
        data-testid="copyToClipBoard"
        text={copyText}
        onCopy={onCopy}
        options={{ format: "text/plain" }}
      >
        <FileCopyOutlined classes={{ root: styles.icon }} fontSize="inherit" color="primary" />
      </CopyToClipboard>
      <span
        className={cx(className, styles.badge, {
          [styles.badge_hide]: !["FADE_IN", "FADE_OUT"].includes(showClipboard),
          [styles.badge_fadeIn]: showClipboard === "FADE_IN",
          [styles.badge_fadeOut]: showClipboard === "FADE_OUT",
        })}
      >
        {onCopiedDisplayText}
      </span>
    </div>
  );
};

export { CopyablePopup };
