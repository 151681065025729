import React, { FC, ReactNode } from "react";

import { HeroIconSize } from "@unchained/component-library/types/src/stories/Components/HeroIcon/HeroIcon";

import { ClientErrorAPI } from "Shared/api/clientErrorAPI";
import { TREFOIL_CONFIG_ERROR } from "Utils/config";

import { ErrorPage } from "./ErrorPage";

type ErrorBoundaryProps = {
  /* The size of the icon to display */
  size?: HeroIconSize;
  children: React.ReactNode;
  /* The error message to display to the user. */
  message?: ReactNode;
  /* The name of the component that errored */
  boundaryName?: string;
  /* A custom class to apply to the default wrapper div */
  classes?: {
    wrapper?: string;
    icon?: string;
    message?: string;
  };
  /*
   * A custom component to render instead of the default error message.
   * If provided, all above styling/message props will be ignored.
   * */
  CustomErrorComponent?: FC<{ error: Error }>;
};

type ErrorBoundaryState = { error: Error };

/**
 * A default error boundary for catching uncaught errors, reporting them, and rendering a helpful message
 * in the UI context in which the error occurred.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  reportError = (error, incomingInfo, tries = 0) => {
    const { boundaryName } = this.props;

    const maxTries = 3;
    if (tries >= maxTries) return;

    let info;
    if (typeof incomingInfo === "object") {
      info = { ...incomingInfo, boundaryName };
    } else {
      info = { boundaryName, message: incomingInfo };
    }

    try {
      ClientErrorAPI.reportRenderError(error, info);
    } catch (e) {
      console.error("Failed to report error to server:");
      console.error({ error, info });
      console.error(e);
      setTimeout(() => {
        this.reportError(error, info, tries + 1);
      }, 500);
    }
  };

  static getDerivedStateFromError(error) {
    // If the whole site's down, let the error bubble up to the top level
    if (error.message === TREFOIL_CONFIG_ERROR) return {};

    return { error };
  }

  componentDidCatch(error, info) {
    // If we dont have a config object, we also wont have a CSRF token
    // so we should not try to report this error upstream.
    if (error.message === TREFOIL_CONFIG_ERROR) return;

    this.reportError(error, info);
  }

  render() {
    const { children, message, size, CustomErrorComponent, classes = {} } = this.props;
    const { error } = this.state;

    if (!error) return children;

    if (CustomErrorComponent) return <CustomErrorComponent error={error} />;

    return <ErrorPage message={message} size={size} classes={classes} />;
  }
}

/**
 *
 * @param Component - The component to render within the error boundary
 * @param boundaryProps - Any props other than children (which is the rendered Component) to pass to the ErrorBoundary.
 */
export const withErrorBoundary =
  <PropsType extends object>(
    Component: FC<PropsType> | ((props: PropsType) => JSX.Element),
    boundaryProps?: Omit<ErrorBoundaryProps, "children">
  ) =>
  (props: PropsType) =>
    (
      <ErrorBoundary {...boundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    );
