import React from "react";

import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

export const SignDocIcon = (
  { viewBox = "0 0 24 24", htmlColor = "black", classes = {} },
  props
) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={{ ...classes }}>
    <path d="M 6 2 C 4.9045455 2 4 2.9045455 4 4 L 4 20 C 4 21.095455 4.9045455 22 6 22 L 18 22 C 19.095455 22 20 21.095455 20 20 L 20 4 C 20 2.9069372 19.093063 2 18 2 L 6 2 z M 6 4 L 18 4 L 18 20 L 6 20 L 6 4 z M 8 6 L 8 8 L 16 8 L 16 6 L 8 6 z M 10.457031 10 C 9.555872 10 8.8353496 10.581606 8.4746094 11.183594 C 8.1138692 11.785581 7.9788513 12.426375 8 13.035156 C 8.0315548 13.937014 8.3909011 14.773725 8.8496094 15.542969 C 8.5533553 15.681275 8.2274858 16 8 16 L 8 18 C 8.8362652 18 9.5702614 17.665329 10.246094 17.226562 C 10.782541 17.664229 11.380746 18 12.125 18 C 13.150091 18 13.826361 17.424098 14.304688 16.910156 C 14.376027 17.056606 14.365388 17.196312 14.486328 17.337891 C 14.823862 17.733014 15.410511 18 16 18 L 16 16.013672 L 16 15.005859 A 1.0001 1.0001 0 0 0 15 14 C 14.400298 14 13.964717 14.337118 13.695312 14.607422 C 13.425909 14.877726 13.240038 15.142394 13.056641 15.365234 C 12.689845 15.810915 12.495359 16 12.125 16 C 12.089313 16 11.901343 15.808134 11.818359 15.771484 C 12.471831 14.954615 12.980701 14.042364 13 13.019531 A 1.0001 1.0001 0 0 0 13 13.017578 C 13.011091 12.408456 12.844211 11.796972 12.470703 11.201172 C 12.097195 10.605372 11.389107 10 10.457031 10 z M 10.457031 12 C 10.563955 12 10.633899 12.03797 10.775391 12.263672 C 10.916882 12.489372 11.001909 12.877591 11 12.982422 C 10.9951 13.223409 10.648588 13.655694 10.443359 14.041016 C 10.283529 13.659828 10.010524 13.265638 10 12.964844 C 9.9931487 12.767625 10.071693 12.407449 10.189453 12.210938 C 10.307213 12.014424 10.330191 12 10.457031 12 z" />
  </SvgIcon>
);

SignDocIcon.propTypes = {
  classNames: PropTypes.string,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
