import { useState } from "react";

import { CompleteOrg } from "@unchained/api-specs/trefoil/v1/getOrg/200";
import {
  Banner,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import { AxiosError } from "axios";
import { useQueryClient, useMutation } from "react-query";

import { PendingChanges } from "Routes/change-proposals/[uuid]/ChangeProposal/PendingChanges";
import { orgQueryKeys } from "Shared/api";
import { ChangeProposalAPI, changePropsalTypeMap } from "Shared/api/changeProposalApi";
import { AppModalManager } from "Shared/components/Modals";
import { useEasyToasts } from "Utils/toasts";

function checkForAddingApprover(changeProposals, org: CompleteOrg) {
  const approvingRoleUuids = org.child_roles
    .filter(role => ["admins", "transaction_approvers"].includes(role.managed))
    .map(role => role.uuid);

  return changeProposals.some(cp => {
    return (
      cp.type === "OrgUserRoleChangeProposal" &&
      cp.roleUuids.some(roleUuid => approvingRoleUuids.includes(roleUuid))
    );
  });
}

export const SubmitChangesModal = ({ onClose, changeProposals, org }) => {
  const close = (submitted: boolean) => {
    onClose(submitted);
    AppModalManager.close();
  };
  const { uuid, quorum_config } = org;
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { showApiSuccessToast, showErrorToast } = useEasyToasts();

  const createChangeProposalGroup = useMutation((changes: any[]) =>
    ChangeProposalAPI.CreateChangeProposalGroup(uuid, {
      changeProposals: changes,
    })
  );

  let title = "Quorum approval required";
  let description =
    "These changes require quorum approval and won't be reflected until the necessary approvals are obtained. Do you wish to submit these changes?";

  const quorumEnabled = !!org.quorum_config && org.quorum_config?.min_approvals > 1;
  if (!quorumEnabled) {
    title = "Confirm changes";
    if (checkForAddingApprover(changeProposals, org)) {
      description =
        "Adding additional users with approval permissions to this account will enable quorums by default. This means that transactions where the Unchained key or an institutional key agent is required, and other account changes, will require approval by your designated quorum.";
    } else {
      description =
        "These changes will be executed immediately. Do you wish to submit these changes?";
    }
  }

  const submit = () => {
    setErrorMessage("");
    const successMessage = !!quorum_config
      ? "Change proposal submitted and approvers have been notified"
      : "Changes executed successfully";
    createChangeProposalGroup.mutate(changeProposals, {
      onSuccess: () => {
        showApiSuccessToast({
          message: successMessage,
        });
        queryClient.invalidateQueries(orgQueryKeys.changeProposals(uuid));
        queryClient.invalidateQueries(orgQueryKeys.show(uuid));
        queryClient.refetchQueries(orgQueryKeys.changeProposals(uuid));
        queryClient.refetchQueries(orgQueryKeys.show(uuid));
        close(true);
      },
      onError: (err: AxiosError) => {
        if (err?.response?.data["errorCode"] === "quorum_min_approvals_threshold") {
          setErrorMessage(
            "Your changes would reduce the number of approving members below the quorum's minimum approvals. Please adjust your changes and try again."
          );
        } else {
          showErrorToast(err);
        }
      },
    });
  };

  const displayCps = changeProposals.map((changeProposal, i) => {
    const type = changePropsalTypeMap[changeProposal.type];

    return <PendingChanges key={i} changeProposal={{ ...changeProposal, type }} org={org} />;
  });

  return (
    <Modal maxWidth="md" className="w-[750px]" onDismiss={() => close(false)}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <div>{displayCps}</div>
        <p>{description}</p>
        {errorMessage && (
          <Banner className="mt-4" type="warning">
            {errorMessage}
          </Banner>
        )}
      </ModalContent>
      <ModalFooter>
        <Button onClick={submit} disabled={!!errorMessage}>
          Submit
        </Button>
        <Button type="text" onClick={() => close(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
