import React from "react";

export const htmlEntity = {
  bullet: <>&#8226;</>,
  check: <>&#10003;</>,
  cross: <>&#10005;</>,
  ellipsis: <>&#8230;</>,
  info: <>&#8505;</>,
  space: <>&#160;</>,
  startQuote: <>&#8220;</>,
  endQuote: <>&#8221;</>,
};
