import React, { Component } from "react";

import PropTypes from "prop-types";

import styles from "./CryptoAmountsCell.module.scss";
import { SummaryTableCell } from "./SummaryTableCell";

class SummaryCryptoAmountsBase extends Component {
  static propTypes = {
    crypto: PropTypes.string,
    fiat: PropTypes.string,
    extra: PropTypes.string,
  };

  render() {
    const { crypto, fiat, extra } = this.props;
    return (
      <SummaryTableCell>
        <div className={styles.container}>
          <span className={styles.crypto}>{crypto}</span>
          <span className={styles.fiat}>{fiat}</span>
          {extra && <span className={styles.fiat}>{extra}</span>}
        </div>
      </SummaryTableCell>
    );
  }
}

export const CryptoAmountsCell = SummaryCryptoAmountsBase;
