import $ from "jquery";

import { detableize } from "./strings";

var STATES_IN_SEQUENCE = {
  user: ["pending_basic_info", "pending_approval", "live"],
  loan: [
    "pending_approval",
    "pending_legal",
    "pending_escrow",
    "pending_deposit",
    "pending_disbursement",
    "live",
  ],
  vault: ["pending_escrow", "live"],
  bank_account: ["pending_approval", "approved"],
  contract: ["pending", "created", "finalized", "signed"],
  slice: ["pending_creation_confirmation", "live", "spent"],
  disbursement: ["pending", "completed"],
  payment: ["scheduled", "due", "paid"],
  spend: ["unsigned", "partially_signed", "fully_signed", "broadcasted", "confirmed"],
};

var STATES_TERMINAL = {
  user: ["denied", "failed", "abandoned"],
  loan: ["denied", "closed", "failed", "abandoned"],
  contract: ["declined", "failed", "abandoned"],
  slice: ["failed"],
  bank_account: ["denied"],
  disbursement: ["failed"],
  payment: ["failed", "late", "defaulted"],
  spend: ["failed"],
};

function ensureStateIsInSequence(type, state) {
  var sequenceIndex = $.inArray(state, STATES_IN_SEQUENCE[type] || []);
  if (sequenceIndex > -1) {
    return sequenceIndex;
  } else {
    console.error(`State is not part of the ${type} sequence: ${state}`);
    return false;
  }
}

function ensureState(type, state) {
  var sequenceIndex = $.inArray(state, STATES_IN_SEQUENCE[type] || []);
  var terminalIndex = $.inArray(state, STATES_TERMINAL[type] || []);
  if (sequenceIndex > -1 || terminalIndex > -1) {
    return true;
  } else {
    console.error(`State is neither in sequence nor terminal for ${type}: ${state}`);
    return false;
  }
}

export function stateSequence(type) {
  return STATES_IN_SEQUENCE[type];
}

export function stateUpTo(obj, type, state) {
  return ensureStateIsInSequence(type, obj.state) <= ensureStateIsInSequence(type, state);
}

export function stateBefore(obj, type, state) {
  return ensureStateIsInSequence(type, obj.state) < ensureStateIsInSequence(type, state);
}

export function stateAfter(obj, type, state) {
  return ensureStateIsInSequence(type, obj.state) > ensureStateIsInSequence(type, state);
}

export function stateProgress(obj, type) {
  return ensureStateIsInSequence(type, obj.state);
}

export function humanState(obj) {
  return detableize(obj.state);
}

export function stateIs() {
  var obj = arguments[0];
  if (obj) {
    for (var i = 1; i < arguments.length; i++) {
      var state = arguments[i];
      if (obj.state === state) {
        return true;
      }
    }
  }
  return false;
}
