import { useRef, useState } from "react";

/**
 * For use with the MUI Popover component.
 * Simplifies the logic for opening and closing the popover.
 *
 * Ex:
 *
 * const { triggerRef, isOpen, popoverRef, setOpen, setClosed } = usePopover();
 *
 * <Button ref={triggerRef} onClick={setOpen}>Open Menu</Button>
 * <Popover open={open} anchorEl={popoverRef} onClose={setClosed}>
 *   <Menu />
 * </Popover>
 */
export const usePopover = () => {
  const triggerRef = useRef(null);
  const [popoverRef, setPopoverRef] = useState(null);
  const isOpen = Boolean(popoverRef);

  const setOpen = () => setPopoverRef(triggerRef.current);
  const setClosed = () => setPopoverRef(null);
  return { triggerRef, popoverRef, isOpen, setOpen, setClosed };
};
