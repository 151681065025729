import React from "react";

import { CircularProgress } from "@mui/material";
import { Button, ButtonProps } from "@unchained/component-library";

type LoadableButtonProps = {
  isLoading: boolean;
} & ButtonProps;
export const LoadableButton = ({ isLoading, ...props }: LoadableButtonProps) => {
  return (
    <Button {...props}>
      {isLoading ? <CircularProgress color="primary" size="1rem" /> : props.children}
    </Button>
  );
};
