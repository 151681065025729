import { updatedField } from "Utils/errors";

var initialState = {
  bip32_node: {},
  verified: false,
  wallet_type: "",
  wallet_active: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SPEND/SET/BIP32_NODE":
      return updatedField(state, action, "bip32_node");
    case "SPEND/SET/VERIFIED":
      return updatedField(state, action, "verified");
    case "SPEND/SET/WALLET_TYPE":
      return updatedField(state, action, "wallet_type");
    case "SPEND/SET/WALLET_ACTIVE":
      return updatedField(state, action, "wallet_active");

    default:
      return state;
  }
};
