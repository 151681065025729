import { useState } from "react";

import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";

import { Invoice } from "Shared/api/v2/invoices";
import { getConfig } from "Utils/config";

const stripeStyles: Appearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    spacingGridRow: ".5rem",
  },
  rules: {
    ".Label--resting": {
      color: "#667085",
    },

    ".Tab:hover": {
      color: "var(--colorText)",
    },

    ".Tab--selected": {
      borderColor: "#E0E6EB",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
    },

    ".Input--invalid": {
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
    },
  },
};

export const createStripeElementOptions = (stripeClientSecret?: string) => {
  const stripeElementOptions: StripeElementsOptions = {
    clientSecret: stripeClientSecret || undefined,
    appearance: stripeStyles,
  };
  return stripeElementOptions;
};

// Countries we are willing to ship to.
//
// We have specifically left off the following countries
//  Iran
//  North Korea (DPRK)
//  Syria
//  Yemen
//  South Sudan
//  Congo, Democratic Republic
//  Ukraine
//  Lebanon
//  Libya
//  Zimbabwe
//  Iraq
//  Sudan
//  Belarus
//  Venezuela
//  Cuba
//  Somalia
//  Burundi
//  Central African Republic
//  Slovenia
//  Croatia
//  Bosnia and Herzegovina
//  Serbia
//  Montenegro
//  Albania
//  Macedonia
//  Greece
//  Bulgaria
//  Romania
//  Russia
export const stripeShippingCountryCodes = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AM",
  "AO",
  "AQ",
  "AR",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BB",
  "BD",
  "BE",
  "BF",
  "BH",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BZ",
  "CA",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CV",
  "CW",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HN",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MC",
  "MD",
  "MF",
  "MG",
  "ML",
  "MM",
  "MN",
  "MO",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PY",
  "QA",
  "RE",
  "RW",
  "SA",
  "SB",
  "SC",
  "SE",
  "SG",
  "SH",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SR",
  "ST",
  "SV",
  "SX",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UG",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VG",
  "VN",
  "VU",
  "WF",
  "WS",
  "YT",
  "ZA",
  "ZM",
];

export const useStripeElementProps = (clientSecret?: string) => {
  const stripeApiPublishableKey = getConfig("stripe.public")["publishable_api_key"];

  const [stripePromise] = useState(() => loadStripe(stripeApiPublishableKey));
  const stripeOptions = createStripeElementOptions(clientSecret);

  return { stripe: stripePromise, options: stripeOptions };
};
