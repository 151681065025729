import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";

import { ListInvoices200 } from "Specs/v2";

import { Invoice, InvoiceAPI, type PatchInvoice } from "../index";

const queryKeys = {
  all: ["invoices"] as const,
  list: (orgId: string, sku?: string) => [...queryKeys.all, orgId, sku],
  get: (invoiceId: string) => [...queryKeys.all, invoiceId],
} as const;

export const useInvoices = (
  orgId: string,
  sku?: string,
  paymentMethods?: boolean,
  options?: UseQueryOptions<ListInvoices200>
) =>
  useQuery<ListInvoices200>(
    queryKeys.list(orgId, sku),
    () => InvoiceAPI.ListForOrg(orgId, sku, paymentMethods),
    options
  );

export const useInvoice = (invoiceId: string, options?: UseQueryOptions<Invoice>) =>
  useQuery<Invoice>(queryKeys.get(invoiceId), () => InvoiceAPI.Get(invoiceId), options);

/** Sets a basic pattern for mutating (creating or patching) an invoice, and then updating the RQ cache. */
const useInvoiceMutation = <Variables = unknown>(
  mutationFn: MutationFunction<Invoice, Variables>,
  options?: UseMutationOptions<Invoice, unknown, Variables>
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    ...options,
    onSuccess: (invoice, variables, context) => {
      if (invoice?.orgId) queryClient.invalidateQueries(queryKeys.list(invoice.orgId));
      if (invoice?.id) queryClient.setQueryData(queryKeys.get(invoice.id), invoice);
      if (options?.onSuccess) options.onSuccess(invoice, variables, context);
    },
  });
};

export const useCreateInvoice = (orgId: string, options?: UseMutationOptions<Invoice>) =>
  useInvoiceMutation(() => InvoiceAPI.Create(orgId), options);

export const usePatchInvoice = (
  invoiceId: string,
  options?: UseMutationOptions<Invoice, unknown, PatchInvoice>
) =>
  useInvoiceMutation(
    (newInvoice: PatchInvoice) => InvoiceAPI.Patch(invoiceId, newInvoice),
    options
  );
