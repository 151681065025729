import PropTypes from "prop-types";

import { formatCurrency, formatPercentage } from "Utils/strings";

import { accountKeyListingProptypes } from "./account_key_listing_proptypes";
import { Slice } from "./slices";
import { User, userProptypes } from "./users";
import { VaultListing } from "./vault_listing";
import { VaultThresholdChange, vaultThresholdChangeProptypes } from "./vault_threshold_changes";
import { Wallet } from "./wallets";

export function vaultProptypes(admin) {
  if (admin) {
    return {
      ...vaultProptypes(),
      ...{
        user: PropTypes.shape(userProptypes()).isRequired,
        is_liquidateable: PropTypes.bool,
        is_partially_liquidateable: PropTypes.bool,
        note: PropTypes.string,
      },
    };
  } else {
    return {
      uuid: PropTypes.string,
      state: PropTypes.string,
      humanState: PropTypes.string.isRequired,
      created_at: PropTypes.string,
      product_type: PropTypes.string,
      unit: PropTypes.string.isRequired,
      sla: PropTypes.string,
      wallet_type: PropTypes.string,

      username: PropTypes.string,

      current_account_keys: PropTypes.array,

      is_refundable: PropTypes.bool,
      is_partially_refundable: PropTypes.bool,

      fee_rate: PropTypes.number,
      feePercentage: PropTypes.string,

      createdAt: PropTypes.object.isRequired,
      readableCreatedAt: PropTypes.string.isRequired,

      depositInstructions: PropTypes.object.isRequired,

      balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      confirmedBalanceWithUnit: PropTypes.string,
      confirmedBalanceWithoutUnit: PropTypes.string,
      hasBalance: PropTypes.bool.isRequired,
      current_fee: PropTypes.number,
      currentFeeWithUnit: PropTypes.string,

      is_depositable: PropTypes.bool,
      is_transferrable: PropTypes.bool,
      is_partially_transferrable: PropTypes.bool,

      statement_dates: PropTypes.array,

      transferrable_products: PropTypes.array,

      preferred_account_key_uuid: PropTypes.string,
      preferredAccountKey: PropTypes.shape(accountKeyListingProptypes()),
      backupAccountKey: PropTypes.shape(accountKeyListingProptypes()),
      protected_by_partner: PropTypes.bool,
      protected_by_owner: PropTypes.bool,

      threshold: PropTypes.number,
      thresholdWithUnit: PropTypes.string,
      current_threshold_change: PropTypes.shape(vaultThresholdChangeProptypes()),
      is_thresholdable: PropTypes.bool,
      threshold_change_required: PropTypes.bool,
    };
  }
}

export function Vault(data, prices) {
  if (data == null) {
    return data;
  }

  data = VaultListing(data, prices);

  data.feePercentage = formatPercentage(data.fee_rate);

  if (data.current_fee != null) {
    data.currentFeeWithUnit = `$${formatCurrency(data.current_fee, 2)}`;
  }

  if (data.user) {
    data.user = User(data.user);
  }

  if (data.slice) {
    data.slice = Slice(data.slice);
  }

  if (data.preferred_account_key_uuid && data.slice && data.slice.members) {
    var preferredSliceMember = data.slice.members.find(
      slice_member =>
        slice_member.strand.account_key &&
        slice_member.strand.account_key.uuid === data.preferred_account_key_uuid
    );
    if (preferredSliceMember) {
      data.preferredAccountKey = preferredSliceMember.strand.account_key;
    }
    var backupSliceMember = data.slice.members.find(
      slice_member =>
        slice_member.strand.account_key &&
        slice_member.strand.account_key.role === "client" &&
        slice_member.strand.account_key.uuid !== data.preferred_account_key_uuid
    );
    if (backupSliceMember) {
      data.backupAccountKey = backupSliceMember.account_key;
    }
  }

  if (data.wallet) {
    data.wallet = Wallet(data.wallet, data.slice);
  }

  if (data.current_account_keys) {
    // data.current_account_keys = data.current_account_keys.map((account_key) => AccountKeyListing(account_key));
    data.currentAccountKeyFor = role =>
      data.current_account_keys.find(account_key => account_key.role === role);
  }

  data.depositInstructions = {
    message: `Deposit into Unchained vault ${data.name} (ID: ${data.uuid})`,
    address: data.slice ? data.slice.address : null,
  };

  if (data.current_threshold_change) {
    data.current_threshold_change = VaultThresholdChange(data.current_threshold_change);
  }

  if (data.threshold !== null && data.threshold !== undefined) {
    // threshold can be 0 which is false
    data.hasThreshold = true;
    data.thresholdWithUnit = formatPercentage(data.threshold);
    if (data.hasBalance) {
      data.thresholdSpendAmount = data.threshold * data.deposited_balance;
      data.thresholdSpendAmountWithUnit = `${formatCurrency(data.thresholdSpendAmount, 8)} ${
        data.unit
      }`;
    }
  } else {
    data.hasThreshold = false;
    data.thresholdWithUnit = "None";
  }

  return data;
}
