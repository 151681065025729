import React from "react";

import { Checkbox, HelperText } from "@unchained/component-library";
import { useField } from "formik";

import { pascalCase } from "Utils/strings";

import { setTouched } from "./utils";

export type FormikCheckboxGroupProps = {
  name: string;
  options: string[];
  className?: string;
  showError?: boolean;
};

/**
 * A component for a group of checkboxes represented as a "multiselect" array.
 * Checking a box adds the value to the list of selected values, and unchecking removes it.
 * This assumes that the value under the formik name is stored as an array of selected values.
 */
export const FormikCheckboxGroup = ({
  name,
  options,
  className,
  showError = false,
}: FormikCheckboxGroupProps) => {
  const [field, { error, touched }, helpers] = useField(name);
  const { setValue, setError } = helpers;
  const selected = field.value;

  const hasError = showError && error && touched;

  return (
    // Role used to override default label styling from bootstrap
    <div className="flex flex-col gap-2">
      <div role="checkboxgroup" className={className}>
        {options.map(value => (
          <Checkbox
            name={`${name}${pascalCase(value)}`}
            checked={selected.includes(value)}
            label={value}
            key={value}
            onChange={e => {
              const { checked } = e.target;

              const newValues = checked ? selected.filter(s => s !== value) : [...selected, value];
              setValue(newValues);

              setError(undefined);
              setTouched(helpers, true);
            }}
          />
        ))}
      </div>
      {hasError ? <HelperText error={hasError}>{error}</HelperText> : null}
    </div>
  );
};
