import { Dropdown, DropdownProps } from "@unchained/component-library";
import cn from "classnames";
import { useField } from "formik";
import { flatten } from "lodash";

import { useIsInValidateOnSubmitForm } from "./Formik";
import { setTouched } from "./utils";

type FormikDropdownProps = Omit<
  DropdownProps & {
    name: string;
    onSelect?: DropdownProps["onSelect"];
    prepareValue?: (option: { value: unknown }) => unknown;
  },
  "error"
>;

/**
 * Binds a UCL Dropdown to a formik field.
 * Is single-select.
 */
export const FormikDropdown = ({
  name,
  options,
  onSelect,
  selectedLabels,
  prepareValue = (option: { value: unknown }) =>
    typeof option === "string" ? option : option.value,
  ...rest
}: FormikDropdownProps) => {
  const [field, meta, helpers] = useField(name);
  const opt = flatten(options).find(o => o.value === field.value);
  const validateOnSubmit = useIsInValidateOnSubmitForm();

  return (
    <Dropdown
      {...rest}
      {...field}
      options={options}
      selectedLabels={selectedLabels || (opt ? [opt.label] : [])}
      error={meta.touched ? meta.error : undefined}
      className={cn(rest.className, rest.disabled && "cursor-not-allowed")}
      onSelect={option => {
        if (onSelect) {
          onSelect(option);
          return;
        }

        const value = prepareValue(option as { value: unknown });

        helpers.setValue(value);
        setTouched(helpers, true);
        if (validateOnSubmit) helpers.setError(undefined);
      }}
    />
  );
};
