import { CreateEntity200, CreateEntityRequest, PutEntity200, PutEntityRequest } from "Specs/v1";
import { AddressRoleEnum } from "Specs/v1/getAccount/200";
import { Address } from "Specs/v1/updateAddresses/request";

import { API } from "./api";

export class EntityAPI {
  static Create = async (entity: CreateEntityRequest) =>
    (await API.Post<CreateEntity200>(`/entities`, entity)).data;

  static Put = async (uuid, entity: PutEntityRequest) =>
    (await API.Put<PutEntity200>(`/entities/${uuid}`, entity)).data;

  static UpdateAddress = async (uuid: string, address: Address & { role: AddressRoleEnum }) => {
    return (await API.Put<unknown>(`/entities/${uuid}/addresses/${address.role}`, address)).data;
  };
}
