import React, { useContext } from "react";

import { ModalContent, ModalFooter } from "@unchained/component-library";

import { SigningDeviceSelector } from "Components/SigningDevices/SigningDeviceSelector";

import { WalletRegistrationContext } from "../WalletRegistrationWizard";

export const ChooseDevice = () => {
  const { setDeviceType, setStepIndex, deviceType, stepIndex } =
    useContext(WalletRegistrationContext);

  return (
    <>
      <ModalContent>
        <div className="py-3">
          <SigningDeviceSelector
            onDeviceSelected={setDeviceType}
            size="lg"
            defaultActiveDeviceType={deviceType}
          />
        </div>
      </ModalContent>
      <ModalFooter
        actions={[
          { children: "Next", onClick: () => setStepIndex(stepIndex + 1), disabled: !deviceType },
        ]}
      />
    </>
  );
};
