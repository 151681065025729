import { useSigReqPermission } from "Permissions/hooks";
import { SigRequest } from "Specs/v1/getTransactionRequests/200";

export const FINANCE_COORDINATOR = "Finance Transaction Coordinators";
export const BTC_COORDINATOR = "Bitcoin Transaction Coordinators";

const approvedBy = (sigRequest: SigRequest, roleName: string) =>
  sigRequest.approvals.some(app => app?.role?.name === roleName);
const isFinanceApproved = (sigRequest: SigRequest) => approvedBy(sigRequest, FINANCE_COORDINATOR);
const isBtcApproved = (sigRequest: SigRequest) => approvedBy(sigRequest, BTC_COORDINATOR);

/** Returns the most salient action the current user could take on this sig request, or undefined */
export const useSigReqApprovalAction = (sigReq: SigRequest) => {
  const permish = useSigReqPermission(sigReq);
  return permish.approvalAction();
};

/** Returns array of strings representing approval types the sig request has received */
export const sigRequestApprovalStrings = (sigRequest: SigRequest) =>
  [isBtcApproved(sigRequest) && "Coordinator", isFinanceApproved(sigRequest) && "Finance"].filter(
    Boolean
  );
