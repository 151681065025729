import { REQUEST_STATUS } from "Shared/api/api";
import { VaultAPI } from "Shared/api/vaultApi";

export const SET_VAULT_INDEX_USER = "SET_VAULT_INDEX_USER";
export const RESET_VAULT_INDEX = "RESET_VAULT_INDEX";
/**
 * Set List of Vaults action for the current user
 * @param userVaults - retrieve via an api call
 */

export const setVaultsIndexUserAction = userVaults => ({
  type: SET_VAULT_INDEX_USER,
  payload: userVaults,
});

export const getVaultsIndexUserAction = orgUUID => {
  return async dispatch => {
    try {
      const vaults = await VaultAPI.ListForOrg(orgUUID);
      const userVaults = {
        vaults,
        status: REQUEST_STATUS.SUCCESS,
      };
      dispatch(setVaultsIndexUserAction(userVaults));
    } catch (e) {}
  };
};

export const resetVaultIndexAction = () => ({
  type: RESET_VAULT_INDEX,
});
