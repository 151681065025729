import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import wizStyles from "./WizardComponentWrapper.module.scss";
import styles from "./WizardComponent.module.scss";

class WizardComponentWrapperBase extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.string,
    children: PropTypes.any,
    size: PropTypes.oneOf(["sm", "md"]),
    testId: PropTypes.string,
  };

  static defaultProps = {
    size: "md",
  };

  render() {
    const { children, classes, size, testId } = this.props;

    let componentProps = {
      className: classnames(classes, wizStyles.content, {
        [styles.smContent]: size === "sm",
      }),
    };

    if (testId) {
      componentProps["data-testid"] = testId;
    }

    return <div {...componentProps}>{children}</div>;
  }
}

export const WizardComponentWrapper = WizardComponentWrapperBase;
