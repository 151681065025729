import {
  ChangeProposal,
  ChangeProposalType,
  OrgUserRoleChangeProposal,
  OrgMemberChangeProposal,
  OrgQuorumMinApprovalsChangeProposal,
  OrgQuorumApplyToAllTxsChangeProposal,
} from "Shared/api/changeProposalApi";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import OrgMemberChange from "./(changeProposalType)/OrgMemberChange";
import { OrgQuorumApplyToAllTxsChange } from "./(changeProposalType)/OrgQuorumApplyToAllTxsChangeProposal";
import { OrgQuorumMinApprovalsChange } from "./(changeProposalType)/OrgQuorumMinApprovalsChange";
import OrgUserRoleChange from "./(changeProposalType)/OrgUserRoleChange";

export const PendingChanges = ({
  changeProposal,
  org,
}: {
  changeProposal: ChangeProposal;
  org: CompleteOrg;
}) => {
  switch (changeProposal.type) {
    case ChangeProposalType.OrgUserRoleChangeProposal:
      return (
        <OrgUserRoleChange
          org={org}
          changeProposal={changeProposal as OrgUserRoleChangeProposal}
          childRoles={org.child_roles}
        />
      );
    case ChangeProposalType.OrgMemberInviteProposal:
    case ChangeProposalType.OrgMemberRemoveProposal:
      return <OrgMemberChange changeProposal={changeProposal as OrgMemberChangeProposal} />;
    case ChangeProposalType.OrgQuorumMinApprovalsChangeProposal:
      return (
        <OrgQuorumMinApprovalsChange
          changeProposal={changeProposal as OrgQuorumMinApprovalsChangeProposal}
        />
      );
    case ChangeProposalType.OrgQuorumApplyToAllTxsChangeProposal:
      return (
        <OrgQuorumApplyToAllTxsChange
          changeProposal={changeProposal as OrgQuorumApplyToAllTxsChangeProposal}
        />
      );
    default:
      return (
        <div className="rounded bg-gray-100 p-2">
          Change proposal display not implemented for type {changeProposal.type}
        </div>
      );
  }
};
