import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  rejected_reason: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "VERIFICATION/APPROVE/SET/REJECTED_REASON":
      return updatedField(state, action, "rejected_reason");

    case "VERIFICATION/APPROVE/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "VERIFICATION/APPROVE/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "VERIFICATION/APPROVE/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
