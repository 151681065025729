import { useEffect } from "react";

/**
 * Takes a `handler` function and a `ref`.
 * The `handler` function is called when a click event is detected outside the `ref` element.
 **/
export const useOnClickOutside = ({
  handler,
  ref,
}: {
  handler: (event: Event) => void;
  ref: React.RefObject<HTMLElement>;
}) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const element = ref?.current;
      if (!element || element.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
