import React, { useEffect, useMemo } from "react";

import { useToast } from "@unchained/component-library";
import { Big } from "big.js";
import { useSelector } from "react-redux";

import {
  BTC,
  TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE,
  useBuyBitcoinDispatch,
  useBuyBitcoinStore,
} from "Contexts/BuyBitcoin";
import {
  setBuyBitcoinInfo,
  setIsGetBuyBitcoinInfoError,
  setIsGetBuyBitcoinInfoLoading,
  setIsTradeStatementDownloading,
  setTradingJwt,
} from "Contexts/BuyBitcoin/buyBitcoinActions";
import { BuyBitcoinActions, Currency } from "Contexts/BuyBitcoin/types";
import { getCurrentOrgId, getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { DocumentErrorToast } from "Routes/documents/DocumentErrorToast";
import { TradingAPI } from "Shared/api";
import { useTradingStatus } from "Shared/api/hooks";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { featureOn } from "Utils/config";
import {
  CENTRAL_TIME_ZONE,
  parseTimestamp,
  utcTimestampIsBeforeTodayCt,
  utcTimestampIsTodayCt,
} from "Utils/time";

import { getInverseBtcUsdAmount, isValidMinMaxAmount } from "./helpers";

/**
 * A custom hook that will get the maxPurchaseAmount and minPurchaseAmount of the selected amountCurrency.
 *
 * @returns {object} - 2 fields
 * maxPurchaseAmount - number max purchase amount in the current amount currency.
 * minPurchaseAmount - number min purchase amount in the current amount currency.
 */
export const useGetMinMaxPurchaseAmountInAmountCurrency = () => {
  const { amountCurrency, maximumPurchaseAmount, minimumPurchaseAmount } = useBuyBitcoinStore();
  let max: string;
  let min: string;
  if (amountCurrency === BTC) {
    max = maximumPurchaseAmount.BTC;
    min = minimumPurchaseAmount.BTC;
  } else {
    max = maximumPurchaseAmount.USD;
    min = minimumPurchaseAmount.USD;
  }

  return { maxPurchaseAmount: max, minPurchaseAmount: min };
};

/**
 * A custom hook that returns the current amount as a number in the opposite currency as the currenct amountCurrency.
 * If the current amountCurrency is BTC then the function will return a USD amount.
 * If the current amountCurrenct is USD then the function will return a BTC amount.
 *
 * @returns {string} - Amount in the opposite currency as the currenct amountCurrency.
 */
export const useAmountOfInverseCurrency = (
  currency: Currency,
  amount: number | string,
  bitcoinPrice: string
) => {
  return getInverseBtcUsdAmount(currency, amount, bitcoinPrice);
};

/**
 * A function that fetches a trading Jwt and then fetches Buy Bitcoin Info with said jwt.
 * Loading, error and data setting reducer actions are called around the requests.
 *
 * @param {Function} dispatch - The useBuyBitcoinDispatch
 * @param {String} orgUuid - The current user's orgUuid
 */
export const fetchBuyBitcoinData = async (
  dispatch: React.Dispatch<BuyBitcoinActions>,
  orgUuid: string
) => {
  try {
    dispatch(setIsGetBuyBitcoinInfoLoading(true));

    const { jwt } = await TradingAPI.StartTradingSession(orgUuid);

    const tradingData = await TradingAPI.GetTradingInfo(jwt);

    dispatch(setBuyBitcoinInfo(tradingData));
    dispatch(setTradingJwt(jwt));
    dispatch(setIsGetBuyBitcoinInfoLoading(false));
    dispatch(setIsGetBuyBitcoinInfoError(false));
  } catch (err) {
    dispatch(setIsGetBuyBitcoinInfoError(true));
    dispatch(setIsGetBuyBitcoinInfoLoading(false));
  }
};

/**
 * A custom hook that fetches Buy Bitcoin Info and calls relevant context actions around the request.
 *
 */
export const useGetBuyBitcoinInfo = () => {
  const orgUuid: string = useSelector(getCurrentOrgId);

  const dispatch = useBuyBitcoinDispatch();
  useEffect(() => {
    fetchBuyBitcoinData(dispatch, orgUuid);
  }, [dispatch, orgUuid]);
};

/**
 * A custom hook that will determine is the buy bitcoin amount is within the min and max purchase amount range.
 *
 * @returns {boolean}
 */
export const useIsValidMinMaxPurchaseAmount = () => {
  const { amount } = useBuyBitcoinStore();
  const { maxPurchaseAmount, minPurchaseAmount } = useGetMinMaxPurchaseAmountInAmountCurrency();
  return isValidMinMaxAmount(amount, maxPurchaseAmount, minPurchaseAmount);
};

/**
 * A custom hook that fetches the buy bitcoin feature flag data and sets the bool value in the buy bitcoin context.
 *
 * @returns {object}
 *  hasFetched - boolean representing if a fetch has been made to GetTradingStatus yet.
 *  isTradingFeatureAvailable - boolean value representing if the buy bitcoin feature is available to this user.
 */
export const useIsBuyBitcoinFeatureEnabled = () => {
  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg.account_type === "ira";

  const isIraBuying: boolean = featureOn("ira_buy_bitcoin", currentOrg);
  const isIraSelling: boolean = featureOn("ira_sell_bitcoin", currentOrg);
  const isIraTradingFeatureEnabled = isIraBuying && isIraSelling;

  const orgUuid = currentOrg.uuid;
  const { isTradingFeatureAvailable } = useBuyBitcoinStore();

  const isTradeFeatureEnabled = isIraOrg
    ? isIraTradingFeatureEnabled && isTradingFeatureAvailable
    : isTradingFeatureAvailable;

  const { isLoading } = useTradingStatus(orgUuid);

  return { hasFetched: !isLoading, isTradingFeatureAvailable: isTradeFeatureEnabled };
};

/**
 * Hook to determine if a wire transfer is due today or not.
 *
 * If a wire is purchased before 3PM (15:00) central time today then the wire is due today.
 */
export const useIsWireDueToday = () => {
  const { confirmedBuyTimeStamp } = useBuyBitcoinStore();
  // assure that [GMT] is converted to GMT
  const confirmedBuyTimeStampSanitized = confirmedBuyTimeStamp
    ? confirmedBuyTimeStamp.replace("[", "").replace("]", "")
    : confirmedBuyTimeStamp;
  const hourBought = parseTimestamp(confirmedBuyTimeStampSanitized)
    .tz(CENTRAL_TIME_ZONE)
    .format("HH");

  const wasBoughtTodayAndBefore3PM =
    // TODO: @joseph - hourBought is a string here it seems. I ran into a type issue and ignored it.
    // Maybe this needs to be revisited?
    utcTimestampIsTodayCt(confirmedBuyTimeStampSanitized) && (hourBought as unknown as number) < 15;

  return wasBoughtTodayAndBefore3PM || utcTimestampIsBeforeTodayCt(confirmedBuyTimeStampSanitized);
};

/**
 * A hook determining which trading onboarding card should be showing,
 * either the onBoardingCompletedCard, the onBoardingIncompleteCard, or neither if both false.
 */
export const useTradingOnboardingCardStatus = (
  forceOnboardingCompletedCardToShow = false,
  currentOrg: CompleteOrg
) => {
  const isIraAccount = currentOrg.account_type === "ira";

  const {
    isTradingOnboardingCardShowing,
    onboardingStatus: { hasVault, isTierThreeProfile, isBankAccountOnFile },
  } = useBuyBitcoinStore();

  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();

  const isPotentiallyPendingOnboardingRequirementCompleted = onboardingRequirement => {
    return onboardingRequirement === TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE;
  };

  const isIraBuying: boolean = featureOn("ira_buy_bitcoin", currentOrg);
  const isIraSelling: boolean = featureOn("ira_sell_bitcoin", currentOrg);
  const isIraTradingFeatureFlagsEnabled = isIraBuying && isIraSelling;

  const isIraOnBoardingIncomplete =
    !hasVault || !isPotentiallyPendingOnboardingRequirementCompleted(isTierThreeProfile);

  const isOnBoardingIncomplete = isIraAccount
    ? isIraOnBoardingIncomplete
    : !hasVault ||
      !isPotentiallyPendingOnboardingRequirementCompleted(isTierThreeProfile) ||
      !isPotentiallyPendingOnboardingRequirementCompleted(isBankAccountOnFile);

  const showTradingOnboardingIncompleteCardForAllAccountTypes =
    !isTradingFeatureAvailable && isTradingOnboardingCardShowing && isOnBoardingIncomplete;

  const showTradingOnboardingIncompleteCardForIraAccount =
    showTradingOnboardingIncompleteCardForAllAccountTypes && isIraTradingFeatureFlagsEnabled;

  const showTradingOnboardingIncompleteCard = isIraAccount
    ? showTradingOnboardingIncompleteCardForIraAccount
    : showTradingOnboardingIncompleteCardForAllAccountTypes;

  const showTradingOnboardingCompletedCard = forceOnboardingCompletedCardToShow
    ? !isOnBoardingIncomplete
    : !isOnBoardingIncomplete && isTradingOnboardingCardShowing && isTradingFeatureAvailable;

  return { showTradingOnboardingIncompleteCard, showTradingOnboardingCompletedCard };
};

/**
 * Hook which returns a function to handle downloading a trade statement pdf.
 */
export const useDownloadTradeStatement = () => {
  const dispatch = useBuyBitcoinDispatch();
  const orgUuid: string = useSelector(getCurrentOrgId);
  const { enqueueToast } = useToast();

  const fireErrorToast = tradeId => {
    enqueueToast("Trading agreement failed", {
      persist: true,
      content: key => <DocumentErrorToast tradeId={tradeId} toastKey={key} />,
    });
  };

  const downloadStatement = async (tradeId: string) => {
    try {
      dispatch(setIsTradeStatementDownloading(true, tradeId));
      await TradingAPI.GetBuyStatementDocument(orgUuid, tradeId);
      dispatch(setIsTradeStatementDownloading(false, tradeId));
    } catch (err) {
      dispatch(setIsTradeStatementDownloading(false, tradeId));
      fireErrorToast(tradeId);
    }
  };

  return downloadStatement;
};

export const useDoesUserHavePendingWireAmountDue = () => {
  const {
    credit: { usedUsd },
  } = useBuyBitcoinStore();

  return doesUserHavePendingWireAmountDue(usedUsd);
};

export const doesUserHavePendingWireAmountDue = (usedUsd: string): boolean => {
  try {
    return Big(usedUsd).gt(0);
  } catch (err) {
    return false;
  }
};

export const useIsPaidViaCashBalanceOnly = (wireAmountDue: string) => {
  return useMemo(() => {
    try {
      return Big(wireAmountDue).eq(0);
    } catch (e) {
      return false;
    }
  }, [wireAmountDue]);
};

export const useAmountPaidFromCashBalance = (
  totalCost: string,
  wireAmountDue: string,
  isPaidViaCashBalanceOnly: boolean
) => {
  return useMemo(() => {
    try {
      if (isPaidViaCashBalanceOnly) {
        return totalCost;
      } else {
        return Big(totalCost).minus(wireAmountDue).toString();
      }
    } catch (e) {
      return "0";
    }
  }, [totalCost, wireAmountDue, isPaidViaCashBalanceOnly]);
};
