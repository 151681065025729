import React from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import { DocumentMetadata } from "./DocumentMetadata";
import { FilePreview } from "./FilePreview";
import styles from "./FileUpload.module.scss";

class DocumentPreview extends React.Component {
  static propTypes = {
    previewMessage: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    thumbnail_data: PropTypes.string,
    size: PropTypes.number.isRequired,
    onDownload: PropTypes.func,
    remove: PropTypes.func,
    newContent: PropTypes.object,
    direction: PropTypes.oneOf(["row", "column"]),
    className: PropTypes.string,
  };
  static defaultProps = {
    direction: "row",
  };
  getGridSizeFromDirection = direction => {
    if (direction.includes("column")) {
      return {
        documentMetaData: 12,
        fileUpload: 8,
        justify: "center",
      };
    }
    return {
      documentMetaData: 5,
      fileUpload: 7,
    };
  };
  render() {
    const {
      label,
      name,
      description,
      size,
      thumbnail_data,
      onDownload,
      remove,
      previewMessage,
      newContent,
      direction,
      className,
    } = this.props;
    const sizes = this.getGridSizeFromDirection(direction);
    return (
      <Grid container justifyContent={sizes.justify} className={className}>
        <Grid item xs={sizes.documentMetaData}>
          <DocumentMetadata label={label} description={description} />
        </Grid>

        <Grid item xs={sizes.fileUpload}>
          <FilePreview
            label={label}
            previewMessage={previewMessage}
            name={name}
            size={size}
            thumbnail_data={thumbnail_data}
            onDownload={newContent ? null : onDownload}
            remove={remove}
          />
        </Grid>
      </Grid>
    );
  }
}

export { DocumentPreview };
