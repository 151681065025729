import { SET_LENDERS } from "Actions/lenders";

const initialState = [];

export const lenderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LENDERS: {
      return payload;
    }

    default:
      return state;
  }
};
