import { useEffect, useRef } from "react";

/**
 *
 * @param {*} value - Any value to be remembered and returned next time the value changes.
 * @returns {*} ref of the previous input value.
 */
export function usePrevious(value) {
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
