import React from "react";

import cn from "classnames";

import { useNavigationState } from "Contexts/Navigation";

import { MobileTopNav } from "./MobileTopNav";
import { MobileUnderlay } from "./MobileUnderlay";
import { Sidebar } from "./Sidebar";

export const Nav = ({ mobile = false }) => {
  const { drawerOpen } = useNavigationState();

  return mobile ? (
    <>
      <MobileTopNav />
      <div className={cn("fixed z-50 flex md:hidden", { "pointer-events-none": !drawerOpen })}>
        <MobileUnderlay />
        <Sidebar
          id="mobileSidebar"
          className={cn("-left-full z-50", drawerOpen && "translate-x-full")}
          mobile
        />
      </div>
    </>
  ) : (
    <div className="hidden md:flex">
      <Sidebar id="sidebar" className="sticky top-0" />
    </div>
  );
};
