import React, { ReactElement } from "react";

import cx from "classnames";

import styles from "./PreviewContentItem.module.scss";

interface PreviewContentItemProps {
  title: string | ReactElement;
  content: string | [] | number | ReactElement;
  animateOnChange: boolean;
  contentStyles?: string;
  titleStyles?: string;
  containerStyles?: string;
}

const PreviewContentItem = ({
  title,
  content,
  animateOnChange = false,
  contentStyles = styles.content,
  titleStyles = styles.title,
  containerStyles = styles.contentItem,
}: PreviewContentItemProps) => {
  return (
    <div className={containerStyles}>
      <p className={titleStyles}>{title}</p>
      <span className={cx({ [styles.content_flash]: animateOnChange }, contentStyles)}>
        {content}
      </span>
    </div>
  );
};

export { PreviewContentItem };
