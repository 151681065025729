import { updatedField } from "Utils/errors";

import { initialPersonState } from "./person";

var steps = ["identity", "address"];

function initialState() {
  return Object.assign(initialPersonState(), { uuid: "new", title: "", business: true });
}

function nextStep(currentStep) {
  return steps[steps.indexOf(currentStep) + 1];
}

function previousStep(currentStep) {
  return steps[steps.indexOf(currentStep) - 1];
}

function enabledSubmitIfPossible(state) {
  if (
    state.physical_address_line_1 &&
    state.physical_address_city &&
    state.physical_address_zip &&
    state.physical_address_state
  ) {
    return {
      ...state,
      ...{ profile_complete: true },
    };
  } else {
    return state;
  }
}

export default (state = initialState(), action = {}) => {
  switch (action.type) {
    case "@redux-conn/LOAD_SUCCESS":
      if (action.payload.data && action.payload.data.principal) {
        return { ...state, ...action.payload.data.principal };
      } else {
        return state;
      }

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/FIRST_NAME":
      return updatedField(state, action, "first_name");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MIDDLE_NAME":
      return updatedField(state, action, "middle_name");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/LAST_NAME":
      return updatedField(state, action, "last_name");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/TITLE":
      return updatedField(state, action, "title");

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/DOB":
      return updatedField(state, action, "dob");

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_LINE_1":
      return updatedField(state, action, "physical_address_line_1");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_LINE_2":
      return updatedField(state, action, "physical_address_line_2");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_LINE_3":
      return updatedField(state, action, "physical_address_line_3");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_CITY":
      return updatedField(state, action, "physical_address_city");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_STATE":
      return updatedField(state, action, "physical_address_state");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_COUNTRY":
      return updatedField(state, action, "physical_address_country");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_ZIP":
      return updatedField(state, action, "physical_address_zip");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/PHYSICAL_ADDRESS_ZIP4":
      return updatedField(state, action, "physical_address_zip4");

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_LINE_1":
      return updatedField(state, action, "mailing_address_line_1");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_LINE_2":
      return updatedField(state, action, "mailing_address_line_2");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_LINE_3":
      return updatedField(state, action, "mailing_address_line_3");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_CITY":
      return updatedField(state, action, "mailing_address_city");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_STATE":
      return updatedField(state, action, "mailing_address_state");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_COUNTRY":
      return updatedField(state, action, "mailing_address_country");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_ZIP":
      return updatedField(state, action, "mailing_address_zip");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/MAILING_ADDRESS_ZIP4":
      return updatedField(state, action, "mailing_address_zip4");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/TOGGLE/MAILING_ADDRESS_IS_PHYSICAL_ADDRESS":
      return {
        ...state,
        ...{ mailing_address_is_physical_address: !state.mailing_address_is_physical_address },
      };

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/STATE_ID_ACCEPTED_FILES":
      return updatedField(state, action, "state_id_accepted_files");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/STATE_ID_REJECTED_FILES":
      return updatedField(state, action, "state_id_rejected_files");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/STATE_ID":
      return updatedField(state, action, "state_id");
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/STATE_ID_PREVIOUS_UPLOAD":
      return { ...state, state_id: {} };

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/NEW":
      return initialState();

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/EDIT":
      return {
        ...state,
        ...{ status: "new", uuid: action.uuid, step: steps[0], business: true },
      };

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SUBMITTING":
      return { ...state, status: "submitting" };
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SUCCESS":
      return { ...state, status: "success", errors: {} };

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/STEP/NEXT":
      return { ...state, step: nextStep(state.step) };
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/STEP/NEXT/SUCCESS":
      var addressed_as = action.principal.first_name + " " + action.principal.last_name;
      return enabledSubmitIfPossible({
        ...state,
        ...action.principal,
        ...{ addressed_as: addressed_as, errors: {} },
      });

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/STEP/PREVIOUS":
      return { ...state, step: previousStep(state.step) };
    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/SET/STEP":
      return { ...state, step: action.step };

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/RESET":
      return initialState();

    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/SSN":
      return updatedField(state, action, "ssn");

    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/DIGITAL_ASSETS":
      return updatedField(state, action, "digital_assets");
    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/BITCOIN_SOURCE_NOTES":
      return updatedField(state, action, "bitcoin_source_notes");

    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/FIAT_ASSETS":
      return updatedField(state, action, "fiat_assets");
    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/FIAT_ASSET_SOURCES":
      return updatedField(state, action, "fiat_asset_sources");

    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/INCOME":
      return updatedField(state, action, "income");
    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/SET/INCOME_SOURCES":
      return updatedField(state, action, "income_sources");

    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "CREATE_ADVANCED_PROFILE/PRINCIPAL/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };

    default:
      return state;
  }
};
