/* global TREFOIL_CONFIG TREFOIL_USER */
// The 'TREFOIL_CONFIG' variable is templated out into a separate JS
// file during the build process.
import get from "lodash/get";

export const TREFOIL_CONFIG_ERROR = "TREFOIL CONFIG ERROR";

export function getConfig(key, defaultVal = null) {
  if (window.TREFOIL_CONFIG) {
    return get(window.TREFOIL_CONFIG, key, defaultVal);
  }

  throw Error(TREFOIL_CONFIG_ERROR);
}

export function minLength(field) {
  return getConfig("min_length")[field];
}

export function maxLength(field) {
  return getConfig("max_length")[field];
}

//
// Features
//

export function featureOn(name, user_or_org) {
  const features = user_or_org.all_features || {};
  return Boolean(Object.keys(features).includes(name) && features[name]);
}

export function featureOff(name, user_or_org) {
  const features = user_or_org.all_features || {};
  return !Object.keys(features).includes(name) || !features[name];
}
