import React from "react";

import PropTypes from "prop-types";

export function documentProptypes(admin) {
  return {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    dangerous: PropTypes.bool.isRequired,

    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  };
}
