import { GetAdminSupportPin200, GetClientSupportPin200, VerifyAdminSupportPin200 } from "Specs/v1";

import { API } from "./api";

class SupportPinsAPI {
  static GetSupportPin = async (userUuid: string) =>
    (await API.Get<GetClientSupportPin200>(`/users/${userUuid}/support-pin`)).data;
  static GetAdminSupportPin = async (userUuid: string) =>
    (await API.Get<GetAdminSupportPin200>(`/users/${userUuid}/admin-support-pin`)).data;
  static VerifyAdminSupportPin = async data =>
    (await API.Post<VerifyAdminSupportPin200>("/support-pins/admin-pin/verify", data)).data;
}

export { SupportPinsAPI };
