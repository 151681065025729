import React from "react";

import { Checkbox, FormGroup, FormHelperText } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

import { RadioButton } from "../../RadioButton/RadioButton";

export const DeprecatedFormikMuiCheckbox = ({ name, ...other }) => {
  const fieldConfig = { name, type: "checkbox" };
  const [field] = useField(fieldConfig);

  return <Checkbox {...other} {...field} />;
};

DeprecatedFormikMuiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export const DeprecatedFormikCheckbox = ({ name, value, ...other }) => {
  const [field] = useField({ name, value, type: "checkbox" });

  return <RadioButton type="checkbox" {...other} {...field} />;
};

DeprecatedFormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export const DeprecatedFormikCheckboxGroup = ({ name, children }) => {
  const [, { touched, error }] = useField(name);

  const hasError = error && touched;

  return (
    <FormGroup>
      {children}
      {hasError ? <FormHelperText error>{error}</FormHelperText> : null}
    </FormGroup>
  );
};

DeprecatedFormikCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
};
