import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./WizardComponent.module.scss";

const WizardTitle = ({ text, children, size, classes }) => {
  return (
    <h3
      className={classnames(
        classes,
        {
          [styles.title]: size !== "sm",
          [styles.smTitle]: size === "sm",
        },
        "mb-4"
      )}
    >
      {text ? text : children}
    </h3>
  );
};

WizardTitle.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.oneOf(["sm", "md"]),
};

WizardTitle.defaultProps = {
  size: "md",
};

export { WizardTitle };
