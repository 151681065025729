import React, { ReactElement } from "react";

import { Loader } from "@unchained/component-library";

import { REQUEST_STATUS } from "Shared/api/api";

import { Forbidden } from "../../errors/Forbidden";
import NotFound from "../../errors/NotFound";
import { ServerError } from "../../errors/ServerError";

interface LoadingWrapperProps {
  status: string;
  render: () => ReactElement;
  fullScreen?: boolean;
}

export const LoadingWrapper = ({ status, render, fullScreen = true }: LoadingWrapperProps) => {
  if (status === REQUEST_STATUS.PENDING) {
    return <Loader className={fullScreen ? "h-screen" : undefined} />;
  }
  if (status === REQUEST_STATUS.SUCCESS) {
    return render();
  }
  if (status === REQUEST_STATUS.NOT_FOUND) {
    return <NotFound />;
  }
  if (status === REQUEST_STATUS.FORBIDDEN) {
    return <Forbidden />;
  }
  if (status === REQUEST_STATUS.ERROR) {
    return <ServerError />;
  }
  return null;
};
