import { LendersAPI } from "Shared/api/lendersApi";

import { Logger } from "../shared/logging/Logger";

export const SET_LENDERS = "SET_LENDERS";

export const setLendersAction = lenders => ({
  type: SET_LENDERS,
  payload: lenders,
});

export const getLendersAction = () => {
  return async dispatch => {
    try {
      const lenders = await LendersAPI.GetLenders();
      dispatch(setLendersAction(lenders));
    } catch (e) {
      Logger.log("Could not retrieve lenders");
    }
  };
};
