import PropTypes from "prop-types";

import { getVaultStatus } from "Models/get_vault_status";
import {
  cryptoToFiat,
  fiatWithUnit,
  longCrypto,
  longCryptoWithUnit,
  shortCrypto,
  shortCryptoWithUnit,
} from "Utils/currency";
import { humanState } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

export function vaultListingProptypes() {
  return {
    uuid: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    humanState: PropTypes.string,
    unit: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    product_type: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    unconfirmed_balance: PropTypes.number,
    deposited_balance: PropTypes.number,
    balance: PropTypes.number,
    name: PropTypes.string.isRequired,

    createdAt: PropTypes.object.isRequired,
    readableCreatedAt: PropTypes.string.isRequired,

    confirmedBalanceWithUnit: PropTypes.string,
    confirmedBalanceWithoutUnit: PropTypes.string,
    partner: PropTypes.bool,
  };
}

export function VaultListing(vault, prices) {
  if (vault == null) {
    return vault;
  }
  const productClass = "Vault";

  const humanReadableState = humanState({ state: vault.state });
  const createdAt = parseTimestamp(vault.created_at);
  const readableCreatedAt = readableTime(vault.createdAt);

  const custodyType = vault.wallet_type === "multiorg" ? "Multi-institution" : "Client-controlled";
  const currentStatus = vault.state ? getVaultStatus(vault.state) : "";

  const cryptoAmount = shortCrypto(vault.deposited_balance);
  const cryptoDisplayed = shortCryptoWithUnit(vault.deposited_balance, vault.unit);
  const fiatAmount = cryptoToFiat(vault.deposited_balance, vault.unit, prices);
  const fiatDisplayed = fiatWithUnit(fiatAmount);

  let confirmedBalanceWithUnit;
  let confirmedBalanceWithoutUnit;
  let hasBalance = false;

  if (vault.deposited_balance !== null) {
    confirmedBalanceWithUnit = longCryptoWithUnit(vault.deposited_balance, vault.unit);
    confirmedBalanceWithoutUnit = longCrypto(vault.deposited_balance);
    hasBalance = true;
  }

  let pendingBalanceWithUnit;
  if (vault.balance !== null) {
    pendingBalanceWithUnit = longCryptoWithUnit(vault.balance, vault.unit);
    hasBalance = true;
  } else {
    hasBalance = false;
  }

  let additional_pending_balance, additionalPendingBalanceWithUnit;
  let hasPendingBalance = false;

  if (
    vault.balance !== null &&
    vault.balance !== undefined &&
    vault.deposited_balance !== null &&
    vault.deposited_balance !== undefined
  ) {
    if (vault.balance !== vault.deposited_balance) {
      additional_pending_balance = vault.balance - vault.deposited_balance;
      additionalPendingBalanceWithUnit = longCryptoWithUnit(
        vault.additional_pending_balance,
        vault.unit
      );
      hasPendingBalance = true;
    }
  }

  let value = prices => {
    if (
      typeof vault.deposited_balance != "undefined" &&
      vault.deposited_balance != null &&
      prices &&
      prices[vault.unit] &&
      prices[vault.unit].value
    ) {
      return vault.deposited_balance * prices[vault.unit].value;
    } else {
      return null;
    }
  };

  let valueWithUnit = prices => {
    let fiatValue = value(prices);
    if (
      typeof fiatValue != "undefined" &&
      fiatValue != null &&
      prices &&
      prices[vault.unit] &&
      prices[vault.unit].unit
    ) {
      return `$${formatCurrency(fiatValue)}`;
    } else {
      return null;
    }
  };

  return {
    ...vault,
    productClass,
    humanState: humanReadableState,
    createdAt,
    readableCreatedAt,

    // temporary Names
    custodyType,
    currentStatus,
    cryptoAmount,
    cryptoDisplayed,
    fiatAmount,
    fiatDisplayed,
    // old values
    hasBalance,
    confirmedBalanceWithUnit,
    confirmedBalanceWithoutUnit,
    pendingBalanceWithUnit,
    additional_pending_balance,
    additionalPendingBalanceWithUnit,
    hasPendingBalance,
    value,
    valueWithUnit,
  };
}
