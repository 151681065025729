import React, { Component } from "react";

import { TableCell } from "@mui/material";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./SummaryTableCell.module.scss";

class SummaryTableCellBase extends Component {
  static propTypes = {
    children: PropTypes.any,
    component: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    component: "td",
  };

  render() {
    const { component, children, className } = this.props;

    return (
      <TableCell
        component={component}
        classes={{
          root: classnames(className, {
            [styles.th]: component === "th",
            [styles.td]: component === "td",
          }),
        }}
      >
        {children}
      </TableCell>
    );
  }
}

const SummaryTableHeaderCellBase = props => <SummaryTableCellBase component="th" {...props} />;

export const SummaryTableCell = SummaryTableCellBase;
export const SummaryTableHeaderCell = SummaryTableHeaderCellBase;
