import { useEffect, useState } from "react";

import { Add } from "@mui/icons-material";
import { ClickAwayListener, Collapse } from "@mui/material";
import { Avatar, Exit } from "@unchained/component-library";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import { Link, useNavigate } from "Components/Link";
import { useGetAccount } from "Shared/api";
import { COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getAccount/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { postOnboardingStates, useHasCompletedBasicInfo } from "Utils/orgState";

import { useCloseOnBreakpoint, useSwitchCurrentOrg } from "../hooks";
import { AvatarRow, AvatarRowLeft, CurrentOrgAvatarItem, OrgAvatarRowLeft } from "./AvatarRows";
import { DeleteOrgIcon } from "./DeleteOrgIcon";
import { FadeUp } from "./FadeUp";

const { INDIVIDUAL } = COMPLETE_ORG_ACCOUNT_TYPE;

export const AccountSwitcher = ({
  mobile = false,
  className,
  direction = "down",
}: {
  mobile?: boolean;
  className?: string;
  direction?: "up" | "down";
}) => {
  const getAccount = useGetAccount();
  const [isOpen, setOpenState] = useState(false);
  const [fullyClosed, setFullyClosed] = useState(true);
  const setClosed = () => setOpenState(false);
  const setOpen = () => {
    setOpenState(true);
    setFullyClosed(false);
  };
  const switchCurrentOrg = useSwitchCurrentOrg();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * There's a very particular animation that goes on when the switcher collapses/expands downward.
   * On expansion, the bottom border of the trigger should immediately un-round as the items slide out.
   * On collapse, the bottom border should remain un-rounded until the items are fully hidden, then animate rounded again.
   * This involves two closed states.
   * - !isOpen - immediate upon trigger click
   * - fullyClosed - delayed til the end of the slide animation (300ms)
   *
   * Then, below, the rounding of the item surrounding the trigger animates accordingly.
   * */
  useEffect(() => {
    if (!isOpen && !fullyClosed) {
      setTimeout(() => setFullyClosed(true), direction === "down" ? 300 : 0);
    }
  }, [isOpen, fullyClosed, direction]);

  useCloseOnBreakpoint("md", mobile, isOpen, setClosed);

  const basicInfoComplete = useHasCompletedBasicInfo();

  if (getAccount.isLoading) return null;

  const { personalOrg, memberships, currentOrg, isUnchainedAdmin } = getAccount.data;

  const orgs = [...memberships.map(m => m.org), personalOrg];

  const denied = personalOrg.state === "denied";

  const orgIsAccessible = (org: CompleteOrg) => {
    if (org.account_type === INDIVIDUAL) {
      // Don't show pending_payment individual orgs,
      // which are semantically just basic info without an individual org.
      // The pending_basic_info individual org shouldn't exist if you have other orgs.
      return postOnboardingStates.includes(org.state);
    } else {
      // non-individual orgs
      // show non-denied orgs, arbiter orgs and delegate orgs.
      if (org.state !== "denied" || org.type === "arbiter" || org.type === "delegate") return true;
    }

    return false;
  };

  // Filtering logic for which other orgs may be switched to
  const otherOrgs = denied
    ? // If basic profile is denied, you can't do anything.
      []
    : orgs.filter(
        o =>
          // Can't switch to current org (duh)
          o.uuid !== currentOrg.uuid &&
          // Admins shouldn't be able to see/toggle to their individual accounts
          (isUnchainedAdmin ? o.account_type !== INDIVIDUAL : true) &&
          orgIsAccessible(o)
      );

  const switchToOrg = async org => {
    setClosed();
    if (denied) return;

    const { account_type, type, uuid, state } = org;

    const targetMembership = memberships.find(m => m.org.uuid === uuid);

    // If trying to switch to an invited-but-not-accepted org, redirect to settings
    // to accept the invite.
    if (targetMembership?.state === "pending_acceptance") {
      navigate("/settings?tab=my-accounts&reason=invite");
      return;
    }

    await switchCurrentOrg(uuid);
    if (!postOnboardingStates.includes(state) && account_type === INDIVIDUAL) {
      navigate(`/onboard?from=${location.pathname}`);
    } else if (
      !postOnboardingStates.includes(state) &&
      !isUnchainedAdmin &&
      type !== "arbiter" &&
      type !== "delegate"
    ) {
      navigate(`/onboard/${uuid}?from=${location.pathname}`);
    } else {
      navigate("/home");
    }
  };

  const canAddAccount = basicInfoComplete && !isUnchainedAdmin;
  const down = direction === "down";
  const up = direction === "up";

  const switcherContent = (
    <div className={cn("flex flex-col overflow-hidden border-t border-t-gray-200")}>
      <div className={cn("overflow-y-auto bg-white", canAddAccount ? "py-2" : "")}>
        {otherOrgs.map(org => (
          <AvatarRow
            key={org.uuid}
            className="bg-white hover:bg-gray-100"
            onClick={() => switchToOrg(org)}
          >
            <OrgAvatarRowLeft org={org} isUnchainedAdmin={isUnchainedAdmin} />
            <DeleteOrgIcon org={org} />
          </AvatarRow>
        ))}
        {canAddAccount && (
          <AvatarRow
            to={`/onboard?from=${encodeURIComponent(window.location.pathname)}`}
            onClick={setClosed}
            className="no-underline hover:bg-gray-100 hover:no-underline"
          >
            <AvatarRowLeft
              avatar={
                <Avatar
                  size="md"
                  icon={<Add />}
                  color="#e3e8ef"
                  name="Add account"
                  className="[&>svg]:text-gray-800"
                />
              }
              subtitle="Add another account"
            />
          </AvatarRow>
        )}
      </div>

      <Link
        className={cn(
          "flex w-full items-center justify-center gap-4 rounded-b-lg py-4 font-bold transition-colors",
          "bg-primary-600 text-white no-underline",
          "hover:bg-primary-700 hover:text-white hover:no-underline"
        )}
        to="/logout"
      >
        <span>Sign out</span>
        <Exit />
      </Link>
    </div>
  );

  return (
    <ClickAwayListener onClickAway={setClosed}>
      <div
        className={cn(
          "relative z-40 w-[280px] rounded-lg bg-[#F5F7FA] shadow-lg transition-colors",
          className
        )}
      >
        {up ? (
          <FadeUp
            in={isOpen}
            data-testid="account-switcher-popover"
            className={cn(
              "absolute z-50 w-full rounded-lg shadow-lg",
              isOpen ? "" : "pointer-events-none"
            )}
            style={{
              // Position it above the current org avatar item
              top:
                // row height
                (58 *
                  // number of rows
                  (otherOrgs.length + 1 + (canAddAccount ? 1 : 0)) +
                  // upper and lower padding
                  16 -
                  // 🤷 - some difference I don't understand for perfect alignment
                  3) *
                // make it negative
                -1,
            }}
          >
            {/* Show the trigger again, in the faded-up switcher */}
            <CurrentOrgAvatarItem
              org={currentOrg}
              isOpen={isOpen}
              setOpen={val => {
                if (val) setOpen();
                else setClosed();
              }}
              isUnchainedAdmin={isUnchainedAdmin}
              direction={direction}
              className="!rounded-b-none"
            />
            {switcherContent}
          </FadeUp>
        ) : null}
        {/* This is the animated trigger wrapper mentioned in the useEffect comment above */}
        <div
          className={cn(
            "bg-[#F5F7FA] transition-all",
            !isOpen && fullyClosed ? "rounded-lg duration-100" : "rounded-t-lg duration-0"
          )}
        >
          <CurrentOrgAvatarItem
            org={currentOrg}
            isOpen={isOpen}
            setOpen={val => (val ? setOpen() : setClosed())}
            isUnchainedAdmin={isUnchainedAdmin}
            direction={direction}
          />
          {down ? (
            <Collapse
              in={isOpen}
              data-testid="account-switcher-popover"
              className={cn("absolute top-[50px] w-full rounded-b-lg", "pt-2")}
            >
              {switcherContent}
            </Collapse>
          ) : null}
        </div>
      </div>
    </ClickAwayListener>
  );
};
