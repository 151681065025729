import React, { MutableRefObject, useRef, useState, ReactNode, useMemo } from "react";

/** Defaults for how long to wait before stopping video,
 * and how long to wait before warning user we'll stop the video */
const WARN_SECONDS_BEFORE_TIMEOUT = 45;
const TIMEOUT_AFTER_SECONDS = 60;

type MediaCaptureFunctions = {
  onRequestPermission: () => void;
  onGranted: (stream: string) => void;
  onDenied: ({ name }: { name: string }) => void;
  onStart: (stream: string) => void;
  onStop: (blob: Blob) => void;
  onPause: () => void;
  onResume: (stream: string) => void;
  onError: (err: Error) => void;
  onStreamClosed: () => void;
};

export type VideoRecordingContextType = {
  /** Functions too hook into the MediaCapture component */
  handlers: MediaCaptureFunctions;
  /** Fields representing the state of the video capture. */
  state: {
    /** Permission has been granted by browser to record */
    granted: boolean;
    /** Permission has been reject by browser to record. Reason why */
    rejectedReason: string;
    /** Video is being recorded */
    recording: boolean;
    /** Video play is paused */
    paused: boolean;
    /** Period until warning about auto-recording-stop has been reached */
    timeWarningNeeded: boolean;
    /** Number of seconds before the auto-recording-stop to warn the user */
    warnSecondsBeforeTimeout?: number;
    /** Number of seconds after which to auto-stop recording */
    timeOutAfterSeconds?: number;
    /** Any warning emitted from the MediaCapturer */
    error?: Error;
  };
  /** The video blob itself. Starts as an empty object. To test for presence, you can use video.size */
  video: Blob;
  /** Resets the video object, etc, to allow for restarting recording. */
  recordAgain: () => void;
  /**
   * This ref should be attached to a parent of the video recorder/video component.
   * That parent must have only one `video` tag child.
   **/
  ref: MutableRefObject<any>;
  /**
   * If a stopInterval is passed in generating the context,
   * this function should be called to define the function that
   * will be called when the stopInterval is reached.
   * That stop function is generally passed in the render prop function of the MediaCapture component.
   *
   * @example
   * <MediaCapturer
   *  render{({ stop, start }) => {
   *   defineAutoStop(stop);
   *  return <div>...</div>;
   * }}
   * />
   *  */
  defineAutoStop: (stopFunc: () => void) => void;
};

export const VideoRecordingContext = React.createContext<VideoRecordingContextType>(null);

const onRequestPermission = () => {};

type ContextProps = {
  timeOutAfterSeconds?: number;
  warnSecondsBeforeTimeout?: number;
};

const throwTimeoutErrors = (props: ContextProps) => {
  const { warnSecondsBeforeTimeout, timeOutAfterSeconds } = props;
  if ((warnSecondsBeforeTimeout || 0) >= (timeOutAfterSeconds || 0)) {
    throw new Error(
      `The timeOutAfterSeconds must be greater than the warnInterval.
       timeOutAfterSeconds: ${timeOutAfterSeconds},
       warnSecondsBeforeTimeout: ${warnSecondsBeforeTimeout}`
    );
  }

  if (warnSecondsBeforeTimeout && !timeOutAfterSeconds) {
    throw new Error(`timeOutAfterSeconds must be defined if warnSecondsBeforeTimeout is defined.`);
  }
};

/**
 * This wrapper encapsulates all the logic related to stopping, starting, and recording a video,
 * without associating the functionality with any particular visual elements.
 *
 * It is context, rather than a simple hook, because it needs to be able to define a single context
 * shared between multiple "children" elements.
 *
 * Wrap a component in this wrapper, and it will be able to useContext(VideoRecordingContext) to
 * grab all relevant functions and state. The ref exported in that context should be attached to a parent
 * of the video element.
 *
 * See VideoRecordingModal for an example of how this works.
 *  */
export const VideoRecordingContextWrapper = ({
  warnSecondsBeforeTimeout = WARN_SECONDS_BEFORE_TIMEOUT,
  timeOutAfterSeconds = TIMEOUT_AFTER_SECONDS,
  children,
}: ContextProps & {
  children: ReactNode | ((props: VideoRecordingContextType) => ReactNode);
}) => {
  const ref = useRef(null);
  const [granted, setGranted] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const [error, setError] = useState<Error>(undefined);
  const [recording, setRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [video, setVideo] = useState<Blob>({} as unknown as Blob);
  const [warnTimerId, setWarnTimerId] = useState(null);
  const [timeWarningNeeded, setTimeWarningNeeded] = useState(false);
  const [stopTimerId, setStopTimerId] = useState(null);
  const [timeoutAutoStop, setTimeoutAutoStop] = useState<(bool: boolean) => void>(undefined);

  const state = useMemo(
    () => ({
      granted,
      rejectedReason,
      recording,
      paused,
      timeWarningNeeded,
      warnSecondsBeforeTimeout,
      timeOutAfterSeconds,
      error,
    }),
    [
      granted,
      rejectedReason,
      recording,
      paused,
      timeWarningNeeded,
      warnSecondsBeforeTimeout,
      timeOutAfterSeconds,
      error,
    ]
  );

  const value = useMemo(() => {
    const setStreamToVideo = stream => {
      const video = ref.current.querySelector("video");
      if (video) video.srcObject = stream;
    };

    const onWarn = () => {
      setWarnTimerId(null);
      setTimeWarningNeeded(true);
    };

    const onForceStop = () => {
      setStopTimerId(null);
      setTimeWarningNeeded(false);
      // Note that the stream doesn't need to be stopped because
      // its already stopped in onStop as a result of setting the state
      // of the video property.
      timeoutAutoStop && timeoutAutoStop(false);
    };

    const releaseStreamFromVideo = () => {
      const video = ref.current.querySelector("video");
      if (video) video.src = "";
    };

    const onGranted = stream => {
      setGranted(true);
      setStreamToVideo(stream);
    };

    const onDenied = err => {
      setRejectedReason(err.name);
    };

    const onError = setError;

    const onStop = blob => {
      if (warnTimerId > 0) {
        clearInterval(warnTimerId);
        setWarnTimerId(null);
      }
      setTimeWarningNeeded(false);
      if (stopTimerId > 0) {
        clearInterval(stopTimerId);
        setStopTimerId(null);
      }
      setRecording(false);
      releaseStreamFromVideo();
      setVideo(blob);
    };

    const recordAgain = () => {
      setTimeoutAutoStop(undefined);
      setVideo({} as unknown as Blob);
    };

    const onPause = () => {
      releaseStreamFromVideo();
      setPaused(true);
    };

    const onResume = stream => {
      setStreamToVideo(stream);
      setPaused(false);
    };

    const onStreamClosed = () => {
      setGranted(false);
    };

    const onStart = stream => {
      setRecording(true);
      if (warnSecondsBeforeTimeout)
        setWarnTimerId(setTimeout(onWarn, (timeOutAfterSeconds - warnSecondsBeforeTimeout) * 1000));
      if (timeOutAfterSeconds) setStopTimerId(setTimeout(onForceStop, timeOutAfterSeconds * 1000));
      setStreamToVideo(stream);
    };

    const defineAutoStop = stop => {
      // Only define this once.
      if (timeoutAutoStop) return null;
      setTimeoutAutoStop(() => stop);
    };

    const context = {
      handlers: {
        onRequestPermission,
        onGranted,
        onDenied,
        onStart,
        onStop,
        onPause,
        onResume,
        onError,
        onStreamClosed,
      },
      recordAgain,
      defineAutoStop,
      video,
      ref,
      state,
    };

    return context;
  }, [
    state,
    timeoutAutoStop,
    warnTimerId,
    stopTimerId,
    warnSecondsBeforeTimeout,
    timeOutAfterSeconds,
    video,
  ]);

  throwTimeoutErrors({ warnSecondsBeforeTimeout, timeOutAfterSeconds });

  return (
    <VideoRecordingContext.Provider value={value}>
      {children as ReactNode}
    </VideoRecordingContext.Provider>
  );
};

/** Alternate interface for VideoRecordingContextWrapper */
export const withVideoRecordingContext = (
  children: ReactNode,
  props: ContextProps = {
    warnSecondsBeforeTimeout: WARN_SECONDS_BEFORE_TIMEOUT,
    timeOutAfterSeconds: TIMEOUT_AFTER_SECONDS,
  }
) => {
  throwTimeoutErrors(props);

  return <VideoRecordingContextWrapper {...props}>{children}</VideoRecordingContextWrapper>;
};
