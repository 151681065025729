import { useContext } from "react";

import { WizardStepperContext } from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { Formik, FormikDropdown } from "Components/FormFields";
import { DeprecatedFormikUploadField } from "Components/Shared/Elements/Fields";
import { ManifestStep } from "Routes/onboard/(shared)";
import {
  initUserId,
  OnboardBasicContext,
  useWrapManifestMutation,
  validateUserId,
} from "Routes/onboard/(utils)";
import { DocLabel, orgQueryKeys, ProfileAPI, useDeleteProfileDoc } from "Shared/api";
import { UpdateProfileDocumentsRequest } from "Specs/v1";
import { UploadFile } from "Specs/v1/updateProfileDocuments/request";
import { useEasyToasts } from "Utils/toasts";

export const ID_TYPES = {
  STATE_ID: "state_id",
  PASSPORT: "passport",
} as const;

export const ID_TYPE_OPTIONS = [
  { label: "Driver's license", value: ID_TYPES.STATE_ID },
  { label: "Passport", value: ID_TYPES.PASSPORT },
];

export const UserIdentification = () => {
  const info = useContext(OnboardBasicContext);
  const { goToNext } = useContext(WizardStepperContext);
  const queryClient = useQueryClient();
  const { personalOrg } = info;
  const initVals = initUserId(info);
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: personalOrg.uuid });

  const updateDocuments = wrapManifestMutation(
    useMutation((documentObject: unknown) =>
      ProfileAPI.UpdateDocuments(personalOrg.uuid, documentObject as UpdateProfileDocumentsRequest)
    )
  );

  const { showErrorToast } = useEasyToasts();

  const deleteProfileDoc = useDeleteProfileDoc(personalOrg.uuid);
  const deleteDoc = (docName: DocLabel) =>
    new Promise<void>(resolve => {
      deleteProfileDoc.mutate(docName, {
        onSuccess: () =>
          resolve(queryClient.invalidateQueries(orgQueryKeys.show(personalOrg.uuid))),
        onError: err => showErrorToast(err),
      });
    });

  const submit = async (values: typeof initVals) => {
    if (values.file?.allowed_actions) return goToNext();
    return updateDocuments.mutate({
      [values.document_type as DocLabel]: values.file.newContent as unknown as UploadFile,
    });
  };

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={submit}
      validationSchema={validateUserId}
      debugName="UserID"
    >
      {({ handleSubmit, values }) => {
        return (
          <ManifestStep
            width="narrow"
            actions={[
              {
                disabled: personalOrg.state === "pending_activation",
                onClick: handleSubmit,
                children: "Continue",
                buttonType: "submit",
              },
            ]}
            title="User identification"
            description={
              <>
                <p>
                  Please upload a valid driver's license, passport, or other government-issued photo
                  ID. If you reside outside of the United States, a valid passport is required.
                </p>
                <p>
                  Please ensure that all four corners of the form of identification uploaded are
                  visible and the text in the picture is clear.
                </p>
              </>
            }
          >
            <div className="flex w-full flex-col items-start gap-6">
              <div className="flex w-full flex-col gap-4">
                <FormikDropdown
                  fullWidth
                  label="Document type"
                  options={ID_TYPE_OPTIONS}
                  name="document_type"
                />

                <DeprecatedFormikUploadField
                  className="mt-4"
                  name="file"
                  deleteDocumentFromServer={() => deleteDoc(values.document_type)}
                  disabled={!values.document_type}
                />
              </div>
            </div>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
