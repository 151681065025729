import { createContext, useMemo } from "react";

import { Loader } from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { useNavigate } from "Components/Link";
import { FullPageWizard } from "Components/Shared/Layouts/FullPageWizard";
import {
  CloseButton,
  WizardAccountSwitcher,
} from "Components/Shared/Layouts/FullPageWizard/components";
import { LoadingContextProvider, useLoadingContext } from "Contexts/LoadingContext";
import { useSetOnboardingClientReportExtra } from "Routes/onboard/(shared)/useSetOnboardingClientReportExtra";
import { GetSetObj, hideSkippedSteps, useMakeGetSetObj } from "Routes/onboard/(utils)";
import { withAccount } from "Shared/api";
import { Invoice, INVOICE_STATUS, SubscriptionAPI } from "Shared/api/v2";
import { useInvoice } from "Shared/api/v2/hooks/invoices";
import { CompleteOrg, CompleteOrgStateEnum } from "Specs/v1/getAccount/200";
import { postOnboardingStates } from "Utils/orgState";
import { useAndRemoveQueryParams } from "Utils/uris";

import { InvoiceCheckoutWithElements } from "./(steps)/CheckoutStep";

export type InvoiceContextType = {
  from?: string;
  org?: CompleteOrg;
  shippingName?: GetSetObj<string>;
  paymentProcessing?: GetSetObj<boolean>;
};

const { SETTLED, COMPLETED } = INVOICE_STATUS;

export const InvoiceContext = createContext<InvoiceContextType | undefined>(undefined);

const getInvoiceManifest = (
  invoice: Invoice | undefined,
  context: InvoiceContextType,
  orgState: CompleteOrgStateEnum,
  goBack: () => void,
  setLoading: (loading: boolean) => void
) => {
  const isSettled = SETTLED === invoice?.status;
  const paid = [SETTLED, COMPLETED].includes(invoice?.status);

  const cancel = async () => {
    if (!postOnboardingStates.includes(orgState)) {
      setLoading(true);
      await SubscriptionAPI.Cancel(invoice.subscriptionId);
      setLoading(false);
    }

    goBack();
  };

  return hideSkippedSteps([
    {
      title: paid ? "Invoice" : "Checkout",
      complete: isSettled,
      AlternateIcon: !(paid || context.paymentProcessing.value)
        ? () => <CloseButton onClose={cancel} />
        : () => <></>,
      steps: [
        {
          title: "Payment",
          Component: () => <InvoiceCheckoutWithElements invoice={invoice} />,
          complete: isSettled,
          excludeFromNavigation: true,
        },
      ],
    },
  ]);
};

const InvoiceStepperBase = ({ org }: { org: CompleteOrg }) => {
  const { from } = useAndRemoveQueryParams("from");
  const shippingName = useMakeGetSetObj<string>("");
  const paymentProcessing = useMakeGetSetObj(false);
  const navigate = useNavigate();
  const loading = useLoadingContext();

  const context = useMemo(
    () => ({ from, org, shippingName, paymentProcessing }),
    [from, org, shippingName, paymentProcessing]
  );
  const { uuid } = useParams<{ uuid: string }>();

  const invoiceQuery = useInvoice(uuid);

  const manifest = useMemo(
    () =>
      getInvoiceManifest(
        invoiceQuery.data,
        context,
        org.state,
        () => navigate(from || "/home"),
        loading.set
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context, invoiceQuery.data]
  );

  useSetOnboardingClientReportExtra("invoiceStepper", {
    manifest,
    other: { from, shippingName, paymentProcessing },
  });

  if (invoiceQuery.isLoading)
    return (
      <div className="fixed left-0 top-0 z-[1300] h-screen w-screen overflow-y-auto bg-white">
        <Loader />
      </div>
    );

  return (
    <InvoiceContext.Provider value={context}>
      <FullPageWizard
        manifest={manifest}
        stepperFooter={<WizardAccountSwitcher />}
        desktopLayout="top"
      />
    </InvoiceContext.Provider>
  );
};

const InvoiceStepper = withAccount(({ currentOrg }) => (
  <LoadingContextProvider>
    <InvoiceStepperBase org={currentOrg} />
  </LoadingContextProvider>
));

export default InvoiceStepper;
