import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { postRenderErrorAction } from "Actions/clientErrorActions/clientErrorActions";
import { TREFOIL_CONFIG_ERROR } from "Utils/config";

import DownForMaintenance from "./DownForMaintenance";
import GeneralError from "./GeneralError";

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      siteDown: false,
    };
  }

  static propTypes = {
    userAuthenticated: PropTypes.bool,
    // actions
    reportRenderError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userAuthenticated: true,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      siteDown: error.message === TREFOIL_CONFIG_ERROR,
    };
  }

  componentDidCatch(error, info) {
    // If we dont have a config object, we also wont have a CSRF token
    // so we should not try to report this error upstream.
    if (error.message !== TREFOIL_CONFIG_ERROR) {
      this.props.reportRenderError(error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.siteDown) {
        return <DownForMaintenance userAuthenticated={this.props.userAuthenticated} />;
      } else {
        return <GeneralError userAuthenticated={this.props.userAuthenticated} />;
      }
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  reportRenderError: postRenderErrorAction,
})(GlobalErrorBoundary);
