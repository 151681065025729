import React, { useContext, useEffect } from "react";

import { satoshisToBitcoins } from "@caravan/bitcoin";
import { RadioGroup, RadioButton, Rocket2, Truck1 } from "@unchained/component-library";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { useFeeRates } from "Shared/api/hooks/fees";
import { BITCOIND_MAX_FEE_SATS } from "Utils/constants";

export const StandardFees = ({ maxFeeExceeded }: { maxFeeExceeded: boolean }) => {
  const { feeRate, setState } = useContext(WithdrawModalContext);
  const { fast, slow } = useFeeRates();

  useEffect(() => {
    // Sets the fee rate when the feeRate is unset
    if (!feeRate && fast) {
      setState({ feeRate: fast.toString() });
    }
  }, [fast, feeRate, setState]);

  return (
    <>
      <RadioGroup
        className="flex flex-col gap-2"
        name="standard-fee-rate"
        value={feeRate}
        onChange={e => {
          const value = e.target.value;

          // This check is performed to prevent a context state change if the
          // value isn't really changing because a state change will re-disable
          // the next button and the validation effect does not re-run when it's
          // watching the amount.
          if (value !== feeRate) {
            setState({
              feeRate: e.target.value,
            });
          }
        }}
      >
        <RadioButton
          label="High priority (recommended)"
          description={`~1 block · ${fast} sats/vB`}
          Icon={Rocket2}
          value={fast.toString()}
        />
        <RadioButton
          label="Low priority"
          description={`~6 blocks · ${slow} sats/vB`}
          Icon={Truck1}
          value={fast !== slow && slow.toString()}
          disabled={fast === slow}
        />
      </RadioGroup>
      {maxFeeExceeded && (
        <span className=" text-xs text-red-500">
          {`Total fees cannot exceed ${satoshisToBitcoins(
            BITCOIND_MAX_FEE_SATS
          )} BTC. Please contact Client Services for help with resolving this issue.`}
        </span>
      )}
    </>
  );
};
