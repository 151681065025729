import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import CopyToClipboard from "react-copy-to-clipboard";

import FAIcon from "./FAIcon";

const Copyable = React.forwardRef(
  ({ newline = false, icon = true, grid = false, text, children }, ref) => {
    const [showCopied, setShowCopied] = useState(false);
    const [showCopiedTimer, setShowCopiedTimer] = useState(null);

    useEffect(() => {
      return () => {
        clearTimeout(showCopiedTimer);
      };
    });

    const onCopy = () => {
      setShowCopied(true);
      setShowCopiedTimer(
        setTimeout(() => {
          setShowCopied(false);
        }, 1000)
      );
    };

    const faIconName = showCopied ? "clipboard-check" : "clipboard";
    const iconName = showCopied ? "copied" : "uncopied";
    const className = showCopied ? "text-success" : "";
    const Icon = (
      <span className={className} data-testid={`${iconName}`}>
        <FAIcon name={faIconName} />
      </span>
    );

    if (!text || text.length <= 0) return null;

    return (
      <CopyToClipboard text={text} onCopy={onCopy} options={{ format: "text/plain" }} ref={ref}>
        {grid ? (
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            alignContent="center"
            wrap="nowrap"
          >
            <Grid item>{children || text}</Grid>
            <Grid item>{icon && Icon}</Grid>
          </Grid>
        ) : (
          <span className="copyable">
            {children || text}
            {newline && <br />}
            {icon && <span>&nbsp;</span>}
            {icon && Icon}
          </span>
        )}
      </CopyToClipboard>
    );
  }
);

export default Copyable;
