import { Button } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { getOwnVaults } from "Redux/selectors";
import { useCurrentOrgDeprecated } from "Redux/selectors/hooks";
import { useIraOrg } from "Shared/api/hooks";
import { AppModalManager } from "Shared/components/Modals";

import { NoVaultsIraModal } from "./NoVaultsIraModal";

type FundIraButtonProps = {
  disabled?: boolean;
};
export const FundIraButton = ({ disabled }: FundIraButtonProps) => {
  const org = useCurrentOrgDeprecated();

  const vaults = useSelector(getOwnVaults);
  const iraOrgQuery = useIraOrg(org.uuid, {
    enabled: !!org.uuid,
  });

  if (org.state !== "live") return null;

  const handleClick = () => {
    if (vaults?.length === 0) {
      AppModalManager.open(NoVaultsIraModal);
    } else {
      window.open("https://unchained.com/ira-forms", "_blank");
    }
  };

  return (
    <Button disabled={disabled} onClick={handleClick} fullWidth type="darkSecondary">
      Fund IRA
    </Button>
  );
};
