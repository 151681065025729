import React from "react";

import { useBreakpoint } from "@unchained/component-library";

import styles from "./BreakpointTracker.module.scss";

/**
 * A simple devtool
 * Renders -- only on dev --  a small circular element in the bottom left of the screen,
 * which displays the active breakpoint (eg "xs") as it changes.
 **/
export const BreakpointTracker = () => {
  const bp = useBreakpoint();

  if (process.env.NODE_ENV !== "development" || !process.env.REACT_APP_BP_TRACKER) return null;

  return <aside className={styles.tracker}>{bp.name}</aside>;
};
