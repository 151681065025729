import React from "react";

import PropTypes from "prop-types";

import { formatPercentage } from "Utils/strings";
import { parseTimestamp, readableTime } from "Utils/time";

import { Verification, verificationProptypes } from "./verifications";

export function vaultThresholdChangeProptypes(admin) {
  return {
    uuid: PropTypes.string.isRequired,
    verification: PropTypes.shape(verificationProptypes()).isRequired,
    comparison_verification: PropTypes.shape(verificationProptypes()),
    new_threshold: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    vault: PropTypes.object,
  };
}

export function VaultThresholdChange(data) {
  if (data == null) {
    return data;
  }
  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);

  data.newThresholdWithUnit = formatPercentage(data.new_threshold);

  if (data.verification) {
    data.verification = Verification(data.verification);
  }

  return data;
}
