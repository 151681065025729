import React, { useContext, useEffect, useState } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Checkbox,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import Big from "big.js";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import {
  getBtcAmountFromUsd,
  getUsdAmountFromBtc,
} from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";
import { getConfig } from "Utils/config";
import { formatCurrency } from "Utils/strings";

import { getMaxSpendable } from "./functions";

enum Focus {
  NONE,
  BTC,
  USD,
}

const limitDecimals = (value: string, decimalPlaces: number) => {
  const workingValue = Number(value);
  if (isNaN(workingValue)) {
    return "";
  }
  if (value.split(".")[1]?.length > decimalPlaces) {
    return workingValue.toFixed(decimalPlaces);
  }
  return value;
};

export const AmountInput = ({ estimatedFeeBitcoin }: { estimatedFeeBitcoin: string }) => {
  const {
    BTC: { value: usdPrice },
  } = useMemoizedState("crypto.prices.current");
  const vaultBalance: number = useMemoizedState("vaults.vaultShow.vault.balance");
  const { amount, feeRate, isMaxSpend, isBatchSpendFlow, maxSpendLocked, setState } =
    useContext(WithdrawModalContext);
  const [hasFocus, setHasFocus] = useState<Focus>(Focus.NONE);
  const [usdAmount, setUsdAmount] = useState(getUsdAmountFromBtc(amount, usdPrice));
  const [validationMessage, setValidationMessage] = useState("");

  const maxSpendable = getMaxSpendable(vaultBalance, estimatedFeeBitcoin, isMaxSpend);
  useEffect(() => {
    // After switching to max spend (checkbox checked), set amount and
    // usdAmount. The fee rate requires a recalc of these values.
    if (isMaxSpend) {
      const newAmount = maxSpendable.toFixed(8);
      setState({ amount: newAmount });
      setUsdAmount(getUsdAmountFromBtc(newAmount, usdPrice));
    }
  }, [feeRate, isMaxSpend]);

  useEffect(() => {
    // Handles BTC/USD change
    if (hasFocus !== Focus.USD) {
      // Only set USD if BTC input is being edited
      setUsdAmount(getUsdAmountFromBtc(amount, usdPrice));
    } else if (hasFocus === Focus.USD) {
      // Only set BTC if USD input is being edited
      setState({ amount: getBtcAmountFromUsd(usdAmount, usdPrice) });
    }
  }, [amount, usdAmount, usdPrice, setState]);

  useEffect(() => {
    // Validate input
    if (amount === "") {
      setValidationMessage("");
      return;
    }

    if (Big(amount).lte(0)) {
      setValidationMessage("Must be greater than zero");
      return;
    }

    // Case: Exceeds balance minus fees
    if (!isMaxSpend && Big(amount).gt(Big(maxSpendable))) {
      setValidationMessage(
        `Must not be more than ${formatCurrency(
          maxSpendable,
          8,
          false,
          true
        )} or use max withdrawal`
      );
      return;
    }

    // Case: Would create dust
    if (!isMaxSpend && Big(amount).lt(getConfig("wallet.dust_limit.BTC"))) {
      setValidationMessage("Transaction would create dust");
      return;
    }

    // Everything is good
    setValidationMessage("");
  }, [amount, maxSpendable, estimatedFeeBitcoin, isMaxSpend]);

  // This input type is temporary until HeroInput can replace it. This is mostly
  // copied over from the old OutflowWizard.
  return (
    <>
      <div className="grid grid-cols-2 gap-x-4">
        <FormGroup>
          <FormLabel className="mb-0 text-left text-xs">Amount</FormLabel>
          <TextField
            data-testid="btc-amount"
            label=""
            InputProps={{
              endAdornment: <InputAdornment position="end">BTC</InputAdornment>,
            }}
            value={amount}
            onFocus={() => setHasFocus(Focus.BTC)}
            onBlur={() => {
              setHasFocus(Focus.NONE);
            }}
            onChange={e => setState({ amount: limitDecimals(e.target.value, 8) })}
            error={Boolean(validationMessage)}
            disabled={isMaxSpend || isBatchSpendFlow}
            margin="dense"
            InputLabelProps={{ shrink: false }}
            autoFocus={true}
            inputProps={{ type: "number", tabIndex: 1 }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel className="mb-0 text-right text-xs">
            Max withdrawal{" "}
            <Checkbox
              className="mb-0 p-0 text-xs"
              value={isMaxSpend}
              onChange={e => setState({ isMaxSpend: e.target.checked })}
              disabled={isBatchSpendFlow || maxSpendLocked}
              checked={isMaxSpend}
              icon={<CheckBoxOutlineBlankIcon className="mb-0 p-0 text-sm" />}
              checkedIcon={<CheckBoxIcon className="mb-0 p-0 text-sm" />}
            />
          </FormLabel>
          <TextField
            data-testid="usd-amount"
            label=""
            InputProps={{
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
            value={usdAmount}
            onFocus={() => setHasFocus(Focus.USD)}
            onBlur={() => {
              setUsdAmount(Number(usdAmount).toFixed(2));
              setHasFocus(Focus.NONE);
            }}
            onChange={e => setUsdAmount(limitDecimals(e.target.value, 2))}
            error={Boolean(validationMessage)}
            disabled={isMaxSpend || isBatchSpendFlow}
            margin="dense"
            InputLabelProps={{ shrink: false }}
            inputProps={{ type: "number", tabIndex: 2 }}
          />
        </FormGroup>
      </div>
      <FormHelperText className="-mt-4 min-h-[1.2rem] text-center">
        {validationMessage}
      </FormHelperText>
    </>
  );
};
