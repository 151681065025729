import { SummaryRowDatum, summaryRowDatum } from "Components/Shared/Elements/Summary/SummaryTable";
import { docTitleMap } from "Shared/api";
import { Document as IraDocument } from "Specs/v1/getIraOrg/200";
import { Document } from "Specs/v1/getOrg/200";

export const docData = (docs: (Document | IraDocument)[], orgUuid: string): SummaryRowDatum[] =>
  docs.map(doc =>
    summaryRowDatum(doc, "name", {
      title: doc?.title,
      download: { label: docTitleMap[doc?.title], orgUuid },
    })
  );
