import { useContext } from "react";

import { ButtonProps, WizardStepperContext } from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { Formik, FormikTextField } from "Components/FormFields";
import { FormikCountrySelector } from "Components/FormFields/FormikAddressForm/FormikCountrySelector";
import { ManifestStep } from "Routes/onboard/(shared)";
import { OnboardBasicContext, useWrapManifestMutation } from "Routes/onboard/(utils)";
import { usePatchPerson } from "Shared/api";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { initSsn, validateSsn } from "../(individual)/individualOrgValsAndValidations";

export const SocialSecurityNumber = ({
  org,
  canSkipSsn,
}: {
  org: CompleteOrg | IraOrg;
  canSkipSsn?: boolean;
}) => {
  let { uuid } = useParams<{ uuid: string }>();
  if (!uuid) uuid = (org as CompleteOrg).uuid;
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: uuid, isIra: "iraPlan" in org });
  const initVals = initSsn(org);
  const updatePrincipalPerson = wrapManifestMutation(usePatchPerson());

  const context = useContext(OnboardBasicContext);
  const { goTo } = useContext(WizardStepperContext);

  const personUuid =
    "iraPlan" in org
      ? org.entity.principals.find(p => p.title === "Owner").person.uuid
      : org.person.uuid;

  const onSubmit = (values: typeof initVals) => {
    updatePrincipalPerson.mutate({ ...values, uuid: personUuid });
  };

  const onSkip = () => {
    if (context) {
      context.skipSSN.set(true);
    }

    goTo("Review and finish");
  };

  const skipButton = {
    children: "Skip and finish",
    onClick: onSkip,
  };

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={onSubmit}
      validationSchema={validateSsn}
      debugName="SocialSecurityNumber"
    >
      {({ values, handleSubmit }) => {
        const actions: [ButtonProps] | [ButtonProps, ButtonProps] = [
          {
            children: "Continue",
            onClick: handleSubmit,
            buttonType: "submit",
          },
        ];

        if (canSkipSsn) {
          actions.push(skipButton);
        }

        return (
          <ManifestStep
            width="max-w-xl"
            actions={actions}
            title="Social security number or TIN"
            description="Enter your Social Security number to get access to our entire suite of financial services."
          >
            <div className="flex w-full flex-col items-center">
              <FormikCountrySelector
                className="mb-4"
                name="nationality"
                label="Nationality"
                useFullCountry={false}
              />

              {values.nationality && (
                <FormikTextField
                  name="ssn"
                  label={
                    values.nationality === "US"
                      ? "Social security number"
                      : "Tax identification number"
                  }
                  fullWidth
                />
              )}

              {canSkipSsn && (
                <p className="my-2 text-center">
                  You can skip this step or complete it later if you just want to use a vault.
                </p>
              )}
            </div>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
