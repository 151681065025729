import { createContext, Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { Modal, ModalHeader, ModalTitle } from "@unchained/component-library";

import { useGetSlice } from "Shared/api";
import { WalletUuid } from "Shared/api/walletApi";
import { AppModalManager } from "Shared/components/Modals";
import { AccountKey } from "Specs/v1/getSliceAccountKeys/200";
import { ProductUuid } from "Specs/v1/getWallets/params/path";

import { getSteps, ManifestTypes } from "./getSteps";

type BtcAddress = string;
type voidFunction = () => void;

interface ConfirmOnDeviceContextType {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  setDeviceType: Dispatch<SetStateAction<ManifestTypes | null>>;
  setAccountKey: Dispatch<SetStateAction<AccountKey>>;
  deviceType: ManifestTypes | null;
  address: BtcAddress;
  accountKey: AccountKey;
  walletUuid: WalletUuid;
  productUuid: ProductUuid;
  handleNext: voidFunction;
  relativeBip32Path: string;
  onDone: () => void;
}

export const ConfirmOnDeviceContext = createContext<
  ConfirmOnDeviceContextType | Record<string, never>
>({});

interface ConfirmOnDeviceWizardProps {
  address: BtcAddress;
  // Sometimes when the user is done confirming the address
  // we might want to take them back to what they were doing before
  // which isn't always on the main page, but might be another modal
  // for instance.
  onDone?: () => void;
  onDismiss?: () => void;
}
export const ConfirmOnDeviceWizard = ({
  address,
  // defaults to just closing the modal
  onDone = () => AppModalManager.close(),
  onDismiss = () => AppModalManager.close(),
}: ConfirmOnDeviceWizardProps) => {
  const [deviceType, setDeviceType] = useState<ManifestTypes | null>(null);
  const [accountKey, setAccountKey] = useState<AccountKey | null>(null);

  const getSliceQuery = useGetSlice(address);

  /** start modal settings */
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const steps = useMemo(() => getSteps(deviceType), [deviceType]);
  const step = steps[currentStepIndex];

  const onBack = () => {
    if (step.handleBack) step.handleBack(currentStepIndex, setStepIndex);
    else if (currentStepIndex === 0) onDone();
    else setStepIndex(currentStepIndex - 1);
  };

  const headerProps =
    // Don't show the back button on the first or last step
    currentStepIndex >= 0 && currentStepIndex < steps.length - 1
      ? { back: { text: "Back", onClick: onBack } }
      : {};

  // make sure we can't set the step index past the end of the steps
  const setStepIndex = useCallback(
    (index: number) => {
      if (index >= steps.length) {
        console.error(`Invalid step index: ${index}`);
      } else {
        setCurrentStepIndex(index);
      }
    },
    [steps.length]
  );

  const handleNext = useCallback(() => {
    const nextStep = currentStepIndex + 1;
    if (nextStep < steps.length) setStepIndex(currentStepIndex + 1);
  }, [currentStepIndex, setStepIndex, steps.length]);

  const { Component: CurrentStep } = step;
  /** end modal settings **/

  const context = useMemo<ConfirmOnDeviceContextType>(
    () => ({
      stepIndex: currentStepIndex,
      setStepIndex,
      setDeviceType,
      deviceType,
      setAccountKey,
      accountKey,
      address,
      relativeBip32Path: getSliceQuery.data?.relativeBip32Path || "",
      walletUuid: getSliceQuery.data?.walletUuid || "",
      productUuid: getSliceQuery.data?.productUuid || "",
      handleNext,
      onDone,
    }),
    [
      currentStepIndex,
      setStepIndex,
      deviceType,
      accountKey,
      address,
      getSliceQuery.data,
      handleNext,
      onDone,
    ]
  );

  const modalSizes = {
    sm: "md:w-[25.6rem]",
    md: "md:w-[31.5rem]",
    lg: "md:w-[44.6rem]",
  };

  return (
    <ConfirmOnDeviceContext.Provider value={context}>
      <Modal onDismiss={onDismiss} className={modalSizes[step.modalSize || "md"]}>
        <ModalHeader {...headerProps}>
          <ModalTitle subtitle={step.subtitle}>
            {!step.hideTitle && "Confirm address on device"}
          </ModalTitle>
        </ModalHeader>
        <CurrentStep />
      </Modal>
    </ConfirmOnDeviceContext.Provider>
  );
};
