import {
  RESET_VAULT_SHOW_USER,
  SET_VAULT_SHOW_ACCOUNT_KEYS,
  SET_VAULT_SHOW_ROLE,
  SET_VAULT_SHOW_STATUS,
  SET_VAULT_SHOW_VAULT_DATA,
  SET_VAULT_SHOW_NAME,
  SET_VAULT_SHOW_THRESHOLD,
  SET_VAULT_SHOW_CLOSE_TYPE,
  SET_VAULT_SHOW_NOTE,
} from "Actions/vaultActions/vaultShowActions";

const initialState = {
  vault: {
    name: "",
    confirmedValue: null,
    unit: "",
    balance: "",
    wallet: {},
    allowed_actions: [],
  },
  status: "pending",
  viewerRole: "",
  newName: "",
  vaultKeys: {},
  threshold: "",
  closeType: "",
};

export const vaultShowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_VAULT_SHOW_VAULT_DATA: {
      return {
        ...state,
        vault: payload,
      };
    }

    case SET_VAULT_SHOW_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case SET_VAULT_SHOW_ROLE: {
      return {
        ...state,
        viewerRole: payload,
      };
    }

    case SET_VAULT_SHOW_ACCOUNT_KEYS: {
      return {
        ...state,
        vaultKeys: payload,
      };
    }

    case RESET_VAULT_SHOW_USER: {
      return {
        ...initialState,
      };
    }

    case SET_VAULT_SHOW_NAME: {
      return {
        ...state,
        ...{
          vault: {
            ...state.vault,
            ...{ name: payload },
          },
        },
      };
    }

    case SET_VAULT_SHOW_NOTE: {
      return {
        ...state,
        ...{
          vault: {
            ...state.vault,
            note: payload,
          },
        },
      };
    }

    case SET_VAULT_SHOW_THRESHOLD: {
      return {
        ...state,
        ...{ threshold: payload },
      };
    }

    case SET_VAULT_SHOW_CLOSE_TYPE: {
      return {
        ...state,
        ...{ closeType: payload },
      };
    }

    default:
      return state;
  }
};
