import { Outlet, useLocation } from "react-router-dom";

import { NavigationProvider } from "Contexts/Navigation";

import { Nav } from "./Nav";

export const Layout = () => {
  const location = useLocation();

  const fullPagePages = ["/onboard", "/inheritance"];

  // The typical layout with sidebar doesn't apply to some pages
  if (fullPagePages.some(p => location.pathname.startsWith(p))) {
    return (
      <div className="h-screen w-screen">
        <Outlet />
      </div>
    );
  }

  return (
    <div className="relative flex min-h-screen">
      <NavigationProvider>
        <Nav mobile />
        <Nav />
      </NavigationProvider>
      <Outlet />
    </div>
  );
};
