export function flash(level, message, duration) {
  return {
    type: "FLASH/NEW_MESSAGE",
    level: level,
    message: message,
    duration: duration,
  };
}

export function flashClose() {
  return {
    type: "FLASH/CLOSE_MESSAGE",
  };
}
