import { createSelector } from "reselect";

import { btcAccountKeys } from "../keys/accountKeySelectors";

const userKey1 = state => state.vaults.vaultNewWizard.userKey1;
const userKey2 = state => state.vaults.vaultNewWizard.userKey2;
const selectingKey = state => state.vaults.vaultNewWizard.selectingKey;
const currency = state => state.vaults.vaultNewWizard.vaultCurrency;

const getKeysBasedOnCurrency = createSelector(
  currency,
  btcAccountKeys,
  (currency, btcKeys, ethKeys) => {
    if (currency === "BTC") {
      return btcKeys;
    } else {
      return ethKeys;
    }
  }
);

const getOnlyUsableKey = createSelector(getKeysBasedOnCurrency, assetKey => {
  return assetKey.filter(
    ak =>
      ak.is_valid &&
      !ak.distinguished &&
      (ak.allowed_actions || []).includes("use") &&
      !ak.current_outbound_rekey
  );
});

export const getKeyOptionsForNewVaults = createSelector(
  getOnlyUsableKey,
  userKey1,
  userKey2,
  selectingKey,
  (usableKeys, key1, key2, selectingKey) => {
    if (selectingKey === "userKey1" && key2.uuid) {
      return usableKeys.filter(uk => uk.uuid !== key2.uuid);
    } else if (selectingKey === "userKey2" && key1 && key1.uuid) {
      return usableKeys.filter(uk => uk.uuid !== key1.uuid);
    } else return usableKeys;
  }
);
