import { useContext } from "react";

import { Link } from "Components/Link";
import { LandingPage } from "Routes/onboard/(shared)/LandingPage";
import { OnboardBasicContext } from "Routes/onboard/(utils)";

export const CreateYourProfile = () => {
  const context = useContext(OnboardBasicContext);
  return (
    <LandingPage
      title={[
        context.from ? undefined : "Your email is confirmed.",
        "Complete your profile information",
      ]
        .filter(Boolean)
        .join(" ")}
      description="Enter your personal information to setup your account. Any progress you make will be saved, so you can always come back later."
      infoCellDescription={
        <>
          You'll be asked to provide standard personal details and a photo of a legal document that
          proves your identity. Here's{" "}
          <Link to="https://help.unchained.com/what-personal-information-is-needed-to-set-up-a-vault">
            why we need this info.
          </Link>
        </>
      }
      timeCellDescription="Adding your basic info is a quick 2-3 minute process."
      action={{ children: "Create your profile" }}
    />
  );
};
