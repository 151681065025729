import get from "lodash/get";

import { requestFailed, requestSuccess, submittingRequest } from "Actions/common";
import { OrgAPI } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";
import { LoanAPI } from "Shared/api/loanApi";

import { flash } from "../flash";

export const SET_LOAN_SHOW_LOAN_DATA = "SET_LOAN_SHOW_LOAN_DATA";
export const SET_LOAN_SHOW_STATUS = "SET_LOAN_SHOW_STATUS";
export const SET_LOAN_SHOW_ROLE = "SET_LOAN_SHOW_ROLE";
export const SET_LOAN_SHOW_ACCOUNT_KEYS = "SET_LOAN_SHOW_ACCOUNT_KEYS";
export const RESET_LOAN_SHOW_USER = "RESET_LOAN_SHOW_USER";
export const SET_LOAN_SHOW_CLOSE_TYPE = "SET_LOAN_SHOW_CLOSE_TYPE";
export const SET_LOAN_SHOW_NEW_RETURN_ADDRESS = "SET_LOAN_SHOW_NEW_RETURN_ADDRESS";
export const SET_LOAN_SHOW_OLD_RETURN_ADDRESS = "SET_LOAN_SHOW_OLD_RETURN_ADDRESS";

export const SET_LOAN_SHOW_LENDERS = "SET_LOAN_SHOW_LENDERS";
export const SET_LOAN_SHOW_LENDERS_STATUS = "SET_LOAN_SHOW_LENDERS_STATUS";
export const SET_LOAN_SHOW_UPDATE_LENDER = "SET_LOAN_SHOW_UPDATE_LENDER";
export const SET_LOAN_SHOW_UPDATE_NOTE = "SET_LOAN_SHOW_UPDATE_NOTE";

/**
 * Set Individual Loan action for the current user
 * @param userLoan - retrieve user loan via an api call
 */

export const setLoanShowLoanDataAction = userLoan => ({
  type: SET_LOAN_SHOW_LOAN_DATA,
  payload: userLoan,
});

/**
 * Set Loan API request status action
 * @param status - set the api call status - action triggered based on current loan in store & new loan retrieved
 *
 */

export const setLoanShowStatusAction = status => ({
  type: SET_LOAN_SHOW_STATUS,
  payload: status,
});

/**
 * Set the view role for the show page as requested
 * @param role - view role as requested enum: [user, admin, arbiter, signer]
 *
 */

export const setLoanShowRoleAction = role => ({
  type: SET_LOAN_SHOW_ROLE,
  payload: role,
});

export const setLoanShowKeysAction = accountKeys => ({
  type: SET_LOAN_SHOW_ACCOUNT_KEYS,
  payload: accountKeys,
});

export const resetLoanShowAction = () => ({
  type: RESET_LOAN_SHOW_USER,
  payload: null,
});

export const setLoanShowCloseTypeAction = type => ({
  type: SET_LOAN_SHOW_CLOSE_TYPE,
  payload: type,
});

export const setLoanShowNewReturnAddressAction = address => ({
  type: SET_LOAN_SHOW_NEW_RETURN_ADDRESS,
  payload: address,
});

export const setLoanShowOldReturnAddressAction = address => ({
  type: SET_LOAN_SHOW_OLD_RETURN_ADDRESS,
  payload: address,
});

export const setLoanShowLendersAction = lenders => ({
  type: SET_LOAN_SHOW_LENDERS,
  payload: lenders,
});

export const setLoanShowLendersStatusAction = status => ({
  type: SET_LOAN_SHOW_LENDERS_STATUS,
  payload: status,
});

/**
 * Get Individual Loan action for the current user
 * @param uuid - specific uuid for loan being requested
 */

export const getLoanShowUserAction = uuid => {
  return async dispatch => {
    try {
      // dispatch(setLoanShowRoleAction('user'));
      const loan = await LoanAPI.Get(uuid);
      dispatch(setLoanShowLoanDataAction(loan));
      dispatch(setLoanShowStatusAction(REQUEST_STATUS.SUCCESS));
      // dispatch(setLoanShowRoleAction('user'));
      return loan;
    } catch (e) {
      const status = get(e, "response.status", 0);
      switch (status) {
        case 403:
          dispatch(setLoanShowStatusAction(REQUEST_STATUS.FORBIDDEN));
          break;
        case 404:
          dispatch(setLoanShowStatusAction(REQUEST_STATUS.NOT_FOUND));
          break;
        default:
          dispatch(setLoanShowStatusAction(REQUEST_STATUS.ERROR));
          break;
      }
    }
  };
};

export const getLendersAction = () => {
  return async dispatch => {
    try {
      dispatch(setLoanShowLendersStatusAction(REQUEST_STATUS.PENDING));
      const lenders = await OrgAPI.GetLenders();
      dispatch(setLoanShowLendersAction(lenders));
      dispatch(setLoanShowLendersStatusAction(REQUEST_STATUS.SUCCESS));
    } catch (e) {
      const status = get(e, "response.status", 0);
      switch (status) {
        case 403:
          dispatch(setLoanShowLendersStatusAction(REQUEST_STATUS.FORBIDDEN));
          break;
        case 404:
          dispatch(setLoanShowLendersStatusAction(REQUEST_STATUS.NOT_FOUND));
          break;
        default:
          dispatch(setLoanShowLendersStatusAction(REQUEST_STATUS.ERROR));
          break;
      }
    }
  };
};

export const refreshLoanShowAction = loanUUID => async dispatch => {
  dispatch(setLoanShowStatusAction("pending"));
  await dispatch(getLoanShowUserAction(loanUUID));
};

export const submitLoanApproveAction = (context, loanUUID, data) => async dispatch => {
  const name = "APPROVE_REQUEST";
  dispatch(submittingRequest(context, name));
  try {
    const response = await LoanAPI.ApproveLoan(loanUUID, data);
    dispatch(requestSuccess(context, name));
    dispatch(flash("success", response.data.message));
    dispatch(refreshLoanShowAction(loanUUID));
  } catch (e) {
    dispatch(flash("error", e.response.data.message));
    dispatch(requestFailed(context, name, e.response.data.errors));
  }
};

export const submitLoanDeny = (context, loanUUID, data) => async dispatch => {
  const name = "DENY_REQUEST";
  dispatch(submittingRequest(context, name));
  try {
    const response = await LoanAPI.DenyLoan(loanUUID, data);
    dispatch(requestSuccess(context, name));
    dispatch(flash("success", response.data.message));
    dispatch(refreshLoanShowAction(loanUUID));
  } catch (e) {
    dispatch(flash("error", e.response.data.message));
    dispatch(requestFailed(context, name, e.response.data.errors));
  }
};

const setLoanShowUpdateLenderAction = data => ({
  type: SET_LOAN_SHOW_UPDATE_LENDER,
  payload: data,
});

export const setLoanLenderAction = (loanUUID, data) => async dispatch => {
  const { message } = await LoanAPI.SetLender(loanUUID, data);
  dispatch(setLoanShowUpdateLenderAction(data));
  dispatch(flash("success", message));
};

export const setLoanShowUpdateNoteAction = note => ({
  type: SET_LOAN_SHOW_UPDATE_NOTE,
  payload: note,
});
