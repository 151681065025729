import {
  AddOrUpdatePersonAddress200,
  AddOrUpdatePersonAddressRequest,
  CreatePerson200,
  CreatePersonRequest,
  PatchPerson200,
  PatchPersonRequest,
  PutPersonCitizenship200,
  PutPersonEmployment200,
} from "Specs/v1";
import { Citizenship, Employment } from "Specs/v1/getIraOrg/200";

import { ApiType } from "./apiType";
import { DocLabel } from "./hooks";

export const PersonAPIFactory = (API: ApiType) => ({
  PutCitizenship: async (uuid: string, citizenship: Citizenship) =>
    (await API.Put<PutPersonCitizenship200>(`/persons/${uuid}/citizenship`, citizenship)).data,

  PutEmployment: async (uuid: string, employment: Employment) =>
    (await API.Put<PutPersonEmployment200>(`/persons/${uuid}/employment`, employment)).data,

  Create: async (person: CreatePersonRequest) =>
    (await API.Post<CreatePerson200>(`/persons`, person)).data,

  Patch: async (uuid: string, person: PatchPersonRequest) =>
    (await API.Patch<PatchPerson200>(`/persons/${uuid}`, person)).data,

  CreateOrUpdatePersonDocuments: async (
    uuid: string,
    documentsObject: { state_id?: Document; death_certificate?: Document }
  ) => (await API.PutFiles(`/persons/${uuid}/documents`, documentsObject)).data,

  DeleteDoc: async (uuid: string, label: DocLabel) =>
    await API.Delete(`/persons/${uuid}/documents/${label}`),

  DownloadDocument: async (uuid: string, label: string) =>
    await API.Download(`/persons/${uuid}/documents/${label}`),

  UpdatePersonAddress: async (
    uuid: string,
    role: string,
    address: AddOrUpdatePersonAddressRequest
  ) =>
    (await API.Put<AddOrUpdatePersonAddress200>(`/persons/${uuid}/addresses/${role}`, address))
      .data,
});
