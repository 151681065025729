import { TradeStatement } from "Specs/v1/getTradeStatements/200";

import {
  CLEAR_MODAL_STATE,
  CLEAR_MODAL_STATE_RETAIN_DESTINATION_AND_AMOUNT,
  CLEAR_TRADING_STATE,
  CLOSE_ONBOARDING_TRADING_CARD,
  SET_AMOUNT,
  SET_AMOUNT_CURRENCY,
  SET_BUY_BITCOIN_INFO,
  SET_BUY_BITCOIN_REJECTED,
  SET_BUY_BITCOIN_SUCCESS,
  SET_IS_GET_BUY_BITCOIN_INFO_ERROR,
  SET_IS_GET_BUY_BITCOIN_INFO_LOADING,
  SET_IS_TRADE_STATEMENT_DOWNLOADING,
  SET_IS_TRADING_FEATURE_AVAILABLE,
  SET_MODAL_STATUS,
  SET_OUTSTANDING_TRADE_IDS,
  SET_STREAMING_QUOTE_RESPONSE,
  SET_STREAMING_QUOTE_RESPONSE_ISLOADING,
  SET_STREAMING_QUOTE_STATUS,
  SET_TRADE_CONFIRMATION_STATUS,
  SET_TRADE_STATEMENTS,
  SET_TRADING_JWT,
  SET_VAULT,
  SET_WEB_SOCKET_STATUS,
  STREAMING_QUOTE_CLOSED,
  STREAMING_QUOTE_OPEN,
  STREAMING_QUOTE_RESPONSE,
  TRADE_CONFIRMATION_ACCEPTED,
  TRADE_RESPONSE,
} from "./buyBitcoinConstants";
import {
  BuyBitcoinInfo,
  BuyDestination,
  ClearModalStateAction,
  ClearModalStateRetainVaultAndAmountAction,
  ClearTradingStateAction,
  CloseOnboardingTradingCard,
  Currency,
  SetAmountAction,
  SetAmountCurrencyAction,
  SetBuyBitcoinInfoAction,
  SetBuyBitcoinRejectedAction,
  SetBuyBitcoinSuccessAction,
  SetIsGetBuyBitcoinInfoErrorAction,
  SetIsGetBuyBitcoinInfoLoadingAction,
  SetIsTradeStatementDownloading,
  SetIsTradingFeatureAvailableAction,
  SetModalStatusAction,
  SetOutstandingTradeIds,
  SetStreamingQuoteResponseAction,
  SetStreamingQuoteResponseIsLoadingAction,
  SetStreamingQuoteStatusAction,
  SetTradeConfirmationStatusAction,
  SetTradeStatements,
  SetTradingJwtAction,
  SetVaultAction,
  SetWebSocketStatusAction,
  StreamingQuote,
  StreamingQuoteStatus,
  TradeResponseSuccess,
  TradeStatus,
  TradingModalStatus,
  TradingStatus,
  TradingWebSocketStatus,
} from "./types";

export const updateAmount = (amount: string): SetAmountAction => ({
  type: SET_AMOUNT,
  payload: { amount },
});

export const updateVault = (destination: BuyDestination): SetVaultAction => ({
  type: SET_VAULT,
  payload: { destination },
});

export const updateAmountCurrency = (amountCurrency: Currency): SetAmountCurrencyAction => ({
  type: SET_AMOUNT_CURRENCY,
  payload: { amountCurrency },
});

export const clearModalState = (): ClearModalStateAction => ({
  type: CLEAR_MODAL_STATE,
  payload: {},
});

export const clearModalStateRetainVaultAndAmount =
  (): ClearModalStateRetainVaultAndAmountAction => ({
    type: CLEAR_MODAL_STATE_RETAIN_DESTINATION_AND_AMOUNT,
    payload: {},
  });

export const clearTradingState = (): ClearTradingStateAction => ({
  type: CLEAR_TRADING_STATE,
  payload: {},
});

export const setBuyBitcoinInfo = (info: BuyBitcoinInfo): SetBuyBitcoinInfoAction => ({
  type: SET_BUY_BITCOIN_INFO,
  payload: { ...info },
});

export const setTradingJwt = (jwt: string): SetTradingJwtAction => ({
  type: SET_TRADING_JWT,
  payload: { jwt },
});

export const setIsGetBuyBitcoinInfoLoading = (
  isLoading: boolean
): SetIsGetBuyBitcoinInfoLoadingAction => ({
  type: SET_IS_GET_BUY_BITCOIN_INFO_LOADING,
  payload: { isLoading },
});

export const setIsGetBuyBitcoinInfoError = (
  isError: boolean
): SetIsGetBuyBitcoinInfoErrorAction => ({
  type: SET_IS_GET_BUY_BITCOIN_INFO_ERROR,
  payload: { isError },
});

export const setIsTradingFeatureAvailable = (
  tradingStatus: TradingStatus
): SetIsTradingFeatureAvailableAction => ({
  type: SET_IS_TRADING_FEATURE_AVAILABLE,
  payload: { ...tradingStatus },
});

export const setStreamingQuoteResponse = (
  streamingQuote: StreamingQuote
): SetStreamingQuoteResponseAction => ({
  type: SET_STREAMING_QUOTE_RESPONSE,
  payload: { ...streamingQuote },
});

export const setStreamingQuoteResponseIsLoading = (
  isLoading: boolean
): SetStreamingQuoteResponseIsLoadingAction => ({
  type: SET_STREAMING_QUOTE_RESPONSE_ISLOADING,
  payload: { isLoading },
});

export const setBuyBitcoinSuccess = (
  tradeResponse: TradeResponseSuccess
): SetBuyBitcoinSuccessAction => ({
  type: SET_BUY_BITCOIN_SUCCESS,
  payload: { ...tradeResponse },
});

export const setBuyBitcoinRejected = (): SetBuyBitcoinRejectedAction => ({
  type: SET_BUY_BITCOIN_REJECTED,
  payload: {},
});

export const handleTradeWebSocketMessages = (rawResponse, dispatch) => {
  const response = JSON.parse(rawResponse.data);

  if (response.messageType === STREAMING_QUOTE_RESPONSE) {
    dispatch(setStreamingQuoteResponseIsLoading(true));
    dispatch(setStreamingQuoteResponse(response));

    // keep isLoading state for half a second for longer changing price transition effect
    setTimeout(() => {
      dispatch(setStreamingQuoteResponseIsLoading(false));
    }, 500);
  } else if (response.messageType === TRADE_RESPONSE) {
    if (response.tradeStatus === TRADE_CONFIRMATION_ACCEPTED) {
      dispatch(setBuyBitcoinSuccess(response));
    } else {
      dispatch(setBuyBitcoinRejected());
    }
  }
};

export const setWebSocketStatus = (
  webSocketStatus: TradingWebSocketStatus
): SetWebSocketStatusAction => ({
  type: SET_WEB_SOCKET_STATUS,
  payload: { webSocketStatus },
});

export const setModalStatus = (status: TradingModalStatus): SetModalStatusAction => ({
  type: SET_MODAL_STATUS,
  payload: { status },
});

export const setTradeConfirmationStatus = (
  status: TradeStatus
): SetTradeConfirmationStatusAction => ({
  type: SET_TRADE_CONFIRMATION_STATUS,
  payload: { status },
});

export const setStreamingQuoteStatus = (
  streamingQuoteStatus: StreamingQuoteStatus
): SetStreamingQuoteStatusAction => ({
  type: SET_STREAMING_QUOTE_STATUS,
  payload: { streamingQuoteStatus },
});

export const setStreamingQuoteStatusFromStreamingQuoteResponse = (
  currentStreamingQuoteStatus: StreamingQuoteStatus,
  responseStreamingQuoteStatus: StreamingQuoteStatus | "OPEN"
) => {
  // If a user is trying to close the streaming quote
  // but the backend is still sending open quotes
  // keep the status as STREAMING_QUOTE_CLOSED.
  // If a user is not trying to close the quote then set the
  // quote to the backend response.
  if (
    responseStreamingQuoteStatus === "OPEN" &&
    currentStreamingQuoteStatus !== STREAMING_QUOTE_CLOSED
  ) {
    return STREAMING_QUOTE_OPEN;
  } else {
    return STREAMING_QUOTE_CLOSED;
  }
};

export const setTradeStatements = (tradeStatements: TradeStatement[]): SetTradeStatements => {
  return { type: SET_TRADE_STATEMENTS, payload: { tradeStatements } };
};

export const setIsTradeStatementDownloading = (
  isLoading: boolean,
  tradeId: string
): SetIsTradeStatementDownloading => {
  return { type: SET_IS_TRADE_STATEMENT_DOWNLOADING, payload: { isLoading, tradeId } };
};

export const closeOnboardingTradingCard = (): CloseOnboardingTradingCard => {
  return { type: CLOSE_ONBOARDING_TRADING_CARD, payload: null };
};

export const setOutstandingTradeIds = (outstandingTradeIds: string[]): SetOutstandingTradeIds => {
  return {
    type: SET_OUTSTANDING_TRADE_IDS,
    payload: { outstandingTradeIds },
  };
};
