import cn from "classnames";

import fullLogoWhite from "Assets/images/FullLogoWhite.svg";
import { Link } from "Components/Link";
import { Menu } from "Components/UCIcon";
import { useNavigationDispatch, useNavigationState } from "Contexts/Navigation";

export const MobileTopNav = () => {
  const { drawerOpen } = useNavigationState();
  const { setDrawerOpen } = useNavigationDispatch();

  return (
    <div
      className={cn(
        "absolute left-0 flex w-full items-center justify-between bg-primary-900 p-3 transition-transform duration-700 md:hidden",
        {
          "-translate-y-full": drawerOpen,
        }
      )}
    >
      <Link to="/home">
        <img src={fullLogoWhite} className="w-32" alt="Full logo white" />
      </Link>
      <button
        className="flex items-center justify-center rounded-md p-3 text-white hover:bg-primary-700"
        onClick={() => setDrawerOpen(true)}
      >
        <Menu />
      </button>
    </div>
  );
};
