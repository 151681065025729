import {
  RESET_DEVICE_CHECK_FLOW,
  SET_DEVICE_CHECK_BIP32_NODE,
  SET_DEVICE_CHECK_BIP32_PATH,
  SET_DEVICE_CHECK_CURRENCY,
  SET_DEVICE_CHECK_SUCCESS_MESSAGE,
  SET_DEVICE_CHECK_WALLET_ACTIVE,
  SET_DEVICE_CHECK_WALLET_TYPE,
  SET_DEVICE_CHECK_KEY_UUID,
} from "Actions/keyActions/deviceCheckWizardActions";
import { updatedField } from "Utils/errors";

const initialState = {
  keyName: "",
  walletCurrency: "",
  uuid: "",
  walletType: "",
  walletActive: false,
  bip32_path: "",
  bip32_node: {},
  successMessage: "You have successfully confirmed your key.",
};

export const deviceCheckWizardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DEVICE_CHECK_WALLET_TYPE: {
      return {
        ...state,
        walletType: payload,
      };
    }

    case SET_DEVICE_CHECK_CURRENCY: {
      return {
        ...state,
        walletCurrency: payload,
      };
    }

    case SET_DEVICE_CHECK_KEY_UUID: {
      return {
        ...state,
        uuid: payload,
      };
    }

    case SET_DEVICE_CHECK_BIP32_PATH: {
      return {
        ...state,
        bip32_path: payload,
      };
    }

    case SET_DEVICE_CHECK_BIP32_NODE: {
      return {
        ...state,
        bip32_node: payload,
      };
    }

    case SET_DEVICE_CHECK_WALLET_ACTIVE: {
      return {
        ...state,
        walletActive: payload,
      };
    }

    case SET_DEVICE_CHECK_SUCCESS_MESSAGE: {
      return {
        ...state,
        successMessage: payload,
      };
    }

    case RESET_DEVICE_CHECK_FLOW: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
