import { FC, useEffect } from "react";

import { DropdownProps, InputLabel, TextFieldProps } from "@unchained/component-library";
import cn from "classnames";
import { useField } from "formik";
import { camelCase as toCamelCase, snakeCase } from "lodash";
import * as yup from "yup";

import { Address as IraAddress } from "Specs/v1/getIraOrg/200";
import { Address } from "Specs/v1/getOrg/200";

import { FormikTextField } from "../FormikTextField";
import { countryStateData } from "./CountryStateData";
import { FormikCountrySelector } from "./FormikCountrySelector";
import { FormikStateSelector } from "./FormikStateSelector";
import { CountryCode } from "./data";

export const addrFields = ["line_1", "line_2", "line_3", "city", "zip", "state", "country"];
export const addrFieldsCamel = ["line1", "line2", "line3", "city", "zip", "state", "country"];

/** Exported to be included in the validation schema of parent forms. */
export const addressValidationSchema = (camelCase = false, isInternational = undefined) =>
  yup.object({
    [camelCase ? "line1" : "line_1"]: yup.string().required("Address line 1 required."),
    city: yup.string().required("City required."),
    state: yup.string().min(1).required("State required"),
    country: yup.string().min(1).required("Country required."),
    zip: yup
      .string()
      .min(1)
      .max(15)
      .required(`${isInternational ? "Postal" : "Zip"} required.`)
      .matches(/^[a-zA-Z0-9-\s]*$/, "Invalid character in zip."),
  });

/** IraAddresses are camelCased, so validate them accordingly */
export const addrComplete = (addr: Address | IraAddress) => {
  if ("line_1" in addr) {
    return addressValidationSchema().isValidSync(addr);
  } else {
    return addressValidationSchema(true).isValidSync(addr);
  }
};

/**
 * A physical address form insertable into any formik form.
 * Asks for country first, and then reveals rest of form.
 * Must be within a Formik context which defines address fields at a top level or under formKey.
 * Those address fields: line_1, line_2, city, state, country, zip (or camelCased)
 */
export const FormikAddressForm: FC<FormikAddressFormProps> = ({
  fixCountry = false,
  className,
  nestingKey,
  camelCase,
  prioritizedCountryCodes = ["US", "GB", "CA"],
  disabled = false,
  cityStateZipRowClass = "grid grid-cols-1 gap-4 md:grid-cols-8",
  fieldOverrides = {},
  useFullCountryAndState = true,
  ...other
}) => {
  const name = (rawFieldName: string) => {
    const fieldName = camelCase ? toCamelCase(rawFieldName) : snakeCase(rawFieldName);
    return nestingKey ? `${nestingKey}.${fieldName}` : fieldName;
  };

  const [country, meta, { setValue: setCountry }] = useField(name("country"));

  const selectedCountry = countryStateData.getCountry(country.value);

  const countryCodeIsValid = !!selectedCountry;

  /** Data might come in from the API in the wrong format,
   * which would break the state selector downstream. If this is the case,
   * clear the country dropdown rather than bump into an error. */
  useEffect(() => {
    if (country.value && !countryCodeIsValid) {
      setCountry(undefined);
    }
  }, [country.value, setCountry, countryCodeIsValid]);

  if (country.value && !countryCodeIsValid) return null;

  const CountrySelect = () => (
    <div>
      <FormikCountrySelector
        name={name("country")}
        prioritizedCountryCodes={prioritizedCountryCodes}
        disabled={disabled || fixCountry}
        useFullCountry={useFullCountryAndState}
        {...(fieldOverrides.country || {})}
      />
      <div className="pointer-events-none mt-2 flex justify-center">
        <small>If address is in a US territory, please select "United States"</small>
      </div>
    </div>
  );

  if (!country.value) {
    return (
      <div className={cn(className)} data-testid={`address-form-${nestingKey}`}>
        <CountrySelect />
      </div>
    );
  }

  const isInternational = Boolean(selectedCountry?.code && selectedCountry?.code !== "US");
  return (
    <div
      className={cn(className, "flex flex-col gap-4")}
      data-testid={`address-form-${nestingKey}`}
    >
      <div className="w-full">
        <CountrySelect />
      </div>

      <div className="w-full">
        <InputLabel>Street address</InputLabel>
        <FormikTextField
          placeholder="Address line 1"
          name={name("line1")}
          className="mb-4 mt-1.5"
          disabled={disabled}
          hideLabel
          data-testid="line_1"
          fullWidth
          {...(fieldOverrides.line_1 || {})}
        />
        <FormikTextField
          placeholder="Address line 2"
          name={name("line2")}
          disabled={disabled}
          hideLabel
          data-testid="line_2"
          fullWidth
          {...(fieldOverrides.line_2 || {})}
        />
      </div>

      <div className={cityStateZipRowClass}>
        <FormikTextField
          label="City"
          name={name("city")}
          placeholder="City"
          disabled={disabled}
          data-testid="city"
          fullWidth
          className="col-span-1 md:col-span-3"
          {...(fieldOverrides.city || {})}
        />
        <FormikStateSelector
          countryCode={selectedCountry.code}
          name={name("state")}
          disabled={disabled}
          fullWidth
          className="col-span-1 md:col-span-3"
          useFullState={useFullCountryAndState}
          {...(fieldOverrides.state || {})}
        />
        <FormikTextField
          label={isInternational ? "Postcode" : "ZIP code"}
          name={name("zip")}
          placeholder="12345"
          disabled={disabled}
          data-testid="zip"
          autoComplete="postal-code"
          fullWidth
          className="col-span-1 md:col-span-2"
          {...(fieldOverrides.zip || {})}
        />
      </div>
    </div>
  );
};

export type FormikAddressFormProps = {
  /**
   * Key in the parent form under which the address fields can be found.
   * Can be multi-level nested:
   * For { address: { ... }} -- 'address'
   * For { person: { address: { ... }}} -- 'person.address'
   * For { ... } -- undefined
   * */
  nestingKey?: string;
  className?: string;
  /** Assume camel-cased versions of all address fields */
  camelCase?: boolean;
  /** Make the country uneditable */
  fixCountry?: boolean;
  prioritizedCountryCodes?: CountryCode[];

  /* If true, all fields will be disabled, whether or not they have values */
  disabled?: boolean;
  /** If true, the full country and state names will be tracked instead of abbreviated codes */
  useFullCountryAndState?: boolean;
  /** Any props can be overwritten for form fields if necessary. */
  fieldOverrides?: {
    line_1?: Partial<TextFieldProps>;
    line_2?: Partial<TextFieldProps>;
    city?: Partial<TextFieldProps>;
    state?: Partial<DropdownProps>;
    country?: Partial<DropdownProps>;
    zip?: Partial<TextFieldProps>;
  };
  /** The default grid class for the row with city state and zip code can be overwritten */
  cityStateZipRowClass?: string;
};
