import { updatedField } from "Utils/errors";

const initialState = {
  status: "new",
  errors: {},

  step: "identity",
  name: "",
  ein: "",
  purpose: "",
  business_type: "",
  incorporated_in: "",
  incorporated_on: "",

  business_address: {},
  business_address_line_1: "",
  business_address_line_2: "",
  business_address_line_3: "",
  business_address_city: "",
  business_address_state: "",
  business_address_country: "",
  business_address_zip: "",
  has_business_address_error: false,

  registered_address: {},
  registered_address_line_1: "",
  registered_address_line_2: "",
  registered_address_line_3: "",
  registered_address_city: "",
  registered_address_state: "",
  registered_address_country: "",
  registered_address_zip: "",
  has_registered_address_error: false,

  registered_address_is_business_address: true,

  articles_of_incorporation: {},
  articles_of_incorporation_accepted_files: [],
  articles_of_incorporation_rejected_files: [],

  proof_of_business_address: {},
  proof_of_business_address_accepted_files: [],
  proof_of_business_address_rejected_files: [],

  certificate_of_good_standing: {},
  certificate_of_good_standing_accepted_files: [],
  certificate_of_good_standing_rejected_files: [],

  profile_complete: false,
  submit_success: false,

  principals: [],
};

var steps = ["identity", "address", "documents", "principals"];

function nextStep(currentStep) {
  return steps[steps.indexOf(currentStep) + 1];
}

function previousStep(currentStep) {
  return steps[steps.indexOf(currentStep) - 1];
}

function enabledSubmitIfPossible(state) {
  if (
    state.proof_of_business_address &&
    state.articles_of_incorporation &&
    state.certificate_of_good_standing
  ) {
    return {
      ...state,
      ...{ profile_complete: true },
    };
  } else {
    return state;
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "@redux-conn/LOAD_SUCCESS":
      if (action.payload.data && action.payload.data.business) {
        var business = action.payload.data.business;
        if (typeof business.type != "undefined") {
          business.business_type = business.type;
          delete business.type;
        }
        return { ...state, ...business };
      } else {
        return state;
      }

    case "PROFILE_CREATE_BUSINESS/SET/NAME":
      return updatedField(state, action, "name");
    case "PROFILE_CREATE_BUSINESS/SET/EIN":
      return updatedField(state, action, "ein");
    case "PROFILE_CREATE_BUSINESS/SET/PURPOSE":
      return updatedField(state, action, "purpose");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_TYPE":
      return updatedField(state, action, "business_type");
    case "PROFILE_CREATE_BUSINESS/SET/INCORPORATED_IN":
      return updatedField(state, action, "incorporated_in");

    case "PROFILE_CREATE_BUSINESS/SET/INCORPORATED_ON":
      return updatedField(state, action, "incorporated_on");

    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_LINE_1":
      return updatedField(state, action, "business_address_line_1");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_LINE_2":
      return updatedField(state, action, "business_address_line_2");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_LINE_3":
      return updatedField(state, action, "business_address_line_3");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_CITY":
      return updatedField(state, action, "business_address_city");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_STATE":
      return updatedField(state, action, "business_address_state");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_COUNTRY":
      return updatedField(state, action, "business_address_country");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_ZIP":
      return updatedField(state, action, "business_address_zip");
    case "PROFILE_CREATE_BUSINESS/SET/BUSINESS_ADDRESS_ZIP4":
      return updatedField(state, action, "business_address_zip4");
    case "PROFILE_CREATE_BUSINESS/SET/HAS_BUSINESS_ADDRESS_ERROR":
      return updatedField(state, action, "has_business_address_error");

    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_LINE_1":
      return updatedField(state, action, "registered_address_line_1");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_LINE_2":
      return updatedField(state, action, "registered_address_line_2");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_LINE_3":
      return updatedField(state, action, "registered_address_line_3");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_CITY":
      return updatedField(state, action, "registered_address_city");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_STATE":
      return updatedField(state, action, "registered_address_state");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_COUNTRY":
      return updatedField(state, action, "registered_address_country");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_ZIP":
      return updatedField(state, action, "registered_address_zip");
    case "PROFILE_CREATE_BUSINESS/SET/REGISTERED_ADDRESS_ZIP4":
      return updatedField(state, action, "registered_address_zip4");
    case "PROFILE_CREATE_BUSINESS/TOGGLE/REGISTERED_ADDRESS_IS_BUSINESS_ADDRESS":
      return {
        ...state,
        ...{
          registered_address_is_business_address: !state.registered_address_is_business_address,
        },
      };
    case "PROFILE_CREATE_BUSINESS/SET/HAS_REGISTERED_ADDRESS_ERROR":
      return updatedField(state, action, "has_registered_address_error");

    case "PROFILE_CREATE_BUSINESS/SET/ARTICLES_OF_INCORPORATION":
      return updatedField(state, action, "articles_of_incorporation");
    case "PROFILE_CREATE_BUSINESS/SET/PROOF_OF_BUSINESS_ADDRESS":
      return updatedField(state, action, "proof_of_business_address");
    case "PROFILE_CREATE_BUSINESS/SET/CERTIFICATE_OF_GOOD_STANDING":
      return updatedField(state, action, "certificate_of_good_standing");

    case "PROFILE_CREATE_BUSINESS/SET/ARTICLES_OF_INCORPORATION_ACCEPTED_FILES":
      return updatedField(state, action, "articles_of_incorporation_accepted_files");
    case "PROFILE_CREATE_BUSINESS/SET/PROOF_OF_BUSINESS_ADDRESS_ACCEPTED_FILES":
      return updatedField(state, action, "proof_of_business_address_accepted_files");
    case "PROFILE_CREATE_BUSINESS/SET/CERTIFICATE_OF_GOOD_STANDING_ACCEPTED_FILES":
      return updatedField(state, action, "certificate_of_good_standing_accepted_files");

    case "PROFILE_CREATE_BUSINESS/SET/ARTICLES_OF_INCORPORATION_REJECTED_FILES":
      return updatedField(state, action, "articles_of_incorporation_rejected_files");
    case "PROFILE_CREATE_BUSINESS/SET/PROOF_OF_BUSINESS_ADDRESS_REJECTED_FILES":
      return updatedField(state, action, "proof_of_business_address_rejected_files");
    case "PROFILE_CREATE_BUSINESS/SET/CERTIFICATE_OF_GOOD_STANDING_REJECTED_FILES":
      return updatedField(state, action, "certificate_of_good_standing_rejected_files");

    case "PROFILE_CREATE_BUSINESS/SET/ARTICLES_OF_INCORPORATION_PREVIOUS_UPLOAD":
      return {
        ...state,
        ...{ articles_of_incorporation: {} },
      };
    case "PROFILE_CREATE_BUSINESS/SET/PROOF_OF_BUSINESS_ADDRESS_PREVIOUS_UPLOAD":
      return {
        ...state,
        ...{ proof_of_business_address: {} },
      };
    case "PROFILE_CREATE_BUSINESS/SET/CERTIFICATE_OF_GOOD_STANDING_PREVIOUS_UPLOAD":
      return {
        ...state,
        ...{ certificate_of_good_standing: {} },
      };

    case "PROFILE_CREATE_BUSINESS/SET/SUBMIT_SUCCESS":
      return updatedField(state, action, "submit_success");

    case "PROFILE_CREATE_BUSINESS/SUBMITTING":
      return { ...state, status: "submitting" };
    case "PROFILE_CREATE_BUSINESS/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "PROFILE_CREATE_BUSINESS/SUCCESS":
      return { ...state, status: "success", errors: {} };

    case "PROFILE_CREATE_BUSINESS/STEP/NEXT":
      return { ...state, step: nextStep(state.step) };
    case "PROFILE_CREATE_BUSINESS/STEP/NEXT/SUCCESS":
      return enabledSubmitIfPossible({ ...state, errors: {} });
    case "PROFILE_CREATE_BUSINESS/STEP/PREVIOUS":
      return { ...state, step: previousStep(state.step) };

    case "PROFILE_CREATE_BUSINESS/STEP/SET":
      return { ...state, step: action.step };
    case "PROFILE_CREATE_BUSINESS/SET/STEP":
      return { ...state, step: action.step };

    case "CREATE_ADVANCED_PROFILE/BUSINESS/SET/SUBMIT_SUCCESS":
      return updatedField(state, action, "submit_success");
    case "CREATE_ADVANCED_PROFILE/BUSINESS/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "CREATE_ADVANCED_PROFILE/BUSINESS/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "CREATE_ADVANCED_PROFILE/BUSINESS/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };

    default:
      return state;
  }
};
