import React, { Component } from "react";

import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";

import { Link } from "Components/Link";
import { pluralize } from "Utils/strings";

import { LoanIcon } from "../../Icons/LoanIcon";
import { VaultIcon } from "../../Icons/VaultIcon";
import styles from "./AccountNameCell.module.scss";
import { SummaryTableCell } from "./SummaryTableCell";

class AccountNameCellBase extends Component {
  static propTypes = {
    name: PropTypes.string,
    uuid: PropTypes.string.isRequired,
    product_type: PropTypes.string.isRequired,
  };

  getName = () => {
    const { name, uuid, product_type } = this.props;
    if (name && name !== "") {
      return name;
    } else return `${capitalize(product_type)} ${uuid}`;
  };

  getIcon = () => {
    const { product_type } = this.props;
    if (product_type === "vault") {
      return <VaultIcon classes={{ root: styles.vaultIcon }} />;
    } else {
      return <LoanIcon classes={{ root: styles.loanIcon }} />;
    }
  };

  render() {
    const { uuid, product_type } = this.props;

    return (
      <SummaryTableCell>
        <div className={styles.container}>
          {this.getIcon()}
          <Link to={`/${pluralize(product_type)}/${uuid}`} target="_blank">
            <span className={styles.name}>{this.getName()}</span>
          </Link>
        </div>
      </SummaryTableCell>
    );
  }
}

export const AccountNameCell = AccountNameCellBase;
