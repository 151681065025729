import {
  RESET_NEW_VAULT_FLOW,
  SET_NEW_VAULT_ARBITER_ROLE,
  SET_NEW_VAULT_CUSTODY_MODEL,
  SET_NEW_VAULT_NAME,
  SET_NEW_VAULT_SELECTING_KEY,
  SET_NEW_VAULT_UNCHAINED_ROLE,
  SET_NEW_VAULT_USER_KEY_1,
  SET_NEW_VAULT_USER_KEY_2,
  SET_NEW_VAULT_ORG,
} from "Actions/vaultActions/vaultNewWizardActions";

const initialState = {
  vaultName: "",
  get vaultCurrency() {
    return "BTC";
  },
  vaultCustodyModel: "USER_CONTROLLED",
  userKey1: {
    name: "",
    uuid: "",
    username: "",
    signingRole: "cosigner",
    role: "client",
  },
  userKey2: {
    name: "",
    uuid: "",
    username: "",
    signingRole: "cosigner",
    required: true,
    role: "client",
  },
  unchainedKey: {
    signingRole: "backup",
    role: "unchained",
    distinguished: true,
  },
  arbiterKey: {
    signingRole: "cosigner",
    required: false,
    role: "arbiter",
    distinguished: true,
  },
  selectingKey: "userKey1",

  org: {
    uuid: "",
    name: "",
    state: "",
    advanced_profile_state: "",
  },
};

export const vaultNewWizardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_NEW_VAULT_CUSTODY_MODEL: {
      return {
        ...state,
        vaultCustodyModel: payload,
      };
    }

    case SET_NEW_VAULT_NAME: {
      return {
        ...state,
        vaultName: payload,
      };
    }

    case SET_NEW_VAULT_USER_KEY_1: {
      return {
        ...state,
        userKey1: {
          ...state.userKey1,
          ...payload,
        },
      };
    }

    case SET_NEW_VAULT_USER_KEY_2: {
      return {
        ...state,
        userKey2: {
          ...state.userKey2,
          ...payload,
        },
      };
    }

    case SET_NEW_VAULT_UNCHAINED_ROLE: {
      return {
        ...state,
        unchainedKey: {
          ...state.unchainedKey,
          ...payload,
        },
      };
    }

    case SET_NEW_VAULT_ARBITER_ROLE: {
      return {
        ...state,
        arbiterKey: {
          ...state.arbiterKey,
          ...payload,
        },
      };
    }

    case SET_NEW_VAULT_SELECTING_KEY: {
      return {
        ...state,
        selectingKey: payload,
      };
    }

    case SET_NEW_VAULT_ORG: {
      return {
        ...state,
        org: payload,
      };
    }

    case RESET_NEW_VAULT_FLOW: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
