import { useEffect, useState } from "react";

import { useIsMounted } from "./useIsMounted";

/**
 * @description a hook for components that need a looping timer. This is useful in components
 * like media slider or QR Code player.
 * @param {number} [finalIndex] - total length of the loop. If none passed it continues to increment forever
 * @param {number} [timer=1000] - length of time before each increment
 * @param {number} [initialTimer] - if the initial index should have a different timer than the rest
 * @param {number} [initialIndex=0] - where to start the index
 * @returns {object} in addition to the activeIndex, this hook will return several helper functions
 * that allow the consumer of the hook to interact with the timer:
 * setActiveIndex - for manual navigation between indexes
 * clearTimer - if you want it to pause
 * setTimer - if you want to restart the timer
 */
export const useIncrementIndexTimer = (finalIndex, timer = 1000, initialIndex = 0) => {
  const isMounted = useIsMounted();
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [timerIds, setTimerIds] = useState([]);

  const toggleTimerId = id => {
    setTimerIds(timerIds.includes(id) ? timerIds.filter(i => i !== id) : [...timerIds, id]);
  };

  const clearTimer = () => {
    timerIds.forEach(id => {
      window.clearTimeout(id);
      toggleTimerId(id);
    });
  };

  const incrementIndex = () => {
    if (isMounted.current) {
      let newIndex = activeIndex + 1;
      if (finalIndex) newIndex = newIndex % finalIndex;
      setActiveIndex(newIndex);
    }
  };

  const setTimer = () => {
    clearTimer();
    const newTimerId = window.setTimeout(incrementIndex, timer);
    toggleTimerId(newTimerId);
  };

  // watch for a change in the activeIndex. When incremented,
  // set the timeout to increment to next one
  useEffect(() => {
    if (isMounted.current) setTimer();
    // return cleanup function to clear the timer on unmount
    return clearTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]); // setting a new timer if active index updates

  return {
    activeIndex,
    setActiveIndex,
    clearTimer,
    setTimer,
  };
};
