import { flash } from "Actions/flash";
import { REQUEST_STATUS, ProfileAPI, OrgAPI } from "Shared/api";

export const UPDATE_USER_PERSONAL_ORG = "/USER/PERSONAL_ORG/UPDATE";
export const UPDATE_USER_PERSONAL_ORG_STATE = "/USER/PERSONAL_ORG/STATE/UPDATE";
export const UPDATE_USER_PERSONAL_ORG_ADVANCED_PROFILE_STATE =
  "/USER/PERSONAL_ORG/ADVANCED_PROFILE_STATE/UPDATE";
export const UPDATE_USER_PERSONAL_ORG_BANK_ACCOUNT = "/USER/PERSONAL_ORG/BANK_ACCOUNTS/UPDATE";
export const UPDATE_USER_PERSONAL_ORG_MULTI_INSITUTION_VAULTS =
  "/USER/PERSONAL_ORG/MULTI_INSITUTION_VAULTS/UPDATE";
export const UPDATE_USER_PERSONAL_ORG_CODENAME = "/USER/PERSONAL_ORG/CODENAME/UPDATE";
export const UPDATE_USER_PERSONAL_ORG_DENIED_REASON = "/USER/PERSONAL_ORG/DENIED_REASON/UPDATE";

export const updateUserPersonalOrgAction = personalOrg => ({
  type: UPDATE_USER_PERSONAL_ORG,
  payload: personalOrg,
});

const updateUserPersonalOrgCodenameAction = codename => ({
  type: UPDATE_USER_PERSONAL_ORG_CODENAME,
  payload: codename,
});

export const updateUserPersonalOrgBankAccountAction = bankAccount => ({
  type: UPDATE_USER_PERSONAL_ORG_BANK_ACCOUNT,
  payload: bankAccount,
});

export const updateUserPersonalOrgMultiInstitutionVaultsAction = value => ({
  type: UPDATE_USER_PERSONAL_ORG_MULTI_INSITUTION_VAULTS,
  payload: value,
});

//
// Multi-Institution Vaults
//

export const submitEnablePersonalOrgMultiInstitutionVaultsAction = uuid => async dispatch => {
  try {
    const response = await ProfileAPI.EnableMultiInstitutionVaults(uuid);
    dispatch(updateUserPersonalOrgMultiInstitutionVaultsAction(true));
    dispatch(flash("success", response.message));
  } catch (e) {
    dispatch(flash("error", e.response.message));
  }
};

export const submitDisablePersonalOrgMultiInstitutionVaultsAction = uuid => async dispatch => {
  try {
    const response = await ProfileAPI.DisableMultiInstitutionVaults(uuid);
    dispatch(updateUserPersonalOrgMultiInstitutionVaultsAction(false));
    dispatch(flash("success", response.message));
  } catch (e) {
    dispatch(flash("error", e.response.message));
  }
};

//
// Codename
//

export const submitPersonalOrgChangeCodenameAction = codename => async (dispatch, getState) => {
  const uuid = getState().user.personalOrg.uuid;
  await OrgAPI.ChangeCodename(uuid, codename);
  dispatch(updateUserPersonalOrgCodenameAction(codename));
};
