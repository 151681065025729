import React from "react";

import PropTypes from "prop-types";

export function addressProptypes(admin) {
  return {
    line_1: PropTypes.string.isRequired,
    line_2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    zip4: PropTypes.string,

    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  };
}
