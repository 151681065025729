import React, { useEffect, useState, useContext } from "react";

import { CheckboxGroup, useToast, WizardStepperContext } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { setSelectedKeys } from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  useSellBitcoinDispatch,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import { getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { useUpdateSellBitcoinSelectedKeys } from "Shared/api/hooks/trading";

import { sellBitcoinNetworkFailureToastDescription } from "../../helpers";
import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { LoadableButton } from "../components/LoadableButton";
import { SaveProgressAndCloseButton } from "../components/SaveProgressAndCloseButton";
import { SellHeader } from "../components/SellHeader";
import { SignKeyCardCheckbox } from "../components/SignKeyCardCheckbox";

type KeyState = {
  name: string;
  isDisabled: boolean;
  setIsDisabled: () => void;
  isUnchained: boolean;
  isDelegatedKey: boolean;
  logoSlug: string | null;
  uuid: string;
};
type SelectKeysStepProps = {
  onContinue: () => void;
  onClose: () => void;
};
export const SelectKeysStep = ({ onContinue, onClose }: SelectKeysStepProps) => {
  const { goToNext, goToPrev } = useContext(WizardStepperContext);
  const dispatch = useSellBitcoinDispatch();

  const { enqueueSimpleToast } = useToast();
  const orgUuid: string = useSelector(getCurrentOrgId);

  const { selectedKeys: currentSelectedKeys, allKeys, currentSaleUuid } = useSellBitcoinStore();

  const [keyOneIsDisabled, setKeyOneIsDisabled] = useState(false);
  const [keyTwoIsDisabled, setKeyTwoIsDisabled] = useState(false);
  const [keyThreeIsDisabled, setKeyThreeIsDisabled] = useState(false);

  const [keysSelected, setKeysSelected] = useState<string[]>(
    currentSelectedKeys.map((currentSelectedKey): string => {
      return currentSelectedKey.name;
    })
  );

  const keyOneState = {
    name: allKeys[0]?.name,
    isDisabled: keyOneIsDisabled,
    setIsDisabled: setKeyOneIsDisabled,
    isUnchained: allKeys[0]?.isUnchained,
    isDelegatedKey: allKeys[0]?.isDelegatedKey,
    logoSlug: allKeys[0]?.logoSlug,
    uuid: allKeys[0]?.uuid,
  };
  const keyTwoState = {
    name: allKeys[1]?.name,
    isDisabled: keyTwoIsDisabled,
    setIsDisabled: setKeyTwoIsDisabled,
    isUnchained: allKeys[1]?.isUnchained,
    isDelegatedKey: allKeys[1]?.isDelegatedKey,
    logoSlug: allKeys[1]?.logoSlug,
    uuid: allKeys[1]?.uuid,
  };
  const keyThreeState = {
    name: allKeys[2]?.name,
    isDisabled: keyThreeIsDisabled,
    setIsDisabled: setKeyThreeIsDisabled,
    isUnchained: allKeys[2]?.isUnchained,
    isDelegatedKey: allKeys[2]?.isDelegatedKey,
    logoSlug: allKeys[2]?.logoSlug,
    uuid: allKeys[2]?.uuid,
  };

  /**
   * Order keysStates so that user keys are first, any unchained keys are next
   * and then any delegated keys are last.
   */
  const keysStates = [keyTwoState, keyOneState, keyThreeState].sort((a, b) => {
    const getKeyPreference = (keyItem: KeyState) => {
      if (keyItem.isUnchained) {
        return 1;
      } else if (keyItem.isDelegatedKey) {
        return 2;
      } else {
        return 0;
      }
    };
    const keyA = getKeyPreference(a as KeyState);
    const keyB = getKeyPreference(b as KeyState);
    return keyA - keyB;
  });

  // set initial disabled key states
  useEffect(() => {
    if (currentSelectedKeys.length >= 2) {
      const currentSelectKeyNames = currentSelectedKeys.map(currentSelect => {
        return currentSelect.name;
      });

      keysStates.forEach(key => {
        if (currentSelectKeyNames.includes(key.name)) {
          key.setIsDisabled(false);
        } else {
          key.setIsDisabled(true);
        }
      });
    }
    // only run onmount therefore ignore eslint complaint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = keyName => {
    if (keysSelected.includes(keyName)) {
      // we are removing the key as checked
      // therefore remove this key from the keysSelected

      const newKeyCount = keysSelected.length - 1;
      const newSelectedSet = keysSelected.filter(item => item !== keyName);

      if (newKeyCount < 2) {
        // if we are under two keys selected make sure
        // the key that was disabled is no longer disabled.
        keysStates.forEach(keyState => {
          if (keyState.isDisabled) {
            keyState.setIsDisabled(false);
          }
        });
      }

      setKeysSelected(newSelectedSet);
    } else {
      // An additional key was selected
      // add this key to the keysSelected

      const newCountOfKeys = keysSelected.length + 1;
      const newSetOfSelectedKeys = [...keysSelected, keyName];
      if (newCountOfKeys === 2) {
        // disable the one unselected key since we are at the max selected keys of 2
        keysStates.forEach(keyState => {
          if (!newSetOfSelectedKeys.includes(keyState.name)) {
            keyState.setIsDisabled(true);
          }
        });
      }
      setKeysSelected([...keysSelected, keyName]);
    }
  };

  const canContinueToNextStep = keysSelected.length === 2;

  const normalizeKeys = () => {
    const selectedKeysOnly = keysStates.filter(key => keysSelected.includes(key.name));
    const normalizedSelectedKeys = selectedKeysOnly.map(keyState => {
      return {
        isDelegatedKey: keyState.isDelegatedKey,
        isUnchained: keyState.isUnchained,
        logoSlug: keyState.logoSlug,
        name: keyState.name,
      };
    });
    return normalizedSelectedKeys;
  };

  const handleContinueSuccess = () => {
    const normalizedKeys = normalizeKeys();

    dispatch(setSelectedKeys(normalizedKeys));
    onContinue();
    goToNext();
  };

  const handleContinueError = () => {
    enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
  };
  const { mutate: updateSellBitcoinSelectedKeys, isLoading: isUpdateLoading } =
    useUpdateSellBitcoinSelectedKeys({
      orgUuid,
      currentSaleUuid,
      handleOnSuccess: handleContinueSuccess,
      handleOnError: handleContinueError,
    });

  const handleOnContinue = () => {
    const selectedKeysOnly = keysStates.filter(key => keysSelected.includes(key.name));
    const normalizedSelectedKeysForRequest = selectedKeysOnly.map(keyState => {
      return keyState.uuid;
    });
    updateSellBitcoinSelectedKeys(normalizedSelectedKeysForRequest);
  };

  return (
    <CancelModalWrapper closeStepper={onClose}>
      <div className="max-h-screen">
        <SellHeader
          onBack={goToPrev}
          title="Select keys"
          subTitle="Choose two signing keys for this transaction."
        />
        <div className="mb-4 flex w-full flex-col [@media(min-width:1040px)]:flex-row">
          <div className="mt-4 w-full min-w-[19rem] [@media(min-width:1040px)]:mt-0">
            <CheckboxGroup
              className={"flex flex-col gap-5  min-[985px]:flex-row"}
              onChange={() => {}}
            >
              <SignKeyCardCheckbox
                onClick={() => handleOnChange(keysStates[0].name)}
                title={keysStates[0].name}
                isDisabled={keysStates[0].isDisabled}
                isKeySelected={keysSelected.includes(keysStates[0].name)}
                isUnchained={keysStates[0].isUnchained}
                isDelegatedKey={keysStates[0].isDelegatedKey}
                logoSlug={keysStates[0].logoSlug}
              />
              <SignKeyCardCheckbox
                onClick={() => handleOnChange(keysStates[1].name)}
                title={keysStates[1].name}
                isDisabled={keysStates[1].isDisabled}
                isKeySelected={keysSelected.includes(keysStates[1].name)}
                isUnchained={keysStates[1].isUnchained}
                isDelegatedKey={keysStates[1].isDelegatedKey}
                logoSlug={keysStates[1].logoSlug}
              />
              <SignKeyCardCheckbox
                onClick={() => handleOnChange(keysStates[2].name)}
                title={keysStates[2].name}
                isDisabled={keysStates[2].isDisabled}
                isKeySelected={keysSelected.includes(keysStates[2].name)}
                isUnchained={keysStates[2].isUnchained}
                isDelegatedKey={keysStates[2].isDelegatedKey}
                logoSlug={keysStates[2].logoSlug}
              />
            </CheckboxGroup>
          </div>
        </div>

        <LoadableButton
          fullWidth={true}
          disabled={!canContinueToNextStep || isUpdateLoading}
          type={"primary"}
          onClick={handleOnContinue}
          isLoading={isUpdateLoading}
        >
          Continue
        </LoadableButton>
        <SaveProgressAndCloseButton onClose={onClose} />
      </div>
    </CancelModalWrapper>
  );
};
