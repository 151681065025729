import { createTheme } from "@mui/material/styles";
import { tailwindTheme } from "@unchained/component-library";

const { colors, fontFamily } = tailwindTheme;

export const theme = createTheme({
  palette: {
    text: {
      hint: "rgba(0, 0, 0, 0.38)",
    },
    mode: "light",
    type: "light",
    primary: {
      main: colors.primary["700"],
    },
    secondary: {
      main: colors.red["500"],
    },
  },
  typography: {
    fontFamily: fontFamily.sans.join(", "),
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "4px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "8px",
        },
        marginDense: {
          minHeight: "calc(1.1875rem + 37px + .75rem)",
        },
        marginNormal: {
          minHeight: "calc(1.1875rem + 8px + 37px + .75rem)",
          marginTop: "8px",
          marginBottom: "4px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            opacity: 1,
          },
          textTransform: "initial",
          fontSize: "1.1667rem",
          fontWeight: "normal",
          marginRight: "3rem",
          letterSpacing: "0",
          "@media (min-width:960px)": {
            fontSize: "1.1667rem",
          },
        },
        labelContainer: {
          padding: "0",
          "@media (min-width:960px)": {
            padding: 0,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "-1px",
          borderBottom: "1px solid #CECECE",
        },
        indicator: {
          backgroundColor: colors.primary["900"],
          height: "3px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingRight: "24px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: "pointer",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {},
        colorPrimary: {
          "&$checked": {
            color: colors.white,
          },
        },
        colorSecondary: {
          "&$checked": {
            color: colors.white,
          },
        },
        switchBase: {
          "&$disabled": {
            color: "#FAFAFA",
            "& + $bar": {
              opacity: 0.5,
            },
            "& $icon": {
              color: "#FAFAFA",
              boxShadow: "none",
            },
            "&$switchBase": {
              "& + $bar": {
                backgroundColor: "#babec1",
              },
            },
          },
          "&$checked": {
            transform: "translateX(12px)",
            "& + $bar": {
              opacity: 1,
            },
          },
        },
        bar: {
          width: "32px",
          height: "20px",
          marginTop: "-10px",
          marginLeft: "-20px",
          borderRadius: "13px",
          opacity: 1,
          backgroundColor: "#babec1",
        },
        icon: {
          height: "18px",
          width: "18px",
        },
        iconChecked: {
          boxShadow:
            "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
        },
        disabled: {},
        checked: {},
      },
    },
  },
  mixins: {},
});
