import { useEffect, useState } from "react";

import { OperationAPI } from "Shared/api";
import { TransactionRequest } from "Specs/v1/getTransactionRequests/200";
import { btcOperationTypes } from "Utils/enums";

/**
 * @description a reusable hook to get an operation with a current btc tx request associated
 * with it for a given product (loan or vault). Allows for generalized operation request rather than
 * checking for explicit property names of operations on the product (e.g. created_transactions for vaults
 * or redemptions for loans)
 * @param {object} product - a product such as a vault or loan
 * @param {string} product.product_type - product type, one of "loan" or "vault"
 * @param {string} product.uuid
 * @param {array} [states=["created"]] state(s) of operations that we're querying for
 * @returns {object} the operation and tx request for first operation that has a
 * tx request and `operationIsLoading` boolean
 */
export const useGetOperationBtcTxReq = (
  product: Record<string, unknown> = {},
  states = ["created", "pending"]
) => {
  const [operationIsLoading, setIsLoading] = useState(true);
  const [operation, setOperation] = useState({});
  const [txRequest, setTxRequest] = useState<TransactionRequest>();

  useEffect(() => {
    const getOperation = async () => {
      let op,
        pagesLeft = 1,
        page = 1,
        exit = false;

      // if there are a lot of operations without btc tx requests, we need to paginate the requests
      // until we find one
      while (pagesLeft > 0 && !op && !exit) {
        try {
          // don't need eslint to complain here: https://eslint.org/docs/rules/no-await-in-loop#when-not-to-use-it
          /* eslint-disable no-await-in-loop */
          const { operations, totalPages } = await OperationAPI.List(
            product.product_type,
            product.uuid,
            states,
            page
          );
          // set the operation to one with btc tx requests, if it finds one it short circuits the loop
          op = operations.find(op => op.has_current_btc_transaction_requests);
          // set pages left, when it gets to zero it will end the loop
          pagesLeft = totalPages - page;
          // increment the page so we request the next one in the next iteration
          page++;
        } catch (e) {
          // if we get a 404 then there are no more pages to request
          if (e.response && e.response.status === 404) {
            exit = true;
          } else {
            throw e;
          }
        }
      }

      // if we found an operation, let's get a btc_request from it
      if (op) {
        setOperation(op);

        const vaultTransactionOperations = [
          btcOperationTypes.vault_sale_transaction,
          btcOperationTypes.vault_transaction,
        ];

        const operationType = vaultTransactionOperations.includes(op.type)
          ? btcOperationTypes.vault_transaction
          : op.type;

        const { btc_transaction_requests } = await OperationAPI.Get(
          product.uuid,
          operationType,
          op.uuid
        );
        if (btc_transaction_requests && btc_transaction_requests.length) {
          setTxRequest(btc_transaction_requests[0]);
        }
      }

      setIsLoading(false);
    };

    getOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.uuid, product.product_type]);

  return { operation, txRequest, operationIsLoading };
};
