import React, { Component } from "react";

import { Visibility as ShowIcon, VisibilityOff as HideIcon } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import Copyable from "Components/helpers/Copyable";

import { SummaryTableCell } from "./SummaryTableCell";

class MaskedCell extends Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    maskedValue: PropTypes.any.isRequired,
    copyable: PropTypes.bool,
  };

  state = {
    showValue: false,
  };

  render() {
    const { value, maskedValue, copyable } = this.props;
    const { showValue } = this.state;
    const Icon = showValue ? ShowIcon : HideIcon;
    const displayValue = copyable ? <Copyable text={value}>{value}</Copyable> : value;
    return (
      <SummaryTableCell>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{showValue ? displayValue : maskedValue}</Grid>

          <Grid item>
            <Tooltip title={showValue ? "Hide" : "Show"}>
              <IconButton size="small" onClick={this.toggleShowValue}>
                <Icon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </SummaryTableCell>
    );
  }

  toggleShowValue = () => {
    const { showValue } = this.state;
    this.setState({ showValue: !showValue });
  };
}

export { MaskedCell };
