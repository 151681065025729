import { useEffect } from "react";

/**
 * Custom hook to prevent scrolling of the app's main body element.
 *
 * This hook is designed to be used when a child component needs to take over the screen on the landing (dashboard) page
 * and control scrolling based on its own height, ignoring the height of the surrounding context.
 *
 * By default, if the `<body>` element has a greater height than the child component, scrolling would be allowed,
 * which may not be desired. To prevent this, the hook dynamically sets the `overflow` CSS property of the `<body>` element to "hidden"
 * when it is mounted, ensuring that only the child component's height determines scrolling behavior.
 * When the hook is unmounted, the overflow is reset and set back to auto.
 *
 * This is similar to how our modals work which are powered by MUI's Dialog component.
 */
export function useHideAppBodyOverflow() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
}
