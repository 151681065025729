import { updatedField } from "Utils/errors";

var initialState = {
  status: "new",
  errors: {},
  wallet_type: "",
  wallet_active: false,
  bip32_node: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "ACCOUNT_KEY/SHOW/SET/BIP32_NODE":
      return updatedField(state, action, "bip32_node");
    case "ACCOUNT_KEY/SHOW/SET/WALLET_TYPE":
      return updatedField(state, action, "wallet_type");
    case "ACCOUNT_KEY/SHOW/SET/WALLET_ACTIVE":
      return updatedField(state, action, "wallet_active");

    case "ACCOUNT_KEY/SHOW/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "ACCOUNT_KEY/SHOW/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "ACCOUNT_KEY/SHOW/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };
    default:
      return state;
  }
};
