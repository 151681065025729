import {
  UpdateClientBuyBitcoin200,
  UpdateClientSellBitcoin200,
  UpdateClientBuyBitcoinRequest,
  UpdateClientSellBitcoinRequest,
} from "Specs/v2";

import { ApiType } from "../apiType";

export const TradingAPIV2Factory = (API: ApiType) => ({
  UpdateBuyBitcoin: async (cbbUuid: string, data: UpdateClientBuyBitcoinRequest) => {
    const response = await API.Put<UpdateClientBuyBitcoin200>(
      `/trading/admin/client-buy-bitcoin/${cbbUuid}`,
      data
    );
    return response.data;
  },

  UpdateSellBitcoin: async (csbUuid: string, data: UpdateClientSellBitcoinRequest) => {
    const response = await API.Put<UpdateClientSellBitcoin200>(
      `/trading/admin/client-sell-bitcoin/${csbUuid}`,
      data
    );
    return response.data;
  },
});
