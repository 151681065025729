/* global UNCHAINED_LOG GA_IGNORE_NEXT_PAGEVIEW:true TREFOIL_USER trackEvent ga */

let log = process.env.REACT_APP_UNCHAINED_LOG !== undefined && process.env.REACT_APP_UNCHAINED_LOG;

export function trackPageview(path) {
  if (typeof GA_IGNORE_NEXT_PAGEVIEW != "undefined" && GA_IGNORE_NEXT_PAGEVIEW) {
    GA_IGNORE_NEXT_PAGEVIEW = false;
    return;
  }
  if (ga != null) {
    ga("send", "pageview", path);
  }
  if (log) {
    var logMessage = `GA: Pageview ${path}`;
    if (typeof TREFOIL_USER != "undefined" && TREFOIL_USER != null) {
      logMessage += ` as ${TREFOIL_USER.uuid}`;
    }
    console.log(logMessage);
  }
}

export function eventTracker(args) {
  return _ => {
    trackEvent(args);
    return true;
  };
}
