import { AnyAction } from "redux";

import { SET_LOAN_INDEX_USER, RESET_ALL_LOAN_INDEXES } from "Actions/loanActions/loanIndexActions";
import { REQUEST_STATUS } from "Shared/api/api";

// TODO: Define types in API specs, and specifically define loan type

const initialState = {
  userLoans: {
    loans: [],
    status: REQUEST_STATUS.PENDING,
  },
};

type LoanIndexState = typeof initialState;
export const loanIndexReducer = (
  state: LoanIndexState = initialState,
  action: AnyAction
): LoanIndexState => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOAN_INDEX_USER: {
      return {
        ...state,
        userLoans: payload,
      };
    }

    case RESET_ALL_LOAN_INDEXES: {
      return {
        userLoans: { ...initialState.userLoans },
      };
    }

    default:
      return state;
  }
};
