import React, { useContext, useMemo, useState } from "react";

import { ModalContent, ModalFooter } from "@unchained/component-library";
import cn from "classnames";

import { KeyItem } from "Components/Shared/Elements/Keys/KeyItem";
import { useGetSliceAccountKeys } from "Shared/api";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";
import { ModalLoader } from "./ModalLoader";

export const ChooseKeyStep = () => {
  const [nextEnabled, setNextEnabled] = useState(false);
  const { address, setAccountKey, accountKey, handleNext } = useContext(ConfirmOnDeviceContext);

  const accountKeysQuery = useGetSliceAccountKeys(address);
  const signableKeys = useMemo(() => {
    if (!accountKeysQuery.data) return [];
    return accountKeysQuery.data.filter(key => key.allowedActions.includes("create_signature"));
  }, [accountKeysQuery.data]);

  const handleClick = key => {
    if (key.uuid === accountKey?.uuid) {
      setAccountKey(null);
      setNextEnabled(false);
    } else {
      setAccountKey(key);
      setNextEnabled(true);
    }
  };

  const gridClass = useMemo(
    () => ["grid-cols-1", "grid-cols-2", "grid-cols-3"][signableKeys.length - 1],
    [signableKeys]
  );

  if (!signableKeys.length) {
    return (
      <div className="flex h-[7.5rem] items-center justify-center">
        <p className="text-gray-500">You don't have any keys associated with this wallet.</p>
      </div>
    );
  }
  if (accountKeysQuery.isLoading) return <ModalLoader />;

  return (
    <>
      <ModalContent>
        <div className={cn(`grid-cols grid ${gridClass} py-5`)}>
          {signableKeys &&
            signableKeys.map(key => (
              <div className="col-span-1 flex flex-col items-center" key={key.uuid}>
                <KeyItem
                  accountKey={key}
                  name={key.name}
                  size="lg"
                  onClick={() => handleClick(key)}
                  active={accountKey?.uuid === key.uuid}
                  distinguished={key.distinguished}
                  // TODO: does this need `partner_username`?
                />
              </div>
            ))}
        </div>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: "Next",
            onClick: handleNext,
            disabled: !nextEnabled,
          },
        ]}
      />
    </>
  );
};
