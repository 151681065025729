import { makeQueryStringContext } from "Contexts/QueryStringContext";
import { CompleteOrgAccountTypeEnum } from "Specs/v1/getAccount/200";

const { QueryStringContext: BasicQsContext, useQueryStringContext: useBasicQueryString } =
  makeQueryStringContext<{ from: string; accountType: CompleteOrgAccountTypeEnum }>([
    "from",
    "accountType",
  ]);

export { BasicQsContext, useBasicQueryString };
