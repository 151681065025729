import get from "lodash/get";
import { createSelector } from "reselect";
import { RootState } from "src/reducers";

import { OrgForList } from "Specs/v1/searchOrgs/200";

// get the price from the store
export const usernameSelector = (state: RootState) => get(state, "account.user.username", null);
const allowedActionsSelector = (state: RootState) =>
  get(state, "account.user.allowed_actions", null);

export const getIsViewableAsAdmin = createSelector(allowedActionsSelector, actions => {
  return actions.includes("view_admin");
});

/** Returns all *non-individual* orgs on the account in state */
export const nonIndividualOrgsSelector = (state: RootState) =>
  state.account.orgs.memberships.map(membership => membership.org);

/** Returns the personal org in state */
export const individualOrgSelector = (state: RootState) => state.account.orgs.personal;

export const currentOrgSelector = (state: RootState) => state.account.orgs.current;

/** Returns all orgs on the account in state, including the individual org */
export const accountOrgsSelector = (state: RootState) => {
  const nonIndividualOrgs = nonIndividualOrgsSelector(state);
  const individualOrg = individualOrgSelector(state) as OrgForList;
  return individualOrg ? [individualOrg, ...nonIndividualOrgs] : nonIndividualOrgs;
};
