import { useGetVaultBalances } from "Shared/api";
import { Transaction } from "Utils/vaultUtils";

import { Operation } from "./OperationType";
import { getOutputBucketsFromTxOutputs } from "./TransactionDetails/getOutputBucketsFromTxOutputs";

/** Determines if an operation is considered suspicious, so as to display relevant warnings. */
export const useIsOperationSuspicious = (operation: Operation) => {
  const vault = operation.vault;

  const txRequest = operation.btc_transaction_requests?.[0];
  const tx = (txRequest as unknown as { transaction: Transaction })?.transaction;
  const operationType = operation.type;
  const isVaultTransaction = operationType === "vault_transaction";

  const getBalancesQuery = useGetVaultBalances(vault?.uuid, {
    enabled: isVaultTransaction && !!vault?.uuid,
  });

  if (!isVaultTransaction) return false;

  const balances = getBalancesQuery.data;

  if (!vault || !tx?.outputs || !balances) return false;

  const { externalOutputs } = getOutputBucketsFromTxOutputs(
    tx.outputs,
    vault.product_type,
    vault.uuid
  );

  const externalOutputsAmount = externalOutputs.reduce((acc, output) => acc + output.amount, 0);

  const balance = parseFloat(balances.balance);

  const percentOfOutputsGoingExternally = externalOutputsAmount / balance;

  return percentOfOutputsGoingExternally >= 0.9;
};
