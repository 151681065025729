import acmeLogo from "Assets/images/acme_logo.png";
import acompanyLogo from "Assets/images/acompany.png";
import bakktLogo from "Assets/images/bakkt_logo.png";
import bcompanyLogo from "Assets/images/bcompany.png";
import coincoverLogo from "Assets/images/coincover_logo.png";
import ctLogo from "Assets/images/ct.png";
import fortisLogo from "Assets/images/fortisbank.png";
import kingdomTrustLogo from "Assets/images/kingdomtrust_logo.png";
import unchainedLogo from "Assets/images/unchained_logo.png";
import widgetLogo from "Assets/images/widget_logo.png";

/*
 * WARNING: This solution is far from ideal and should be improved upon.
 *
 * The current implementation hard codes logo slugs that can potentially vary across
 * different environments. This approach introduces environment-specific information
 * into the source code, which is not a good practice. It makes the code less clean,
 * more brittle, and harder to maintain.
 *
 * A more robust solution would be to store the images in the database.
 * The very concept of logo slug would then become unnecessary as it only serves as
 * a key to retrieve the image from front-end assets currently.
 *
 * Despite its shortcomings, this file and its collocated test file provide as good
 * of a safeguard against breaking changes as we can have in the meantime.
 *
 * It is crucial to understand these issues and work towards a better solution.
 */

export const logoMap = {
  // logo_slug  ->  logo image
  unchained: unchainedLogo,
  "kingdom-trust": kingdomTrustLogo,
  kingdomtrust: kingdomTrustLogo,
  fortis: fortisLogo,
  fortisbank: fortisLogo,
  ct: ctLogo,
  acme: acmeLogo,
  acompany: acompanyLogo,
  bcompany: bcompanyLogo,
  widget: widgetLogo,
  coincover: coincoverLogo,
  bakkt: bakktLogo,
};
