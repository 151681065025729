import { useContext, useEffect, useState } from "react";

import { WizardStepperContext } from "@unchained/component-library";

/**
 * The manifest needs to rerender when the state changes to recalculate which steps are disabled/complete.
 * If such a rerender is required prior to navigation, this hook handles the waiting and subsequent redirect. **/
const useTriggerStepChange = (change: "goToNext" | "goToFirstReadyStep") => {
  const [go, setGo] = useState(false);
  const { [change]: goTo } = useContext(WizardStepperContext);

  useEffect(() => {
    if (go) goTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [go]);

  return () => setGo(true);
};

/** Waits for rerender, and then navigates to next step */
export const useTriggerNextStep = () => useTriggerStepChange("goToNext");

/** Waits for rerender, and then navigates to first ready step */
export const useTriggerFirstReadyStep = () => useTriggerStepChange("goToFirstReadyStep");
