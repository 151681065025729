/* global DEFAULT_TIME_ZONE */
import moment from "moment-timezone";

import { getConfig } from "./config";

// For communication with the server.
export const SERVER_DATE_SEND_FORMAT = "YYYY/MM/DD"; // 2017/01/01
export const SERVER_DATE_GET_PARAM_FORMAT = "YYYY-MM-DD"; // 2017-01-01
export const SERVER_DATETIME_SEND_FORMAT = "YYYY-MM-DD HH:mm:ss"; // 2017-01-01 015:03:32
export const SERVER_TIME_SEND_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]"; // 2017-01-01T015:03:32Z
export const SERVER_TIME_RECEIVE_FORMAT = "ddd, DD MMM YYYY HH:mm:ss [GMT]"; // Sun, 01 Jan 2017 15:03:32 GMT
const SERVER_DATE_RECEIVE_FORMAT = SERVER_DATE_SEND_FORMAT;

// For end-user display purposes
export const READABLE_DATE_FORMAT = "MMMM Do, YYYY"; // January 1st, 2017
const READABLE_MONTH_YEAR = "MMMM YYYY"; // January 2017
const READABLE_DATETIME_FORMAT = "MMMM Do, YYYY [at] h:mm A"; // January 1st, 2017 at 3:03 PM
export const READABLE_DATETIME_FORMAT_SHORT = "MM/DD/yyyy [at] hh:mm a"; // 01/01/2017 at 3:03 PM
export const READABLE_DATE_ABBREVIATED = "MM/DD/YY"; // 01/01/17
export const TRADE_READABLE_DATETIME_FORMAT_SHORT = "YYYY/MM/DD HH:mm:ss"; // 2017/01/01 15:03:32
const READABLE_CENTRAL_TIME_FORMAT = "hA [(US Central Time)]"; // 3PM (US Central Time)
const READABLE_GRAPH_LABEL_WITH_YEAR = "MMM D, YYYY";
// See "Locale aware formats" here: https://momentjs.com/docs/#/parsing/string-format/
// TODO: consider customizing via the moment facility for localized date format customization
// https://momentjs.com/docs/#/customization/long-date-formats/
const LOCALIZED_BRIEF_DATE = "MMM D YYYY";
const LOCALIZED_BRIEF_DATE_TIME_WITH_DAY = "ddd, MMM D YYYY[ at ]LT";

export const CENTRAL_TIME_ZONE = "America/Chicago";

export function currentUTCTime() {
  return moment.utc();
}

export function currentCentralTime() {
  return utcToCentralTime(currentUTCTime());
}

export function utcToCentralTime(time) {
  return moment.utc(time).tz(CENTRAL_TIME_ZONE);
}

export function parseTimestamp(str, format = SERVER_TIME_RECEIVE_FORMAT) {
  return moment.utc(str, format || SERVER_TIME_RECEIVE_FORMAT);
}

export function parseDate(str, format = SERVER_DATE_RECEIVE_FORMAT) {
  return moment.utc(str, format || SERVER_DATE_RECEIVE_FORMAT);
}

export function formatTime(str, format) {
  return parseTimestamp(str).format(format || SERVER_TIME_SEND_FORMAT);
}

export function formatDate(time, format) {
  return moment(time).format(format || SERVER_DATE_SEND_FORMAT);
}

export function readableTime(time, short = false) {
  return moment
    .utc(time)
    .local()
    .format(short ? READABLE_DATETIME_FORMAT_SHORT : READABLE_DATETIME_FORMAT);
}

export function readableTimeCentral(time) {
  return moment.utc(time).tz(CENTRAL_TIME_ZONE).format(READABLE_DATETIME_FORMAT);
}

export function readableTimeOfDay(time) {
  return time.format(READABLE_CENTRAL_TIME_FORMAT);
}

// This function DOES NOT localize the input `time` to the browser's
// time zone.
export function readableDate(time) {
  return moment.utc(time).format(READABLE_DATE_FORMAT);
}

export function readableDateAbbreviated(time) {
  return moment.utc(time).format(READABLE_DATE_ABBREVIATED);
}

export function readableShortDate(time) {
  return moment(time).format(READABLE_GRAPH_LABEL_WITH_YEAR);
}

export function dateString(time) {
  return moment.utc(time).format(READABLE_DATE_FORMAT);
}

export function tradeDateString(time) {
  return parseTimestamp(time).tz(CENTRAL_TIME_ZONE).format(TRADE_READABLE_DATETIME_FORMAT_SHORT);
}

export function localizedDate(time) {
  return moment.utc(time).local().format(LOCALIZED_BRIEF_DATE);
}

export function localizedDateTimeWithDay(time) {
  return moment.utc(time).local().format(LOCALIZED_BRIEF_DATE_TIME_WITH_DAY);
}

// This function DOES NOT localize the input `time` to the browser's
// time zone.
export function readableMonthYear(time) {
  return moment.utc(time).format(READABLE_MONTH_YEAR);
}

// This function DOES localize the input `time` to the browser's time
// zone.
export function readableLocalDate(time) {
  return moment.utc(time).local().format(READABLE_DATE_FORMAT);
}

// this function expects a unix timestamp and DOES
// localize the input `time` to the browser's time.
export function getReadableGraphLabel(time) {
  return moment.utc(time).local().format(READABLE_GRAPH_LABEL_WITH_YEAR);
}

export const timestampFromServerDate = date => moment(date, SERVER_DATE_SEND_FORMAT).valueOf();
export const timestampFromServerTime = time => moment(time, SERVER_TIME_RECEIVE_FORMAT).valueOf();
export const timeFromUtcTimestamp = timestamp => moment.utc(timestamp).local().format("LT");

export const getGraphHourFromTimestamp = timestamp =>
  moment.utc(timestamp).startOf("hour").local().format("h A");

/**
 * @description if the timestamp is in the future (because of unix date conversion for example)
 * then just return the current timestamp
 * @param {number} timestamp
 * @returns {number} a timestamp that is no later than today
 */
export const ensureNotFutureTimestamp = timestamp => {
  const now = moment();
  if (moment.utc(timestamp).isAfter(now)) {
    return now.valueOf();
  }
  return timestamp;
};

export function businessHours() {
  var linkAttrs = {
    href: "https://www.opm.gov/policy-data-oversight/pay-leave/pay-administration/fact-sheets/holidays-work-schedules-and-pay",
    target: "_blank",
    className: "track-link",
  };
  linkAttrs["data-category"] = "holidays";
  linkAttrs["data-action"] = "goto";
  linkAttrs["data-label"] = "vault-show";
  return (
    <span>
      Monday - Friday, 9am - 5pm (US Central Time), excluding{" "}
      <a {...linkAttrs}>US government holidays</a>
    </span>
  );
}

export function businessDays() {
  var linkAttrs = {
    href: "https://www.opm.gov/policy-data-oversight/pay-leave/pay-administration/fact-sheets/holidays-work-schedules-and-pay",
    target: "_blank",
    className: "track-link",
  };
  linkAttrs["data-category"] = "holidays";
  linkAttrs["data-action"] = "goto";
  linkAttrs["data-label"] = "vault-show";
  return (
    <span>
      Monday - Friday, excluding <a {...linkAttrs}>US government holidays</a>
    </span>
  );
}

export function vaultSigningTime() {
  var centralTimeString = getConfig("vault_terms").signing_time; // 17:00:00
  return moment(centralTimeString, ["h:m:s"], CENTRAL_TIME_ZONE);
}

export function vaultSubmissionTime() {
  var centralTimeString = getConfig("vault_terms").submission_time; // 09:00:00
  return moment(centralTimeString, ["h:m:s"], CENTRAL_TIME_ZONE);
}

export function parseReadableDate(date) {
  return moment(date, READABLE_DATE_FORMAT);
}

export function utcTimestampIsTodayCt(timestamp) {
  return moment.utc(timestamp).tz(CENTRAL_TIME_ZONE).isSame(moment.tz(CENTRAL_TIME_ZONE), "day");
}

export function utcTimestampIsBeforeTodayCt(timestamp) {
  return moment.utc(timestamp).tz(CENTRAL_TIME_ZONE).isBefore(moment.tz(CENTRAL_TIME_ZONE), "day");
}

export const getLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatMilliseconds = (ms, failureValue = "00:00") => {
  if (isNaN(ms)) return failureValue;
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const getDaysSincePreviousDate = previousDate => {
  const now = Date.now();

  const MILLISECONDS_IN_A_DAY = 86400000; // 24 * 60 * 60 * 1000
  const daysSince = Math.ceil((now - previousDate.getTime()) / MILLISECONDS_IN_A_DAY);

  return daysSince;
};
