import { useQuery } from "react-query";

import { OTPResetsApi } from "../otpResetsApi";

export const otpResetKeys = {
  all: ["otpResets"] as const,
  showAdmin: (token: string) => ["otpResets", "showAdmin", token] as const,
};

export const useOtpResetQuery = (token: string) =>
  useQuery(otpResetKeys.showAdmin(token), () => OTPResetsApi.Get2FAResetAdmin(token));
