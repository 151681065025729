import { updatedField } from "Utils/errors";

import { initialPersonState } from "./person";

const initialState = initialPersonState();

function nextStep(currentStep) {
  switch (currentStep) {
    case "identity":
      return "address";
    case "address":
      return "success";
    default:
      return null;
  }
}

function previousStep(currentStep) {
  switch (currentStep) {
    case "success":
      return "address";
    case "address":
      return "identity";
    default:
      return null;
  }
}

function enabledSubmitIfPossible(state) {
  if (
    state.physical_address_line_1 &&
    state.physical_address_city &&
    state.physical_address_zip &&
    state.physical_address_state
  ) {
    return {
      ...state,
      ...{ profile_complete: true },
    };
  } else {
    return state;
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "@redux-conn/LOAD_SUCCESS":
      if (action.payload.data && action.payload.data.consumer) {
        return { ...state, ...action.payload.data.consumer };
      } else {
        return state;
      }

    case "PROFILE_CREATE_CONSUMER/SET/FIRST_NAME":
      return updatedField(state, action, "first_name");
    case "PROFILE_CREATE_CONSUMER/SET/MIDDLE_NAME":
      return updatedField(state, action, "middle_name");
    case "PROFILE_CREATE_CONSUMER/SET/LAST_NAME":
      return updatedField(state, action, "last_name");

    case "PROFILE_CREATE_CONSUMER/SET/DOB":
      return updatedField(state, action, "dob");

    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_LINE_1":
      return updatedField(state, action, "physical_address_line_1");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_LINE_2":
      return updatedField(state, action, "physical_address_line_2");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_LINE_3":
      return updatedField(state, action, "physical_address_line_3");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_CITY":
      return updatedField(state, action, "physical_address_city");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_STATE":
      return updatedField(state, action, "physical_address_state");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_ZIP":
      return updatedField(state, action, "physical_address_zip");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_ZIP4":
      return updatedField(state, action, "physical_address_zip4");
    case "PROFILE_CREATE_CONSUMER/SET/PHYSICAL_ADDRESS_COUNTRY":
      return updatedField(state, action, "physical_address_country");
    case "PROFILE_CREATE_CONSUMER/SET/HAS_PHYSICAL_ADDRESS_ERROR":
      return updatedField(state, action, "has_physical_address_error");

    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_LINE_1":
      return updatedField(state, action, "mailing_address_line_1");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_LINE_2":
      return updatedField(state, action, "mailing_address_line_2");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_LINE_3":
      return updatedField(state, action, "mailing_address_line_3");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_CITY":
      return updatedField(state, action, "mailing_address_city");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_STATE":
      return updatedField(state, action, "mailing_address_state");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_ZIP":
      return updatedField(state, action, "mailing_address_zip");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_ZIP4":
      return updatedField(state, action, "mailing_address_zip4");
    case "PROFILE_CREATE_CONSUMER/SET/MAILING_ADDRESS_COUNTRY":
      return updatedField(state, action, "mailing_address_country");
    case "PROFILE_CREATE_CONSUMER/TOGGLE/MAILING_ADDRESS_IS_PHYSICAL_ADDRESS":
      return {
        ...state,
        ...{ mailing_address_is_physical_address: !state.mailing_address_is_physical_address },
      };
    case "PROFILE_CREATE_CONSUMER/SET/HAS_MAILING_ADDRESS_ERROR":
      return updatedField(state, action, "has_mailing_address_error");

    case "PROFILE_CREATE_CONSUMER/SET/STATE_ID_ACCEPTED_FILES":
      return updatedField(state, action, "state_id_accepted_files");
    case "PROFILE_CREATE_CONSUMER/SET/STATE_ID_REJECTED_FILES":
      return updatedField(state, action, "state_id_rejected_files");
    case "PROFILE_CREATE_CONSUMER/SET/STATE_ID":
      return updatedField(state, action, "state_id");
    case "PROFILE_CREATE_CONSUMER/SET/STATE_ID_PREVIOUS_UPLOAD":
      return { ...state, state_id: {} };

    case "PROFILE_CREATE_CONSUMER/SET/SUBMIT_SUCCESS":
      return updatedField(state, action, "submit_success");
    case "PROFILE_CREATE_CONSUMER/SUBMITTING":
      return { ...state, status: "submitting" };
    case "PROFILE_CREATE_CONSUMER/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "PROFILE_CREATE_CONSUMER/SUCCESS":
      return { ...state, status: "success", errors: {} };

    case "PROFILE_CREATE_CONSUMER/STEP/NEXT":
      return { ...state, step: nextStep(state.step) };
    case "PROFILE_CREATE_CONSUMER/STEP/NEXT/SUCCESS":
      return enabledSubmitIfPossible({ ...state, ...action.consumer, errors: {} });
    case "PROFILE_CREATE_CONSUMER/STEP/PREVIOUS":
      return { ...state, step: previousStep(state.step) };

    case "PROFILE_CREATE_CONSUMER/SET/STEP":
      return { ...state, step: action.step };

    case "CREATE_ADVANCED_PROFILE/SET/SSN":
      return updatedField(state, action, "ssn");

    case "CREATE_ADVANCED_PROFILE/SET/DIGITAL_ASSETS":
      return updatedField(state, action, "digital_assets");
    case "CREATE_ADVANCED_PROFILE/SET/BITCOIN_SOURCE_NOTES":
      return updatedField(state, action, "bitcoin_source_notes");

    case "CREATE_ADVANCED_PROFILE/SET/FIAT_ASSETS":
      return updatedField(state, action, "fiat_assets");
    case "CREATE_ADVANCED_PROFILE/SET/FIAT_ASSET_SOURCES":
      return updatedField(state, action, "fiat_asset_sources");

    case "CREATE_ADVANCED_PROFILE/SET/INCOME":
      return updatedField(state, action, "income");
    case "CREATE_ADVANCED_PROFILE/SET/INCOME_SOURCES":
      return updatedField(state, action, "income_sources");

    case "CREATE_ADVANCED_PROFILE/SET/SUBMIT_SUCCESS":
      return updatedField(state, action, "submit_success");
    case "CREATE_ADVANCED_PROFILE/REQUEST/SUBMITTING":
      return { ...state, status: "submitting" };
    case "CREATE_ADVANCED_PROFILE/REQUEST/ERROR":
      return { ...state, status: "error", errors: action.errors };
    case "CREATE_ADVANCED_PROFILE/REQUEST/SUCCESS":
      return { ...state, status: "success", errors: {} };

    default:
      return state;
  }
};
