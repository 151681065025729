import * as yup from "yup";

// NOTE: When adding a yup method, also add a type for it to ./typings/yup.d.ts
// Otherwise, TypeScript won't like it!

export function validateMaxPrecision(this: yup.NumberSchema, maxDigits: number, message: string) {
  const regex = new RegExp(`^\\d*(\\.\\d{1,${maxDigits}})?$`);
  return this.test({
    name: "maxPrecision",
    // This regex.test is being called on a number, but I don't want to change its behavior, which is evidently working.
    // Instead, just coerce the type
    test: value => (value ? regex.test(value as unknown as string) : false),
    message: message ? message : `Must have less than or equal to ${maxDigits} decimal places`,
    params: { maxDigits },
    exclusive: false,
  });
}
