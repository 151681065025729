export const employmentIndustries = [
  "Accommodation and food services",
  "Accounting legal",
  "Agriculture forestry fishing and hunting",
  "Airlines aviation",
  "Architecture design",
  "Arts entertainment and recreation",
  "Automotive",
  "Entity services",
  "Cannabis",
  "Construction repair maintenance",
  "Educational services",
  "Engineering",
  "Environmental services",
  "Financial services",
  "Gambling",
  "Health care and social assistance",
  "Insurance",
  "Information technology and web development",
  "Law enforcement",
  "Management consulting",
  "Manufacturing",
  "Media inc marketing and advertising",
  "Military",
  "Non profit organization management",
  "Oil energy",
  "Professional scientific and technical services",
  "Public administration",
  "Real estate and rental and leasing",
  "Retail and ecommerce",
  "Transportation and warehousing",
  "Utilities",
  "Waste management and remediation services",
  "Wholesale trade",
  "Other",
];
