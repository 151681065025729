import { createContext } from "react";

import { ShippingInfo } from "Routes/onboard/(shared)/ShippingAddressStep";
import { GetSetObj } from "Routes/onboard/(utils)";

export type OnboardBizOrgContextType = {
  userCount?: GetSetObj<number>;
  from?: string;
  invitesSkipped?: GetSetObj<boolean>;
  shippingInfo: GetSetObj<ShippingInfo | undefined>;
  selectedPricing: GetSetObj<boolean>;
};

export const OnboardBizOrgContext = createContext<OnboardBizOrgContextType | undefined>(undefined);
