import React from "react";

import { AlertCircle, FloatingIcon } from "@unchained/component-library";

import { PageTitle } from "Components/Layout/Header/Titles/PageTitle";

const NotFound = () => (
  <div className="flex h-screen w-full items-center justify-center">
    <div className="flex flex-col items-center gap-4 pb-8">
      <FloatingIcon color="red" Icon={AlertCircle} size="md" shadow />
      <PageTitle>Page not found</PageTitle>
      <h3>Could not find the page you were looking for.</h3>
    </div>
  </div>
);

export default NotFound;
