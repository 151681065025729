import PropTypes from "prop-types";

import { parseTimestamp, readableTime, formatDate, SERVER_DATE_SEND_FORMAT } from "Utils/time";

import { addressProptypes } from "./addresses";
import { documentProptypes } from "./documents";

export function personProptypes(admin) {
  if (admin) {
    return {
      ...personProptypes(),
      ...{},
    };
  } else {
    return {
      first_name: PropTypes.string,
      middle_name: PropTypes.string,
      last_name: PropTypes.string,

      ssn: PropTypes.string,
      masked_ssn: PropTypes.string,
      dob: PropTypes.string,

      income: PropTypes.string,
      income_sources: PropTypes.string,
      digital_assets: PropTypes.string,
      bitcoin_source_notes: PropTypes.string,
      fiat_assets: PropTypes.string,
      fiat_asset_sources: PropTypes.string,
      bitcoin_sources: PropTypes.arrayOf(PropTypes.string),

      title: PropTypes.string,

      state_id: PropTypes.shape(documentProptypes()),

      created_at: PropTypes.string,
      updated_at: PropTypes.string,

      addressed_as: PropTypes.string,

      physical_address: PropTypes.shape(addressProptypes()),
      mailing_address: PropTypes.shape(addressProptypes()),

      createdAt: PropTypes.object,
      updatedAt: PropTypes.object,

      DOB: PropTypes.object,
      readableDOB: PropTypes.string,
      readableCreatedAt: PropTypes.string,
      readableUpdatedAt: PropTypes.string,

      readableSSN: PropTypes.string,

      readableIncome: PropTypes.string,
      readableDigitalAssets: PropTypes.string,
      readableFiatAssets: PropTypes.string,
    };
  }
}

export function Person(data) {
  if (data == null) {
    return data;
  }

  if (data.dob) {
    data.DOB = parseTimestamp(data.dob, SERVER_DATE_SEND_FORMAT);
    data.readableDOB = formatDate(data.DOB, SERVER_DATE_SEND_FORMAT);
  }

  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTime(data.updatedAt);

  if (data.ssn) {
    data.readableSSN = [
      data.ssn.substring(0, 3),
      data.ssn.substring(3, 5),
      data.ssn.substring(5, 9),
    ].join("-");
  }

  data.readableIncome = data.income ? `${data.income} USD` : null;
  data.readableDigitalAssets = data.digital_assets ? `${data.digital_assets} USD` : null;
  data.readableFiatAssets = data.fiat_assets ? `${data.fiat_assets} USD` : null;

  return data;
}
