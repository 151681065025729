import { OnboardingInfo } from ".";

import { advancedIndividualOrgValid } from "../[uuid]/(individual)/individualOrgValsAndValidations";
import { basicProfileValid } from "./basicInfoValsAndValidations";

export const basicFlowComplete = (info: OnboardingInfo) => {
  const { personalOrg } = info;
  return basicProfileValid(info) && ["live", "pending_approval"].includes(personalOrg.state);
};

export const individualFlowComplete = (info: OnboardingInfo) => {
  const { personalOrg } = info;
  return (
    personalOrg.advanced_profile_state === "approved" ||
    (basicProfileValid(info) &&
      advancedIndividualOrgValid(personalOrg) &&
      personalOrg.state === "live" &&
      ["approved", "pending_approval"].includes(personalOrg.advanced_profile_state))
  );
};
