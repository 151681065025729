import { Network } from "@caravan/bitcoin";

import { getConfig } from "./config";

// eslint-disable-next-line no-process-env
const env_variables = { ...process.env }; // Accessing directly does not appear to work, let's make a copy
const ENV_TREZOR_BLOCKBOOK_URL = env_variables.REACT_APP_TREZOR_BLOCKBOOK_URL;
const TREZOR_BLOCKBOOK_URL = ENV_TREZOR_BLOCKBOOK_URL || "http://localhost:3035";

//
// BIP32
//

export function bip32SequenceFromPath(path) {
  // See https://github.com/trezor/connect/blob/master/connect.js
  return path
    .toLowerCase()
    .split("/")
    .filter(function (p) {
      return p !== "m";
    })
    .map(function (p) {
      let hardened = false;
      if (p[p.length - 1] === "'") {
        hardened = true;
        p = p.substr(0, p.length - 1);
      }
      if (isNaN(p)) {
        throw new Error("Not a valid path.");
      }
      let n = parseInt(p);
      if (hardened) {
        // hardened index
        n = (n | 0x80000000) >>> 0;
      }
      return n;
    });
}

export function BIP32CoinType() {
  return isTestnet()
    ? getConfig("wallet").coin_types.testnet
    : getConfig("wallet").coin_types["BTC"];
}

//
// Explorers
//

export function isTestnet() {
  return getConfig("wallet")["testnet"];
}

export function bitcoinNetwork() {
  return getConfig("wallet")["testnet"] ? Network.TESTNET : Network.MAINNET;
}

function bitcoinURLPrefix(testnet) {
  if (getConfig("wallet")["regtest"]) {
    return TREZOR_BLOCKBOOK_URL;
  } else {
    return `https://mempool.space${testnet ? "/testnet" : ""}`;
  }
}

export function exploreBitcoinBlocksURL() {
  return bitcoinURLPrefix(isTestnet());
}

export function exploreBitcoinTransactionURL(testnet, txid) {
  return `${bitcoinURLPrefix(testnet)}/tx/${txid}`;
}

export function exploreBitcoinAddressURL(testnet, address) {
  return `${bitcoinURLPrefix(testnet)}/address/${address}`;
}

export function exploreSliceAddressURL(slice) {
  return exploreBitcoinAddressURL(getConfig("wallet")["testnet"], slice.address);
}

export function exploreAddressURL(testnet, unit, address) {
  return exploreBitcoinAddressURL(testnet, address);
}

export function exploreTransactionURL(testnet, unit, address) {
  return exploreBitcoinTransactionURL(testnet, address);
}

//
// Payment
//

// See https://github.com/bitcoin/bips/blob/master/bip-0021.mediawiki
export function bitcoinQRCodeURL({ address, amount }) {
  let url = `bitcoin:${address}`;
  if (amount) {
    url += `?amount=${amount}`;
  }
  return url;
}
