import { useMutation } from "react-query";

import {
  UpdateProfileDocumentsRequest,
  updateProfileDocumentsRequestFields,
} from "Specs/v1/updateProfileDocuments/request";

import { ProfileAPI } from "..";

export type DocLabel = (typeof updateProfileDocumentsRequestFields)[number];

export const docTitleMap = {
  "State ID": "state_id",
  "Green Card": "greencard",
  "W-8 BEN": "w8_ben",
  // TODO: Add other doc labels
} as Record<string, DocLabel>;

export const useUploadProfileDocs = (orgUuid: string) =>
  useMutation((docs: UpdateProfileDocumentsRequest) => ProfileAPI.UpdateDocuments(orgUuid, docs));

export const useDeleteProfileDoc = (orgUuid: string) =>
  useMutation((docLabel: DocLabel) => ProfileAPI.DeleteDocument(orgUuid, docLabel));

export const useDownloadProfileDoc = (orgUuid: string, principalUuid?: string) =>
  useMutation((label: DocLabel) => ProfileAPI.DownloadDocument(label, orgUuid, principalUuid));
