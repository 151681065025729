import React from "react";

import { Grid } from "@mui/material";

import { KeyItem } from "Components/Shared/Elements/Keys/KeyItem";
import { AccountKey } from "Components/VaultsView/types";
import { useMemoizedState } from "Redux/selectors/hooks";

import { SigningKey } from "./ConfirmSigningKeys";
import "./SigningKeySelection.scss";

interface SigningKeySelectionProps {
  accountKeys: AccountKey[];
  key1: SigningKey;
  key2: SigningKey;
  key3: SigningKey;
  handleKeyClick: (string, bool) => void;
}

export const SigningKeySelection = ({
  accountKeys,
  key1,
  key2,
  key3,
  handleKeyClick,
}: SigningKeySelectionProps) => {
  const orgUuid = useMemoizedState("account.orgs.current.uuid");
  const css = className => {
    return `SigningKeySelection-${className}`;
  };

  const containerClass = accountKey => {
    const selected = isSelected(accountKey) ? "selected" : "";
    return `${css("keyWrapper")} ${selected}`;
  };

  const isSelected = (key: AccountKey) => {
    if (key1.uuid === key.uuid) {
      return key1.active;
    }
    if (key2.uuid === key.uuid) {
      return key2.active;
    }
    if (key3.uuid === key.uuid) {
      return key3.active;
    }
    return false;
  };

  const sortedAccountKeys = [
    accountKeys.find(key => key.uuid === key1.uuid),
    accountKeys.find(key => key.uuid === key2.uuid),
    accountKeys.find(key => key.uuid === key3.uuid),
  ];

  return (
    <Grid classes={{ container: css("container") }} container justifyContent="center" spacing={2}>
      {sortedAccountKeys.map((key, index) => {
        if (key.allowed_actions?.length === 0 || !key.uuid) {
          // Then the user has no permissions to view or use the key
          return null;
        }

        const shared = orgUuid && key.owner.uuid !== orgUuid;
        const logoSlug = key.role === "delegate" ? key.logo_slug || key.logoSlug : "";
        return (
          <Grid item key={`${key}-${index}`}>
            <div className={containerClass(key)}>
              <KeyItem
                accountKey={key}
                name={key.name}
                shared={shared}
                ownerName={shared && key.owner.name}
                distinguished={key.distinguished}
                onClick={e => handleKeyClick(index, isSelected(key))}
                active={isSelected(key)}
                size="md"
                role={key.role}
                logoSlug={logoSlug}
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};
