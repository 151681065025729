import { ReactNode } from "react";

import {
  useBreakpoint,
  useWizardStepper,
  WizardStep,
  WizardStepperContext,
} from "@unchained/component-library";
import cn from "classnames";
import { noop } from "lodash";

import { useHideAppBodyOverflow } from "Utils/hooks/useHideGlobalOverflow";

import { DesktopWizard, FullPageContent, MobileWizard } from "./components";

type FullPageWizardProps = {
  /** The full manifest of steps and substeps for this wizard. */
  manifest: WizardStep[];
  /**
   * Either specify the starting step/substep index (allowing the substep to be undefined),
   * or pass in `"firstIncomplete"`. If the latter, the wizard will automatically start on the first
   * step or substep which is not complete. */
  startingIndices?: [number, number] | [number, undefined] | "firstIncomplete";
  /** Can be passed to / called when the final step is completed. */
  onComplete?: () => void;
  /** Can be passed to / called when the user backs out of the first step or otherwise exits. */
  onWizardExit?: () => void;
  /**
   * Optional content to display at footer of wizard stepper, or in header on mobile.
   * Basic options are WizardLinks and WizardSwitcher (in ./components.tsx).
   * */
  stepperFooter?: ReactNode;
  className?: string;
  /** Whether the current component should be centrally positioned or located
   * in relation to the top of the page. */
  desktopLayout?: "center" | "top";
  headerBackgroundColor?: string;
  isShowUnchainedLogo?: boolean;
  isSignatureLogo?: boolean;
  footerStyles?: string;
  onStepClick?: (stepInd: number, subStepInd?: number) => void;
};

/**
 * A component that encapsulates the display and step-tracking logic of a full page wizard.
 * By default, it drops you on the first incomplete step/substep, but you can override this behavior,
 * and customize further with stepperOptions.
 *
 * If `stepperFooter` is passed, the passed component (often one of WizardLinks or WizardSwitcher) is rendered as a wizard footer
 * on desktop and in the mobile header.
 *
 * It uses the UDS's WizardStepper component.
 **/
export const FullPageWizard = ({
  manifest,
  onWizardExit = noop,
  stepperFooter,
  startingIndices = "firstIncomplete",
  className,
  desktopLayout = "center",
  headerBackgroundColor,
  isShowUnchainedLogo,
  isSignatureLogo,
  footerStyles,
  onStepClick,
  ...stepperOptions
}: FullPageWizardProps) => {
  const [startingStepIndex, startingSubStepIndex] = Array.isArray(startingIndices)
    ? startingIndices
    : [];
  useHideAppBodyOverflow();

  const { widthIsBelow } = useBreakpoint();
  const isMobile = widthIsBelow("sm");

  const { currentComponent: component, ...stepperProps } = useWizardStepper(manifest, {
    ...stepperOptions,
    startingStepIndex,
    startingSubStepIndex,
    onExit: onWizardExit,
  });
  // TODO: Fix type from CL
  const currentComponent = component as ReactNode;

  const { currentStep, currentSubStep } = stepperProps;

  // The full page wizards also allow for `fullPage` components in the manifest. When these render,
  // they break out of the main wizard layout.
  const currentComponentIsFullPage = currentSubStep?.fullPage || currentStep.fullPage;

  const wizardProps = {
    // Setting an optional onStepClick here allows, among other possibilities, a
    // component to specify that nothing should happen on step click.
    stepperProps: { ...stepperProps, onStepClick: onStepClick || stepperProps.onStepClick },
    manifest,
    stepperFooter,
    currentComponent,
    desktopLayout,
    headerBackgroundColor,
    isShowUnchainedLogo,
    isSignatureLogo,
    footerStyles,
  };

  return (
    <WizardStepperContext.Provider value={stepperProps}>
      <div
        className={cn(
          "fixed left-0 top-0 z-[1300] h-screen w-screen overflow-y-auto bg-white",
          className
        )}
      >
        {currentComponentIsFullPage ? (
          <FullPageContent stepperFooter={stepperFooter}>{currentComponent}</FullPageContent>
        ) : (
          <>
            {
              /** we dont want to render both of these at the same time, especially for things that are not allowed to render twice at once like StripeElements */
              !isMobile ? <DesktopWizard {...wizardProps} /> : <MobileWizard {...wizardProps} />
            }
          </>
        )}
      </div>
    </WizardStepperContext.Provider>
  );
};
