import { updatedField } from "Utils/errors";

var initialState = {
  signature_text: "",
  qrcode_reader_active: false,
  qrcode_reader_error: "",
  qrcode_reader_success: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SPEND/SET/SIGNATURE_TEXT":
      return updatedField(state, action, "signature_text");
    case "SPEND/SET/QRCODE_READER_ACTIVE":
      return updatedField(state, action, "qrcode_reader_active");
    case "SPEND/SET/QRCODE_READER_ERROR":
      return updatedField(state, action, "qrcode_reader_error");
    case "SPEND/SET/QRCODE_READER_SUCCESS":
      return updatedField(state, action, "qrcode_reader_success");
    default:
      return state;
  }
};
