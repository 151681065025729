import { useContext } from "react";

import {
  ButtonProps,
  formatCurrency,
  Loader,
  RadioButton,
  RadioGroup,
  RadioOrCheckboxSecondTier,
  WizardStepperContext,
} from "@unchained/component-library";
import cn from "classnames";
import { useMutation } from "react-query";
import { Navigate } from "react-router-dom";

import { AccountAvatar } from "Components/Layout/Nav/AccountSwitcher/accountHelpers";
import { useSwitchCurrentOrg } from "Components/Layout/Nav/hooks";
import { useNavigate } from "Components/Link";
import { useLoadingContext } from "Contexts/LoadingContext";
import { ManifestStep } from "Routes/onboard/(shared)";
import { OnboardBasicContext } from "Routes/onboard/(utils)/context";
import { individualFlowComplete } from "Routes/onboard/(utils)/flowCompletion";
import { useBasicQueryString } from "Routes/onboard/BasicQuery";
import { OrgAPI } from "Shared/api";
import { SKUS } from "Shared/api/v2";
import { getPurchaseable, usePurchasables } from "Shared/api/v2/hooks/purchasables";
import { CompleteOrgAccountTypeEnum } from "Specs/v1/getAccount/200";
import { COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

export const formatDollars = (dollars: string | number) =>
  formatCurrency(parseFloat(dollars.toString()), "USD");

// For radio buttons
const avatar = (accountType: CompleteOrgAccountTypeEnum) => (
  <AccountAvatar accountType={accountType} className="mr-1" />
);

export const CreateYourAccount = () => {
  const navigate = useNavigate();
  const loading = useLoadingContext();
  const info = useContext(OnboardBasicContext);
  const { goToNext } = useContext(WizardStepperContext);
  const { showErrorToast } = useEasyToasts();
  const switchCurrentOrg = useSwitchCurrentOrg();
  const { accountType: accountTypeIntention } = useBasicQueryString();
  const purchasablesQuery = usePurchasables();

  const { person, accountType, personalOrg, from } = info;
  const { IRA, BUSINESS, INDIVIDUAL, TRUST } = COMPLETE_ORG_ACCOUNT_TYPE;

  const hideProfile = info.personalOrg?.state !== "pending_payment";
  const basicProfileLive = info.personalOrg?.state === "live";

  // Preselect personal if it's available
  let type =
    accountType.value ||
    accountTypeIntention ||
    (!individualFlowComplete(info) ? INDIVIDUAL : undefined);
  // But if it's hidden, set type to undefined
  if (hideProfile && type === INDIVIDUAL) {
    type = undefined;
  }

  const hasInProgressIra = !!info.memberships
    .map(m => m.org)
    .find(o => o.account_type === IRA && o.state !== "live");

  const createOrg = useMutation(
    ({ name, accountType }: { name: string; accountType: CompleteOrgAccountTypeEnum }) =>
      OrgAPI.Create({ name, accountType })
  );

  // Org creation requires a name. For IRA and TRUST type orgs, we create one with a filler name
  // and the user can update it later.
  // (In the case of IRA, the backend handles naming entirely. For Trusts, we start blank).
  const createOrgWithName = (accountType: CompleteOrgAccountTypeEnum, name: string) => {
    if (loading.value) return;

    loading.set(true);
    createOrg.mutate(
      {
        name,
        accountType,
      },
      {
        onSuccess: async resp => {
          const uuid = resp.org?.uuid;
          await switchCurrentOrg(uuid);
          loading.set(false);
          await navigate([`/onboard/${uuid}`, from].filter(Boolean).join("?from="));
        },
        onError: error => {
          loading.set(false);
          showErrorToast(error);
        },
      }
    );
  };

  const onContinueClick = async () => {
    if (type === IRA) {
      // be robust to commas in a name, but remove duplicate spaces
      const firstName = person.first_name.replace(/,/g, " ").replace(/ {2}/g, " ");
      const lastName = person.last_name.replace(/,/g, " ").replace(/ {2}/g, " ");
      createOrgWithName(IRA, `Solera National Bank FBO ${firstName} ${lastName}`);
    } else if (type === TRUST) {
      goToNext();
    } else if (type === BUSINESS) {
      goToNext();
    } else if (type === INDIVIDUAL) {
      await switchCurrentOrg(personalOrg.uuid);
      navigate([`/onboard/${personalOrg.uuid}`, from].filter(Boolean).join("?from="));
    }
  };

  const actions = [
    {
      onClick: onContinueClick,
      children: "Continue",
      disabled: !type || loading.value,
      buttonType: "submit",
    },
  ] as [ButtonProps] | [ButtonProps, ButtonProps];

  if (purchasablesQuery.isLoading) return <Loader className="h-screen w-full" />;
  if (purchasablesQuery.isError) {
    showErrorToast(purchasablesQuery.error);
    return <Navigate to="/home" />;
  }

  const { purchasables } = purchasablesQuery.data;

  const individualAcctPurchasable = getPurchaseable(purchasables, SKUS.INDIVIDUAL_ACCOUNT);
  const iraFee = getPurchaseable(purchasables, SKUS.IRA_ACCOUNT);
  const trustFee = getPurchaseable(purchasables, SKUS.TRUST_ACCOUNT);

  return (
    <ManifestStep
      width="max-w-3xl"
      className="md:mt-12"
      actions={actions}
      title={
        basicProfileLive
          ? "Create a new account"
          : "Congrats! Your personal information has been approved"
      }
      description={
        basicProfileLive
          ? "Would you like an IRA, business, or trust account?"
          : "Finish your personal account setup or add another account type. You will have the option to add more accounts later."
      }
    >
      <div className="flex flex-col gap-7">
        <RadioGroup
          onChange={e => accountType.set(e.target.value as CompleteOrgAccountTypeEnum)}
          name="accountType"
          className={cn("grid grid-cols-1 gap-3 md:grid-cols-2")}
          value={type}
        >
          {hideProfile ? null : (
            <RadioButton
              label="Personal"
              data-testid="individual-radio"
              customIcon={avatar(INDIVIDUAL)}
              value={INDIVIDUAL}
              description={
                <RadioOrCheckboxSecondTier>
                  <div className="mb-2 text-lg font-semi text-gray-700">
                    {formatDollars(individualAcctPurchasable.usdPrice)}/year
                  </div>
                  <div>
                    Secure and trade bitcoin in a personal vault, and access the inheritance
                    protocol.
                  </div>
                </RadioOrCheckboxSecondTier>
              }
            />
          )}
          <RadioButton
            label="Bitcoin IRA"
            data-testid="ira-radio"
            value={IRA}
            disabled={hasInProgressIra}
            tooltip={
              hasInProgressIra
                ? "You can only have one in-progress IRA application at a time."
                : undefined
            }
            customIcon={avatar(IRA)}
            description={
              <RadioOrCheckboxSecondTier>
                <div className="mb-2 text-lg font-semi text-gray-700">
                  {formatDollars(iraFee.usdPrice)}/year
                </div>
                <div>Hold the keys to tax-advantaged bitcoin with an all-in-one IRA account.</div>
              </RadioOrCheckboxSecondTier>
            }
          />
          <RadioButton
            label="Business"
            data-testid="business-radio"
            value={BUSINESS}
            customIcon={avatar(BUSINESS)}
            description={
              <RadioOrCheckboxSecondTier>
                <div className="mb-2 text-lg font-semi text-gray-700">Variable pricing</div>
                <div>
                  Bitcoin custody and services for businesses, non-profits, and Solo 401(k)s.
                </div>
              </RadioOrCheckboxSecondTier>
            }
          />
          <RadioButton
            label="Trust"
            data-testid="trust-radio"
            value={TRUST}
            customIcon={avatar(TRUST)}
            description={
              <RadioOrCheckboxSecondTier>
                <div className="mb-2 text-lg font-semi text-gray-700">
                  {formatDollars(trustFee.usdPrice)}/year
                </div>
                <div>
                  Title your bitcoin vaults and loans to your trust to ensure your estate plan is
                  executed according to your plan.
                </div>
              </RadioOrCheckboxSecondTier>
            }
          />
        </RadioGroup>
      </div>
    </ManifestStep>
  );
};
