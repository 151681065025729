import React from "react";

import Grid from "@mui/material/Grid";

import windowsSecurityBox from "Assets/images/LedgerErrors/windowsSecurityBox.png";

import styles from "./DeviceIssues.module.scss";

const supportLink =
  "https://support.ledger.com/hc/en-us/articles/360023190873-Fix-for-Windows-10-security-popup";

const LedgerWindowsSecurityWarningPrompt = () => (
  <span className={styles.linkLike}>Review windows instructions</span>
);

const LedgerWindowsSecurityWarningSimple = () => {
  return (
    <div>
      <p>
        Ledger Nano S and Ledger Nano X have a{" "}
        <a href={supportLink} target="_blank">
          known issue
        </a>{" "}
        on Windows OS 1903. You’ll be prompted at key signing with a Windows security popup. Ledger
        is aware of this issue and claims they will fix in a future firmware update. For now they
        suggest you ignore the error and proceed.
      </p>
      <p>
        We suggest if you’re using a Ledger Nano S or X to use MacOS. If you only have access to a
        Windows machine, you should move the error to the side and complete the signing process.
      </p>
    </div>
  );
};

const LedgerWindowsSecurityWarningDeviceCheck = () => {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      classes={{ container: styles.content }}
    >
      <Grid item>
        <p className={styles.text}>
          Ledger has an unresolved{" "}
          <a href={supportLink} target="_blank">
            Windows security warning
          </a>
          .
        </p>
      </Grid>
      <Grid item>
        <p className={styles.primaryInstruction}>
          Ledger recommends moving the warning to the side and proceeding.
        </p>
      </Grid>
      <Grid item className={styles.imageContainer}>
        <img className={styles.image} src={windowsSecurityBox} alt="Windows security message" />
      </Grid>
      <Grid item>
        <p className={styles.helpText}>
          If you press ‘Cancel’ or the 'X', the Ledger will freeze, ask you to reconnect your
          device, and try again.
        </p>
      </Grid>
    </Grid>
  );
};

const LedgerWindowsSecurityWarningSigning = () => {
  return (
    <Grid container spacing={2} direction="column" alignItems="center" className={styles.content}>
      <Grid item>
        <p className={styles.text}>
          Ledger has an unresolved{" "}
          <a href={supportLink} target="_blank">
            Windows security warning
          </a>
          .
        </p>
      </Grid>

      <Grid item>
        <p className={styles.primaryInstruction}>
          The warning will flash several times on your windows machine. They suggest when it stops,
          you move the box to the side and proceed with signing.
        </p>
      </Grid>

      <Grid item className={styles.imageContainer}>
        <img className={styles.image} src={windowsSecurityBox} alt="Windows security message" />
      </Grid>

      <Grid item>
        <p className={styles.helpText}>
          If you press ‘Cancel’ or the 'X', the Ledger will freeze, ask you to reconnect your
          device, and try again.
        </p>
      </Grid>
    </Grid>
  );
};

export {
  LedgerWindowsSecurityWarningSimple,
  LedgerWindowsSecurityWarningDeviceCheck,
  LedgerWindowsSecurityWarningSigning,
  LedgerWindowsSecurityWarningPrompt,
};
