import React from "react";

import PropTypes from "prop-types";

import { humanState } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { parseTimestamp, formatDate, readableDate, readableTime } from "Utils/time";

export function loanPaymentProptypes() {
  return {
    number: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    interest: PropTypes.number.isRequired,
    principal: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    due_at: PropTypes.string.isRequired,
    current: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    paid_at: PropTypes.string,

    humanState: PropTypes.string.isRequired,
    interestWithUnit: PropTypes.string.isRequired,
    principalWithUnit: PropTypes.string.isRequired,
    totalWithUnit: PropTypes.string.isRequired,

    dueAt: PropTypes.object.isRequired,
    readableDueOn: PropTypes.string.isRequired,

    paidAt: PropTypes.object,
    readablePaidOn: PropTypes.string,

    is_incomplete: PropTypes.bool.isRequired,
  };
}

export function LoanPayment(data) {
  if (data == null) {
    return data;
  }

  data.humanState = humanState(data);

  data.dueAt = parseTimestamp(data.due_at);
  data.readableDueOn = readableDate(data.dueAt);

  if (data.paid_at) {
    data.paidAt = parseTimestamp(data.paid_at);
    data.readablePaidAt = readableTime(data.paidAt);
  }

  data.interestWithUnit = `$${formatCurrency(data.interest, 2)}`;
  data.principalWithUnit = `$${formatCurrency(data.principal, 2)}`;
  data.totalWithUnit = `$${formatCurrency(data.total, 2)}`;

  return data;
}
