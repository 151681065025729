import { useQuery } from "react-query";

import { UserAPI } from "../userApi";

export const userQueryKeys = {
  all: ["users"] as const,
  show: uuid => [...userQueryKeys.all, "show", uuid],
  account: ["account"] as const,
};

export const useGetUser = (uuid: string) =>
  useQuery(userQueryKeys.show(uuid), () => UserAPI.GetUser(uuid));
