import { UclLinkContext } from "@unchained/component-library";
import FontFaceObserver from "fontfaceobserver";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "Assets/scss/trefoil.scss";
import { BreakpointTracker } from "Components/BreakpointTracker";
import { Link, LinksBlockedProvider } from "Components/Link";
import { CurrentOrgUuidProvider } from "Contexts/CurrentOrgUuidContext";
import { trefoilStore } from "Redux/store";

import { theme } from "./assets/theme";
import AppRoutes from "./routes";
import { GlobalStyleWrap, ToasterProvider } from "./style-import-workaround";

const appFont = new FontFaceObserver("Inter");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /**
       * This is the RQ default, but let's make it explicit.
       *
       * Queries are immediately considered "stale", meaning if (and only if)
       *
       * - the window is blurred and then refocused, or...
       * - another component renders the same query, or...
       * - the current component unmounts and remounts...
       *
       * then the query will be requested once again, and the stale data updated.
       * */
      staleTime: 0,

      // Another explicitly stated default. See above staleTime note for context.
      refetchOnWindowFocus: true,
    },
  },
});

appFont.load().then(() => {
  const root = createRoot(document.getElementById("app"));
  root.render(
    <GlobalStyleWrap addedTheme={theme}>
      <QueryClientProvider client={queryClient}>
        <CurrentOrgUuidProvider>
          <Provider store={trefoilStore}>
            <LinksBlockedProvider>
              <UclLinkContext.Provider value={{ Link }}>
                <ToasterProvider>
                  <BreakpointTracker />
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </ToasterProvider>
              </UclLinkContext.Provider>
            </LinksBlockedProvider>
          </Provider>
        </CurrentOrgUuidProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </GlobalStyleWrap>
  );
});
