import { FieldHelperProps, FormikProps, useField } from "formik";

import { flattenDeepObjectIntoSingleTieredObjectWithCombinedKeys } from "Utils/objects";

export const setTouched = (
  helpers: FieldHelperProps<any> | FormikProps<unknown>,
  value: boolean
) => {
  // Set the field to touched (to trigger error visibility)
  // *after* Formik's onChange handler has run,
  // avoiding race conditions.
  setTimeout(() => helpers.setTouched(value), 0);
};

export const useSetTouched = (name: string) => {
  const [field, meta, helpers] = useField(name);
  return (value: boolean) => setTouched(helpers, value);
};

export const flattenFormikErrors = (errors: Record<string, any>) => {
  if (!errors) return {};
  return flattenDeepObjectIntoSingleTieredObjectWithCombinedKeys(errors);
};
