import { useEffect, useState } from "react";

import { SwapVert } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { Tooltip } from "@unchained/component-library";
import Big from "big.js";
import cx from "classnames";
import { useFormikContext } from "formik";

import { DeprecatedFormikNumberField } from "Components/Shared/Elements/Fields";
import { TradingFeeAmount } from "Components/TradingDesk/modals/BuyBitcoinModal/TradingFeeAmount";
import { BTC, USD } from "Contexts/BuyBitcoin";
import { TradeAmount, Currency, Fee } from "Contexts/BuyBitcoin/types";
import { usePrevious } from "Utils/hooks/usePrevious";
import { formatCurrency } from "Utils/strings";

import { InputLabel } from "../../components";
import { BuyBitcoinValues } from "./BuyBitcoinForm";
import { InverseCurrencyAmount } from "./InverseCurrencyAmount";
import styles from "./TransactionAmountInput.module.scss";

type InverseCurrencyProps = {
  usdTooltipContent?: string;
  fees: Fee[];
};

type MinMaxButtonProps = {
  minimumTransactionAmount?: TradeAmount;
  maximumTransactionAmount?: TradeAmount;
  disableMinMax?: boolean;
  disableMinMaxToolTip?: string;
};

type TransactionAmountInputProps = {
  bitcoinPrice?: string;
  currencies: Currency[];
  label: string;
  className?: string;
  displayErrors?: boolean;
  inverseCurrencyProps?: InverseCurrencyProps;
  minMaxButtonProps: MinMaxButtonProps;
};
export const TransactionAmountInput = ({
  bitcoinPrice,
  currencies,
  label,
  className,
  displayErrors = true,
  inverseCurrencyProps,
  minMaxButtonProps,
}: TransactionAmountInputProps) => {
  const {
    minimumTransactionAmount,
    maximumTransactionAmount,
    disableMinMax,
    disableMinMaxToolTip,
  } = minMaxButtonProps;
  const showInverseCurrencyAmount = !!inverseCurrencyProps;
  const { usdTooltipContent, fees } = inverseCurrencyProps || {};
  const { values, errors, touched, setValues, validateForm } = useFormikContext<BuyBitcoinValues>();
  const { amount, currency, max, min } = values;
  const previousMaximumTransactionAmount: TradeAmount | undefined =
    usePrevious(maximumTransactionAmount);

  const [hasBeenValid, setHasBeenValid] = useState(false);

  useEffect(() => {
    // if the maximumTransactionAmount changed
    // then reset the max state, since the previous max state represents
    // the previous maximumTransactionAmount.
    if (previousMaximumTransactionAmount?.BTC !== maximumTransactionAmount.BTC) {
      setValues({ ...values, max: false });
    }
  });

  useEffect(() => {
    validateForm();
  }, [currency, validateForm]);

  useEffect(() => {
    if (!hasBeenValid && !errors.amount && amount) {
      setHasBeenValid(true);
    }
  }, [hasBeenValid, errors.amount, amount]);

  const handleSwitchCurrency = () => {
    const newCurrency = currency === USD ? BTC : USD;
    let newAmount: number | string = amount;
    if (max && maximumTransactionAmount) {
      newAmount = maximumTransactionAmount[newCurrency];
    } else if (min && minimumTransactionAmount) {
      newAmount = minimumTransactionAmount[newCurrency];
    } else if (newAmount) {
      if (currency === USD) {
        newAmount = Big(newAmount).div(bitcoinPrice).toFixed(8);
      } else {
        newAmount = Big(newAmount).times(bitcoinPrice).toFixed(2);
      }
    }
    setValues({ ...values, currency: newCurrency, amount: newAmount });
  };

  const setAmountToFixedDecimalPlaces = amount => {
    const fixedDecimalLength = currency === USD ? 2 : 8;
    return Big(amount).toFixed(fixedDecimalLength);
  };

  const handleClickMax = () => {
    setValues({
      ...values,
      amount: setAmountToFixedDecimalPlaces(maximumTransactionAmount[currency]),
      max: true,
      min: false,
    });
  };

  const handleClickMin = () => {
    setValues({
      ...values,
      amount: setAmountToFixedDecimalPlaces(minimumTransactionAmount[currency]),
      max: false,
      min: true,
    });
  };

  const isValidAmount = touched.amount ? !errors.amount : !hasBeenValid || !errors.amount;

  const inputStartAdornment =
    currency === USD ? (
      <InputAdornment position="start" className={styles.inputAdornment}>
        <h4
          className={cx(styles.inputAdornmentLabel, {
            [styles.inputAdornmentLabel_error]: !isValidAmount,
          })}
        >
          $
        </h4>
      </InputAdornment>
    ) : null;

  const decimalScale = currency === USD ? 2 : 8;

  const minDisplayAmount =
    currency === USD && minimumTransactionAmount
      ? `$${formatCurrency(minimumTransactionAmount?.USD)}`
      : `${minimumTransactionAmount?.BTC} BTC`;
  const maxDisplayAmount =
    currency === USD && maximumTransactionAmount
      ? `$${formatCurrency(maximumTransactionAmount.USD)}`
      : `${maximumTransactionAmount?.BTC} BTC`;

  const minToolipContent = disableMinMax ? disableMinMaxToolTip : minDisplayAmount;
  const maxTooltipContent = disableMinMax ? disableMinMaxToolTip : maxDisplayAmount;

  return (
    <div className={className}>
      <InputLabel label={label}>
        <MinMaxAmountButtons
          minimumTransactionAmount={minimumTransactionAmount}
          maximumTransactionAmount={maximumTransactionAmount}
          disableMinMax={disableMinMax}
          handleClickMin={handleClickMin}
          handleClickMax={handleClickMax}
          isMinSelected={min}
          isMaxSelected={max}
          minTooltipContent={minToolipContent}
          maxTooltipContent={maxTooltipContent}
        />
      </InputLabel>

      <DeprecatedFormikNumberField
        name="amount"
        InputLabelProps={{ shrink: false }}
        label={false}
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={decimalScale}
        displayErrors={displayErrors}
        valueIsNumericString={true}
        fullWidth
        variant="standard"
        onValueChange={({ value }) =>
          setValues({ ...values, amount: value, max: false, min: false })
        }
        hasError={displayErrors && Boolean(hasBeenValid && errors.amount)}
        data-testid="transactionAmountInput"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <CurrencySwap
              currencies={currencies}
              currency={currency}
              handleSwitchCurrency={handleSwitchCurrency}
            />
          ),
          startAdornment: inputStartAdornment,
          classes: {
            input: cx(styles.inputField, {
              [styles.inputField_error]: (touched.amount || hasBeenValid) && errors.amount,
            }),
            root: "!mt-0",
            adornedEnd: cx(styles.adornedEnd, { [styles.adornedEnd_disabled]: !isValidAmount }),
          },
        }}
      />

      {showInverseCurrencyAmount && (
        <div className={styles.details}>
          <InverseCurrencyAmount
            maximumTransactionAmount={maximumTransactionAmount}
            minimumTransactionAmount={minimumTransactionAmount}
            bitcoinPrice={bitcoinPrice}
            usdTooltipContent={usdTooltipContent}
          />
          <TradingFeeAmount fees={fees} bitcoinPrice={bitcoinPrice} />
        </div>
      )}
    </div>
  );
};

type CurrencySwapProps = {
  handleSwitchCurrency: () => void;
  currencies: Currency[];
  currency: Currency;
};
const CurrencySwap = ({ handleSwitchCurrency, currencies, currency }: CurrencySwapProps) => {
  return (
    <InputAdornment
      position="end"
      className={styles.switchButton}
      onClick={currencies.length > 1 ? handleSwitchCurrency : null}
      data-testid="TransactionAmountInputSwapButton"
    >
      <div className={styles.tickerIcons}>
        {currencies.includes(USD) ? (
          <span
            className={cx(styles.tickerIcon, {
              [styles.tickerIcon_disabled]: currency !== USD,
            })}
          >
            USD
          </span>
        ) : null}

        {currencies.includes(BTC) ? (
          <span
            className={cx(styles.tickerIcon, {
              [styles.tickerIcon_disabled]: currency !== BTC,
            })}
          >
            BTC
          </span>
        ) : null}
      </div>
      {currencies.length > 1 ? <SwapVert /> : null}
    </InputAdornment>
  );
};

type MinMaxAmountButtonsProps = {
  minimumTransactionAmount?: TradeAmount;
  maximumTransactionAmount?: TradeAmount;
  disableMinMax: boolean;
  handleClickMin: () => void;
  handleClickMax: () => void;
  isMinSelected: boolean;
  isMaxSelected: boolean;
  maxTooltipContent?: string;
  minTooltipContent?: string;
};
const MinMaxAmountButtons = ({
  minimumTransactionAmount,
  maximumTransactionAmount,
  disableMinMax,
  handleClickMin,
  handleClickMax,
  isMinSelected,
  isMaxSelected,
  minTooltipContent,
  maxTooltipContent,
}: MinMaxAmountButtonsProps) => {
  return (
    <div className={styles.labelContainer}>
      {minimumTransactionAmount && (
        <div className={styles.labelButtonContainer}>
          <Tooltip content={minTooltipContent} arrow placement="top">
            <span
              onClick={!disableMinMax ? handleClickMin : null}
              className={cx(styles.labelButton, {
                [styles.labelButton_disabled]: isMinSelected || disableMinMax,
              })}
            >
              min
            </span>
          </Tooltip>
        </div>
      )}
      {maximumTransactionAmount && (
        <div className={styles.labelButtonContainer}>
          <Tooltip content={maxTooltipContent} arrow placement="top">
            <span
              onClick={!disableMinMax ? handleClickMax : null}
              className={cx(styles.labelButton, {
                [styles.labelButton_disabled]: isMaxSelected || disableMinMax,
              })}
            >
              max
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
