import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const VaultIcon = (
  { viewBox = "0 0 24 25", htmlColor = "#002349", classes = {}, style },
  props
) => (
  <SvgIcon
    {...props}
    viewBox={viewBox}
    htmlColor={htmlColor}
    classes={{ ...classes }}
    style={style}
    x="0"
    y="0"
  >
    <path d="M20.1 1.93091H3.9C2.55 1.93091 1.5 2.98091 1.5 4.33091V20.5309C1.5 21.8809 2.55 22.9309 3.9 22.9309H20.1C21.45 22.9309 22.5 21.8809 22.5 20.5309V4.33091C22.5 2.98091 21.45 1.93091 20.1 1.93091ZM20.925 20.5309C20.925 20.9809 20.55 21.3559 20.1 21.3559H3.9C3.45 21.3559 3.075 20.9809 3.075 20.5309V4.33091C3.075 3.88091 3.45 3.50591 3.9 3.50591H20.1C20.55 3.50591 20.925 3.88091 20.925 4.33091V20.5309Z" />
    <path d="M18.225 15.4309L15.825 14.0809C15.975 13.6309 16.05 13.2559 16.05 12.7309C16.05 10.7059 14.625 9.05588 12.75 8.68088V5.98088C12.75 5.53088 12.375 5.15588 11.925 5.15588C11.475 5.15588 11.1 5.53088 11.1 5.98088V8.68088C9.22498 9.05588 7.79998 10.7059 7.79998 12.7309C7.79998 13.1809 7.87498 13.6309 8.02498 14.0809L5.77498 15.4309C5.39998 15.6559 5.24998 16.1809 5.47498 16.5559C5.62498 16.7809 5.84998 16.9309 6.14998 16.9309C6.29998 16.9309 6.44998 16.9309 6.52498 16.8559L8.92498 15.5059C9.67498 16.3309 10.8 16.9309 12 16.9309C13.2 16.9309 14.325 16.4059 15.075 15.5059L17.475 16.8559C17.625 16.9309 17.775 16.9309 17.85 16.9309C18.15 16.9309 18.375 16.7809 18.525 16.5559C18.75 16.1809 18.675 15.6559 18.225 15.4309ZM12 15.2809C10.65 15.2809 9.52498 14.1559 9.52498 12.8059C9.52498 11.4559 10.65 10.3309 12 10.3309C13.35 10.3309 14.475 11.4559 14.475 12.8059C14.475 14.1559 13.35 15.2809 12 15.2809Z" />
  </SvgIcon>
);

VaultIcon.propTypes = {
  classes: PropTypes.object,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};
