import React, { useEffect, useMemo, useState } from "react";

import { TextField, TextFieldProps } from "@unchained/component-library";
import { useField, useFormikContext } from "formik";
import { debounce, get, merge, pick } from "lodash";

import { useCustomFormikContext, useIsInValidateOnSubmitForm } from "./Formik";
import { DEFAULT_FORMIK_TEXT_FIELD_DEBOUNCE_INTERVAL } from "./constants";

export type FormikTextFieldProps = TextFieldProps & {
  name: string;
  /** Whether error state should be displayed */
  showError?: boolean;
  /** When inside a validateOnSubmit form,
   * this is the interval to wait before updating formik context,
   * or validating, if the field has an error */
  debounceInterval?: number;
};

type InternalFormikTextFieldProps = Omit<FormikTextFieldProps, "error"> & {
  value: string;
  error?: string;
  touched?: boolean;
  setTouched: (bool: boolean) => void;
  setValue: (value: string) => void;
};

const InternalFormikTextField = ({
  name,
  helperText,
  showError = true,
  debounceInterval = DEFAULT_FORMIK_TEXT_FIELD_DEBOUNCE_INTERVAL,
  error,
  touched,
  setTouched,
  setValue: setFormikValue,
  ...props
}: InternalFormikTextFieldProps) => {
  const validateOnSubmit = useIsInValidateOnSubmitForm();
  const form = useFormikContext();
  const { enableReinitialize } = useCustomFormikContext();
  const initialValue = get(form.initialValues, name);
  const [val, setVal] = useState(initialValue);

  // If the initial value for the text field has changed in the form, AND
  // the form is set to enableReinitialize on update
  // update the internally-tracked value.
  useEffect(() => {
    if (enableReinitialize) setVal(initialValue);
  }, [initialValue, enableReinitialize]);

  const { validateField } = form;
  const hasError = error && touched;

  const validate = (isTouched: boolean) => {
    if (!isTouched) setTouched(true);
    try {
      validateField(name);
    } catch (e) {
      console.error(e);
    }
  };

  const setFormikValueDebounced = useMemo(
    () => debounce((value: string) => setFormikValue(value), debounceInterval),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validateDebounced = useMemo(
    () => debounce(validate, debounceInterval),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // If validateOnSubmit is true, we track values locally and only validate or update formik context debounced
  const shouldDebounce = Boolean(validateOnSubmit && process.env.NODE_ENV !== "test");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (shouldDebounce) {
      setVal(e.target.value);
      setFormikValueDebounced(e.target.value);
      if (hasError) validateDebounced(touched);
    } else {
      setFormikValue(e.target.value);
      validate(touched);
    }
  };

  const finalProps = merge({}, props, {
    value: val,
    error: hasError,
    helperText: hasError && showError ? error : helperText,
    name,
    onChange: handleChange,
  });

  return <TextField {...finalProps} />;
};

export const FormikTextField = (props: FormikTextFieldProps) => {
  const [field, { error, touched }, helpers] = useField(props.name);

  // Separated into an "internal" field to solve weird state issues.
  // useEffect was not operating as expected.
  return (
    <InternalFormikTextField
      {...props}
      {...pick(helpers, "setValue", "setTouched")}
      {...field}
      error={error}
      touched={touched}
      onBlur={props.onBlur}
    />
  );
};
