import { useMediaQuery, useTheme } from "@mui/material";

import { breakpointNames } from "../sx";

/**
 * @param {string} breakpoint One of the acceptable breakpoints "xs", "sm", "md", "lg", or "xl"
 * @returns {*} A ifBelowBreakpoint function which takes values (ifBelow, and ifAbove) and returns one based on the breakpoint.
 * - Most commonly, these values are style objects (eg { display: 'none' })
 */
export const useBreakpoint = breakpoint => {
  if (!breakpointNames.includes(breakpoint)) {
    throw new Error(`Invalid breakpoint name: ${breakpoint}`);
  }
  const theme = useTheme();
  const isBelow = useMediaQuery(theme.breakpoints.down(breakpoint));
  const ifBelowBreakpoint = (ifBelow, ifAbove = {}) => (isBelow ? ifBelow : ifAbove);
  return ifBelowBreakpoint;
};
