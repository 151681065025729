import { SET_KEY_INDEX_STATUS, SET_KEY_INDEX_USER } from "Actions/keyActions/keyIndexActions";
import {
  SET_KEY_BY_UUID,
  SET_KEY_SHOW_CHECK_ENABLED,
  SET_KEY_SHOW_NAME,
  SET_KEY_SHOW_NEW_NAME,
} from "Actions/keyActions/keyShowActions";

const initialState = {
  userKeys: {},
  status: "PENDING",
};

export const keyIndexReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_KEY_INDEX_USER: {
      let allKeys = {};
      Object.keys(payload).forEach(keyUuid => {
        if (state.userKeys[keyUuid]) {
          allKeys[keyUuid] = {
            ...payload[keyUuid],
            vault_listings: state.userKeys[keyUuid].vault_listings,
            loan_listings: state.userKeys[keyUuid].loan_listings,
          };
        } else {
          allKeys[keyUuid] = { ...payload[keyUuid] };
        }
      });

      return {
        ...state,
        userKeys: allKeys,
      };
    }

    case SET_KEY_INDEX_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case SET_KEY_BY_UUID: {
      return {
        ...state,
        userKeys: {
          ...state.userKeys,
          [payload.uuid]: payload.userKey,
        },
      };
    }

    case SET_KEY_SHOW_NAME: {
      return {
        ...state,
        userKeys: {
          ...state.userKeys,
          [payload.uuid]: {
            ...state.userKeys[payload.uuid],
            name: payload.name,
          },
        },
      };
    }

    case SET_KEY_SHOW_NEW_NAME: {
      return {
        ...state,
        userKeys: {
          ...state.userKeys,
          [payload.uuid]: {
            ...state.userKeys[payload.uuid],
            newName: payload.name,
          },
        },
      };
    }

    case SET_KEY_SHOW_CHECK_ENABLED: {
      return {
        ...state,
        userKeys: {
          ...state.userKeys,
          [payload.uuid]: {
            ...state.userKeys[payload.uuid],
            users_account_keys: (state.userKeys[payload.uuid].users_account_keys || []).map(
              signership => {
                if (signership.username === window.TREFOIL_USER.username) {
                  return { ...signership, check_enabled: payload.check_enabled };
                } else {
                  return signership;
                }
              }
            ),
          },
        },
      };
    }

    default:
      return state;
  }
};
