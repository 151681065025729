import React from "react";

import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

import { PrimaryButton } from "Components/Shared/Elements/Buttons/PrimaryButton";
import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";
import wizWrapStyles from "Components/Shared/wizard/WizardComponentWrapper.module.scss";
import { downloadTextFile } from "Utils/download";

import styles from "./KeySignedPsbt.module.scss";

export const KeySignedPsbt = ({ signedPsbt, operationUUID, accountKeyName, ...rest }) => {
  let Content;
  if (!signedPsbt) Content = () => <p>You are unable to view the signed PSBT</p>;
  else {
    const filename = `${accountKeyName.substr(0, 5)}-${operationUUID}.psbt`;
    const downloadPsbt = () => downloadTextFile(signedPsbt, filename);
    Content = () => <PrimaryButton text="Download PSBT" onClick={downloadPsbt} />;
  }

  return (
    <WizardComponentWrapper size="sm">
      <h3 className={styles.smTitle}>Download signed PSBT</h3>
      <div className={wizWrapStyles.response}>
        <Grid container spacing={1} classes={{ container: styles.signatureContainer }}>
          <Grid item xs={12}>
            <Content />
          </Grid>
        </Grid>
      </div>
    </WizardComponentWrapper>
  );
};

KeySignedPsbt.propTypes = {
  signedPsbt: PropTypes.string,
  operationUUID: PropTypes.string.isRequired,
  accountKeyName: PropTypes.string,
};
