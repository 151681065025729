import React, { useContext, useEffect } from "react";

import { LedgerRegisterWalletPolicy, MultisigWalletConfig } from "@caravan/wallets";
import { ModalContent, useToast } from "@unchained/component-library";

import { handleDeviceError } from "Components/SigningDevices/DeviceErrorHandling";
import { WalletConfigInfo } from "Components/wallets/WalletConfigInfo";
import { useUpdatePolicyHmacs } from "Shared/api/hooks/wallets";
import { GetWalletConfig200 } from "Specs/v1";
import { ErrorToastOptions, useErrorToast } from "Utils/toasts";

import { WalletRegistrationContext } from "../WalletRegistrationWizard";

export const LedgerWalletRegistrationStepWithContext = () => {
  const { walletConfig, setStepIndex, stepIndex } = useContext(WalletRegistrationContext);

  return (
    <LedgerWalletRegistrationStep
      walletConfig={walletConfig}
      handleNext={() => setStepIndex(stepIndex + 1)}
    />
  );
};

export const LedgerWalletRegistrationStep = ({
  walletConfig,
  handleNext,
  xfp,
}: {
  walletConfig: GetWalletConfig200;
  handleNext: () => void;
  xfp?: string;
}) => {
  const showErrorToast = useErrorToast();
  const { enqueueSimpleToast } = useToast();
  const updatePolicyHmac = useUpdatePolicyHmacs();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    (() => {
      // we can register the wallet as soon as the component loads.
      // callers of this component should handle confirmation of device version and connection.
      const interaction = new LedgerRegisterWalletPolicy(walletConfig as MultisigWalletConfig);
      const registerWallet = async () => {
        try {
          const deviceXfp = xfp || (await interaction.getXfp());
          if (walletConfig.ledgerPolicyHmacs.find(registration => registration.xfp === deviceXfp)) {
            // if the wallet is already registered, skip this step
            enqueueSimpleToast({
              persist: false,
              dismissable: true,
              title: "Wallet already registered",
              description: "Connect a different device to register with this wallet.",
            });
            handleNext();
            return;
          }
          const ledgerPolicyHmac = await interaction.run();
          await updatePolicyHmac.mutate({
            xfp: deviceXfp,
            ledgerPolicyHmac,
            walletUuid: walletConfig.uuid,
          });

          // this is only using a timeout for testing so that we can confirm
          // the data on the registration view when we're mocking out ledger interactions
          setTimeout(handleNext, 0);
        } catch (e) {
          const cleanMessage = handleDeviceError(e);
          const error: ErrorToastOptions = {
            persist: false,
            title: "Something went wrong.",
            description: cleanMessage,
          };
          showErrorToast(e, error);
        }
      };
      registerWallet();

      return async () => {
        try {
          // cleanup of the interaction if in progress and component dismounts
          await interaction.closeTransport();
        } catch (e) {
          // if this fails then another action is in progress or it was already was closed and we can
          // safely swallow and ignore
          return;
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }) as unknown as React.EffectCallback,
    []
  );

  return (
    <ModalContent>
      <WalletConfigInfo walletConfig={walletConfig} withUuidAndPolicyRows={true} />
    </ModalContent>
  );
};
