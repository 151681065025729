import React from "react";

import { InputLabel, TextFieldProps } from "@unchained/component-library";
import cn from "classnames";
import { camelCase as toCamelCase } from "lodash";

import { FormikTextField } from "./FormikTextField";

type FormikNameRowProps = {
  /** If the name fields are not top-level, this is the key under which they are stored. */
  nestingKey?: string;
  layoutClass?: string;
  /**
   * If a unifiedLabel is provided (a label above the whole row),
   * it replaces field-level labels.
   * */
  unifiedLabel?: string;
  camelCase?: boolean;
  /** Any text field props can be passed for any of the sub-rows, to override them.
   * Note that the field names in overrides are always snake cased (eg, overrides.first_name),
   * even if the camelCased prop is set.
   */
  overrides?: {
    first_name?: TextFieldProps;
    middle_name?: TextFieldProps;
    last_name?: TextFieldProps;
  };

  /** Whether all the fields should be disabled */
  disabled?: boolean;
};

/**
 * Renders a responsive row of input fields for first, middle, and last name.
 * Defaults are applied, but can be overridden by passing overrides.
 * */
export const FormikNameRow = ({
  nestingKey,
  layoutClass = "w-full grid gap-4 md:grid-cols-8",
  unifiedLabel,
  overrides = {},
  camelCase,
  disabled,
}: FormikNameRowProps) => {
  const hideIndividualLabels = !!unifiedLabel;
  const name = (field: string) => {
    const cased = camelCase ? toCamelCase(field) : field;
    return nestingKey ? [nestingKey, cased].join(".") : cased;
  };

  return (
    <div className="w-full">
      {unifiedLabel ? <InputLabel>{unifiedLabel}</InputLabel> : null}
      <div className={cn(layoutClass, unifiedLabel ? "mt-2" : null)}>
        <FormikTextField
          name={name("first_name")}
          label="First Name"
          hideLabel={hideIndividualLabels}
          placeholder="First name"
          className="col-span-1 md:col-span-3"
          fullWidth
          disabled={disabled}
          {...(overrides["first_name"] || {})}
        />
        <FormikTextField
          name={name("middle_name")}
          label="Middle"
          hideLabel={hideIndividualLabels}
          placeholder="Middle"
          className="col-span-1 md:col-span-2"
          fullWidth
          disabled={disabled}
          {...(overrides["middle_name"] || {})}
        />
        <FormikTextField
          name={name("last_name")}
          label="Last name"
          hideLabel={hideIndividualLabels}
          placeholder="Last name"
          className="col-span-1 md:col-span-3"
          fullWidth
          disabled={disabled}
          {...(overrides["last_name"] || {})}
        />
      </div>
    </div>
  );
};
